import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import EventList from "../../common/EventList";
import ButtonBack from "../../common/ButtonBack";
import Breadcrumb from "../../common/Breadcrumb";

class Events extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The admin event list component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="Event History" />

                    <EventList />

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Events.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Events);