import React from 'react';
import {FormattedMessage} from "react-intl";
import Propertii from "../common/Propertii";
import Alert from "../common/Alert";
import axios from "axios";
import * as constants from "../../util/constants";
import $ from "jquery";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FieldText from "../common/FieldText";
import ButtonSave from "../common/ButtonSave";
import Modal from "../common/Modal";
import Spinner from "./Spinner";
import FieldCheckbox from "./FieldCheckbox";

class ChargeCodes extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            code: '',
            name: '',

            company: null,

            editing: false,

            integrationId: '',
            integrationChargeCodes: [],

            selectAllChargeCodes: false,

            validationList: [],
        };

        this.initChargeCode = this.initChargeCode.bind(this);
        this.initChargeCodeImport = this.initChargeCodeImport.bind(this);
        this.saveChargeCodeImport = this.saveChargeCodeImport.bind(this);
        this.editChargeCode = this.editChargeCode.bind(this);
        this.saveChargeCode = this.saveChargeCode.bind(this);
        this.deleteChargeCode = this.deleteChargeCode.bind(this);
        this.handleChangeChargeCodeImport = this.handleChangeChargeCodeImport.bind(this);
        this.handleChangeSelectAllChargeCodes = this.handleChangeSelectAllChargeCodes.bind(this);
    }

    /**
     * Get the company based on the ID provided in the route.
     */
    componentDidMount() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/company/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            ]
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let company = response.data.records[0];

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${company.id}/fallbackfields`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    company: company,
                    integrationId: response.data.integrationId,
                }));

            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });

    }

    /**
     * Initialize a new charge code.
     */
    initChargeCode() {

        this.setState(prevState => ({
            ...prevState,
            code: '',
            name: '',
            editing: false,
        }));
    }

    /**
     * Initialize the import charge code flow by making a call to the integration service and pulling all available
     * charge codes from the integration.
     */
    initChargeCodeImport() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/${this.state.company.integrationId}/chargecodes`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

            let integrationChargeCodes = [];
            let companyChargeCodes = this.state.company.chargeCodes;

            response.data.forEach((data, key) => {
                if(!companyChargeCodes.some(chargeCode => (chargeCode.code === data.code && chargeCode.name === data.name))) {
                    integrationChargeCodes.push({
                        code: data.code,
                        name: data.name,
                        selected: false
                    });
                }
            });

            this.setState(prevState => ({
                ...prevState,
                integrationChargeCodes: integrationChargeCodes,
            }));

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Save the list of selected charge codes pulled from the integration. Check for duplicates beforehand to ensure
     * only new charge codes are added.
     *
     * @param event
     */
    saveChargeCodeImport(event) {

        event.preventDefault();

        let chargeCodes = [];
        let companyChargeCodes = this.state.company.chargeCodes;
        let duplicateChargeCode = false;

        this.state.integrationChargeCodes.forEach((data, key) => {
            if(data.selected) {
                chargeCodes.push({
                    code: data.code,
                    name: data.name,
                });
            }
        });

        chargeCodes.find((charge, key) => {

            companyChargeCodes.forEach((data, key) => {
                if(charge.code === data.code) {

                    duplicateChargeCode = true;

                    this.setState({
                        validationList: [{
                            fields: {},
                            alert: {
                                type: 'danger',
                                code: this.props.duplicateMessage
                            }
                        }],
                    });

                }
            });

            return null;

        });

        if(!duplicateChargeCode) {
            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, {
                ...this.state.company,
                chargeCodes: [...this.state.company.chargeCodes, ...chargeCodes]
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    company: response.data,
                    selectAllChargeCodes: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: this.state.editing ? 'saved' : this.props.createMessage
                        }
                    }],
                });

                $("#charge-code-import").modal("hide");

            }).catch(error => {
                console.error(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Edit an existing charge code.
     *
     * @param chargeCode - The charge code to edit.
     */
    editChargeCode(chargeCode) {

        this.setState(prevState => ({
            ...prevState,
            code: chargeCode.code,
            name: chargeCode.name,
            editing: true
        }));

        $("#charge-code").modal("show");
    }

    /**
     * Save a charge code to the company's list of charge codes. If creating a brand new charge, simply add it to the
     * array of charge codes. If editing an existing charge, first we must remove the existing charge and re-add the
     * newly updated charge to the array.
     *
     * @param event - The event container.
     */
    saveChargeCode(event) {

        event.preventDefault();

        let company = this.state.company;
        let chargeCodes = company.chargeCodes;
        let duplicateChargeCode = false;

        chargeCodes.find((charge, key) => {

            if(charge.code === this.state.code) {

                duplicateChargeCode = true;

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: this.props.duplicateMessage
                        }
                    }],
                });

            }

            return null;

        });

        if(!duplicateChargeCode) {

            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, {
                ...company,
                chargeCodes: [...chargeCodes, {
                    code: this.state.code,
                    name: this.state.name
                }],
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    company: response.data,
                    code: '',
                    name: '',
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: this.state.editing ? 'saved' : this.props.createMessage
                        }
                    }],
                });

                $("#charge-code").modal("hide");

            }).catch(error => {
                console.error(error);
            });

            window.scrollTo(0, 0);

        }
    }

    /**
     * Delete a charge code. In order to delete an existing charge code, the charge code array must be filtered.
     */
    deleteChargeCode() {

        let company = this.state.company;

        let filteredChargeCodes = company.chargeCodes.filter(chargeCode => chargeCode.code !== this.state.code);

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, {
            ...company,
            chargeCodes: filteredChargeCodes,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                company: response.data,
                code: '',
                name: '',
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: this.props.deleteMessage
                    }
                }],
            });

            $("#charge-code").modal("hide");

        }).catch(error => {
            console.error(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle changes to the selected status of each individual charge code pulled from the integration.
     *
     * @param event - The event container.
     */
    handleChangeChargeCodeImport(event) {

        event.persist();

        let integrationChargeCodes = this.state.integrationChargeCodes;

        integrationChargeCodes.forEach((data, key) => {
            if(data.code === event.target.id) {
                integrationChargeCodes[key].selected = event.target.checked;
            }
        });

        this.setState(prevState => ({
            ...prevState,
            integrationChargeCodes: integrationChargeCodes,
            selectAllChargeCodes: false
        }));
    }

    /**
     * Handle selecting all charge codes pulled from the integration for importing.
     *
     * @param event - The event container.
     */
    handleChangeSelectAllChargeCodes(event) {

        event.persist();

        let integrationChargeCodes = this.state.integrationChargeCodes;

        integrationChargeCodes.forEach((data, key) => {
            integrationChargeCodes[key].selected = event.target.checked;
        });

        this.setState(prevState => ({
            ...prevState,
            integrationChargeCodes: integrationChargeCodes,
            selectAllChargeCodes: event.target.checked
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The company charge codes management component.
     */
    render() {

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} validationType="primary" />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Charge Codes
                            </div>
                            <div className="col-8 text-right">
                                {this.state.integrationId &&
                                <div data-toggle="modal" data-target="#charge-code-import" className="btn btn-primary btn-sm" onClick={() => this.initChargeCodeImport()}>
                                    <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Import Charge Codes
                                </div>
                                }
                                <div data-toggle="modal" data-target="#charge-code" className="btn btn-primary btn-sm ml-2" onClick={() => this.initChargeCode()}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Charge Code
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.company && this.state.company.chargeCodes.length === 0 &&
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="text-center text-secondary">
                                    <FontAwesomeIcon icon={['fas', 'ghost']} className="fa-fw mb-4" size="5x"/>
                                </div>
                                <div className="text-center text-muted">
                                    <small>
                                        We were unable to find any custom charge codes associated with this company.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {this.state.company && this.state.company.chargeCodes.length > 0 &&
                    <div className="card-body card-body-table">
                        <table className="table table-hover table-bordered">
                            <thead>
                            <tr>
                                <th width="25%">Code</th>
                                <th width="75%">Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.company.chargeCodes.map((data, key) => {
                                return (
                                    <tr key={key} onClick={() => this.editChargeCode(data)} className="c-pointer">
                                        <td>
                                            {data.code}
                                        </td>
                                        <td>
                                            {data.name}
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                    }
                </div>

                <div className="modal fade" id="charge-code" tabIndex="-1" role="dialog" aria-labelledby="charge-code-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveChargeCode}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="charge-code-label">
                                        {!this.state.editing &&
                                        <span className="">Create Charge Code</span>
                                        }
                                        {this.state.editing &&
                                        <span className="">Charge Code</span>
                                        }
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>

                                {this.state.editing &&
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        If you would like to edit this charge code, you must first delete this charge code, then create a new one.
                                    </p>
                                </div>
                                }

                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="code" label="Code" disabled={this.state.editing} parent={this} value={this.state.code} />

                                    <FieldText id="name" label="Name" disabled={this.state.editing} parent={this} value={this.state.name} />

                                </div>
                                <div className="modal-footer bg-secondary d-block rounded-bottom">

                                    <div className="row">
                                        <div className="col">

                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#charge-code").modal("hide")}>Close</button>
                                            </div>

                                            <div className="float-right">

                                                {this.state.editing &&
                                                <div className="btn btn-lg btn-primary ml-2" onClick={() => {$("#charge-code").modal("hide"); $("#delete-charge-code").modal('show');}}>
                                                    Delete
                                                </div>
                                                }

                                                {!this.state.editing &&
                                                <ButtonSave/>
                                                }

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="charge-code-import" tabIndex="-1" role="dialog" aria-labelledby="charge-code-import-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveChargeCodeImport}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="charge-code-import-label">
                                        Import Charge Codes
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">

                                    <Alert validationList={this.state.validationList} validationType="danger" customClasses="mb-3" />

                                    <p className="mb-0">
                                        Select which charge codes you would like to import from your integrated system.
                                    </p>

                                </div>

                                {this.state.integrationChargeCodes.length > 0 &&
                                <div className="modal-body modal-body-table">
                                    <table className="table mb-0">
                                        <tbody>

                                        <tr>
                                            <td className="py-2 bg-secondary">
                                                <FieldCheckbox id="selectAllChargeCodes" fieldLabel="Select all charge codes" fieldClass="form-control-sm" fieldColumns="12" labelClass="col-form-label-sm c-pointer" parent={this} value={this.state.selectAllChargeCodes} handleChange={this.handleChangeSelectAllChargeCodes} />
                                            </td>
                                        </tr>

                                        {this.state.integrationChargeCodes.map((data, key) => {
                                            return (
                                                <tr key={key} className="">
                                                    <td className="py-2">
                                                        <FieldCheckbox id={data.code} fieldLabel={`${data.name} (${data.code})`} fieldClass="form-control-sm" fieldColumns="12" labelClass="col-form-label-sm c-pointer" model="integrationChargeCodes" parent={this} value={data.selected} handleChange={this.handleChangeChargeCodeImport}/>
                                                    </td>
                                                </tr>
                                            );
                                        })}

                                        </tbody>
                                    </table>
                                </div>
                                }

                                {this.state.integrationChargeCodes.length === 0 &&
                                <div className="modal-body text-center">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-secondary">
                                                <FontAwesomeIcon icon={['fas', 'ghost']} className="fa-fw mb-4" size="5x" />
                                            </div>
                                            <div className="text-center text-muted">
                                                <small>
                                                    There are no new available charge codes to import.
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }

                                <div className="modal-footer bg-secondary d-block rounded-bottom">

                                    <div className="row">
                                        <div className="col">

                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#charge-code-import").modal("hide")}>Close</button>
                                            </div>

                                            <div className="float-right">

                                                <ButtonSave/>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <Modal id="delete-charge-code" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Charge Code"
                       body="Are you sure you want to delete this custom charge code? Tenants will no longer be able to use this charge type when making payments.">
                    <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => {$("#delete-charge-code").modal("hide"); $("#charge-code").modal('show');}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button onClick={() => {this.deleteChargeCode()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                        Delete Charge Code
                    </button>
                </Modal>

            </React.Fragment>
        )
    };
}

export default ChargeCodes;