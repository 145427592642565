import { Component } from 'react';
import creditCardType from "credit-card-type";
import axios from "axios";
import * as constants from "../../util/constants";

class Letus extends Component {

    /**
     * Initialize the component. This component acts as a generic base component to house all commonly used functions to
     * reduce code duplication. Most pages will extend this base component. By default, all components should have the
     * state established below.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            spinner: false,
            validationList: []
        };

        this.handleValidation = this.handleValidation.bind(this);
        this.handleLoginRedirect = this.handleLoginRedirect.bind(this);

        this.getCardBrand = this.getCardBrand.bind(this);
        this.getCardSecurityCodeLabel = this.getCardSecurityCodeLabel.bind(this);

        this.generateRequestHeaders = this.generateRequestHeaders.bind(this);

        this.addDays = this.addDays.bind(this);

        this.handleFocusPaymentMethodField = this.handleFocusPaymentMethodField.bind(this);
        this.handleBlurPaymentMethodField = this.handleBlurPaymentMethodField.bind(this);
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state. You
     * may override this method by creating a method with the same handle in the corresponding child class.
     *
     * @param error - The server error response.
     */
    handleValidation(error) {

        let validationList = [];
        let validation = {};
        let fields = {};
        let recordType = '';

        if(error.response && error.response.data && error.response.data.recordType) {
            recordType = error.response.data.recordType;
        }

        this.setState({
            spinner: false,
        });

        // Handle access token expired failures
        if(error.response.data.errorCode === 'RM_ERROR_ACCESS_TOKEN_EXPIRED') {

            validation = {
                alert: {
                    type: 'danger',
                    code: error.response.data.errorCode,
                    message: error.response.data.message
                },
                fields
            };

            validationList.push(validation);

            this.setState({
                spinner: false,
                validationList: validationList,
            });
        }

        // Handle save list failures
        if(error.response.data.errorCode === 'RM_SAVE_LIST_FAILURE') {

            if(error.response.data.exceptionList) {
                Object.entries(error.response.data.exceptionList).forEach(([key, exception]) => {

                    if(exception.exception.fieldErrors) {

                        fields = {};

                        Object.entries(exception.exception.fieldErrors).forEach(([key, fieldError]) => {
                            fields[fieldError.fieldName] = fieldError.errorCode;
                        });
                    }

                    validation = {
                        alert: {
                            type: 'danger',
                            code: exception.exception.errorCode,
                            message: exception.exception.message
                        },
                        fields
                    };

                    validationList.push(validation);
                });
            }

            this.setState({
                spinner: false,
                validationList: validationList,
            });
        }

        // Handle non-save list failures
        if(error.response.data.errorCode !== 'RM_SAVE_LIST_FAILURE') {
            this.setState({
                spinner: false,
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: error.response.data.errorCode,
                        message: error.response.data.message
                    }
                }]
            });
        }

        // Handle duplicate username validation errors
        if(error.response.data.errorCode === 'RM_ERROR_RECORD_ALREADY_EXIST') {

            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: error.response.data.errorCode + (recordType ? ('.' + recordType) : ''),
                        message: error.response.data.message
                    },
                    fields
                }],
            });

            return;
        }

        // Handle all other singular validation errors
        if(error.response.data.fieldErrors) {

            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );

            validation = {
                alert: {
                    type: 'danger',
                    code: error.response.data.errorCode,
                    message: error.response.data.message
                },
                fields
            };

            validationList.push(validation);

            this.setState({
                spinner: false,
                validationList: validationList
            });
        }
    }

    /**
     * Handle redirecting the user to their appropriate page after successfully logging in.
     *
     * @param sessionRole - The session role data for the user who has successfully logged in.
     */
    handleLoginRedirect(sessionRole) {

        if(sessionRole == null) {
            return "/";
        }

        switch(sessionRole.type) {

            case "TYPE_ADMIN":
                return "/admin/dashboard";

            case "TYPE_MANAGER":
                return "/manager/dashboard";

            case "TYPE_LANDLORD":

                localStorage.setItem('status', sessionRole.applicationStatus);

                switch(sessionRole.applicationStatus) {

                    case "PENDING":
                    case "APPLIED":
                        return "/landlord/signup/application";

                    case "PAID":
                    case "COMPLETE":
                        return "/landlord/transactions";

                    default:
                        return "/login";

                }

            case "TYPE_CUSTOMER":
                return "/customer/payments";

            default:
                return "/login";

        }
    }

    /**
     * Fetch an individual list of each type of merchant account: bank, cash, PAD (all merchant accounts), and credit
     * for populating merchant account mapping select boxes.
     *
     * @param companyId - The ID of the company to search merchant accounts by.
     * @param paymentType - The payment type to search merchant accounts by. If no payment type is provided, search for
     * all merchant accounts for PAD purposes.
     * @param cardBrand - The brand of card, only applicable if the payment type is of 'TYPE_CREDIT_CARD'.
     */
    getMerchantAccounts(companyId, paymentType, cardBrand) {

        let conditionList = [
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'companyId',
                operator: 'EQUALS',
                fieldValue: companyId
            }, {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'paymentType',
                operator: 'EQUALS',
                fieldValue: paymentType
            },
        ];

        // Remove the payment type condition if no payment type is provided
        if(paymentType === null) {
            conditionList.splice(1, 1);
        }

        // Apply a card type condition if the payment type is credit
        if(paymentType === "TYPE_CREDIT_CARD") {
            conditionList.push({
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'pp.supportedCards',
                operator: 'MATCH',
                fieldValue: cardBrand
            })
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/merchant_account/search`, {
            orderBy: 'ASC',
            orderByFields: ['name'],
            conditionList: conditionList,
            joins: {
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                    returnFields: ['creditMerchantAccountList', 'bankMerchantAccountId', 'cashMerchantAccountId', 'padMerchantAccountId']
                },
                pp: {
                    targetRecordType: 'TYPE_PAYMENT_PROVIDER',
                    joinField: 'paymentProviderId',
                    alias: 'pp',
                    returnFields: ['supportedCards']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            switch(paymentType) {

                case 'TYPE_CREDIT_CARD':

                    switch(cardBrand) {

                        case 'VISA':
                            this.setState(prevState => ({
                                ...prevState,
                                visaMerchantAccounts: response.data.records,
                            }));
                            break;

                        case 'VISA_ELECTRON':
                            this.setState(prevState => ({
                                ...prevState,
                                visaElectronMerchantAccounts: response.data.records,
                            }));
                            break;

                        case 'MASTERCARD':
                            this.setState(prevState => ({
                                ...prevState,
                                mastercardMerchantAccounts: response.data.records,
                            }));
                            break;

                        case 'MAESTRO':
                            this.setState(prevState => ({
                                ...prevState,
                                maestroMerchantAccounts: response.data.records,
                            }));
                            break;

                        case 'AMERICAN_EXPRESS':
                            this.setState(prevState => ({
                                ...prevState,
                                americanExpressMerchantAccounts: response.data.records,
                            }));
                            break;

                        case 'DISCOVER':
                            this.setState(prevState => ({
                                ...prevState,
                                discoverMerchantAccounts: response.data.records,
                            }));
                            break;

                        case 'DINERS_CLUB':
                            this.setState(prevState => ({
                                ...prevState,
                                dinersClubMerchantAccounts: response.data.records,
                            }));
                            break;

                        case 'JCB':
                            this.setState(prevState => ({
                                ...prevState,
                                jcbMerchantAccounts: response.data.records,
                            }));
                            break;

                        case 'UNION_PAY':
                            this.setState(prevState => ({
                                ...prevState,
                                unionPayMerchantAccounts: response.data.records,
                            }));
                            break;

                        default:
                            break;

                    }

                    break;

                case 'TYPE_BANK_ACCOUNT':
                    this.setState(prevState => ({
                        ...prevState,
                        bankMerchantAccounts: response.data.records,
                    }));
                    break;

                case 'TYPE_CASH':
                    this.setState(prevState => ({
                        ...prevState,
                        cashMerchantAccounts: response.data.records,
                    }));
                    break;

                default:
                    this.setState(prevState => ({
                        ...prevState,
                        padMerchantAccounts: response.data.records,
                    }));
                    break;

            }

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Return a credit/debit card brand, based on the 'creditCardType' library's validation of an inputted card number,
     * in all uppercase.
     *
     * @param cardNumber - The credit or debit card number to validate.
     * @returns {string|null} - The credit card type, if available, in uppercase (i.e: 'VISA').
     */
    getCardBrand(cardNumber) {

        let creditCards = creditCardType(cardNumber);

        if(creditCards[0] != null) {

            switch(creditCards[0].type.toUpperCase().replace('-', '_')) {

                case "DINERSCLUBINTERNATIONAL":
                    return "DINERS_CLUB";

                case "UNIONPAY":
                    return "UNION_PAY";

                default:
                    return creditCards[0].type.toUpperCase().replace('-', '_');

            }
        }

        return null;
    }

    /**
     * Return a credit card security code label matching that of the credit card type. For example, Visa's security code
     * is referred to as 'CVV', whereas Mastercard's security code label is referred to as 'CVC'. Utilizes the
     * 'creditCardType' library for validation.
     *
     * @param cardNumber - The credit or debit card number to validate.
     * @returns {string|*} - The credit or debit card security code label, or 'CVV' by default.
     */
    getCardSecurityCodeLabel(cardNumber) {

        let creditCards = creditCardType(cardNumber);

        if(creditCards[0] != null) {
            return creditCards[0].code.name;
        } else {
            return 'CVV';
        }
    }

    /**
     * Generate a request header for making API calls. Returns a JSON object containing the Content-Type, defaulted to
     * 'application/json', and Authorization parameters commonly used when making calls.
     *
     * @returns - A basic request header containing the Content-Type and Authorization params.
     */
    generateRequestHeaders() {

        return {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token')
        };
    }

    /**
     * Add days to a provided date. Useful for establishing a min or max date range for date picker fields.
     *
     * @param date - A date object, usually set to the current date.
     * @param days - The amount of days to add to the provided date.
     * @returns {Date} - A new date object with the added days.
     */
    addDays(date, days) {

        let result = new Date(date);

        result.setDate(result.getDate() + days);

        return result;
    }

    /**
     * Handle focusing on a payment method field. Used for the card preview component, where each element of the card
     * preview becomes highlighted as the user focuses on the appropriate field. If the security code field is focused,
     * trigger a flipping animation on the card preview component, revealing the security code element of the card
     * preview.
     *
     * @param field - The name of the field focused on.
     */
    handleFocusPaymentMethodField(field) {

        this.setState(prevState => ({
            ...prevState,
            activePaymentMethodField: field,
        }));

        if(field === 'securityCode') {
            this.setState(prevState => ({
                ...prevState,
                cardPreviewFlipped: true
            }));
        }
    }

    /**
     * Handle blurring on a payment method field. Used for the card preview component, where each element of the card
     * preview becomes highlighted as the user focused on the appropriate field. In this case, if any field is blurred,
     * set the active payment method field to blank, and flip the card preview component to it's original side.
     *
     * @param field - The name of the field blurred.
     */
    handleBlurPaymentMethodField(field) {

        this.setState(prevState => ({
            ...prevState,
            activePaymentMethodField: '',
        }));

        if(field === 'securityCode') {
            this.setState(prevState => ({
                ...prevState,
                cardPreviewFlipped: false
            }));
        }
    }
}

export default Letus;