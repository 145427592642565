import React from 'react';
import axios from "axios";
import * as constants from "../../util/constants";
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FieldFile from "../common/FieldFile";
import $ from "jquery";
import ButtonUpload from "../common/ButtonUpload";
import Alert from "./Alert";
import Spinner from "./Spinner";
import Moment from "react-moment";

class DocumentList extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            document: {
                formData: '',
            },
            propertyLeaseId: '',
            propertyLeaseList: [],
            documentList: [],
            validationList: [],
        };

        this.searchDocuments = this.searchDocuments.bind(this);
        this.searchPropertyLeases = this.searchPropertyLeases.bind(this);

        this.downloadDocument = this.downloadDocument.bind(this);
        this.uploadDocument = this.uploadDocument.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);

        this.handleChangePropertyLeaseId = this.handleChangePropertyLeaseId.bind(this);
    }

    /**
     * Initialize the list of documents on the mounting of the component. If no record ID is established in the props,
     * fetch a list of property leases so the user can select one
     */
    componentDidMount() {

        this.searchDocuments();

        if(!this.props.recordId) {
            this.searchPropertyLeases();
        }
    }

    /***
     * Download a document from the list of documents.
     *
     * @param documentId - The ID of the document selected.
     */
    downloadDocument(documentId) {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/document/${documentId}/download`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': this.props.token
            }
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

            this.setState({
                spinner: false
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle the submission of the form.
     *
     * @param event - The event container.
     */
    uploadDocument(event) {

        event.preventDefault();

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/${this.props.recordType}/${this.props.recordId || this.state.propertyLeaseId}/upload`, this.state.document.formData, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.documents.created'
                    }
                }],
            });

            this.searchDocuments();

            $('#document').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Delete the selected document.
     *
     * @param documentId - The ID of the document to be deleted.
     */
    deleteDocument(documentId) {

        this.setState({
            spinner: true
        });

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/document/${documentId}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.documents.deleted'
                    }
                }],
            });

            this.searchDocuments();

            $('#delete-document').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Search for a list of documents.
     */
    searchDocuments() {

        this.setState({
            spinner: true
        });

        let documentQuery = {
            orderBy: 'ASC',
            orderByFields: ['name'],
            conditionList: []
        };

        if(this.props.recordId) {
            documentQuery.conditionList = [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'recordId',
                    operator: 'EQUALS',
                    fieldValue: this.props.recordId
                }
            ]
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/document/search`, documentQuery, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                documentList: response.data.records,
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Search for a list of property leases in order to select a property lease to upload a document to.
     */
    searchPropertyLeases() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: 'ACTIVE'
                }
            ],
            joins: {
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['name']
                },
                property: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'property',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode']
                },
            },
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                propertyLeaseList: response.data.records,
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle changes to the selected payment method for the recurring schedule or scheduled payment.
     *
     * @param event - The event container.
     */
    handleChangePropertyLeaseId(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    }


    /**
     * Render the component.
     *
     * @returns {*} - The administrator companies dashboard component.
     */
    render() {

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} validationType="primary" />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Documents
                            </div>
                            <div className="col text-right">
                                <div data-toggle="modal" data-target="#document" className="btn btn-primary btn-sm">
                                    <FontAwesomeIcon icon={['fas', 'upload']} className="fa-fw" /> Upload Document
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.documentList.length > 0 &&
                    <div className="card-body card-body-list">

                        {this.state.documentList.map((document, key) => {

                            let documentExtension = document.name.substr(document.name.lastIndexOf('.') + 1);
                            let documentIconName;

                            switch (documentExtension) {

                                case 'png':
                                case 'jpg':
                                case 'jpeg':
                                case 'gif':
                                    documentIconName = 'file-image';
                                    break;

                                case 'pdf':
                                    documentIconName = 'file-pdf';
                                    break;

                                case 'docx':
                                    documentIconName = 'file-word';
                                    break;

                                case 'csv':
                                    documentIconName = 'file-csv';
                                    break;

                                case 'xls':
                                    documentIconName = 'file-excel';
                                    break;

                                case 'txt':
                                    documentIconName = 'file-alt';
                                    break;

                                default:
                                    documentIconName = 'file';
                                    break;

                            }

                            return (
                                <div key={key} className="list-group">
                                    <div className="list-group-item list-group-item-action">
                                        <div className="media">
                                           <div className="align-self-center mr-3 text-center">
                                               <div className="fa-stack fa-1x">
                                                   <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                   <FontAwesomeIcon icon={['far', documentIconName]} className="fa-stack-1x fa-inverse" />
                                               </div>
                                           </div>
                                            <div className="media-body align-self-center">
                                                <div className="">
                                                    {document.name}
                                                </div>
                                                <small className="mb-0 ml-md-0 small text-muted">
                                                    Uploaded on <Moment format="MMM DD, YYYY HH:mm">{document.createDate}</Moment>
                                                </small>
                                            </div>
                                            <div className="align-self-center text-right">
                                                <div className="text-right">
                                                    <div className="dropdown">
                                                        <button className="btn btn-secondary btn-sm" type="button" id="document-actions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <FontAwesomeIcon icon={['fas', 'cog']} className="fa-fw"/>
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="document-actions">
                                                            <div className="dropdown-item c-pointer" onClick={() => this.downloadDocument(document.id)}>Download</div>
                                                            <div className="dropdown-item c-pointer" onClick={() => this.deleteDocument(document.id)}>Delete</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                    }

                    {this.state.documentList.length === 0 &&
                    <div className="card-body bg-secondary py-5">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="text-center text-muted">
                                    <div className="fa-stack fa-2x mb-2">
                                        <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                        <FontAwesomeIcon icon={['far', 'file-alt']} className="fa-stack-1x fa-inverse" />
                                    </div>
                                </div>
                                <div className="text-center text-muted">
                                    <small>
                                        There are currently no documents available to you.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                </div>

                <div className="modal fade" id="document" tabIndex="-1" role="dialog" aria-labelledby="document-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content">
                            <form onSubmit={this.uploadDocument}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="document-label">Upload Document</h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>

                                {(this.props.recordType === 'PROPERTY_LEASE' && !this.props.recordId) &&
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        To upload a document, select the document from your device and which property lease you would like to associate it with below. Your property manager or landlord will be able to view documents uploaded by you.
                                    </p>
                                </div>
                                }

                                {(this.props.recordType === 'PROPERTY_LEASE' && this.props.recordId) &&
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        To upload a document, select the document from your device below. The tenant will be able to view documents uploaded by you.
                                    </p>
                                </div>
                                }

                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    {this.state.propertyLeaseList.map((propertyLease, key) => {
                                        return (
                                            <div key={key} className="list-group mb-3">
                                                <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                    <input type="radio" name="propertyLeaseId" value={propertyLease.id} id={key} checked={this.state.propertyLeaseId === propertyLease.id || false} onChange={this.handleChangePropertyLeaseId} className="custom-control-input"/>
                                                    <label className="custom-control-label pl-3 c-pointer" htmlFor={key}>
                                                        <div className="row align-items-center">
                                                            <div className="col-8">
                                                                <div className="">
                                                                    {propertyLease.unit ? propertyLease.unit + ' - ' : ''}{propertyLease.joins.property.street1}
                                                                </div>
                                                                <small className="mb-0 ml-md-0 small text-muted">
                                                                    Managed by {propertyLease.joins.company.name}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    })}

                                    <FieldFile id="formData" labelClass="col-form-label-md d-none" fieldClass="form-control-md" fieldColumns="12" labelColums="0" model="document" parent={this} value={this.state.document['formData']} />

                                </div>
                                <div className="modal-footer bg-secondary d-block">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonUpload />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

DocumentList.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(DocumentList);