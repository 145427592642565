import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import EventList from "../../common/EventList";

class Events extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The company event list component.
     */
    render() {

        return(
            <React.Fragment>

                <EventList companyId={this.props.match.params.companyId} />

            </React.Fragment>
        )
    };
}

Events.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Events);