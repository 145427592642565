import React from 'react';
import Propertii from "../../common/Propertii";
import TransactionList from "../../common/Transactions";
import axios from "axios";
import * as constants from "../../../util/constants";
import $ from "jquery";
import {injectIntl, intlShape} from "react-intl";
import Spinner from "../../common/Spinner";
import Alert from "../../common/Alert";

class Transactions extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            propertyLease: {},
            validationList: [],
        };
    }

    /**
     * On mounting of the component, fetch the property lease data and all open charges associated with the property
     * lease at hand.
     */
    componentDidMount() {

        this.setState(prevState => ({
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.match.params.propertyLeaseId
                }
            ],
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                propertyLease: response.data.records[0],
            }));

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                {this.state.propertyLease.userId &&
                    <TransactionList userId={this.state.propertyLease.userId}
                                     displayFeeAmount={true}
                                     displayAdvanced={true}
                                     userType="TYPE_ADMIN" />
                }
            </React.Fragment>
        )
    };
}

Transactions.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Transactions);