import React from 'react';
import {Switch} from 'react-router-dom';
import axios from "axios";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import {injectIntl, intlShape} from "react-intl";
import PrivateRoute from "../../common/PrivateRoute";
import Propertii from "../../common/Propertii";
import NavListItem from "../../common/NavListItem";
import Signup from "./Signup";
import Properties from "./Properties";
import Tenants from "./Tenants";
import MerchantAccounts from "./MerchantAccounts";
import MerchantAccount from "./MerchantAccount";
import SystemAccount from "./SystemAccount";
import Transactions from "./Transactions";
import Landlord from "./Landlord";
import Invoices from "./Invoices";
import Mimic from "../../common/Mimic";
import Events from "./Events";
import Breadcrumb from "../../common/Breadcrumb";

class Navigation extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            landlord: {},
            company: {},
            validationList: [],
        };

        this.getLandlordWithCompany = this.getLandlordWithCompany.bind(this);
        this.retireLandlord = this.retireLandlord.bind(this);
    }

    /**
     * Get the landlord based on the Route's ID.
     */
    componentDidMount() {

        this.getLandlordWithCompany(this.props.match.params.landlordId);
    }

    /**
     * Retrieves the landlord and landlord's company.
     *
     * @param landlordId - The ID of the landlord to fetch data from.
     */
    getLandlordWithCompany(landlordId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${landlordId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                landlord: response.data
            }));

            if(response.data.companyId) {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${response.data.companyId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        company: response.data
                    }));
                }).catch(error => {
                    console.error(error);
                });
            }

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Set the landlord's application status to 'CANCELLED', thus retiring the landlord and it's company.
     */
    retireLandlord() {

        let landlord = Object.assign({}, this.state.landlord);

        landlord['applicationStatus'] = 'CANCELLED';

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, landlord, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.landlords.landlord.status.CANCELLED'
                    }
                }],
            });

            this.getLandlordWithCompany(this.state.landlord.id);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The edit API account interface.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/admin/landlords" parentPage="Landlords" childPage={`${this.state.landlord.firstName} ${this.state.landlord.lastName}`} />

                    <Alert validationList={this.state.validationList} />

                    <div className="row">
                        <div className="col-md-4">

                            <div className="card">

                                <div className="card-header">
                                    Options
                                </div>

                                <div className="card-body card-body-list">
                                    <div className="list-group">

                                        <NavListItem path={`/admin/landlords/${this.state.landlord.id}/edit`} active="edit" size="small" iconName="user" name="Landlord" />
                                        <NavListItem path={`/admin/landlords/${this.state.landlord.id}/signup`} active="signup" size="small" iconName="sign-in-alt" name="Sign Up" />

                                        {(this.state.landlord.applicationStatus === 'PAID' || this.state.landlord.applicationStatus === 'COMPLETE') &&
                                        <React.Fragment>
                                            <NavListItem path={`/admin/landlords/${this.state.landlord.id}/properties`} active="properties" size="small" iconName="building" name="Properties" />
                                            <NavListItem path={`/admin/landlords/${this.state.landlord.id}/tenants`} active="tenants" size="small" iconName="users" name="Tenants" />
                                            <NavListItem path={`/admin/landlords/${this.state.landlord.id}/merchants`} active="merchants" size="small" iconName="university" name="Merchant Accounts" />
                                            <NavListItem path={`/admin/landlords/${this.state.landlord.id}/transactions`} active="transactions" size="small" iconName="credit-card" name="Transactions" />
                                            <NavListItem path={`/admin/landlords/${this.state.landlord.id}/invoices`} active="invoices" size="small" iconName="file-invoice" name="Invoices" />
                                        </React.Fragment>
                                        }

                                        <NavListItem path={`/admin/landlords/${this.state.landlord.id}/account`} active="account" size="small" iconName="lock-alt" name="System Account" />
                                        <NavListItem path={`/admin/landlords/${this.state.landlord.id}/events`} active="events" size="small" iconName="history" name="Event History" customClasses="rounded-0" />

                                        {(this.state.company === null || this.state.company.status !== "RETIRED") &&
                                        <Mimic userType="landlord" userId={this.state.landlord.id} parent={this} label="Mimic Landlord" displayType="list" />
                                        }

                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="col-md-8">

                            <Switch>
                                <PrivateRoute exact path="/admin/landlords/:landlordId/edit" type="TYPE_ADMIN" component={Landlord} handleRefresh={(landlordId) => this.getLandlordWithCompany(landlordId)} />
                                <PrivateRoute exact path="/admin/landlords/:landlordId/signup" type="TYPE_ADMIN" component={Signup} />
                                <PrivateRoute exact path="/admin/landlords/:landlordId/properties" type="TYPE_ADMIN" component={Properties} />
                                <PrivateRoute exact path="/admin/landlords/:landlordId/tenants" type="TYPE_ADMIN" component={Tenants} />
                                <PrivateRoute exact path="/admin/landlords/:landlordId/merchants" type="TYPE_ADMIN" component={MerchantAccounts} />
                                <PrivateRoute exact path="/admin/landlords/:landlordId/transactions" type="TYPE_ADMIN" component={Transactions} />
                                <PrivateRoute exact path="/admin/landlords/:landlordId/invoices" type="TYPE_ADMIN" component={Invoices} />
                                <PrivateRoute exact path="/admin/landlords/:landlordId/merchants/:merchantAccountId" type="TYPE_ADMIN" component={MerchantAccount} />
                                <PrivateRoute exact path="/admin/landlords/:landlordId/account" type="TYPE_ADMIN" component={SystemAccount} />
                                <PrivateRoute exact path="/admin/landlords/:landlordId/events" type="TYPE_ADMIN" component={Events} />
                            </Switch>

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Navigation.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Navigation);