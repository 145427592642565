import React from 'react';
import {injectIntl, intlShape} from 'react-intl';
import Propertii from "../common/Propertii";
import Spinner from "../common/Spinner";
import LeaseApplicationFlow from "../common/LeaseApplication";
import qs from "qs";
import axios from "axios";
import * as constants from "../../util/constants";
import familyMoving from "../../media/img/backgrounds/couple-moving.jpg";

class Application extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            leaseApplication: {},
            leaseApplicationPage: {},
            companyName: '',
            validationList: [],
        };
    }

    /**
     * On mounting of the component, determine if the lease application is being initiated via a custom lease
     * application page, or via a direct link. If URL parameters have been provided, a direct link has been given. If
     * state props have been passed in from a previous redirect, the user came from a custom lease application landing
     * page.
     */
    componentDidMount() {

        let urlParams;

        // Handle starting a lease application via URL parameters
        if(this.props.location.search) {

            urlParams = qs.parse(this.props.location.search.slice(1));

            if (Object.keys(urlParams).length !== 0) {

                axios.get(`${constants.REACT_APP_HOST_API_URL}/lease_application/new`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        leaseApplication: {
                            ...response.data,
                            companyId: urlParams.c,
                            firstName: urlParams.fn,
                            lastName: urlParams.ln,
                            email: urlParams.e,
                        }
                    }));
                }).catch(error => {
                    this.props.history.push("");
                });

                axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${urlParams.c}/peek`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        companyName: response.data.name
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });

            }
        }

        // Handle starting a lease application via lease application page
        if(this.props.location.state && this.props.location.state.leaseApplication && this.props.location.state.leaseApplicationPage) {

            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...this.props.location.state.leaseApplication,
                    leaseApplicationPageId: this.props.location.state.leaseApplicationPage.id,
                    existingTenant: this.props.location.state.leaseApplication.existingTenant === 'true'
                },
                leaseApplicationPage: this.props.location.state.leaseApplicationPage,
                companyName: this.props.location.state.leaseApplicationPage.customFields.companyName,
            }));

        }

    }

    /**
     * Render the component.computer
     *
     * @returns {*} - The lease application component.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                {this.state.leaseApplication.id &&
                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${(this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields && this.state.leaseApplicationPage.customFields.background) ? this.state.leaseApplicationPage.customFields.background : familyMoving}') no-repeat center center scroll`}}>
                    <div className="container text-md-left">
                        <h1 className="display-5 pt-3 text-white">

                            {this.state.leaseApplication.firstName &&
                            <React.Fragment>
                                Hi, {this.state.leaseApplication.firstName}!
                            </React.Fragment>
                            }

                            {!this.state.leaseApplication.firstName &&
                            <React.Fragment>
                                Welcome to Letus!
                            </React.Fragment>
                            }

                        </h1>
                        <p className="lead text-white">

                            {this.state.leaseApplication.existingTenant &&
                            <React.Fragment>
                                Provide your lease information for {this.state.companyName}
                            </React.Fragment>
                            }

                            {!this.state.leaseApplication.existingTenant &&
                            <React.Fragment>
                                Submit your lease application {this.state.companyName ? `for ${this.state.companyName}` : ''}
                            </React.Fragment>
                            }

                        </p>
                    </div>
                </div>
                }

                <div className="container">

                    {this.state.leaseApplication.id &&
                    <LeaseApplicationFlow history={this.props.history} leaseApplicationPage={this.state.leaseApplicationPage ? this.state.leaseApplicationPage : null} leaseApplication={this.state.leaseApplication} />
                    }

                </div>

            </div>
        )
    };
}

Application.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Application);