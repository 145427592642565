import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Table from "../../common/Table";
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import FieldText from "../../common/FieldText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FieldTextarea from "../../common/FieldTextarea";
import FieldCompanyType from "../../common/FieldCompanyType";
import FieldAddress from "../../common/FieldAddress";
import $ from "jquery";
import ButtonSubmit from "../../common/ButtonSubmit";
import Alert from "../../common/Alert";
import FieldBirthDate from "../../common/FieldBirthDate";
import FieldSegmentCode from "../../common/FieldSegmentCode";

class Subsidiaries extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            parentCompany: {},
            company: {},

            companySearchFilter: '',

            companyList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            companyQuery: {
                orderBy: 'ASC',
                orderByFields: ['name'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'parentId',
                        operator: 'EQUALS',
                        fieldValue: this.props.match.params.companyId
                    }
                ]
            },
            validationList: [],
        };

        this.searchCompanies = this.searchCompanies.bind(this);
        this.filterCompanies = this.filterCompanies.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.selectCompany = this.selectCompany.bind(this);
        this.initCompany = this.initCompany.bind(this);
        this.saveCompany = this.saveCompany.bind(this);
    }

    /**
     * Initialize the subsidiary companies list by calling the update table function.
     */
    componentDidMount() {

        this.searchCompanies(1, 25, this.state.companyQuery);
    }

    /**
     * Handle clicking company by redirecting the user to the edit company component. Trigger a refresh to update the
     * parent's component's state.
     *
     * @param id - The ID of the company clicked on.
     */
    selectCompany(id) {

        this.props.handleRefresh(id);

        this.props.history.push('/admin/companies/' + id + '/edit');
    }

    /**
     * Initialize a new instance of a company when the user clicks the 'create subsidiary company' button.
     */
    initCompany() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                company: {
                    ...response.data,
                    parentId: this.props.match.params.companyId,
                    status: "ACTIVE"
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle submitting the search filter field by adjusting the parent company search query and initiating a new
     * search.
     *
     * @param event - The event container.
     */
    filterCompanies(event) {

        if (event != null) {
            event.preventDefault();
        }

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let companyQuery = this.state.companyQuery;

        companyQuery.conditionList = [
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'parentId',
                operator: 'EQUALS',
                fieldValue: this.props.match.params.companyId
            },
        ];

        if(this.state.companySearchFilter !== '') {
            companyQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.state.companySearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'name,legalName',
                    operator: 'MATCH',
                    fieldValue: this.state.companySearchFilter
                }
            );
        }

        this.setState({
            companyQuery: companyQuery
        });

        this.searchCompanies(1, 25, companyQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter queries.
     */
    clearFilters() {

        this.setState({
            companySearchFilter: '',
        }, () => {
            this.filterCompanies();
        });
    }

    /**
     * Handle the submission of the form. Use the create date field of the property unit in the state to determine if
     * the user is editing or creating a new property unit. Upon successful submission of the form, refresh the table
     * and hide the modal.
     *
     * @param event - The event container.
     */
    saveCompany(event) {

        event.preventDefault();

        axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, this.state.company, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.company.created'
                    }
                }],
            });

            this.searchCompanies(this.state.companyList.page, this.state.companyList.recordsPerPage, this.state.companyQuery);

            $('#company').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }


    /**
     * Update the data table.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchCompanies(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/company/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                companyList: response.data,
                companyQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The child company list component.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} validationType="primary" />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Subsidiary Companies
                            </div>
                            <div className="col text-right">
                                <div data-toggle="modal" data-target="#company" className="btn btn-primary btn-sm" onClick={() => this.initCompany()}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Subsidiary Company
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-header gotham border-top py-3 bg-secondary">
                        <form onSubmit={this.filterCompanies}>
                            <div className="media">
                                <div className="media-body align-self-center mr-3">
                                    <FieldText id="companySearchFilter" label="Company Name" labelClass="d-none"
                                               fieldColumns="12" labelColums="0" placeholder="Filter by ID, name or legal name..."
                                               parent={this} value={this.state.companySearchFilter} />
                                </div>
                                <div className="align-self-center text-right">
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                            <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                        </button>
                                        <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                            <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Table columns={{name: 'Name', street1: 'Street', city: 'City', province: 'State/Province'}}
                           columnWidths={['25%', '25%', '25%', '25%']}
                           headerClass="c-pointer"
                           data={this.state.companyList}
                           query={this.state.companyQuery}
                           sortEnabled={true}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.searchCompanies}>
                        <tbody>
                        {this.state.companyList.records.map((data, key) => {
                            return(
                                <tr key={key} onClick={() => this.selectCompany(data.id)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            {data.name}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.street1}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.city}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.country === 'CA' &&
                                            <FormattedMessage id={"province." + data.province}/>
                                            }
                                            {data.country === 'US' &&
                                            <FormattedMessage id={"state." + data.province}/>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="company" tabIndex="-1" role="dialog" aria-labelledby="company-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <form onSubmit={this.saveCompany}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="company-label">Create Subsidiary Company</h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="name" label="Company Name" model="company" parent={this} value={this.state.company['name']} />

                                    <FieldText id="legalName" label="Legal Name" model="company" parent={this} value={this.state.company['legalName']} />

                                    <FieldCompanyType id="companyType" label="Company Type" model="company" parent={this} value={this.state.company['companyType']} />

                                    <FieldSegmentCode id="segmentCode" label="Segment Code" model="company" parent={this} value={this.state.company['segmentCode']} />

                                    <FieldText id="phone" label="Business Phone" model="company" parent={this} value={this.state.company['phone']} />

                                    <FieldText id="taxNumber" label="Tax ID" model="company" parent={this} value={this.state.company['taxNumber']} />

                                    <FieldBirthDate id="inceptionDate" label="Inception Date" model="company" parent={this} required={true} value={this.state.company['inceptionDate']} />

                                    <FieldTextarea id="description" label="Description" model="company" parent={this} value={this.state.company['description']} />

                                    <FieldAddress model="company" parent={this} value={this.state.company} />

                                </div>
                                <div className="modal-footer bg-secondary d-block">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonSubmit />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

Subsidiaries.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Subsidiaries);