import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import Propertii from "../../common/Propertii";
import FieldText from "../../common/FieldText";
import FieldPhone from "../../common/FieldPhone";
import Spinner from "../../common/Spinner";
import {injectIntl, intlShape} from "react-intl";
import FieldSelect from "../../common/FieldSelect";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldCharges from "../../common/FieldCharges";
import FieldDate from "../../common/FieldDate";

class Tenant extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            propertyLease: {},
            customer: {},

            charges: [],

            validationList: [],

        };

        this.saveCustomer = this.saveCustomer.bind(this);

        this.addCharge = this.addCharge.bind(this);
        this.removeCharge = this.removeCharge.bind(this);
    }

    /**
     * Fetch the property lease and associated customer data on mounting of the component. Also pre-fill the property ID
     * field of the integrated tenant search with the relevant property identifier.
     */
    componentDidMount() {

        this.setState({
            spinner: true,
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.match.params.propertyLeaseId
                }
            ],
            joins: {
                property: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'property',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let propertyLease = response.data.records[0];

            axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${propertyLease.userId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    propertyLease: propertyLease,
                    customer: response.data,
                    charges: propertyLease.charges,
                }));

            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle the submission of the form by saving both the customer and property lease data.
     *
     * @param event - The event container.
     */
    saveCustomer(event) {

        event.preventDefault();

        this.setState({
            spinner: true,
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, [
            this.state.customer,
            {
                ...this.state.propertyLease,
                charges: this.state.charges
            }
        ], {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved'
                    }
                }],
            }));

            this.props.refreshPropertyLease(this.props.match.params.propertyLeaseId);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Add a new blank charge to the list of charges.
     */
    addCharge() {

        this.setState(prevState => ({
            ...prevState,
            charges: [...prevState.charges, {
                amount: '',
                code: '',
                name: ''
            }],
        }));
    }

    /**
     * Remove a specific charge from the list of charges.
     *
     * @param index - The array index of the charge to remove.
     */
    removeCharge(index) {

        let charges = this.state.charges;

        charges.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            charges: charges,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The tenant account management component for landlords.
     */
    render() {

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.saveCustomer}>

                    <div className="card">
                        <div className="card-header">
                            Tenant
                        </div>
                        <div className="card-body">

                            <FieldText id="firstName" label="First Name" model="customer" parent={this} value={this.state.customer['firstName']} />

                            <FieldText id="lastName" label="Last Name" model="customer" parent={this} value={this.state.customer['lastName']} />

                            <FieldText id="email" label="Email" type="email" disabled={true} model="customer" parent={this} value={this.state.customer['email']} />

                            <FieldPhone id="phone" label="Phone" model="customer" parent={this} value={this.state.customer['phone']} optional={true} />

                            <FieldSelect id="status" label="Lease Status" model="propertyLease" parent={this} value={this.state.propertyLease['status']}>
                                <option disabled>Select a status...</option>
                                <option value="ACTIVE">Active</option>
                                <option value="SUSPENDED">Suspended</option>
                                <option value="TERMINATED">Terminated</option>
                            </FieldSelect>

                            <FieldText id="unit" label="Unit" model="propertyLease" parent={this} value={this.state.propertyLease['unit']} />

                            <FieldDate id="startDate" label="Start Date" model="propertyLease" parent={this} disabled={true} value={this.state.propertyLease['startDate'] || null} />

                            <FieldSelect id="monthlyPaymentDueDay" label="Payment Due" model="propertyLease" parent={this} value={this.state.propertyLease['monthlyPaymentDueDay']}>
                                <option value={0}>No monthly payment due date</option>
                                <option value={1}>1st day of every month</option>
                                <option value={2}>2nd day of every month</option>
                                <option value={3}>3rd day of every month</option>
                                <option value={4}>4th day of every month</option>
                                <option value={5}>5th day of every month</option>
                                <option value={6}>6th day of every month</option>
                                <option value={7}>7th day of every month</option>
                                <option value={8}>8th day of every month</option>
                                <option value={9}>9th day of every month</option>
                                <option value={10}>10th day of every month</option>
                                <option value={11}>11th day of every month</option>
                                <option value={12}>12th day of every month</option>
                                <option value={13}>13th day of every month</option>
                                <option value={14}>14th day of every month</option>
                                <option value={15}>15th day of every month</option>
                                <option value={16}>16th day of every month</option>
                                <option value={17}>17th day of every month</option>
                                <option value={18}>18th day of every month</option>
                                <option value={19}>19th day of every month</option>
                                <option value={20}>20th day of every month</option>
                                <option value={21}>21st day of every month</option>
                                <option value={22}>22nd day of every month</option>
                                <option value={23}>23rd day of every month</option>
                                <option value={24}>24th day of every month</option>
                                <option value={25}>25th day of every month</option>
                                <option value={26}>26th day of every month</option>
                                <option value={27}>27th day of every month</option>
                                <option value={28}>28th day of every month</option>
                                <option value={29}>29th day of every month</option>
                                <option value={30}>30th day of every month</option>
                                <option value={31}>31st day of every month</option>
                            </FieldSelect>

                            <FieldCheckbox id="blockPartialPayments" label="Partial Payments" fieldLabel="Block this tenant from making partial payments" model="propertyLease" parent={this} value={this.state.propertyLease['blockPartialPayments']} />

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label col-form-label-sm">
                                    Monthly Charges
                                </label>
                                <div className="col-sm-9">
                                    <FieldCharges charges={this.state.charges} addCharge={this.addCharge} removeCharge={this.removeCharge} size="sm" parent={this} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonSave />

                        </div>
                    </div>

                </form>

            </React.Fragment>
        )
    };
}

Tenant.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Tenant);