import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import DatePicker from "react-datepicker/es";
import * as moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

class FieldDate extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        if (this.props.parent.handleChange) {
            this.handleChange = this.props.parent.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
    }

    /**
     * Handle change events on date fields. Convert the date to the format accepted by the server.
     *
     * @param date - The selected date value.
     */
    handleChange(date) {

        let dateField;

        let dateTime = date ? moment(date).toDate() : null;

        if (dateTime) {
            dateTime.setHours(0);
            dateTime.setMinutes(0);
            dateTime.setSeconds(0);
            dateTime.setMilliseconds(0);
        }

        if (this.props.model != null) {
            dateField = this.props.parent.state[this.props.model];
            dateField[this.props.id] = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]hh:mm:ssZZ') : null;
        }

        if (this.props.model == null) {
            dateField = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]hh:mm:ssZZ') : null;
        }

        this.props.parent.setState(({
            [this.props.model ? this.props.model : this.props.id]: dateField,
        }));
    }

    /**
     * Filter out specific days from selection on the datepicker.
     *
     * @param date - The date object from the datepicker.
     * @returns {boolean} - True if the date is selectable from the datepicker.
     */
    filterDate = (date) => {

        const day = date.getDate();

        // Filter the available selections from the datepicker to only the first of each month
        if(this.props.filterDateType === 'FIRST_DATE') {

            return day === 1;
        }

        // Filter the available selections from the datepicker to dates before the monthly payment due day
        if(this.props.filterDateType === 'MONTHLY_PAYMENT_DUE_DAY') {

            return this.props.monthlyPaymentDueDay >= date.getDate();
        }

        return false;
    };

    /**
     * Render the component.
     *
     * @returns {*} - A generic date field component. When clicked on, a date picker appears, which utilizes the
     * external react-datepicker library.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if (validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if (key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        let valueDate = new Date(this.props.value);
        let startDate = new Date(this.props.startDate);
        let endDate = new Date(this.props.endDate);
        let minDate = new Date(this.props.minDate);
        let maxDate = new Date(this.props.maxDate);

        return (
            <React.Fragment>

                <div className={`form-group row ${this.props.containerClass}`}>

                    <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                        {this.props.label}
                    </label>

                    <div className={`col-sm-${this.props.fieldColumns}`}>

                        <DatePicker
                            showMonthDropdown
                            showYearDropdown
                            showMonthYearPicker={this.props.showMonthYearPicker}
                            dropdownMode="select"
                            autoComplete="off"
                            id={this.props.id}
                            name={this.props.id}
                            minDate={this.props.minDate ? new Date(minDate.getTime() + minDate.getTimezoneOffset() * 60000) : null}
                            maxDate={this.props.maxDate ? new Date(maxDate.getTime() + maxDate.getTimezoneOffset() * 60000) : null}
                            selected={this.props.value ? new Date(valueDate.getTime() + valueDate.getTimezoneOffset() * 60000) : null}
                            onChange={this.handleChange}
                            required={this.props.required}
                            selectsStart={this.props.selectsStart}
                            selectsEnd={this.props.selectsEnd}
                            startDate={this.props.startDate ? new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000) : null}
                            endDate={this.props.endDate ? new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000) : null}
                            filterDate={this.props.filterDateType ? this.filterDate : null}
                            monthsShown={this.props.monthsShown}
                            disabled={this.props.disabled}
                            placeholderText={this.props.placeholder}
                            calendarClassName="gotham"
                            dateFormat={this.props.dateFormat}
                            className={`form-control w-100 gotham ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`}>

                            {this.props.children}

                        </DatePicker>

                        {isInvalid &&
                        <div className="small text-danger mt-1">
                            <FormattedMessage id={"danger." + errorCode}/>
                        </div>
                        }

                        {this.props.help &&
                        <small className="form-text text-muted">
                            {this.props.help}
                        </small>
                        }

                    </div>

                </div>

            </React.Fragment>
        )
    };
}

FieldDate.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    monthsShown: 1,
    dateFormat: 'MM/dd/yyyy',
};

export default FieldDate;
