import React from 'react';
import axios from "axios/index";
import * as constants from '../../util/constants';
import Propertii from "../common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Alert from "../common/Alert";
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import Spinner from "../common/Spinner";
import visa from "../../media/img/payments/visa.png";
import mastercard from "../../media/img/payments/mastercard.png";
import americanExpress from "../../media/img/payments/american-express.png";
import discover from "../../media/img/payments/discover.png";
import unionPay from "../../media/img/payments/unionpay.png";
import bankAccount from "../../media/img/payments/bank-account.png";
import {Link} from "react-router-dom";
import FieldText from "../common/FieldText";
import FieldSelect from "../common/FieldSelect";
import paymentFlow from "../../media/img/mockups/payment-flow.png";
import recurringPayment from "../../media/img/mockups/recurring-payment.png";
import creditCards from "../../media/img/mockups/credit-cards.png";
import modernTower from "../../media/img/backgrounds/modern-building.jpg";
import womanChair from "../../media/img/backgrounds/woman-chair.jpg";
import transactions from "../../media/img/mockups/transactions.png";
import * as Scroll from 'react-scroll';

class Landing extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            leaseApplicationPage: {},
            leaseApplication: {},
            existingTenant: false,
            firstName: '',
            lastName: '',
            email: '',
            confirmEmail: '',
            validationList: [],
        };

        this.startLeaseApplication = this.startLeaseApplication.bind(this);
    }

    /**
     * On mounting of the component, fetch the lease application page associated with the page name provided in the URL.
     * The values in the lease application page record will be used to customize the look and feel of the landing page.
     * Afterwards, create a new instance of a lease application object, pre-filling some values with the ones provided
     * in the lease application page record.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/lease_application_page/name/${this.props.match.params.pageName}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let leaseApplicationPage = response.data;

            axios.get(`${constants.REACT_APP_HOST_API_URL}/lease_application/new`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    leaseApplicationPage: leaseApplicationPage,
                    leaseApplication: {
                        ...response.data,
                        leaseApplicationPage: leaseApplicationPage.id,
                        companyId: leaseApplicationPage.companyId
                    },
                }));
            }).catch(error => {
                this.props.history.push("");
            });

        }).catch(error => {
            this.props.history.push("");
        });
    }

    /**
     * Start the lease application flow by redirecting the prospective tenant to the lease application form, passing
     * the lease application page and lease application records through the state.
     *
     * @param event - The event container.
     */
    startLeaseApplication(event) {

        event.preventDefault();

        if(this.state.leaseApplication.email !== this.state.leaseApplication.confirmEmail) {
            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: 'application.email.mismatch'
                    }
                }]
            }));
        }

        else {
            this.props.history.push({
                pathname: '/apply',
                state: {
                    leaseApplicationPage: this.state.leaseApplicationPage,
                    leaseApplication: this.state.leaseApplication,
                }
            });
        }
    }

    /**
     * Render the component.
     */
    render() {

        let ScrollLink = Scroll.Link;

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner}/>

                <div id="signup" className="jumbotron jumbotron-fluid content-header content-header-landing mb-0" style={{background: `linear-gradient(100deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${this.state.leaseApplicationPage.customFields && this.state.leaseApplicationPage.customFields.background ? this.state.leaseApplicationPage.customFields.background : modernTower}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container">

                        <div className="row align-items-center">
                            <div className="col-md-6">

                                <h1 className="text-white mb-4">
                                    {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields ? this.state.leaseApplicationPage.customFields.heroHeading : '{HERO_HEADING}'}
                                </h1>
                                <ul className="fa-ul benefit-list text-white lead mb-5">
                                    <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" /> {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields ? this.state.leaseApplicationPage.customFields.heroPoint1 : '{HERO_POINT_1}'}</li>
                                    <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" /> {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields ? this.state.leaseApplicationPage.customFields.heroPoint2 : '{HERO_POINT_2}'}</li>
                                    <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" /> {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields ? this.state.leaseApplicationPage.customFields.heroPoint3 : '{HERO_POINT_3}'}</li>
                                    <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" /> {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields ? this.state.leaseApplicationPage.customFields.heroPoint4 : '{HERO_POINT_4}'}</li>
                                </ul>
                                <div className="mb-5">
                                    <img src={visa} className="rounded img-fluid bg-light mr-2" alt="Visa" title="Visa" width="50"/>
                                    <img src={mastercard} className="rounded img-fluid bg-light mr-2" alt="Mastercard" title="Mastercard" width="50"/>
                                    <img src={americanExpress} className="rounded img-fluid bg-light mr-2" alt="American Express" title="American Express" width="50"/>
                                    <img src={discover} className="rounded img-fluid bg-light mr-2" alt="Discover" title="Discover" width="50"/>
                                    <img src={unionPay} className="rounded img-fluid bg-light mr-2" alt="UnionPay" title="UnionPay" width="50"/>
                                    <img src={bankAccount} className="rounded img-fluid bg-light mr-2" alt="Bank Account" title="Bank Account" width="50"/>
                                </div>

                            </div>
                            <div className="col-md-6">

                                <div className="jumbotron jumbotron-fluid rounded shadow mx-md-5 px-4 py-5">
                                    <div className="container">

                                        <form onSubmit={this.startLeaseApplication}>

                                            {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields && this.state.leaseApplicationPage.customFields.logo &&
                                            <h3 className="text-center">
                                                <img src={this.state.leaseApplicationPage.customFields.logo} alt={this.state.leaseApplicationPage.customFields.companyName} className="img-fluid"/>
                                            </h3>
                                            }

                                            <p className="text-center small mb-4 font-italic">
                                                {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields ? this.state.leaseApplicationPage.customFields.formSubheading : '{FORM_SUBHEADING}'}
                                            </p>

                                            <hr className="my-4"/>

                                            <Alert validationList={this.state.validationList} />

                                            <FieldSelect id="existingTenant" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0" fieldColumns="12" labelColums="0" parent={this} model="leaseApplication" value={this.state.leaseApplication['existingTenant']} autoFocus={true}>
                                                <option value="">Select your tenancy status...</option>
                                                <option value={false}>I am applying as a new tenant</option>
                                                <option value={true}>I am an existing tenant</option>
                                            </FieldSelect>

                                            <div className="row">
                                                <div className="col-6">

                                                    <FieldText id="firstName" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0" fieldColumns="12" labelColums="0" placeholder="First Name" parent={this} model="leaseApplication" value={this.state.leaseApplication['firstName']} maxLength="100" />

                                                </div>
                                                <div className="col-6">

                                                    <FieldText id="lastName" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0" fieldColumns="12" labelColums="0" placeholder="Last Name" parent={this} model="leaseApplication" value={this.state.leaseApplication['lastName']} maxLength="100" />

                                                </div>
                                            </div>

                                            <FieldText id="email" type="email" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0" fieldColumns="12" labelColums="0" placeholder="Email" parent={this} model="leaseApplication" value={this.state.leaseApplication['email']} maxLength="100" />

                                            <FieldText id="confirmEmail" type="email" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0" fieldColumns="12" labelColums="0" placeholder="Confirm Email" parent={this} model="leaseApplication" value={this.state.leaseApplication['confirmEmail']} maxLength="100" />

                                            <div className="row">
                                                <div className="col text-right">
                                                    <button type="submit" className="btn btn-lg btn-primary btn-block mb-0">
                                                        <FormattedMessage id="button.signup" />
                                                    </button>
                                                </div>
                                            </div>

                                        </form>

                                    </div>

                                </div>

                                <div className="row justify-content-sm-center">
                                    <div className="col-md-10 text-center">
                                        <p className="text-white text-center small">
                                            Already have an account? <Link to="/login">Log in now</Link>.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="container">

                    <div className="text-center pt-4">
                        <h1 className="py-4">
                            Letus is made for renters, by renters
                        </h1>
                    </div>

                    <div className="row justify-content-center py-5">

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'credit-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Payments
                                </h3>
                                <p className="">
                                    With flexible payment options and recurring schedules, making rent payments is a breeze.
                                </p>
                                <ScrollLink to="payments" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'bell']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Notifications
                                </h3>
                                <p className="">
                                    Receive real-time payment updates and detailed payment receipts, direct to your inbox.
                                </p>
                                <ScrollLink to="notifications" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'home-lg']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Leases
                                </h3>
                                <p className="">
                                    Letus has you covered with support from multiple leases to multiple roommates.
                                </p>
                                <ScrollLink to="leases" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'gift']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Rewards
                                </h3>
                                <p className="">
                                    Take advantage of one of your largest monthly expenses with your favorite rewards card.
                                </p>
                                <ScrollLink to="rewards" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                    </div>

                    <hr className="my-5" id="payments" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h1 className="mb-4">
                                Make paying your rent simple and rewarding
                            </h1>
                            <p className="mb-4 lead">
                                Despise those late fees? With Letus, you'll never miss another payment again with monthly auto payments, using a bank account or rewards credit card that suits you best.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'credit-card']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Multiple Payment Methods</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'calendar-check']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Setup Scheduled Payments</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={paymentFlow} alt="Letus offers your tenants the ability to make rent payments with their preferred payment method including Visa Credit, Visa Debit, Mastercard, Mastercard Debit, American Express, or directly from their bank account" className="img-sm-fluid" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="notifications" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h1 className="mb-4">
                                Stay in the loop with real time notifications
                            </h1>
                            <p className="mb-4 lead">
                                Never lose track of your payment history again. Reviewing the details of all your past and upcoming rent payments can be done anytime, anywhere from your Letus dashboard.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'bell']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Real-Time Payment Updates</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'receipt']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Detailed Payment Receipts</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={recurringPayment} alt="Receive email notifications from all of your tenants' payments, even if a payment was failed or charged back, all while funds are deposited directly into your bank account" className="img-sm-fluid float-right" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="leases" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h1 className="mb-4">
                                Supported for multiple leases and roommates
                            </h1>
                            <p className="mb-4 lead">
                                Whether you're paying for your son or daughter's college dorm, or splitting payments for your dorm with your roommate, Letus has you covered no matter the scenario.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'stopwatch']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Track Upcoming Payments</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'home-heart']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Review Lease Details</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={transactions} alt="Letus's intuitive payments platform and user interface will save you time and make paying rent easier than ever before" className="img-sm-fluid my-3" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="rewards" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h1 className="mb-4">
                                Maximize your rewards potential and build your credit
                            </h1>
                            <p className="mb-4 lead">
                                Make your rent payments with your favorite rewards credit card and enjoy the cash-back or points redemption from one of your largest monthly payments, all while building your credit.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'mobile-alt']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Make Payments Anytime</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'gift']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Earn Credit Card Rewards</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={creditCards} alt="Making rent payments with Letus allows tenants to build their credit history and earn points from their favorite rewards credit card" className="img-sm-fluid float-right" width="130%"/>
                        </div>
                    </div>

                </div>

                <div className="jumbotron jumbotron-fluid mb-0">
                    <div className="container">

                        <div className="text-center">
                            <h3 className="display-4 py-4">
                                Next steps
                            </h3>
                        </div>

                        <div className="row justify-content-center my-5">

                            <div className="col-md-4 text-center mb-5 mb-md-0">
                                <div className="px-3">
                                    <FontAwesomeIcon icon={['fal', 'id-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                    <h3 className="display-5 mb-2">
                                        Create your account
                                    </h3>
                                    <p className="">
                                        Set up your Letus tenant account with your rental lease information and payment details.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-4 text-center mb-5 mb-md-0 border-left border-right">
                                <div className="px-3">
                                    <FontAwesomeIcon icon={['fal', 'check-circle']} className="fa-fw mb-5 text-primary" size="6x"/>
                                    <h3 className="display-5 mb-2">
                                        Get verified
                                    </h3>
                                    <p className="">
                                        Your property manager will verify your information and invite you to complete your account.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-4 text-center mb-5 mb-md-0">
                                <div className="px-3">
                                    <FontAwesomeIcon icon={['fal', 'credit-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                    <h3 className="display-5 mb-2">
                                        Make your payments
                                    </h3>
                                    <p className="">
                                        Start making one time payments, or never miss a payment again with monthly auto payments.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${womanChair}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container text-center">
                        <h1 className="pt-4 mb-4 text-white">
                            A better rental experience begins with Letus
                        </h1>
                        <div className="row justify-content-md-center">
                            <div className="col-md-8 text-md-center">
                                <p className="lead text-white">
                                    {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields && this.state.leaseApplicationPage.customFields.companyName ? this.state.leaseApplicationPage.customFields.companyName : 'Your property manager'} is now accepting online payments with Propertii! Sign up today and enjoy simpler rent payments.
                                </p>
                            </div>
                        </div>
                        <ScrollLink to="signup" smooth={true} duration={1000} className="btn btn-lg btn-primary mt-3 mb-4 px-4 text-white">
                            Get Started
                        </ScrollLink>
                    </div>
                </div>

            </div>
        );
    }
}

Landing.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Landing);
