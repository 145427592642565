import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter} from 'react-router-dom';
import ScrollRestoration from './components/common/ScrollRestoration';
import App from './components/common/App';
import * as serviceWorker from './registerServiceWorker'

ReactDOM.render((
        <BrowserRouter>
            <ScrollRestoration>
                <App />
            </ScrollRestoration>
        </BrowserRouter>
), document.getElementById('root'));

serviceWorker.unregister();
