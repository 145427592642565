import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Table from "../../common/Table";
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import FieldText from "../../common/FieldText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import FieldSelect from "../../common/FieldSelect";
import Alert from "../../common/Alert";
import Spinner from "../../common/Spinner";
import FieldPhone from "../../common/FieldPhone";
import FieldPaymentProvider from "../../common/FieldPaymentProvider";
import LogoPaymentProvider from "../../common/LogoPaymentProvider";

class MerchantAccounts extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            merchantAccount: {},
            customFields: {},

            paymentProviders: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            merchantAccounts: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            searchFilter: '',

            query: {
                orderBy: 'DESC',
                orderByFields: ['accountStatus'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.match.params.companyId
                    }
                ]
            },

            validationList: [],

        };

        this.updateTable = this.updateTable.bind(this);
        this.selectRecord = this.selectRecord.bind(this);
        this.initMerchantAccount = this.initMerchantAccount.bind(this);
        this.createMerchantAccount = this.createMerchantAccount.bind(this);
        this.clearMerchantAccount = this.clearMerchantAccount.bind(this);
        this.handleChangeSearchFilter = this.handleChangeSearchFilter.bind(this);
        this.handleSubmitSearchFilter = this.handleSubmitSearchFilter.bind(this);
    }

    /**
     * Retrieve the company, and if applicable, the parent company. Afterwards, retrieve a list of company owners based
     * on the company ID in the route.
     */
    componentDidMount() {

        this.updateTable(1, 25, this.state.query);
    }

    /**
     * Initialize a new instance of a merchant account when the user clicks the 'create merchant account' button.
     */
    initMerchantAccount() {

        let merchantAccount = this.state.merchantAccount;

        axios.get(`${constants.REACT_APP_HOST_API_URL}/merchant_account/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                validationList: [],
                merchantAccount: {
                    ...response.data,
                    ...merchantAccount,
                    id: null,
                    companyId: this.props.match.params.companyId,
                    accountStatus: 'PENDING',
                }
            }));
        }).catch(error => {
            console.error(error);
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_provider/search?recordsPerPage=100&page=1`, {}, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                paymentProviders: response.data
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle submitting a new merchant account.
     *
     * @event - The event container.
     */
    createMerchantAccount(event) {

        event.preventDefault();

        let merchantAccount = this.state.merchantAccount;

        merchantAccount.customFields = this.state.customFields;

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, merchantAccount, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.merchants.created'
                    }
                }],
            });

            this.updateTable(this.state.merchantAccounts.page, this.state.merchantAccounts.recordsPerPage, this.state.query);

            $('#createMerchantAccount').modal('hide');

        }).catch(error => {

            this.handleValidation(error);

            window.scrollTo(0, 0);

        });
    }

    /**
     * Clear all merchant account fields in the creation form.
     */
    clearMerchantAccount() {

        this.setState(prevState => ({
            ...prevState,
            validationList: [],
            merchantAccount: {
                id: null,
                customFields: [],
                companyId: this.props.match.params.companyId,
                accountStatus: 'PENDING',
            },
            customFields: {},
        }));
    }

    /**
     * Handle clicking a merchant account by redirecting the user to the edit merchant account component.
     *
     * @param merchantAccountId - The ID of the merchant account clicked on.
     */
    selectRecord(merchantAccountId) {

        this.props.history.push(`/admin/companies/${this.props.match.params.companyId}/merchants/${merchantAccountId}`);
    }

    /**
     * Update the data table of merchant accounts.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    updateTable(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/merchant_account/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                merchantAccounts: response.data,
                query: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle changes made to the search filter field.
     *
     * @param event - The event container.
     */
    handleChangeSearchFilter(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            searchFilter: event.target.value
        }));

        if (event.target.value === '') {
            this.handleSubmitSearchFilter(null, event.target.value);
        }
    }

    /**
     * Handle submitting the search filter field by adjusting the merchant account search query and initiating a new
     * search.
     *
     * @param event - The event container.
     * @param searchFilter - The value within the search filter field.
     */
    handleSubmitSearchFilter(event, searchFilter) {

        if (event != null) {
            event.preventDefault();
        }

        let query = {
            orderBy: 'ASC',
            orderByFields: ['accountStatus'],
            conditionList: [
                {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'companyId',
                operator: 'EQUALS',
                fieldValue: this.props.match.params.companyId
            }]
        };

        if (searchFilter !== '') {

            query.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: searchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'name,accountNumber',
                    operator: 'MATCH',
                    fieldValue: searchFilter
                }
            );
        }

        this.setState({
            query: query
        });

        this.updateTable(1, 25, query);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The administrator companies dashboard component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner}/>

                <Alert validationList={this.state.validationList} validationType="primary"/>

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Merchant Accounts
                            </div>
                            <div className="col text-right">
                                <div data-toggle="modal" data-target="#createMerchantAccount" className="btn btn-primary btn-sm" onClick={() => this.initMerchantAccount()}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Merchant Account
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-header gotham border-top py-3 bg-secondary">
                        <form onSubmit={(event) => this.handleSubmitSearchFilter(event, this.state.searchFilter)}>
                            <div className="media">
                                <div className="media-body align-self-center mr-3">
                                    <FieldText id="searchFilter" label="Search" labelClass="d-none" fieldColumns="12"
                                               labelColums="0" placeholder="Filter by ID, name or account number..."
                                               parent={this} value={this.state.searchFilter}
                                               handleChange={this.handleChangeSearchFilter} />
                                </div>
                                <div className="align-self-center text-right">
                                    <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                        <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Table columns={{name: 'Name', accountNumber: 'Number', paymentProviderId: 'Processor', currency: 'Currency', paymentType: 'Type', accountStatus: 'Status'}}
                           columnWidths={['25%', '15%', '15%', '15%', '15%', '15%']}
                           headerClass="c-pointer"
                           data={this.state.merchantAccounts}
                           query={this.state.query}
                           sortEnabled={true}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.updateTable}>
                        <tbody>
                        {this.state.merchantAccounts.records.map((data, key) => {
                            return (
                                <tr key={key} onClick={() => this.selectRecord(data.id)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            {data.name}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.accountNumber}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            <LogoPaymentProvider paymentProviderId={data.paymentProviderId} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.currency}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            <FormattedMessage id={"enum.merchantAccount.paymentType." + data.paymentType}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-nowrap">
                                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus + ".class"})}`} />
                                            <span className="ml-1"><FormattedMessage id={"enum.merchantAccount.accountStatus." + data.accountStatus} /></span>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="createMerchantAccount" tabIndex="-1" role="dialog"
                     aria-labelledby="createMerchantAccount-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.createMerchantAccount}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="merchantAccount-label">
                                        Create Merchant Account
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="name" label="Name" model="merchantAccount" parent={this}
                                               value={this.state.merchantAccount['name']}/>

                                    <FieldText id="descriptor" label="Descriptor" model="merchantAccount" parent={this}
                                               value={this.state.merchantAccount['descriptor']}/>

                                    <FieldText id="bankAccountNumber" label="Bank Account Number"
                                               model="merchantAccount" parent={this}
                                               value={this.state.merchantAccount['bankAccountNumber']}/>

                                    <FieldText id="routingNumber" label="Routing Number" model="merchantAccount"
                                               parent={this} value={this.state.merchantAccount['routingNumber']}/>

                                    <FieldPaymentProvider id="paymentProviderId" label="Processor"
                                                          model="merchantAccount" parent={this}
                                                          value={this.state.merchantAccount['paymentProviderId']} />

                                    <FieldSelect id="paymentType" label="Payment Type" model="merchantAccount"
                                                 parent={this} value={this.state.merchantAccount['paymentType']}>
                                        <option value="" disabled>Select a payment type...</option>
                                        <option value="TYPE_CREDIT_CARD">Credit Card</option>
                                        <option value="TYPE_BANK_ACCOUNT">Bank Account</option>
                                        <option value="TYPE_CASH">Cash</option>
                                    </FieldSelect>

                                    <FieldSelect id="currency" label="Currency" model="merchantAccount" parent={this}
                                                 value={this.state.merchantAccount['currency']}>
                                        <option value="" disabled>Select a currency...</option>
                                        <option value="CAD">CAD</option>
                                        <option value="USD">USD</option>
                                    </FieldSelect>

                                    <FieldSelect id="yearlyVolumeRange" label="Yearly Volume" model="merchantAccount"
                                                 parent={this} value={this.state.merchantAccount['yearlyVolumeRange']}>
                                        <option value="" disabled>Select a yearly volume range...</option>
                                        <option value="0-50K">Low ($0-$50,000)</option>
                                        <option value="50K-100K">Medium ($50,000-$100,000)</option>
                                        <option value="100K-250K">High ($100,000-$250,000)</option>
                                        <option value="250K+">Very High ($250,000+)</option>
                                    </FieldSelect>

                                    <FieldText id="averageTransAmount" label="Average Transaction"
                                               model="merchantAccount" type="number"
                                               min="0.00" max="999999.99" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" parent={this}
                                               value={this.state.merchantAccount['averageTransAmount']}/>

                                    <FieldText id="disbursementDelay" label="Disbursement Delay" model="merchantAccount"
                                               type="number" max="99" parent={this} value={this.state.merchantAccount['disbursementDelay']}/>

                                    {this.state.merchantAccount['customFields'] &&
                                    <React.Fragment>

                                        <FieldPhone id="phone" label="Phone" model="customFields"
                                                    parent={this} value={this.state.customFields['phone']} />

                                        <FieldText id="url" label="Company URL" model="customFields" parent={this}
                                                   value={this.state.customFields['url']}/>

                                    </React.Fragment>
                                    }

                                    {this.state.merchantAccount['customFields'] &&
                                    <React.Fragment>

                                        {this.state.merchantAccount.paymentProviderId === 'VANTIV' &&
                                        <React.Fragment>

                                            <FieldText id="subMerchantId" label="Sub-Merchant ID" model="customFields"
                                                       parent={this} value={this.state.customFields['subMerchantId']}
                                                       disabled={this.state.merchantAccount['accountStatus'] === 'CANCELLED' ? 'disabled' : ''}/>

                                        </React.Fragment>
                                        }

                                        {this.state.merchantAccount.paymentProviderId === 'PAYNEARME' &&
                                        <React.Fragment>

                                            <FieldText id="secretKey" label="Secret Key" model="customFields"
                                                       parent={this} value={this.state.customFields['secretKey']}
                                                       disabled={this.state.merchantAccount['accountStatus'] === 'CANCELLED' ? 'disabled' : ''}/>

                                        </React.Fragment>
                                        }

                                    </React.Fragment>
                                    }

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#createMerchantAccount").modal("hide")}>Close</button>
                                            </div>
                                            <div className="float-right">
                                                <div className="btn btn-outline-primary btn-lg ml-2" onClick={() => this.clearMerchantAccount()}>Clear Fields</div>
                                                <button type="submit" className="btn btn-primary btn-lg ml-2">Save</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

MerchantAccounts.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(MerchantAccounts);