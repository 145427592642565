import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import DocumentList from "../../common/DocumentList";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";

class Documents extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - A document management interface for a property lease under a manager.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Documents" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <DocumentList token={this.props.token} parent={this} recordType="PROPERTY_LEASE" />

                </div>

            </div>
        )
    };
}

Documents.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Documents);