import Propertii from "./Propertii";
import {FormattedMessage, FormattedNumber, injectIntl, intlShape} from "react-intl";
import axios from "axios";
import * as constants from "../../util/constants";
import React from "react";
import Table from "./Table";
import $ from "jquery";
import ReceiptPaymentTransaction from "./ReceiptPaymentTransaction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FieldText from "./FieldText";
import ButtonClose from "./ButtonClose";
import Alert from "./Alert";
import Spinner from "./Spinner";
import FieldDate from "./FieldDate";
import 'moment-timezone';
import Modal from "./Modal";
import {Link} from "react-router-dom";
import FieldSelect from "./FieldSelect";
import FieldTextarea from "./FieldTextarea";
import Moment from "react-moment";
import ReceiptScheduledPayment from "./ReceiptScheduledPayment";

class Transactions extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            selectedTab: '',

            reversedAmount: 0,
            reversedFeeAmount: 0,

            paymentTransactionSearchFilter: '',
            scheduledPaymentSearchFilter: '',
            transactionProcessorFilter: null,
            transactionTypeFilter: null,
            transactionStatusFilter: null,
            recurrenceTypeFilter: null,
            processingStatusFilter: null,
            padScheduleFilter: null,

            startDate: null,
            endDate: null,

            padSchedule: false,

            customChargeCodes: [],
            charges: [{}],
            paymentDate: '',
            settlementDate: '',

            paymentTransaction: {},
            scheduledPayment: {},
            parentPaymentTransaction: {},

            paymentTransactionList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            scheduledPaymentList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            paymentTransactionReversalList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            paymentTransactionQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [],
                joins: {
                    pl: {
                        targetRecordType: 'TYPE_PROPERTY_LEASE',
                        joinField: 'billingAccountId',
                        alias: 'pl',
                        returnFields: ['propertyId', 'companyId', 'unit']
                    },
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'propertyId',
                        alias: 'p',
                        returnFields: ['propertyName', 'street1']
                    },
                    c: {
                        targetRecordType: 'TYPE_CUSTOMER',
                        joinField: 'userId',
                        alias: 'c',
                        returnFields: ['firstName', 'lastName', 'email']
                    },
                    co: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'companyId',
                        alias: 'co',
                        returnFields: ['name', 'chargeCodes', 'integrationId', 'landlordId']
                    },
                    pc: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'co.parentId',
                        alias: 'pc',
                        returnFields: ['name', 'chargeCodes', 'integrationId', 'landlordId']
                    }
                }
            },

            scheduledPaymentQuery: {
                orderBy: 'DESC',
                orderByFields: ['scheduledDate'],
                conditionList: [],
                joins: {
                    pl: {
                        targetRecordType: 'TYPE_PROPERTY_LEASE',
                        joinField: 'billingAccountId',
                        alias: 'pl',
                        returnFields: ['id', 'propertyId', 'unit', 'accountNumber']
                    },
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'pl.propertyId',
                        alias: 'p',
                        returnFields: ['id', 'propertyName', 'street1', 'propertyIdentifier']
                    },
                    c: {
                        targetRecordType: 'TYPE_CUSTOMER',
                        joinField: 'userId',
                        alias: 'c',
                        returnFields: ['firstName', 'lastName']
                    },
                    co: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'pl.companyId',
                        alias: 'co',
                        returnFields: ['id', 'name', 'chargeCodes', 'integrationId', 'landlordId']
                    },
                    pc: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'pl.companyId',
                        alias: 'pc',
                        returnFields: ['id', 'name', 'chargeCodes', 'integrationId', 'landlordId']
                    },
                    cc: {
                        targetRecordType: 'TYPE_CREDIT_CARD',
                        joinField: 'paymentMethodId',
                        alias: 'cc',
                        returnFields: ['brand', 'last4']
                    },
                    ba: {
                        targetRecordType: 'TYPE_BANK_ACCOUNT',
                        joinField: 'paymentMethodId',
                        alias: 'ba',
                        returnFields: ['last4']
                    }
                }
            },

            validationList: [],

        };

        this.selectTab = this.selectTab.bind(this);

        this.searchPaymentTransactions = this.searchPaymentTransactions.bind(this);
        this.searchScheduledPayments = this.searchScheduledPayments.bind(this);
        this.searchPaymentTransactionReversals = this.searchPaymentTransactionReversals.bind(this);

        this.filterPaymentTransactions = this.filterPaymentTransactions.bind(this);
        this.filterScheduledPayments = this.filterScheduledPayments.bind(this);
        this.clearFilters = this.clearFilters.bind(this);

        this.viewPaymentTransaction = this.viewPaymentTransaction.bind(this);
        this.viewScheduledPayment = this.viewScheduledPayment.bind(this);

        this.savePaymentTransactionSettlementDate = this.savePaymentTransactionSettlementDate.bind(this);
        this.savePaymentTransactionStatus = this.savePaymentTransactionStatus.bind(this);

        this.stopScheduledPayment = this.stopScheduledPayment.bind(this);

        this.refundPaymentTransaction = this.refundPaymentTransaction.bind(this);
        this.pushPaymentTransaction = this.pushPaymentTransaction.bind(this);
        this.manuallyPushPaymentTransaction = this.manuallyPushPaymentTransaction.bind(this);
    }

    /**
     * Populate the list of transactions on mounting of the component.
     */
    componentDidMount() {

        if(this.props.history && this.props.history.location.state && this.props.history.location.state.paymentTransaction) {
            this.selectTab('PAYMENT_TRANSACTIONS');
            this.viewPaymentTransaction(this.props.history.location.state.paymentTransaction);
        }

        if(this.props.history && this.props.history.location.state && this.props.history.location.state.scheduledPayment) {
            this.selectTab('SCHEDULED_PAYMENTS');
            this.viewScheduledPayment(this.props.history.location.state.scheduledPayment);
        }

        this.selectTab('PAYMENT_TRANSACTIONS');
    }

    /**
     * Select a tab from the list of navigation tabs.
     *
     * @param tabId - The ID of the tab selected.
     */
    selectTab(tabId) {

        let scheduledPaymentsModal = $('#scheduled-payments');
        let paymentTransactionsModal = $('#payment-transactions');

        switch(tabId) {

            case "PAYMENT_TRANSACTIONS":

                this.searchPaymentTransactions(1, 25, this.state.paymentTransactionQuery);
                scheduledPaymentsModal.tab('dispose');
                paymentTransactionsModal.tab('show');

                break;

            case "SCHEDULED_PAYMENTS":

                this.searchScheduledPayments(1, 25, this.state.scheduledPaymentQuery);
                paymentTransactionsModal.tab('dispose');
                scheduledPaymentsModal.tab('show');

                break;

            default:

                this.searchPaymentTransactions(1, 25, this.state.paymentTransactionQuery);
                scheduledPaymentsModal.tab('dispose');
                paymentTransactionsModal.tab('show');

                break;

        }
    }

    /**
     * Handle submitting the search filter field for payment transactions.
     *
     * @param event - The event container.
     */
    filterPaymentTransactions(event) {

        if (event != null) {
            event.preventDefault();
        }

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let paymentTransactionQuery = this.state.paymentTransactionQuery;

        paymentTransactionQuery.conditionList = [];

        if(this.state.paymentTransactionSearchFilter !== '') {
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'id',
                    fieldValue: this.state.paymentTransactionSearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'confirmationId',
                    fieldValue: this.state.paymentTransactionSearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    operator: 'MATCH',
                    fieldName: 'p.propertyName,p.street1,pl.unit,c.firstName,c.lastName,c.email',
                    fieldValue: this.state.paymentTransactionSearchFilter
                }
            );
        }

        if(this.state.transactionProcessorFilter !== null && this.state.transactionProcessorFilter !== '') {
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'paymentProviderId',
                    operator: 'EQUALS',
                    fieldValue: this.state.transactionProcessorFilter
                },
            );
        }

        if(this.state.transactionTypeFilter !== null && this.state.transactionTypeFilter !== '') {
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'transactionType',
                    operator: 'EQUALS',
                    fieldValue: this.state.transactionTypeFilter
                },
            );
        }

        if(this.state.transactionStatusFilter !== null && this.state.transactionStatusFilter !== '') {
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: this.state.transactionStatusFilter
                },
            );
        }

        if(this.state.startDate !== null) {
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'createDate',
                    operator: 'GREATER_THAN_OR_EQUALS',
                    fieldValue: this.state.startDate
                },
            );
        }

        if(this.state.endDate !== null) {
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'createDate',
                    operator: 'LESS_THAN_OR_EQUALS',
                    fieldValue: this.state.endDate
                },
            );
        }

        this.setState({
            paymentTransactionQuery: paymentTransactionQuery
        });

        this.searchPaymentTransactions(1, 25, paymentTransactionQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter queries.
     */
    clearFilters() {

        this.setState({
            paymentTransactionSearchFilter: '',
            scheduledPaymentSearchFilter: '',
            transactionProcessorFilter: null,
            transactionTypeFilter: null,
            transactionStatusFilter: null,
            recurrenceTypeFilter: null,
            processingStatusFilter: null,
            padScheduleFilter: null,
            startDate: null,
            endDate: null,
        }, () => {

            if(this.props.displayAdvanced) {
                this.filterScheduledPayments();
            }

            this.filterPaymentTransactions();

        });
    }

    /**
     * Handle submitting the search filter field for scheduled payments.
     *
     * @param event - The event container.
     */
    filterScheduledPayments(event) {

        if (event != null) {
            event.preventDefault();
        }

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let scheduledPaymentQuery = this.state.scheduledPaymentQuery;

        scheduledPaymentQuery.conditionList = [];

        if(this.state.scheduledPaymentSearchFilter === '') {

            if(this.props.propertyId) {
                scheduledPaymentQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'pl.propertyId',
                        fieldValue: this.props.propertyId
                    }
                );
            }

            if(this.props.companyId) {
                scheduledPaymentQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'pl.companyId',
                        fieldValue: this.props.companyId
                    }
                );
            }

            if(this.props.userId) {
                scheduledPaymentQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'userId',
                        fieldValue: this.props.userId
                    }
                );
            }

            if(this.props.billingAccountId) {
                scheduledPaymentQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'billingAccountId',
                        fieldValue: this.props.billingAccountId
                    }
                );
            }
        }

        if(this.state.scheduledPaymentSearchFilter !== '') {
            scheduledPaymentQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'id',
                    fieldValue: this.state.scheduledPaymentSearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    operator: 'MATCH',
                    fieldName: 'pl.unit,p.street1,c.firstName,c.lastName',
                    fieldValue: this.state.scheduledPaymentSearchFilter
                }
            );
        }

        if(this.state.processingStatusFilter !== null && this.state.processingStatusFilter !== '') {
            scheduledPaymentQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: this.state.processingStatusFilter
                },
            );
        }

        if(this.state.padScheduleFilter !== null && this.state.padScheduleFilter !== '') {
            scheduledPaymentQuery.conditionList.push(
                {
                    type: 'BOOLEAN',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'padSchedule',
                    operator: 'EQUALS',
                    fieldValue: this.state.padScheduleFilter === 'true'
                },
            );
        }

        if(this.state.startDate !== null) {
            scheduledPaymentQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'createDate',
                    operator: 'GREATER_THAN_OR_EQUALS',
                    fieldValue: this.state.startDate
                },
            );
        }

        if(this.state.endDate !== null) {
            scheduledPaymentQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'createDate',
                    operator: 'LESS_THAN_OR_EQUALS',
                    fieldValue: this.state.endDate
                },
            );
        }

        this.setState({
            scheduledPaymentQuery: scheduledPaymentQuery
        });

        this.searchScheduledPayments(1, 25, scheduledPaymentQuery);
    }

    /**
     * Search for a list of payment transactions.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchPaymentTransactions(page, recordsPerPage, query) {

        this.setState(prevState => ({
            ...prevState,
            spinner: true,
            selectedTab: 'PAYMENT_TRANSACTIONS',
        }));

        let conditionList = query.conditionList;

        // Search transactions by property ID, if provided
        if(this.props.propertyId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'propertyId',
                    fieldValue: this.props.propertyId
                }
            );
        }

        // Search transactions by company ID, if provided
        if(this.props.companyId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'companyId',
                    fieldValue: this.props.companyId
                }
            );
        }

        // Search transactions by user ID, if provided
        if(this.props.userId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'userId',
                    fieldValue: this.props.userId
                }
            );
        }

        // Search transactions by billing account ID, if provided
        if(this.props.billingAccountId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'billingAccountId',
                    fieldValue: this.props.billingAccountId
                }
            );
        }

        if(this.props.userType !== 'TYPE_ADMIN') {
            conditionList.push(
                {
                    type: 'NUMBER',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'NOT_EQUALS',
                    fieldName: 'amount',
                    fieldValue: 0
                }
            );
        }
        
        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                scheduledPaymentList: {
                    page: '',
                    recordsPerPage: '',
                    totalPages: '',
                    totalRecordCount: '',
                    records: [
                        {}
                    ]
                },
                paymentTransactionList: {
                    ...response.data,
                    emptyUnfilteredList: this.state.paymentTransactionSearchFilter === '' && response.data.records.length === 0
                },
                paymentTransactionQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Search for a list of scheduled payments.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchScheduledPayments(page, recordsPerPage, query) {

        this.setState(prevState => ({
            ...prevState,
            spinner: true,
            selectedTab: 'SCHEDULED_PAYMENTS',
        }));

        let conditionList = query.conditionList;

        // Search transactions by property ID, if provided
        if(this.props.propertyId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'pl.propertyId',
                    fieldValue: this.props.propertyId
                },
            );
        }

        // Search transactions by company ID, if provided
        if(this.props.companyId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'pl.companyId',
                    fieldValue: this.props.companyId
                }
            );
        }

        // Search transactions by user ID, if provided
        if(this.props.userId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'userId',
                    fieldValue: this.props.userId
                }
            );
        }

        // Search transactions by billing account ID, if provided
        if(this.props.billingAccountId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'billingAccountId',
                    fieldValue: this.props.billingAccountId
                },
            );
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/scheduled_payment/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let scheduledPayments = response.data;

            scheduledPayments.records.forEach((scheduledPayment, key) => {

                let amount = 0;

                scheduledPayment.charges.forEach((data, key) => {
                    amount += data.amount;
                });

                scheduledPayments.records[key].amount = amount;
            });

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                paymentTransactionList: {
                    page: '',
                    recordsPerPage: '',
                    totalPages: '',
                    totalRecordCount: '',
                    records: [
                        {}
                    ]
                },
                scheduledPaymentList: {
                    ...response.data,
                    emptyUnfilteredList: this.state.scheduledPaymentSearchFilter === '' && response.data.records.length === 0
                },
                scheduledPaymentQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));


        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Search for a list of reversal payment transactions related to the transaction currently being viewed.
     */
    searchPaymentTransactionReversals() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let paymentTransactionReversalQuery = {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'parentId',
                    fieldValue: this.state.paymentTransaction.id
                }
            ],
            joins: this.state.paymentTransactionQuery.joins
        };

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search`, {
            orderBy: paymentTransactionReversalQuery.orderBy,
            orderByFields: paymentTransactionReversalQuery.orderByFields,
            conditionList: paymentTransactionReversalQuery.conditionList,
            joins: paymentTransactionReversalQuery.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                paymentTransactionReversalList: response.data,
            }));

            $('#receipt-payment-transaction').modal('hide');
            $('#payment-transaction-reversals').modal('show');

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * View the detailed information of a payment transaction. If the transaction has a parent ID, search for the parent
     * transaction in order to display both transactions in a side-by-side view in the receipt modal. If the joined
     * company of the payment transaction has an integration ID, get the transaction data from the integration service.
     *
     * @param paymentTransaction - The payment transaction to view the details of.
     */
    viewPaymentTransaction(paymentTransaction) {

        if(paymentTransaction.parentId) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search?recordsPerPage=1&page=1`, {
                orderBy: this.state.paymentTransactionQuery.orderBy,
                orderByFields: this.state.paymentTransactionQuery.orderByFields,
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'id',
                        fieldValue: paymentTransaction.parentId
                    }
                ],
                joins: this.state.paymentTransactionQuery.joins
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    parentPaymentTransaction: response.data.records[0],
                    validationList: [],
                }));

            }).catch(error => {
                console.error(error);
            });
        }

        if(paymentTransaction.joins && ((paymentTransaction.joins.co && paymentTransaction.joins.co.integrationId) || (paymentTransaction.joins.pc && paymentTransaction.joins.pc.integrationId)) && paymentTransaction.status === 'COMPLETE' && (paymentTransaction.transactionType === 'PAYMENT' || paymentTransaction.transactionType === 'NSF')) {
            axios.get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/transaction/${paymentTransaction.id}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                paymentTransaction.integration = response.data;

                this.setState(prevState => ({
                    ...prevState,
                    paymentTransaction: {
                        ...paymentTransaction,
                        integration: response.data
                    }
                }));

            }).catch(error => {
                console.error(error);
            });
        }

        if(paymentTransaction.feeProfileId) {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/fee_profile/${paymentTransaction.feeProfileId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                paymentTransaction.feeProfile = response.data;

                this.setState(prevState => ({
                    ...prevState,
                    paymentTransaction: {
                        ...paymentTransaction,
                        feeProfile: response.data
                    }
                }));

            }).catch(error => {
                console.error(error);
            });
        }

        this.setState(prevState => ({
            ...prevState,
            paymentTransaction: {
                ...paymentTransaction
            }
        }));

        $('#payment-transaction-reversals').modal('hide');
        $('#receipt-payment-transaction').modal('show');
    }

    /**
     * View the detailed information of a scheduled payment. Initializes the scheduled payment modal.
     *
     * @param scheduledPayment - The scheduled payment to view.
     */
    viewScheduledPayment(scheduledPayment) {

        this.setState(prevState => ({
            ...prevState,
            paymentTransaction: {},
            recurringSchedule: {},
            scheduledPayment: scheduledPayment,
            validationList: [],
        }));

        $('#receipt-scheduled-payment').modal('show');
    }

    /**
     * Save an existing payment transaction's settlement date. Used by admins to manually set a settlement date for a
     * payment transaction if one does not exist.
     *
     * @param event - The event container.
     */
    savePaymentTransactionSettlementDate(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let paymentTransaction = this.state.paymentTransaction;

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/${paymentTransaction.id}/update`, {
            settlementDate: this.state.settlementDate,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.transactions.payment.settled'
                    }
                }],
            }));

            $('#settle-payment-transaction').modal('hide');

            this.searchPaymentTransactions(this.state.paymentTransactionList.page, this.state.paymentTransactionList.recordsPerPage, this.state.paymentTransactionQuery);

        }).catch(error => {

            this.handleValidation(error);

        });

        window.scrollTo(0, 0);
    }

    /**
     * Save an existing payment transaction's status. Closes all modals and updates the payment transaction list with
     * the newly updated status. If the payment transaction is being marked as complete, a confirmation ID must be
     * provided. If the payment transaction is being marked as failed, an error must be provided.
     *
     * @param event - The event container.
     * @param status - The new status of the payment transaction.
     */
    savePaymentTransactionStatus(event, status) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let paymentTransaction = this.state.paymentTransaction;

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/${paymentTransaction.id}/update`, {
            status: status,
            confirmationId: this.state.paymentTransaction.confirmationId,
            processedDate: this.state.paymentTransaction.processedDate,
            error: null
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: status === 'COMPLETE' ? 'common.transactions.payment.completed' : 'common.transactions.payment.failed'
                    },
                    values: {
                        firstName: paymentTransaction.joins.c.firstName,
                        lastName: paymentTransaction.joins.c.lastName
                    }
                }],
            }));

            $('#complete-payment-transaction').modal('hide');
            $('#fail-payment-transaction').modal('hide');
            $('#receipt-payment-transaction').modal('hide');

            this.searchPaymentTransactions(this.state.paymentTransactionList.page, this.state.paymentTransactionList.recordsPerPage, this.state.paymentTransactionQuery);

        }).catch(error => {

            this.handleValidation(error);

        });

        window.scrollTo(0, 0);
    }

    /**
     * Stop a scheduled payment by deleting the scheduled payment record.
     *
     * @param scheduledPaymentId - The ID of the scheduled payment.
     */
    stopScheduledPayment(scheduledPaymentId) {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/scheduled_payment/${scheduledPaymentId}/delete`,  {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.transactions.scheduled.stopped'
                    },
                    values: {
                        firstName: this.state.scheduledPayment.joins.c.firstName,
                        lastName: this.state.scheduledPayment.joins.c.lastName
                    }
                }],
            });

            this.searchScheduledPayments(this.state.scheduledPaymentList.page, this.state.scheduledPaymentList.recordsPerPage, this.state.scheduledPaymentQuery);

            $('#stop-scheduled-payment').modal('hide');

        }).catch(error => {
            console.error(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Refund a transaction.
     *
     * @param event - The event container.
     */
    refundPaymentTransaction(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/processrefund`, {
            originalTransactionId: this.state.paymentTransaction.id,
            refundAmount: this.state.reversedAmount,
            refundFeeAmount: this.state.reversedFeeAmount,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.status === 'FAILED') {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'common.transactions.refund.failed'
                        },
                        values: {
                            error: response.data.error
                        }
                    }],
                }));
            }

            if(response.data.status !== 'FAILED') {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'common.transactions.payment.refunded'
                        }
                    }],
                }));

                $('#refund-payment-transaction').modal('hide');

                this.searchPaymentTransactions(this.state.paymentTransactionList.page, this.state.paymentTransactionList.recordsPerPage, this.state.paymentTransactionQuery);

            }

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Push a transaction to the integration service.
     */
    pushPaymentTransaction() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/${this.state.paymentTransaction.id}/push`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

            $('#push-payment-transaction').modal('hide');

            this.viewPaymentTransaction(this.state.paymentTransaction);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * In some scenarios, a regular transaction push fails because there is no way that the client's ERP system can
     * accept the data according to their business rules. If this is the case, the transaction can be marked as manually
     * pushed.
     */
    manuallyPushPaymentTransaction() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.patch(`${constants.REACT_APP_HOST_INTEGRATION_URL}/transaction/${this.state.paymentTransaction.id}/update`, {
            pushStatus: 'MANUAL_ENTRY',
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.transactions.payment.manual'
                    }
                }],
            }));

            $('#manually-push-payment-transaction').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The landlord dashboard component.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip();
        });

        const {formatMessage} = this.props.intl;

        let paymentTransactionColumnLabels = {};
        let scheduledPaymentColumnLabels = {};

        let columnWidths = [];

        if(this.props.propertyId) {
            paymentTransactionColumnLabels = {createDate: 'Date', amount: 'Amount', 'pl.unit': 'Unit', 'c.firstName': 'Tenant', status: 'Status'};
            scheduledPaymentColumnLabels = {scheduledDate: 'Scheduled Date', charges: 'Amount', 'pl.unit': 'Unit', 'c.firstName': 'Tenant', status: 'Status'};
            columnWidths = ['20%', '20%', '20%', '20%', '20%'];
        }

        if(this.props.companyId) {
            paymentTransactionColumnLabels = {createDate: 'Date', amount: 'Amount', 'pl.unit': 'Unit', 'p.street1': 'Property', 'c.firstName': 'Tenant', status: 'Status'};
            scheduledPaymentColumnLabels = {scheduledDate: 'Scheduled Date', charges: 'Amount', 'pl.unit': 'Unit', 'p.street1': 'Property', 'c.firstName': 'Tenant', status: 'Status'};
            columnWidths = ['16%', '16%', '16%', '16%', '16%', '16%'];
        }

        if(this.props.userId || this.props.billingAccountId) {
            paymentTransactionColumnLabels = {createDate: 'Date', amount: 'Amount', 'pl.unit': 'Unit', 'p.street1': 'Property', status: 'Status'};
            scheduledPaymentColumnLabels = {scheduledDate: 'Scheduled Date', charges: 'Amount', 'pl.unit': 'Unit', 'p.street1': 'Property', status: 'Status'};
            columnWidths = ['20%', '20%', '20%', '20%', '20%'];
        }

        if(this.props.global) {
            paymentTransactionColumnLabels = {createDate: 'Date', amount: 'Amount', 'co.name': 'Company', 'p.street1': 'Property', 'c.firstName': 'Tenant', status: 'Status'};
            scheduledPaymentColumnLabels = {scheduledDate: 'Scheduled Date', charges: 'Amount', 'co.name': 'Company', 'p.street1': 'Property', 'c.firstName': 'Tenant', status: 'Status'};
            columnWidths = ['16%', '16%', '16%', '16%', '16%', '16%'];
        }

        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <div className="card accordion" id="navigation">

                    <div className={`card-header ${this.props.displayAdvanced ? 'pb-0' : ''}`}>
                        <div className="row align-items-center">

                            <div className="col">

                                {!this.props.displayAdvanced &&
                                <span className="">Transactions</span>
                                }

                                {this.props.displayAdvanced &&
                                <ul className="nav nav-tabs border-bottom-0">
                                    <li className="nav-item">
                                        <a className={`nav-link ${this.state.selectedTab === 'PAYMENT_TRANSACTIONS' ? 'active' : ''}`} id="payment-transactions-tab" data-toggle="tab" href="#payment-transactions" role="tab" aria-controls="payment-transactions" aria-selected="true" onClick={() => this.selectTab('PAYMENT_TRANSACTIONS')}>
                                            Transactions
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${this.state.selectedTab === 'SCHEDULED_PAYMENTS' ? 'active' : ''}`} id="scheduled-payments-tab" data-toggle="tab" href="#scheduled-payments" role="tab" aria-controls="scheduled-payments" aria-selected="true" onClick={() => this.selectTab('SCHEDULED_PAYMENTS')}>
                                            Scheduled Payments
                                        </a>
                                    </li>
                                </ul>
                                }

                            </div>

                            {this.props.makePayment &&
                            <div className="col text-right">
                                <Link to={this.props.makePaymentPath} className="btn btn-primary btn-sm mb-0">
                                    <FontAwesomeIcon icon={['fas', 'usd-square']} className="fa-fw" /> Make a Payment
                                </Link>
                            </div>
                            }

                        </div>
                    </div>

                    <div className={this.props.displayAdvanced ? 'tab-content' : ''} id="tabs">

                        <div className={`${this.props.displayAdvanced ? 'tab-pane fade-show' : ''} ${this.state.selectedTab === 'PAYMENT_TRANSACTIONS' ? 'active' : ''}`} id="payment-transactions" role={this.props.displayAdvanced ? 'tabpanel' : ''} aria-labelledby="payment-transactions-tab">

                            <div className="card-header gotham border-top py-3 bg-secondary">
                                <form onSubmit={this.filterPaymentTransactions} autoComplete="off">
                                    <div className="media">
                                        <div className="media-body align-self-center mr-3">
                                            <FieldText id="paymentTransactionSearchFilter" label="Search" labelClass="d-none"
                                                       fieldColumns="12"
                                                       labelColums="0" placeholder="Filter by name, phone, email, unit, property, or confirmation ID..."
                                                       parent={this}
                                                       value={this.state.paymentTransactionSearchFilter} />
                                        </div>
                                        {this.props.global &&
                                        <React.Fragment>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldSelect id="transactionProcessorFilter" labelColumns="0"
                                                             fieldColumns="12" parent={this}
                                                             value={this.state.transactionProcessorFilter}>
                                                    <option value="">All Processors</option>
                                                    <option value="PAYSAFE">Paysafe</option>
                                                    <option value="PAYSAFE_SPLIT_PAY">Paysafe Split-Pay</option>
                                                    <option value="VANTIV">Worldpay</option>
                                                    <option value="GEOSWIFT">Geoswift</option>
                                                    <option value="PAYNEARME">PayNearMe</option>
                                                    <option value="LOADHUB">Loadhub</option>
                                                    <option value="RENTMOOLA_PP">Letus</option>
                                                </FieldSelect>
                                            </div>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldSelect id="transactionTypeFilter" labelColumns="0"
                                                             fieldColumns="12" parent={this}
                                                             value={this.state.transactionTypeFilter}>
                                                    <option value="">All Types</option>
                                                    <option value="PAYMENT">Payment</option>
                                                    <option value="NSF">NSF</option>
                                                    <option value="CHARGEBACK">Chargeback</option>
                                                    <option value="CHARGEBACK_REVERSAL">Reverse Chargeback</option>
                                                    <option value="REFUND">Refund</option>
                                                    <option value="RETURN">Return</option>
                                                </FieldSelect>
                                            </div>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldSelect id="transactionStatusFilter" labelColumns="0"
                                                             fieldColumns="12" parent={this}
                                                             value={this.state.transactionStatusFilter}>
                                                    <option value="">All Statuses</option>
                                                    <option value="PENDING">Pending</option>
                                                    <option value="INPROCESS">Processing</option>
                                                    <option value="CANCELLED">Cancelled</option>
                                                    <option value="COMPLETE">Complete</option>
                                                    <option value="FAILED">Failed</option>
                                                    <option value="TIMEDOUT">Timed Out</option>
                                                </FieldSelect>
                                            </div>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldDate id="startDate" placeholder="Start Date" labelColumns="0"
                                                           fieldColumns="12" parent={this} value={this.state.startDate}
                                                           selectsStart={true} startDate={this.state.startDate}
                                                           endDate={this.state.endDate}/>
                                            </div>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldDate id="endDate" placeholder="End Date" labelColumns="0"
                                                           fieldColumns="12" parent={this} value={this.state.endDate}
                                                           selectsEnd={this} startDate={this.state.startDate}
                                                           endDate={this.state.endDate} minDate={this.state.startDate}/>
                                            </div>
                                        </React.Fragment>
                                        }
                                        <div className="align-self-center text-right">
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                    <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                                </button>
                                                <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                    <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            {this.state.paymentTransactionList.emptyUnfilteredList &&
                            <div className="card-body bg-secondary py-5 border-top">
                                <div className="row justify-content-center">
                                    <div className="col-10">
                                        <div className="text-center text-muted">
                                            <div className="fa-stack fa-2x mb-2">
                                                <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                <FontAwesomeIcon icon={['far', 'credit-card']} className="fa-stack-1x fa-inverse" />
                                            </div>
                                        </div>
                                        <div className="text-center text-muted">
                                            <small>
                                                Payments made by your tenants will appear here.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {!this.state.paymentTransactionList.emptyUnfilteredList &&
                            <Table tableClass="table-bordered table-hover table-responsive-sm border-bottom border-dark"
                                   columns={paymentTransactionColumnLabels}
                                   columnWidths={columnWidths}
                                   headerClass="c-pointer"
                                   data={this.state.paymentTransactionList}
                                   query={this.state.paymentTransactionQuery}
                                   sortEnabled={true}
                                   recordsEnabled={true}
                                   paginationEnabled={true}
                                   parent={this}
                                   updateFunction={this.searchPaymentTransactions}>
                                <tbody>
                                {this.state.paymentTransactionList.records.map((data, key) => {
                                    return (
                                        <tr key={key} onClick={() => this.viewPaymentTransaction(data)} className="c-pointer">
                                            <td>
                                                <div className="">
                                                    <Moment format="MMM DD, YYYY">
                                                        {data.createDate}
                                                    </Moment>
                                                </div>
                                            </td>
                                            <td>
                                                {this.props.displayFeeAmount &&
                                                <React.Fragment>
                                                    {(data.amount != null && data.feeAmount == null) &&
                                                    <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                        <FormattedNumber value={data.amount} style={`currency`} currency={data.currency}/>
                                                    </div>
                                                    }
                                                    {(data.amount != null && data.feeAmount != null) &&
                                                    <div className={(data.amount + data.feeAmount) < 0 ? 'text-danger' : ''}>
                                                        <FormattedNumber value={data.amount + data.feeAmount} style={`currency`} currency={data.currency}/>
                                                    </div>
                                                    }
                                                </React.Fragment>
                                                }
                                                {!this.props.displayFeeAmount &&
                                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                    <FormattedNumber value={data.amount} style={`currency`} currency={data.currency}/>
                                                </div>
                                                }
                                            </td>
                                            {this.props.global &&
                                            <td>
                                                {data.joins && data.joins.co &&
                                                <div className="">
                                                    {data.joins.co.name}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {!this.props.global &&
                                            <td>
                                                {data.joins && data.joins.pl &&
                                                <div className="">
                                                    {data.joins.pl.unit}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {this.props.propertyId &&
                                            <td>
                                                {data.joins && data.joins.c &&
                                                <div className="">
                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {!this.props.propertyId &&
                                            <td>
                                                {data.joins && data.joins.p &&
                                                <div className="">
                                                    {data.joins.p.street1}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {this.props.companyId &&
                                            <td>
                                                {data.joins && data.joins.c &&
                                                <div className="">
                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {this.props.global &&
                                            <td>
                                                {data.joins && data.joins.c &&
                                                <div className="">
                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            <td>
                                                {data.status &&
                                                <div className="text-nowrap">
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + data.status + ".class"})}`}/>
                                                    <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + data.status}/></span>
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                            }

                        </div>

                        {this.props.displayAdvanced &&
                        <div className={`tab-pane fade-show ${this.state.selectedTab === 'SCHEDULED_PAYMENTS' ? 'active' : ''}`} id="scheduled-payments" role="tabpanel" aria-labelledby="scheduled-payments-tab">

                            <div className="card-header gotham border-top py-3 bg-secondary">
                                <form onSubmit={this.filterScheduledPayments} autoComplete="off">
                                    <div className="media">
                                        <div className="media-body align-self-center mr-3">
                                            <FieldText id="scheduledPaymentSearchFilter" label="Search"
                                                       labelClass="d-none"
                                                       fieldColumns="12"
                                                       labelColums="0"
                                                       placeholder="Filter by name, phone, email, unit, or property...." parent={this}
                                                       value={this.state.scheduledPaymentSearchFilter}/>
                                        </div>
                                        {this.props.global &&
                                        <React.Fragment>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldSelect id="processingStatusFilter" labelColumns="0"
                                                             fieldColumns="12" parent={this}
                                                             value={this.state.processingStatusFilter}>
                                                    <option value="">All Statuses</option>
                                                    <option value="PENDING">Pending</option>
                                                    <option value="INPROCESS">Processing</option>
                                                    <option value="PROCESSED">Processed</option>
                                                    <option value="FAILED">Failed</option>
                                                </FieldSelect>
                                            </div>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldSelect id="padScheduleFilter" labelColumns="0"
                                                             fieldColumns="12" parent={this}
                                                             value={this.state.padScheduleFilter}>
                                                    <option value="">All Processing</option>
                                                    <option value="false">Regular Processing</option>
                                                    <option value="true">Pre-Authorized Debit Processing</option>
                                                </FieldSelect>
                                            </div>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldDate id="startDate" placeholder="Start Date" labelColumns="0"
                                                           fieldColumns="12" parent={this} value={this.state.startDate}
                                                           selectsStart={true} startDate={this.state.startDate}
                                                           endDate={this.state.endDate}/>
                                            </div>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldDate id="endDate" placeholder="End Date" labelColumns="0"
                                                           fieldColumns="12" parent={this} value={this.state.endDate}
                                                           selectsEnd={this} startDate={this.state.startDate}
                                                           endDate={this.state.endDate} minDate={this.state.startDate}/>
                                            </div>
                                        </React.Fragment>
                                        }
                                        <div className="align-self-center text-right">
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                    <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                                </button>
                                                <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                    <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            {this.state.scheduledPaymentList.emptyUnfilteredList &&
                            <div className="card-body bg-secondary py-5 border-top">
                                <div className="row justify-content-center">
                                    <div className="col-10">
                                        <div className="text-center text-muted">
                                            <div className="fa-stack fa-2x mb-2">
                                                <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x"/>
                                                <FontAwesomeIcon icon={['far', 'clock']} className="fa-stack-1x fa-inverse"/>
                                            </div>
                                        </div>
                                        <div className="text-center text-muted">
                                            <small>
                                                If your tenants have one time payments scheduled for a future date, the details will be listed here.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {!this.state.scheduledPaymentList.emptyUnfilteredList &&
                            <Table columns={scheduledPaymentColumnLabels}
                                   columnWidths={columnWidths}
                                   headerClass="c-pointer"
                                   data={this.state.scheduledPaymentList}
                                   query={this.state.scheduledPaymentQuery}
                                   sortEnabled={true}
                                   recordsEnabled={true}
                                   paginationEnabled={true}
                                   parent={this}
                                   updateFunction={this.searchScheduledPayments}>
                                <tbody>
                                {this.state.scheduledPaymentList.records.map((data, key) => {
                                    return (
                                        <tr key={key} onClick={() => this.viewScheduledPayment(data)} className="c-pointer">
                                            <td>
                                                <div className="">
                                                    <Moment format="MMM DD, YYYY" tz="UTC">
                                                        {data.scheduledDate}
                                                    </Moment>
                                                </div>
                                            </td>
                                            <td>
                                                {data.amount &&
                                                <div className="">
                                                    <FormattedNumber value={data.amount} style={`currency`} currency="USD"/>
                                                </div>
                                                }
                                            </td>
                                            {this.props.global &&
                                            <td>
                                                {data.joins && data.joins.co &&
                                                <div className="">
                                                    {data.joins.co.name}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {!this.props.global &&
                                            <td>
                                                {data.joins && data.joins.pl &&
                                                <div className="">
                                                    {data.joins.pl.unit}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {this.props.propertyId &&
                                            <td>
                                                {data.joins && data.joins.c &&
                                                <div className="">
                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {!this.props.propertyId &&
                                            <td>
                                                {data.joins && data.joins.p &&
                                                <div className="">
                                                    {data.joins.p.street1}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {this.props.companyId &&
                                            <td>
                                                {data.joins && data.joins.c &&
                                                <div className="">
                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {this.props.global &&
                                            <td>
                                                {data.joins && data.joins.c &&
                                                <div className="">
                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            <td>
                                                {data.status &&
                                                <div className="text-nowrap">
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + data.status + ".class"})}`}/>
                                                    <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + data.status}/></span>
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                            }

                        </div>
                        }

                    </div>

                </div>

                <ReceiptPaymentTransaction history={this.props.history} userType={this.props.userType} displayFeeAmount={this.props.displayFeeAmount} displayAdvanced={this.props.displayAdvanced} paymentTransaction={this.state.paymentTransaction} parentPaymentTransaction={this.state.parentPaymentTransaction}>

                    <div className="float-left">
                        <ButtonClose />
                    </div>

                    <div className="float-right">

                        <div className="dropdown">

                            <div className="btn btn-primary btn-lg dropdown-toggle" role="button" id="payment-transaction-actions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Actions
                            </div>

                            <div className="dropdown-menu" aria-labelledby="payment-transaction-actions">

                                {(this.props.displayAdvanced && this.state.paymentTransaction.status === 'COMPLETE' && this.state.paymentTransaction.transactionType === 'PAYMENT') &&
                                <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#refund-payment-transaction').modal('show');}}>
                                    <FontAwesomeIcon icon={['fas', 'undo']} className="fa-fw" /> Refund Transaction
                                </div>
                                }

                                {(this.props.userType !== 'TYPE_CUSTOMER' && this.props.userType !== 'TYPE_LANDLORD' && this.state.paymentTransaction.status === 'COMPLETE' && this.state.paymentTransaction.joins && ((this.state.paymentTransaction.joins.co && this.state.paymentTransaction.joins.co.integrationId) || (this.state.paymentTransaction.joins.pc && this.state.paymentTransaction.joins.pc.integrationId)) && this.state.paymentTransaction.integration != null) &&
                                <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#push-payment-transaction').modal('show');}}>
                                    <FontAwesomeIcon icon={['fas', 'forward']} className="fa-fw" /> Push Transaction
                                </div>
                                }

                                {(this.props.userType !== 'TYPE_CUSTOMER' && this.props.userType !== 'TYPE_LANDLORD' && this.state.paymentTransaction.status === 'COMPLETE' && this.state.paymentTransaction.joins && ((this.state.paymentTransaction.joins.co && this.state.paymentTransaction.joins.co.integrationId) || (this.state.paymentTransaction.joins.pc && this.state.paymentTransaction.joins.pc.integrationId)) && this.state.paymentTransaction.integration != null && (this.state.paymentTransaction.integration.pushStatus  === 'FAILED' || this.state.paymentTransaction.integration.pushStatus === 'TIMEDOUT')) &&
                                <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#manually-push-payment-transaction').modal('show');}}>
                                    <FontAwesomeIcon icon={['fas', 'keyboard']} className="fa-fw" /> Mark as Manually Entered
                                </div>
                                }

                                {(this.props.displayAdvanced && (this.state.paymentTransaction.reversedAmount > 0 || this.state.paymentTransaction.reversedFeeAmount > 0)) &&
                                <div className="dropdown-item c-pointer" onClick={() => this.searchPaymentTransactionReversals()}>
                                    <FontAwesomeIcon icon={['fas', 'history']} className="fa-fw" /> Reversal History
                                </div>
                                }

                                {(this.props.displayAdvanced && !this.state.paymentTransaction.settlementDate) &&
                                <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#settle-payment-transaction').modal('show');}}>
                                    <FontAwesomeIcon icon={['fas', 'calendar-check']} className="fa-fw" /> Mark as Settled
                                </div>
                                }

                                {(this.props.displayAdvanced && this.state.paymentTransaction.status === 'TIMEDOUT') &&
                                <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#complete-payment-transaction').modal('show');}}>
                                    <FontAwesomeIcon icon={['fas', 'check']} className="fa-fw" /> Mark as Completed
                                </div>
                                }

                                {(this.props.displayAdvanced && this.state.paymentTransaction.status === 'TIMEDOUT') &&
                                <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#fail-payment-transaction').modal('show');}}>
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw" /> Mark as Failed
                                </div>
                                }

                                <div className="dropdown-item c-pointer" onClick={() => window.print()}>
                                    <FontAwesomeIcon icon={['fas', 'print']} className="fa-fw" /> Print Receipt
                                </div>

                            </div>
                        </div>

                    </div>

                </ReceiptPaymentTransaction>

                {this.props.displayAdvanced &&
                <ReceiptScheduledPayment userType={this.props.userType} displayAdvanced={this.props.displayAdvanced} scheduledPayment={this.state.scheduledPayment} hideFundsDisclaimer={true}>

                    <div className="float-left">
                        <ButtonClose/>
                    </div>

                </ReceiptScheduledPayment>
                }

                <div className="modal fade" id="refund-payment-transaction" tabIndex="-1" role="dialog" aria-labelledby="refund-payment-transaction-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.refundPaymentTransaction}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="refund-payment-transaction-label">
                                        Refund Transaction
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Enter the amount you would like to refund from the transaction total and the transaction fee total.
                                    </p>
                                </div>

                                <div className="modal-body mb-2">

                                    <Alert validationList={this.state.validationList} validationType="danger" customClasses="mb-3" />

                                    {(this.state.paymentTransaction.amount != null && this.state.paymentTransaction.reversedAmount != null) &&
                                    <FieldText id="reversedAmount" label="Refund Amount" type="number" prependIconType="fas" prependIconName="dollar-sign"
                                               fieldColumns="12" labelColumns="12" fieldClass="form-control-sm mb-0"
                                               help={`The maximum refundable amount is $${(this.state.paymentTransaction.amount - this.state.paymentTransaction.reversedAmount).toFixed(2)}.`}
                                               placeholder="0.00" labelClass="col-form-label-sm" step="0.01" parent={this} value={this.state.reversedAmount}/>
                                    }

                                    {(this.state.paymentTransaction.feeAmount != null && this.state.paymentTransaction.reversedFeeAmount != null) &&
                                    <FieldText id="reversedFeeAmount" label="Transaction Fee Refund Amount" type="number" prependIconType="fas" prependIconName="dollar-sign"
                                               fieldColumns="12" labelColumns="12" fieldClass="form-control-sm mb-0"
                                               help={`The maximum refundable transaction fee amount is $${(this.state.paymentTransaction.feeAmount - this.state.paymentTransaction.reversedFeeAmount).toFixed(2)}.`}
                                               placeholder="0.00" labelClass="col-form-label-sm" step="0.01" parent={this} value={this.state.reversedFeeAmount}/>
                                    }

                                </div>

                                <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <div className="btn btn-outline-primary btn-lg" onClick={() => {$('#refund-payment-transaction').modal('hide'); $('#receipt-payment-transaction').modal('show');}}>
                                                    Back
                                                </div>
                                            </div>
                                            <div className="float-right">
                                                <button type="submit" className="btn btn-primary btn-lg">
                                                    Refund
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="payment-transaction-reversals" tabIndex="-1" role="dialog" aria-labelledby="payment-transaction-reversals-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="payment-transaction-reversals-label">
                                    Reversal History
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                </button>
                            </div>

                            <div className="modal-body bg-secondary">
                                <p className="mb-0">
                                    Select a reversal from the list below to view it's corresponding transaction receipt.
                                </p>
                            </div>

                            <div className="modal-body modal-body-table">

                                <table className="table table-hover mb-0">
                                    <thead>
                                    <tr className="small">
                                        <th>
                                            Date
                                        </th>
                                        <th>
                                            Charges
                                        </th>
                                        <th>
                                            Transaction Fee
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.paymentTransactionReversalList.records.map((data, key) => {
                                        return(
                                            <tr key={key} className="small c-pointer" onClick={() => this.viewPaymentTransaction(data)}>
                                                <td>
                                                    <div className="">
                                                        <Moment format="MMM DD, YYYY">
                                                            {data.createDate}
                                                        </Moment>
                                                    </div>
                                                </td>
                                                <td>
                                                    {data.amount != null &&
                                                    <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                        <FormattedNumber value={data.amount} style={`currency`} currency={data.currency}/>
                                                    </div>
                                                    }
                                                </td>
                                                <td>
                                                    {data.feeAmount != null &&
                                                    <div className={data.feeAmount < 0 ? 'text-danger' : ''}>
                                                        <FormattedNumber value={data.feeAmount} style={`currency`} currency={data.currency}/>
                                                    </div>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>

                            </div>

                            <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                                <div className="row">
                                    <div className="col">
                                        <div className="float-left">
                                            <div className="btn btn-outline-primary btn-lg" onClick={() => {$('#payment-transaction-reversals').modal('hide'); $('#receipt-payment-transaction').modal('show');}}>
                                                Back
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="settle-payment-transaction" tabIndex="-1" role="dialog" aria-labelledby="settle-payment-transaction-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form onSubmit={this.savePaymentTransactionSettlementDate}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="settle-payment-transaction-label">
                                        Mark Transaction as Settled
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        To mark this transaction as settled, enter a settlement date below.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <FieldDate id="settlementDate" label="Settlement Date" placeholder="MM/DD/YYYY" required={true} labelColumns="0" fieldColumns="12" fieldClass="form-control-md" labelClass="col-form-label-md d-none" parent={this} value={this.state.settlementDate} />

                                </div>

                                <div className="modal-footer d-block bg-secondary">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#settle-payment-transaction").modal("hide"); $("#receipt-payment-transaction").modal("show")}}>
                                                <FormattedMessage id="button.back" />
                                            </button>
                                        </div>
                                        <div className="col-8 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">
                                                Mark as Settled
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="complete-payment-transaction" tabIndex="-1" role="dialog" aria-labelledby="complete-payment-transaction-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form onSubmit={(e) => this.savePaymentTransactionStatus(e, 'COMPLETE')}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="complete-payment-transaction-label">
                                        Mark Transaction as Completed
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        To mark this transaction as completed, please provide the confirmation ID from the payment processor below.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <FieldText id="confirmationId" label="Confirmation ID" placeholder="Confirmation ID" required={true} labelColumns="0" fieldColumns="12" fieldClass="form-control-md" labelClass="col-form-label-md d-none" model="paymentTransaction" parent={this} value={this.state.paymentTransaction['confirmationId']} />

                                    <FieldDate id="processedDate" label="Date Processed" placeholder="Date Processed" required={true} labelColumns="0" fieldColumns="12" fieldClass="form-control-md" labelClass="col-form-label-md d-none" model="paymentTransaction" parent={this} value={this.state.paymentTransaction['processedDate']} />

                                </div>

                                <div className="modal-footer d-block bg-secondary">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#complete-payment-transaction").modal("hide"); $("#receipt-payment-transaction").modal("show")}}>
                                                <FormattedMessage id="button.back" />
                                            </button>
                                        </div>
                                        <div className="col-8 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">
                                                Mark as Completed
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="fail-payment-transaction" tabIndex="-1" role="dialog" aria-labelledby="fail-payment-transaction-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form onSubmit={(e) => this.savePaymentTransactionStatus(e, 'FAILED')}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="fail-payment-transaction-label">
                                        Mark Transaction as Failed
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        To mark this transaction as failed, please provide the error from the payment processor below.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <FieldTextarea id="error" label="Error" model="paymentTransaction" rows="8" required={true} labelColumns="0" fieldColumns="12" fieldClass="form-control-sm" labelClass="col-form-label-sm d-none" maxLength="200" parent={this} value={this.state.paymentTransaction.error} />

                                </div>

                                <div className="modal-footer d-block bg-secondary">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#fail-payment-transaction").modal("hide"); $("#receipt-payment-transaction").modal("show")}}>
                                                <FormattedMessage id="button.back" />
                                            </button>
                                        </div>
                                        <div className="col-8 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">
                                                Mark as Failed
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <Modal id="push-payment-transaction" theme="primary" iconType="fas" iconName="question-circle" title="Push Transaction"
                       body="Are you sure you want to push this transaction to the integrated system?">
                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => {$("#receipt-payment-transaction").modal("show")}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button className="btn btn-primary btn-lg" data-dismiss="modal" onClick={() => this.pushPaymentTransaction(this.state.paymentTransaction.id)}>
                        Push Transaction
                    </button>
                </Modal>

                <Modal id="manually-push-payment-transaction" theme="primary" iconType="fas" iconName="question-circle" title="Mark Transaction as Manually Entered"
                       body="Are you sure you want to mark this transaction's push status as manually entered? Performing this action does not push the transaction itself.">
                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => {$("#receipt-payment-transaction").modal("show")}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button className="btn btn-primary btn-lg" data-dismiss="modal" onClick={() => this.manuallyPushPaymentTransaction(this.state.paymentTransaction.id)}>
                        Mark as Manually Entered
                    </button>
                </Modal>

            </React.Fragment>

        )
    }
}

Transactions.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Transactions);