import React from 'react';
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import {FormattedMessage, FormattedNumber, injectIntl, intlShape} from "react-intl";
import axios from "axios";
import * as constants from "../../../util/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NavListItem from "../../common/NavListItem";
import Breadcrumb from "../../common/Breadcrumb";
import Moment from "react-moment";

class Dashboard extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.getProcessingAlerts = this.getProcessingAlerts.bind(this);
        this.selectPaymentTransaction = this.selectPaymentTransaction.bind(this);
        this.selectScheduledPayment = this.selectScheduledPayment.bind(this);
        this.selectFundsTransfer = this.selectFundsTransfer.bind(this);
    }

    /**
     * Populate the list of transaction statistic totals on mounting of the component.
     */
    componentDidMount() {

        this.setState(prevState => ({
            spinner: true,
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/admin/stats/totals`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            Object.entries(response.data).forEach(([processingAlertType, count]) => {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    [processingAlertType]: {
                        ...prevState[processingAlertType],
                        records: [],
                        totalRecordCount: count,
                        recordsShown: 9
                    },
                }));
            });

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Get a list of all processing alerts of o a particular type, only if the records haven't already been loaded.
     *
     * @param processingAlertType - The processing alert type to fetch records for.
     */
    getProcessingAlerts(processingAlertType) {

        if(this.state[processingAlertType].records.length === 0 && this.state[processingAlertType].totalRecordCount !== 0) {

            this.setState({
                spinner: true,
            });

            axios.get(`${constants.REACT_APP_HOST_API_URL}/admin/stats/${processingAlertType}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                let processingAlertRecords = response.data[processingAlertType];

                if(processingAlertType === 'FAILED_SCHEDULED_PAYMENTS') {

                    let processingAlertRecords = response.data.FAILED_SCHEDULED_PAYMENTS;

                    processingAlertRecords.forEach((failedScheduledPayment, key) => {

                        let amount = 0;

                        failedScheduledPayment.charges.forEach((data, key) => {
                            amount += data.amount;
                        });

                        processingAlertRecords[key].amount = amount;
                    });

                }

                this.setState(prevState => ({
                    spinner: false,
                    [processingAlertType]: {
                        ...prevState[processingAlertType],
                        records: processingAlertRecords,
                    },
                }));

            }).catch(error => {
                this.handleValidation(error);
            });

        }
    }

    /**
     * Select a payment transaction record type from the time out transactions, chargebacks, chargeback reversals,
     * outstanding settlements, or outstanding disbursements lists. Redirects the user to the Transactions page with
     * the transaction receipt pre-populated.
     *
     * @param paymentTransaction - The payment transaction record selected.
     */
    selectPaymentTransaction(paymentTransaction) {

        this.props.history.push({
            pathname: '/admin/transactions',
            state: {
                paymentTransaction: paymentTransaction
            }
        });
    }

    /**
     * Select a scheduled payment record type from the failed scheduled payments list. Redirects the user to the
     * Transactions page with the scheduled payment receipt pre-populated.
     *
     * @param scheduledPayment - The scheduled payment record selected.
     */
    selectScheduledPayment(scheduledPayment) {

        this.props.history.push({
            pathname: '/admin/transactions',
            state: {
                scheduledPayment: scheduledPayment
            }
        });
    }

    /**
     * Select a funds transfer record type from the failed EFTs or outstanding EFT settlements list. Redirects the user
     * to the EFT Management page with the EFT details pre-populated.
     *
     * @param fundsTransfer - The funds transfer record selected.
     */
    selectFundsTransfer(fundsTransfer) {

        this.props.history.push({
            pathname: '/admin/system/efts',
            state: {
                fundsTransferId: fundsTransfer.id
            }
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The administrator dashboard component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return (
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPage="Dashboard" />

                    <div className="accordion" id="processing-alerts">

                        {this.state.TIMED_OUT_TRANSACTIONS &&
                        <div className="card mb-0">

                            <div className="card-header">
                                Processing Alerts
                            </div>

                            <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#timed-out-transactions" aria-expanded="true" aria-controls="timed-out-transactions" onClick={() => this.getProcessingAlerts('TIMED_OUT_TRANSACTIONS')}>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <span className={`badge badge-pill badge-${this.state.TIMED_OUT_TRANSACTIONS.totalRecordCount === 0 ? 'dark' : 'danger'} va-b mr-1`}>{this.state.TIMED_OUT_TRANSACTIONS.totalRecordCount}</span> Timed Out Transactions <small className="text-muted text-uppercase">/ All Time</small>
                                    </div>
                                    <div className="col">
                                        <div className="float-right">
                                            <i className="fas"> </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="timed-out-transactions" className="collapse" data-parent="#processing-alerts">
                                {this.state.TIMED_OUT_TRANSACTIONS.totalRecordCount > 0 &&
                                <div className="card-body card-body-table">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th width="16%">Date</th>
                                            <th width="16%">Amount</th>
                                            <th width="16%">Property</th>
                                            <th width="16%">Tenant</th>
                                            <th width="16%">Processor</th>
                                            <th width="16%">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.TIMED_OUT_TRANSACTIONS.records &&
                                        <React.Fragment>

                                            {this.state.TIMED_OUT_TRANSACTIONS.records.map((data, key) => {

                                                if (key <= this.state.TIMED_OUT_TRANSACTIONS.recordsShown) {
                                                    return (
                                                        <tr key={key} onClick={() => this.selectPaymentTransaction(data)} className="c-pointer">
                                                            <td>
                                                                <div className="">
                                                                    <Moment format="MMM DD, YYYY">
                                                                        {data.createDate}
                                                                    </Moment>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                                    {data.amount &&
                                                                    <FormattedNumber value={(data.amount + data.feeAmount)} style={`currency`} currency={data.currency}/>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {(data.joins && data.joins.p && data.joins.pl) &&
                                                                <div className="">
                                                                    {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}{data.joins.p.street1}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {data.joins && data.joins.c &&
                                                                <div className="">
                                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="">
                                                                    <FormattedMessage id={`enum.paymentProviderId.${data.paymentProviderId}`} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {data.status &&
                                                                <div className="text-nowrap">
                                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + data.status + ".class"})}`}/>
                                                                    <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + data.status}/></span>
                                                                </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }

                                                return null;

                                            })}

                                            {this.state.TIMED_OUT_TRANSACTIONS.recordsShown < this.state.TIMED_OUT_TRANSACTIONS.totalRecordCount &&
                                            <tr className="c-pointer">
                                                <td colSpan="6" className="text-center" onClick={() => this.setState(prevState => ({TIMED_OUT_TRANSACTIONS: {...prevState.TIMED_OUT_TRANSACTIONS, recordsShown: prevState.TIMED_OUT_TRANSACTIONS.recordsShown + 10}}))}>
                                                    <div className="btn-link">
                                                        Load more records <FontAwesomeIcon icon={['fas', 'caret-down']} className="fa-fw"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            }

                                        </React.Fragment>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                }
                                {this.state.TIMED_OUT_TRANSACTIONS.totalRecordCount === 0 &&
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-muted">
                                                <FontAwesomeIcon icon={['fal', 'smile-beam']} className="fa-fw mb-4" size="5x"/>
                                            </div>
                                            <p className="text-center text-muted mb-0">
                                                <small>
                                                    Awesome! We have no timed out transactions. Treat yourself to a beer!
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        }

                        {this.state.CHARGE_BACKS &&
                        <div className="card mb-0">
                            <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#charge-backs" aria-expanded="true" aria-controls="charge-backs" onClick={() => this.getProcessingAlerts('CHARGE_BACKS')}>
                                <div className="row">
                                    <div className="col">
                                        <span className={`badge badge-pill badge-${this.state.CHARGE_BACKS.totalRecordCount === 0 ? 'dark' : 'warning'} va-b mr-1`}>{this.state.CHARGE_BACKS.totalRecordCount}</span> Chargebacks <small className="text-muted text-uppercase">/ Last 7 Days</small>
                                    </div>
                                    <div className="col">
                                        <div className="float-right">
                                            <i className="fas"> </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="charge-backs" className="collapse" data-parent="#processing-alerts">
                                {this.state.CHARGE_BACKS.totalRecordCount > 0 &&
                                <div className="card-body card-body-table">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th width="16%">Date</th>
                                            <th width="16%">Amount</th>
                                            <th width="16%">Property</th>
                                            <th width="16%">Tenant</th>
                                            <th width="16%">Processor</th>
                                            <th width="16%">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.CHARGE_BACKS.records &&
                                        <React.Fragment>

                                            {this.state.CHARGE_BACKS.records.map((data, key) => {

                                                if (key <= this.state.CHARGE_BACKS.recordsShown) {
                                                    return (
                                                        <tr key={key} onClick={() => this.selectPaymentTransaction(data)} className="c-pointer">
                                                            <td>
                                                                <div className="">
                                                                    <Moment format="MMM DD, YYYY">
                                                                        {data.createDate}
                                                                    </Moment>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                                    {data.amount &&
                                                                    <FormattedNumber value={(data.amount + data.feeAmount)} style={`currency`} currency={data.currency}/>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {(data.joins && data.joins.p && data.joins.pl) &&
                                                                <div className="">
                                                                    {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}{data.joins.p.street1}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {data.joins && data.joins.c &&
                                                                <div className="">
                                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="">
                                                                    <FormattedMessage id={`enum.paymentProviderId.${data.paymentProviderId}`} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {data.status &&
                                                                <div className="text-nowrap">
                                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + data.status + ".class"})}`}/>
                                                                    <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + data.status}/></span>
                                                                </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }

                                                return null;

                                            })}

                                            {this.state.CHARGE_BACKS.recordsShown < this.state.CHARGE_BACKS.totalRecordCount &&
                                            <tr className="c-pointer">
                                                <td colSpan="6" className="text-center" onClick={() => this.setState(prevState => ({CHARGE_BACKS: {...prevState.CHARGE_BACKS, recordsShown: prevState.CHARGE_BACKS.recordsShown + 10}}))}>
                                                    <div className="btn-link">
                                                        Load more records <FontAwesomeIcon icon={['fas', 'caret-down']} className="fa-fw"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            }

                                        </React.Fragment>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                }
                                {this.state.CHARGE_BACKS.totalRecordCount === 0 &&
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-muted">
                                                <FontAwesomeIcon icon={['fal', 'smile-beam']} className="fa-fw mb-4" size="5x"/>
                                            </div>
                                            <p className="text-center text-muted mb-0">
                                                <small>
                                                    Nice! No chargebacks in the last 7 days. Next round's on me!
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        }

                        {this.state.CHARGE_BACK_REVERSALS &&
                        <div className="card mb-0">
                            <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#charge-back-reversals" aria-expanded="true" aria-controls="charge-back-reversals" onClick={() => this.getProcessingAlerts('CHARGE_BACK_REVERSALS')}>
                                <div className="row">
                                    <div className="col">
                                        <span className={`badge badge-pill badge-${this.state.CHARGE_BACK_REVERSALS.totalRecordCount === 0 ? 'dark' : 'warning'} va-b mr-1`}>{this.state.CHARGE_BACK_REVERSALS.totalRecordCount}</span> Chargeback Reversals <small className="text-muted text-uppercase">/ Last 7 Days</small>
                                    </div>
                                    <div className="col">
                                        <div className="float-right">
                                            <i className="fas"> </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="charge-back-reversals" className="collapse" data-parent="#processing-alerts">
                                {this.state.CHARGE_BACK_REVERSALS.totalRecordCount > 0 &&
                                <div className="card-body card-body-table">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th width="16%">Date</th>
                                            <th width="16%">Amount</th>
                                            <th width="16%">Property</th>
                                            <th width="16%">Tenant</th>
                                            <th width="16%">Processor</th>
                                            <th width="16%">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.CHARGE_BACK_REVERSALS.records &&
                                        <React.Fragment>

                                            {this.state.CHARGE_BACK_REVERSALS.records.map((data, key) => {

                                                if (key <= this.state.CHARGE_BACK_REVERSALS.recordsShown) {
                                                    return (
                                                        <tr key={key} onClick={() => this.selectPaymentTransaction(data)} className="c-pointer">
                                                            <td>
                                                                <div className="">
                                                                    <Moment format="MMM DD, YYYY">
                                                                        {data.createDate}
                                                                    </Moment>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                                    {data.amount &&
                                                                    <FormattedNumber value={(data.amount + data.feeAmount)} style={`currency`} currency={data.currency}/>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {(data.joins && data.joins.p && data.joins.pl) &&
                                                                <div className="">
                                                                    {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}{data.joins.p.street1}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {data.joins && data.joins.c &&
                                                                <div className="">
                                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="">
                                                                    <FormattedMessage id={`enum.paymentProviderId.${data.paymentProviderId}`} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {data.status &&
                                                                <div className="text-nowrap">
                                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + data.status + ".class"})}`}/>
                                                                    <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + data.status}/></span>
                                                                </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }

                                                return null;

                                            })}

                                            {this.state.CHARGE_BACK_REVERSALS.recordsShown < this.state.CHARGE_BACK_REVERSALS.totalRecordCount &&
                                            <tr className="c-pointer">
                                                <td colSpan="6" className="text-center" onClick={() => this.setState(prevState => ({CHARGE_BACK_REVERSALS: {...prevState.CHARGE_BACK_REVERSALS, recordsShown: prevState.CHARGE_BACK_REVERSALS.recordsShown + 10}}))}>
                                                    <div className="btn-link">
                                                        Load more records <FontAwesomeIcon icon={['fas', 'caret-down']} className="fa-fw"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            }

                                        </React.Fragment>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                }
                                {this.state.CHARGE_BACK_REVERSALS.totalRecordCount === 0 &&
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-muted">
                                                <FontAwesomeIcon icon={['fal', 'smile-beam']} className="fa-fw mb-4" size="5x"/>
                                            </div>
                                            <p className="text-center text-muted mb-0">
                                                <small>
                                                    Tremendous! No chargeback reversals in the last 7 days. Breathe a sigh of relief!
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        }

                        {this.state.NOT_SETTLED_TRANSACTIONS &&
                        <div className="card mb-0">
                            <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#not-settled-transactions" aria-expanded="true" aria-controls="not-settled-transactions" onClick={() => this.getProcessingAlerts('NOT_SETTLED_TRANSACTIONS')}>
                                <div className="row">
                                    <div className="col">
                                        <span className={`badge badge-pill badge-${this.state.NOT_SETTLED_TRANSACTIONS.totalRecordCount === 0 ? 'dark' : 'danger'} va-b mr-1`}>{this.state.NOT_SETTLED_TRANSACTIONS.totalRecordCount}</span> Outstanding Settlements <small className="text-muted text-uppercase">/ Over 5 Business Days</small>
                                    </div>
                                    <div className="col">
                                        <div className="float-right">
                                            <i className="fas"> </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="not-settled-transactions" className="collapse" data-parent="#processing-alerts">
                                {this.state.NOT_SETTLED_TRANSACTIONS.totalRecordCount > 0 &&
                                <div className="card-body card-body-table">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th width="16%">Date</th>
                                            <th width="16%">Amount</th>
                                            <th width="16%">Property</th>
                                            <th width="16%">Tenant</th>
                                            <th width="16%">Processor</th>
                                            <th width="16%">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.NOT_SETTLED_TRANSACTIONS.records &&
                                        <React.Fragment>

                                            {this.state.NOT_SETTLED_TRANSACTIONS.records.map((data, key) => {

                                                if (key <= this.state.NOT_SETTLED_TRANSACTIONS.recordsShown) {
                                                    return (
                                                        <tr key={key} onClick={() => this.selectPaymentTransaction(data)} className="c-pointer">
                                                            <td>
                                                                <div className="">
                                                                    <Moment format="MMM DD, YYYY">
                                                                        {data.createDate}
                                                                    </Moment>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                                    {data.amount &&
                                                                    <FormattedNumber value={(data.amount + data.feeAmount)} style={`currency`} currency={data.currency}/>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {(data.joins && data.joins.p && data.joins.pl) &&
                                                                <div className="">
                                                                    {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}{data.joins.p.street1}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {data.joins && data.joins.c &&
                                                                <div className="">
                                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="">
                                                                    <FormattedMessage id={`enum.paymentProviderId.${data.paymentProviderId}`} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {data.status &&
                                                                <div className="text-nowrap">
                                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + data.status + ".class"})}`}/>
                                                                    <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + data.status}/></span>
                                                                </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }

                                                return null;

                                            })}

                                            {this.state.NOT_SETTLED_TRANSACTIONS.recordsShown < this.state.NOT_SETTLED_TRANSACTIONS.totalRecordCount &&
                                            <tr className="c-pointer">
                                                <td colSpan="6" className="text-center" onClick={() => this.setState(prevState => ({NOT_SETTLED_TRANSACTIONS: {...prevState.NOT_SETTLED_TRANSACTIONS, recordsShown: prevState.NOT_SETTLED_TRANSACTIONS.recordsShown + 10}}))}>
                                                    <div className="btn-link">
                                                        Load more records <FontAwesomeIcon icon={['fas', 'caret-down']} className="fa-fw"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            }

                                        </React.Fragment>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                }
                                {this.state.NOT_SETTLED_TRANSACTIONS.totalRecordCount === 0 &&
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-muted">
                                                <FontAwesomeIcon icon={['fal', 'smile-beam']} className="fa-fw mb-4" size="5x"/>
                                            </div>
                                            <p className="text-center text-muted mb-0">
                                                <small>
                                                    Woot! We have no outstanding settlements over 5 business days. Time to party!
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        }

                        {this.state.NOT_DISBURSED_TRANSACTIONS &&
                        <div className="card mb-0">
                            <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#not-disbursed-transactions" aria-expanded="true" aria-controls="not-disbursed-transactions" onClick={() => this.getProcessingAlerts('NOT_DISBURSED_TRANSACTIONS')}>
                                <div className="row">
                                    <div className="col">
                                        <span className={`badge badge-pill badge-${this.state.NOT_DISBURSED_TRANSACTIONS.totalRecordCount === 0 ? 'dark' : 'danger'} va-b mr-1`}>{this.state.NOT_DISBURSED_TRANSACTIONS.totalRecordCount}</span> Outstanding Disbursements <small className="text-muted text-uppercase">/ Over 5 Business Days</small>
                                    </div>
                                    <div className="col">
                                        <div className="float-right">
                                            <i className="fas"> </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="not-disbursed-transactions" className="collapse" data-parent="#processing-alerts">
                                {this.state.NOT_DISBURSED_TRANSACTIONS.totalRecordCount > 0 &&
                                <div className="card-body card-body-table">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th width="16%">Date</th>
                                            <th width="16%">Amount</th>
                                            <th width="16%">Property</th>
                                            <th width="16%">Tenant</th>
                                            <th width="16%">Processor</th>
                                            <th width="16%">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.NOT_DISBURSED_TRANSACTIONS.records &&
                                        <React.Fragment>

                                            {this.state.NOT_DISBURSED_TRANSACTIONS.records.map((data, key) => {

                                                if (key <= this.state.NOT_DISBURSED_TRANSACTIONS.recordsShown) {
                                                    return (
                                                        <tr key={key} onClick={() => this.selectPaymentTransaction(data)} className="c-pointer">
                                                            <td>
                                                                <div className="">
                                                                    <Moment format="MMM DD, YYYY">
                                                                        {data.createDate}
                                                                    </Moment>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                                    {data.amount &&
                                                                    <FormattedNumber value={(data.amount + data.feeAmount)} style={`currency`} currency={data.currency}/>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {(data.joins && data.joins.p && data.joins.pl) &&
                                                                <div className="">
                                                                    {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}{data.joins.p.street1}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {data.joins && data.joins.c &&
                                                                <div className="">
                                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="">
                                                                    <FormattedMessage id={`enum.paymentProviderId.${data.paymentProviderId}`} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {data.status &&
                                                                <div className="text-nowrap">
                                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + data.status + ".class"})}`}/>
                                                                    <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + data.status}/></span>
                                                                </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }

                                                return null;

                                            })}

                                            {this.state.NOT_DISBURSED_TRANSACTIONS.recordsShown < this.state.NOT_DISBURSED_TRANSACTIONS.totalRecordCount &&
                                            <tr className="c-pointer">
                                                <td colSpan="6" className="text-center" onClick={() => this.setState(prevState => ({NOT_DISBURSED_TRANSACTIONS: {...prevState.NOT_DISBURSED_TRANSACTIONS, recordsShown: prevState.NOT_DISBURSED_TRANSACTIONS.recordsShown + 10}}))}>
                                                    <div className="btn-link">
                                                        Load more records <FontAwesomeIcon icon={['fas', 'caret-down']} className="fa-fw"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            }

                                        </React.Fragment>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                }
                                {this.state.NOT_DISBURSED_TRANSACTIONS.totalRecordCount === 0 &&
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-muted">
                                                <FontAwesomeIcon icon={['fal', 'smile-beam']} className="fa-fw mb-4" size="5x"/>
                                            </div>
                                            <p className="text-center text-muted mb-0">
                                                <small>
                                                    Outstanding! We have no outstanding disbursements. Treat yourself to an ice cream bar!
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        }

                        {this.state.FAILED_SCHEDULED_PAYMENTS &&
                        <div className="card mb-0">
                            <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#failed-scheduled-payments" aria-expanded="true" aria-controls="failed-scheduled-payments" onClick={() => this.getProcessingAlerts('FAILED_SCHEDULED_PAYMENTS')}>
                                <div className="row">
                                    <div className="col">
                                        <span className={`badge badge-pill badge-${this.state.FAILED_SCHEDULED_PAYMENTS.totalRecordCount === 0 ? 'dark' : 'danger'} va-b mr-1`}>{this.state.FAILED_SCHEDULED_PAYMENTS.totalRecordCount}</span> Failed Scheduled Payments <small className="text-muted text-uppercase">/ Last 2 Business Days</small>
                                    </div>
                                    <div className="col">
                                        <div className="float-right">
                                            <i className="fas"> </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="failed-scheduled-payments" className="collapse" data-parent="#processing-alerts">
                                {this.state.FAILED_SCHEDULED_PAYMENTS.totalRecordCount > 0 &&
                                <div className="card-body card-body-table">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th width="16%">Date</th>
                                            <th width="16%">Amount</th>
                                            <th width="16%">Property</th>
                                            <th width="16%">Tenant</th>
                                            <th width="16%">Processor</th>
                                            <th width="16%">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.FAILED_SCHEDULED_PAYMENTS.records &&
                                        <React.Fragment>

                                            {this.state.FAILED_SCHEDULED_PAYMENTS.records.map((data, key) => {

                                                if (key <= this.state.FAILED_SCHEDULED_PAYMENTS.recordsShown) {
                                                    return (
                                                        <tr key={key} onClick={() => this.selectScheduledPayment(data)} className="c-pointer">
                                                            <td>
                                                                <div className="">
                                                                    <Moment format="MMM DD, YYYY">
                                                                        {data.createDate}
                                                                    </Moment>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                                    {data.amount &&
                                                                    <FormattedNumber value={data.amount} style={`currency`} currency="USD"/>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {(data.joins && data.joins.p && data.joins.pl) &&
                                                                <div className="">
                                                                    {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}{data.joins.p.street1}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {data.joins && data.joins.c &&
                                                                <div className="">
                                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="">
                                                                    <FormattedMessage id={`enum.paymentProviderId.${data.paymentProviderId}`} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {data.status &&
                                                                <div className="text-nowrap">
                                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + data.status + ".class"})}`}/>
                                                                    <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + data.status}/></span>
                                                                </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }

                                                return null;

                                            })}

                                            {this.state.FAILED_SCHEDULED_PAYMENTS.recordsShown < this.state.FAILED_SCHEDULED_PAYMENTS.totalRecordCount &&
                                            <tr className="c-pointer">
                                                <td colSpan="6" className="text-center" onClick={() => this.setState(prevState => ({FAILED_SCHEDULED_PAYMENTS: {...prevState.FAILED_SCHEDULED_PAYMENTS, recordsShown: prevState.FAILED_SCHEDULED_PAYMENTS.recordsShown + 10}}))}>
                                                    <div className="btn-link">
                                                        Load more records <FontAwesomeIcon icon={['fas', 'caret-down']} className="fa-fw"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            }

                                        </React.Fragment>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                }
                                {this.state.FAILED_SCHEDULED_PAYMENTS.totalRecordCount === 0 &&
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-muted">
                                                <FontAwesomeIcon icon={['fal', 'smile-beam']} className="fa-fw mb-4" size="5x"/>
                                            </div>
                                            <p className="text-center text-muted mb-0">
                                                <small>
                                                    Incredible! We have no failed scheduled payments in the last 2 business days. Rejoice!
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        }

                        {this.state.FAILED_SAAS_PAYMENTS &&
                        <div className="card mb-0">
                            <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#failed-saas-payments" aria-expanded="true" aria-controls="failed-saas-payments" onClick={() => this.getProcessingAlerts('FAILED_SAAS_PAYMENTS')}>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <span className={`badge badge-pill badge-${this.state.FAILED_SAAS_PAYMENTS.totalRecordCount === 0 ? 'dark' : 'danger'} va-b mr-1`}>{this.state.FAILED_SAAS_PAYMENTS.totalRecordCount}</span> Failed Receivable Payments <small className="text-muted text-uppercase">/ Last 7 Days</small>
                                    </div>
                                    <div className="col">
                                        <div className="float-right">
                                            <i className="fas"> </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="failed-saas-payments" className="collapse" data-parent="#processing-alerts">
                                {this.state.FAILED_SAAS_PAYMENTS.totalRecordCount > 0 &&
                                <div className="card-body card-body-table">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th width="16%">Date</th>
                                            <th width="16%">Amount</th>
                                            <th width="16%">Property</th>
                                            <th width="16%">Tenant</th>
                                            <th width="16%">Processor</th>
                                            <th width="16%">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.FAILED_SAAS_PAYMENTS.records &&
                                        <React.Fragment>

                                            {this.state.FAILED_SAAS_PAYMENTS.records.map((data, key) => {

                                                if (key <= this.state.FAILED_SAAS_PAYMENTS.recordsShown) {
                                                    return (
                                                        <tr key={key} onClick={() => this.selectPaymentTransaction(data)} className="c-pointer">
                                                            <td>
                                                                <div className="">
                                                                    <Moment format="MMM DD, YYYY">
                                                                        {data.createDate}
                                                                    </Moment>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                                    {data.amount &&
                                                                    <FormattedNumber value={(data.amount + data.feeAmount)} style={`currency`} currency={data.currency}/>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {(data.joins && data.joins.p && data.joins.pl) &&
                                                                <div className="">
                                                                    {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}{data.joins.p.street1}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {data.joins && data.joins.c &&
                                                                <div className="">
                                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="">
                                                                    <FormattedMessage id={`enum.paymentProviderId.${data.paymentProviderId}`} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {data.status &&
                                                                <div className="text-nowrap">
                                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + data.status + ".class"})}`}/>
                                                                    <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + data.status}/></span>
                                                                </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }

                                                return null;

                                            })}

                                            {this.state.FAILED_SAAS_PAYMENTS.recordsShown < this.state.FAILED_SAAS_PAYMENTS.totalRecordCount &&
                                            <tr className="c-pointer">
                                                <td colSpan="6" className="text-center" onClick={() => this.setState(prevState => ({FAILED_SAAS_PAYMENTS: {...prevState.FAILED_SAAS_PAYMENTS, recordsShown: prevState.FAILED_SAAS_PAYMENTS.recordsShown + 10}}))}>
                                                    <div className="btn-link">
                                                        Load more records <FontAwesomeIcon icon={['fas', 'caret-down']} className="fa-fw"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            }

                                        </React.Fragment>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                }
                                {this.state.FAILED_SAAS_PAYMENTS.totalRecordCount === 0 &&
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-muted">
                                                <FontAwesomeIcon icon={['fal', 'smile-beam']} className="fa-fw mb-4" size="5x"/>
                                            </div>
                                            <p className="text-center text-muted mb-0">
                                                <small>
                                                    Amazing! There are no failed payments to us in the last 7 days. Lunch is on me!
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        }

                        {this.state.FAILED_EFTS &&
                        <div className="card mb-0">
                            <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#failed-efts" aria-expanded="true" aria-controls="failed-efts" onClick={() => this.getProcessingAlerts('FAILED_EFTS')}>
                                <div className="row">
                                    <div className="col">
                                        <span className={`badge badge-pill badge-${this.state.FAILED_EFTS.totalRecordCount === 0 ? 'dark' : 'danger'} va-b mr-1`}>{this.state.FAILED_EFTS.totalRecordCount}</span> Failed EFTs <small className="text-muted text-uppercase">/ All Time</small>
                                    </div>
                                    <div className="col">
                                        <div className="float-right">
                                            <i className="fas"> </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="failed-efts" className="collapse" data-parent="#processing-alerts">
                                {this.state.FAILED_EFTS.totalRecordCount > 0 &&
                                <div className="card-body card-body-table">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th width="14%">Date</th>
                                            <th width="14%">Settlement</th>
                                            <th width="14%">Company</th>
                                            <th width="14%">Bank Account</th>
                                            <th width="14%">Merchant Account</th>
                                            <th width="14%">Amount</th>
                                            <th width="14%">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.FAILED_EFTS.records &&
                                        <React.Fragment>

                                            {this.state.FAILED_EFTS.records.map((data, key) => {

                                                if (key <= this.state.FAILED_EFTS.recordsShown) {
                                                    return (
                                                        <tr key={key} onClick={() => this.selectFundsTransfer(data)} className="c-pointer">
                                                            <td>
                                                                <div className="">
                                                                    <Moment format="MMM DD, YYYY">
                                                                        {data.createDate}
                                                                    </Moment>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="">
                                                                    {data.settlementDate &&
                                                                    <Moment format="MMM DD, YYYY">
                                                                        {data.settlementDate}
                                                                    </Moment>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {data.joins &&
                                                                <div className="">
                                                                    {data.joins.c.name}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="">
                                                                    ******{data.last4}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {data.joins &&
                                                                <div className="">
                                                                    {data.joins.ma.accountNumber}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {data.amount &&
                                                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                                    <FormattedNumber value={data.amount} style={`currency`} currency={data.joins ? data.joins.ma.currency : 'USD'}/>
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {data.status &&
                                                                <div className="text-nowrap">
                                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.fundsTransfer.eftStatus." + data.status + ".class"})}`}/>
                                                                    <span className="ml-1"><FormattedMessage id={"enum.fundsTransfer.eftStatus." + data.status}/></span>
                                                                </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }

                                                return null;

                                            })}

                                            {this.state.FAILED_EFTS.recordsShown < this.state.FAILED_EFTS.totalRecordCount &&
                                            <tr className="c-pointer">
                                                <td colSpan="6" className="text-center" onClick={() => this.setState(prevState => ({FAILED_EFTS: {...prevState.FAILED_EFTS, recordsShown: prevState.FAILED_EFTS.recordsShown + 10}}))}>
                                                    <div className="btn-link">
                                                        Load more records <FontAwesomeIcon icon={['fas', 'caret-down']} className="fa-fw"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            }

                                        </React.Fragment>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                }
                                {this.state.FAILED_EFTS.totalRecordCount === 0 &&
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-muted">
                                                <FontAwesomeIcon icon={['fal', 'smile-beam']} className="fa-fw mb-4" size="5x"/>
                                            </div>
                                            <p className="text-center text-muted mb-0">
                                                <small>
                                                    Nice! We have no failed EFTs. Let's celebrate!
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        }

                        {this.state.NOT_SETTLED_EFTS &&
                        <div className="card">
                            <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#not-settled-efts" aria-expanded="true" aria-controls="not-settled-efts" onClick={() => this.getProcessingAlerts('NOT_SETTLED_EFTS')}>
                                <div className="row">
                                    <div className="col">
                                        <span className={`badge badge-pill badge-${this.state.NOT_SETTLED_EFTS.totalRecordCount === 0 ? 'dark' : 'danger'} va-b mr-1`}>{this.state.NOT_SETTLED_EFTS.totalRecordCount}</span> Outstanding EFT Settlements <small className="text-muted text-uppercase">/ OVER 3 BUSINESS DAYS</small>
                                    </div>
                                    <div className="col">
                                        <div className="float-right">
                                            <i className="fas"> </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="not-settled-efts" className="collapse" data-parent="#processing-alerts">
                                {this.state.NOT_SETTLED_EFTS.totalRecordCount > 0 &&
                                <div className="card-body card-body-table">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th width="14%">Date</th>
                                            <th width="14%">Settlement</th>
                                            <th width="14%">Company</th>
                                            <th width="14%">Bank Account</th>
                                            <th width="14%">Merchant Account</th>
                                            <th width="14%">Amount</th>
                                            <th width="14%">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.NOT_SETTLED_EFTS.records &&
                                        <React.Fragment>

                                            {this.state.NOT_SETTLED_EFTS.records.map((data, key) => {

                                                if (key <= this.state.NOT_SETTLED_EFTS.recordsShown) {
                                                    return (
                                                        <tr key={key} onClick={() => this.selectFundsTransfer(data)} className="c-pointer">
                                                            <td>
                                                                <div className="">
                                                                    <Moment format="MMM DD, YYYY">
                                                                        {data.createDate}
                                                                    </Moment>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="">
                                                                    {data.settlementDate &&
                                                                    <Moment format="MMM DD, YYYY">
                                                                        {data.settlementDate}
                                                                    </Moment>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {data.joins &&
                                                                <div className="">
                                                                    {data.joins.c.name}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="">
                                                                    ******{data.last4}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {data.joins &&
                                                                <div className="">
                                                                    {data.joins.ma.accountNumber}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {data.amount &&
                                                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                                    <FormattedNumber value={data.amount} style={`currency`} currency={data.joins ? data.joins.ma.currency : 'USD'}/>
                                                                </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {data.status &&
                                                                <div className="text-nowrap">
                                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.fundsTransfer.eftStatus." + data.status + ".class"})}`}/>
                                                                    <span className="ml-1"><FormattedMessage id={"enum.fundsTransfer.eftStatus." + data.status}/></span>
                                                                </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }

                                                return null;

                                            })}

                                            {this.state.NOT_SETTLED_EFTS.recordsShown < this.state.NOT_SETTLED_EFTS.totalRecordCount &&
                                            <tr className="c-pointer">
                                                <td colSpan="6" className="text-center" onClick={() => this.setState(prevState => ({NOT_SETTLED_EFTS: {...prevState.NOT_SETTLED_EFTS, recordsShown: prevState.NOT_SETTLED_EFTS.recordsShown + 10}}))}>
                                                    <div className="btn-link">
                                                        Load more records <FontAwesomeIcon icon={['fas', 'caret-down']} className="fa-fw"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            }

                                        </React.Fragment>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                }
                                {this.state.NOT_SETTLED_EFTS.totalRecordCount === 0 &&
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-muted">
                                                <FontAwesomeIcon icon={['fal', 'smile-beam']} className="fa-fw mb-4" size="5x"/>
                                            </div>
                                            <p className="text-center text-muted mb-0">
                                                <small>
                                                    Booya! We have no outstanding EFT settlements over 3 business days. Let's get crackin'!
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        }

                    </div>

                    <div className="card">
                        <div className="card-header">
                            Reports
                        </div>
                        <div className="card-body card-body-list">
                            <div className="list-group">

                                <NavListItem path="/admin/dashboard/revenue" iconType="far" iconName="chart-bar" name="Revenue" size="large" description="View revenue processing totals on a year-by-year basis." />
                                <NavListItem path="/admin/dashboard/processing" iconType="far" iconName="chart-pie" name="Processing" size="large" description="View all processing totals by payment type." />

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Dashboard.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Dashboard);