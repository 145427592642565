import React from 'react';
import {injectIntl, intlShape} from 'react-intl';
import Propertii from "../../common/Propertii";
import axios from "axios";
import * as constants from "../../../util/constants";
import Spinner from "../../common/Spinner";
import Breadcrumb from "../../common/Breadcrumb";
import InvoiceFlow from "../../common/Invoice";

class Invoice extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            landlord: {},
            company: {},
            invoice: {},

            validationList: [],

        };
    }

    /**
     * On mounting of the component, fetch the landlord and company data based on the landlord ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let landlord = response.data;

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${landlord.companyId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    landlord: landlord,
                    company: response.data,
                }));

            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A public-facing component that handles customers accepting invitations.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/admin/landlords" parentPage="Landlords" childPath={`/admin/landlords/${this.state.landlord.id}/invoices`} childPage={this.state.company.name} subChildPage="Invoice" />

                    <InvoiceFlow company={this.state.company} invoice={(this.props.location && this.props.location.state && this.props.location.state.invoice) ? this.props.location.state.invoice : {}} userId={this.props.userSession.sessionRole.id} history={this.props.history} />

                </div>

            </div>
        )
    };
}

Invoice.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Invoice);