import React from 'react';
import Propertii from "./Propertii";
import ReactCardFlip from "react-card-flip";
import CardBrandIcon from "./CardBrandIcon";

class CardPreview extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - A helpful credit card-stylized component that builds a mock-up of a credit card as the user enters
     * their payment information.
     */
    render() {

        return(
            <div className="row justify-content-center">
                <div className={this.props.columnClasses}>
                    <ReactCardFlip isFlipped={this.props.cardPreviewFlipped} flipDirection="horizontal">

                        <div className="cc-mockup bg-secondary border mt-3 mb-4" key="front">

                            <CardBrandIcon paymentMethodType="TYPE_CREDIT_CARD" brand={this.props.paymentMethod.brand} customClasses="bg-white" />

                            <p className={`cc-number-display ${this.props.activePaymentMethodField === 'cardNumber' ? '' : 'text-muted'}`}>
                                {this.props.paymentMethod.cardNumber ? (this.props.paymentMethod.cardNumber.substr(0, 4) + ' ' + this.props.paymentMethod.cardNumber.substr(4, 4) + ' ' + this.props.paymentMethod.cardNumber.substr(8, 4) + ' ' + this.props.paymentMethod.cardNumber.substr(12, 4)) : '1234 5678 1234 5678'}
                            </p>

                            <div className="cc-lower-display">
                                <p className={`cc-holder-display ${this.props.activePaymentMethodField === 'nameOnCard' ? '' : 'text-muted'}`}>
                                    {this.props.paymentMethod.nameOnCard ? this.props.paymentMethod.nameOnCard : 'John Smith'}
                                </p>
                                <p className="cc-expiry-display">
                                    <span className={`cc-expiry-month-display ${this.props.activePaymentMethodField === 'expiryMonth' ? '' : 'text-muted'}`}>{this.props.paymentMethod.expiryMonth ? ('0' + this.props.paymentMethod.expiryMonth).slice(-2) : 'MM'}</span>
                                    <span className="text-muted">/</span>
                                    <span className={`cc-expiry-year-display ${this.props.activePaymentMethodField === 'expiryYear' ? '' : 'text-muted'}`}>{this.props.paymentMethod.expiryYear ? this.props.paymentMethod.expiryYear.substr(2, 2) : 'YY'}</span>
                                </p>
                            </div>

                        </div>

                        <div className="cc-mockup bg-secondary border mt-3 mb-4" key="back">

                            <div className="bg-dark cc-stripe"> </div>

                            <div className="bg-white cc-signature text-right m-auto">
                                {this.props.paymentMethod.securityCode ? this.props.paymentMethod.securityCode : '123'}
                            </div>

                        </div>

                    </ReactCardFlip>
                </div>
            </div>

        )
    };
}

CardPreview.defaultProps = {
    columnClasses: 'col-10 col-sm-10 col-md-10 col-lg-8 col-xl-6'
};

export default CardPreview;
