import React from 'react';
import {injectIntl, intlShape} from 'react-intl';
import Propertii from "../common/Propertii";
import axios from "axios";
import * as constants from "../../util/constants";
import $ from "jquery";
import FieldText from "../common/FieldText";
import Alert from "../common/Alert";
import Spinner from "../common/Spinner";
import FieldAddress from "../common/FieldAddress";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProgressBar from 'react-customizable-progressbar'
import TermsContents from "./Terms";
import FieldCheckbox from "./FieldCheckbox";
import FieldPhone from "./FieldPhone";
import FieldBirthDate from "./FieldBirthDate";
import FieldProperty from "./FieldProperty";
import FieldSelect from "./FieldSelect";

class LeaseApplication extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            company: {},
            leaseApplicationPage: {},
            leaseApplication: {},
            customFields: {},

            occupantList: [],
            occupant: {},
            showOccupantForm: false,
            editOccupant: false,

            employment: {},

            referenceList: [],
            reference: {},
            showReferenceForm: false,
            editReference: false,

            otherInfo: {},

            property: {},
            propertyList: [],

            address: {},
            addressList: [],
            showAddressForm: false,
            editAddress: false,

            termsCompleted: false,
            propertyCompleted: false,
            customerCompleted: false,
            addressesCompleted: false,
            occupantsCompleted: false,
            employmentCompleted: false,
            referencesCompleted: false,
            consentCompleted: false,

            progress: 0,

            validationList: [],

        };

        this.saveTerms = this.saveTerms.bind(this);
        this.saveProperty = this.saveProperty.bind(this);
        this.saveCustomer = this.saveCustomer.bind(this);
        this.saveAddresses = this.saveAddresses.bind(this);
        this.saveOccupants = this.saveOccupants.bind(this);
        this.saveEmployment = this.saveEmployment.bind(this);
        this.saveReferences = this.saveReferences.bind(this);
        this.saveConsent = this.saveConsent.bind(this);

        this.editTerms = this.editTerms.bind(this);
        this.editProperty = this.editProperty.bind(this);
        this.editCustomer = this.editCustomer.bind(this);
        this.editAddresses = this.editAddresses.bind(this);
        this.editOccupants = this.editOccupants.bind(this);
        this.editEmployment = this.editEmployment.bind(this);
        this.editReferences = this.editReferences.bind(this);
        this.editConsent = this.editConsent.bind(this);

        this.initAddress = this.initAddress.bind(this);
        this.editAddress = this.editAddress.bind(this);
        this.deleteAddress = this.deleteAddress.bind(this);

        this.initOccupant = this.initOccupant.bind(this);
        this.editOccupant = this.editOccupant.bind(this);
        this.deleteOccupant = this.deleteOccupant.bind(this);

        this.initReference = this.initReference.bind(this);
        this.editReference = this.editReference.bind(this);
        this.deleteReference = this.deleteReference.bind(this);

        this.handleChangeProperty = this.handleChangeProperty.bind(this);

        this.submitLeaseApplication = this.submitLeaseApplication.bind(this);
    }

    /**
     * On mounting of the component, fetch the lease application page and lease application currently in progress from
     * the props. If no lease application has been provided in the props, initialize a new instance of a lease
     * application.
     */
    componentDidMount() {

        if(this.props.leaseApplicationPage) {
            this.setState(prevState => ({
                ...prevState,
                leaseApplicationPage: this.props.leaseApplicationPage,
            }));
        }

        if(this.props.leaseApplication) {

            this.setState(prevState => ({
                ...prevState,
                leaseApplication: this.props.leaseApplication,
            }));

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.leaseApplication.companyId}/peek`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    company: response.data
                }));
            }).catch(error => {
                this.handleValidation(error);
            });

        }

        if(!this.props.leaseApplication) {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/lease_application/new`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    leaseApplication: {
                        ...response.data,
                        existingTenant: false
                    }
                }));
            }).catch(error => {
                console.error(error);
            });
        }
    }

    /**
     * Save the terms of use of the lease application flow.
     *
     * @param event - The event container.
     */
    saveTerms(event) {

        event.preventDefault();

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.company.id}/properties`, {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: []
        }, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                propertyList: response.data.records,
                termsCompleted: true,
                propertyCompleted: false,
                customerCompleted: false,
                addressesCompleted: false,
                occupantsCompleted: false,
                employmentCompleted: false,
                referencesCompleted: false,
                consentCompleted: false,
                progress: this.state.leaseApplication.existingTenant ? 33 : 13,
                validationList: [],
            }));

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Save the property portion of the lease application flow.
     *
     * @param event - The event container.
     */
    saveProperty(event) {

        event.preventDefault();

        if(!this.state.leaseApplication.propertyId) {

            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: 'application.property.null'
                    }
                }]
            });

            window.scrollTo(0, 0);
        }

        if(this.state.leaseApplication.propertyId) {
            this.setState(prevState => ({
                ...prevState,
                propertyCompleted: true,
                customerCompleted: false,
                addressesCompleted: false,
                occupantsCompleted: false,
                employmentCompleted: false,
                referencesCompleted: false,
                consentCompleted: false,
                progress: this.state.leaseApplication.existingTenant ? 67 : 25,
                validationList: [],
            }));
        }
    }

    /**
     * Save the customer portion of the lease application flow.
     *
     * @param event - The event container.
     */
    saveCustomer(event) {

        event.preventDefault();

        let otherInfo = this.state.otherInfo;

        // If this is an existing tenant, skip application-specific sections
        if(this.state.leaseApplication.existingTenant) {
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    otherInfo: otherInfo
                },
                customerCompleted: true,
                addressesCompleted: false,
                occupantsCompleted: false,
                employmentCompleted: false,
                referencesCompleted: false,
                consentCompleted: true,
                progress: 100,
                validationList: [],
            }));
        }

        // If this is a new application, follow through with regular application flow
        if(!this.state.leaseApplication.existingTenant) {
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    otherInfo: otherInfo
                },
                customerCompleted: true,
                addressesCompleted: false,
                occupantsCompleted: false,
                employmentCompleted: false,
                referencesCompleted: false,
                consentCompleted: false,
                progress: 38,
                validationList: [],
            }));
        }
    }

    /**
     * Save the address history portion of the lease application flow.
     *
     * @param event - The event container.
     */
    saveAddresses(event) {

        event.preventDefault();

        let addressList = this.state.addressList;

        if(this.state.showAddressForm) {

            if(this.state.editAddress) {
                addressList.forEach((address, key) => {
                    if(address.id === this.state.address.id) {
                        address = this.state.address;
                    }
                });
            }

            if(!this.state.editAddress) {
                addressList.push(this.state.address);
            }

            this.setState(prevState => ({
                ...prevState,
                showAddressForm: false,
                editAddress: false,
                addressList: addressList,
                validationList: [],
            }));
        }

        else {

            let monthsAtAddressTotal = 0;

            addressList.forEach((address, key) => {
                monthsAtAddressTotal = monthsAtAddressTotal + parseInt(address.monthsAtAddress);
            });

            if(monthsAtAddressTotal < 36) {

                this.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            code: 'application.addresses.invalid'
                        }
                    }]
                }));

                window.scrollTo(0, 0);
            }

            if(monthsAtAddressTotal >= 36) {
                this.setState(prevState => ({
                    ...prevState,
                    leaseApplication: {
                        ...prevState.leaseApplication,
                        addresses: addressList
                    },
                    addressesCompleted: true,
                    occupantsCompleted: false,
                    employmentCompleted: false,
                    referencesCompleted: false,
                    consentCompleted: false,
                    progress: 50,
                    validationList: [],
                }));
            }
        }
    }

    /**
     * Save the additional occupants portion of the lease application flow.
     *
     * @param event - The event container.
     */
    saveOccupants(event) {

        event.preventDefault();

        let occupantList = this.state.occupantList;

        if(this.state.showOccupantForm) {

            if(this.state.editOccupant) {
                occupantList.forEach((occupant, key) => {
                    if(occupant.id === this.state.occupant.id) {
                        occupant = this.state.occupant;
                    }
                });
            }

            if(!this.state.editOccupant) {
                occupantList.push(this.state.occupant);
            }

            this.setState(prevState => ({
                ...prevState,
                showOccupantForm: false,
                editOccupant: false,
                occupantList: occupantList,
                validationList: [],
            }));
        }

        else {

            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    otherOccupants: occupantList
                },
                occupantsCompleted: true,
                employmentCompleted: false,
                referencesCompleted: false,
                consentCompleted: false,
                progress: 63,
                validationList: [],
            }));

        }
    }

    /**
     * Save the employment information portion of the lease application flow.
     *
     * @param event - The event container.
     */
    saveEmployment(event) {

        event.preventDefault();

        let employment = this.state.employment;

        this.setState(prevState => ({
            ...prevState,
            leaseApplication: {
                ...prevState.leaseApplication,
                employment: employment
            },
            employmentCompleted: true,
            referencesCompleted: false,
            consentCompleted: false,
            progress: 75,
            validationList: [],
        }));
    }

    /**
     * Save the references portion of the lease application flow.
     *
     * @param event - The event container.
     */
    saveReferences(event) {

        event.preventDefault();

        let referenceList = this.state.referenceList;

        if(this.state.showReferenceForm) {

            if(this.state.editReference) {
                referenceList.forEach((reference, key) => {
                    if(reference.id === this.state.reference.id) {
                        reference = this.state.reference;
                    }
                });
            }

            if(!this.state.editReference) {
                referenceList.push(this.state.reference);
            }

            this.setState(prevState => ({
                ...prevState,
                showReferenceForm: false,
                editReference: false,
                referenceList: referenceList,
                validationList: [],
            }));
        }

        else {

            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    referenceList: referenceList
                },
                referencesCompleted: true,
                consentCompleted: false,
                progress: 88,
                validationList: [],
            }));

        }
    }

    /**
     * Save the privacy consent portion of the lease application flow.
     *
     * @param event - The event container.
     */
    saveConsent(event) {

        event.preventDefault();

        let otherInfo = this.state.otherInfo;

        this.setState(prevState => ({
            ...prevState,
            leaseApplication: {
                ...prevState.leaseApplication,
                otherInfo: otherInfo
            },
            consentCompleted: true,
            progress: 100,
            validationList: [],
        }));
    }

    /**
     * Edit the terms of use portion of the lease application flow.
     */
    editTerms() {

        this.setState(prevState => ({
            ...prevState,
            termsCompleted: false,
            propertyCompleted: false,
            customerCompleted: false,
            addressesCompleted: false,
            occupantsCompleted: false,
            employmentCompleted: false,
            referencesCompleted: false,
            consentCompleted: false,
            progress: 0,
        }));
    }

    /**
     * Edit the property portion of the lease application flow.
     */
    editProperty() {

        this.setState(prevState => ({
            ...prevState,
            propertyCompleted: false,
            customerCompleted: false,
            addressesCompleted: false,
            occupantsCompleted: false,
            employmentCompleted: false,
            referencesCompleted: false,
            consentCompleted: false,
            progress: 13,
        }));
    }

    /**
     * Edit the customer portion of the lease application flow.
     */
    editCustomer() {

        this.setState(prevState => ({
            ...prevState,
            customerCompleted: false,
            addressesCompleted: false,
            occupantsCompleted: false,
            employmentCompleted: false,
            referencesCompleted: false,
            consentCompleted: false,
            progress: 25,
        }));
    }

    /**
     * Edit the address history portion of the lease application flow.
     */
    editAddresses() {

        this.setState(prevState => ({
            ...prevState,
            addressesCompleted: false,
            occupantsCompleted: false,
            employmentCompleted: false,
            referencesCompleted: false,
            consentCompleted: false,
            progress: 38,
        }));
    }

    /**
     * Edit the additional occupants portion of the lease application flow.
     */
    editOccupants() {

        this.setState(prevState => ({
            ...prevState,
            occupantsCompleted: false,
            employmentCompleted: false,
            referencesCompleted: false,
            consentCompleted: false,
            progress: 50,
        }));
    }

    /**
     * Edit the employment information portion of the lease application flow.
     */
    editEmployment() {

        this.setState(prevState => ({
            ...prevState,
            employmentCompleted: false,
            referencesCompleted: false,
            consentCompleted: false,
            progress: 63,
        }));
    }

    /**
     * Edit the references portion of the lease application flow.
     */
    editReferences() {

        this.setState(prevState => ({
            ...prevState,
            referencesCompleted: false,
            consentCompleted: false,
            progress: 75,
        }));
    }

    /**
     * Edit the privacy consent of the lease application flow.
     */
    editConsent() {

        this.setState(prevState => ({
            ...prevState,
            consentCompleted: false,
            progress: 88,
        }));
    }

    /**
     * Handle changing the selected property for the lease application.
     *
     * @param selectedProperty - The selected property.
     */
    handleChangeProperty(selectedProperty) {

        this.setState(prevState => ({
            ...prevState,
            leaseApplication: {
                ...prevState.leaseApplication,
                property: {
                    value: selectedProperty.value,
                    label: selectedProperty.label
                },
                propertyId: selectedProperty.value
            }
        }));
    }

    /**
     * Initialize a new instance of an address object.
     */
    initAddress() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                showAddressForm: true,
                editAddress: false,
                address: {
                    ...response.data,
                    addressType: prevState.addressList.length === 0 ? 'RESIDENTIAL' : 'PREVIOUS'
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Edit an existing address from the list of addresses.
     *
     * @param address - The address to edit.
     */
    editAddress(address) {

        this.setState(prevState => ({
            ...prevState,
            showAddressForm: true,
            editAddress: true,
            address: address
        }));
    }

    /**
     * Delete an existing address from the list of addresses.
     *
     * @param address - The address object to delete.
     */
    deleteAddress(address) {

        let addressList = this.state.addressList;

        addressList.forEach((data, key) => {
            if(data.id === address.id) {
                addressList.splice(key, 1);
            }
        });

        this.setState(prevState => ({
            ...prevState,
            showAddressForm: false,
            editAddress: false,
            address: {},
            addressList: addressList,
        }));
    }

    /**
     * Initialize a new instance of an additional occupant.
     */
    initOccupant() {

        this.setState(prevState => ({
            ...prevState,
            showOccupantForm: true,
            editOccupant: false,
            occupant: {
                id: prevState.occupantList.length,
                firstName: '',
                lastName: '',
                age: '',
            }
        }));
    }

    /**
     * Edit an existing occupant from the list of additional occupants.
     *
     * @param occupant - The occupant to edit.
     */
    editOccupant(occupant) {

        this.setState(prevState => ({
            ...prevState,
            showOccupantForm: true,
            editOccupant: true,
            occupant: occupant
        }));
    }

    /**
     * Delete an existing occupant from the list of additional occupants.
     *
     * @param occupant - The occupant to delete.
     */
    deleteOccupant(occupant) {

        let occupantList = this.state.occupantList;

        occupantList.forEach((data, key) => {
            if(data.id === occupant.id) {
                occupantList.splice(key, 1);
            }
        });

        this.setState(prevState => ({
            ...prevState,
            showOccupantForm: false,
            editOccupant: false,
            occupant: {},
            occupantList: occupantList,
        }));
    }

    /**
     * Initialize a new instance of a reference.
     */
    initReference() {

        this.setState(prevState => ({
            ...prevState,
            showReferenceForm: true,
            editReference: false,
            reference: {
                id: prevState.referenceList.length,
                firstName: '',
                lastName: '',
                phone: '',
                relationship: '',
            }
        }));
    }

    /**
     * Edit a reference from the list of references.
     *
     * @param reference - The reference to edit.
     */
    editReference(reference) {

        this.setState(prevState => ({
            ...prevState,
            showReferenceForm: true,
            editReference: true,
            reference: reference
        }));
    }

    /**
     * Delete an existing reference from the list of references.
     *
     * @param reference - The reference to delete.
     */
    deleteReference(reference) {

        let referenceList = this.state.referenceList;

        referenceList.forEach((data, key) => {
            if(data.id === reference.id) {
                referenceList.splice(key, 1);
            }
        });

        this.setState(prevState => ({
            ...prevState,
            showReferenceForm: false,
            editReference: false,
            reference: {},
            referenceList: referenceList,
        }));
    }

    /**
     * Submit the lease application data. Redirect the user to the lease application post-submission page, passing along
     * the lease application page, company, and lease application data in the state prop.
     */
    submitLeaseApplication() {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/submit`, this.state.leaseApplication, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => {

            this.setState({
                spinner: false
            });

            this.props.history.push({
                pathname: '/apply/submitted',
                state: {
                    leaseApplicationPage: this.state.leaseApplicationPage ? this.state.leaseApplicationPage : null,
                    company: this.state.company,
                    leaseApplication: this.state.leaseApplication
                }
            });

        }).catch(error => {

            this.handleValidation(error);

            window.scrollTo(0, 0);

        });
    }

    /**
     * Render the component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <div className="row">
                    <div className="col-md-8">

                        <Alert validationList={this.state.validationList} />

                        <div className="card">

                            <div className="card-header">
                                Terms of Use
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">
                                    Please review and accept the Letus Terms of Use.
                                </p>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.saveTerms}>

                                    {this.state.termsCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editTerms()}>
                                                <div className="">
                                                    Letus Terms of Use
                                                </div>
                                                <small className="mb-0 ml-md-0 small text-muted">
                                                    I have read and agree to the Letus Terms of Use
                                                </small>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editTerms()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {!this.state.termsCompleted &&
                                    <React.Fragment>

                                        <div className="card-body border mb-3 bg-secondary rounded small" style={{height: "250px", overflowY: "scroll"}}>

                                            <TermsContents />

                                        </div>

                                        <FieldCheckbox id="acknowledgedTerms" fieldLabel="I have read and agree to the Letus Terms of Use" fieldColumns="12" parent={this} model="leaseApplication" value={this.state.leaseApplication['acknowledgedTerms']} />

                                        {this.state.leaseApplication.acknowledgedTerms &&
                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>
                                        }

                                        {!this.state.leaseApplication.acknowledgedTerms &&
                                        <div className="btn btn-primary btn-md btn-block mt-3 disabled" data-toggle="tooltip" data-placement="top" title="In order to setup your lease application, you must indicate that you have read and agree to the Letus terms of use above.">
                                            Save & Continue
                                        </div>
                                        }

                                    </React.Fragment>
                                    }

                                </form>
                            </div>
                        </div>

                        {this.state.termsCompleted &&
                        <div className="card">

                            <div className="card-header">
                                Property
                            </div>

                            <div className="card-body bg-secondary">

                                {this.state.leaseApplication.existingTenant &&
                                <p className="mb-0">
                                    Which property do you currently have an existing lease with?
                                </p>
                                }

                                {!this.state.leaseApplication.existingTenant &&
                                <p className="mb-0">
                                    Which property are you applying as a tenant for?
                                </p>
                                }

                            </div>

                            <div className="card-body">
                                <form onSubmit={this.saveProperty} autoComplete="off">

                                    {this.state.propertyCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editProperty()}>
                                                <div className="">
                                                    {this.state.leaseApplication.unit ? this.state.leaseApplication.unit + ' - ' : ''}{this.state.leaseApplication.property.label}
                                                </div>
                                                <small className="mb-0 ml-md-0 small text-muted">
                                                    Managed by {this.state.company.name}
                                                </small>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editProperty()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {!this.state.propertyCompleted &&
                                    <React.Fragment>

                                        <FieldProperty id="propertyId" label="Property" model="leaseApplication" value={this.state.leaseApplication.property} parent={this} options={this.state.propertyList} handleChange={this.handleChangeProperty} />

                                        <FieldText id="unit" label="Unit" model="leaseApplication" parent={this} value={this.state.leaseApplication['unit']}/>

                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </React.Fragment>
                                    }

                                </form>
                            </div>

                        </div>
                        }

                        {this.state.propertyCompleted &&
                        <div className="card">

                            <div className="card-header">
                                {this.state.leaseApplication.existingTenant ? 'Tenant' : 'Applicant'}
                            </div>

                            <div className="card-body bg-secondary">

                                {this.state.leaseApplication.existingTenant &&
                                <p className="mb-0">
                                    Who is the current lease holder?
                                </p>
                                }

                                {!this.state.leaseApplication.existingTenant &&
                                <p className="mb-0">
                                    Who is applying for this lease?
                                </p>
                                }

                            </div>

                            <div className="card-body">

                                {this.state.customerCompleted &&
                                <div className="">

                                    <div className="list-group">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editCustomer()}>
                                            <div className="">
                                                {this.state.leaseApplication.firstName} {this.state.leaseApplication.lastName}
                                            </div>
                                            <small className="mb-0 ml-md-0 small text-muted">
                                                {this.state.leaseApplication.email}
                                            </small>
                                        </div>
                                    </div>

                                    <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editCustomer()}>
                                        Edit
                                    </div>

                                </div>
                                }

                                {!this.state.customerCompleted &&
                                <form onSubmit={this.saveCustomer} autoComplete="off">

                                    <FieldText id="firstName" label="First Name" model="leaseApplication" parent={this} required={true} value={this.state.leaseApplication['firstName']} />

                                    <FieldText id="lastName" label="Last Name" model="leaseApplication" parent={this} required={true} value={this.state.leaseApplication['lastName']} />

                                    <FieldText id="email" label="Email" model="leaseApplication" type="email" parent={this} required={true} value={this.state.leaseApplication['email']} />

                                    <FieldPhone id="phone" label="Phone" model="leaseApplication" parent={this} value={this.state.leaseApplication['phone']} />

                                    <FieldBirthDate id="birthDate" label="Date of Birth" model="leaseApplication" parent={this} required={true} value={this.state.leaseApplication['birthDate']} />

                                    {!this.state.leaseApplication.existingTenant &&
                                    <React.Fragment>

                                        <FieldText id="sin" label="SIN/SSN" model="leaseApplication" parent={this} required={true} value={this.state.leaseApplication['sin']}/>

                                        <FieldSelect id="maritalStatus" label="Marital Status" model="otherInfo" parent={this} required={true} value={this.state.otherInfo['maritalStatus']}>
                                            <option value="" disabled>Select a marital status...</option>
                                            <option value="MARRIED">Married</option>
                                            <option value="WIDOWED">Widowed</option>
                                            <option value="DIVORCED_OR_SEPARATED">Divorced or Separated</option>
                                            <option value="NEVER_MARRIED_OR_SINGLE">Never Married or Single</option>
                                        </FieldSelect>

                                        <FieldCheckbox id="pets" label="Pets" fieldLabel="I currently own one or more pets" model="otherInfo" parent={this} value={this.state.otherInfo['pets']}/>

                                        <FieldCheckbox id="smoking" label="Smoking" fieldLabel="I currently smoke" model="otherInfo" parent={this} value={this.state.otherInfo['smoking']}/>

                                    </React.Fragment>
                                    }

                                    <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                        Save & Continue
                                    </button>

                                </form>
                                }

                            </div>

                        </div>
                        }

                        {(this.state.customerCompleted && !this.state.leaseApplication.existingTenant) &&
                        <div className="card">

                            <div className="card-header">
                                Address History
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">
                                    What address(es) have you resided at in the last 3 years?
                                </p>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.saveAddresses}>

                                    {!this.state.addressesCompleted &&
                                    <React.Fragment>
                                        {this.state.addressList.map((address, key) => {
                                            return(
                                                <div key={key} className="list-group mb-2">
                                                    <div className={`list-group-item ${(this.state.editAddress && address.id === this.state.address.id) ? '' : 'list-group-item-action c-pointer'}`} onClick={() => (this.state.editAddress && address.id === this.state.address.id) ? null : this.editAddress(address)}>

                                                        <div className="row align-items-center">
                                                            <div className="col-10">
                                                                <div className="">
                                                                    {address.suite ? address.suite + ' - ' : ''}{address.street1 + (address.street2 ? ', ' + address.street2 : '') + ', ' + address.city + ', ' + (address.country === 'CA' ? formatMessage({id: "province." + address.province}) : formatMessage({id: "state." + address.province})) + ', ' + formatMessage({id: "country." + address.country}) + ' ' + address.postalCode}
                                                                </div>
                                                                <small className="mb-0 ml-md-0 small text-muted">
                                                                    {address.addressType === 'RESIDENTIAL' ? `Current address for ${address.monthsAtAddress} month(s)` : `Previous address for ${address.monthsAtAddress} month(s)`}
                                                                </small>
                                                            </div>
                                                            <div className="col text-right">
                                                                <div className="float-right">
                                                                    <FontAwesomeIcon icon={['far', 'pencil']} className="fa-fw" size="1x" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {(this.state.editAddress && address.id === this.state.address.id) &&
                                                        <React.Fragment>

                                                            <div className="mt-3">
                                                                <FieldAddress model="address" parent={this} value={this.state.address} suite={true} monthsAtAddress={true} required={true} />
                                                            </div>

                                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.deleteAddress(address)}>
                                                                Delete Address
                                                            </div>

                                                            <button type="submit" className="btn btn-primary btn-md btn-block">
                                                                Save Address
                                                            </button>

                                                        </React.Fragment>
                                                        }

                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                    }

                                    {(!this.state.addressesCompleted && !this.state.showAddressForm) &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.initAddress()}>
                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    Add New Address
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right">
                                                        <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {(!this.state.addressesCompleted && this.state.showAddressForm && !this.state.editAddress) &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item pb-3">

                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    Add New Address
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right">
                                                        <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                <FieldAddress model="address" parent={this} value={this.state.address} suite={true} monthsAtAddress={true} required={true} />
                                            </div>

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save Address
                                            </button>

                                        </div>
                                    </div>
                                    }

                                    {this.state.addressesCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editAddresses()}>
                                                <div className="">
                                                    {this.state.addressList.length} current and/or previous address(es)
                                                </div>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editAddresses()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {(!this.state.addressesCompleted && !this.state.showAddressForm) &&
                                    <React.Fragment>

                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </React.Fragment>
                                    }

                                </form>
                            </div>
                        </div>
                        }

                        {(this.state.addressesCompleted && !this.state.leaseApplication.existingTenant) &&
                        <div className="card">

                            <div className="card-header">
                                Additional Occupants
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">
                                    Do you have any additional occupants currently residing with you?
                                </p>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.saveOccupants}>

                                    {!this.state.occupantsCompleted &&
                                    <React.Fragment>
                                        {this.state.occupantList.map((occupant, key) => {
                                            return(
                                                <div key={key} className="list-group mb-2">
                                                    <div className={`list-group-item ${(this.state.editOccupant && occupant.id === this.state.occupant.id) ? '' : 'list-group-item-action c-pointer'}`} onClick={() => (this.state.editOccupant && occupant.id === this.state.occupant.id) ? null : this.editOccupant(occupant)}>

                                                        <div className="row align-items-center">
                                                            <div className="col-10">
                                                                <div className="">
                                                                    {occupant.firstName} {occupant.lastName}
                                                                </div>
                                                                <small className="mb-0 ml-md-0 small text-muted">
                                                                    {occupant.age} years old
                                                                </small>
                                                            </div>
                                                            <div className="col text-right">
                                                                <div className="float-right">
                                                                    <FontAwesomeIcon icon={['far', 'pencil']} className="fa-fw" size="1x" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {(this.state.editOccupant && occupant.id === this.state.occupant.id) &&
                                                        <React.Fragment>

                                                            <div className="mt-3">

                                                                <FieldText id="firstName" label="First Name" model="occupant" parent={this} required={true} value={this.state.occupant['firstName']} />

                                                                <FieldText id="lastName" label="Last Name" model="occupant" parent={this} required={true} value={this.state.occupant['lastName']} />

                                                                <FieldText id="age" label="Age" model="occupant" parent={this} required={true} value={this.state.occupant['age']} />

                                                                <FieldPhone id="phone" label="Phone" model="occupant" parent={this} required={true} value={this.state.occupant['phone']} />

                                                                <FieldSelect id="employment" label="Employment Status" model="occupant" parent={this} value={this.state.occupant['employment']}>
                                                                    <option value="" disabled>Select an employment status...</option>
                                                                    <option value="EMPLOYED_FULL_TIME">Employed Full-Time</option>
                                                                    <option value="EMPLOYED_PART_TIME">Employed Part-Time</option>
                                                                    <option value="UNEMPLOYED">Unemployed</option>
                                                                    <option value="RETIRED">Retired</option>
                                                                    <option value="STUDENT">Student</option>
                                                                </FieldSelect>

                                                                <FieldText id="relationship" label="Relationship" model="occupant" parent={this} required={true} value={this.state.occupant['relationship']} />

                                                            </div>

                                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.deleteOccupant(occupant)}>
                                                                Delete Occupant
                                                            </div>

                                                            <button type="submit" className="btn btn-primary btn-md btn-block">
                                                                Save Occupant
                                                            </button>

                                                        </React.Fragment>
                                                        }

                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                    }

                                    {(!this.state.occupantsCompleted && !this.state.showOccupantForm) &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.initOccupant()}>
                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    Add Additional Occupant
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right">
                                                        <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {(!this.state.occupantsCompleted && this.state.showOccupantForm && !this.state.editOccupant) &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item pb-3">

                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    Add Additional Occupant
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right">
                                                        <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-3">

                                                <FieldText id="firstName" label="First Name" model="occupant" parent={this} required={true} value={this.state.occupant['firstName']} />

                                                <FieldText id="lastName" label="Last Name" model="occupant" parent={this} required={true} value={this.state.occupant['lastName']} />

                                                <FieldText id="age" label="Age" model="occupant" parent={this} required={true} value={this.state.occupant['age']} />

                                                <FieldPhone id="phone" label="Phone" model="occupant" parent={this} required={true} value={this.state.occupant['phone']} />

                                                <FieldSelect id="employment" label="Employment Status" model="occupant" parent={this} value={this.state.occupant['employment']}>
                                                    <option value="" disabled>Select an employment status...</option>
                                                    <option value="EMPLOYED_FULL_TIME">Employed Full-Time</option>
                                                    <option value="EMPLOYED_PART_TIME">Employed Part-Time</option>
                                                    <option value="UNEMPLOYED">Unemployed</option>
                                                    <option value="RETIRED">Retired</option>
                                                    <option value="STUDENT">Student</option>
                                                </FieldSelect>

                                                <FieldText id="relationship" label="Relationship" model="occupant" parent={this} required={true} value={this.state.occupant['relationship']} />

                                            </div>

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save Occupant
                                            </button>

                                        </div>
                                    </div>
                                    }

                                    {this.state.occupantsCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editOccupants()}>
                                                <div className="">
                                                    {this.state.occupantList.length} additional occupant(s)
                                                </div>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editOccupants()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {(!this.state.occupantsCompleted && !this.state.showOccupantForm) &&
                                    <React.Fragment>

                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </React.Fragment>
                                    }

                                </form>
                            </div>
                        </div>
                        }

                        {(this.state.occupantsCompleted && !this.state.leaseApplication.existingTenant) &&
                        <div className="card">

                            <div className="card-header">
                                Employment Information
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">
                                    What is your current employment information?
                                </p>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.saveEmployment} autoComplete="off">

                                    {this.state.employmentCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editEmployment()}>
                                                <div className="">
                                                    {this.state.employment.companyName}
                                                </div>
                                                <small className="mb-0 ml-md-0 small text-muted">
                                                    {this.state.employment.occupation}
                                                </small>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editEmployment()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {!this.state.employmentCompleted &&
                                    <React.Fragment>

                                        <FieldSelect id="employment" label="Employment Status" model="employment" parent={this} value={this.state.employment['employment']}>
                                            <option value="" disabled>Select an employment status...</option>
                                            <option value="EMPLOYED_FULL_TIME">Employed Full-Time</option>
                                            <option value="EMPLOYED_PART_TIME">Employed Part-Time</option>
                                            <option value="UNEMPLOYED">Unemployed</option>
                                            <option value="RETIRED">Retired</option>
                                            <option value="STUDENT">Student</option>
                                        </FieldSelect>

                                        {(this.state.employment.employment === 'EMPLOYED_FULL_TIME' || this.state.employment.employment === 'EMPLOYED_PART_TIME') &&
                                        <React.Fragment>

                                            <FieldText id="employerName" label="Employer Name" model="employment" parent={this} value={this.state.employment['employerName']} />

                                            <FieldPhone id="phone" label="Employer Phone" model="employment" parent={this} required={true} value={this.state.employment['phone']} />

                                            <FieldText id="occupation" label="Occupation" model="employment" parent={this} value={this.state.employment['occupation']} />

                                            <FieldText id="monthsEmployed" label="Months Employed" model="employment" parent={this} value={this.state.employment['monthsEmployed']} />

                                            <FieldText id="monthlySalary" label="Monthly Income" model="employment" parent={this} value={this.state.employment['monthlySalary']} />

                                        </React.Fragment>
                                        }

                                        <FieldText id="otherIncome" label="Other Income" model="employment" parent={this} value={this.state.employment['otherIncome']} />

                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </React.Fragment>
                                    }

                                </form>
                            </div>

                        </div>
                        }

                        {(this.state.employmentCompleted && !this.state.leaseApplication.existingTenant) &&
                        <div className="card">

                            <div className="card-header">
                                Personal References
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">
                                    Do you have any personal references from someone who knows you in a personal, financial, educational, professional, or prior rental experience?
                                </p>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.saveReferences}>

                                    {!this.state.referencesCompleted &&
                                    <React.Fragment>
                                        {this.state.referenceList.map((reference, key) => {
                                            return(
                                                <div key={key} className="list-group mb-2">
                                                    <div className={`list-group-item ${(this.state.editReference && reference.id === this.state.reference.id) ? '' : 'list-group-item-action c-pointer'}`} onClick={() => (this.state.editReference && reference.id === this.state.reference.id) ? null : this.editReference(reference)}>

                                                        <div className="row align-items-center">
                                                            <div className="col-10">
                                                                <div className="">
                                                                    {reference.firstName} {reference.lastName}
                                                                </div>
                                                                <small className="mb-0 ml-md-0 small text-muted">
                                                                    {reference.relationship}
                                                                </small>
                                                            </div>
                                                            <div className="col text-right">
                                                                <div className="float-right">
                                                                    <FontAwesomeIcon icon={['far', 'pencil']} className="fa-fw" size="1x" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {(this.state.editReference && reference.id === this.state.reference.id) &&
                                                        <React.Fragment>

                                                            <div className="mt-3">

                                                                <FieldText id="firstName" label="First Name" model="reference" parent={this} required={true} value={this.state.reference['firstName']} />

                                                                <FieldText id="lastName" label="Last Name" model="reference" parent={this} required={true} value={this.state.reference['lastName']} />

                                                                <FieldPhone id="phone" label="Phone" model="reference" parent={this} required={true} value={this.state.reference['phone']} />

                                                                <FieldText id="relationship" label="Relationship" model="reference" parent={this} required={true} value={this.state.reference['relationship']} />

                                                            </div>

                                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.deleteReference(reference)}>
                                                                Delete Reference
                                                            </div>

                                                            <button type="submit" className="btn btn-primary btn-md btn-block">
                                                                Save Reference
                                                            </button>

                                                        </React.Fragment>
                                                        }

                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                    }

                                    {(!this.state.referencesCompleted && !this.state.showReferenceForm) &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.initReference()}>
                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    Add Reference
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right">
                                                        <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {(!this.state.referencesCompleted && this.state.showReferenceForm && !this.state.editReference) &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item pb-3">

                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    Add Reference
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right">
                                                        <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-3">

                                                <FieldText id="firstName" label="First Name" model="reference" parent={this} required={true} value={this.state.reference['firstName']} />

                                                <FieldText id="lastName" label="Last Name" model="reference" parent={this} required={true} value={this.state.reference['lastName']} />

                                                <FieldPhone id="phone" label="Phone" model="reference" parent={this} required={true} value={this.state.reference['phone']} />

                                                <FieldText id="relationship" label="Relationship" model="reference" parent={this} required={true} value={this.state.reference['relationship']} />

                                            </div>

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save Reference
                                            </button>

                                        </div>
                                    </div>
                                    }

                                    {this.state.referencesCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editReferences()}>
                                                <div className="">
                                                    {this.state.referenceList.length} reference(s) provided
                                                </div>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editReferences()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {(!this.state.referencesCompleted && !this.state.showReferenceForm) &&
                                    <React.Fragment>

                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </React.Fragment>
                                    }

                                </form>
                            </div>
                        </div>
                        }

                        {(this.state.referencesCompleted && !this.state.leaseApplication.existingTenant) &&
                        <div className="card">

                            <div className="card-header">
                                Lease Application Privacy Consent
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">
                                    Please indicated you have read, understood, and voluntarily agree to the terms and conditions outlined below.
                                </p>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.saveConsent}>

                                    {this.state.consentCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editTerms()}>
                                                <div className="">
                                                    Collection, Use, and Disclosure of Information
                                                </div>
                                                <small className="mb-0 ml-md-0 small text-muted">
                                                    I have read and agreed to the collection, use, and disclosure of information as outlined within
                                                </small>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editTerms()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {!this.state.consentCompleted &&
                                    <React.Fragment>

                                        <div className="card-body border mb-3 bg-secondary rounded small" style={{height: "250px", overflowY: "scroll"}}>

                                            <h5>Definitions</h5>

                                            <p>When used in this Agreement, each of the following words and phrases shall have the meanings set out below:</p>

                                            <p>(a) "<span className="font-weight-bold">Agreement</span>" means these Terms of Use, including related schedules, policies and other documents that appear under "Legal" on the Website, as may be amended from time to time;</p>
                                            <p>(b) "<span className="font-weight-bold">Information</span>" means Credit Information, Personal Information, and information about the services you use that are provided by the Landlord as listed in this rental application and information relating to your tenancy at the premises applied for in this rental application including information regarding the duration of your tenancy, monthly rent, emergency contacts and any matters relating to your lease/tenancy agreement, including misrepresentations relating to, defaults under and/or breaches of your lease/tenancy agreement or any other matter experienced by the Landlord;</p>
                                            <p>(c) "<span className="font-weight-bold">Credit Information</span>" means information about you, including your name, age, date of birth, occupation, place of residence, previous places of residence, occupancy length, marital status, co-occupant's/spouse's/same-sex partner's name and age, number of dependants, particulars of education or professional qualifications, field of employment, places of employment, previous places of employment, employment durations, estimated income, paying habits, outstanding debt obligations, cost of living obligations, involvement in bankruptcy proceedings or landlord and tenant disputes, assets, and banking information (including account and credit card information);</p>
                                            <p>(c) "<span className="font-weight-bold">Personal Information</span>" means information about you other than credit information that is relevant to your suitability as a tenant, including information gathered from references (provided by you to the Landlord) concerning your character, reputation, physical or personal characteristics or mode of living or about any other matter or experience concerning you that is relevant to your suitability as a tenant.</p>
                                            <p>(d) "<span className="font-weight-bold">Landlord</span>" means the person or entity, including but not limited to property management companies, as listed in this rental application and information relating to your tenancy at the premises applied for in this rental application;</p>

                                            <h5>Collection, Use and Disclosure of Information</h5>

                                            <p>In considering for the property manager/landlord accepting you as a tenant and entering into a lease/tenancy agreement with you, you expressly consent to and authorize the following:</p>

                                            <p className="mb-0">
                                                <ol>
                                                    <li className="mb-3">The property manager/landlord may obtain information about you through a tenancy report conducted by Certn and/or credit report conducted by Equifax.</li>
                                                    <li className="mb-3">The property manager/landlord may use information about you to determine your suitability as a tenant and as permitted or required by law.</li>
                                                    <li className="mb-3">
                                                        The property manager/landlord may disclose credit information about you Equifax. as permitted or required by law, for inclusion within a database of rent-roll information and within a tenancy file on you, for the purposes of:
                                                        <ul>
                                                            <li className="mb-3">tenant reporting and credit reporting in accordance with provincial or state credit and consumer reporting acts;</li>
                                                            <li className="mb-3">establishing a credit history and or rental history</li>
                                                            <li className="mb-3">maintaining aggregate statistical data for purposes of tenancy and credit scoring; and</li>
                                                            <li className="mb-3">supporting the credit approval process in accordance with governing legislation.</li>
                                                        </ul>
                                                    </li>
                                                    <li className="mb-3">You expressly authorize Equifax to retain positive Credit Information regarding you for the purposes outlined in section 3 above, for up to 20 (twenty) years. Negative Credit Information shall be maintained on record in accordance with provincial or state credit and consumer reporting acts.</li>
                                                    <li className="mb-0">You agree that all statements on this lease application are true and you expressly authorize all references given to release information about you to the Landlord for verification subject to sections 1 to 5.</li>
                                                </ol>
                                            </p>

                                        </div>

                                        <FieldCheckbox id="privacyConsent" fieldLabel="I have read, understood, and voluntarily agree to the terms and conditions outlined in the Lease Application Privacy Consent above." fieldColumns="12" model="otherInfo" parent={this} value={this.state.otherInfo['privacyConsent']} />

                                        {this.state.otherInfo.privacyConsent &&
                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>
                                        }

                                        {!this.state.otherInfo.privacyConsent &&
                                        <div className="btn btn-primary btn-md btn-block mt-3 disabled" data-toggle="tooltip" data-placement="top" title="In order to continue with your lease application, you must indicate that you have read, understood, and voluntarily agree to the terms and conditions outlined in the Lease Application Privacy Consent above.">
                                            Save & Continue
                                        </div>
                                        }

                                    </React.Fragment>
                                    }

                                </form>
                            </div>
                        </div>
                        }

                    </div>
                    <div className="col-md-4">
                        <div className="sticky-top">

                            {this.state.leaseApplication.existingTenant &&
                            <div className="card">

                                <div className="card-header">
                                    Sign Up Summary
                                </div>

                                <div className="card-body card-body-table">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>
                                                Current Progress
                                            </th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>

                                <div className="card-body text-center m-auto">
                                    <ProgressBar radius={50} progress={this.state.progress} strokeWidth={18} strokeColor="#52c75d" strokeLinecap="square" trackStrokeWidth={18}>
                                        <div className="indicator">
                                            <div>{this.state.progress}%</div>
                                        </div>
                                    </ProgressBar>
                                </div>

                                <div className="card-footer">
                                    <p className="small text-muted mb-0">
                                        Once your lease details have been submitted, the landlord or property manager at your selected property will review your details.
                                    </p>
                                </div>

                            </div>
                            }

                            {!this.state.leaseApplication.existingTenant &&
                            <div className="card">

                                <div className="card-header">
                                    Application Summary
                                </div>

                                <div className="card-body card-body-table">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>
                                                Current Progress
                                            </th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>

                                <div className="card-body text-center m-auto">
                                    <ProgressBar radius={50} progress={this.state.progress} strokeWidth={18} strokeColor="#52c75d" strokeLinecap="square" trackStrokeWidth={18}>
                                        <div className="indicator">
                                            <div>{this.state.progress}%</div>
                                        </div>
                                    </ProgressBar>
                                </div>

                                <div className="card-footer">
                                    <p className="small text-muted mb-0">
                                        Once your lease application has been submitted, the landlord or property manager at your selected property will review your details.
                                    </p>
                                </div>

                            </div>
                            }

                            {this.state.consentCompleted &&
                            <React.Fragment>

                                <div className="btn btn-primary btn-lg btn-block py-3 mb-4" onClick={() => this.submitLeaseApplication()}>
                                    <FontAwesomeIcon icon={['far', 'check-circle']} className="fa-fw" /> Submit {this.state.leaseApplication.existingTenant ? 'Lease Details' : 'Application'}
                                </div>

                            </React.Fragment>
                            }

                        </div>

                    </div>
                </div>

            </React.Fragment>
        )
    };
}

LeaseApplication.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(LeaseApplication);