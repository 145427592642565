import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { IntlProvider, addLocaleData } from "react-intl";

import Navigation from './Navigation';
import Footer from './Footer';
import Login from "../Login";
import Logout from "../Logout";
import PrivateRoute from "../../components/common/PrivateRoute";

import locale_en from "react-intl/locale-data/en";
import locale_fr from "react-intl/locale-data/fr";
import localeMessages_en from "../../locales/en";
import localeMessages_fr from "../../locales/fr";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas }from '@fortawesome/pro-solid-svg-icons';
import { far }from '@fortawesome/pro-regular-svg-icons';
import { fal }from '@fortawesome/pro-light-svg-icons';
import { fab }from '@fortawesome/free-brands-svg-icons';

import '../../css/custom.css';
import '../../../node_modules/jquery/dist/jquery.min';
import '../../../node_modules/bootstrap/dist/js/bootstrap.bundle';

import Home from "../Home";
import About from "../About";
import Signup from "../Signup";
import Verify from "../Verify";
import Contact from "../Contact";
import Privacy from "../Privacy";
import Terms from "../Terms";
import Landlords from "../Landlords";
import Tenants from "../Tenants";
import Pricing from "../Pricing";
import ApplyApplication from "../apply/Application";
import ApplySubmitted from "../apply/Submitted";
import ApplyLanding from "../apply/Landing";
import PayLanding from "../pay/Landing";
import PayPayment from "../pay/Payment";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
import InvitationSplash from "../invitation/Splash";
import InvitationCustomer from "../invitation/Customer";
import OnboardingSplash from "../setup/Splash";
import OnboardingAdmin from "../setup/Admin";
import OnboardingManager from "../setup/Manager";
import OnboardingCustomer from "../setup/Customer";
import AdminDashboard from "../admin/dashboard/Dashboard";
import AdminRevenue from "../admin/dashboard/Revenue";
import AdminProcessing from "../admin/dashboard/Processing";
import AdminProperties from "../admin/properties/Properties";
import AdminProperty from "../admin/properties/Navigation";
import AdminTenants from "../admin/tenants/Tenants";
import AdminTenant from "../admin/tenants/Navigation";
import AdminCompanies from "../admin/companies/Companies";
import AdminCompanyInvoice from "../admin/companies/Invoice";
import AdminCompany from "../admin/companies/Navigation";
import AdminManagers from "../admin/managers/Managers";
import AdminManager from "../admin/managers/Navigation";
import AdminLandlords from "../admin/landlords/Landlords";
import AdminLandlordInvoice from "../admin/landlords/Invoice";
import AdminLandlord from "../admin/landlords/Navigation";
import AdminTransactions from "../admin/transactions/Transactions";
import AdminSystem from "../admin/system/System";
import AdminAccount from "../admin/account/Account";
import AdminAccountProfile from "../admin/account/Profile";
import AdminAccountPassword from "../admin/account/Password";
import AdminAdministrators from "../admin/system/Administrators";
import AdminApiAccounts from "../admin/system/ApiAccounts";
import AdminSystemAccounts from "../admin/system/SystemAccounts";
import AdminFeeProfiles from "../admin/system/DefaultFeeProfiles";
import AdminServiceFees from "../admin/system/DefaultServiceFees";
import AdminBanks from "../admin/system/Banks";
import AdminBinMappings from "../admin/system/BinMappings";
import AdminImports from "../admin/system/Imports";
import AdminEmails from "../admin/system/Emails";
import AdminEvents from "../admin/system/Events";
import AdminBroadcasts from "../admin/system/Broadcasts";
import AdminFundsTransfers from "../admin/system/FundsTransfers";
import AdminRoles from "../admin/system/Roles";
import AdminStatHolidays from "../admin/system/StatHolidays";
import ManagerDashboard from "../manager/dashboard/Dashboard";
import ManagerProperties from "../manager/properties/Properties";
import ManagerProperty from "../manager/properties/Navigation";
import ManagerManagers from "../manager/managers/Managers";
import ManagerManager from "../manager/managers/Navigation";
import ManagerTenants from "../manager/tenants/Tenants";
import ManagerTenant from "../manager/tenants/Navigation";
import ManagerTenantPayment from "../manager/tenants/Payment";
import ManagerApplications from "../manager/applications/Applications";
import ManagerApplication from "../manager/applications/Navigation";
import ManagerTransactions from "../manager/transactions/Transactions";
import ManagerPayment from "../manager/transactions/Payment";
import ManagerReports from "../manager/reports/Reports";
import ManagerTransactionReport from "../manager/reports/Transactions";
import ManagerEftReport from "../manager/reports/Efts";
import ManagerTenantReport from "../manager/reports/Tenants";
import ManagerSettings from "../manager/settings/Settings";
import ManagerProfile from "../manager/settings/Profile";
import ManagerBilling from "../manager/settings/Billing";
import ManagerCharges from "../manager/settings/ChargeCodes";
import ManagerImports from "../manager/settings/Imports";
import ManagerPassword from "../manager/settings/Password";
import LandlordTransactions from "../landlord/transactions/Transactions";
import LandlordProperties from "../landlord/properties/Properties";
import LandlordProperty from "../landlord/properties/Navigation";
import LandlordTenants from "../landlord/tenants/Tenants";
import LandlordTenant from "../landlord/tenants/Navigation";
import LandlordApplications from "../landlord/applications/Applications";
import LandlordApplication from "../landlord/applications/Navigation";
import LandlordAccount from "../landlord/account/Account";
import LandlordProfile from "../landlord/account/Profile";
import LandlordBilling from "../landlord/account/Billing";
import LandlordBanking from "../landlord/account/Banking";
import LandlordPassword from "../landlord/account/Password";
import LandlordSignup from "../landlord/signup/Signup";
import CustomerPayments from "../customer/payments/Payments";
import CustomerPayment from "../customer/payments/Payment";
import CustomerMethods from "../customer/payments/PaymentMethods";
import CustomerDocuments from "../customer/documents/Documents";
import CustomerAccount from "../customer/account/Account";
import CustomerProfile from "../customer/account/Profile";
import CustomerPassword from "../customer/account/Password";

import NotFound from "../NotFound";

library.add(fab, fas, far, fal);

class App extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        // Initialize localization
        const locale = localStorage.getItem('locale') != null ? localStorage.getItem('locale') : 'en';

        this.state = {
            token_type: localStorage.getItem('token_type'),
            access_token: localStorage.getItem('access_token'),
            expires_in: localStorage.getItem('expires_in'),
            status: localStorage.getItem('status'),
            localization: {
                locale: locale,
                messages: localeMessages_en
            }
        };

        // Add available localizations
        addLocaleData([...locale_en, ...locale_fr]);

        this.handleChangeLocale = this.handleChangeLocale.bind(this);
        this.refreshAppState = this.refreshAppState.bind(this);
    }

    /**
     * Handle localization configuration once the page has rendered. Grabs localization from localStorage, if it
     * already exists, otherwise defaults to English.
     */
    componentDidMount() {

        const locale = localStorage.getItem('locale') != null ? localStorage.getItem('locale') : 'en';

        this.handleChangeLocale(null, locale);
    }

    /**
     * Handle changing the localization of the website. Defaults to English if no other localization is provided.
     *
     * @param event - The event container.
     * @param locale - The new locale to be set.
     */
    handleChangeLocale(event, locale) {

        if(event != null) { event.preventDefault(); }

        switch(locale) {

            case 'en':
                this.setState({ localization: { locale: 'en', messages: localeMessages_en } });
                localStorage.setItem('locale', 'en');
                break;

            case 'fr':
                this.setState({ localization: { locale: 'fr', messages: localeMessages_fr } });
                localStorage.setItem('locale', 'fr');
                break;

            default:
                this.setState({ localization: { locale: 'en', messages: localeMessages_en } });
                localStorage.setItem('locale', 'en');
                break;
        }
    }

    /**
     * Trigger a mass re-rendering of the application by refreshing the state of this App component. Pulls all potential
     * values from the local storage sets it in the state.
     */
    refreshAppState() {

        this.setState({
            token_type: localStorage.getItem('token_type'),
            access_token: localStorage.getItem('access_token'),
            expires_in: localStorage.getItem('expires_in'),
            status: localStorage.getItem('status'),
            screening: localStorage.getItem('screening'),
            broadcast_messages: localStorage.getItem('broadcast_messages')
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The core application container, wrapped with a localization provider tag. Children are all other
     * routes, found in the parent index.js file.
     */
    render() {

        return(
            <IntlProvider key={this.state.localization.locale} locale={this.state.localization.locale} messages={this.state.localization.messages}>
                <div>
                    <Navigation token={this.state.access_token} locale={this.state.localization.locale} broadcastMessages={this.state.broadcast_messages} handleChangeLocale={this.handleChangeLocale} refreshAppState={this.refreshAppState} />
                    <Switch>

                        <Route path="/login" render={(props) => (<Login refreshAppState={this.refreshAppState} {...props} />)} />
                        <Route path="/logout" render={(props) => (<Logout refreshAppState={this.refreshAppState} {...props} />)} />

                        <Switch>

                            <Route path="/" exact render={(props) => (<Home refreshAppState={this.refreshAppState} {...props} />)}  />
                            <Route path="/about" component={About} />
                            <Route path="/signup" component={Signup} />
                            <Route path="/privacy" component={Privacy} />
                            <Route path="/terms" component={Terms} />
                            <Route path="/landlords" render={(props) => (<Landlords refreshAppState={this.refreshAppState} {...props} />)} />
                            <Route path="/tenants" component={Tenants} />
                            <Route path="/pricing" component={Pricing} />
                            <Route path="/verify" component={Verify} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/forgot-password" component={ForgotPassword} />
                            <Route path="/reset-password" component={ResetPassword} />

                            <Route exact path="/apply" component={ApplyApplication} />
                            <Route exact path="/apply/submitted" component={ApplySubmitted} />
                            <Route exact path="/apply/:pageName" component={ApplyLanding} />

                            <Route exact path="/pay/:pageName" component={PayLanding} />
                            <Route exact path="/pay/:pageName/payment" component={PayPayment} />

                            <Route exact path="/invitation/:invitationId" render={(props) => (<InvitationSplash refreshAppState={this.refreshAppState} {...props} />)} />
                            <Route exact path="/invitation/:invitationId/customer" render={(props) => (<InvitationCustomer refreshAppState={this.refreshAppState} {...props} />)} />

                            <Route exact path="/onboarding/:userId" render={(props) => (<OnboardingSplash refreshAppState={this.refreshAppState} {...props} />)} />
                            <Route exact path="/onboarding/:userId/admin" render={(props) => (<OnboardingAdmin refreshAppState={this.refreshAppState} {...props} />)} />
                            <Route exact path="/onboarding/:userId/manager" render={(props) => (<OnboardingManager refreshAppState={this.refreshAppState} {...props} />)} />
                            <Route exact path="/onboarding/:userId/customer" render={(props) => (<OnboardingCustomer refreshAppState={this.refreshAppState} {...props} />)} />

                            <PrivateRoute exact path="/admin/dashboard" type="TYPE_ADMIN" component={AdminDashboard} />
                            <PrivateRoute exact path="/admin/dashboard/revenue" type="TYPE_ADMIN" component={AdminRevenue} />
                            <PrivateRoute exact path="/admin/dashboard/processing" type="TYPE_ADMIN" component={AdminProcessing} />
                            <PrivateRoute exact path="/admin/tenants" type="TYPE_ADMIN" component={AdminTenants} />
                            <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/*" type="TYPE_ADMIN" component={AdminTenant} refreshAppState={this.refreshAppState} />
                            <PrivateRoute exact path="/admin/companies" type="TYPE_ADMIN" component={AdminCompanies} />
                            <PrivateRoute exact path="/admin/companies/:companyId/invoice" type="TYPE_ADMIN" component={AdminCompanyInvoice} />
                            <PrivateRoute exact path="/admin/companies/:companyId/*" type="TYPE_ADMIN" component={AdminCompany} />
                            <PrivateRoute exact path="/admin/properties" type="TYPE_ADMIN" component={AdminProperties} />
                            <PrivateRoute exact path="/admin/properties/:propertyId/*" type="TYPE_ADMIN" component={AdminProperty} refreshAppState={this.refreshAppState} />
                            <PrivateRoute exact path="/admin/managers" type="TYPE_ADMIN" component={AdminManagers} />
                            <PrivateRoute exact path="/admin/managers/:managerId/*" type="TYPE_ADMIN" component={AdminManager} refreshAppState={this.refreshAppState} />
                            <PrivateRoute exact path="/admin/landlords" type="TYPE_ADMIN" component={AdminLandlords} />
                            <PrivateRoute exact path="/admin/landlords/:landlordId/invoice" type="TYPE_ADMIN" component={AdminLandlordInvoice} />
                            <PrivateRoute exact path="/admin/landlords/:landlordId/*" type="TYPE_ADMIN" component={AdminLandlord} refreshAppState={this.refreshAppState} />
                            <PrivateRoute exact path="/admin/transactions" type="TYPE_ADMIN" component={AdminTransactions} />
                            <PrivateRoute exact path="/admin/system" type="TYPE_ADMIN" component={AdminSystem} />
                            <PrivateRoute exact path="/admin/system/administrators" type="TYPE_ADMIN" component={AdminAdministrators} />
                            <PrivateRoute exact path="/admin/system/apis" type="TYPE_ADMIN" component={AdminApiAccounts} />
                            <PrivateRoute exact path="/admin/system/accounts" type="TYPE_ADMIN" component={AdminSystemAccounts} refreshAppState={this.refreshAppState} />
                            <PrivateRoute exact path="/admin/system/fees" type="TYPE_ADMIN" component={AdminFeeProfiles} />
                            <PrivateRoute exact path="/admin/system/services" type="TYPE_ADMIN" component={AdminServiceFees} />
                            <PrivateRoute exact path="/admin/system/bins" type="TYPE_ADMIN" component={AdminBinMappings} />
                            <PrivateRoute exact path="/admin/system/banks" type="TYPE_ADMIN" component={AdminBanks} />
                            <PrivateRoute exact path="/admin/system/imports" type="TYPE_ADMIN" component={AdminImports} />
                            <PrivateRoute exact path="/admin/system/emails" type="TYPE_ADMIN" component={AdminEmails} />
                            <PrivateRoute exact path="/admin/system/events" type="TYPE_ADMIN" component={AdminEvents} />
                            <PrivateRoute exact path="/admin/system/broadcasts" type="TYPE_ADMIN" component={AdminBroadcasts} />
                            <PrivateRoute exact path="/admin/system/efts" type="TYPE_ADMIN" component={AdminFundsTransfers} />
                            <PrivateRoute exact path="/admin/system/roles" type="TYPE_ADMIN" component={AdminRoles} />
                            <PrivateRoute exact path="/admin/system/holidays" type="TYPE_ADMIN" component={AdminStatHolidays} />
                            <PrivateRoute exact path="/admin/account" type="TYPE_ADMIN" component={AdminAccount} />
                            <PrivateRoute exact path="/admin/account/profile" type="TYPE_ADMIN" component={AdminAccountProfile} />
                            <PrivateRoute exact path="/admin/account/password" type="TYPE_ADMIN" component={AdminAccountPassword} />

                            <PrivateRoute exact path="/manager/dashboard" type="TYPE_MANAGER" component={ManagerDashboard} />
                            <PrivateRoute exact path="/manager/properties" type="TYPE_MANAGER" component={ManagerProperties} />
                            <PrivateRoute exact path="/manager/properties/:propertyId/*" type="TYPE_MANAGER" component={ManagerProperty} />
                            <PrivateRoute exact path="/manager/managers" type="TYPE_MANAGER" component={ManagerManagers} />
                            <PrivateRoute exact path="/manager/managers/:managerId/*" type="TYPE_MANAGER" component={ManagerManager} />
                            <PrivateRoute exact path="/manager/tenants" type="TYPE_MANAGER" component={ManagerTenants} />
                            <PrivateRoute exact path="/manager/tenants/:propertyLeaseId/payment" type="TYPE_MANAGER" component={ManagerTenantPayment} />
                            <PrivateRoute exact path="/manager/tenants/:propertyLeaseId/*" type="TYPE_MANAGER" component={ManagerTenant} />
                            <PrivateRoute exact path="/manager/applications" type="TYPE_MANAGER" component={ManagerApplications} />
                            <PrivateRoute exact path="/manager/applications/:leaseApplicationId/*" type="TYPE_MANAGER" component={ManagerApplication} />
                            <PrivateRoute exact path="/manager/transactions" type="TYPE_MANAGER" component={ManagerTransactions} />
                            <PrivateRoute exact path="/manager/transactions/payment" type="TYPE_MANAGER" component={ManagerPayment} />
                            <PrivateRoute exact path="/manager/reports" type="TYPE_MANAGER" component={ManagerReports} />
                            <PrivateRoute exact path="/manager/reports/transactions" type="TYPE_MANAGER" component={ManagerTransactionReport} />
                            <PrivateRoute exact path="/manager/reports/efts" type="TYPE_MANAGER" component={ManagerEftReport} />
                            <PrivateRoute exact path="/manager/reports/tenants" type="TYPE_MANAGER" component={ManagerTenantReport} />
                            <PrivateRoute exact path="/manager/settings" type="TYPE_MANAGER" component={ManagerSettings} />
                            <PrivateRoute exact path="/manager/settings/profile" type="TYPE_MANAGER" component={ManagerProfile} />
                            <PrivateRoute exact path="/manager/settings/billing" type="TYPE_MANAGER" component={ManagerBilling} />
                            <PrivateRoute exact path="/manager/settings/charges" type="TYPE_MANAGER" component={ManagerCharges} />
                            <PrivateRoute exact path="/manager/settings/imports" type="TYPE_MANAGER" component={ManagerImports} />
                            <PrivateRoute exact path="/manager/settings/password" type="TYPE_MANAGER" component={ManagerPassword} />

                            <PrivateRoute exact path="/landlord/transactions" type="TYPE_LANDLORD" component={LandlordTransactions} />
                            <PrivateRoute exact path="/landlord/properties" type="TYPE_LANDLORD" component={LandlordProperties} />
                            <PrivateRoute exact path="/landlord/properties/:propertyId/*" type="TYPE_LANDLORD" component={LandlordProperty} />
                            <PrivateRoute exact path="/landlord/tenants" type="TYPE_LANDLORD" component={LandlordTenants} />
                            <PrivateRoute exact path="/landlord/tenants/:propertyLeaseId/*" type="TYPE_LANDLORD" component={LandlordTenant} />
                            <PrivateRoute exact path="/landlord/applications" type="TYPE_LANDLORD" component={LandlordApplications} />
                            <PrivateRoute exact path="/landlord/applications/:leaseApplicationId/*" type="TYPE_LANDLORD" component={LandlordApplication} />
                            <PrivateRoute exact path="/landlord/account" type="TYPE_LANDLORD" component={LandlordAccount} />
                            <PrivateRoute exact path="/landlord/account/profile" type="TYPE_LANDLORD" component={LandlordProfile} />
                            <PrivateRoute exact path="/landlord/account/billing" type="TYPE_LANDLORD" component={LandlordBilling} />
                            <PrivateRoute exact path="/landlord/account/banking" type="TYPE_LANDLORD" component={LandlordBanking} refreshAppState={this.refreshAppState} />
                            <PrivateRoute exact path="/landlord/account/password" type="TYPE_LANDLORD" component={LandlordPassword} />
                            <PrivateRoute exact path="/landlord/signup/application" type="TYPE_LANDLORD" component={LandlordSignup} refreshAppState={this.refreshAppState} />

                            <PrivateRoute exact path="/customer/payments" type="TYPE_CUSTOMER" component={CustomerPayments} />
                            <PrivateRoute exact path="/customer/payments/payment" type="TYPE_CUSTOMER" component={CustomerPayment} />
                            <PrivateRoute exact path="/customer/payments/methods" type="TYPE_CUSTOMER" component={CustomerMethods} />
                            <PrivateRoute exact path="/customer/documents" type="TYPE_CUSTOMER" component={CustomerDocuments} />
                            <PrivateRoute exact path="/customer/account" type="TYPE_CUSTOMER" component={CustomerAccount} />
                            <PrivateRoute exact path="/customer/account/profile" type="TYPE_CUSTOMER" component={CustomerProfile} />
                            <PrivateRoute exact path="/customer/account/password" type="TYPE_CUSTOMER" component={CustomerPassword} />

                            <Route path="" component={NotFound} />

                        </Switch>
                    </Switch>
                    <Footer />
                </div>
            </IntlProvider>
        )
    }
}

export default App;
