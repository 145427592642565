import React from 'react';
import Propertii from "../../common/Propertii";
import ChangePassword from "../../common/Password";
import Breadcrumb from "../../common/Breadcrumb";

class Password extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The change password interface for landlords.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/landlord/account" parentPage="Account" childPage="Change Password" />

                    <ChangePassword backRedirect="/landlord/account" userId={this.props.userSession.sessionRole.id} saveMessage="landlord.account.password.saved" />

                </div>
            </div>
        )
    };
}

export default Password;