import React, { Component } from 'react';
import FieldSelect from "../common/FieldSelect";
import {FormattedMessage} from "react-intl";

class FieldCountry extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A select field component, specialized for countries.
     */
    render() {

        return(
            <FieldSelect id={this.props.id} label={this.props.label} labelClass={this.props.labelClass} fieldClass={this.props.fieldClass}
                         fieldColumns={this.props.fieldColumns} labelColumns={this.props.labelColumns} handleChange={this.props.handleChange}
                         disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value}
                         required={this.props.required}>

                <FormattedMessage id="field.country.default">
                    {(country) => <option value="">{country}</option>}
                </FormattedMessage>

                <option value="CA">Canada</option>
                <option value="US">United States</option>

            </FieldSelect>
        )
    };
}

FieldCountry.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    disabled: false
};


export default FieldCountry;
