import React from 'react';
import Propertii from "../../common/Propertii";
import ImportList from "../../common/ImportList";
import Alert from "../../common/Alert";

class Imports extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The import interface.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} validationType="primary" />

                <ImportList token={this.props.token} companyId={this.props.match.params.companyId} searchFilters={true} importModel="FILE_IMPORT" createMessage="admin.companies.imports.created" />

            </React.Fragment>
        )
    };
}

export default Imports;