import React, { Component } from 'react';
import FieldSelect from "../common/FieldSelect";

class FieldUserType extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A select field component, specialized for user types.
     */
    render() {

        return(
            <FieldSelect id={this.props.id} label={this.props.label} model={this.props.model} parent={this.props.parent} value={this.props.value}>
                <option value="">Select a user type...</option>
                <option value="TYPE_ADMIN">Administrator</option>
                <option value="TYPE_CUSTOMER">Customer</option>
                <option value="TYPE_LANDLORD">Landlord</option>
                <option value="TYPE_MANAGER">Manager</option>
            </FieldSelect>
        )
    };
}

export default FieldUserType;
