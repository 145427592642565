import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Table from "../../common/Table";
import {FormattedMessage} from "react-intl";
import ButtonBack from "../../common/ButtonBack";
import Propertii from "../../common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import ButtonSave from "../../common/ButtonSave";
import FieldText from "../../common/FieldText";
import Modal from "../../common/Modal";
import Alert from "../../common/Alert";
import Spinner from "../../common/Spinner";
import Breadcrumb from "../../common/Breadcrumb";

class Banks extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            searchFilter: '',
            newBankInfo: false,
            bankInfo: {},
            bankInfoList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },
            bankInfoQuery: {
                orderBy: 'ASC',
                orderByFields: ['id'],
                conditionList: []
            },
            validationList: [],
        };

        this.searchBankInfos = this.searchBankInfos.bind(this);
        this.filterBankInfos = this.filterBankInfos.bind(this);
        this.viewBankInfo = this.viewBankInfo.bind(this);
        this.initBankInfo = this.initBankInfo.bind(this);
        this.saveBankInfo = this.saveBankInfo.bind(this);
        this.deleteBankInfo = this.deleteBankInfo.bind(this);
    }

    /**
     * Load the list of bank information records on mounting of the component.
     */
    componentDidMount() {

        this.searchBankInfos(1, 25, this.state.bankInfoQuery);
    }

    /**
     * Handle selecting a bank info record by bringing up the modal with bank info values pre-filled.
     *
     * @param bankInfoId - The ID of the bank info selected.
     */
    viewBankInfo(bankInfoId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/bank_info/${bankInfoId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                bankInfo: response.data,
                newBankInfo: false
            }));

            $('#bank-info').modal('show');

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Initialize a new blank instance of a bank info object.
     */
    initBankInfo() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/bank_info/new`).then(response => {
            this.setState(prevState => ({
                ...prevState,
                bankInfo: response.data,
                newBankInfo: true
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Delete the selected bank info record.
     */
    deleteBankInfo() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/fee_profile/${this.state.bankInfo.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.system.banks.deleted'
                    }
                }],
            });

            this.searchBankInfos(this.state.bankInfoList.page, this.state.bankInfoList.recordsPerPage, this.state.bankInfoQuery);

            $('#bank-info').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }


    /**
     * Handle the submission of the create/update bank info record form. Updates or creates a new bank info object
     * accordingly.
     *
     * @param event - The event container.
     */
    saveBankInfo(event) {

        event.preventDefault();

        if(this.state.newBankInfo) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, this.state.bankInfo, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.system.banks.created'
                        }
                    }],
                });

                this.searchBankInfos(this.state.bankInfoList.page, this.state.bankInfoList.recordsPerPage, this.state.bankInfoQuery);

                $('#bank-info').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, this.state.bankInfo, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved'
                        }
                    }],
                });

                this.searchBankInfos(this.state.bankInfoList.page, this.state.bankInfoList.recordsPerPage, this.state.bankInfoQuery);

                $('#bank-info').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Update the data table of bank info records.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchBankInfos(page, recordsPerPage, query) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/bank_info/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                bankInfoList: response.data,
                bankInfoQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle submitting the search filter field by adjusting the bank info search query and initiating a new search.
     *
     * @param event - The event container.
     * @param searchFilter - The value within the search filter field.
     */
    filterBankInfos(event, searchFilter) {

        if (event != null) {
            event.preventDefault();
        }

        let bankInfoQuery = {
            orderBy: 'ASC',
            orderByFields: ['id'],
            conditionList: []
        };

        if (searchFilter !== '') {

            bankInfoQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: searchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'name',
                    operator: 'EQUALS',
                    fieldValue: searchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'institutionCode',
                    operator: 'EQUALS',
                    fieldValue: searchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'routingNumber',
                    operator: 'EQUALS',
                    fieldValue: searchFilter
                },
            );
        }

        this.setState({
            bankInfoQuery: bankInfoQuery
        });

        this.searchBankInfos(1, 25, bankInfoQuery);
    }


    /**
     * Render the component.
     *
     * @returns {*} - The bank info list and management interface.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="Banks" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card card-primary border-primary">
                        <div className="card-body">
                            <p className="card-text">
                                The bank information records below have been imported to our local database using an external file. Please do not tamper with these records unless you have been instructed to do so.
                            </p>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Banks
                                </div>
                                <div className="col text-right">
                                    <div data-toggle="modal" data-target="#bank-info" className="btn btn-primary btn-sm" onClick={() => this.initBankInfo()}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Add Bank
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-header gotham border-top py-3 bg-secondary">
                            <form onSubmit={(event) => this.filterBankInfos(event, this.state.searchFilter)}>
                                <div className="media">
                                    <div className="media-body align-self-center mr-3">
                                        <FieldText id="searchFilter" label="Search" labelClass="d-none" fieldColumns="12"
                                                   labelColums="0" placeholder="Filter by ID, name, institution number, or routing number..."
                                                   parent={this} value={this.state.searchFilter} />
                                    </div>
                                    <div className="align-self-center text-right">
                                        <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                            <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <Table columns={{name: 'Name', institutionCode: 'Institution Number', routingNumber: 'Routing Number'}}
                               columnWidths={['50%', '25%', '25%']}
                               headerClass="c-pointer"
                               data={this.state.bankInfoList}
                               query={this.state.bankInfoQuery}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchBankInfos}>
                            <tbody>
                            {this.state.bankInfoList.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.viewBankInfo(data.id)} className="c-pointer">
                                        <td>
                                            <div className="">
                                                {data.name}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.institutionCode}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.routingNumber}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="bank-info" tabIndex="-1" role="dialog" aria-labelledby="bank-info-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.saveBankInfo}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="bank-info-label">
                                            {this.state.newBankInfo &&
                                            <div className="">
                                                Add Bank
                                            </div>
                                            }
                                            {!this.state.newBankInfo &&
                                            <div className="">
                                                Edit Bank
                                            </div>
                                            }
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldText id="name" label="Name" model="bankInfo" parent={this} value={this.state.bankInfo['name']} />

                                        <FieldText id="institutionCode" label="Institution Number" model="bankInfo" parent={this} value={this.state.bankInfo['institutionCode']} />

                                        <FieldText id="routingNumber" label="Routing Number" model="bankInfo" parent={this} value={this.state.bankInfo['routingNumber']} />

                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom d-block">
                                        <div className="row">
                                            <div className="col-4">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#bank-info").modal("hide")}>Close</button>
                                            </div>
                                            <div className="col-8 text-right">

                                                {!this.state.newBankInfo &&
                                                <div className="btn btn-lg btn-primary ml-2" onClick={() => {$("#bank-info").modal("hide"); $("#delete-bank-info").modal("show");}}>
                                                    Delete
                                                </div>
                                                }

                                                <ButtonSave />

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <Modal id="delete-bank-info" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Bank"
                           body="Are you sure you would like to delete this bank information record?">
                        <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => {$("#bank-info").modal("show")}}>
                            <FormattedMessage id="button.back" />
                        </button>
                        <button onClick={() => {this.deleteBankInfo()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                            Delete Bank
                        </button>
                    </Modal>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default Banks;