import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import ChargeCodeList from "../../common/ChargeCodes";

class ChargeCodes extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The company charge codes management component.
     */
    render() {

        return(
            <React.Fragment>

                <div className="card card-primary border-primary">
                    <div className="card-body">
                        <p className="card-text">
                            Please note that by adding at least one custom charge code below, the generic default list of charges will not be displayed for this company.
                        </p>
                    </div>
                </div>

                <ChargeCodeList companyId={this.props.match.params.companyId} createMessage="admin.companies.charges.created" deleteMessage="admin.companies.charges.deleted" duplicateMessage="admin.companies.charges.duplicate" />

            </React.Fragment>
        )
    };
}

ChargeCodes.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ChargeCodes);