import React from 'react';
import axios from "axios";
import * as constants from "../../util/constants";
import Table from "../common/Table";
import Propertii from "../common/Propertii";
import {FormattedMessage, FormattedNumber, injectIntl, intlShape} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import FieldCheckbox from "../common/FieldCheckbox";
import Alert from "../common/Alert";
import FieldText from "../common/FieldText";
import FieldSelect from "./FieldSelect";
import Spinner from "./Spinner";
import {Link} from "react-router-dom";
import FieldCharges from "./FieldCharges";
import Modal from "./Modal";
import Moment from "react-moment";
import FieldProperty from "./FieldProperty";
import FieldDate from "./FieldDate";

class Tenants extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        let propertyLeaseConditionList = [];
        let invitationConditionList = [];

        invitationConditionList.push(
            {
                type: 'BOOLEAN',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                operator: 'EQUALS',
                fieldName: 'accepted',
                fieldValue: false
            }
        );

        if(this.props.propertyId) {
            propertyLeaseConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'propertyId',
                    fieldValue: this.props.propertyId
                }
            );
            invitationConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'propertyId',
                    fieldValue: this.props.propertyId
                }
            );
        }

        if(this.props.companyId) {
            propertyLeaseConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'companyId',
                    fieldValue: this.props.companyId
                }
            );
            invitationConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'p.companyId',
                    fieldValue: this.props.companyId
                }
            );
        }

        if(this.props.customerId) {
            propertyLeaseConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'userId',
                    fieldValue: this.props.customerId
                }
            );
        }

        this.state = {

            selectedTab: '',

            propertyLeaseSearchFilter: '',
            propertyLeaseStatusFilter: '',
            invitationSearchFilter: '',
            invitationAcceptedFilter: false,

            propertyLease: {},
            invitation: {},
            property: {},
            customer: {},
            company: {},

            charges: [],

            companyId: '',
            integrationId: '',
            customChargeCodes: [],

            activeModal: '',

            recurring: false,

            integrationSearch: {
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                tenantId: '',
                propertyId: ''
            },

            integrationCustomerList: [],

            propertyLeaseList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            propertyList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            invitationList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            propertyLeaseQuery: {
                orderBy: 'ASC',
                orderByFields: ['c.firstName'],
                conditionList: propertyLeaseConditionList,
                joins: {
                    c: {
                        targetRecordType: 'TYPE_CUSTOMER',
                        joinField: 'userId',
                        alias: 'c',
                        returnFields: ['firstName', 'lastName', 'email', 'phone']
                    },
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'propertyId',
                        alias: 'p',
                        returnFields: ['propertyName', 'street1', 'street2', 'city', 'country', 'province', 'postalCode']
                    },
                },
            },

            invitationQuery: {
                orderBy: 'DESC',
                orderByFields: ['e.lastSentDate'],
                conditionList: invitationConditionList,
                joins: {
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'propertyId',
                        alias: 'p',
                        returnFields: ['propertyName', 'street1', 'companyId']
                    },
                    e: {
                        targetRecordType: 'TYPE_EMAIL',
                        joinField: 'sentEmailId',
                        alias: 'e',
                        returnFields: ['lastSentDate']
                    },
                    c: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'p.companyId',
                        alias: 'c',
                        returnFields: ['id', 'integrationId', 'chargeCodes', 'parentId']
                    },
                    pc: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'c.parentId',
                        alias: 'pc',
                        returnFields: ['integrationId', 'chargeCodes']
                    }
                },
            },

            validationList: [],

        };

        this.selectTab = this.selectTab.bind(this);

        this.searchProperties = this.searchProperties.bind(this);
        this.searchPropertyLeases = this.searchPropertyLeases.bind(this);
        this.filterPropertyLeases = this.filterPropertyLeases.bind(this);

        this.searchInvitations = this.searchInvitations.bind(this);
        this.filterInvitations = this.filterInvitations.bind(this);

        this.clearFilters  = this.clearFilters.bind(this);
        this.redirectPropertyLease = this.redirectPropertyLease.bind(this);

        this.initInvitation = this.initInvitation.bind(this);
        this.saveInvitation = this.saveInvitation.bind(this);
        this.selectInvitation = this.selectInvitation.bind(this);
        this.deleteInvitation = this.deleteInvitation.bind(this);

        this.initCustomer = this.initCustomer.bind(this);
        this.saveCustomer = this.saveCustomer.bind(this);

        this.initIntegrationCustomers = this.initIntegrationCustomers.bind(this);
        this.searchIntegrationCustomers = this.searchIntegrationCustomers.bind(this);
        this.selectIntegrationCustomer = this.selectIntegrationCustomer.bind(this);

        this.addCharge = this.addCharge.bind(this);
        this.removeCharge = this.removeCharge.bind(this);
        this.importCharges = this.importCharges.bind(this);

        this.handleChangeAccountNumber = this.handleChangeAccountNumber.bind(this);
        this.handleChangeProperty = this.handleChangeProperty.bind(this);
    }

    /**
     * Retrieve list of tenants with active property leases, a list of all invitations, and a list of all lease
     * applications on mounting of the component. If a company ID is provided in the props, fetch the company data as
     * well.
     */
    componentDidMount() {

        if(this.props.history && this.props.history.location.state && this.props.history.location.state.invitation) {
            this.selectTab('INVITATIONS');
            this.selectInvitation(this.props.history.location.state.invitation);
        }

        else {
            this.selectTab('TENANTS');
        }
    }

    /**
     * Redirect the user to the view tenant component when clicking a property lease from the list.
     *
     * @param propertyLease - The property lease selected.
     */
    redirectPropertyLease(propertyLease) {

        this.props.history.push(`/${this.props.userType.substring(5).toLowerCase()}/tenants/${propertyLease.id}/edit`);
    }

    /**
     * Select a tab from the list of navigation tabs.
     *
     * @param tabId - The ID of the tab selected.
     */
    selectTab(tabId) {

        switch(tabId) {

            case "TENANTS":

                this.searchPropertyLeases(1, 25, this.state.propertyLeaseQuery);
                $('#invitations').tab('dispose');
                $('#tenants').tab('show');

                break;

            case "INVITATIONS":

                this.searchInvitations(1, 25, this.state.invitationQuery);
                $('#tenants').tab('dispose');
                $('#invitations').tab('show');

                break;

            default:

                this.searchPropertyLeases(1, 25, this.state.propertyLeaseQuery);
                $('#invitations').tab('dispose');
                $('#tenants').tab('show');

                break;

        }
    }

    /**
     * Update the data table of property leases.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchPropertyLeases(page, recordsPerPage, query) {

        this.setState({
            spinner: true,
            selectedTab: 'TENANTS',
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let propertyLeases = response.data;

            propertyLeases.records.forEach((propertyLease, key) => {

                let totalCharges = 0;

                propertyLease.charges.forEach((data, key) => {
                    totalCharges += data.amount;
                });

                propertyLeases.records[key].totalCharges = totalCharges;
            });

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                invitationList: {
                    page: '',
                    recordsPerPage: '',
                    totalPages: '',
                    totalRecordCount: '',
                    records: [
                        {}
                    ]
                },
                propertyLeaseList: {
                    ...response.data,
                    emptyUnfilteredList: this.state.propertyLeaseSearchFilter === '' && response.data.records.length === 0
                },
                propertyLeaseQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Update the data table of invitations.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchInvitations(page, recordsPerPage, query) {

        this.setState({
            spinner: true,
            selectedTab: 'INVITATIONS',
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/invitation/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let invitations = response.data;

            invitations.records.forEach((invitation, key) => {

                let totalCharges = 0;

                invitation.charges.forEach((data, key) => {
                    totalCharges += data.amount;
                });

                invitations.records[key].totalCharges = totalCharges;
            });

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                propertyLeaseList: {
                    page: '',
                    recordsPerPage: '',
                    totalPages: '',
                    totalRecordCount: '',
                    records: [
                        {}
                    ]
                },
                invitationList: {
                    ...invitations,
                    emptyUnfilteredList: this.state.invitationSearchFilter === '' && response.data.records.length === 0
                },
                invitationQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Initialize a new instance of an invitation object when the user initiates a new invitation.
     */
    initInvitation() {

        $('#onboard-tenant').modal('hide');

        this.searchProperties();

        axios.get(`${constants.REACT_APP_HOST_API_URL}/invitation/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                activeModal: 'invite-tenant',
                invitation: {
                    ...response.data,
                    userType: this.props.userType,
                    userId: this.props.userId,
                    propertyId: this.props.propertyId !== null ? this.props.propertyId : null,
                },
                charges: [],
                integrationSearch: {
                    email: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    tenantId: '',
                    propertyId: ''
                },
                integrationCustomerList: [],
            }));

            $('#invite-tenant').modal('show');

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Select an existing invitation.
     *
     * @param invitation - The invitation selected.
     */
    selectInvitation(invitation) {

        this.setState(prevState => ({
            ...prevState,
            activeModal: 'invite-tenant',
            invitation: {
                ...invitation,
                selectedProperty: {
                    value: invitation.propertyId,
                    label: invitation.joins.p.street1
                },
            },
            charges: invitation.charges && invitation.charges.length > 0 ? invitation.charges : [{}],
            recurring: !!invitation.recurringStartDate,
        }), () => {

            this.searchProperties();

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${invitation.joins.p.companyId}/fallbackfields`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    companyId: invitation.joins.p.companyId,
                    integrationId: response.data.integrationId,
                    customChargeCodes: response.data.chargeCodes,
                }));

            }).catch(error => {
                console.error(error);
            });

        });

        $('#invite-tenant').modal('show');
    }

    /**
     * Handle the submission of the invitation form.
     *
     * @param event - The event container.
     */
    saveInvitation(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true,
        }));

        let invitation = this.state.invitation;

        // Perform a null check on an empty first charge
        if(this.state.charges[0]) {
            invitation.charges = (this.state.charges[0].code == null || this.state.charges[0].amount == null) ? null : this.state.charges;
        }

        if(this.state.invitation.createDate == null) {

            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, invitation, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                }));

                this.setState({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'common.tenants.invitation.sent'
                        },
                        values: {
                            email: this.state.invitation.email
                        }
                    }],
                });

                this.selectTab('INVITATIONS');

                $('#invite-tenant').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });

        } else {

            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, invitation, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'common.tenants.invitation.sent'
                        },
                        values: {
                            email: this.state.invitation.email
                        }
                    }],
                });

                this.selectTab('INVITATIONS');

                $('#invite-tenant').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });

        }

        window.scrollTo(0, 0);
    }

    /**
     * Delete an existing invitation.
     *
     * @param invitation - The invitation model to delete.
     */
    deleteInvitation(invitation) {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/invitation/${invitation.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.tenants.invitation.deleted'
                    },
                    values: {
                        firstName: invitation.firstName,
                        lastName: invitation.lastName
                    }
                }],
            });

            this.searchInvitations(1, 25, this.state.invitationQuery);

            $('#invite-tenant').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle submitting the property leases search filter field by adjusting the search query and initiating a new
     * search.
     *
     * @param event - The event container.
     */
    filterPropertyLeases(event) {

        if(event != null) {
            event.preventDefault();
        }

        this.setState({
            spinner: true
        });

        let propertyLeaseQuery = this.state.propertyLeaseQuery;

        propertyLeaseQuery.conditionList = [];

        if (this.state.propertyLeaseSearchFilter === '') {

            if (this.props.propertyId) {
                propertyLeaseQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'propertyId',
                        fieldValue: this.props.propertyId
                    }
                );
            }

            if (this.props.companyId) {
                propertyLeaseQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'companyId',
                        fieldValue: this.props.companyId
                    }
                );
            }

            if (this.props.customerId) {
                propertyLeaseQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'userId',
                        fieldValue: this.props.customerId
                    }
                );
            }
        }

        if (this.state.propertyLeaseSearchFilter !== '') {

            propertyLeaseQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.state.propertyLeaseSearchFilter
                }
            );

            // Only search by phone if the filter is at least 6 characters long
            propertyLeaseQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'c.phone',
                    operator: 'EQUALS',
                    fieldValue: this.state.propertyLeaseSearchFilter.replace(/\D/g,"")
                },
            );

            propertyLeaseQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'c.firstName,c.lastName,c.email,unit,accountNumber,p.street1',
                    operator: 'MATCH',
                    fieldValue: this.state.propertyLeaseSearchFilter
                }
            );
        }

        if(this.state.propertyLeaseStatusFilter !== '') {
            propertyLeaseQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: this.state.propertyLeaseStatusFilter
                }
            );
        }

        this.setState({
            propertyLeaseQuery: propertyLeaseQuery,
        });

        this.searchPropertyLeases(1, 25, propertyLeaseQuery);
    }

    /**
     * Handle submitting the invitations search filter field by adjusting the search query and initiating a new search.
     *
     * @param event - The event container.
     */
    filterInvitations(event) {

        if(event != null) {
            event.preventDefault();
        }

        this.setState({
            spinner: true
        });

        let invitationQuery = this.state.invitationQuery;

        invitationQuery.conditionList = [];

        if(this.state.invitationSearchFilter === '') {

            if(this.props.propertyId) {
                invitationQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'propertyId',
                        fieldValue: this.props.propertyId
                    }
                );
            }

            if(this.props.companyId) {
                invitationQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'p.companyId',
                        fieldValue: this.props.companyId
                    }
                );
            }

        }

        if(this.state.invitationSearchFilter !== '') {
            invitationQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.state.invitationSearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'firstName,lastName,email,unit,accountNumber,p.street1',
                    operator: 'MATCH',
                    fieldValue: this.state.invitationSearchFilter
                }
            );
        }

        invitationQuery.conditionList.push(
            {
                type: 'BOOLEAN',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                operator: 'EQUALS',
                fieldName: 'accepted',
                fieldValue: this.state.invitationAcceptedFilter === 'true'
            }
        );

        this.setState({
            invitationQuery: invitationQuery,
        });

        this.searchInvitations(1, 25, invitationQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter queries.
     */
    clearFilters() {

        this.setState({
            propertyLeaseSearchFilter: '',
            invitationSearchFilter: '',
            invitationAcceptedFilter: '',
        }, () => {

            this.filterPropertyLeases(null, '');
            this.filterInvitations(null, '');

        });
    }

    /**
     * Search for all properties related to the company in order to populate the property field when creating or
     * inviting a tenant.
     */
    searchProperties() {

        let conditionList = [];

        if(this.props.userType === 'TYPE_ADMIN') {
            conditionList = [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId || this.state.invitation.joins.p.companyId
                },
            ];
        }

        let propertyQuery = {
            orderBy: 'ASC',
            orderByFields: ['street1'],
            conditionList: conditionList,
        };

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property/search`, {
            orderBy: propertyQuery.orderBy,
            orderByFields: propertyQuery.orderByFields,
            conditionList: propertyQuery.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                propertyList: response.data.records,
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Initialize a new instance of a customer and property lease in order to allow the user to populate information for
     * creating a tenant.
     */
    initCustomer() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/new`).then(response => {

            this.setState(prevState => ({
                ...prevState,
                activeModal: 'create-tenant',
                customer: {
                    ...response.data
                },
                integrationSearch: {
                    email: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    tenantId: '',
                    propertyId: ''
                },
                charges: [],
                integrationCustomerList: [],
            }));

        }).catch(error => {
            console.error(error);
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property_lease/new`).then(response => {

            this.setState(prevState => ({
                ...prevState,
                propertyLease: {
                    ...response.data,
                    propertyId: this.props.propertyId !== null ? this.props.propertyId : null,
                    status: 'ACTIVE'
                }
            }));

        }).catch(error => {
            console.error(error);
        });

        $('#onboard-tenant').modal('hide');

        this.searchProperties();
    }

    /**
     * Create a customer from scratch without a local system account. Sends out an email to the tenant giving them the
     * option to create a password for their newly created Letus account. Because the customer will already be in
     * an active state, the property manager can already begin creating payment methods and making payments on the
     * account.
     *
     * @param event - The event container.
     */
    saveCustomer(event) {

        event.preventDefault();

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/setupuser`, {
            user: this.state.customer,
            billingAccount: {
                ...this.state.propertyLease,
                charges: this.state.charges
            },
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            $('#create-tenant').modal('hide');

            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.tenants.tenant.created'
                    },
                    values: {
                        email: this.state.customer.email,
                    }
                }],
            });

            this.searchInvitations(1, 25, this.state.invitationQuery);

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize the search integration customers flow by taking the property identifier value from the selected
     * property and pre-filling the property ID search field.
     */
    initIntegrationCustomers() {

        let propertyId;

        if(this.state.propertyLease.propertyId) {
            propertyId = this.state.propertyLease.propertyId;
        }

        if(this.state.invitation.propertyId) {
            propertyId = this.state.invitation.propertyId;
        }

        if(propertyId) {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${propertyId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    integrationCustomerList: [],
                    integrationSearch: {
                        ...prevState.integrationSearch,
                        propertyId: response.data.propertyIdentifier,
                        firstName: '',
                        lastName: '',
                        phone: '',
                        email: '',
                        tenantId: ''
                    },
                }));

                $('#invite-tenant').modal('hide');
                $('#create-tenant').modal('hide');
                $('#property-lease').modal('hide');
                $('#search-tenant').modal('show');

            }).catch(error => {
                console.error(error);
            });
        }
    }

    /**
     * Perform a search for customers in the integrated system for selection.
     */
    searchIntegrationCustomers(event) {

        event.preventDefault();

        this.setState({
            spinner: true,
            integrationCustomerList: [],
            validationList: [{
                fields: {},
                alert: {
                    type: '',
                    code: ''
                }
            }]
        });

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/tenant/search`, {
            email: this.state.integrationSearch.email,
            firstName: this.state.integrationSearch.firstName,
            lastName: this.state.integrationSearch.lastName,
            phone: this.state.integrationSearch.phone,
            propertyId: this.state.integrationSearch.propertyId,
            tenantId: this.state.integrationSearch.tenantId,
            integrationId: this.state.integrationId
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    integrationCustomerList: response.data
                }));
            }

            if(response.data.length === 0) {
                this.setState({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'common.tenants.integration.null'
                        }
                    }]
                });
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Select a customer from the integrated system. Using the data from the integrated system, the fields used to
     * invite or create a tenant will be pre-filled with property lease and customer information.
     *
     * @param data - The customer that has been selected from the integrated system.
     */
    selectIntegrationCustomer(data) {

        this.setState(prevState => ({
            ...prevState,
            propertyLease: {
                ...prevState.propertyLease,
                accountNumber: data.externalId,
                unit: data.unit
            },
            customer: {
                ...prevState.customer,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: data.email
            },
            invitation: {
                ...prevState.invitation,
                accountNumber: data.externalId,
                unit: data.unit,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: data.email,
            },
        }));

        $('#search-tenant').modal('hide');

        // The previous modal is stored in the state
        $('#' + this.state.activeModal).modal('show');
    }

    /**
     * Add a new blank charge to the list of charges.
     */
    addCharge() {

        this.setState(prevState => ({
            ...prevState,
            charges: [...prevState.charges, {
                amount: '',
                code: '',
                name: ''
            }],
        }));
    }

    /**
     * Remove a specific charge from the list of charges.
     *
     * @param index - The array index of the charge to remove.
     */
    removeCharge(index) {

        let charges = this.state.charges;

        charges.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            charges: charges,
        }));
    }

    /**
     * Import charges and amounts from the integrated system.
     */
    importCharges() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/tenant/recurringcharges`, {
            propertyId: this.state.integrationSearch.propertyId,
            tenantId: this.state.invitation.accountNumber || this.state.propertyLease.accountNumber,
            integrationId: this.state.integrationId
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    customChargeCodes: [...prevState.customChargeCodes, ...response.data],
                    charges: response.data,
                }));
            }

            if(response.data.length === 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'common.tenants.charges.null'
                        }
                    }]
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the account number field.
     *
     * @param event - The event container.
     */
    handleChangeAccountNumber(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            propertyLease: {
                ...prevState.propertyLease,
                accountNumber: event.target.value
            },
            invitation: {
                ...prevState.invitation,
                accountNumber: event.target.value
            }
        }));
    }

    /**
     * Handle changes to the property field.
     *
     * @param selectedOption - The selected property.
     */
    handleChangeProperty(selectedOption) {

        let companyId;

        this.state.propertyList.forEach((property, index) => {
            if(property.id === selectedOption.value) {
                companyId = property.companyId;
            }
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${companyId}/fallbackfields`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                propertyLease: {
                    ...prevState.propertyLease,
                    propertyId: selectedOption.value,
                    selectedProperty: {
                        value: selectedOption.value,
                        label: selectedOption.label
                    },
                    companyId: companyId,
                },
                invitation: {
                    ...prevState.invitation,
                    propertyId: selectedOption.value,
                    selectedProperty: {
                        value: selectedOption.value,
                        label: selectedOption.label
                    },
                },
                companyId: companyId,
                integrationId: response.data.integrationId,
                customChargeCodes: response.data.chargeCodes,
            }));

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {

        const {formatMessage} = this.props.intl;
        const ordinal = require('ordinal');

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        let propertyLeaseColumnLabels = {'c.firstName': 'Tenant', unit: 'Unit', 'p.street1': 'Property', charges: 'Monthly Charges', monthlyPaymentDueDay: 'Payment Due', status: 'Lease'};
        let propertyLeaseColumnWidths = ['16%', '16%', '16%', '16%', '16%', '16%'];

        let invitationColumnLabels = {'e.lastSentDate': 'Sent On', firstName: 'Tenant', unit: 'Unit', 'p.street1': 'Property', charges: 'Monthly Charges', accepted: 'Status'};
        let invitationColumnWidths = ['16%', '16%', '16%', '16%', '16%', '16%'];

        if(this.props.context === 'property') {

            propertyLeaseColumnLabels = {'c.firstName': 'Tenant', unit: 'Unit', charges: 'Monthly Charges', monthlyPaymentDueDay: 'Payment Due', status: 'Lease'};
            propertyLeaseColumnWidths = ['20%', '20%', '20%', '20%', '20%'];

            invitationColumnLabels = {'e.lastSentDate': 'Sent On', firstName: 'Tenant', unit: 'Unit', charges: 'Monthly Charges', accepted: 'Status'};
            invitationColumnWidths = ['20%', '20%', '20%', '20%', '20%'];

        }

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} validationType="primary" />

                {this.props.history.location.state &&
                <Alert validationList={this.props.history.location.state.validationList} validationType="primary" />
                }

                <div className="card accordion" id="navigation">

                    <div className="card-header pb-0">
                        <div className="row align-items-center">
                            <div className="col-8">
                                <ul className="nav nav-tabs border-bottom-0">
                                    <li className="nav-item">
                                        <a className={`nav-link ${this.state.selectedTab === 'TENANTS' ? 'active' : ''}`} id="tenants-tab" data-toggle="tab" href="#tenants" role="tab" aria-controls="tenants" aria-selected="true" onClick={() => this.selectTab('TENANTS')}>
                                            Tenants
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${this.state.selectedTab === 'INVITATIONS' ? 'active' : ''}`} id="invitations-tab" data-toggle="tab" href="#invitations" role="tab" aria-controls="invitations" aria-selected="true" onClick={() => this.selectTab('INVITATIONS')}>
                                            Invitations
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {(this.props.createTenant || this.props.inviteTenant) &&
                            <div className="col text-right">

                                {(this.props.createTenant && this.props.inviteTenant) &&
                                <div data-toggle="modal" data-target="#onboard-tenant" className="btn btn-primary btn-sm">
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Onboard Tenant
                                </div>
                                }

                                {(this.props.createTenant && !this.props.inviteTenant) &&
                                <div data-toggle="modal" data-target="#create-tenant" className="btn btn-primary btn-sm" onClick={() => this.initCustomer()}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Tenant
                                </div>
                                }

                                {(this.props.inviteTenant && !this.props.createTenant) &&
                                <React.Fragment>

                                    {!this.props.inviteTenantDisabled &&
                                    <div data-toggle="modal" data-target="#invite-tenant" className="btn btn-primary btn-sm ml-2" onClick={() => this.initInvitation()}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Invite Tenant
                                    </div>
                                    }

                                    {this.props.inviteTenantDisabled &&
                                    <div className="btn btn-primary btn-sm ml-2 disabled" data-toggle={this.props.inviteTenantTooltip ? 'tooltip' : ''} data-placement="top" title={this.props.inviteTenantTooltip}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Invite Tenant
                                    </div>
                                    }

                                </React.Fragment>
                                }

                            </div>
                            }
                        </div>
                    </div>

                    <div className="tab-content" id="tabs">

                        <div className={`tab-pane fade-show ${this.state.selectedTab === 'TENANTS' ? 'active' : ''}`} id="tenants" role="tabpanel" aria-labelledby="tenants-tab">

                            <div className="card-header gotham border-top py-3 bg-secondary">
                                <form onSubmit={this.filterPropertyLeases}>
                                    <div className="media">
                                        <div className="media-body align-self-center mr-3">
                                            <FieldText id="propertyLeaseSearchFilter" label="Search" labelClass="d-none"
                                                       fieldColumns="12"
                                                       labelColums="0" placeholder="Filter by name, phone, email, unit, or property..." parent={this}
                                                       value={this.state.propertyLeaseSearchFilter} />
                                        </div>
                                        <div className="media-body align-self-center mr-3">
                                            <FieldSelect id="propertyLeaseStatusFilter" labelColumns="0"
                                                         fieldColumns="12" parent={this}
                                                         value={this.state.propertyLeaseStatusFilter}>
                                                <option value="">Any Lease Status</option>
                                                <option value="ACTIVE">Active</option>
                                                <option value="SUSPENDED">Suspended</option>
                                                <option value="TERMINATED">Terminated</option>
                                            </FieldSelect>
                                        </div>
                                        <div className="align-self-center text-right">
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                    <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                                </button>
                                                <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                    <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            {this.state.propertyLeaseList.emptyUnfilteredList &&
                            <div className="card-body bg-secondary py-5 border-top">
                                <div className="row justify-content-center">
                                    <div className="col-10">
                                        <div className="text-center text-muted">
                                            <div className="fa-stack fa-2x mb-2">
                                                <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                <FontAwesomeIcon icon={['far', 'user-friends']} className="fa-stack-1x fa-inverse" />
                                            </div>
                                        </div>
                                        <div className="text-center text-muted">
                                            <small>
                                                Tenants making payments with Letus will appear here.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {!this.state.propertyLeaseList.emptyUnfilteredList &&
                            <Table tableClass="table-bordered table-hover table-responsive-sm border-bottom border-dark"
                                   columns={propertyLeaseColumnLabels}
                                   columnWidths={propertyLeaseColumnWidths}
                                   headerClass="c-pointer"
                                   data={this.state.propertyLeaseList}
                                   query={this.state.propertyLeaseQuery}
                                   sortEnabled={true}
                                   recordsEnabled={true}
                                   paginationEnabled={true}
                                   parent={this}
                                   updateFunction={this.searchPropertyLeases}>
                                <tbody>
                                {this.state.propertyLeaseList.records.map((data, key) => {
                                    return (
                                        <tr key={key} onClick={() => this.redirectPropertyLease(data)} className="c-pointer">
                                            <td>
                                                {data.joins &&
                                                <div className="">
                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                </div>
                                                }
                                            </td>
                                            <td>
                                                <div className="">
                                                    {data.unit}
                                                </div>
                                            </td>
                                            {this.props.context !== 'property' &&
                                            <td>
                                                {data.joins &&
                                                <div className="">
                                                    {data.joins.p.street1}
                                                </div>
                                                }
                                            </td>
                                            }
                                            <td>
                                                <div className="">
                                                    <FormattedNumber value={data.totalCharges} style={`currency`} currency="USD" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="">
                                                    {data.monthlyPaymentDueDay > 0 ? ordinal(data.monthlyPaymentDueDay) : 'Anytime'} <span className="text-muted text-uppercase small">(Monthly)</span>
                                                </div>
                                            </td>
                                            <td>
                                                {data.status != null &&
                                                <div className="text-nowrap">
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.propertyLease.status." + data.status + ".class"})}`}/>
                                                    <span className="ml-1"><FormattedMessage id={"enum.propertyLease.status." + data.status}/></span>
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                            }
                        </div>

                        <div className={`tab-pane fade-show ${this.state.selectedTab === 'INVITATIONS' ? 'active' : ''}`} id="invitations" role="tabpanel" aria-labelledby="invitations-tab">

                            <div className="card-header gotham border-top py-3 bg-secondary">
                                <form onSubmit={this.filterInvitations}>
                                    <div className="media">
                                        <div className="media-body align-self-center mr-3">
                                            <FieldText id="invitationSearchFilter" label="Search" labelClass="d-none"
                                                       fieldColumns="12"
                                                       labelColums="0" placeholder="Filter by name, phone, email, unit, or property..." parent={this}
                                                       value={this.state.invitationSearchFilter}/>
                                        </div>
                                        <div className="media-body align-self-center mr-3">
                                            <FieldSelect id="invitationAcceptedFilter" labelColumns="0"
                                                         fieldColumns="12" parent={this}
                                                         value={this.state.invitationAcceptedFilter}>
                                                <option value={false}>Pending</option>
                                                <option value={true}>Accepted</option>
                                            </FieldSelect>
                                        </div>
                                        <div className="align-self-center text-right">
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                    <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                                </button>
                                                <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                    <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            {this.state.invitationList.emptyUnfilteredList &&
                            <div className="card-body bg-secondary py-5 border-top">
                                <div className="row justify-content-center">
                                    <div className="col-10">
                                        <div className="text-center text-muted">
                                            <div className="fa-stack fa-2x mb-2">
                                                <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                <FontAwesomeIcon icon={['far', 'user-plus']} className="fa-stack-1x fa-inverse" />
                                            </div>
                                        </div>
                                        <div className="text-center text-muted">
                                            <small>
                                                Tenants that have received email invitations to create a Letus account will appear here.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {!this.state.invitationList.emptyUnfilteredList &&
                            <Table columns={invitationColumnLabels}
                                   columnWidths={invitationColumnWidths}
                                   headerClass="c-pointer"
                                   data={this.state.invitationList}
                                   query={this.state.invitationQuery}
                                   sortEnabled={true}
                                   recordsEnabled={true}
                                   paginationEnabled={true}
                                   parent={this}
                                   updateFunction={this.searchInvitations}>
                                <tbody>
                                {this.state.invitationList.records.map((data, key) => {
                                    return (
                                        <tr key={key} onClick={() => this.selectInvitation(data)} className="c-pointer">
                                            <td>
                                                {data.joins && data.joins.e &&
                                                <div className="">
                                                    <Moment format="MMM DD, YYYY">
                                                        {data.joins.e.lastSentDate}
                                                    </Moment>
                                                </div>
                                                }
                                            </td>
                                            <td>
                                                <div className="">
                                                    {data.firstName} {data.lastName}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="">
                                                    {data.unit}
                                                </div>
                                            </td>
                                            {this.props.context !== 'property' &&
                                            <td>
                                                {data.joins && data.joins.p &&
                                                <div className="">
                                                    {data.joins.p.street1}
                                                </div>
                                                }
                                            </td>
                                            }
                                            <td>
                                                <div className="">
                                                    <FormattedNumber value={data.totalCharges} style={`currency`} currency="USD" />
                                                </div>
                                            </td>
                                            <td>
                                                {data.accepted != null &&
                                                <div className="text-nowrap">
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${data.accepted ? 'text-success' : 'text-warning'}`}/>
                                                    <span className="ml-1">{data.accepted ? 'Accepted' : 'Pending'}</span>
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                            }

                        </div>
                    </div>

                </div>

                <div className="modal fade" id="invite-tenant" tabIndex="-1" role="dialog" aria-labelledby="invite-tenant-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveInvitation} autoComplete="false">

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="search-tenant-label">
                                        {!this.state.invitation.createDate &&
                                        <span className="">Invite Tenant</span>
                                        }
                                        {this.state.invitation.createDate &&
                                        <span className="">Edit Invitation</span>
                                        }
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                {!this.state.invitation.createDate &&
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Inviting a tenant will send an email to your tenant with instructions to create their Letus account.
                                    </p>
                                </div>
                                }

                                {this.state.invitation.accepted &&
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        This invitation has already been accepted by the tenant.
                                    </p>
                                </div>
                                }

                                {this.state.propertyList.length > 0 &&
                                <React.Fragment>

                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger"/>

                                        <FieldProperty id="propertyId" label="Property" model="invitation" value={this.state.invitation.selectedProperty} disabled={this.state.invitation.accepted} parent={this} options={this.state.propertyList} handleChange={this.handleChangeProperty} />

                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label col-form-label-sm" htmlFor="accountNumber">
                                                Account ID
                                            </label>
                                            <div className="col-sm-9">
                                                <div className="input-group input-group-sm">

                                                    <input id="accountNumber" name="accountNumber" value={this.state.invitation['accountNumber'] || ""} disabled={this.state.invitation.accepted} className="form-control mb-0" onChange={this.handleChangeAccountNumber}/>

                                                    {(this.state.integrationId && !this.state.invitation.accepted) &&
                                                    <div className="input-group-append">
                                                        <div className={`btn btn-secondary ${this.state.invitation.propertyId ? '' : 'disabled'}`} onClick={() => this.initIntegrationCustomers()}>
                                                            Search...
                                                        </div>
                                                    </div>
                                                    }

                                                </div>

                                            </div>
                                        </div>

                                        <FieldText id="unit" label="Unit" model="invitation" parent={this} disabled={this.state.invitation.accepted} value={this.state.invitation['unit']} />

                                        <FieldText id="firstName" label="First Name" model="invitation" parent={this} disabled={this.state.invitation.accepted} value={this.state.invitation['firstName']} />

                                        <FieldText id="lastName" label="Last Name" model="invitation" parent={this} disabled={this.state.invitation.accepted} value={this.state.invitation['lastName']} />

                                        <FieldText id="email" label="Email" type="email" model="invitation" parent={this} disabled={this.state.invitation.accepted} value={this.state.invitation['email']} />

                                        <FieldDate id="startDate" label="Start Date" model="invitation" parent={this} disabled={this.state.invitation.accepted} value={this.state.invitation['startDate'] || null} />

                                        <FieldSelect id="monthlyPaymentDueDay" label="Payment Due" model="invitation" parent={this} disabled={this.state.invitation.accepted} value={this.state.invitation['monthlyPaymentDueDay']}>
                                            <option value={0}>No monthly payment due date</option>
                                            <option value={1}>1st day of every month</option>
                                            <option value={2}>2nd day of every month</option>
                                            <option value={3}>3rd day of every month</option>
                                            <option value={4}>4th day of every month</option>
                                            <option value={5}>5th day of every month</option>
                                            <option value={6}>6th day of every month</option>
                                            <option value={7}>7th day of every month</option>
                                            <option value={8}>8th day of every month</option>
                                            <option value={9}>9th day of every month</option>
                                            <option value={10}>10th day of every month</option>
                                            <option value={11}>11th day of every month</option>
                                            <option value={12}>12th day of every month</option>
                                            <option value={13}>13th day of every month</option>
                                            <option value={14}>14th day of every month</option>
                                            <option value={15}>15th day of every month</option>
                                            <option value={16}>16th day of every month</option>
                                            <option value={17}>17th day of every month</option>
                                            <option value={18}>18th day of every month</option>
                                            <option value={19}>19th day of every month</option>
                                            <option value={20}>20th day of every month</option>
                                            <option value={21}>21st day of every month</option>
                                            <option value={22}>22nd day of every month</option>
                                            <option value={23}>23rd day of every month</option>
                                            <option value={24}>24th day of every month</option>
                                            <option value={25}>25th day of every month</option>
                                            <option value={26}>26th day of every month</option>
                                            <option value={27}>27th day of every month</option>
                                            <option value={28}>28th day of every month</option>
                                            <option value={29}>29th day of every month</option>
                                            <option value={30}>30th day of every month</option>
                                            <option value={31}>31st day of every month</option>
                                        </FieldSelect>

                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label col-form-label-sm">
                                                Monthly Charges
                                            </label>
                                            <div className="col-sm-9">
                                                <FieldCharges customChargeCodes={this.state.customChargeCodes.length > 0 ? this.state.customChargeCodes : null} charges={this.state.charges} addCharge={this.addCharge} removeCharge={this.removeCharge} importCharges={this.state.integrationId && this.state.invitation.accountNumber ? this.importCharges : null} size="sm" disabled={this.state.invitation.accepted} parent={this} />
                                            </div>
                                        </div>

                                        <FieldCheckbox id="requireAutoPay" label="Auto Pay" fieldLabel="Require this tenant to set up automatic monthly payments" model="invitation" disabled={this.state.invitation.accepted} parent={this} value={this.state.invitation['requireAutoPay']} />

                                    </div>

                                    <div className="modal-footer bg-secondary rounded-bottom d-block">

                                        <div className="row">
                                            <div className="col-4">
                                                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                            </div>
                                            <div className="col-8 text-right">

                                                {!this.state.invitation.accepted && this.state.invitation.sentEmailId &&
                                                <div className="btn btn-primary btn-lg" onClick={() => {$("#invite-tenant").modal("hide"); $("#delete-invitation").modal("show");}}>Delete</div>
                                                }

                                                {!this.state.invitation.accepted && this.state.invitation.sentEmailId &&
                                                <button type="submit" className="btn btn-primary btn-lg ml-2">Resend</button>
                                                }

                                                {!this.state.invitation.accepted && !this.state.invitation.sentEmailId &&
                                                <button type="submit" className="btn btn-primary btn-lg ml-2">Invite Tenant</button>
                                                }

                                            </div>
                                        </div>

                                    </div>

                                </React.Fragment>
                                }

                                {this.state.propertyList.length === 0 &&
                                <React.Fragment>

                                    <div className="modal-body text-center">

                                        <p className="pt-3 pb-1">Let's set up your first rental property.</p>

                                        <Link to="./properties" className="btn btn-primary btn-lg mb-4" onClick={() => $('#invite-tenant').modal('hide')}>
                                            Create Property
                                        </Link>

                                    </div>

                                </React.Fragment>
                                }

                            </form>
                        </div>
                    </div>
                </div>

                <Modal id="delete-invitation" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Invitation" body="Are you sure you would like to delete this invitation?">
                    <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => {$("#invite-tenant").modal("show")}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button className="btn btn-danger btn-lg" data-dismiss="modal" onClick={() => this.deleteInvitation(this.state.invitation)}>
                        Delete Invitation
                    </button>
                </Modal>

                <div className="modal fade" id="create-tenant" tabIndex="-1" role="dialog" aria-labelledby="create-tenant-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveCustomer}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="search-tenant-label">
                                        Create Tenant
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Creating a tenant allows you to set up your tenant's Letus account instantly on their behalf.
                                    </p>
                                </div>

                                {this.state.propertyList.length > 0 &&
                                <React.Fragment>

                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger"/>

                                        <FieldProperty id="propertyId" label="Property" model="propertyLease" value={this.state.propertyLease.selectedProperty} parent={this} options={this.state.propertyList} handleChange={this.handleChangeProperty} />

                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label col-form-label-sm" htmlFor="accountNumber">
                                                Account ID
                                            </label>
                                            <div className="col-sm-9">
                                                <div className="input-group input-group-sm">

                                                    <input id="accountNumber" name="accountNumber" value={this.state.propertyLease['accountNumber'] || ""} className="form-control mb-0" onChange={this.handleChangeAccountNumber}/>

                                                    {this.state.integrationId &&
                                                    <div className="input-group-append">
                                                        <div className={`btn btn-secondary ${this.state.propertyLease.propertyId ? '' : 'disabled'}`} onClick={() => {this.initIntegrationCustomers()}}>
                                                            Search...
                                                        </div>
                                                    </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                        <FieldText id="unit" label="Unit" model="propertyLease" parent={this} value={this.state.propertyLease['unit']} />

                                        <FieldText id="firstName" label="First Name" model="customer" parent={this} value={this.state.customer['firstName']} />

                                        <FieldText id="lastName" label="Last Name" model="customer" parent={this} value={this.state.customer['lastName']} />

                                        <FieldText id="email" label="Email" type="email" model="customer" parent={this} value={this.state.customer['email']} />

                                        <FieldDate id="startDate" label="Start Date" model="propertyLease" parent={this} value={this.state.propertyLease['startDate'] || null} />

                                        <FieldSelect id="monthlyPaymentDueDay" label="Payment Due" model="propertyLease" parent={this} value={this.state.propertyLease['monthlyPaymentDueDay']}>
                                            <option value={0}>No monthly payment due date</option>
                                            <option value={1}>1st day of every month</option>
                                            <option value={2}>2nd day of every month</option>
                                            <option value={3}>3rd day of every month</option>
                                            <option value={4}>4th day of every month</option>
                                            <option value={5}>5th day of every month</option>
                                            <option value={6}>6th day of every month</option>
                                            <option value={7}>7th day of every month</option>
                                            <option value={8}>8th day of every month</option>
                                            <option value={9}>9th day of every month</option>
                                            <option value={10}>10th day of every month</option>
                                            <option value={11}>11th day of every month</option>
                                            <option value={12}>12th day of every month</option>
                                            <option value={13}>13th day of every month</option>
                                            <option value={14}>14th day of every month</option>
                                            <option value={15}>15th day of every month</option>
                                            <option value={16}>16th day of every month</option>
                                            <option value={17}>17th day of every month</option>
                                            <option value={18}>18th day of every month</option>
                                            <option value={19}>19th day of every month</option>
                                            <option value={20}>20th day of every month</option>
                                            <option value={21}>21st day of every month</option>
                                            <option value={22}>22nd day of every month</option>
                                            <option value={23}>23rd day of every month</option>
                                            <option value={24}>24th day of every month</option>
                                            <option value={25}>25th day of every month</option>
                                            <option value={26}>26th day of every month</option>
                                            <option value={27}>27th day of every month</option>
                                            <option value={28}>28th day of every month</option>
                                            <option value={29}>29th day of every month</option>
                                            <option value={30}>30th day of every month</option>
                                            <option value={31}>31st day of every month</option>
                                        </FieldSelect>

                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label col-form-label-sm">
                                                Monthly Charges
                                            </label>
                                            <div className="col-sm-9">
                                                <FieldCharges customChargeCodes={this.state.customChargeCodes.length > 0 ? this.state.customChargeCodes : null} charges={this.state.charges} addCharge={this.addCharge} removeCharge={this.removeCharge} importCharges={this.state.integrationId && this.state.propertyLease.accountNumber ? this.importCharges : null} size="sm" parent={this} />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="modal-footer bg-secondary rounded-bottom d-block">

                                        <div className="row">
                                            <div className="col-4">
                                                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                            </div>
                                            <div className="col-8 text-right">
                                                <button type="submit" className="btn btn-primary btn-lg ml-2">Create Tenant</button>
                                            </div>
                                        </div>

                                    </div>

                                </React.Fragment>
                                }

                                {this.state.propertyList.length === 0 &&
                                <React.Fragment>

                                    <div className="modal-body text-center">

                                        <p className="pt-3 pb-1">Let's set up your first rental property.</p>

                                        <Link to="./properties" className="btn btn-primary btn-lg mb-4" onClick={() => $('#create-tenant').modal('hide')}>
                                            Create Property
                                        </Link>

                                    </div>

                                </React.Fragment>
                                }

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="search-tenant" tabIndex="-1" role="dialog" aria-labelledby="search-tenant-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.searchIntegrationCustomers}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="search-tenant-label">
                                        Search Tenants
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Select the tenant from your integrated system using the available search fields below.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                    <FieldText id="tenantId" label="Account ID" model="integrationSearch" parent={this} value={this.state.integrationSearch['tenantId']} />

                                    <div className="divider">
                                        <span className="small text-muted font-italic text-uppercase">or</span>
                                    </div>

                                    <FieldText id="propertyId" label="Property ID" model="integrationSearch" parent={this} disabled={true} value={this.state.integrationSearch['propertyId']} />

                                    <FieldText id="firstName" label="First Name" model="integrationSearch" parent={this} value={this.state.integrationSearch['firstName']} />

                                    <FieldText id="lastName" label="Last Name" model="integrationSearch" parent={this} value={this.state.integrationSearch['lastName']} />

                                    <FieldText id="email" label="Email" type="email" model="integrationSearch" parent={this} value={this.state.integrationSearch['email']} />

                                    <FieldText id="phone" label="Phone" type="tel" model="integrationSearch" parent={this} value={this.state.integrationSearch['phone']} />

                                </div>

                                {this.state.integrationCustomerList.length > 0 &&
                                <div className="modal-body modal-body-table overflow-auto border-top" style={{maxHeight: '400px'}}>
                                    <div className="card-body card-body-table">
                                        <table className="table table-bordered table-hover border">
                                            <thead>
                                            <tr>
                                                <th width="25%">First Name</th>
                                                <th width="25%">Last Name</th>
                                                <th width="25%">Unit</th>
                                                <th width="25%">Account ID</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.integrationCustomerList.map((data, key) => {
                                                return (
                                                    <tr key={key} onClick={() => this.selectIntegrationCustomer(data)} className="c-pointer">
                                                        <td className="">
                                                            {data.firstName}
                                                        </td>
                                                        <td className="">
                                                            {data.lastName}
                                                        </td>
                                                        <td className="">
                                                            {data.unit}
                                                        </td>
                                                        <td className="">
                                                            {data.externalId}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                }

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-6 text-left">
                                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $('#' + this.state.activeModal).modal('show')}>Back</button>
                                        </div>
                                        <div className="col-6 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">Search</button>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="onboard-tenant" tabIndex="-1" role="dialog" aria-labelledby="onboard-tenant-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="onboard-tenant-label">
                                    Onboard Tenant
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="row justify-content-center">

                                    <div className="col-md-6 text-center mb-4 mb-sm-0">
                                        <div className="px-3">
                                            <h5 className="display-5 mb-4">
                                                Invite a Tenant
                                            </h5>
                                            <FontAwesomeIcon icon={['fal', 'envelope-open-text']} className="fa-fw mb-4 text-primary" size="3x" />
                                            <p className="mb-4">
                                                Inviting a tenant will send an email to your tenant, guiding them to creating their Letus account.
                                            </p>
                                            <div data-toggle="modal" data-target="#invite-tenant" className="btn btn-primary btn-md mb-3" onClick={() => this.initInvitation()}>
                                                Invite Tenant
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 text-center mb-4 mb-sm-0 border-left">
                                        <div className="px-3">
                                            <h5 className="display-5 mb-4">
                                                Create a Tenant
                                            </h5>
                                            <FontAwesomeIcon icon={['fal', 'user-plus']} className="fa-fw mb-4 text-primary" size="3x" />
                                            <p className="mb-4">
                                                Creating a tenant allows you to set up your tenant's Letus account instantly on their behalf.
                                            </p>
                                            <div data-toggle="modal" data-target="#create-tenant" className="btn btn-primary btn-md mb-3" onClick={() => this.initCustomer()}>
                                                Create Tenant
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </React.Fragment>
        )
    };
}

Tenants.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Tenants);