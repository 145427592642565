import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import Modal from "../../common/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import ButtonBack from "../../common/ButtonBack";
import ButtonSave from "../../common/ButtonSave";
import Propertii from "../../common/Propertii";
import FieldText from "../../common/FieldText";
import FieldSelect from "../../common/FieldSelect";
import FieldTextarea from "../../common/FieldTextarea";
import CustomFieldList from "../../common/CustomFieldList";
import Spinner from "../../common/Spinner";
import FieldPhone from "../../common/FieldPhone";

class MerchantAccount extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            merchantAccount: {},
            merchantAccountNumber: '',
            customFields: {},
            newBankAccountNumber: '',
            newRoutingNumber: '',
            paymentProviders: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },
            validationList: [],
        };

        this.updateMerchantAccountStatus = this.updateMerchantAccountStatus.bind(this);
        this.saveMerchantAccount = this.saveMerchantAccount.bind(this);
        this.sendMerchantAccount = this.sendMerchantAccount.bind(this);
        this.suspendMerchantAccount = this.suspendMerchantAccount.bind(this);
        this.updateMerchantAccount = this.updateMerchantAccount.bind(this);
        this.updateBankingDetails = this.updateBankingDetails.bind(this);
    }

    /**
     * Get the merchant account based on the Route's ID. Use the merchant account's ID to retrieve the payment
     * provider's merchant account status. Also retrieve a list of all payment providers for populating select boxes.
     */
    componentDidMount() {

        $('[data-toggle="tooltip"]').tooltip();

        axios.get(`${constants.REACT_APP_HOST_API_URL}/merchant_account/${this.props.match.params.merchantAccountId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                merchantAccount: response.data,
                customFields: response.data.customFields,
                merchantAccountNumber: response.data.accountNumber
            }));

            if(this.state.merchantAccount.accountStatus !== 'PENDING') {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/merchant_account/${this.state.merchantAccount.id}/providerstatus`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        merchantAccount: {
                            ...prevState.merchantAccount,
                            providerAccountStatus: response.data.providerAccountStatus
                        },
                        customFields: response.data.customFields
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });
            }

        }).catch(error => {
            console.error(error);
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_provider/search?recordsPerPage=100&page=1`, {}, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                paymentProviders: response.data
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Update the merchant account details of the merchant account.
     *
     * @param event - The event container.
     */
    updateMerchantAccount(event) {

        event.preventDefault();

        let merchantAccount = this.state.merchantAccount;

        merchantAccount.customFields = this.state.customFields;

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, merchantAccount, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved'
                    }
                }],
            });

            $('#update-merchant-account').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Update the banking details of the merchant account.
     *
     * @param event - The event container.
     */
    updateBankingDetails(event) {

        event.preventDefault();

        let merchantAccount = this.state.merchantAccount;

        merchantAccount.customFields = this.state.customFields;

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/merchant_account/${this.props.match.params.merchantAccountId}/update`, {
            bankAccountNumber: this.state.newBankAccountNumber,
            routingNumber: this.state.newRoutingNumber
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved'
                    }
                }],
            });

            axios.get(`${constants.REACT_APP_HOST_API_URL}/merchant_account/${this.props.match.params.merchantAccountId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    merchantAccount: response.data,
                    customFields: response.data.customFields,
                    merchantAccountNumber: response.data.accountNumber
                }));

            }).catch(error => {
                console.error(error);
            });

            $('#update-banking-details').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle updating the merchant account's status.
     *
     * @param accountStatus - The new status of the merchant account.
     */
    updateMerchantAccountStatus(accountStatus) {

        let merchantAccount = this.state.merchantAccount;

        merchantAccount['accountStatus'] = accountStatus;

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, merchantAccount, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.merchants.status.' + accountStatus
                    }
                }],
            })
        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle submitting updates to the merchant account.
     */
    saveMerchantAccount(event) {

        event.preventDefault();

        let merchantAccount = this.state.merchantAccount;

        merchantAccount.customFields = this.state.customFields;

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, merchantAccount, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved'
                    }
                }],
            });
        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Send the merchant account to the provided payment processor for verification.
     */
    sendMerchantAccount() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/merchant_account/${this.state.merchantAccount.id}/process`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.props.history.push({
                pathname: '/admin/companies/' + this.props.match.params.companyId + '/merchants',
                state: {
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.companies.merchants.status.INPROCESS'
                        }
                    }],
                }
            });
        }).catch(error => {
            this.setState(prevState => ({
                spinner: false,
                validationList: [{
                    ...prevState.validationList,
                    alert: {
                        type: 'danger',
                        code: error.response.data.errorCode,
                        message: error.response.data.message
                    }
                }],
            }));
        });

        window.scrollTo(0, 0);
    }

    /**
     * Suspend the merchant account.
     *
     * @param event - The event container.
     */
    suspendMerchantAccount(event) {

        event.preventDefault();

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, {
            ...this.state.merchantAccount,
            accountStatus: 'SUSPENDED'
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                merchantAccount: response.data,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.merchants.status.SUSPENDED'
                    }
                }],
            }));

            $('#suspend-merchant-account').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The edit API account interface.
     */
    render() {

        const {formatMessage} = this.props.intl;

        $('[data-toggle="tooltip"]').tooltip();

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                {this.state.merchantAccount['accountStatus'] === 'SUSPENDED' &&
                <div className="card card-danger border-danger">
                    <div className="card-body">
                        <h4 className="card-title">
                            This merchant account is suspended
                        </h4>
                        <p className="card-text">
                            Payments will not be processed for this merchant account until it is reactivated. A reason for the suspension has been provided: {this.state.merchantAccount['suspensionReason']}
                        </p>
                    </div>
                </div>
                }

                {this.state.merchantAccount['accountStatus'] === 'CANCELLED' &&
                <div className="card card-danger border-danger">
                    <div className="card-body">
                        <h4 className="card-title">
                            This merchant account is deactivated
                        </h4>
                        <p className="card-text">
                            Payments will no longer be processed for this merchant account. Please create another merchant account.
                        </p>
                    </div>
                </div>
                }

                {this.state.merchantAccount['accountStatus'] === 'PENDING' &&
                <div className="card card-warning border-warning">
                    <div className="card-body">
                        <h4 className="card-title">
                            This merchant account is pending
                        </h4>
                        <p className="card-text">
                            In order to process payments with this merchant account, it must be submitted to {formatMessage({id: "enum.merchantAccount.paymentProviderId." + this.state.merchantAccount['paymentProviderId']})} for completion.
                        </p>
                    </div>
                </div>
                }

                {this.state.merchantAccount['accountStatus'] === 'INPROCESS' &&
                <div className="card card-warning border-warning">
                    <div className="card-body">
                        <h4 className="card-title">
                            This merchant account is in process
                        </h4>
                        <p className="card-text">
                            The information associated with this merchant account has been sent to {formatMessage({id: "enum.merchantAccount.paymentProviderId." + this.state.merchantAccount['paymentProviderId']})} for completion. Once fully approved, a communication will be sent from {formatMessage({id: "enum.merchantAccount.paymentProviderId." + this.state.merchantAccount['paymentProviderId']})} containing the values for the credential fields below.
                        </p>
                    </div>
                </div>
                }

                <form onSubmit={this.saveMerchantAccount}>

                    {this.state.merchantAccount['accountStatus'] !== 'PENDING' &&
                    <div className="card">
                        <div className="card-header rounded-bottom">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="">
                                        {formatMessage({id: "enum.merchantAccount.paymentProviderId." + this.state.merchantAccount['paymentProviderId']})} Merchant Account Status
                                    </div>
                                </div>
                                <div className="col text-right">
                                    <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-primary" />
                                    <span className="ml-1">{this.state.merchantAccount['providerAccountStatus'] ? this.state.merchantAccount['providerAccountStatus'] : 'UNKNOWN'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Merchant Account Details
                                </div>
                                <div className="col text-right">
                                    {((this.state.merchantAccount['accountStatus'] !== 'CANCELLED') || (this.state.merchantAccount['accountStatus'] !== 'SUSPENDED')) &&
                                    <div data-toggle="modal" data-target="#update-merchant-account" className="btn btn-primary btn-sm">
                                        <FontAwesomeIcon icon={['fas', 'edit']} className="fa-fw" /> Update Merchant Account Details
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card-body card-body-table">
                            <table className="table table-striped table-bordered table-responsive-sm">
                                <tbody>
                                <tr>
                                    <td width="25%">Account Name</td>
                                    <td width="75%">{this.state.merchantAccount['name']}</td>
                                </tr>
                                <tr>
                                    <td>Account Number</td>
                                    <td>{this.state.merchantAccount['accountNumber']}</td>
                                </tr>
                                <tr>
                                    <td>Descriptor</td>
                                    <td>{this.state.merchantAccount['descriptor']}</td>
                                </tr>
                                <tr>
                                    <td>Processor</td>
                                    <td>
                                        <FormattedMessage id={`enum.merchantAccount.paymentProviderId.${this.state.merchantAccount['paymentProviderId']}`} />
                                    </td>
                                </tr>
                                {this.state.merchantAccount['customFields'] &&
                                <React.Fragment>
                                    {this.state.merchantAccount.paymentProviderId === 'PAYSAFE' &&
                                    <React.Fragment>
                                        <tr>
                                            <td>Store ID</td>
                                            <td>{this.state.customFields['storeId']}</td>
                                        </tr>
                                        <tr>
                                            <td>Store Password</td>
                                            <td>{this.state.customFields['storePassword']}</td>
                                        </tr>
                                    </React.Fragment>
                                    }
                                    {this.state.merchantAccount.paymentProviderId === 'VANTIV' &&
                                    <React.Fragment>
                                        <tr>
                                            <td>Sub-Merchant ID</td>
                                            <td>{this.state.customFields['subMerchantId']}</td>
                                        </tr>
                                    </React.Fragment>
                                    }
                                    {this.state.merchantAccount.paymentProviderId === 'PAYNEARME' &&
                                    <React.Fragment>
                                        <tr>
                                            <td>Secret Key</td>
                                            <td>{this.state.customFields['secretKey']}</td>
                                        </tr>
                                    </React.Fragment>
                                    }
                                </React.Fragment>
                                }
                                <tr>
                                    <td>Payment Type</td>
                                    <td>
                                        <FormattedMessage id={`enum.paymentType.${this.state.merchantAccount['paymentType']}`} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Currency</td>
                                    <td>{this.state.merchantAccount['currency']}</td>
                                </tr>
                                <tr>
                                    <td>Yearly Volume</td>
                                    <td>{this.state.merchantAccount['yearlyVolumeRange']}</td>
                                </tr>
                                <tr>
                                    <td>Average Transaction</td>
                                    <td>{this.state.merchantAccount['averageTransAmount']}</td>
                                </tr>
                                <tr>
                                    <td>Disbursement Delay</td>
                                    <td>{this.state.merchantAccount['disbursementDelay']}</td>
                                </tr>
                                {this.state.merchantAccount['customFields'] &&
                                <React.Fragment>
                                    <tr>
                                        <td>Phone</td>
                                        <td>{this.state.merchantAccount.customFields['phone']}</td>
                                    </tr>
                                    <tr>
                                        <td>Company URL</td>
                                        <td>{this.state.merchantAccount.customFields['url']}</td>
                                    </tr>
                                </React.Fragment>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Banking Details
                                </div>
                                <div className="col text-right">
                                    {((this.state.merchantAccount['accountStatus'] !== 'CANCELLED') || (this.state.merchantAccount['accountStatus'] !== 'SUSPENDED')) &&
                                    <div data-toggle="modal" data-target="#update-banking-details" className="btn btn-primary btn-sm">
                                        <FontAwesomeIcon icon={['fas', 'edit']} className="fa-fw" /> Update Banking Details
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card-body card-body-table">
                            <table className="table table-striped table-bordered table-responsive-sm">
                                <tbody>
                                <tr>
                                    <td width="25%">Account Number</td>
                                    <td width="75%">*****{this.state.merchantAccount['last4']}</td>
                                </tr>
                                <tr>
                                    <td>Routing Number</td>
                                    <td>{this.state.merchantAccount['routingNumber']}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <CustomFieldList intl={this.props.intl} token={this.props.token} parent={this} recordType="MERCHANT_ACCOUNT" recordId={this.props.match.params.merchantAccountId}
                                     createMessage="admin.companies.merchants.fields.created" deleteMessage="admin.companies.merchants.fields.deleted" />

                    <div className="row">

                        <div className="col text-right">

                            <ButtonBack path={`/admin/companies/${this.props.match.params.companyId}/merchants`} />

                            {this.state.merchantAccount['accountStatus'] === 'PENDING' &&
                                <React.Fragment>

                                    <div data-toggle="modal" data-target="#activate-merchant-account" className="btn btn-outline-primary btn-lg ml-2">
                                        Manually Activate
                                    </div>

                                    <div data-toggle="modal" data-target="#send-merchant-account" className="btn btn-primary btn-lg ml-2">
                                        Send to {formatMessage({id: "enum.merchantAccount.paymentProviderId." + this.state.merchantAccount['paymentProviderId']})}
                                    </div>

                                </React.Fragment>
                            }

                            {this.state.merchantAccount['accountStatus'] === 'INPROCESS' &&
                            <React.Fragment>

                                <div data-toggle="modal" data-target="#deactivate-merchant-account" className="btn btn-primary btn-lg ml-2">
                                    Deactivate
                                </div>

                                <div data-toggle="modal" data-target="#activate-merchant-account" className="btn btn-primary btn-lg ml-2">
                                    Activate
                                </div>

                            </React.Fragment>
                            }

                            {this.state.merchantAccount['accountStatus'] === 'ACTIVE' &&
                            <React.Fragment>

                                <div data-toggle="modal" data-target="#suspend-merchant-account" className="btn btn-primary btn-lg ml-2">
                                    Suspend
                                </div>

                            </React.Fragment>
                            }

                            {this.state.merchantAccount['accountStatus'] === 'SUSPENDED' &&
                            <React.Fragment>

                                <div data-toggle="modal" data-target="#activate-merchant-account" className="btn btn-primary btn-lg ml-2">
                                    Activate
                                </div>

                            </React.Fragment>
                            }

                        </div>
                    </div>

                </form>

                <Modal id="send-merchant-account" theme="primary" iconType="fas" iconName="question-circle" title={`Send Merchant Account to ${formatMessage({id: "enum.merchantAccount.paymentProviderId." + this.state.merchantAccount['paymentProviderId']})}`}
                       body={`Are you sure you want to send this merchant account to ${formatMessage({id: "enum.merchantAccount.paymentProviderId." + this.state.merchantAccount['paymentProviderId']})} for processing?`}>
                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                        <FormattedMessage id="button.close" />
                    </button>
                    <button onClick={() => {this.sendMerchantAccount()}} className="btn btn-primary btn-lg" data-dismiss="modal">
                        Send Merchant Account
                    </button>
                </Modal>

                <Modal id="activate-merchant-account" theme="primary" iconType="fas" iconName="question-circle" title="Activate Merchant Account"
                       body="Are you sure you want to manually activate this merchant account? Manual activation will not send merchant account information to the payment processor, but will simply locally activate the merchant account.">
                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                        <FormattedMessage id="button.close" />
                    </button>
                    <button onClick={() => {this.updateMerchantAccountStatus("ACTIVE")}} className="btn btn-primary btn-lg" data-dismiss="modal">
                        Activate Merchant Account
                    </button>
                </Modal>

                <Modal id="deactivate-merchant-account" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Deactivate Merchant Account"
                       body="Are you sure you want to save and deactivate this merchant account? This will deactivate the merchant account directly with the processor.">
                    <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal">
                        <FormattedMessage id="button.close" />
                    </button>
                    <button onClick={() => {this.updateMerchantAccountStatus("CANCELLED")}} className="btn btn-danger btn-lg" data-dismiss="modal">
                        Deactivate Merchant Account
                    </button>
                </Modal>

                <div className="modal fade" id="suspend-merchant-account" tabIndex="-1" role="dialog" aria-labelledby="suspend-merchant-account-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form onSubmit={this.suspendMerchantAccount}>

                                <div className={`modal-header text-center d-block text-white py-4 bg-dark`}>
                                    <div className="">
                                        <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="fa-fw va-b mb-3" size="4x" />
                                    </div>
                                    <h5 className="modal-title" id="suspend-merchant-account-label">
                                        Suspend Merchant Account
                                    </h5>
                                </div>

                                <div className="modal-body text-left bg-secondary">
                                    <p className="mb-0">
                                        Are you sure you want to save and suspend this merchant account? Payments will fail to process for this merchant account until it is reactivated.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <FieldTextarea id="suspensionReason" label="Suspension Reason" model="merchantAccount"
                                                   labelColumns="0" fieldColumns="12" placeholder="Please provide a reason for the suspension"
                                                   fieldClass="form-control-sm" labelClass="col-form-label-sm d-none" required={true}
                                                   maxLength="200" parent={this} value={this.state.merchantAccount['suspensionReason']} />

                                </div>

                                <div className="modal-footer d-block text-center bg-secondary">

                                    <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal">
                                        <FormattedMessage id="button.close" />
                                    </button>
                                    <button type="submit" className="btn btn-danger btn-lg">
                                        Suspend Merchant Account
                                    </button>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="update-merchant-account" tabIndex="-1" role="dialog" aria-labelledby="update-merchant-account-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.updateMerchantAccount}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="update-merchant-account-label">
                                        Update Merchant Account Details
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="name" label="Name" model="merchantAccount" labelColumns="5" fieldColumns="7" parent={this} value={this.state.merchantAccount['name']} />

                                    <FieldText id="accountNumber" label="Account Number" model="merchantAccount" labelColumns="5" fieldColumns="7" parent={this} value={this.state.merchantAccount['accountNumber']}
                                               disabled={(this.state.merchantAccount['accountStatus'] === 'CANCELLED' || this.state.merchantAccountNumber) ? 'disabled' : ''}/>

                                    <FieldText id="descriptor" label="Descriptor" model="merchantAccount" labelColumns="5" fieldColumns="7" parent={this} value={this.state.merchantAccount['descriptor']} />

                                    {this.state.merchantAccount['customFields'] &&
                                    <React.Fragment>

                                        {this.state.merchantAccount.paymentProviderId === 'PAYSAFE' &&
                                        <React.Fragment>

                                            <FieldText id="storeId" label="Store ID" model="customFields" labelColumns="5" fieldColumns="7" parent={this} value={this.state.customFields['storeId']}
                                                       disabled={this.state.merchantAccount['accountStatus'] === 'CANCELLED' ? 'disabled' : ''}/>

                                            <FieldText id="storePassword" label="Store Password" model="customFields" labelColumns="5" fieldColumns="7" parent={this} value={this.state.customFields['storePassword']}
                                                       disabled={this.state.merchantAccount['accountStatus'] === 'CANCELLED' ? 'disabled' : ''}/>

                                        </React.Fragment>
                                        }

                                        {this.state.merchantAccount.paymentProviderId === 'VANTIV' &&
                                        <React.Fragment>

                                            <FieldText id="subMerchantId" label="Sub-Merchant ID" model="customFields" labelColumns="5" fieldColumns="7" parent={this} value={this.state.customFields['subMerchantId']}
                                                       disabled={this.state.merchantAccount['accountStatus'] === 'CANCELLED' ? 'disabled' : ''}/>

                                        </React.Fragment>
                                        }

                                        {this.state.merchantAccount.paymentProviderId === 'PAYNEARME' &&
                                        <React.Fragment>

                                            <FieldText id="secretKey" label="Secret Key" model="customFields" labelColumns="5" fieldColumns="7" parent={this} value={this.state.customFields['secretKey']}
                                                       disabled={this.state.merchantAccount['accountStatus'] === 'CANCELLED' ? 'disabled' : ''}/>

                                        </React.Fragment>
                                        }

                                    </React.Fragment>
                                    }

                                    <FieldSelect id="yearlyVolumeRange" label="Yearly Volume" model="merchantAccount" labelColumns="5" fieldColumns="7" parent={this} value={this.state.merchantAccount['yearlyVolumeRange']}>
                                        <option value="" disabled>Select a yearly volume range...</option>
                                        <option value="0-50K">Low ($0-$50,000)</option>
                                        <option value="50K-100K">Medium ($50,000-$100,000)</option>
                                        <option value="100K-250K">High ($100,000-$250,000)</option>
                                        <option value="250K+">Very High ($250,000+)</option>
                                    </FieldSelect>

                                    <FieldText id="averageTransAmount" label="Average Transaction" labelColumns="5" fieldColumns="7" model="merchantAccount" type="number"
                                               min="0.00" max="999999.99" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" parent={this}
                                               value={this.state.merchantAccount['averageTransAmount']} />


                                    <FieldText id="disbursementDelay" label="Disbursement Delay" model="merchantAccount" labelColumns="5" fieldColumns="7" type="number" max="99"
                                               parent={this} value={this.state.merchantAccount['disbursementDelay']} />

                                    {this.state.merchantAccount['customFields'] &&
                                    <React.Fragment>

                                        <FieldPhone id="phone" label="Phone" model="customFields" labelColumns="5" fieldColumns="7" parent={this} value={this.state.customFields['phone']} />

                                        <FieldText id="url" label="Company URL" model="customFields" labelColumns="5" fieldColumns="7" parent={this} value={this.state.customFields['url']} />

                                    </React.Fragment>
                                    }

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#update-merchant-account").modal("hide")}>Close</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonSave />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="update-banking-details" tabIndex="-1" role="dialog" aria-labelledby="update-banking-details-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.updateBankingDetails}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="update-banking-details-label">
                                        Update Banking Details
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="newBankAccountNumber" label="Account Number" labelColumns="4" fieldColumns="8" required={true} parent={this} value={this.state.newBankAccountNumber} />

                                    <FieldText id="newRoutingNumber" label="Routing Number" labelColumns="4" fieldColumns="8" required={true} parent={this} value={this.state.newRoutingNumber} />

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#update-banking-details").modal("hide")}>Close</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonSave />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

MerchantAccount.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(MerchantAccount);