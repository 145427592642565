import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Propertii from "./Propertii";
import ButtonClose from "./ButtonClose";
import FieldText from "./FieldText";
import $ from "jquery";

class ModalPhrase extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            confirmPhrase: '',
            confirmPhraseValid: null,
            validationList: [],
        };

        this.handleConfirm = this.handleConfirm.bind(this);
    }

    /**
     * Handle clicking the confirm button within the modal. Perform a check to see if the entered confirmation phrase
     * matches the required confirmation phrase. If there is a match, perform the action provided in the props.
     */
    handleConfirm() {

        if(this.props.confirmPhrase === this.state.confirmPhrase) {
            $(`#${this.props.id}`).modal('hide');
            this.props.handleSuccess();
        } else {
            this.setState(prevState => ({
                ...prevState,
                confirmPhraseValid: false
            }));
        }
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic modal component that prompts the user to enter specific a phrase in order to perform an
     * action.
     */
    render() {

        return (
            <React.Fragment>

                <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby={`${this.props.id}-label`} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header text-center d-block text-white py-4 bg-dark">
                                <div className="">
                                    <FontAwesomeIcon icon={[this.props.iconType, this.props.iconName]} className="fa-fw va-b mb-3" size="4x" />
                                </div>
                                <h5 className="modal-title" id={`${this.props.id}-label`}>
                                    {this.props.title}
                                </h5>
                            </div>

                            <div className="modal-body bg-secondary">

                                {this.props.children}

                            </div>

                            <div className="modal-body">

                                <FieldText id="confirmPhrase" required={true} labelClass="d-none mb-0" fieldClass={`form-control-lg mb-0 ${this.state.confirmPhraseValid != null && !this.state.confirmPhraseValid ? 'is-invalid' : ''}`}
                                           fieldColumns="12" labelColums="0" placeholder="Enter the required phrase" parent={this} value={this.state['confirmPhrase']} />

                            </div>

                            <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                                <ButtonClose theme="danger" />

                                <button onClick={() => this.handleConfirm()} className="btn btn-danger btn-lg">
                                    Confirm
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

export default ModalPhrase;
