import React from 'react';
import qs from 'qs';
import axios from "axios/index";
import { Link } from 'react-router-dom';
import * as constants from '../util/constants';
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Alert from "./common/Alert";
import Propertii from "./common/Propertii";
import familyMoving from "../media/img/backgrounds/couple-laptop.jpg";

class ResetPassword extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            verificationCode: '',
            newPassword: '',
            confirmNewPassword: '',
            submitted: false,
            validationList: [],
        };

        this.handleValidation = this.handleValidation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Parse the URL for the verification code query parameter. If no verification code is provided in the URL, redirect
     * the user to the login component.
     */
    componentDidMount() {

        let fields = this.state;

        fields['verificationCode'] = qs.parse(this.props.location.search.slice(1)).verificationCode;

        if(fields['verificationCode'] != null) {
            this.setState({ fields });
        } else {
            this.props.history.push('/login');
        }
    }

    /**
     * Handle change events on fields.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state.
     *
     * @param error - The server error response.
     */
    handleValidation(error) {

        let fields = {};

        if(error.response.data.fieldErrors) {
            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );
        }

        const accountStatus = error.response.data.accountStatus;

        // Display an error if the verification code is expired
        if(error.response.data.errorCode === 'RM_ERROR_VERIFICATION_CODE_EXPIRED') {
            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        message: error.response.data.message,
                        code: 'reset.RM_ERROR_VERIFICATION_CODE_EXPIRED'
                    },
                    fields
                }],
            });
            return;
        }

        // Display an error if the account has not requested a password reset
        if(error.response.data.errorCode === 'RM_ERROR_SYSTEM_ACCOUNT_STATUS') {
            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        message: error.response.data.message,
                        code: 'reset.RM_ERROR_SYSTEM_ACCOUNT_STATUS' + (accountStatus ? ('.' + accountStatus) : '')
                    },
                    fields
                }],
            });
            return;
        }

        // Display all other errors
        this.setState({
            validationList: [{
                alert: {
                    type: 'danger',
                    message: error.response.data.message,
                    code: error.response.data.errorCode
                },
                fields
            }],
        });
    }

    /**
     * Handle the reset password form submission. Upon success, the user is redirected to a confirmation component,
     * letting them know that their account password has been successfully reset, and they may now log in with their new
     * password.
     *
     * @param event - The event container.
     */
    handleSubmit(event) {

        event.preventDefault();

        axios.post(`${constants.REACT_APP_HOST_API_URL}/resetpassword`, {
            verificationCode: this.state.verificationCode,
            newPassword: this.state.newPassword,
            confirmNewPassword: this.state.confirmNewPassword
        }).then(response => {
            this.setState({
                submitted: true
            });
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A standard password reset form where the user is asked to enter their new account password.
     */
    render() {

        let renderFragment;

        // Render the form or success message fragment based on form submission state
        if(!this.state.submitted) {
            renderFragment = this.renderForm();
        } else {
            renderFragment = this.renderSuccess();
        }

        return(
            <div className="content-block">

                <div className="content-header mb-0">
                    <div className="container">

                        <div className="row no-gutters">
                            <div className="col-md-6 col-12 align-self-center pr-md-5">
                                <div className="jumbotron jumbotron-fluid bg-transparent">

                                    {renderFragment}

                                </div>
                            </div>
                            <div className="col-md-6 d-none d-md-block align-self-center">

                                <div style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${familyMoving}') no-repeat center center scroll`, backgroundSize: "cover", height: "725px", width: "998px"}}>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        );
    }

    /**
     * Render the password reset entry form fragment.
     *
     * @returns {*} - A simple form where the user is asked to enter, then re-enter their new password.
     */
    renderForm() {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <h1 className="display-5 mb-3">
                    <FormattedMessage id="static.reset.heading" />
                </h1>

                <p className="mb-0">
                    <FormattedMessage id="static.reset.body" />
                </p>

                <hr className="my-4" />

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.handleSubmit}>

                    <div className="form-group row">
                        <div className="col-sm-12">
                            <div className="input-group input-group-lg">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={['fas', 'key']} />
                                    </span>
                                </div>
                                <input name="newPassword" type="password" placeholder={formatMessage({ id: "field.password"})} value={this.state['newPassword']} onChange={this.handleChange} className={`form-control form-control-lg mb-0 rounded-right ${this.state.validationList[0] ? (this.state.validationList[0].fields['newPassword'] ? 'is-invalid' : '') : ''}`} />
                                {this.state.validationList[0] &&
                                <div className="invalid-feedback">
                                    <FormattedMessage id={"danger." + this.state.validationList[0].fields['newPassword']} />
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="form-group row mb-4">
                        <div className="col-sm-12">
                            <div className="input-group input-group-lg">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={['fas', 'key']} />
                                    </span>
                                </div>
                                <input name="confirmNewPassword" type="password" placeholder={formatMessage({ id: "field.password.confirm"})} value={this.state['confirmNewPassword']} onChange={this.handleChange} className={`form-control form-control-lg mb-0 rounded-right ${this.state.validationList[0] ? (this.state.validationList[0].fields['confirmNewPassword'] ? 'is-invalid' : '') : ''}`} />
                                {this.state.validationList[0] &&
                                <div className="invalid-feedback">
                                    <FormattedMessage id={"danger." + this.state.validationList[0].fields['confirmNewPassword']} />
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <Link to="/login" className="btn btn-lg btn-outline-primary btn-block">
                                <FormattedMessage id="button.cancel" />
                            </Link>
                        </div>
                        <div className="col-lg-6">
                            <button type="submit" className="btn btn-lg btn-primary btn-block">
                                <FormattedMessage id="button.save" />
                            </button>
                        </div>
                    </div>

                </form>

            </React.Fragment>
        );
    }

    /**
     * Render the success message fragment.
     *
     * @returns {*} - A message that tells the user that their password has been successfully reset. Clicking the button
     * will redirect the user back to the log in component where they may log in with their new password.
     */
    renderSuccess() {

        return(
            <React.Fragment>

                <div className="card card-primary border-primary">
                    <div className="card-body">
                        <h4 className="card-title">
                            <FormattedMessage id="static.reset.confirm.heading" />
                        </h4>
                        <p className="card-text">
                            <FormattedMessage id="static.reset.confirm.body" />
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Link to="/login" className="btn btn-lg btn-primary btn-block">
                            <FormattedMessage id="button.ok" />
                        </Link>
                    </div>
                </div>


            </React.Fragment>
        );
    }

}

ResetPassword.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ResetPassword);