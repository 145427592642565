import React, { Component } from 'react';
import paysafe from "../../media/img/logos/paysafe.png";
import worldpay from "../../media/img/logos/worldpay.png";
import paynearme from "../../media/img/logos/paynearme.png";
import rentmoola from "../../media/img/logos/rentmoola-b.png";
import geoswift from "../../media/img/logos/geoswift.png";
import loadhub from "../../media/img/logos/loadhub.png";
import bmo from "../../media/img/logos/bmo.png";

class LogoPaymentProvider extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - Renders a payment provider logo based on the payment provider ID in the props.
     */
    render() {

        return(
            <React.Fragment>

                {this.props.paymentProviderId === 'PAYSAFE' &&
                <img src={paysafe} className="img-fluid w-75" alt="Paysafe" />
                }
                {this.props.paymentProviderId === 'PAYSAFE_SPLIT_PAY' &&
                <img src={paysafe} className="img-fluid w-75" alt="Paysafe Split-Pay" />
                }
                {this.props.paymentProviderId === 'VANTIV' &&
                <img src={worldpay} className="img-fluid w-75" alt="Worldpay" />
                }
                {this.props.paymentProviderId === 'PAYNEARME' &&
                <img src={paynearme} className="img-fluid w-75" alt="PayNearMe" />
                }
                {this.props.paymentProviderId === 'RENTMOOLA_PP' &&
                <img src={rentmoola} className="img-fluid w-75" alt="Letus" />
                }
                {this.props.paymentProviderId === 'GEOSWIFT' &&
                <img src={geoswift} className="img-fluid w-75" alt="Geoswift" />
                }
                {this.props.paymentProviderId === 'LOADHUB' &&
                <img src={loadhub} className="img-fluid w-75" alt="Loadhub" />
                }
                {this.props.paymentProviderId === 'BMO' &&
                <img src={bmo} className="img-fluid w-75" alt="BMO Bank of Montreal" />
                }

            </React.Fragment>
        )
    };
}

export default LogoPaymentProvider;