import React from 'react';
import Propertii from "./Propertii";
import Modal from "./Modal";

class ModalUnionPay extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.confirmUnionPayCard = this.confirmUnionPayCard.bind(this);
    }

    /**
     * Prompt the user asking them if the credit card they entered is a China UnionPay card. This must be asked due to
     * UnionPay card numbers rarely conflicting with other card brands. If the user confirms that their card is indeed
     * UnionPay, override the brand field of the payment method accordingly.
     *
     * @param isUnionPay - The user's decision whether or not the card they entered is a UnionPay card.
     */
    confirmUnionPayCard(isUnionPay) {

        if(isUnionPay) {
            this.props.parent.setState(prevState => ({
                ...prevState,
                possibleUnionPayBin: false,
                paymentMethod: {
                    ...prevState.paymentMethod,
                    brand: 'UNION_PAY'
                }
            }), () => {
                this.props.parent.savePaymentMethod();
            });
        }

        if(!isUnionPay) {
            this.props.parent.setState(prevState => ({
                ...prevState,
                possibleUnionPayBin: false,
            }), () => {
                this.props.parent.savePaymentMethod();
            });
        }
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic modal component that prompts the user to enter specific a phrase in order to perform an
     * action.
     */
    render() {

        return (
            <React.Fragment>

                <Modal id="confirm-unionpay" theme="primary" iconType="fas" iconName="question-circle" title="UnionPay Card Detected"
                       body="Our records detect that the card number you entered may be a UnionPay card. Are you using a UnionPay card?">
                    <button onClick={() => {this.confirmUnionPayCard(false)}} className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                        No
                    </button>
                    <button onClick={() => {this.confirmUnionPayCard(true)}} className="btn btn-primary btn-lg" data-dismiss="modal">
                        Yes, this is a UnionPay Card
                    </button>
                </Modal>

            </React.Fragment>
        )
    };
}

export default ModalUnionPay;
