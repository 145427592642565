import React from 'react';
import axios from 'axios';
import {injectIntl, intlShape} from "react-intl";
import * as constants from "../util/constants";
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import Alert from "./common/Alert";
import Letus from "./common/Propertii";
// import GoogleLogin from "react-google-login";
// import google from "../media/img/logos/google.png";
import FieldText from "./common/FieldText";
import FieldPassword from "./common/FieldPassword";
import Spinner from "./common/Spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import womanChair from "../media/img/backgrounds/woman-chair.jpg";
import womanOffice from "../media/img/backgrounds/woman-office.jpg";
import paymentMethods from "../media/img/mockups/payment-methods.png";
import tenantScreening from "../media/img/mockups/tenant-screening.png";
import application from "../media/img/mockups/application.png";
import editRecurring from "../media/img/mockups/edit-recurring.png";

class Landlords extends Letus {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            landlord: {},
            firstName: '',
            lastName: '',
            email: '',
            birthDate: '',
            username: '',
            password: '',
            confirmPassword: '',
            accessCode: '',
            validationList: [],
        };

        this.handleValidation = this.handleValidation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOAuth = this.handleOAuth.bind(this);
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state.
     *
     * @param error - The server error response.
     */
    handleValidation(error) {

        let fields = {};

        this.setState({
            spinner: false,
        });

        if(error.response.data.fieldErrors) {
            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );
        }

        const recordType = error.response.data.recordType;

        // Handle duplicate email validation errors
        if(error.response.data.errorCode === 'RM_ERROR_RECORD_ALREADY_EXIST') {
            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: error.response.data.errorCode + (recordType ? ('.TYPE_LANDLORD') : ''),
                        message: error.response.data.message
                    },
                    fields
                }],
            });
            return;
        }

        this.setState({
            spinner: false,
            validationList: [{
                alert: {
                    type: 'danger',
                    code: error.response.data.errorCode,
                    message: error.response.data.message
                },
                fields
            }],
        });
    }

    /**
     * Handle landlords signing up via OAuth. Signing up via OAuth allows the user to skip the email verification step
     * and proceed directly to their application form.
     *
     * @param response - The returned OAuth response.
     */
    handleOAuth(response) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/authorizeoauth?accountType=TYPE_GOOGLE_OAUTH_ACCOUNT&authorizationCode=${encodeURIComponent(response.code)}&requestUri=${encodeURIComponent(window.location.origin)}&userType=TYPE_LANDLORD`).then(response => {

            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);

            this.props.refreshAppState();

            let token = localStorage.getItem('access_token');
            let sessionRole;

            if(token !== null) {

                let base64Url = token.split('.')[1];
                let base64 = base64Url.replace('-', '+').replace('_', '/');
                let userSession = JSON.parse(window.atob(base64));

                sessionRole = JSON.parse(userSession.sessionRole);

                localStorage.setItem('status', sessionRole.applicationStatus);
            }

            this.props.history.push(this.handleLoginRedirect(sessionRole));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle sign up form submission. If successful, a message will be displayed, prompting the user to check their
     * email inbox to confirm their email address.
     *
     * @param event - The event container.
     */
    handleSubmit(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/new`).then(response => {

            let landlord = response.data;

            landlord.firstName = this.state.firstName;
            landlord.lastName = this.state.lastName;
            landlord.email = this.state.email;
            landlord.customFields.accessCode = this.state.accessCode;

            axios.post(`${constants.REACT_APP_HOST_API_URL}/signup`, {
                user: {
                    ...landlord,
                    applicationStatus: "PENDING",
                    birthDate: this.state.birthDate,
                },
                username: this.state.email,
                password: this.state.password,
                confirmPassword: this.state.confirmPassword
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                }));

                this.props.history.push({
                    pathname: `/verify`,
                    state: {
                        email: this.state.email,
                    }
                });

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The standard user sign up form.
     */
    render() {

        const {formatMessage} = this.props.intl;

        let ScrollLink = Scroll.Link;

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner}/>

                <div id="signup" className="jumbotron jumbotron-fluid content-header content-header-landing mb-0" style={{background: `linear-gradient(100deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.1) 100%) 0% 0% / cover, url('${womanOffice}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container">

                        <div className="row align-items-center">
                            <div className="col-md-6 align-self-center">

                                <h1 className="text-white mb-4 display-4">
                                    The all-in-one property management solution
                                </h1>
                                <p className="lead text-white">
                                    No matter the size of your portfolio, Letus simplifies every aspect of your property management duties with one simple platform.
                                </p>

                            </div>
                            <div className="col-md-6">

                                <div className="jumbotron jumbotron-fluid rounded shadow mx-md-5 px-4 py-5">
                                    <div className="container">

                                        <form onSubmit={this.handleSubmit} autoComplete="off">

                                            <h3 className="text-center">
                                                Let's get started
                                            </h3>

                                            <hr className="my-4"/>

                                            <Alert validationList={this.state.validationList} />

                                            <div className="row">
                                                <div className="col-6">

                                                    <FieldText id="firstName" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0"
                                                               fieldColumns="12" labelColums="0" placeholder={formatMessage({ id: "field.firstname"})}
                                                               parent={this} value={this.state['firstName']} maxLength="100" autoFocus={true} />

                                                </div>
                                                <div className="col-6">

                                                    <FieldText id="lastName" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0"
                                                               fieldColumns="12" labelColums="0" placeholder={formatMessage({ id: "field.lastname"})}
                                                               parent={this} value={this.state['lastName']} maxLength="100" />

                                                </div>
                                            </div>

                                            <FieldText id="email" type="email" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0"
                                                       fieldColumns="12" labelColums="0" placeholder={formatMessage({ id: "field.email"})}
                                                       parent={this} value={this.state['email']} maxLength="100" />

                                            <FieldPassword id="password" type="password" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0"
                                                           fieldColumns="12" labelColums="0" placeholder={formatMessage({ id: "field.password"})}
                                                           parent={this} value={this.state['password']} dataPlacement="right" maxLength="100"
                                                           dataHtml={true} dataContent={
                                                `<span class="font-weight-bold">Your password must:</span>
                                               <ul>
                                                    <li>Be at least 8 characters long</li>
                                                    <li>Contain at least one lowercase character</li>
                                                    <li>Contain at least one uppercase character</li>
                                                    <li>Contain at least one number</li>
                                               </ul>`
                                            } />

                                            <FieldText id="confirmPassword" type="password" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0"
                                                       fieldColumns="12" labelColums="0" placeholder={formatMessage({ id: "field.password.confirm"})}
                                                       parent={this} value={this.state['confirmPassword']} maxLength="100" />

                                            <FieldText id="accessCode" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0"
                                                       fieldColumns="12" labelColums="0" placeholder="Early Access Code"
                                                       parent={this} value={this.state['accessCode']} maxLength="100" />

                                            <div className="row">
                                                <div className="col text-right">
                                                    <button type="submit" className="btn btn-lg btn-primary btn-block mb-0">
                                                        Create an Account
                                                    </button>
                                                </div>
                                            </div>

                                            {/* <div className="divider">
                                                <span className="small text-muted font-italic text-uppercase">or</span>
                                            </div>

                                            <div className="row">
                                                <div className="col text-right">
                                                    <GoogleLogin
                                                        clientId={constants.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                                                        render={renderProps => (
                                                            <div className="btn btn-md btn-block btn-outline-primary btn-google" onClick={renderProps.onClick}>
                                                                <img src={google} alt="Log In with Google" style={{height: '15px'}} className="m-1" /> Create Account with Google
                                                            </div>
                                                        )}
                                                        onSuccess={this.handleOAuth}
                                                        onFailure={this.handleOAuth}
                                                        responseType="code"
                                                    />
                                                </div>
                                            </div> */}

                                        </form>

                                    </div>

                                </div>

                                <div className="row justify-content-sm-center">
                                    <div className="col-md-10 text-center">
                                        <p className="text-white text-center small">
                                            Already have an account? <Link to="/login">Log in now</Link>.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="container">

                    <div className="text-center pt-4">
                        <h1 className="py-4">
                            Letus has all your bases covered
                        </h1>
                    </div>

                    <div className="row justify-content-center py-5">

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'id-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Applications
                                </h3>
                                <p className="">
                                    Accept lease applications with ease using a sharable link or personalized invitations.
                                </p>
                                <ScrollLink to="applications" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'search']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Screenings
                                </h3>
                                <p className="">
                                    Secure background and credit checks ensure better tenants and reduced turnover.
                                </p>
                                <ScrollLink to="screenings" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'credit-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Payments
                                </h3>
                                <p className="">
                                    With flexible payment options and recurring schedules, accepting payments is a breeze.
                                </p>
                                <ScrollLink to="payments" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'building']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Management
                                </h3>
                                <p className="">
                                    Manage your properties, tenants, business details, and more, all in one convenient location.
                                </p>
                                <ScrollLink to="management" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                    </div>

                    <hr className="my-5" id="applications" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h1 className="mb-4">
                                Online lease applications made simple
                            </h1>
                            <p className="mb-4 lead">
                                Letus allows you to track inbound lease applications, send out personalized invitations, or share a unique link on your favorite listing service.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'clipboard-user']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Secure Online Forms</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'user-check']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Customized Invitations</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={application} alt="Streamline your tenancy application process with Letus' intuitive online lease application forms" className="img-sm-fluid" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="screenings" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h1 className="mb-4">
                                Comprehensive background and credit checks, on-demand
                            </h1>
                            <p className="mb-4 lead">
                                Letus' tenant screening tools can perform in-depth checks on your applicants, so you can rest easy knowing you're finding better tenants.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'clock']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Faster Screenings</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'user-friends']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Reduced Tenant Turnover</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={tenantScreening} alt="Open the door to better tenants and reduce tenant turnover with Letus' tenant screening tools" className="img-sm-fluid float-right" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="payments" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h1 className="mb-4">
                                Simple and secure online rent payments
                            </h1>
                            <p className="mb-4 lead">
                                Letus enables you to collect rent online without the hassle of cash, cheques, or e-transfers, with payments deposited directly into your bank account.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'credit-card']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Convenient Payment Methods</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'grin-beam']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Higher Tenant Satisfaction</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={paymentMethods} alt="Offer your tenants the ability to make rent payments with their preferred payment method including Visa Credit, Visa Debit, Mastercard, Mastercard Debit, American Express, or directly from their bank account" className="img-sm-fluid" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="management" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h1 className="mb-4">
                                Smarter property management at your fingertips
                            </h1>
                            <p className="mb-4 lead">
                                With a variety of tools and insights to manage your properties and paying tenants alike, your Letus dashboard replaces the pen and paper of the past.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'window-restore']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Manage Multiple Portfolios</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'shield-check']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Secure Web-Based Platform</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={editRecurring} alt="Letus is your central hub when it comes to all things property management" className="img-sm-fluid float-right" width="130%"/>
                        </div>
                    </div>

                </div>

                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${womanChair}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container text-center">
                        <h1 className="pt-4 mb-4 text-white">
                            A better rental experience begins with Letus
                        </h1>
                        <div className="row justify-content-md-center">
                            <div className="col-md-8 text-md-center">
                                <p className="lead text-white">
                                    Trusted by North America's biggest property managers, now available to you. Start by creating your Letus account today.
                                </p>
                            </div>
                        </div>
                        <ScrollLink to="signup" smooth={true} duration={1000} className="btn btn-lg btn-primary mt-3 mb-4 px-4 text-white">
                            Create an Account
                        </ScrollLink>
                        <Link to="/pricing" className="btn btn-lg btn-secondary mt-3 mb-4 px-4 mx-2">
                            View Pricing
                        </Link>
                    </div>
                </div>

            </div>
        );
    }
}

Landlords.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Landlords);
