import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

class Breadcrumb extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A  breadcrumb component used to indicate the context of where a user is at when viewing a
     * particular navigation tab. Supports three levels of breadcrumbs. Generally the front end should not go deeper
     * than three levels.
     */
    render() {

        return(
            <React.Fragment>

                <h2 className="display-5 mb-5">
                    <span className="pr-2">

                        {this.props.parentPath &&
                        <Link to={this.props.parentPath}>
                            {this.props.parentPage}
                        </Link>
                        }

                        {!this.props.parentPath &&
                        <React.Fragment>
                            {this.props.parentPage}
                        </React.Fragment>
                        }

                    </span>

                    {this.props.childPage &&
                    <React.Fragment>

                        {this.props.childPath &&
                        <span className="text-muted small pr-2">
                            <FontAwesomeIcon icon={['fas', 'chevron-right']} className="fa-fw small"/> <Link to={this.props.childPath}>{this.props.childPage}</Link>
                        </span>
                        }

                        {!this.props.childPath &&
                        <span className="text-muted small pr-2">
                            <FontAwesomeIcon icon={['fas', 'chevron-right']} className="fa-fw small"/> {this.props.childPage}
                        </span>
                        }

                    </React.Fragment>
                    }

                    {this.props.subChildPage &&
                    <React.Fragment>

                        <span className="text-muted small pr-2">
                            <FontAwesomeIcon icon={['fas', 'chevron-right']} className="fa-fw small"/> {this.props.subChildPage}
                        </span>

                    </React.Fragment>
                    }

                </h2>

            </React.Fragment>
        )
    };
}

export default Breadcrumb;