import React, { Component } from 'react';

class ButtonUpload extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A generic upload button component.
     */
    render() {

        return(
            <React.Fragment>

                <button type="submit" className={`btn btn-primary btn-lg ${this.props.customClasses ? this.props.customClasses : 'ml-2'}`}>
                    Upload
                </button>

            </React.Fragment>
        )
    };
}

export default ButtonUpload;