import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import {injectIntl, intlShape} from "react-intl";
import ButtonSave from "../../common/ButtonSave";
import Propertii from "../../common/Propertii";
import FieldText from "../../common/FieldText";
import FieldCompanyType from "../../common/FieldCompanyType";
import FieldAddress from "../../common/FieldAddress";
import FieldTextarea from "../../common/FieldTextarea";
import FieldSelect from "../../common/FieldSelect";
import FieldSegmentCode from "../../common/FieldSegmentCode";
import FieldPhone from "../../common/FieldPhone";
import FieldBirthDate from "../../common/FieldBirthDate";
import Spinner from "../../common/Spinner";

class Company extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            company: {},

            creditMerchantAccountList: {},

            admins: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            validationList: [],

        };

        this.getAdmins = this.getAdmins.bind(this);

        this.handleChangeCreditMerchantAccount = this.handleChangeCreditMerchantAccount.bind(this);
        this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Load the required data on the mounting of the component. Fetch the company based on the ID provided in the route.
     * Fetch the parent company if the company has a parent ID. Fetch a list of admin accounts for populating account
     * and sales manager select boxes. Fetch a list of merchant accounts for populating default merchant account select
     * boxes.
     */
    componentDidMount() {

        this.setState(prevState => ({
            spinner: true
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.match.params.companyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                company: response.data,
                creditMerchantAccountList: response.data.creditMerchantAccountList
            }));

            this.getAdmins();

            let companyId = this.props.match.params.companyId;

            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA_ELECTRON');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MASTERCARD');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MAESTRO');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'AMERICAN_EXPRESS');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DISCOVER');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DINERS_CLUB');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'JCB');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'UNION_PAY');
            this.getMerchantAccounts(companyId,'TYPE_BANK_ACCOUNT');
            this.getMerchantAccounts(companyId,'TYPE_CASH');
            this.getMerchantAccounts(companyId,null);

            if(response.data.parentId != null) {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.company.parentId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        parentCompany: response.data
                    }));
                }).catch(error => {
                    console.error(error);
                });
            }

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle changes in the route's ID.
     *
     * @param prevProps - The previous props.
     * @param prevState - The previous state.
     * @param snapshot - A snapshot of the data.
     */
    componentDidUpdate(prevProps, prevState, snapshot) {

        const oldId = prevProps.match.params.companyId;
        const newId = this.props.match.params.companyId;

        if(oldId !== newId) {

            this.setState(prevState => ({
                admins: prevState.admins,
                creditMerchantAccountList: prevState.creditMerchantAccountList,
                bankMerchantAccounts: prevState.bankMerchantAccounts,
                cashMerchantAccounts: prevState.cashMerchantAccounts,
                padMerchantAccounts: prevState.padMerchantAccounts,
                visaMerchantAccounts: prevState.visaMerchantAccounts,
                visaElectronMerchantAccounts: prevState.visaElectronMerchantAccounts,
                mastercardMerchantAccounts: prevState.mastercardMerchantAccounts,
                maestroMerchantAccounts: prevState.maestroMerchantAccounts,
                americanExpressMerchantAccounts: prevState.americanExpressMerchantAccounts,
                discoverMerchantAccounts: prevState.discoverMerchantAccounts,
                dinersClubMerchantAccounts: prevState.dinersClubMerchantAccounts,
                jcbMerchantAccounts: prevState.jcbMerchantAccounts,
                unionPayMerchantAccounts: prevState.unionPayMerchantAccounts,
                creditMerchantAccounts: prevState.creditMerchantAccounts,
                company: prevState.parentCompany,
                parentCompany: {}
            }));
        }
    }

    /**
     * Fetch a list of admin accounts for populating the account and sales managers select boxes.
     */
    getAdmins() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/admin/search`, {
            orderBy: 'ASC',
            orderByFields: ['firstName', 'lastName']
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                admins: response.data
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle changes to the default credit/debit merchant account field. By selecting a merchant account from this
     * field, the same merchant account will be applies to all credit/debit card brands available.
     *
     * @param event - The event container.
     */
    handleChangeCreditMerchantAccount(event) {

        event.preventDefault();

        let creditMerchantAccountList = this.state.creditMerchantAccountList;
        let creditMerchantAccountId = event.target.value;

        creditMerchantAccountList['VISA'] = creditMerchantAccountId;
        creditMerchantAccountList['VISA_ELECTRON'] = creditMerchantAccountId;
        creditMerchantAccountList['MASTERCARD'] = creditMerchantAccountId;
        creditMerchantAccountList['MAESTRO'] = creditMerchantAccountId;
        creditMerchantAccountList['AMERICAN_EXPRESS'] = creditMerchantAccountId;
        creditMerchantAccountList['DISCOVER'] = creditMerchantAccountId;
        creditMerchantAccountList['DINERS_CLUB'] = creditMerchantAccountId;
        creditMerchantAccountList['JCB'] = creditMerchantAccountId;

        this.setState(prevState => ({
            ...prevState,
            creditMerchantAccountList: creditMerchantAccountList,
        }));
    }

    /**
     * Handle updating the company's status.
     *
     * @param status - The new status of the company.
     */
    handleUpdateStatus(status) {

        let company = this.state.company;

        company['status'] = status;

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, company, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.company.status.' + status
                    }
                }],
            })
        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle submitting updates to the company.
     */
    handleSubmit(event) {

        event.preventDefault();

        let company = this.state.company;

        company.creditMerchantAccountList = this.state.creditMerchantAccountList;

        Object.entries(company.creditMerchantAccountList).forEach(([key, value]) => {
            if(value === '') {
                delete company.creditMerchantAccountList[key];
            }
        });

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, company, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved'
                    }
                }],
            });

            this.props.handleRefresh(this.props.match.params.companyId);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The edit child company component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.handleSubmit}>

                    {this.state.company &&
                    <div className="card">
                        <div className="card-header">
                            Company Details
                        </div>
                        <div className="card-body">

                            <FieldText id="name" label="Company Name" model="company" parent={this} value={this.state.company['name']}/>

                            <FieldText id="legalName" label="Legal Name" model="company" parent={this} value={this.state.company['legalName']}/>

                            <FieldCompanyType id="companyType" label="Company Type" model="company" parent={this} value={this.state.company['companyType']}/>

                            <FieldSegmentCode id="segmentCode" label="Segment Code" model="company" parent={this} value={this.state.company['segmentCode']}/>

                            <FieldPhone id="phone" label="Business Phone" model="company" parent={this} value={this.state.company['phone']}/>

                            <FieldText id="taxNumber" label="Tax ID" model="company" parent={this} value={this.state.company['taxNumber']}/>

                            <FieldBirthDate id="inceptionDate" label="Inception Date" model="company" parent={this} required={true} value={this.state.company['inceptionDate']}/>

                            <FieldTextarea id="description" label="Description" model="company" parent={this} value={this.state.company['description']}/>

                        </div>
                    </div>
                    }

                    <div className="card">
                        <div className="card-header">
                            Company Address
                        </div>
                        <div className="card-body">

                            <FieldAddress model="company" parent={this} value={this.state.company} />

                        </div>
                    </div>

                    {this.state.company &&
                    <div className="card">
                        <div className="card-header">
                            Administrators
                        </div>
                        <div className="card-body">

                            <FieldSelect id="accountManagerId" label="Account Manager" model="company" parent={this} value={this.state.company['accountManagerId']}>
                                <option value="">Select an account manager...</option>
                                {this.state.admins &&
                                <React.Fragment>
                                    {this.state.admins.records.map((data, key) => {
                                        return (
                                            <option key={key} value={data.id}>{data.firstName} {data.lastName}</option>
                                        );
                                    })}
                                </React.Fragment>
                                }
                            </FieldSelect>

                            <FieldSelect id="salesManagerId" label="Sales Manager" model="company" parent={this} value={this.state.company['salesManagerId']}>
                                <option value="">Select an account manager...</option>
                                {this.state.admins &&
                                <React.Fragment>
                                    {this.state.admins.records.map((data, key) => {
                                        return (
                                            <option key={key} value={data.id}>{data.firstName} {data.lastName}</option>
                                        );
                                    })}
                                </React.Fragment>
                                }
                            </FieldSelect>

                        </div>
                    </div>
                    }

                    {this.state.company &&
                    <div className="card">
                        <div className="card-header">
                            Default Merchant Accounts
                        </div>
                        <div className="card-body">

                            {this.state.company.creditMerchantAccountList &&
                            <React.Fragment>

                                <FieldSelect id="VISA" label="Credit/Debit Card" model="creditMerchantAccountList" parent={this} help="Applies to Visa, Visa Electron, Mastercard, Maestro, American Express, Discover, Diners Club, and JCB cards." value={this.state.creditMerchantAccountList['VISA']} handleChange={this.handleChangeCreditMerchantAccount}>
                                    <option value="">-</option>
                                    {this.state.visaMerchantAccounts &&
                                    <React.Fragment>
                                        {this.state.visaMerchantAccounts.map((data, key) => {
                                            return (
                                                <option key={key} value={data.id}>{data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                            );
                                        })}
                                    </React.Fragment>
                                    }
                                </FieldSelect>

                                <FieldSelect id="UNION_PAY" label="UnionPay" model="creditMerchantAccountList" parent={this} value={this.state.company.creditMerchantAccountList['UNION_PAY']}>
                                    <option value="">-</option>
                                    {this.state.unionPayMerchantAccounts &&
                                    <React.Fragment>
                                        {this.state.unionPayMerchantAccounts.map((data, key) => {
                                            return (
                                                <option key={key} value={data.id}>{data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                            );
                                        })}
                                    </React.Fragment>
                                    }
                                </FieldSelect>

                            </React.Fragment>
                            }



                            <FieldSelect id="bankMerchantAccountId" label="Bank Account" model="company" parent={this} value={this.state.company['bankMerchantAccountId']}>
                                <option value="">-</option>
                                {this.state.bankMerchantAccounts &&
                                <React.Fragment>
                                    {this.state.bankMerchantAccounts.map((data, key) => {
                                        return (
                                            <option key={key} value={data.id}>{data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                        );
                                    })}
                                </React.Fragment>
                                }
                            </FieldSelect>

                            <FieldSelect id="cashMerchantAccountId" label="Cash" model="company" parent={this} value={this.state.company['cashMerchantAccountId']}>
                                <option value="">-</option>
                                {this.state.cashMerchantAccounts &&
                                <React.Fragment>
                                    {this.state.cashMerchantAccounts.map((data, key) => {
                                        return (
                                            <option key={key} value={data.id}>{data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                        );
                                    })}
                                </React.Fragment>
                                }
                            </FieldSelect>

                            <FieldSelect id="padMerchantAccountId" label="Pre-Authorized Debit" model="company" parent={this} value={this.state.company['padMerchantAccountId']}>
                                <option value="">-</option>
                                {this.state.padMerchantAccounts &&
                                <React.Fragment>
                                    {this.state.padMerchantAccounts.map((data, key) => {
                                        return (
                                            <option key={key} value={data.id}>{data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                        );
                                    })}
                                </React.Fragment>
                                }
                            </FieldSelect>

                        </div>
                        <div className="card-footer">
                            <p className="text-muted small mb-0">
                                If a property under this company does not have any specifically assigned merchant
                                accounts, the property will utilize these default merchant accounts.
                            </p>
                        </div>
                    </div>
                    }

                    <div className="row">

                        <div className="col text-right">

                            <ButtonSave />

                        </div>
                    </div>

                </form>

            </React.Fragment>
        )
    };
}

Company.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Company);