import React from 'react';
import Propertii from "../../common/Propertii";
import {injectIntl, intlShape} from "react-intl";
import TenantList from "../../common/Tenants";

class Tenants extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The view tenants component for admins.
     */
    render() {

        return(
            <React.Fragment>

                <TenantList userType={this.props.userSession.sessionRole.type}
                            userId={this.props.userSession.sessionRole.id}
                            propertyId={this.props.match.params.propertyId}
                            companyId={this.props.property.companyId}
                            context="property"
                            history={this.props.history}
                            parent={this} />

            </React.Fragment>
        )
    };
}

Tenants.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Tenants);