import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Table from "../../common/Table";
import {FormattedMessage, FormattedNumber} from "react-intl";
import Propertii from "../../common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import ButtonSave from "../../common/ButtonSave";
import FieldText from "../../common/FieldText";
import Modal from "../../common/Modal";
import Alert from "../../common/Alert";
import FieldSelect from "../../common/FieldSelect";

class ServiceFees extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            serviceFee: {},
            serviceFeeList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },
            serviceFeeQuery: {
                orderBy: 'ASC',
                orderByFields: ['createDate'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'rmCompanyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.match.params.companyId
                    }
                ]
            },
            validationList: [],
        };

        this.searchServiceFees = this.searchServiceFees.bind(this);
        this.editServiceFee = this.editServiceFee.bind(this);
        this.initServiceFee = this.initServiceFee.bind(this);
        this.saveServiceFee = this.saveServiceFee.bind(this);
        this.deleteServiceFee = this.deleteServiceFee.bind(this);
    }

    /**
     * Load the list of service fees on mounting of the component.
     */
    componentDidMount() {

        this.searchServiceFees(1, 25, this.state.serviceFeeQuery);
    }

    /**
     * Handle selecting a service fee record by bringing up the modal with service fee values pre-filled.
     *
     * @param serviceFeeId - The ID of the service fee selected.
     */
    editServiceFee(serviceFeeId) {

        axios.get(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/billing/fees/${serviceFeeId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                serviceFee: response.data
            }));

            $('#service-fee').modal('show');

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Initialize a new instance of a service fee object when the user clicks the appropriate button button.
     */
    initServiceFee() {

        axios.get(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/billing/fees/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                serviceFee: {
                    ...response.data,
                    rmCompanyId: this.props.match.params.companyId
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Delete the selected service fee.
     */
    deleteServiceFee() {

        axios.delete(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/billing/fees/${this.state.serviceFee.id}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.system.fees.deleted'
                    }
                }],
            });

            this.searchServiceFees(1, 25, this.state.serviceFeeQuery);

            $('#service-fee').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Create or update an existing service fee.
     *
     * @param event - The event container.
     */
    saveServiceFee(event) {

        event.preventDefault();

        if(this.state.serviceFee.id == null) {
            axios.post(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/billing/fees/create`, this.state.serviceFee, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.companies.fees.created'
                        }
                    }],
                });

                this.searchServiceFees(this.state.serviceFeeList.page, this.state.serviceFeeList.recordsPerPage, this.state.serviceFeeQuery);

                $('#service-fee').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            axios.put(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/billing/fees/update`, this.state.serviceFee, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved'
                        }
                    }],
                });

                this.searchServiceFees(this.state.serviceFeeList.page, this.state.serviceFeeList.recordsPerPage, this.state.serviceFeeQuery);

                $('#service-fee').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Update the data table of default service fees.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchServiceFees(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/billing/fees/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                serviceFeeList: {
                    ...response.data,
                    records: response.data.records ? response.data.records : [{}]
                },
                serviceFeeQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The default fee profile list interface.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} validationType="primary" />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Service Fees
                            </div>
                            <div className="col text-right">
                                <div data-toggle="modal" data-target="#service-fee" className="btn btn-primary btn-sm" onClick={() => this.initServiceFee()}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Service Fee
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table columns={{serviceCode: 'Service Fee', description: 'Description', taxJurisdiction1: 'Tax Jurisdiction 1', taxJurisdiction2: 'Tax Jurisdiction 2', feeAmount: 'Unit Price'}}
                           columnWidths={['20%', '20%', '20%', '20%', '20%']}
                           headerClass="c-pointer"
                           data={this.state.serviceFeeList}
                           query={this.state.serviceFeeQuery}
                           sortEnabled={true}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.searchServiceFees}>
                        <tbody>
                        {this.state.serviceFeeList.records.map((data, key) => {
                            return(
                                <tr key={key} onClick={() => this.editServiceFee(data.id)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            <FormattedMessage id={"enum.serviceFee.serviceCode." + data.serviceCode} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.description}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.taxJurisdiction1 ? `${data.taxJurisdiction1} (${data.taxRate1}%)` : ''}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.taxJurisdiction2 ? `${data.taxJurisdiction2} (${data.taxRate2}%)` : ''}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            <FormattedNumber value={data.feeAmount} style={`currency`} currency={`USD`} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="service-fee" tabIndex="-1" role="dialog" aria-labelledby="service-fee-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveServiceFee}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="service-fee-label">
                                        {this.state.serviceFee['createDate'] == null &&
                                        <span className="">Create Service Fee</span>
                                        }
                                        {this.state.serviceFee['createDate'] != null &&
                                        <span className="">Edit Service Fee</span>
                                        }
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldSelect id="serviceCode" label="Service Fee" disabled={this.state.serviceFee['createDate']} model="serviceFee" parent={this} value={this.state.serviceFee['serviceCode']}>
                                        <option value="" disabled>Select a service fee...</option>
                                        <option value="SCREENING_BASIC">Background Check Fee</option>
                                        <option value="SCREENING_CREDIT">Credit Check Fee</option>
                                        <option value="MANAGER_SERVICE_FEE">Manager Service Fee</option>
                                        <option value="MANAGED_LEASE_FEE">Manager Per-Unit Fee</option>
                                        <option value="LANDLORD_SUBSCRIPTION">Landlord Service Fee</option>
                                        <option value="LANDLORD_LEASE_FEE">Landlord Per-Unit Fee</option>
                                    </FieldSelect>

                                    <FieldText id="feeAmount" label="Unit Price" prependIconType="fas" prependIconName="dollar-sign" type="number" min="0.00" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" model="serviceFee" parent={this} value={this.state.serviceFee['feeAmount']} />

                                    <FieldText id="taxJurisdiction1" label="Tax 1 Type" model="serviceFee" parent={this} value={this.state.serviceFee['taxJurisdiction1']} />

                                    <FieldText id="taxRate1" label="Tax 1 Rate" prependIconType="fas" prependIconName="percentage" type="number" min="0.00" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" model="serviceFee" parent={this} value={this.state.serviceFee['taxRate1']} />

                                    <FieldText id="taxJurisdiction2" label="Tax 2 Type" model="serviceFee" parent={this} value={this.state.serviceFee['taxJurisdiction2']} />

                                    <FieldText id="taxRate2" label="Tax 2 Rate" prependIconType="fas" prependIconName="percentage" type="number" min="0.00" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" model="serviceFee" parent={this} value={this.state.serviceFee['taxRate2']} />

                                    <FieldText id="description" label="Description" model="serviceFee" parent={this} value={this.state.serviceFee['description']} />

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#service-fee").modal("hide")}>Close</button>
                                        </div>
                                        <div className="col-8 text-right">

                                            {this.state.serviceFee['id'] != null &&
                                            <div className="btn btn-lg btn-primary ml-2" onClick={() => {$("#service-fee").modal("hide"); $("#delete-service-fee").modal("show");}}>
                                                Delete
                                            </div>
                                            }

                                            <ButtonSave />

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <Modal id="delete-service-fee" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Service Fee"
                       body="Are you sure you would like to delete this default service fee?">
                    <button type="button" className="btn btn-outline-danger btn-lg my-2" data-dismiss="modal" onClick={() => {$("#service-fee").modal("show")}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button onClick={() => {this.deleteServiceFee()}} className="btn btn-danger btn-lg my-2" data-dismiss="modal">
                        Delete Service Fee
                    </button>
                </Modal>

            </React.Fragment>
        )
    };
}

export default ServiceFees;