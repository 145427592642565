import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import * as Scroll from 'react-scroll';
import Letus from "./common/Propertii";
import Spinner from "./common/Spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import womanChair from "../media/img/backgrounds/woman-chair.jpg";
import womanPhone from "../media/img/backgrounds/woman-phone.jpg";
import paymentFlow from "../media/img/mockups/payment-flow.png";
import recurringPayment from "../media/img/mockups/recurring-payment.png";
import creditCards from "../media/img/mockups/credit-cards.png";
import transactions from "../media/img/mockups/transactions.png";
import visa from "../media/img/payments/visa.png";
import mastercard from "../media/img/payments/mastercard.png";
import americanExpress from "../media/img/payments/american-express.png";
import discover from "../media/img/payments/discover.png";
import unionPay from "../media/img/payments/unionpay.png";
import bankAccount from "../media/img/payments/bank-account.png";

class Tenants extends Letus {

    /**
     * Render the component.
     *
     * @returns {*} - The tenants landing page component.
     */
    render() {

        let ScrollLink = Scroll.Link;

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner}/>

                <div id="signup" className="jumbotron jumbotron-fluid content-header content-header-landing mb-0" style={{background: `linear-gradient(100deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${womanPhone}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container">

                        <div className="row align-items-center">
                            <div className="col-md-6">

                                <h1 className="text-white mb-4 display-4">
                                    Scrap the checkbook and enjoy online rent payments
                                </h1>
                                <p className="lead text-white mb-4">
                                    Letus turns the headaches of being a renter into a simple and rewarding experience. Setup automatic rent payments, using any payment method, on any device, all under one roof.
                                </p>

                                <div className="mb-4">
                                    <img src={visa} className="rounded img-fluid bg-light mr-2" alt="Visa" title="Visa" width="50"/>
                                    <img src={mastercard} className="rounded img-fluid bg-light mr-2" alt="Mastercard" title="Mastercard" width="50"/>
                                    <img src={americanExpress} className="rounded img-fluid bg-light mr-2" alt="American Express" title="American Express" width="50"/>
                                    <img src={discover} className="rounded img-fluid bg-light mr-2" alt="Discover" title="Discover" width="50"/>
                                    <img src={unionPay} className="rounded img-fluid bg-light mr-2" alt="UnionPay" title="UnionPay" width="50"/>
                                    <img src={bankAccount} className="rounded img-fluid bg-light mr-2" alt="Bank Account" title="Bank Account" width="50"/>
                                </div>

                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>

                    </div>
                </div>

                <div className="container">

                    <div className="text-center pt-4">
                        <h1 className="py-4">
                            Letus is made for renters, by renters
                        </h1>
                    </div>

                    <div className="row justify-content-center py-5">

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'credit-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Payments
                                </h3>
                                <p className="">
                                    With flexible payment options and recurring schedules, making rent payments is a breeze.
                                </p>
                                <ScrollLink to="payments" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'bell']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Notifications
                                </h3>
                                <p className="">
                                    Receive real-time payment updates and detailed payment receipts, direct to your inbox.
                                </p>
                                <ScrollLink to="notifications" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'home-lg']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Leases
                                </h3>
                                <p className="">
                                    Letus has you covered with support from multiple leases to multiple roommates.
                                </p>
                                <ScrollLink to="leases" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'gift']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Rewards
                                </h3>
                                <p className="">
                                    Take advantage of one of your largest monthly expenses with your favorite rewards card.
                                </p>
                                <ScrollLink to="rewards" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                    </div>

                    <hr className="my-5" id="payments" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h1 className="mb-4">
                                Make paying your rent simple and rewarding
                            </h1>
                            <p className="mb-4 lead">
                                Despise those late fees? With Letus, you'll never miss another payment again with monthly recurring payments, using a bank account or rewards credit card that suits you best.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'credit-card']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Multiple Payment Methods</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'calendar-check']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Setup Scheduled Payments</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={paymentFlow} alt="Letus offers your tenants the ability to make rent payments with their preferred payment method including Visa Credit, Visa Debit, Mastercard, Mastercard Debit, American Express, or directly from their bank account" className="img-sm-fluid" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="notifications" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h1 className="mb-4">
                                Stay in the loop with real time notifications
                            </h1>
                            <p className="mb-4 lead">
                                Never lose track of your payment history again. Reviewing the details of all your past and upcoming rent payments can be done anytime, anywhere from your Letus dashboard.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'bell']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Real-Time Payment Updates</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'receipt']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Detailed Payment Receipts</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={recurringPayment} alt="Receive email notifications from all of your tenants' payments, even if a payment was failed or charged back, all while funds are deposited directly into your bank account" className="img-sm-fluid float-right" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="leases" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h1 className="mb-4">
                                Supported for multiple leases and roommates
                            </h1>
                            <p className="mb-4 lead">
                                Whether you're paying for your son or daughter's college dorm, or splitting payments for your dorm with your roommate, Letus has you covered no matter the scenario.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'stopwatch']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Track Upcoming Payments</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'home-heart']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Review Lease Details</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={transactions} alt="Letus' intuitive payments platform and user interface will save you time and make paying rent easier than ever before" className="img-sm-fluid my-3" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="rewards" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h1 className="mb-4">
                                Maximize your rewards potential and build your credit
                            </h1>
                            <p className="mb-4 lead">
                                Make your rent payments with your favorite rewards credit card and enjoy the cash-back or points redemption from one of your largest monthly payments, all while building your credit.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'mobile-alt']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Make Payments Anytime</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'gift']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Earn Credit Card Rewards</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={creditCards} alt="Making rent payments with Letus allows tenants to build their credit history and earn points from their favorite rewards credit card" className="img-sm-fluid float-right" width="130%"/>
                        </div>
                    </div>

                </div>

                <div className="jumbotron jumbotron-fluid mb-0">
                    <div className="container">

                        <div className="text-center">
                            <h3 className="display-4 py-4">
                                Next steps
                            </h3>
                        </div>

                        <div className="row justify-content-center my-5">

                            <div className="col-md-4 text-center mb-5 mb-md-0">
                                <div className="px-3">
                                    <FontAwesomeIcon icon={['fal', 'envelope-open-text']} className="fa-fw mb-5 text-primary" size="6x"/>
                                    <h3 className="display-5 mb-2">
                                        Receive an invitation
                                    </h3>
                                    <p className="">
                                        Your property manager can invite you to submit a lease application or setup your account.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-4 text-center mb-5 mb-md-0 border-left border-right">
                                <div className="px-3">
                                    <FontAwesomeIcon icon={['fal', 'check-circle']} className="fa-fw mb-5 text-primary" size="6x"/>
                                    <h3 className="display-5 mb-2">
                                        Complete your account
                                    </h3>
                                    <p className="">
                                        Select which property you're applying for, get verified by your property manager, and setup your payment method.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-4 text-center mb-5 mb-md-0">
                                <div className="px-3">
                                    <FontAwesomeIcon icon={['fal', 'credit-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                    <h3 className="display-5 mb-2">
                                        Make your payments
                                    </h3>
                                    <p className="">
                                        Start making one time payments, or never miss a payment again with monthly recurring payments.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${womanChair}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container text-center">
                        <h1 className="pt-4 mb-4 text-white">
                            A better rental experience begins with Letus
                        </h1>
                        <div className="row justify-content-md-center">
                            <div className="col-md-8 text-md-center">
                                <p className="lead text-white">
                                    If your property manager is already signed up with Letus, contact them to receive an invitation today.
                                </p>
                            </div>
                        </div>
                        <a href="mailto:yourlandlord@yoursite.com?subject=I am interested in paying my rent with Propertii&body=Propertii gives property managers the tools they need to create a better rental experience. Check it out at https://let.us!" className="btn btn-lg btn-primary mt-3 mb-4 px-4 text-white">
                            Invite my Property Manager
                        </a>
                    </div>
                </div>

            </div>
        );
    }
}

Tenants.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Tenants);
