import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {

    /**
     * Return the user to the top of the page when a component updates.
     *
     * @param prevProps - The previous properties of the component.
     */
    componentDidUpdate(prevProps) {
        if(this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    };

    /**
     * Render the component.
     *
     * @returns {*} - The children of the component, which is the entire web application.
     */
    render() {
        return this.props.children;
    };
}

export default withRouter(ScrollToTop);