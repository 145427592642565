import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {injectIntl, intlShape} from "react-intl";

class FieldEmails extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.addEmail = this.addEmail.bind(this);
        this.removeEmail = this.removeEmail.bind(this);

        this.handleChangeEmails = this.handleChangeEmails.bind(this);
    }

    /**
     * Add an email to the list of emails.
     */
    addEmail() {

        let emails = this.props.parent.state[this.props.model];

        emails.push({
            address: '',
        });

        this.props.parent.setState(prevState => ({
            ...prevState,
            [this.props.model]: emails
        }));
    }

    /**
     * Remove an email from the list of emails.
     *
     * @param index - The index of the email to remove from the list of emails.
     */
    removeEmail(index) {

        let emails = this.props.parent.state[this.props.model];

        emails.splice(index, 1);

        this.props.parent.setState(prevState => ({
            ...prevState,
            [this.props.model]: emails
        }));
    }

    /**
     * Handle changes to the email list, passing the updated list back to the parent component's state.
     *
     * @param event - The event container.
     */
    handleChangeEmails(event) {

        let emails = this.props.parent.state[this.props.model];

        let emailIdSplit = (event.target.name).split("-");
        let emailIdIndex = emailIdSplit[emailIdSplit.length - 1];

        // Handle address changes
        if(emailIdSplit[1] === 'address') {
            emails[emailIdIndex] = {
                address: event.target.value,
            };
        }

        this.props.parent.setState(prevState => ({
            ...prevState,
            [this.props.model]: emails
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A component that allows the user to enter a list of email addresses, passing the list of emails to
     * the parent component's state.
     */
    render() {

        return(
            <React.Fragment>

                {this.props.emails.map((email, key) => {

                    return(
                        <div key={key} className="form-group row mb-2">
                            <div className="col-sm-12">
                                <div className={`input-group input-group-${this.props.size}`}>

                                    <input id={`email-address-${key}`} name={`email-address-${key}`} value={email.address || ''} type="email" className={`form-control mb-0`} disabled={this.props.disabled} onChange={this.handleChangeEmails} />

                                    {!this.props.disabled &&
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-primary" type="button" onClick={() => this.removeEmail(key)}>
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw"/>
                                        </button>
                                    </div>
                                    }

                                </div>

                            </div>
                        </div>
                    );
                })}

                {!this.props.disabled &&
                <div className={`btn btn-outline-primary btn-sm ${this.props.emails.length > 0 ? 'mt-2' : 'mt-0'}`} onClick={() => this.addEmail()}>
                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw"/> Add email
                </div>
                }

            </React.Fragment>
        )
    };
}

FieldEmails.propTypes = {
    intl: intlShape.isRequired,
};

FieldEmails.defaultProps = {
    size: 'md'
};

export default injectIntl(FieldEmails);
