import React from 'react';
import axios from "axios/index";
import * as constants from '../../util/constants';
import Propertii from "../common/Propertii";
import qs from "qs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Alert from "../common/Alert";
import $ from "jquery";
import logo from '../../media/img/logos/propertii-w.png';
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import GoogleLogin from "react-google-login";
import google from "../../media/img/logos/google.png";

class Splash extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            invitation: {},
            property: {},
            targetCustomerId: '',
            verificationCode: '',
            username: '',
            password: '',
            accessToken: '',
            validationList: [],
        };

        this.initInvitation = this.initInvitation.bind(this);
        this.authorize = this.authorize.bind(this);
        this.authorizeOAuth = this.authorizeOAuth.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
    }

    /**
     * On mounting of the component, fetch the invitation data based on the ID of the invitation provided in the route.
     * If no valid invitation ID is provided, redirect the user to the homepage. Also fetches the property data using
     * the property ID provided in the invitation.
     */
    componentDidMount() {

        // Purge the local storage to avoid overlapping sessions once the flow is completed
        localStorage.removeItem('token_type');
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_in');
        localStorage.removeItem('status');
        localStorage.removeItem('screening');
        localStorage.removeItem('broadcast_messages');

        // Refresh the overall app state to reflect the localStorage purge
        this.props.refreshAppState();

        const urlParams = qs.parse(this.props.location.search.slice(1));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/invitation/${this.props.match.params.invitationId}/peek`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                invitation: response.data,
                targetCustomerId: urlParams.targetCustomerId,
                verificationCode: urlParams.verificationCode
            }));

            axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${response.data.propertyId}/peek`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    property: response.data
                }));

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize the invitation flow when the user clicks the call to action on the invitation splash page. Redirects
     * the user to the appropriate component.
     */
    initInvitation() {

        this.props.history.push({
            pathname: `/invitation/${this.props.match.params.invitationId}/customer`,
            state: {
                verificationCode: this.state.verificationCode,
                accessToken: this.state.accessToken,
            }
        });
    }

    /**
     * Handle traditional authorization using a username and password.
     *
     * @param event - The event container.
     */
    authorize(event) {

        event.preventDefault();

        axios.get(`${constants.REACT_APP_HOST_API_URL}/authorize`, {
            auth: {
                username: this.state.username,
                password: this.state.password
            }
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                accessToken: response.data.access_token,
            }));

            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);

            this.props.refreshAppState();

            $("#login").modal("hide");

            this.initInvitation();

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle authorization via OAuth.
     *
     * @param response - The returned OAuth response.
     */
    authorizeOAuth(response) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/authorizeoauth?accountType=TYPE_GOOGLE_OAUTH_ACCOUNT&authorizationCode=${encodeURIComponent(response.code)}&requestUri=${encodeURIComponent(window.location.origin)}`).then(response => {

            this.setState(prevState => ({
                ...prevState,
                accessToken: response.data.access_token,
            }));

            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);

            this.props.refreshAppState();

            $("#login").modal("hide");

            this.initInvitation();

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle change events on fields.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state.
     *
     * @param error - The server error response.
     */
    handleValidation(error) {

        let fields = {};

        if(error.response.data.fieldErrors) {
            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );
        }

        // Handle account status validation errors
        const accountStatus = error.response.data.accountStatus;

        // Display login-specific errors if the error code returned is the generic system account status error
        if(error.response.data.errorCode === 'RM_ERROR_SYSTEM_ACCOUNT_STATUS') {
            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        message: error.response.data.message,
                        code: 'login.' + error.response.data.errorCode + (accountStatus ? ('.' + accountStatus) : '')
                    },
                    fields
                }],
            });
            return;
        }

        this.setState({
            validationList: [{
                alert: {
                    type: 'danger',
                    message: error.response.data.message,
                    code: error.response.data.errorCode
                },
                fields
            }],
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A message telling the user that their email verification was successful and that they may now
     * log in.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <div className="content-block">

                <div className="content-header mb-0">
                    <div className="container">

                        <div className="row no-gutters">
                            <div className="col-md-6 col-12 align-self-center pr-md-5">

                                {this.state.targetCustomerId === '' &&
                                <div className="jumbotron jumbotron-fluid bg-transparent mb-0">

                                    <h1 className="display-5 mb-2">
                                        Welcome, {this.state.invitation.firstName}!
                                    </h1>

                                    <h3 className="mb-4">
                                        Let's create your Letus account
                                    </h3>

                                    {this.state.property &&
                                    <p className="mb-4">
                                        Your landlord or property manager requests that you pay your rent for <span className="font-weight-bold">{this.state.invitation.unit} - {this.state.property.street1}</span> online securely with Letus.
                                    </p>
                                    }

                                    <p className="mb-4">
                                        Letus simplifies rent payments through it's secure online platform. No more post-dated checks! Enjoy the convenience of choosing the payment method that works best for you.
                                    </p>

                                    <button className="btn btn-lg btn-block btn-primary" onClick={() => this.initInvitation()}>
                                        Create my Letus Account
                                    </button>

                                </div>
                                }

                                {this.state.targetCustomerId !== '' &&
                                <div className="jumbotron jumbotron-fluid bg-transparent mb-0">

                                    <h1 className="display-5 mb-2">
                                        Welcome, {this.state.invitation.firstName}!
                                    </h1>

                                    <h3 className="mb-4">
                                        You have a new lease invitation
                                    </h3>

                                    {this.state.property &&
                                    <p className="">
                                        Your property manager requests that you pay your rent for <span className="font-weight-bold">{this.state.invitation.unit} - {this.state.property.street1}</span> online securely with Letus.
                                    </p>
                                    }

                                    <p className="mb-4">
                                        Since you already have a Letus account, simply click the button below to log in and accept this invitation.
                                    </p>

                                    <div data-toggle="modal" data-target="#login" className="btn btn-lg btn-block btn-primary">
                                        Accept Invitation
                                    </div>

                                </div>
                                }

                            </div>
                            <div className="col-md-6 d-none d-md-block align-self-center">

                                <div style={{background: "linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('https://dheiziex291vk.cloudfront.net/static/backgrounds/login.jpg') no-repeat center center scroll", backgroundSize: "cover", height: "675px", width: "998px"}}>

                                </div>

                            </div>

                        </div>

                        <div className="modal fade" id="login" tabIndex="-1" role="dialog" aria-labelledby="login-label" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                                <div className="modal-content shadow">
                                    <form onSubmit={this.authorize}>
                                        <div className={`modal-header text-center d-block text-white py-5 px-4 bg-dark`}>
                                            <div className="">
                                                <img src={logo} className="img-fluid w-50" alt="Letus" />
                                            </div>
                                        </div>
                                        <div className="modal-body bg-secondary">

                                            <Alert validationList={this.state.validationList} validationType="danger" customClasses="mb-3" />

                                            <p className="mb-0">
                                                Enter the log in credentials of your Letus account below to accept this invitation.
                                            </p>

                                        </div>
                                        <div className="modal-body">

                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <div className="input-group input-group-lg">
                                                        <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <FontAwesomeIcon icon={['fas', 'user']} />
                                                        </span>
                                                        </div>
                                                        <input name="username" type="email" placeholder={formatMessage({ id: "field.email"})} value={this.state['username']} onChange={this.handleChange} className={`form-control form-control-lg mb-0 rounded-right ${this.state.validationList[0] ? (this.state.validationList[0].fields['username'] ? 'is-invalid' : '') : ''}`} />
                                                        {this.state.validationList[0] &&
                                                        <div className="invalid-feedback">
                                                            <FormattedMessage id={"danger." + this.state.validationList[0].fields['username']} />
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <div className="input-group input-group-lg">
                                                        <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <FontAwesomeIcon icon={['fas', 'key']} />
                                                        </span>
                                                        </div>
                                                        <input name="password" type="password" placeholder={formatMessage({ id: "field.password"})} value={this.state['password']} onChange={this.handleChange} className={`form-control form-control-lg mb-0 rounded-right ${this.state.validationList[0] ? (this.state.validationList[0].fields['password'] ? 'is-invalid' : '') : ''}`} />
                                                        {this.state.validationList[0] &&
                                                        <div className="invalid-feedback">
                                                            <FormattedMessage id={"danger." + this.state.validationList[0].fields['password']} />
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col text-right">
                                                    <button type="submit" className="btn btn-lg btn-primary btn-block mb-0">
                                                        <FormattedMessage id="button.login" />
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="divider">
                                                <span className="small text-muted font-italic text-uppercase">or</span>
                                            </div>

                                            <div className="row">
                                                <div className="col text-center">
                                                    <GoogleLogin
                                                        clientId={constants.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                                                        render={renderProps => (
                                                            <div className="btn btn-md btn-block btn-outline-primary btn-google" onClick={renderProps.onClick}>
                                                                <img src={google} alt="Log In with Google" style={{height: '15px'}} className="m-1" /> Log In with Google
                                                            </div>
                                                        )}
                                                        onSuccess={this.authorizeOAuth}
                                                        onFailure={this.authorizeOAuth}
                                                        responseType="code"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }

}

Splash.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Splash);
