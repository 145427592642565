import React from 'react';
import Propertii from "../../common/Propertii";
import PaymentMethodList from "../../common/PaymentMethods";
import {injectIntl, intlShape} from "react-intl";

class PaymentMethods extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The view customer payment methods` component for admins.
     */
    render() {

        return(
            <React.Fragment>

                <PaymentMethodList userType="TYPE_COMPANY_OWNER" companyId={this.props.match.params.companyId} createMessage="admin.companies.methods.created" deleteMessage="admin.companies.methods.deleted" />

            </React.Fragment>
        )
    };
}

PaymentMethods.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(PaymentMethods);