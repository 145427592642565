import React from 'react';
import Propertii from "../../common/Propertii";
import TransactionList from "../../common/Transactions";
import Breadcrumb from "../../common/Breadcrumb";

class Transactions extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The manager transactions component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Transactions" />

                    <TransactionList makePayment={true}
                                     history={this.props.history}
                                     makePaymentPath="/manager/transactions/payment"
                                     userType="TYPE_MANAGER" />

                </div>

            </div>
        )
    };
}

export default Transactions;