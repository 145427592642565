import React, { Component } from 'react';
import FieldSelect from "../common/FieldSelect";

class FieldLocale extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A select field component, specialized for locales.
     */
    render() {

        return(
            <FieldSelect id={this.props.id} label={this.props.label} labelClass={this.props.labelClass} fieldClass={this.props.fieldClass}
                         fieldColumns={this.props.fieldColumns} labelColumns={this.props.labelColumns} handleChange={this.props.handleChange}
                         disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value}
                         required={this.props.required}>

                <option value="">Select a locale...</option>

                <option value="en-CA">English (Canada)</option>
                <option value="en-US">English (US)</option>
                <option value="fr-CA">French (Canada)</option>

            </FieldSelect>
        )
    };
}

FieldLocale.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    disabled: false
};

export default FieldLocale;
