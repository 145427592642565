import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Pagination from "react-js-pagination";

class Table extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.handleSort = this.handleSort.bind(this);
        this.handleRecordsPerPage = this.handleRecordsPerPage.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
    }

    /**
     * Handle column sorting events when a table header cell is clicked on. Performs a simple check to alternate between
     * ascending and descending column orderings. Makes a call to the parent component table update function, passing it
     * the current page, records per page, sort order (ascending or descending), and which field to order by.
     *
     * @param orderByFields - The column/field to sort by.
     */
    handleSort(orderByFields) {

        if(!this.props.sortEnabled) {
            return;
        }

        let query = {
            orderBy: '',
            orderByFields: [orderByFields],
            conditionList: this.props.query ? this.props.query.conditionList : [],
            joins: this.props.query ? this.props.query.joins : {}
        };

        if(this.props.query && this.props.query.orderBy === "ASC") {
            query.orderBy = "DESC";
        }

        if(this.props.query && this.props.query.orderBy === "DESC") {
            query.orderBy = "ASC";
        }

        this.props.updateFunction(this.props.data.page, this.props.data.recordsPerPage, query);
    }

    /**
     * Handle change events on the records per page select. For convenience, the page is set to 1 each time the records
     * per page is updated. Makes a call to the parent component table update function, passing it the value of the new
     * records per page field.
     *
     * @param event - The event container.
     */
    handleRecordsPerPage(event) {

        let query = {
            orderBy: this.props.query ? this.props.query.orderBy : 'ASC',
            orderByFields: this.props.query ? this.props.query.orderByFields : [],
            conditionList: this.props.query ? this.props.query.conditionList : [],
            joins: this.props.query ? this.props.query.joins : {},
            ...this.props.filters,
        };

        this.props.updateFunction('1', event.target.value, query);
    }

    /**
     * Handle pagination changes on the table. Makes a call to the parent component table update function, passing it
     * the value of the new page.
     *
     * @param page - The selected page.
     */
    handlePagination(page) {

        let query = {
            orderBy: this.props.query ? this.props.query.orderBy : 'ASC',
            orderByFields: this.props.query ? this.props.query.orderByFields : [],
            conditionList: this.props.query ? this.props.query.conditionList : [],
            joins: this.props.query ? this.props.query.joins : {},
            ...this.props.filters,
        };

        this.props.updateFunction(page, this.props.data.recordsPerPage, query);
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic table component.
     */
    render() {

        let recordsOptions = this.props.recordsOptions.map(function(value) {
            return (
                <option key={value} value={value}>Show {value} records per page</option>
            );
        });

        let recordCountDisplayFrom = (this.props.data.page - 1) * this.props.data.recordsPerPage === 0 ? 1 : ((this.props.data.page - 1) * this.props.data.recordsPerPage) + 1;
        let recordCountDisplayTo = this.props.data.page * this.props.data.recordsPerPage > this.props.data.totalRecordCount ? this.props.data.totalRecordCount : this.props.data.page * this.props.data.recordsPerPage;
        let recordCountDisplayOf = this.props.data.totalRecordCount;

        return(
            <React.Fragment>

                <div className={`card-body ${this.props.data.totalRecordCount === 0 ? '' : 'card-body-table'}`}>

                    {this.props.data.totalRecordCount === 0 &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <div className="text-center text-secondary">
                                <FontAwesomeIcon icon={['fas', 'ghost']} className="fa-fw mb-4" size="5x" />
                            </div>
                            <div className="text-center text-muted">
                                <small>
                                    We were unable to find any record(s) matching your search criteria.
                                </small>
                            </div>
                        </div>
                    </div>
                    }

                    {this.props.data.totalRecordCount > 0 &&
                    <table className={`table ${this.props.tableClass}`}>
                        <thead>
                        <tr>
                            {Object.keys(this.props.columns).map((key, index) => (
                                <th key={key} scope="col" className={this.props.headerClass}
                                    width={this.props.columnWidths ? this.props.columnWidths[index] : ''}
                                    style={this.props.minColumnWidths ? {minWidth: this.props.minColumnWidths[index]} : {}}
                                    onClick={() => this.handleSort(key)}>
                                    <div className="float-left">
                                        {this.props.columns[key]}
                                    </div>
                                    {this.props.sortEnabled &&
                                    <div className="float-right">

                                        {this.props.query.orderBy === 'ASC' && this.props.query.orderByFields[0] === key &&
                                        <FontAwesomeIcon icon={['fas', 'sort-up']} className="va-b"/>
                                        }

                                        {this.props.query.orderBy === 'DESC' && this.props.query.orderByFields[0] === key &&
                                        <FontAwesomeIcon icon={['fas', 'sort-down']} className="va-b"/>
                                        }

                                        {this.props.query.orderByFields[0] !== key &&
                                        <FontAwesomeIcon icon={['fas', 'sort']} className="va-b"/>
                                        }

                                    </div>
                                    }
                                </th>
                            ))}
                        </tr>
                        </thead>

                        {this.props.children}

                    </table>
                    }

                </div>

                {!this.props.hideFooter &&
                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-4 col-6 small">
                            {this.props.recordsEnabled &&
                            <div className="form-group w-75">
                                <select className="form-control form-control-sm mb-0"
                                        onChange={this.handleRecordsPerPage}>
                                    {recordsOptions}
                                </select>
                            </div>
                            }
                        </div>
                        <div className="col d-none d-md-block align-self-center text-center">
                            <div className="small text-muted text-uppercase">
                                Showing {recordCountDisplayFrom} to {recordCountDisplayTo} of {recordCountDisplayOf}
                            </div>
                        </div>
                        <div className="col">
                            <div className="float-right">
                                <Pagination
                                    firstPageText={<FontAwesomeIcon icon={['fas', 'angle-double-left']} className="va-b"/>}
                                    prevPageText={<FontAwesomeIcon icon={['fas', 'angle-left']} className="va-b"/>}
                                    nextPageText={<FontAwesomeIcon icon={['fas', 'angle-right']} className="va-b"/>}
                                    lastPageText={<FontAwesomeIcon icon={['fas', 'angle-double-right']} className="va-b"/>}
                                    innerClass={"pagination pagination-sm mb-0"}
                                    itemClass={"page-item"}
                                    linkClass={"page-link"}
                                    activePage={this.props.data.page}
                                    itemsCountPerPage={this.props.data.recordsPerPage}
                                    totalItemsCount={this.props.data.totalRecordCount}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePagination}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                }

            </React.Fragment>
        )
    };
}

Table.defaultProps = {
    tableClass: 'table-bordered table-hover table-responsive-sm',
    recordsOptions: ['25', '50', '100'],
};

export default Table;