import React from 'react';
import Table from "../common/Table";
import axios from "axios";
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import Propertii from "../common/Propertii";
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import FieldText from "../common/FieldText";
import Spinner from "./Spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FieldAddress from "./FieldAddress";
import $ from "jquery";
import FieldCheckbox from "./FieldCheckbox";
import FieldSelect from "./FieldSelect";

class Properties extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        let conditionList = [];

        if(this.props.userType === 'TYPE_ADMIN' && this.props.companyId) {
            conditionList = [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            ]
        }

        this.state = {

            propertySearchFilter: '',
            propertyStreet: '',

            company: {},

            customMerchantAccounts: false,
            creditMerchantAccountList: {},

            integrationId: '',

            integrationSearch: {
                propertyId: '',
                propertyName: '',
                street: ''
            },

            integrationPropertyList: [],

            property: {},

            propertyList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            propertyQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: conditionList,
                joins: {
                    company: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'companyId',
                        alias: 'company',
                        returnFields: ['name']
                    }
                }
            },

            validationList: [],

        };

        this.searchProperties = this.searchProperties.bind(this);
        this.filterProperties = this.filterProperties.bind(this);
        this.clearFilters = this.clearFilters.bind(this);

        this.editProperty = this.editProperty.bind(this);
        this.redirectProperty = this.redirectProperty.bind(this);
        this.initProperty = this.initProperty.bind(this);
        this.saveProperty = this.saveProperty.bind(this);

        this.initIntegrationProperties = this.initIntegrationProperties.bind(this);
        this.searchIntegrationProperties = this.searchIntegrationProperties.bind(this);
        this.selectIntegrationProperty = this.selectIntegrationProperty.bind(this);

        this.handleChangePropertyIdentifier = this.handleChangePropertyIdentifier.bind(this);
        this.handleChangeCreditMerchantAccount = this.handleChangeCreditMerchantAccount.bind(this);
    }

    /**
     * Populate the list of properties on mounting of the component.
     */
    componentDidMount() {

        if(this.props.companyId != null) {

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.companyId}/fallbackfields`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    integrationId: response.data.integrationId,
                }));

            }).catch(error => {
                console.error(error);
            });

        }

        this.searchProperties(1, 25, this.state.propertyQuery);

        if(this.props.history.location.state && this.props.history.location.state.property) {
            this.editProperty(this.props.history.location.state.property.id);
        }
    }

    /**
     * View a property by redirecting the user to a separate property component.
     *
     * @param propertyId - The ID of the property selected.
     */
    redirectProperty(propertyId) {

        this.props.history.push(`/${this.props.userType.substring(5).toLowerCase()}/properties/${propertyId}/edit`);
    }

    /**
     * Edit a property record. Fetch the property data using the ID selected, then load the appropriate merchant account
     * mapping data.
     *
     * @param propertyId - The ID of the property selected.
     */
    editProperty(propertyId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${propertyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let companyId = this.props.companyId;
            let customMerchantAccounts = true;

            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MASTERCARD');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'AMERICAN_EXPRESS');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DISCOVER');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'UNION_PAY');
            this.getMerchantAccounts(companyId,'TYPE_BANK_ACCOUNT');
            this.getMerchantAccounts(companyId,'TYPE_CASH');
            this.getMerchantAccounts(companyId,null);

            if(response.data.bankMerchantAccountId === null
                && response.data.padMerchantAccountId === null
                && response.data.cashMerchantAccountId === null
                && Object.keys(response.data.creditMerchantAccountList).length === 0) {
                customMerchantAccounts = false;
            }

            this.setState(prevState => ({
                ...prevState,
                property: {
                    ...response.data
                },
                propertyStreet: response.data.street1,
                creditMerchantAccountList: response.data.creditMerchantAccountList,
                customMerchantAccounts: customMerchantAccounts
            }));

            $('#property').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of a property when the user indicates they want to create a new property.
     */
    initProperty() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let companyId = this.props.companyId;

            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA_ELECTRON');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MASTERCARD');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MAESTRO');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'AMERICAN_EXPRESS');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DISCOVER');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DINERS_CLUB');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'JCB');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'UNION_PAY');
            this.getMerchantAccounts(companyId,'TYPE_BANK_ACCOUNT');
            this.getMerchantAccounts(companyId,'TYPE_CASH');
            this.getMerchantAccounts(companyId,null);

            this.setState(prevState => ({
                ...prevState,
                property: {
                    ...response.data,
                    companyId: companyId,
                }
            }));

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle saving an existing property or creating a new property.
     *
     * @param event - The event container.
     */
    saveProperty(event) {

        event.preventDefault();

        let property = this.state.property;

        property.creditMerchantAccountList = this.state.creditMerchantAccountList;

        Object.entries(property.creditMerchantAccountList).forEach(([key, value]) => {
            if(value === '' || !this.state.customMerchantAccounts) {
                delete property.creditMerchantAccountList[key];
            }
        });

        if(!this.state.customMerchantAccounts) {
            property.bankMerchantAccountId = null;
            property.cashMerchantAccountId = null;
            property.padMerchantAccountId = null;
        }

        if(this.state.property.createDate == null) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, property, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: this.props.createMessage
                        }
                    }],
                });

                this.searchProperties(this.state.propertyList.page, this.state.propertyList.recordsPerPage, this.state.propertyQuery);

                $('#property').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, property, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved'
                        }
                    }],
                });

                this.searchProperties(this.state.propertyList.page, this.state.propertyList.recordsPerPage, this.state.propertyQuery);

                $('#property').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Handle submitting the search filter field by adjusting the email search query and initiating a new search.
     *
     * @param event - The event container.
     */
    filterProperties(event) {

        if(event != null) {
            event.preventDefault();
        }

        this.setState({
            spinner: true
        });

        let propertyQuery = this.state.propertyQuery;

        propertyQuery.conditionList = [];

        if(this.props.userType === 'TYPE_ADMIN' && this.props.companyId) {
            propertyQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                },
            )
        }

        if(this.state.propertySearchFilter !== '') {
            propertyQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.state.propertySearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'propertyName,street1,city,postalCode,propertyIdentifier',
                    operator: 'MATCH',
                    fieldValue: this.state.propertySearchFilter
                }
            );
        }

        this.setState({
            propertyQuery: propertyQuery
        });

        this.searchProperties(1, 25, propertyQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter query.
     */
    clearFilters() {

        this.setState({
            propertySearchFilter: '',
        }, () => {

            this.filterProperties();

        });
    }

    /**
     * Update the data table of properties.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchProperties(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                propertyList: {
                    ...response.data,
                    emptyUnfilteredList: this.state.propertySearchFilter === '' && response.data.records.length === 0
                },
                propertyQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Initialize the search integration properties process by clearing out the search fields and revealing the search
     * integration properties modal.
     */
    initIntegrationProperties() {

        this.setState(prevState => ({
            ...prevState,
            integrationPropertyList: [],
            integrationSearch: {
                propertyId: '',
                propertyName: '',
                street: ''
            }
        }));

        $('#property').modal('hide');
        $('#search-property').modal('show');
    }

    /**
     * Perform a search for properties in the integrated system for selection.
     */
    searchIntegrationProperties(event) {

        event.preventDefault();

        this.setState({
            spinner: true,
            integrationPropertyList: [],
            validationList: [{
                fields: {},
                alert: {
                    type: '',
                    code: ''
                }
            }]
        });

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/property/search`, {
            integrationId: this.state.integrationId,
            propertyId: this.state.integrationSearch.propertyId,
            propertyName: this.state.integrationSearch.propertyName,
            street: this.state.integrationSearch.street,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    integrationPropertyList: response.data
                }));
            }

            if(response.data.length === 0) {
                this.setState({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'common.properties.integration.null'
                        }
                    }]
                });
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Select a property from the integrated system. Using the data from the integrated system, the fields used to
     * create a property will be pre-filled.
     *
     * @param data - The property that has been selected from the integrated system.
     */
    selectIntegrationProperty(data) {

        this.setState(prevState => ({
            ...prevState,
            property: {
                ...prevState.property,
                city: data.city,
                country: data.country,
                propertyIdentifier: data.externalId,
                propertyName: data.name,
                postalCode: data.postalCode,
                province: data.province,
                street1: data.street1,
                street2: data.street2,
            },
        }));

        $('#search-property').modal('hide');
        $('#property').modal('show');
    }

    /**
     * Handle changes to the account number field.
     *
     * @param event - The event container.
     */
    handleChangePropertyIdentifier(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            property: {
                ...prevState.property,
                propertyIdentifier: event.target.value
            },
        }));
    }

    /**
     * Handle changes to the default credit/debit merchant account field. By selecting a merchant account from this
     * field, the same merchant account will be applies to all credit/debit card brands available.
     *
     * @param event - The event container.
     */
    handleChangeCreditMerchantAccount(event) {

        event.preventDefault();

        let creditMerchantAccountList = this.state.creditMerchantAccountList;
        let creditMerchantAccountId = event.target.value;

        creditMerchantAccountList['VISA'] = creditMerchantAccountId;
        creditMerchantAccountList['VISA_ELECTRON'] = creditMerchantAccountId;
        creditMerchantAccountList['MASTERCARD'] = creditMerchantAccountId;
        creditMerchantAccountList['MAESTRO'] = creditMerchantAccountId;
        creditMerchantAccountList['AMERICAN_EXPRESS'] = creditMerchantAccountId;
        creditMerchantAccountList['DISCOVER'] = creditMerchantAccountId;
        creditMerchantAccountList['DINERS_CLUB'] = creditMerchantAccountId;
        creditMerchantAccountList['JCB'] = creditMerchantAccountId;

        this.setState(prevState => ({
            ...prevState,
            creditMerchantAccountList: creditMerchantAccountList,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The landlord property list component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        let columnLabels = {};
        let columnWidths = [];

        if(this.props.hideRegion) {

            columnLabels = {propertyName: 'Property', street1: 'Street', city: 'City', postalCode: 'ZIP/Postal Code'};
            columnWidths = ['25%', '25%', '25%', '25%'];

        } else {

            if(this.props.userType === 'TYPE_ADMIN' && !this.props.companyId) {

                columnLabels = {propertyName: 'Property', street1: 'Street', city: 'City', province: 'State/Province', 'company.name': 'Company', postalCode: 'ZIP/Postal Code'};
                columnWidths = ['14%', '14%', '14%', '14%', '14%', '14%'];

            } else {

                columnLabels = {propertyName: 'Property', street1: 'Street', city: 'City', province: 'State/Province', country: 'Country', postalCode: 'ZIP/Postal Code'};
                columnWidths = ['14%', '14%', '14%', '14%', '14%', '14%'];

            }

        }

        return(
            <div className="">

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} validationType="primary" />

                {this.props.history.location.state &&
                <Alert validationList={this.props.history.location.state.validationList} validationType="primary"/>
                }

                <div className="row">
                    <div className="col-12">

                        <div className="card">

                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        Properties
                                    </div>
                                    {this.props.companyId &&
                                    <div className="col text-right">
                                        <div data-toggle="modal" data-target="#property" className="btn btn-primary btn-sm" onClick={() => this.initProperty()}>
                                            <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Property
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>

                            {this.state.propertyList.emptyUnfilteredList &&
                            <div className="card-body bg-secondary py-5">
                                <div className="row justify-content-center">
                                    <div className="col-10">
                                        <div className="text-center text-muted">
                                            <div className="fa-stack fa-2x mb-2">
                                                <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                <FontAwesomeIcon icon={['far', 'building']} className="fa-stack-1x fa-inverse" />
                                            </div>
                                        </div>
                                        <div className="text-center text-muted">
                                            <small>
                                                Properties you add will appear here.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {!this.state.propertyList.emptyUnfilteredList &&
                            <div className="card-header gotham border-top py-3 bg-secondary">
                                <form onSubmit={this.filterProperties}>
                                    <div className="media">
                                        <div className="media-body align-self-center mr-3">
                                            <FieldText id="propertySearchFilter" label="Search" labelClass="d-none"
                                                       fieldColumns="12" labelColums="0" placeholder="Filter by building name, street, city, or ZIP/postal code..."
                                                       parent={this} value={this.state.propertySearchFilter}/>
                                        </div>
                                        <div className="align-self-center text-right">
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                    <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                                </button>
                                                <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                    <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            }

                            {!this.state.propertyList.emptyUnfilteredList &&
                            <Table columns={columnLabels}
                                   columnWidths={columnWidths}
                                   headerClass="c-pointer"
                                   data={this.state.propertyList}
                                   query={this.state.propertyQuery}
                                   sortEnabled={true}
                                   recordsEnabled={true}
                                   updateFunction={this.searchProperties}>
                                <tbody>
                                {this.state.propertyList.records.map((data, key) => {
                                    return (
                                        <tr key={key} onClick={() => this.redirectProperty(data.id)} className="c-pointer">
                                            <td>
                                                <div className="">
                                                    {data.propertyName}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="">
                                                    {data.street1}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="">
                                                    {data.city}
                                                </div>
                                            </td>
                                            {!this.props.hideRegion &&
                                            <React.Fragment>

                                                <td>
                                                    <div className="">
                                                        {data.country === 'CA' &&
                                                        <FormattedMessage id={"province." + data.province}/>
                                                        }
                                                        {data.country === 'US' &&
                                                        <FormattedMessage id={"state." + data.province}/>
                                                        }
                                                    </div>
                                                </td>

                                                {(this.props.userType !== 'TYPE_ADMIN' || this.props.companyId) &&
                                                <td>
                                                    <div className="">
                                                        <FormattedMessage id={"country." + data.country}/>
                                                    </div>
                                                </td>
                                                }

                                                {(this.props.userType === 'TYPE_ADMIN' && !this.props.companyId) &&
                                                <td>
                                                    {data.joins &&
                                                    <div className="">
                                                        {data.joins.company.name}
                                                    </div>
                                                    }
                                                </td>
                                                }

                                            </React.Fragment>
                                            }
                                            <td>
                                                <div className="">
                                                    {data.postalCode}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                            }

                        </div>

                        <div className="modal fade" id="property" tabIndex="-1" role="dialog" aria-labelledby="property-label" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                <div className="modal-content">
                                    <form onSubmit={this.saveProperty}>
                                        <div className="modal-header bg-dark text-white">
                                            <h5 className="modal-title" id="property-label">
                                                {this.state.property['createDate'] == null &&
                                                <div className="">
                                                    Create Property
                                                </div>
                                                }
                                                {this.state.property['createDate'] != null &&
                                                <div className="">
                                                    {this.state.propertyStreet}
                                                </div>
                                                }
                                            </h5>
                                            <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                                <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                            </button>
                                        </div>
                                        <div className="modal-body">

                                            <Alert validationList={this.state.validationList} validationType="danger" />

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label col-form-label-sm" htmlFor="propertyIdentifier">
                                                    Property ID
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-sm">

                                                        <input id="propertyIdentifier" name="propertyIdentifier" value={this.state.property['propertyIdentifier'] || ""} className="form-control mb-0" onChange={this.handleChangePropertyIdentifier}/>

                                                        {this.state.integrationId &&
                                                        <div className="input-group-append">
                                                            <div className="btn btn-secondary" onClick={() => this.initIntegrationProperties()}>
                                                                Search...
                                                            </div>
                                                        </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <FieldText id="propertyName" label="Property Name" model="property" parent={this} value={this.state.property['propertyName']}  />

                                            <FieldAddress required={true} model="property" parent={this} value={this.state.property} />

                                            <FieldText id="units" label="Total Units" model="property" type="number" min="0" step="1" parent={this} value={this.state.property['units']} />

                                            {this.props.merchantAccounts &&
                                            <FieldCheckbox id="customMerchantAccounts" label="Merchant Accounts" fieldLabel="This property utilizes custom merchant accounts" parent={this} value={this.state.customMerchantAccounts}/>
                                            }

                                            {this.state.customMerchantAccounts &&
                                            <div className="">

                                                {this.state.property.creditMerchantAccountList &&
                                                <React.Fragment>

                                                    <FieldSelect id="VISA" label="Credit/Debit Card" model="creditMerchantAccountList" parent={this} help="Applies to Visa, Visa Electron, Mastercard, Maestro, American Express, Discover, Diners Club, and JCB cards." value={this.state.creditMerchantAccountList['VISA']} handleChange={this.handleChangeCreditMerchantAccount}>
                                                        {this.state.visaMerchantAccounts &&
                                                        <React.Fragment>
                                                            {this.state.visaMerchantAccounts.map((data, key) => {
                                                                if(data.id === data.joins.c.creditMerchantAccountList['VISA']) {
                                                                    return (
                                                                        <option key={key} value="">
                                                                            {this.props.userType === 'TYPE_ADMIN' ? `[DEFAULT] ${data.accountNumber ? data.accountNumber + ' — ' : ''}${data.name ? data.name + ' — ' : ''}${data.last4 ? '*****' + data.last4 + ' — ' : ''}${formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}` : `[DEFAULT] Bank account ending in *****${data.last4}`}
                                                                        </option>);
                                                                } else {
                                                                    return (
                                                                        <option key={key} value={data.id}>
                                                                            {this.props.userType === 'TYPE_ADMIN' ? `${data.accountNumber ? data.accountNumber + ' — ' : ''}${data.name ? data.name + ' — ' : ''}${data.last4 ? '*****' + data.last4 + ' — ' : ''}${formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}` : `Bank account ending in *****${data.last4}`}
                                                                        </option>);
                                                                }
                                                            })}
                                                            <option value="">-</option>
                                                        </React.Fragment>
                                                        }
                                                    </FieldSelect>

                                                    <FieldSelect id="UNION_PAY" label="UnionPay" model="creditMerchantAccountList" parent={this} value={this.state.creditMerchantAccountList['UNION_PAY']}>
                                                        {this.state.unionPayMerchantAccounts &&
                                                        <React.Fragment>
                                                            {this.state.unionPayMerchantAccounts.map((data, key) => {
                                                                if(data.id === data.joins.c.creditMerchantAccountList['UNION_PAY']) {
                                                                    return (
                                                                        <option key={key} value="">
                                                                            {this.props.userType === 'TYPE_ADMIN' ? `[DEFAULT] ${data.accountNumber ? data.accountNumber + ' — ' : ''}${data.name ? data.name + ' — ' : ''}${data.last4 ? '*****' + data.last4 + ' — ' : ''}${formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}` : `[DEFAULT] Bank account ending in *****${data.last4}`}
                                                                        </option>);
                                                                } else {
                                                                    return (
                                                                        <option key={key} value={data.id}>
                                                                            {this.props.userType === 'TYPE_ADMIN' ? `${data.accountNumber ? data.accountNumber + ' — ' : ''}${data.name ? data.name + ' — ' : ''}${data.last4 ? '*****' + data.last4 + ' — ' : ''}${formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}` : `Bank account ending in *****${data.last4}`}
                                                                        </option>);
                                                                }
                                                            })}
                                                            <option value="">-</option>
                                                        </React.Fragment>
                                                        }
                                                    </FieldSelect>

                                                </React.Fragment>
                                                }

                                                <FieldSelect id="bankMerchantAccountId" label="Bank Account" model="property" parent={this} value={this.state.property['bankMerchantAccountId']}>
                                                    {this.state.bankMerchantAccounts &&
                                                    <React.Fragment>
                                                        {this.state.bankMerchantAccounts.map((data, key) => {
                                                            if(data.id === data.joins.c.bankMerchantAccountId) {
                                                                return (
                                                                    <option key={key} value="">
                                                                        {this.props.userType === 'TYPE_ADMIN' ? `[DEFAULT] ${data.accountNumber ? data.accountNumber + ' — ' : ''}${data.name ? data.name + ' — ' : ''}${data.last4 ? '*****' + data.last4 + ' — ' : ''}${formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}` : `[DEFAULT] Bank account ending in *****${data.last4}`}
                                                                    </option>);
                                                            } else {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {this.props.userType === 'TYPE_ADMIN' ? `${data.accountNumber ? data.accountNumber + ' — ' : ''}${data.name ? data.name + ' — ' : ''}${data.last4 ? '*****' + data.last4 + ' — ' : ''}${formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}` : `Bank account ending in *****${data.last4}`}
                                                                    </option>);
                                                            }
                                                        })}
                                                        <option value="">-</option>
                                                    </React.Fragment>
                                                    }
                                                </FieldSelect>

                                                <FieldSelect id="cashMerchantAccountId" label="Cash" model="property" parent={this} value={this.state.property['cashMerchantAccountId']}>
                                                    {this.state.cashMerchantAccounts &&
                                                    <React.Fragment>
                                                        {this.state.cashMerchantAccounts.map((data, key) => {
                                                            if(data.id === data.joins.c.cashMerchantAccountId) {
                                                                return (
                                                                    <option key={key} value="">
                                                                        {this.props.userType === 'TYPE_ADMIN' ? `[DEFAULT] ${data.accountNumber ? data.accountNumber + ' — ' : ''}${data.name ? data.name + ' — ' : ''}${data.last4 ? '*****' + data.last4 + ' — ' : ''}${formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}` : `[DEFAULT] Bank account ending in *****${data.last4}`}
                                                                    </option>);
                                                            } else {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {this.props.userType === 'TYPE_ADMIN' ? `${data.accountNumber ? data.accountNumber + ' — ' : ''}${data.name ? data.name + ' — ' : ''}${data.last4 ? '*****' + data.last4 + ' — ' : ''}${formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}` : `Bank account ending in *****${data.last4}`}
                                                                    </option>);
                                                            }
                                                        })}
                                                        <option value="">-</option>
                                                    </React.Fragment>
                                                    }
                                                </FieldSelect>

                                                <FieldSelect id="padMerchantAccountId" label="Pre-Authorized Debit" model="property" parent={this} value={this.state.property['padMerchantAccountId']}>
                                                    {this.state.padMerchantAccounts &&
                                                    <React.Fragment>
                                                        {this.state.padMerchantAccounts.map((data, key) => {
                                                            if(data.id === data.joins.c.padMerchantAccountId) {
                                                                return (
                                                                    <option key={key} value="">
                                                                        {this.props.userType === 'TYPE_ADMIN' ? `[DEFAULT] ${data.accountNumber ? data.accountNumber + ' — ' : ''}${data.name ? data.name + ' — ' : ''}${data.last4 ? '*****' + data.last4 + ' — ' : ''}${formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}` : `[DEFAULT] Bank account ending in *****${data.last4}`}
                                                                    </option>);
                                                            } else {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {this.props.userType === 'TYPE_ADMIN' ? `${data.accountNumber ? data.accountNumber + ' — ' : ''}${data.name ? data.name + ' — ' : ''}${data.last4 ? '*****' + data.last4 + ' — ' : ''}${formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}` : `Bank account ending in *****${data.last4}`}
                                                                    </option>);
                                                            }
                                                        })}
                                                        <option value="">-</option>
                                                    </React.Fragment>
                                                    }
                                                </FieldSelect>

                                            </div>
                                            }

                                        </div>
                                        <div className="modal-footer bg-secondary d-block">

                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="">
                                                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="text-right">
                                                        <button type="submit" className="btn btn-primary btn-lg">
                                                            {this.state.property.createDate == null &&
                                                            <span className="">Create Property</span>
                                                            }
                                                            {this.state.property.createDate != null &&
                                                            <span className="">Save</span>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade" id="search-property" tabIndex="-1" role="dialog" aria-labelledby="search-property-label" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                                <div className="modal-content shadow">
                                    <form onSubmit={this.searchIntegrationProperties}>

                                        <div className="modal-header bg-dark text-white">
                                            <h5 className="modal-title" id="search-property-label">
                                                Search Properties
                                            </h5>
                                            <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                                <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                            </button>
                                        </div>

                                        <div className="modal-body bg-secondary">
                                            <p className="mb-0">
                                                Select the property from your integrated system using the available search fields below.
                                            </p>
                                        </div>

                                        <div className="modal-body">

                                            <Alert validationList={this.state.validationList} validationType="danger"/>

                                            <FieldText id="propertyId" label="Property ID" model="integrationSearch" parent={this} value={this.state.integrationSearch['propertyId']} />

                                            <div className="divider">
                                                <span className="small text-muted font-italic text-uppercase">or</span>
                                            </div>

                                            <FieldText id="propertyName" label="Name" model="integrationSearch" parent={this} value={this.state.integrationSearch['propertyName']} />

                                            <FieldText id="street" label="Street" model="integrationSearch" parent={this} value={this.state.integrationSearch['street']} />

                                        </div>

                                        {this.state.integrationPropertyList.length > 0 &&
                                        <div className="modal-body modal-body-table overflow-auto border-top" style={{maxHeight: '400px'}}>
                                            <div className="card-body card-body-table">
                                                <table className="table table-bordered table-hover border">
                                                    <thead>
                                                    <tr>
                                                        <th width="25%">Name</th>
                                                        <th width="25%">Street</th>
                                                        <th width="25%">City</th>
                                                        <th width="25%">Property ID</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.integrationPropertyList.map((data, key) => {
                                                        return (
                                                            <tr key={key} onClick={() => this.selectIntegrationProperty(data)} className="c-pointer">
                                                                <td className="">
                                                                    {data.name}
                                                                </td>
                                                                <td className="">
                                                                    {data.street1}
                                                                </td>
                                                                <td className="">
                                                                    {data.city}
                                                                </td>
                                                                <td className="">
                                                                    {data.externalId}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        }

                                        <div className="modal-footer bg-secondary rounded-bottom d-block">

                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $('#create-tenant').modal('show')}>Back</button>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <button type="submit" className="btn btn-primary btn-lg">Search</button>
                                                </div>
                                            </div>

                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    };
}

Properties.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Properties);