import React from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import * as constants from "../../../util/constants";
import ButtonBack from "../../common/ButtonBack";
import ButtonSubmit from "../../common/ButtonSubmit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import microDeposit from "../../../media/img/payments/micro-deposit.png";
import accountNumber from "../../../media/img/payments/account-number.png";
import institutionNumber from "../../../media/img/payments/institution-number.png";
import transitNumber from "../../../media/img/payments/transit-number.png";
import Propertii from "../../common/Propertii";
import FieldText from "../../common/FieldText";
import ButtonSave from "../../common/ButtonSave";
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import qs from "qs";
import Alert from "../../common/Alert";
import Spinner from "../../common/Spinner";
import Breadcrumb from "../../common/Breadcrumb";

class Banking extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            bankInfo: {},
            screeningStatus: '',
            bankAccountNumber: '',
            routingNumber: '',
            institutionNumber: '',
            transitNumber: '',
            currentInstitutionNumber: '',
            currentTransitNumber: '',
            microDepositAmount: '',
            flinksKey: '1',
            validationList: [],
        };

        this.updateBankingInformation = this.updateBankingInformation.bind(this);
        this.setupBankingInformation = this.setupBankingInformation.bind(this);
        this.validateMicroDeposit = this.validateMicroDeposit.bind(this);
        this.authorizeBankingInformation = this.authorizeBankingInformation.bind(this);
    }

    /**
     * Remove the message event listener upon unmounting the component.
     */
    componentWillUnmount() {

        window.removeEventListener("message", this.authorizeBankingInformation, true);
    }

    /**
     * On mounting of the component, collect the banking information of the landlord.
     */
    componentDidMount() {

        window.addEventListener('message', this.authorizeBankingInformation, true);

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/screening/${this.props.userSession.sessionRole.screeningId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                screeningStatus: response.data.status
            }));
        }).catch(error => {
            this.handleValidation(error);
        });

        if(localStorage.getItem('status') === 'COMPLETE') {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/bankinfo`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    bankInfo: response.data,
                    currentInstitutionNumber: (response.data.routingNumber).substring(0, 3),
                    currentTransitNumber: (response.data.routingNumber).substring(3, 8),
                }));
            }).catch(error => {
                console.error(error);
            });
        }
    }

    /**
     * Setup the landlord's banking information for the first time via issuing a micro-deposit.
     *
     * @param event - The event container.
     */
    setupBankingInformation(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/activate`, {
            bankAccountNumber: this.state.bankAccountNumber,
            routingNumber: this.state.institutionNumber + this.state.transitNumber
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            $('#setup-banking-information').modal('hide');

            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);
            localStorage.setItem('status', 'COMPLETE');

            this.props.refreshAppState();

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'landlord.account.banking.submitted'
                    }
                }],
            });

            axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/bankinfo`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    bankInfo: response.data,
                    currentInstitutionNumber: (response.data.routingNumber).substring(0, 3),
                    currentTransitNumber: (response.data.routingNumber).substring(3, 8),
                }));
            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Submit an update to the landlord's banking information via issuing a micro-deposit.
     *
     * @param event - The event container.
     */
    updateBankingInformation(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/bankinfo`, {
            bankAccountNumber: this.state.bankAccountNumber,
            routingNumber: this.state.institutionNumber + this.state.transitNumber
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'landlord.account.banking.submitted'
                    }
                }],
            });

            axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/bankinfo`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    bankInfo: response.data,
                    currentInstitutionNumber: (response.data.routingNumber).substring(0, 3),
                    currentTransitNumber: (response.data.routingNumber).substring(3, 8),
                }));
            }).catch(error => {
                console.error(error);
            });

            $('#update-banking-information').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Submit the micro-deposit validation process.
     *
     * @param event - The event container.
     */
    validateMicroDeposit(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/micro_deposit/${this.state.bankInfo.microDepId}/validate`, {
            amount: this.state.microDepositAmount
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'landlord.account.banking.validated'
                    }
                }],
            });

            axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/bankinfo`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    bankInfo: response.data,
                    currentInstitutionNumber: (response.data.routingNumber).substring(0, 3),
                    currentTransitNumber: (response.data.routingNumber).substring(3, 8),
                }));
            }).catch(error => {
                console.error(error);
            });

            $('#validate-micro-deposit').modal('hide');

        }).catch(error => {

            this.handleValidation(error);

            axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/bankinfo`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    bankInfo: response.data,
                    currentInstitutionNumber: (response.data.routingNumber).substring(0, 3),
                    currentTransitNumber: (response.data.routingNumber).substring(3, 8),
                }));
            }).catch(error => {
                console.error(error);
            });

        });

        window.scrollTo(0, 0);
    }

    /**
     * Authorize the user's banking information via third party integration with Flinks. Depending on the user's
     * application status, we know whether to activate the user's banking information for the first time, or update it
     * to a new bank account.
     *
     * @param event - The event container.
     */
    authorizeBankingInformation(event) {

        let flinksEndpoint;

        if(localStorage.getItem('status') === 'PAID') {
            flinksEndpoint = "activateflinks";
        }

        if(localStorage.getItem('status') === 'COMPLETE') {
            flinksEndpoint = "bankinfoflinks";
        }

        if(event.data.step === "REDIRECT") {

            let url = event.data.url;
            let parsedUrl = qs.parse(url);

            this.setState(prevState => ({
                ...prevState,
                accountId: parsedUrl.accountId,
                loginId: parsedUrl[window.location.origin + '?loginId'],
                spinner: true
            }));

            axios.post(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/${flinksEndpoint}`, {
                accountId: parsedUrl.accountId,
                loginId: parsedUrl[window.location.origin + '?loginId']
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    flinksKey: Math.random(),
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'landlord.account.banking.authorized'
                        }
                    }],
                }));

                $("#authorize-banking-information").modal("hide");

                if(localStorage.getItem('status') === 'PAID') {
                    localStorage.setItem('token_type', response.data.token_type);
                    localStorage.setItem('access_token', response.data.access_token);
                    localStorage.setItem('expires_in', response.data.expires_in);
                    localStorage.setItem('status', 'COMPLETE');
                }

                this.props.refreshAppState();

                axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/bankinfo`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        bankInfo: response.data,
                        currentInstitutionNumber: (response.data.routingNumber).substring(0, 3),
                        currentTransitNumber: (response.data.routingNumber).substring(3, 8),
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });

            }).catch(error => {

                this.handleValidation(error);

                this.setState(prevState => ({
                    ...prevState,
                    flinksKey: Math.random()
                }));

            });

            window.removeEventListener("message", this.authorizeBankingInformation, true);
            window.addEventListener('message', this.authorizeBankingInformation, true);

            $("#authorize-banking-information").modal("hide");
        }
    }

    /**
     * Render the component.
     *
     * @returns {*} - The create admin interface.
     */
    render() {

        const {formatMessage} = this.props.intl;

        $("#institutionNumber").click(function() {
           $("#bankingDetailsHint").attr("src", institutionNumber);
        });

        $("#transitNumber").click(function() {
            $("#bankingDetailsHint").attr("src", transitNumber);
        });

        $("#bankAccountNumber").click(function() {
            $("#bankingDetailsHint").attr("src", accountNumber);
        });

        window.removeEventListener("message", (e) => this.authorizeBankingInformation(e), true);

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/landlord/account" parentPage="Account" childPage="Banking Information" />

                    <Alert validationList={this.state.validationList} />

                    {this.state.screeningStatus === 'INPROCESS' &&
                    <div className="card card-primary border-primary">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="card-title">
                                        Your account details are under review
                                    </h4>
                                    <p className="card-text">
                                        We're currently in the process of reviewing your account details. Please check back in a few minutes to proceed with setting up your banking information. In the meantime, why not get a head-start on <Link to="/landlord/properties">setting up your properties</Link>?
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {(this.state.screeningStatus === 'FAIL' || this.state.screeningStatus === 'WARNING') &&
                    <div className="card card-danger border-danger">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="card-title">
                                        There was an issue with your account review
                                    </h4>
                                    <p className="card-text">
                                        It appears that the information you provided during your landlord account creation was not sufficient. Please <Link to="/contact">contact us</Link> for further assistance.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {this.state.bankInfo.microDepStatus === 'AWAITING_VALIDATION' &&
                    <div className="card card-warning border-warning">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <h4 className="card-title">
                                        Validate your new banking information
                                    </h4>
                                    <p className="card-text">
                                        For verification purposes, a deposit has been sent to the bank account with an
                                        account number ending in <span
                                        className="font-weight-bold">{this.state.bankInfo.microDepLast4}</span>. This
                                        deposit may take up to 3-5 business days to arrive in your account. Once you have
                                        received the deposit, click the Validate Banking Information button to verify the
                                        amount that has been sent to your account.
                                    </p>
                                </div>
                                <div className="col-md-4 align-self-center text-center">
                                    <div data-toggle="modal" data-target="#validate-micro-deposit" className="btn btn-warning btn-lg mt-4 mt-md-0">
                                        Validate Banking Information
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {this.state.bankInfo.microDepStatus === 'VALIDATION_LIMIT_REACHED' &&
                    <div className="card card-danger border-danger">
                        <div className="card-body">
                            <h4 className="card-title">
                                Your banking information could not be validated
                            </h4>
                            <p className="card-text">
                                For verification purposes, a deposit has been sent to the bank account with an account number ending in <span className="font-weight-bold">{this.state.bankInfo.microDepLast4}</span>. After three attempts, you were unable to provide us with the deposited amount, and your banking information has therefore not been updated. For further assistance in updating your banking information, please <Link to="/contact">contact us</Link>.
                            </p>
                        </div>
                    </div>
                    }

                    <form onSubmit={this.handleSubmit}>

                        <div className="card">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        Banking Information
                                    </div>
                                    <div className="col text-right">
                                        {localStorage.getItem('status') === 'COMPLETE' &&
                                        <div data-toggle="modal" data-target="#authorize-banking-information" className="btn btn-primary btn-sm">
                                            Update Banking Information
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            {localStorage.getItem('status') === 'COMPLETE' &&
                            <div className="card-body card-body-table">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th colSpan="2">
                                            {this.state.bankInfo.status &&
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.merchantAccount.accountStatus." + this.state.bankInfo.status + ".class"})}`} />
                                                <span className="ml-1">Current Banking Information (<FormattedMessage id={"enum.merchantAccount.accountStatus." + this.state.bankInfo.status}/>)</span>
                                            </div>
                                            }
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td width="50%">Institution Number</td>
                                        <td width="50%">{this.state['currentInstitutionNumber']}</td>
                                    </tr>
                                    <tr>
                                        <td>Transit Number</td>
                                        <td>{this.state['currentTransitNumber']}</td>
                                    </tr>
                                    <tr>
                                        <td>Bank Account Number</td>
                                        <td>*****{this.state.bankInfo['last4']}</td>
                                    </tr>
                                    </tbody>
                                    {(this.state.bankInfo.microDepStatus === 'AWAITING_VALIDATION' && this.state.bankInfo.status === 'ACTIVE') &&
                                    <React.Fragment>
                                        <thead>
                                        <tr>
                                            <th colSpan="2">
                                                <div className="text-nowrap">
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-warning" />
                                                    <span className="ml-1">New Banking Information (Pending Validation)</span>
                                                </div>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td width="50%">Institution Number</td>
                                            <td width="50%">{(this.state.bankInfo.microDepRoutingNumber).substring(0, 3)}</td>
                                        </tr>
                                        <tr>
                                            <td>Transit Number</td>
                                            <td>{this.state.bankInfo.microDepRoutingNumber.substring(3, 8)}</td>
                                        </tr>
                                        <tr>
                                            <td>Bank Account Number</td>
                                            <td>*****{this.state.bankInfo.microDepLast4}</td>
                                        </tr>
                                        </tbody>
                                    </React.Fragment>
                                    }
                                    {(this.state.bankInfo.microDepStatus === 'VALIDATION_LIMIT_REACHED' && this.state.bankInfo.status === 'ACTIVE') &&
                                    <React.Fragment>
                                        <thead>
                                        <tr>
                                            <th colSpan="2">
                                                <div className="text-nowrap">
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-danger" />
                                                    <span className="ml-1">New Banking Information (Validation Failed)</span>
                                                </div>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td width="50%">Institution Number</td>
                                            <td width="50%">{(this.state.bankInfo.microDepRoutingNumber).substring(0, 3)}</td>
                                        </tr>
                                        <tr>
                                            <td>Transit Number</td>
                                            <td>{this.state.bankInfo.microDepRoutingNumber.substring(3, 8)}</td>
                                        </tr>
                                        <tr>
                                            <td>Bank Account Number</td>
                                            <td>*****{this.state.bankInfo.microDepLast4}</td>
                                        </tr>
                                        </tbody>
                                    </React.Fragment>
                                    }
                                </table>
                            </div>
                            }

                            {(localStorage.getItem('status') === 'PAID' && (this.state.screeningStatus === 'INPROCESS' || this.state.screeningStatus === 'FAIL')) &&
                            <div className="card-body bg-secondary text-center py-5">

                                <div className="row justify-content-center">
                                    <div className="col-10">
                                        <div className="text-center text-muted">
                                            <div className="fa-stack fa-2x mb-2">
                                                <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                <FontAwesomeIcon icon={['far', 'university']} className="fa-stack-1x fa-inverse" />
                                            </div>
                                        </div>
                                        <div className="text-center text-muted">
                                            <small>
                                                Once your landlord details have been verified, you'll be able to choose your bank account here.
                                            </small>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            }

                            {(localStorage.getItem('status') === 'PAID' && (this.state.screeningStatus === 'PASS' || this.state.screeningStatus === 'FORCED_PASS')) &&
                            <div className="card-body text-center">

                                <p className="pt-3 pb-1">Choose which bank account you would like payments from your tenants to be deposited into.</p>

                                <div className="mb-4">
                                    <div data-toggle="modal" data-target="#authorize-banking-information" className="btn btn-primary btn-lg mb-0">
                                        <div className="">
                                            Choose My Bank Account
                                        </div>
                                    </div>
                                </div>

                            </div>
                            }

                            {(this.state.screeningStatus === 'PASS' || this.state.screeningStatus === 'FORCED_PASS') &&
                            <div className="card-footer">

                                {localStorage.getItem('status') === 'COMPLETE' &&
                                <small className="text-muted">
                                    Payments from your tenants will be deposited into the bank account matching the above account information.
                                </small>
                                }

                                {localStorage.getItem('status') === 'PAID' &&
                                <small className="text-muted">
                                    All banking information is encrypted and securely handled. Before being able to accept payments, you'll need to choose which bank account you would like payments from your tenants to be deposited into.
                                </small>
                                }

                            </div>
                            }

                        </div>

                        <div className="row">
                            <div className="col text-right">

                                <ButtonBack path="/landlord/account" />

                            </div>
                        </div>

                    </form>

                    <div className="modal fade" id="validate-micro-deposit" tabIndex="-1" role="dialog" aria-labelledby="validate-micro-deposit-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.validateMicroDeposit}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="validate-micro-deposit-label">
                                            Validate Banking Information
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body bg-secondary">

                                        <img src={microDeposit} alt="A deposit from Letus will appear in your bank account statements. Enter the deposit amount in the field below to verify your bank account with us." className="img-fluid mb-2" />

                                        <p className="mb-0">
                                            A deposit has been sent to the bank account with an account number ending in <span className="font-weight-bold">{this.state.bankInfo.microDepLast4}</span>. Enter the deposit amount below to validate your banking information.
                                        </p>

                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldText id="microDepositAmount" label="Deposit Amount" type="number" fieldColumns="8" labelColumns="4"
                                                   min="0.00" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" parent={this}
                                                   value={this.state['microDepositAmount']} />

                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom">

                                        <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#validate-micro-deposit").modal("hide")}>Close</button>

                                        <ButtonSubmit />

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {localStorage.getItem('status') === 'COMPLETE' &&
                    <div className="modal fade" id="update-banking-information" tabIndex="-1" role="dialog" aria-labelledby="update-banking-information-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.updateBankingInformation}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="update-banking-information-label">
                                            Update Banking Information
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                        </button>
                                    </div>
                                    <div className="modal-body bg-secondary">

                                        <p className="mb-4">
                                            Enter which bank account you would like payments from your tenants to be deposited into.
                                        </p>

                                        <div className="text-center">
                                            <img src={institutionNumber} alt="A deposit from Letus will appear in your bank account statements. Enter the deposit amount in the field below to verify your bank account with us." className="img-fluid mb-2" id="bankingDetailsHint"/>
                                        </div>

                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldText id="institutionNumber" label="Institution Number" labelColumns="4" fieldColumns="8" type="tel" parent={this} value={this.state['institutionNumber']}/>

                                        <FieldText id="transitNumber" label="Transit Number" labelColumns="4" fieldColumns="8" type="tel" parent={this} value={this.state['transitNumber']}/>

                                        <FieldText id="bankAccountNumber" label="Account Number" labelColumns="4" fieldColumns="8" type="tel" parent={this} value={this.state['bankAccountNumber']}/>

                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom">

                                        <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#update-banking-information").modal("hide")}>Close</button>

                                        <ButtonSave />

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    }

                    {localStorage.getItem('status') === 'PAID' &&
                    <div className="modal fade" id="setup-banking-information" tabIndex="-1" role="dialog" aria-labelledby="setup-banking-information-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.setupBankingInformation}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="setup-banking-information-label">
                                            Set Up Banking Information
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                        </button>
                                    </div>
                                    <div className="modal-body bg-secondary">

                                        <p className="mb-4">
                                            Providing your banking information allows us to determine where funds should be sent to when your tenants make lease payments through Letus.
                                        </p>

                                        <div className="text-center">
                                            <img src={institutionNumber} alt="A deposit from Letus will appear in your bank account statements. Enter the deposit amount in the field below to verify your bank account with us." className="img-fluid mb-2" id="bankingDetailsHint"/>
                                        </div>

                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldText id="institutionNumber" label="Institution Number" labelColumns="4" fieldColumns="8" type="tel" parent={this} value={this.state['institutionNumber']}/>

                                        <FieldText id="transitNumber" label="Transit Number" labelColumns="4" fieldColumns="8" type="tel" parent={this} value={this.state['transitNumber']}/>

                                        <FieldText id="bankAccountNumber" label="Account Number" labelColumns="4" fieldColumns="8" type="tel" parent={this} value={this.state['bankAccountNumber']}/>

                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom">

                                        <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#setup-banking-information").modal("hide")}>Close</button>

                                        <ButtonSubmit />

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    }

                    <div className="modal fade" id="authorize-banking-information" tabIndex="-1" role="dialog" aria-labelledby="authorize-banking-information-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.setupBankingInformation}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="authorize-banking-information-label">
                                            {localStorage.getItem('status') === 'PAID' &&
                                            <span className="">Authorize Banking Information</span>
                                            }
                                            {localStorage.getItem('status') === 'COMPLETE' &&
                                            <span className="">Update Banking Information</span>
                                            }
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                        </button>
                                    </div>
                                    <div className="modal-body modal-body-table">

                                        <Alert validationList={this.state.validationList} validationType="danger" />
                                        
                                        <div className="">
                                            <div className="embed-responsive embed-responsive-1by1">
                                                <iframe className="embed-responsive-item" key={this.state.flinksKey} title="flinks" allowFullScreen={true} src={`${constants.REACT_APP_FLINKS_SRC}?redirectUrl=${window.location.origin}&fixedHeightEnable=false&innerRedirect=true&demo=${constants.REACT_APP_FLINKS_DEMO}&theme=light&desktopLayout=true&consentEnable=true&accountSelectorEnable=true&tag=${this.props.userSession.sessionRole.id}`} />
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    };
}

Banking.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Banking);