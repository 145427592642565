import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import axios from "axios";
import * as constants from "../../../util/constants";
import InvoiceList from "../../common/Invoices";

class Invoices extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            landlord: {},
            validationList: [],
        }
    }

    /**
     * On mounting of the component, fetch the landlord to determine the company ID of the landlord.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                landlord: response.data
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The company subscription component.
     */
    render() {

        return(
            <React.Fragment>

                {this.state.landlord.companyId &&
                <InvoiceList companyId={this.state.landlord.companyId} sessionRole={this.props.userSession.sessionRole} token={this.props.token} userType="TYPE_LANDLORD" userId={this.props.match.params.landlordId} />
                }

            </React.Fragment>
        )
    };
}

Invoices.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Invoices);