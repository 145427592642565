import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import ButtonBack from "../../common/ButtonBack";
import PaymentMethodList from "../../common/PaymentMethods";
import Breadcrumb from "../../common/Breadcrumb";

class PaymentMethods extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The view payment methods component for customers.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/customer/payments" parentPage="Payments" childPage="Payment Methods" />

                    <PaymentMethodList userType="TYPE_CUSTOMER" userId={this.props.userSession.sessionRole.id} createMessage="customer.payments.methods.created" deleteMessage="customer.payments.methods.deleted" />

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/customer/payments" />

                        </div>
                    </div>


                </div>

            </div>
        )
    };
}

PaymentMethods.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(PaymentMethods);