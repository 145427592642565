import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import Propertii from "../../common/Propertii";
import FieldText from "../../common/FieldText";
import FieldPhone from "../../common/FieldPhone";
import $ from "jquery";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Spinner from "../../common/Spinner";
import {injectIntl, intlShape} from "react-intl";
import FieldSelect from "../../common/FieldSelect";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldCharges from "../../common/FieldCharges";
import FieldDate from "../../common/FieldDate";

class Tenant extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            propertyLease: {},
            customer: {},

            charges: [],
            customChargeCodes: [],

            integrationId: '',
            integrationCustomerList: [],
            integrationSearch: {
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                tenantId: '',
                propertyId: ''
            },

            validationList: [],

        };

        this.saveCustomer = this.saveCustomer.bind(this);

        this.initIntegrationCustomers = this.initIntegrationCustomers.bind(this);
        this.searchIntegrationCustomers = this.searchIntegrationCustomers.bind(this);
        this.selectIntegrationCustomer = this.selectIntegrationCustomer.bind(this);

        this.addCharge = this.addCharge.bind(this);
        this.removeCharge = this.removeCharge.bind(this);
        this.importCharges = this.importCharges.bind(this);

        this.handleChangeAccountNumber = this.handleChangeAccountNumber.bind(this);
    }

    /**
     * Fetch the property lease and associated customer data on mounting of the component. Also pre-fill the property ID
     * field of the integrated tenant search with the relevant property identifier.
     */
    componentDidMount() {

        this.setState({
            spinner: true,
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.match.params.propertyLeaseId
                }
            ],
            joins: {
                property: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'property',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode', 'propertyIdentifier']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let propertyLease = response.data.records[0];

            axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${propertyLease.userId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    customer: response.data,
                }));

            }).catch(error => {
                console.error(error);
            });

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${propertyLease.companyId}/fallbackfields`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    propertyLease: propertyLease,
                    integrationId: response.data.integrationId,
                    customChargeCodes: response.data.chargeCodes,
                    charges: propertyLease.charges,
                    integrationSearch: {
                        ...prevState.integrationSearch,
                        propertyId: propertyLease.joins.property.propertyIdentifier
                    }
                }));

            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle the submission of the form by saving both the customer and property lease data.
     *
     * @param event - The event container.
     */
    saveCustomer(event) {

        event.preventDefault();

        this.setState({
            spinner: true,
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, [
            this.state.customer,
            {
                ...this.state.propertyLease,
                charges: this.state.charges
            }
        ], {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved'
                    }
                }],
            }));

            this.props.refreshPropertyLease(this.props.match.params.propertyLeaseId);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Initialize the search integration customers process by clearing out the search fields and revealing the search
     * integration customers modal.
     */
    initIntegrationCustomers() {

        this.setState(prevState => ({
            ...prevState,
            integrationCustomerList: [],
            integrationSearch: {
                ...prevState.integrationSearch,
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                tenantId: ''
            }
        }));

        $('#property-lease').modal('hide');
        $('#search-tenant').modal('show');
    }

    /**
     * Perform a search for customers in the integrated system for selection.
     */
    searchIntegrationCustomers(event) {

        event.preventDefault();

        this.setState({
            spinner: true,
            integrationCustomerList: [],
            validationList: []
        });

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/tenant/search`, {
            email: this.state.integrationSearch.email,
            firstName: this.state.integrationSearch.firstName,
            lastName: this.state.integrationSearch.lastName,
            phone: this.state.integrationSearch.phone,
            propertyId: this.state.integrationSearch.propertyId,
            tenantId: this.state.integrationSearch.tenantId,
            integrationId: this.state.integrationId
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    integrationCustomerList: response.data
                }));
            }

            if(response.data.length === 0) {
                this.setState({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'manager.tenants.lease.integration.null'
                        }
                    }]
                });
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Select a customer from the integrated system. Using the data from the integrated system, the fields used to
     * invite or create a tenant will be pre-filled.
     *
     * @param data - The customer that has been selected from the integrated system.
     */
    selectIntegrationCustomer(data) {

        this.setState(prevState => ({
            ...prevState,
            propertyLease: {
                ...prevState.propertyLease,
                accountNumber: data.externalId,
                unit: data.unit
            },
            customer: {
                ...prevState.customer,
                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone,
            }
        }));

        $('#search-tenant').modal('hide');
    }

    /**
     * Add a new blank charge to the list of charges.
     */
    addCharge() {

        this.setState(prevState => ({
            ...prevState,
            charges: [...prevState.charges, {
                amount: '',
                code: '',
                name: ''
            }],
        }));
    }

    /**
     * Remove a specific charge from the list of charges.
     *
     * @param index - The array index of the charge to remove.
     */
    removeCharge(index) {

        let charges = this.state.charges;

        charges.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            charges: charges,
        }));
    }

    /**
     * Import charges and amounts from the integrated system.
     */
    importCharges() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/tenant/recurringcharges`, {
            propertyId: this.state.integrationSearch.propertyId,
            tenantId: this.state.propertyLease.accountNumber,
            integrationId: this.state.integrationId
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    customChargeCodes: [...prevState.customChargeCodes, ...response.data],
                    charges: response.data,
                }));
            }

            if(response.data.length === 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'common.tenants.charges.null'
                        }
                    }]
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the account number field.
     *
     * @param event - The event container.
     */
    handleChangeAccountNumber(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            propertyLease: {
                ...prevState.propertyLease,
                accountNumber: event.target.value
            }
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The tenant account management component for landlords.
     */
    render() {

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.saveCustomer}>

                    <div className="card">
                        <div className="card-header">
                            Tenant
                        </div>
                        <div className="card-body">

                            <FieldText id="firstName" label="First Name" model="customer" parent={this} value={this.state.customer['firstName']} />

                            <FieldText id="lastName" label="Last Name" model="customer" parent={this} value={this.state.customer['lastName']} />

                            <FieldText id="email" label="Email" type="email" model="customer" parent={this} value={this.state.customer['email']} help={this.state.customer['email'] ? '' : 'Assign an email address to this tenant to allow them to log in.'} />

                            <FieldPhone id="phone" label="Phone" model="customer" parent={this} value={this.state.customer['phone']} optional={true} />

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label col-form-label-sm" htmlFor="accountNumber">
                                    Account ID
                                </label>
                                <div className="col-sm-9">
                                    <div className="input-group input-group-sm">

                                        <input id="accountNumber" name="accountNumber" value={this.state.propertyLease['accountNumber'] || ""} className="form-control mb-0" onChange={this.handleChangeAccountNumber}/>

                                        {this.state.integrationId &&
                                        <div className="input-group-append">
                                            <div className={`btn btn-secondary ${this.state.propertyLease.propertyId ? '' : 'disabled'}`} onClick={() => this.initIntegrationCustomers()}>
                                                Search...
                                            </div>
                                        </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            <FieldSelect id="status" label="Lease Status" model="propertyLease" parent={this} value={this.state.propertyLease['status']}>
                                <option disabled>Select a status...</option>
                                <option value="ACTIVE">Active</option>
                                <option value="SUSPENDED">Suspended</option>
                                <option value="TERMINATED">Terminated</option>
                            </FieldSelect>

                            <FieldText id="unit" label="Unit" model="propertyLease" parent={this} value={this.state.propertyLease['unit']} />

                            <FieldDate id="startDate" label="Start Date" model="propertyLease" parent={this} disabled={true} value={this.state.propertyLease['startDate'] || null} />

                            <FieldSelect id="monthlyPaymentDueDay" label="Payment Due" model="propertyLease" parent={this} value={this.state.propertyLease['monthlyPaymentDueDay']}>
                                <option value={0}>No monthly payment due date</option>
                                <option value={1}>1st day of every month</option>
                                <option value={2}>2nd day of every month</option>
                                <option value={3}>3rd day of every month</option>
                                <option value={4}>4th day of every month</option>
                                <option value={5}>5th day of every month</option>
                                <option value={6}>6th day of every month</option>
                                <option value={7}>7th day of every month</option>
                                <option value={8}>8th day of every month</option>
                                <option value={9}>9th day of every month</option>
                                <option value={10}>10th day of every month</option>
                                <option value={11}>11th day of every month</option>
                                <option value={12}>12th day of every month</option>
                                <option value={13}>13th day of every month</option>
                                <option value={14}>14th day of every month</option>
                                <option value={15}>15th day of every month</option>
                                <option value={16}>16th day of every month</option>
                                <option value={17}>17th day of every month</option>
                                <option value={18}>18th day of every month</option>
                                <option value={19}>19th day of every month</option>
                                <option value={20}>20th day of every month</option>
                                <option value={21}>21st day of every month</option>
                                <option value={22}>22nd day of every month</option>
                                <option value={23}>23rd day of every month</option>
                                <option value={24}>24th day of every month</option>
                                <option value={25}>25th day of every month</option>
                                <option value={26}>26th day of every month</option>
                                <option value={27}>27th day of every month</option>
                                <option value={28}>28th day of every month</option>
                                <option value={29}>29th day of every month</option>
                                <option value={30}>30th day of every month</option>
                                <option value={31}>31st day of every month</option>
                            </FieldSelect>

                            <FieldCheckbox id="blockPartialPayments" label="Partial Payments" fieldLabel="Block this tenant from making partial payments" model="propertyLease" parent={this} value={this.state.propertyLease['blockPartialPayments']} />

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label col-form-label-sm">
                                    Monthly Charges
                                </label>
                                <div className="col-sm-9">
                                    <FieldCharges customChargeCodes={this.state.customChargeCodes.length > 0 ? this.state.customChargeCodes : null} charges={this.state.charges} addCharge={this.addCharge} removeCharge={this.removeCharge} importCharges={this.state.integrationId && this.state.propertyLease.accountNumber ? this.importCharges : null} size="sm" parent={this} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonSave />

                        </div>
                    </div>

                </form>

                <div className="modal fade" id="search-tenant" tabIndex="-1" role="dialog" aria-labelledby="search-tenant-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.searchIntegrationCustomers}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="search-tenant-label">
                                        Search Tenants
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Select the tenant from your integrated system using the available search fields below.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                    <FieldText id="tenantId" label="Account ID" model="integrationSearch" parent={this} value={this.state.integrationSearch['tenantId']} />

                                    <div className="divider">
                                        <span className="small text-muted font-italic text-uppercase">or</span>
                                    </div>

                                    <FieldText id="propertyId" label="Property ID" model="integrationSearch" parent={this} disabled={true} value={this.state.integrationSearch['propertyId']} />

                                    <FieldText id="firstName" label="First Name" model="integrationSearch" parent={this} value={this.state.integrationSearch['firstName']} />

                                    <FieldText id="lastName" label="Last Name" model="integrationSearch" parent={this} value={this.state.integrationSearch['lastName']} />

                                    <FieldText id="email" label="Email" type="email" model="integrationSearch" parent={this} value={this.state.integrationSearch['email']} />

                                    <FieldText id="phone" label="Phone" type="tel" model="integrationSearch" parent={this} value={this.state.integrationSearch['phone']} />

                                </div>

                                {this.state.integrationCustomerList.length > 0 &&
                                <div className="modal-body modal-body-table overflow-auto border-top" style={{maxHeight: '400px'}}>
                                    <div className="card-body card-body-table">
                                        <table className="table table-bordered table-hover border">
                                            <thead>
                                            <tr>
                                                <th width="25%">Tenant</th>
                                                <th width="25%">Unit</th>
                                                <th width="25%">Account ID</th>
                                                <th width="25%">Property ID</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.integrationCustomerList.map((data, key) => {
                                                return (
                                                    <tr key={key} onClick={() => this.selectIntegrationCustomer(data)} className="c-pointer">
                                                        <td className="">
                                                            {data.firstName} {data.lastName}
                                                        </td>
                                                        <td className="">
                                                            {data.unit}
                                                        </td>
                                                        <td className="">
                                                            {data.externalId}
                                                        </td>
                                                        <td className="">
                                                            {data.externalPropertyId}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                }

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-6 text-left">
                                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $('#create-tenant').modal('show')}>Back</button>
                                        </div>
                                        <div className="col-6 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">Search</button>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

Tenant.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Tenant);