import React from 'react';
import {injectIntl, intlShape} from 'react-intl';
import Letus from "./common/Propertii";
import Banner from "./common/Banner";

class Privacy extends Letus {

    /**
     * Render the component.
     *
     * @returns {*} - A public-facing static component that displays the privacy policy page.
     */
    render() {

        return(
            <div className="content-block">

                <Banner backgroundImg="coffee" heading="static.privacy.heading" subheading="static.privacy.subheading" />

                <div className="container">

                    <p className="">This privacy statement describes how PaySlate Inc. collects and uses the personal information you provide on our Web site: <a href="https://let.us/" target="_blank" rel="noopener noreferrer">www.let.us</a> or <a href="https://let.us/" target="_blank" rel="noopener noreferrer">let.us</a> or any other Web sites we own and manage. It also describes the choices available to you regarding our use of your personal information and how you can access and update this information.</p>

                    <h5>Collection and Use of Personal Information</h5>

                    <p className="">We collect the following personal information from you:</p>
                    <ul>
                        <li className="">Contact Information such as name, email address, residential/business address, mailing address, phone number</li>
                        <li className="">Billing Information such as credit card number, bank account, and billing address</li>
                        <li className="">Financial Information such as bank account numbers,  banking information, payment method information</li>
                        <li className="">Unique Identifiers such as user name, account number, password</li>
                    </ul>

                    <p className="">We also collect the following information from you:</p>
                    <ul>
                        <li className="">Information about your business such as company name, company size, business type</li>
                        <li className="">Demographic information such as age, education, gender, interests and postal/zip code</li>
                    </ul>

                    <p className="">As is true of most Web sites, we automatically gather information about your computer such as your IP address, browser type, referring/exit pages, and operating system.</p>

                    <p className="">We use this information to:</p>
                    <ul>
                        <li className="">Process transactions</li>
                        <li className="">Communicate with you regarding your account</li>
                        <li className="">Send product updates or other information</li>
                        <li className="">Respond to customer service requests</li>
                        <li className="">Administer your account</li>
                        <li className="">Send you a newsletter</li>
                        <li className="">Send you marketing communications</li>
                        <li className="">Respond to your questions and concerns</li>
                        <li className="">Improve our Web site and marketing efforts</li>
                        <li className="">Conduct research and analysis</li>
                        <li className="">Display content based upon your interests</li>
                    </ul>

                    <h5>Opt-In/Opt-Out</h5>

                    <p className="">When you opt into our newsletter and marketing emails you consent to receive both Letus information and third party offers.</p>
                    <p className="">You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or you can contact us at <a href="mailto:help@let.us">help@let.us</a>.</p>

                    <p className="font-weight-bold">Information Sharing</p>

                    <p className="">We will share your personal information with third parties only in the ways that are described in this privacy statement. We do not sell your personal information to third parties.</p>
                    <p className="">We may provide your personal information to companies that provide services to help us with our business activities and offering customer service. These companies are authorized to use your personal information only as necessary to provide these services to us.</p>

                    <p className="">We may also disclose your personal information</p>
                    <ul>
                        <li className="">as required by law such as to comply with a subpoena, or similar legal process</li>`
                        <li className="">when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request,</li>
                        <li className="">if we are involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information,</li>
                        <li className="">to any other third party with your prior consent to do so.</li>
                    </ul>

                    <h5>Cookies and Other Tracking Technologies</h5>

                    <p className="">We use cookies on our web sites. We use cookies to improve our web site’s functionality and in some cases, to provide Users with a more customized online experience. Cookies are widely used and most web browsers are configured initially to accept cookies automatically. You may change your Internet browser settings to prevent your computer from accepting cookies or to notify you when you receive a cookie so that you may decline its acceptance. Please note, however, if you disable cookies, you may not experience optimal performance of our web sites.</p>
                    <p className="">We use a third party to gather information about how you and others use our Web site. For example, we will know how many users access a specific page and which links they clicked on. We use this aggregated information to understand and optimize how our site is used.</p>

                    <h5>Links to Other Web Sites</h5>

                    <p className="">Our Site includes links to other Web sites whose privacy practices may differ from those of Letus. If you submit personal information to any of those sites, your information is governed by their privacy statements. We encourage you to carefully read the privacy statement of any Web site you visit.</p>

                    <h5>Security</h5>

                    <p className="">The security of your personal information is important to us. When you enter sensitive information (such as credit card number or bank account) on our order forms, we encrypt the transmission of that information using secure socket layer technology (SSL).</p>
                    <p className="">We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security.</p>
                    <p className="">We will retain your information for as long as your account is active or as needed to provide you services. If you wish to cancel your account or request that we no longer use your information to provide you services contact us at help@let.us. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                    <p className="">If you have any questions about security on our Web site, you can contact us at <a href="mailto:help@let.us">help@let.us</a></p>

                    <h5>Additional Policy Information</h5>

                    <p className="">You can log in to our site using sign-in services such as Google or an Open ID provider. These services will authenticate your identity and provide you with the option to share certain personal information with us such as your name and email address to pre-populate our sign up form. Our Web site offers publicly accessible blogs or community forums. You should be aware that any information you provide in these areas may be read, collected, and used by others who access them.</p>

                    <h5>Correcting and Updating Your Personal Information</h5>

                    <p className="">To review and update your personal information to ensure it is accurate, contact us at <a href="mailto:help@let.us">help@let.us</a>.</p>

                    <h5>Notification of Privacy Statement Changes</h5>

                    <p className="">We may update this privacy statement from time to time to reflect changes to our information practices or as required to comply with applicable laws and regulations. We encourage you to periodically review this page for the latest information on our privacy practices.</p>

                    <h5>Contact Information</h5>

                    <p className="">You can contact us about this privacy statement by writing or email us at the address below:</p>

                    <p className="">
                        Letus Payment Solutions Inc.<br />
                        710 - 1030 W. Georgia St.<br />
                        Vancouver, BC, Canada, V6E 2Y3<br />
                        Email: <a href="mailto:help@let.us">help@let.us</a><br />
                        Phone: <a href="tel:18886658870">1-888-665-8870</a>
                    </p>

                </div>

            </div>
        );
    };
}

Privacy.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Privacy);