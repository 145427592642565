import React from 'react';
import {injectIntl, intlShape} from 'react-intl';
import Letus from "./common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import familyMoving from "../media/img/backgrounds/couple-moving.jpg";

/* eslint no-script-url: 0 */

class Contact extends Letus {

    /**
     * Render the component.
     *
     * @returns {*} - A public-facing static component that displays the contact page.
     */
    render() {

        $('#zopim').attr('href', 'javascript:$zopim.livechat.window.show();');

        return(
            <div className="content-block">

                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${familyMoving}') no-repeat center center scroll`}}>
                    <div className="container text-md-center">
                        <h1 className="display-5 pt-3 text-white">
                            Contact Us
                        </h1>
                        <p className="lead text-white">
                            Letus' world-class support team is here to assist you
                        </p>
                    </div>
                </div>

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-4 text-center mb-5 mb-sm-0">
                            <div className="px-3">
                                <h3 className="display-5 mb-5">
                                    Write to us
                                </h3>
                                <FontAwesomeIcon icon={['fal', 'envelope-open-text']} className="fa-fw mb-5 text-primary" size="6x" />
                                <p className="mb-4">
                                    Our inbox is open 24 hours a day, 7 days a week
                                </p>
                                <a href="mailto:support@let.us" className="btn btn-primary btn-lg">
                                    Send a Message
                                </a>
                            </div>
                        </div>

                        <div className="col-md-4 text-center mb-5 mb-sm-0 border-left border-right">
                            <div className="px-3">
                                <h3 className="display-5 mb-5">
                                    Call or text us
                                </h3>
                                <FontAwesomeIcon icon={['fal', 'phone']} className="fa-fw mb-5 text-primary" size="6x" />
                                <p className="mb-4">
                                    Available 7:00 AM – 4:00 PM Monday-Friday (Pacific Time)
                                </p>
                                <a href="tel:18886658870" className="btn btn-primary btn-lg">
                                    1-888-665-8870
                                </a>
                            </div>
                        </div>

                        <div className="col-md-4 text-center">
                            <div className="px-3">
                                <h3 className="display-5 mb-5">
                                    Chat with us live
                                </h3>
                                <FontAwesomeIcon icon={['fal', 'comments']} className="fa-fw mb-5 text-primary" size="6x" />
                                <p className="mb-4">
                                    Available 7:00 AM – 4:00 PM Monday-Friday (Pacific Time)
                                </p>
                                <a href="https://let.us/" id="zopim" className="btn btn-primary btn-lg">
                                    Start a Chat
                                </a>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="jumbotron jumbotron-fluid mb-0">
                    <div className="container">

                        <div className="text-center">
                            <h3 className="display-5 mb-4">
                                Your burning questions, answered instantly
                            </h3>
                        </div>

                        <div className="row justify-content-sm-center">
                            <div className="col-md-6 text-center">

                                <p className="">
                                    Need a hand? From step-by-step guides to tips on making your rent payment experience easier than ever before, the Letus Help Center has you covered with articles about Letus.
                                </p>

                                <div className="text-center">
                                    <a href="https://help.rentmoola.com/hc/en-us" target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-primary mt-3">
                                        Visit our Help Center
                                    </a>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    };
}

Contact.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Contact);