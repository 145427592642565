import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import Propertii from "../../common/Propertii";
import FieldText from "../../common/FieldText";
import FieldAddress from "../../common/FieldAddress";
import $ from "jquery";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Spinner from "../../common/Spinner";

class Property extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            property: {},
            company: {},

            integrationId: '',

            customMerchantAccounts: false,
            creditMerchantAccountList: {},

            integrationSearch: {
                propertyId: '',
                propertyName: '',
                street: ''
            },

            integrationPropertyList: [],

            validationList: [],

        };

        this.saveProperty = this.saveProperty.bind(this);
        this.searchIntegrationProperties = this.searchIntegrationProperties.bind(this);
        this.selectIntegrationProperty = this.selectIntegrationProperty.bind(this);
        this.handleChangePropertyIdentifier = this.handleChangePropertyIdentifier.bind(this);
    }

    /**
     * Fetch a new instance of a property object and the company on the mounting of the component.
     */
    componentDidMount() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.match.params.propertyId
                }
            ],
            joins: {
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['name']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let property = response.data.records[0];

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${response.data.records[0].companyId}/fallbackfields`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    property: property,
                    integrationId: response.data.integrationId,
                }));

            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle the submission of the form.
     *
     * @param event - The event container.
     */
    saveProperty(event) {

        event.preventDefault();

        let property = this.state.property;

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, property, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved'
                    }
                }],
            });

            this.props.refreshProperty(this.props.match.params.propertyId);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Perform a search for properties in the integrated system for selection.
     */
    searchIntegrationProperties(event) {

        event.preventDefault();

        this.setState({
            spinner: true,
            integrationPropertyList: [],
            validationList: [{
                fields: {},
                alert: {
                    type: '',
                    code: ''
                }
            }]
        });

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/property/search`, {
            integrationId: this.state.integrationId,
            propertyId: this.state.integrationSearch.propertyId,
            propertyName: this.state.integrationSearch.propertyName,
            street: this.state.integrationSearch.street,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    integrationPropertyList: response.data
                }));
            }

            if(response.data.length === 0) {
                this.setState({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'manager.properties.property.integration.null'
                        }
                    }]
                });
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Select a property from the integrated system. Using the data from the integrated system, the fields used to
     * create a property will be pre-filled.
     *
     * @param data - The property that has been selected from the integrated system.
     */
    selectIntegrationProperty(data) {

        this.setState(prevState => ({
            ...prevState,
            property: {
                ...prevState.property,
                city: data.city,
                country: data.country,
                propertyIdentifier: data.externalId,
                propertyName: data.name,
                postalCode: data.postalCode,
                province: data.province,
                street1: data.street1,
                street2: data.street2,
            },
        }));

        $('#search-property').modal('hide');
    }

    /**
     * Handle changes to the account number field.
     *
     * @param event - The event container.
     */
    handleChangePropertyIdentifier(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            property: {
                ...prevState.property,
                propertyIdentifier: event.target.value
            },
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The edit property address component for managers.
     */
    render() {

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.saveProperty}>

                    <div className="card">
                        <div className="card-header">
                            Property
                        </div>
                        <div className="card-body">

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label col-form-label-sm" htmlFor="propertyIdentifier">
                                    Property ID
                                </label>
                                <div className="col-sm-9">
                                    <div className="input-group input-group-sm">

                                        <input id="propertyIdentifier" name="propertyIdentifier" value={this.state.property['propertyIdentifier'] || ""} className="form-control mb-0" onChange={this.handleChangePropertyIdentifier}/>

                                        {this.state.integrationId &&
                                        <div className="input-group-append">
                                            <div className="btn btn-secondary" onClick={() => {$('#property').modal('hide');$('#search-property').modal('show');}}>
                                                Search...
                                            </div>
                                        </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            <FieldText id="propertyName" label="Property Name" model="property" parent={this} value={this.state.property['propertyName']}  />

                            <FieldAddress model="property" parent={this} value={this.state.property} />

                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonSave />

                        </div>
                    </div>

                </form>

                <div className="modal fade" id="search-property" tabIndex="-1" role="dialog" aria-labelledby="search-property-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.searchIntegrationProperties}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="search-property-label">
                                        Search Properties
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Select the property from your integrated system using the available search fields below.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                    <FieldText id="propertyId" label="Property ID" model="integrationSearch" parent={this} value={this.state.integrationSearch['propertyId']} />

                                    <div className="divider">
                                        <span className="small text-muted font-italic text-uppercase">or</span>
                                    </div>

                                    <FieldText id="propertyName" label="Name" model="integrationSearch" parent={this} value={this.state.integrationSearch['propertyName']} />

                                    <FieldText id="street" label="Street" model="integrationSearch" parent={this} value={this.state.integrationSearch['street']} />

                                </div>

                                {this.state.integrationPropertyList.length > 0 &&
                                <div className="modal-body modal-body-table overflow-auto border-top" style={{maxHeight: '400px'}}>
                                    <div className="card-body card-body-table">
                                        <table className="table table-bordered table-hover border">
                                            <thead>
                                            <tr>
                                                <th width="25%">Name</th>
                                                <th width="25%">Street</th>
                                                <th width="25%">City</th>
                                                <th width="25%">Property ID</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.integrationPropertyList.map((data, key) => {
                                                return (
                                                    <tr key={key} onClick={() => this.selectIntegrationProperty(data)} className="c-pointer">
                                                        <td className="">
                                                            {data.name}
                                                        </td>
                                                        <td className="">
                                                            {data.street1}
                                                        </td>
                                                        <td className="">
                                                            {data.city}
                                                        </td>
                                                        <td className="">
                                                            {data.externalId}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                }

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-6 text-left">
                                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $('#property').modal('show')}>Back</button>
                                        </div>
                                        <div className="col-6 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">Search</button>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

export default Property;