import React from 'react';
import { Link } from 'react-router-dom';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import partners from '../media/img/about/partners.png';
import careers from '../media/img/about/careers.png';
import team from '../media/img/about/team.png';
import rentmoola from '../media/img/logos/rentmoola-w.png';
import Letus from "./common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import livingRoom from "../media/img/backgrounds/living-room.jpg";

class Home extends Letus {

    /**
     * Render the component.
     *
     * @returns {*} - A public-facing static component that displays the about us page.
     */
    render() {
        const {formatMessage} = this.props.intl;
        return(
            <div className="content-block">

                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${livingRoom}') no-repeat center center scroll`}}>
                    <div className="container text-md-center">
                        <div className="row justify-content-md-center">
                            <div className="col-8">
                                <h1 className="display-5 pt-3 text-white">
                                    The evolution of <img src={rentmoola} className="img-fluid pl-1" width="217" alt="Letus" />
                                </h1>
                                <p className="lead text-white">
                                    Letus is the industry-leading rent payment platform with a suite of property management tools to create a better rental experience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h3 className="display-5 mb-4">
                                <FormattedMessage id="static.about.team.heading" />
                            </h3>
                            <p className="lead mb-4">
                                <FormattedMessage id="static.about.team.body" />
                            </p>
                            <a href="https://rentmoola.com/team" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-md">
                                <FormattedMessage id="static.about.team.button" /> <FontAwesomeIcon icon={['far', 'angle-double-right']} className="fa-fw va-b" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <img src={team} alt={formatMessage({id: "static.about.team.alt"})} className="img-sm-fluid" width="140%" />
                        </div>
                    </div>

                    <hr className="my-5" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h3 className="display-5 mb-4">
                                <FormattedMessage id="static.about.partners.heading" />
                            </h3>
                            <p className="lead mb-4">
                                <FormattedMessage id="static.about.partners.body" />
                            </p>
                            <a href="https://rentmoola.com/partners" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-md">
                                <FormattedMessage id="static.about.partners.button" /> <FontAwesomeIcon icon={['far', 'angle-double-right']} className="fa-fw va-b" />
                            </a>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={partners} alt={formatMessage({id: "static.about.partners.alt"})} className="img-sm-fluid float-right" width="140%" />
                        </div>
                    </div>

                    <hr className="my-5" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h3 className="display-5 mb-4">
                                <FormattedMessage id="static.about.careers.heading" />
                            </h3>
                            <p className="lead mb-4">
                                <FormattedMessage id="static.about.careers.body" />
                            </p>
                            <a href="https://rentmoola.com/careers" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-md">
                                <FormattedMessage id="static.about.careers.button" /> <FontAwesomeIcon icon={['far', 'angle-double-right']} className="fa-fw va-b" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <img src={careers} alt={formatMessage({id: "static.about.careers.alt"})} className="img-sm-fluid" />
                        </div>
                    </div>

                </div>

                <div className="jumbotron jumbotron-fluid mb-0">
                    <div className="container">

                        <div className="text-center">
                            <h3 className="display-5 mb-4">
                                Learn more about Letus
                            </h3>
                        </div>

                        <div className="row justify-content-sm-center">
                            <div className="col-md-6 text-center">

                                <p className="">
                                    The world-class Letus support team is here to assist you with any questions, suggestions, concerns and feedback you may have.
                                </p>

                                <p className="">
                                    Whether you leave us a message, give us a call, or chat with us live, we'll be happy to assist you.
                                </p>

                                <div className="text-center">
                                    <Link to="/contact" className="btn btn-lg btn-primary mt-3">
                                        Contact Us
                                    </Link>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    };
}

Home.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Home);