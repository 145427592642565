import React from 'react';
import ButtonBack from "../../common/ButtonBack";
import Propertii from "../../common/Propertii";
import ImportList from "../../common/ImportList";
import Breadcrumb from "../../common/Breadcrumb";

class Imports extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The import interface.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="Imports" />

                    <div className="card card-primary border-primary">
                        <div className="card-body">
                            <p className="card-text">
                                The imports below are limited to system-related records such as BIN mappings and bank information records. Tools to import company-specific information such as pre-authorized debit (PAD) records or properties are available under the import tool when viewing a company.
                            </p>
                        </div>
                    </div>

                    <ImportList token={this.props.token} companyId="RENTMOOLA" searchFilters={true} importModel="FILE_IMPORT" createMessage="admin.system.imports.created" />

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default Imports;