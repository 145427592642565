import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Table from "../../common/Table";
import {injectIntl, intlShape} from "react-intl";
import FieldText from "../../common/FieldText";
import Propertii from "../../common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FieldTextarea from "../../common/FieldTextarea";
import $ from "jquery";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import Moment from "react-moment";

class Notes extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            propertyLease: {},
            customer: {},
            customerNote: {},
            customerNoteList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },
            customerNoteQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [],
            },
            validationList: [],
        };

        this.searchCustomerNotes = this.searchCustomerNotes.bind(this);
        this.editCustomerNote = this.editCustomerNote.bind(this);
        this.initCustomerNote = this.initCustomerNote.bind(this);
        this.saveCustomerNote = this.saveCustomerNote.bind(this);
    }

    /**
     * On mounting of the component, fetch the list of customer notes.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property_lease/${this.props.match.params.propertyLeaseId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let propertyLease = response.data;

            axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${response.data.userId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    customer: response.data,
                    propertyLease: propertyLease,
                    customerNoteQuery: {
                        ...prevState.customerNoteQuery,
                        conditionList: [
                            {
                                type: 'STRING',
                                logicalOperator: 'AND',
                                openBrackets: null,
                                closeBrackets: null,
                                operator: 'EQUALS',
                                fieldName: 'customerId',
                                fieldValue: response.data.id
                            }
                        ]
                    },
                }));

                this.searchCustomerNotes(1, 25, this.state.customerNoteQuery);

            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Initialize a new instance of a customer note.
     */
    initCustomerNote() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/customer_note/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                customerNote: {
                    ...response.data,
                    customerId: this.state.customer.id
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Edit an existing customer note.
     *
     * @param customerNoteId - The ID of the customer note selected for editing.
     */
    editCustomerNote(customerNoteId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/customer_note/${customerNoteId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                customerNote: response.data,
            }));

            $('#customer-note').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle saving an existing customer note or creating a new customer note.
     *
     * @param event - The event container.
     */
    saveCustomerNote(event) {

        event.preventDefault();

        let customerNote = this.state.customerNote;

        if(customerNote.createDate == null) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, customerNote, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.tenants.notes.created'
                        }
                    }],
                });

                this.searchCustomerNotes(this.state.customerNoteList.page, this.state.customerNoteList.recordsPerPage, this.state.customerNoteQuery);

                $('#customer-note').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, customerNote, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved'
                        }
                    }],
                });

                this.searchCustomerNotes(this.state.customerNoteList.page, this.state.customerNoteList.recordsPerPage, this.state.customerNoteQuery);

                $('#customer-note').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Search for a list of customer notes.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchCustomerNotes(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/customer_note/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                customerNoteList: response.data,
                customerNoteQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The customer notes component.
     */
    render() {

        return (
            <React.Fragment>

                <Alert validationList={this.state.validationList} validationType="primary"/>

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Notes
                            </div>
                            <div className="col text-right">
                                <div data-toggle="modal" data-target="#customer-note" className="btn btn-primary btn-sm" onClick={() => this.initCustomerNote()}>
                                    Create Note
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table columns={{createDate: 'Date', createdBy: 'Created By', ticketNumber: 'Ticket', note: 'Note'}}
                           columnWidths={['10%', '10%', '10%', '25%']}
                           headerClass="c-pointer"
                           data={this.state.customerNoteList}
                           query={this.state.customerNoteQuery}
                           sortEnabled={true}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.searchCustomerNotes}>
                        <tbody>
                        {this.state.customerNoteList.records.map((data, key) => {
                            return (
                                <tr key={key} onClick={() => this.editCustomerNote(data.id)} className="c-pointer">
                                    <td className="">
                                        {data.createDate &&
                                        <Moment format="MMM DD, YYYY">
                                            {data.createDate}
                                        </Moment>
                                        }
                                    </td>
                                    <td className="">
                                        <div className="">
                                            {data.createdBy}
                                        </div>
                                    </td>
                                    <td className="">
                                        <div className="">
                                            <a href={`https://rentmoola.zendesk.com/agent/tickets/${data.ticketNumber}`} target="_blank" rel="noopener noreferrer">
                                                {data.ticketNumber}
                                            </a>
                                        </div>
                                    </td>
                                    <td className="">
                                        <div className="">
                                            {data.note}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="customer-note" tabIndex="-1" role="dialog" aria-labelledby="customer-note-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveCustomerNote}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="customer-note-label">
                                        {this.state.customerNote['createDate'] == null &&
                                        <div className="">
                                            Create Note
                                        </div>
                                        }
                                        {this.state.customerNote['createDate'] != null &&
                                        <div className="">
                                            Edit Note
                                        </div>
                                        }
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                    <FieldText id="ticketNumber" label="Ticket Number" model="customerNote" parent={this} value={this.state.customerNote['ticketNumber']} />

                                    <FieldTextarea id="note" rows="8" label="Note" model="customerNote" parent={this} value={this.state.customerNote['note']} />

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#customer-note").modal("hide")}>Close</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonSave />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

Notes.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Notes);