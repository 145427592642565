import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import Propertii from "../../common/Propertii";
import FieldText from "../../common/FieldText";
import FieldPhone from "../../common/FieldPhone";
import FieldProperties from "../../common/FieldProperties";
import FieldCheckbox from "../../common/FieldCheckbox";
import {injectIntl, intlShape} from "react-intl";

class Manager extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            manager: {},

            propertyList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            managedPropertyList: [],

            validationList: [],

        };

        this.saveManager = this.saveManager.bind(this);
        this.handleChangeManagedProperties = this.handleChangeManagedProperties.bind(this);
    }

    /**
     * Fetch the manager on mounting of the component.
     */
    componentDidMount() {

        const {formatMessage} = this.props.intl;

        axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/${this.props.match.params.managerId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                manager: response.data
            }));

            axios.post(`${constants.REACT_APP_HOST_API_URL}/property/search`, {
                orderBy: 'ASC',
                orderByFields: ['street1'],
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                let propertyList = response.data.records;

                this.setState(prevState => ({
                    ...prevState,
                    propertyList: propertyList
                }));

                axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/${this.state.manager.id}/properties`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    let managedPropertyList = [];

                    response.data.records.forEach(function(managedProperty) {
                        propertyList.forEach((property, key) => {
                            if(property.id === managedProperty.id) {
                                managedPropertyList.push({
                                    value: property.id,
                                    label: (property.street1) + (property.street2 ? ', ' + property.street2 : '') + ', ' + (property.city) + ', ' + (property.country === 'CA' ? formatMessage({id: "province." + property.province}) : formatMessage({id: "state." + property.province})) + ', ' + (formatMessage({id: "country." + property.country})) + ' ' + (property.postalCode)
                                })
                            }
                        });
                    });

                    this.setState(prevState => ({
                        ...prevState,
                        managedPropertyList: managedPropertyList
                    }));

                }).catch(error => {
                    console.error(error);
                });

            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle the submission of the form.
     *
     * @param event - The event container.
     */
    saveManager(event) {

        event.preventDefault();

        let managedPropertyList = [];

        this.state.managedPropertyList.forEach((managedProperty, key) => {
            managedPropertyList.push(managedProperty.value);
        });

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, this.state.manager, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            axios.post(`${constants.REACT_APP_HOST_API_URL}/manager/${this.state.manager.id}/properties`, managedPropertyList, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved'
                        }
                    }],
                });

                this.props.refreshManager(this.props.match.params.managerId);

            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle changes to the managed properties field.
     *
     * @param selectedOptions - The list of selected managed properties.
     */
    handleChangeManagedProperties(selectedOptions) {

        let managedPropertyList = this.state.managedPropertyList;

        if(selectedOptions == null) {
            selectedOptions = [];
        }

        if(selectedOptions.length === 0) {
            managedPropertyList =  [];
        }

        if(selectedOptions.length > 0) {

            managedPropertyList = [];

            selectedOptions.forEach((managedProperty, key) => {
                managedPropertyList.push({
                    value: managedProperty.value,
                    label: managedProperty.label
                });
            });
        }

        this.setState(prevState => ({
            ...prevState,
            managedPropertyList: managedPropertyList,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The manager profile management interface for managers.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.saveManager}>

                    <div className="card">
                        <div className="card-header">
                            Manager Details
                        </div>
                        <div className="card-body">

                            <FieldText id="firstName" label="First Name" model="manager" parent={this} value={this.state.manager['firstName']} />

                            <FieldText id="lastName" label="Last Name" model="manager" parent={this} value={this.state.manager['lastName']} />

                            <FieldText id="email" label="Email" type="email" model="manager" parent={this} value={this.state.manager['email']} />

                            <FieldPhone id="phone" label="Phone" model="manager" parent={this} value={this.state.manager['phone']} optional={true} />

                            <FieldCheckbox id="admin" label="Administrator" fieldLabel="This manager is an administrator" help="Administrators have access to all properties, managers, and company settings." model="manager" parent={this} value={this.state.manager['admin']}/>

                            {!this.state.manager.admin &&
                            <FieldCheckbox id="accessAllProperties" label="Oversight" disabled={this.state.manager.admin} fieldLabel="This manager has access to all properties by default" help="If unchecked, you must select which properties this manager has access to." model="manager" parent={this} value={this.state.manager['accessAllProperties']}/>
                            }

                            {((this.state.propertyList.length > 0) && (!this.state.manager.admin && !this.state.manager.accessAllProperties)) &&
                            <FieldProperties id="managedPropertyList" label="Managed Properties" labelClass="col-form-label-sm align-self-center" value={this.state.managedPropertyList} parent={this} options={this.state.propertyList} handleChange={this.handleChangeManagedProperties}/>
                            }

                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonSave />

                        </div>
                    </div>

                </form>

            </React.Fragment>
        )
    };
}

Manager.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Manager);