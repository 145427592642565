import React, { Component } from 'react';
import FieldSelect from "../common/FieldSelect";

class FieldTimezone extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A select field component, specialized for timezones.
     */
    render() {

        return(
            <FieldSelect id={this.props.id} label={this.props.label} model={this.props.model} parent={this.props.parent} value={this.props.value}>
                <option value="">Select a timezone...</option>
                <option value="US/Aleutian">(UTC-10:00) Aleutian Islands</option>
                <option value="US/Hawaii">(UTC-10:00) Hawaii</option>
                <option value="Pacific/Marquesas">(UTC-09:30) Marquesas Islands</option>
                <option value="US/Alaska">(UTC-09:00) Alaska</option>
                <option value="Mexico/BajaSur">(UTC-08:00) Baja California</option>
                <option value="Canada/Pacific">(UTC-08:00) Pacific Time (US & Canada)</option>
                <option value="US/Arizona">(UTC-07:00) Arizona</option>
                <option value="America/Chihuahua">(UTC-07:00) Chihuahua, La Paz, Mazatlan</option>
                <option value="Canada/Mountain">(UTC-07:00) Mountain Time (US & Canada)</option>
                <option value="Canada/Central">(UTC-06:00) Central Time (US & Canada)</option>
                <option value="Pacific/Easter">(UTC-06:00) Easter Island</option>
                <option value="America/Mexico_City">(UTC-06:00) Guadalajara, Mexico City, Monterrey</option>
                <option value="Canada/Saskatchewan">(UTC-06:00) Saskatchewan</option>
                <option value="America/Bogota">(UTC-05:00) Bogota, Lima, Quito, Rio Branco</option>
                <option value="Canada/Eastern">(UTC-05:00) Eastern Time (US & Canada)</option>
                <option value="America/Havana">(UTC-05:00) Havana</option>
                <option value="US/East-Indiana">(UTC-05:00) Indiana (East)</option>
                <option value="America/Asuncion">(UTC-04:00) Asuncion</option>
                <option value="Canada/Atlantic">(UTC-04:00) Atlantic Time (Canada)</option>
                <option value="America/Caracas">(UTC-04:00) Caracas</option>
                <option value="America/Cuiaba">(UTC-04:00) Cuiaba</option>
                <option value="America/La_Paz">(UTC-04:00) Georgetown, La Paz, Manaus, San Juan</option>
                <option value="America/Santiago">(UTC-04:00) Santiago</option>
                <option value="America/St_Johns">(UTC-03:30) Newfoundland</option>
                <option value="America/Araguaina">(UTC-03:00) Araguaina</option>
                <option value="America/Sao_Paulo">(UTC-03:00) Brasilia</option>
                <option value="America/Cayenne">(UTC-03:00) Cayenne, Fortaleza</option>
                <option value="America/Buenos_Aires">(UTC-03:00) City of Buenos Aires</option>
                <option value="America/Godthab">(UTC-03:00) Greenland</option>
                <option value="America/Montevideo">(UTC-03:00) Montevideo</option>
                <option value="America/Punta_Arenas">(UTC-03:00) Punta Arenas</option>
                <option value="America/Miquelon">(UTC-03:00) Saint Pierre and Miquelon</option>
                <option value="America/Salvador">(UTC-03:00) Salvador</option>
                <option value="Atlantic/Azores">(UTC-01:00) Azores</option>
                <option value="Atlantic/Cape_Verde">(UTC-01:00) Cape Verde Is.</option>
                <option value="UTC">(UTC) Coordinated Universal Time</option>
                <option value="Africa/Casablanca">(UTC+00:00) Casablanca</option>
                <option value="Europe/Dublin">(UTC+00:00) Dublin, Edinburgh, Lisbon, London</option>
                <option value="Africa/Monrovia">(UTC+00:00) Monrovia, Reykjavik</option>
                <option value="Africa/Sao_Time">(UTC+00:00) Sao Tome</option>
                <option value="Europe/Amsterdam">(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                <option value="Europe/Belgrade">(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                <option value="Europe/Brussels">(UTC+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                <option value="Europe/Sarajevo">(UTC+01:00) Sarajevo, Skopje, Warsaw, Zegreb</option>
                <option value="Asia/Amman">(UTC+02:00) Amman</option>
                <option value="Europe/Athens">(UTC+02:00) Athens, Bucharest</option>
                <option value="Asia/Beirut">(UTC+02:00) Beirut</option>
                <option value="Africa/Baghdad">(UTC+02:00) Cairo</option>
                <option value="Europe/Chisinau">(UTC+02:00) Chisinau</option>
                <option value="Asia/Damascus">(UTC+02:00) Damascus</option>
                <option value="Asia/Gaza">(UTC+02:00) Gaza, Hebron</option>
                <option value="Africa/Harare">(UTC+02:00) Harare, Pretoria</option>
                <option value="Europe/Helsinki">(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                <option value="Asia/Jerusalem">(UTC+02:00) Jerusalem</option>
                <option value="Europe/Kaliningrad">(UTC+02:00) Kaliningrad</option>
                <option value="Africa/Khartoum">(UTC+02:00) Khartoum</option>
                <option value="Africa/Tripoli">(UTC+02:00) Tripoli</option>
                <option value="Africa/Windhoek">(UTC+02:00) Windhoek</option>
                <option value="Asia/Baghdad">(UTC+03:00) Baghdad</option>
                <option value="Asia/Istanbul">(UTC+03:00) Istanbul</option>
                <option value="Asia/Kuwait">(UTC+03:00) Kuwait, Riyadh</option>
                <option value="Europe/Minsk">(UTC+03:00) Minsk</option>
                <option value="Europe/Mscow">(UTC+03:00) Moscow, St. Petersburg, Volgograd</option>
                <option value="Africa/Nairobi">(UTC+03:00) Nairobi</option>
                <option value="Asia/Tehran">(UTC+03:30) Tehran</option>
                <option value="Asia/Muscat">(UTC+04:00) Abu Dhabi, Muscat</option>
                <option value="Europe/Astrakhan">(UTC+04:00) Astrakhan, Ulynaovsk</option>
                <option value="Asia/Baku">(UTC+04:00) Baku</option>
                <option value="Europe/Samara">(UTC+04:00) Izhevsk, Samara</option>
                <option value="Europe/Saratov">(UTC+04:00) Saratov</option>
                <option value="Asia/Tbilisi">(UTC+04:00) Tbilisi</option>
                <option value="Europe/Volgograd">(UTC+04:00) Volgograd</option>
                <option value="Asia/Yerevan">(UTC+04:00) Yerevan</option>
                <option value="Asia/Kabul">(UTC+04:30) Kabul</option>
                <option value="Asia/Ashgabat">(UTC+05:00) Ashgabat, Tashkent</option>
                <option value="Asia/Yekaterinburg">(UTC+05:00) Yekaterinburg</option>
                <option value="Asia/Karachi">(UTC+05:00) Islamabad, Karachi</option>
                <option value="Asia/Qyzylorda">(UTC+05:00) Qyzylorda</option>
                <option value="Asia/Kolkata">(UTC+05:30) Chennai, Kolkata, Mumbai, New Dehli</option>
                <option value="Asia/Jayapura">(UTC+05:30) Sri Jayawardenepura</option>
                <option value="Asia/Kathmandu">(UTC+05:45) Kathmandu</option>
                <option value="Asia/Dhaka">(UTC+06:00) Dhaka</option>
                <option value="Asia/Omsk">(UTC+06:00) Omsk</option>
                <option value="Asia/Yangon">(UTC+06:30) Yangon (Rangoon)</option>
                <option value="Asia/Bangkok">(UTC+07:00) Bangkok, Hanoi, Jakarta</option>
                <option value="Asia/Barnaul">(UTC+07:00) Barnaul, Gorno-Altaysk</option>
                <option value="Asia/Hovd">(UTC+07:00) Hovd</option>
                <option value="Asia/Krasnoyarsk">(UTC+07:00) Krasnoyarsk</option>
                <option value="Asia/Novosibirsk">(UTC+07:00) Novosibirsk</option>
                <option value="Asia/Tomsk">(UTC+07:00) Tomsk</option>
                <option value="Asia/Hong_Kong">(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                <option value="Asia/Irkutsk">(UTC+08:00) Irkutsk</option>
                <option value="Asia/Singapore">(UTC+08:00) Kuala Lumpur, Singapore</option>
                <option value="Australia/Perth">(UTC+08:00) Perth</option>
                <option value="Asia/Taipei">(UTC+08:00) Taipei</option>
                <option value="Asia/Ulaanbaatar">(UTC+08:00) Ulaanbaatar</option>
                <option value="Australia/Eucla">(UTC+08:45) Eucla</option>
                <option value="Asia/Chita">(UTC+09:00) Chita</option>
                <option value="Asia/Tokyo">(UTC+09:00) Osaka, Sapporo, Tokyo</option>
                <option value="Asia/Pyongyang">(UTC+09:00) Pyongyang</option>
                <option value="Asia/Seoul">(UTC+09:00) Seoul</option>
                <option value="Asia/Yakutsk">(UTC+09:00) Yakutsk</option>
                <option value="Australia/Adelaide">(UTC+09:30) Adelaide</option>
                <option value="Australia/Darwin">(UTC+09:30) Darwin</option>
                <option value="Australia/Brisbane">(UTC+10:00) Brisbane</option>
                <option value="Australia/Sydney">(UTC+10:00) Canberra, Melbourne, Sydney</option>
                <option value="Pacific/Guam">(UTC+10:00) Guam, Port Moresby</option>
                <option value="Australia/Hobart">(UTC+10:00) Hobart</option>
                <option value="Asia/Vladivostok">(UTC+10:00) Vladivostok</option>
                <option value="Australia/Lord_Howe">(UTC+10:30) Lord Howe Island</option>
                <option value="Pacific/Bougainville">(UTC+11:00) Bougainville Island</option>
                <option value="Asia/Magadan">(UTC+11:00) Magadan</option>
                <option value="Pacific/Norfolk">(UTC+11:00) Norfolk Island</option>
                <option value="Asia/Sakhalin">(UTC+11:00) Sakhalin</option>
                <option value="Pacific/Guadalcanal">(UTC+11:00) Solomon Is., New Caledonia</option>
                <option value="Asia/Anadyr">(UTC+12:00) Anadyr, Petropavlosk-Kamchatsky</option>
                <option value="Pacific/Auckland">(UTC+12:00) Auckland, Wellington</option>
                <option value="Pacific/Fiji">(UTC+12:00) Fiji</option>
                <option value="Pacific/Chatham">(UTC+12:45) Chatham Islands</option>
                <option value="Pacific/Samoa">(UTC+13:00) Samoa</option>
                <option value="Pacific/Kiritimati">(UTC+14:00) Kiritimati Island</option>
            </FieldSelect>
        )
    };
}

export default FieldTimezone;
