import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {injectIntl, intlShape} from "react-intl";

class FieldLineItems extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.addLineItem = this.addLineItem.bind(this);
        this.removeLineItem = this.removeLineItem.bind(this);

        this.handleChangeLineItems = this.handleChangeLineItems.bind(this);
    }

    /**
     * Add a line item to the list of line items.
     */
    addLineItem() {

        let lineItems = this.props.parent.state[this.props.model];

        lineItems.push({
            order: '',
            unitPrice: 0.00,
            quantity: 1.00,
            description: '',
            tax1Rate: 0.00,
            tax2Rate: 0.00,
        });

        this.props.parent.setState(prevState => ({
            ...prevState,
            [this.props.model]: lineItems
        }));
    }

    /**
     * Remove a line item from the list of line items.
     *
     * @param index - The index of the line items to remove from the list of line items.
     */
    removeLineItem(index) {

        let lineItems = this.props.parent.state[this.props.model];

        lineItems.splice(index, 1);

        this.props.parent.setState(prevState => ({
            ...prevState,
            [this.props.model]: lineItems
        }));
    }

    /**
     * Handle changes to the selected line items. Handles any changes made to any value of each line item, passing the
     * updated list back to the parent component's state.
     *
     * @param event - The event container.
     */
    handleChangeLineItems(event) {

        let lineItems = this.props.parent.state[this.props.model];

        let lineItemIdSplit = (event.target.name).split("-");
        let lineItemIdIndex = lineItemIdSplit[lineItemIdSplit.length - 1];

        // Handle description changes
        if(lineItemIdSplit[1] === 'description') {
            lineItems[lineItemIdIndex] = {
                description: event.target.value,
                unitPrice: lineItems[lineItemIdIndex].unitPrice,
                quantity: lineItems[lineItemIdIndex].quantity,
                tax1Rate: lineItems[lineItemIdIndex].tax1Rate,
                tax2Rate: lineItems[lineItemIdIndex].tax2Rate,
            };
        }

        // Handle unit price changes
        if(lineItemIdSplit[1] === 'unitPrice') {
            lineItems[lineItemIdIndex] = {
                description: lineItems[lineItemIdIndex].description,
                unitPrice: event.target.value,
                quantity: lineItems[lineItemIdIndex].quantity,
                tax1Rate: lineItems[lineItemIdIndex].tax1Rate,
                tax2Rate: lineItems[lineItemIdIndex].tax2Rate,
            };
        }

        // Handle quantity changes
        if(lineItemIdSplit[1] === 'quantity') {
            lineItems[lineItemIdIndex] = {
                description: lineItems[lineItemIdIndex].description,
                unitPrice: lineItems[lineItemIdIndex].unitPrice,
                quantity: event.target.value,
                tax1Rate: lineItems[lineItemIdIndex].tax1Rate,
                tax2Rate: lineItems[lineItemIdIndex].tax2Rate,
            };
        }

        // Handle tax 1 rate changes
        if(lineItemIdSplit[1] === 'tax1Rate') {
            lineItems[lineItemIdIndex] = {
                description: lineItems[lineItemIdIndex].description,
                unitPrice: lineItems[lineItemIdIndex].unitPrice,
                quantity: lineItems[lineItemIdIndex].quantity,
                tax1Rate: event.target.value,
                tax2Rate: lineItems[lineItemIdIndex].tax2Rate,
            };
        }

        // Handle tax 2 rate changes
        if(lineItemIdSplit[1] === 'tax2Rate') {
            lineItems[lineItemIdIndex] = {
                description: lineItems[lineItemIdIndex].description,
                unitPrice: lineItems[lineItemIdIndex].unitPrice,
                quantity: lineItems[lineItemIdIndex].quantity,
                tax1Rate: lineItems[lineItemIdIndex].tax1Rate,
                tax2Rate: event.target.value,
            };
        }

        this.props.parent.setState(prevState => ({
            ...prevState,
            [this.props.model]: lineItems
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A component that allows the user to enter a list of line items, passing the list of line items to
     * the parent component's state.
     */
    render() {

        return(
            <React.Fragment>

                {this.props.lineItems.map((lineItem, key) => {

                    return(
                        <div key={key} className="form-group row mb-2">
                            <div className="col-sm-12">
                                <div className={`input-group input-group-${this.props.size}`}>

                                    <input id={`item-description-${key}`} name={`item-description-${key}`} value={lineItem.description || ''} type="text" className={`form-control mb-0`} disabled={this.props.disabled} onChange={this.handleChangeLineItems} />

                                    <input id={`item-unitPrice-${key}`} name={`item-unitPrice-${key}`} value={lineItem.unitPrice || 0.00} type="number" min="0.00" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" className={`form-control mb-0`} disabled={this.props.disabled} onChange={this.handleChangeLineItems} />

                                    <input id={`item-quantity-${key}`} name={`item-quantity-${key}`} value={lineItem.quantity || 0.00} type="number" min="0.00" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" className={`form-control mb-0`} disabled={this.props.disabled} onChange={this.handleChangeLineItems} />

                                    <input id={`item-tax1Rate-${key}`} name={`item-tax1Rate-${key}`} value={lineItem.tax1Rate || 0.00} type="number" min="0.00" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" className={`form-control mb-0`} disabled={this.props.disabled} onChange={this.handleChangeLineItems} />

                                    <input id={`item-tax2Rate-${key}`} name={`item-tax2Rate-${key}`} value={lineItem.tax2Rate || 0.00} type="number" min="0.00" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" className={`form-control mb-0`} disabled={this.props.disabled} onChange={this.handleChangeLineItems} />

                                    {!this.props.disabled &&
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-primary" type="button" onClick={() => this.removeLineItem(key)}>
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw" />
                                        </button>
                                    </div>
                                    }

                                </div>

                            </div>
                        </div>
                    );
                })}

                {!this.props.disabled &&
                <div className={`btn btn-outline-primary btn-sm ${this.props.lineItems.length > 0 ? 'mt-2' : 'mt-0'}`} onClick={() => this.addLineItem()}>
                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw"/> Add line item
                </div>
                }

            </React.Fragment>
        )
    };
}

FieldLineItems.propTypes = {
    intl: intlShape.isRequired,
};

FieldLineItems.defaultProps = {
    size: 'md'
};

export default injectIntl(FieldLineItems);
