import React from 'react';
import Alert from "../common/Alert";
import Propertii from "../common/Propertii";
import {FormattedNumber, injectIntl, intlShape} from "react-intl";
import axios from "axios";
import * as constants from "../../util/constants";
import Spinner from "../common/Spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import certn from "../../media/img/logos/certn.png";
import $ from "jquery";

class Screenings extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            serviceFee: {},
            basicScreeningDetails: null,
            creditScreeningDetails: null,
            validationList: [],
        };

        this.initScreening = this.initScreening.bind(this);
        this.searchScreenings = this.searchScreenings.bind(this);
        this.getScreeningFee = this.getScreeningFee.bind(this);
        this.getScreeningReport = this.getScreeningReport.bind(this);
    }

    /**
     * On mounting of the component, perform a search for BASIC and CREDIT screenings.
     */
    componentDidMount() {

        this.searchScreenings('BASIC');
        this.searchScreenings('CREDIT');
    }

    /**
     * Search for screening results by screening type.
     *
     * @param screenType - The type of screening (BASIC, CREDIT, etc.)
     */
    searchScreenings(screenType) {

        let screeningQuery = {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'leaseApplicationId',
                    operator: 'EQUALS',
                    fieldValue: this.props.leaseApplicationId
                },
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'screenType',
                    operator: 'EQUALS',
                    fieldValue: screenType
                }
            ],
        };

        axios.post(`${constants.REACT_APP_HOST_API_URL}/screening/search`, {
            orderBy: screeningQuery.orderBy,
            orderByFields: screeningQuery.orderByFields,
            conditionList: screeningQuery.conditionList,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.records.length > 0) {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: true
                }));

                axios.get(`${constants.REACT_APP_HOST_API_URL}/screening/${response.data.records[0].id}/details`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    if (screenType === 'BASIC') {
                        this.setState(prevState => ({
                            ...prevState,
                            spinner: false,
                            basicScreeningDetails: response.data
                        }));
                    }

                    if (screenType === 'CREDIT') {
                        this.setState(prevState => ({
                            ...prevState,
                            spinner: false,
                            creditScreeningDetails: response.data
                        }));
                    }

                }).catch(error => {
                    console.error(error);
                });
            }

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Fetch the fee for a particular screening type from the invoicing service.
     *
     * @param screenType - The type of screening to fetch the fee for.
     */
    getScreeningFee(screenType) {

        axios.get(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/billing/fees/code/${screenType}?companyId=${this.props.companyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                serviceFee: response.data
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Fetch a detailed screening report.
     *
     * @param screeningId - The ID of the screening to fetch a report for.
     */
    getScreeningReport(screeningId) {

        this.setState(prevState => ({
            spinner: true
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/screening/${screeningId}/report`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                spinner: false
            }));

            window.open(response.data.report_url);

        }).catch(error => {
            this.handleValidation(error);
        });

    }

    /**
     * Initialize a new screening on the lease application.
     *
     * @param screenType - The type of screening to perform, such as a background or credit check.
     */
    initScreening(screenType) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/screen`, {
            leaseApplicationId: this.props.leaseApplicationId,
            screenType: screenType
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.screening.' + screenType.toLowerCase() + '.created'
                    },
                }],
            }));

            this.searchScreenings(screenType);

            $('#screening-basic').modal('hide');
            $('#screening-credit').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The screening component.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <div className="card">

                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Background Check
                            </div>
                            <div className="col text-right">
                                {(this.state.basicScreeningDetails && this.state.basicScreeningDetails.status !== 'INPROCESS') &&
                                <div className="btn btn-primary btn-sm" data-dismiss="modal" onClick={() => this.getScreeningReport(this.state.basicScreeningDetails.requestId)}>
                                    View Detailed Report
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                    {!this.state.basicScreeningDetails &&
                    <div className="card-body text-center">

                        <p className="pt-3 pb-1">This applicant has not undergone a background check.</p>

                        <div className="btn btn-primary btn-md mb-4" data-toggle="modal" data-target="#screening-basic" onClick={() => this.getScreeningFee('SCREENING_BASIC')}>
                            Initiate Background Check
                        </div>

                    </div>
                    }

                    {(this.state.basicScreeningDetails && this.state.basicScreeningDetails.status === 'INPROCESS') &&
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="text-center text-muted">
                                   ` <FontAwesomeIcon icon={['fas', 'spinner']} className="fa-fw fa-spin my-4" size="3x" />`
                                </div>
                                <div className="text-center text-muted mb-3">
                                    <small>
                                        This background check is in progress. Check back soon for detailed results.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {(this.state.basicScreeningDetails && this.state.basicScreeningDetails.status !== 'INPROCESS') &&
                    <div className="card-body card-body-table">

                        <table className="table table-bordered table-striped">
                            <thead>
                            <tr>
                                <th colSpan="2">
                                    Results
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td width="75%">
                                    Criminal Scan
                                </td>
                                <td width="25%">
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.criminalScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.basicScreeningDetails.screenResult.criminalScan}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Damage to Property
                                </td>
                                <td>
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.damageToProperty === 'None' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.basicScreeningDetails.screenResult.damageToProperty}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Eviction Potential
                                </td>
                                <td>
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.evictionPotential === 'None' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.basicScreeningDetails.screenResult.evictionPotential}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Fraud Scan
                                </td>
                                <td>
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.fraudScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.basicScreeningDetails.screenResult.fraudScan}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Global Clearance Scan
                                </td>
                                <td>
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.globalClearanceScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.basicScreeningDetails.screenResult.globalClearanceScan}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Global Sanctions Scan
                                </td>
                                <td>
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.globalSanctionsScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.basicScreeningDetails.screenResult.globalSanctionsScan}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Known Affiliations Scan
                                </td>
                                <td>
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.knownAffiliationsScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.basicScreeningDetails.screenResult.knownAffiliationsScan}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Other Scan
                                </td>
                                <td>
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.otherScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.basicScreeningDetails.screenResult.otherScan}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Public Safety Scan
                                </td>
                                <td>
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.publicSafetyScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.basicScreeningDetails.screenResult.publicSafetyScan}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Sex Offender Scan
                                </td>
                                <td>
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.sexOffenderScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.basicScreeningDetails.screenResult.sexOffenderScan}</span>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                    }

                    <div className="card-footer">
                        <div className="row">
                            <div className="col">
                                <small className="text-muted">
                                    Background check reports provided and conducted by Certn.
                                </small>
                            </div>
                            <div className="col-2 text-right">
                                <img src={certn} alt="Background checks powered by Certn." className="img-fluid" />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="card">

                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Credit Check
                            </div>
                            <div className="col text-right">
                                {(this.state.creditScreeningDetails && this.state.creditScreeningDetails.status !== 'INPROCESS') &&
                                <div className="btn btn-primary btn-sm" data-dismiss="modal" onClick={() => this.getScreeningReport(this.state.creditScreeningDetails.requestId)}>
                                    View Detailed Report
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                    {!this.state.creditScreeningDetails &&
                    <div className="card-body text-center">

                        <p className="pt-3 pb-1">This applicant has not undergone a credit check.</p>

                        <div className="btn btn-primary btn-md mb-4" data-toggle="modal" data-target="#screening-credit" onClick={() => this.getScreeningFee('SCREENING_CREDIT')}>
                            Initiate Credit Check
                        </div>

                    </div>
                    }

                    {(this.state.creditScreeningDetails && this.state.creditScreeningDetails.status === 'INPROCESS') &&
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="text-center text-muted">
                                    <FontAwesomeIcon icon={['fas', 'spinner']} className="fa-fw fa-spin my-4" size="3x" />
                                </div>
                                <div className="text-center text-muted mb-3">
                                    <small>
                                        This credit check is in progress. Check back soon for detailed results.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {(this.state.creditScreeningDetails && this.state.creditScreeningDetails.status !== 'INPROCESS') &&
                    <div className="card-body card-body-table">

                        <table className="table table-bordered table-striped">
                            <thead>
                            <tr>
                                <th colSpan="2">
                                    Results
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td width="75%">
                                    Credit Score
                                </td>
                                <td width="25%">
                                    {this.state.creditScreeningDetails.screenResult.creditScore ? this.state.creditScreeningDetails.screenResult.creditScore : 'No Credit Score'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Credit Grade
                                </td>
                                <td>
                                    {this.state.creditScreeningDetails.screenResult.creditScore &&
                                    <React.Fragment>
                                        {this.state.creditScreeningDetails.screenResult.creditScore >= 800 &&
                                        <span className="badge grade-a-plus text-white">A+</span>
                                        }
                                        {(this.state.creditScreeningDetails.screenResult.creditScore <= 799 && this.state.creditScreeningDetails.screenResult.creditScore >= 750) &&
                                        <span className="badge grade-a text-white">A</span>
                                        }
                                        {(this.state.creditScreeningDetails.screenResult.creditScore <= 749 && this.state.creditScreeningDetails.screenResult.creditScore >= 700) &&
                                        <span className="badge grade-b-plus text-white">B+</span>
                                        }
                                        {(this.state.creditScreeningDetails.screenResult.creditScore < 699 && this.state.creditScreeningDetails.screenResult.creditScore >= 650) &&
                                        <span className="badge grade-b text-white">B</span>
                                        }
                                        {(this.state.creditScreeningDetails.screenResult.creditScore < 649 && this.state.creditScreeningDetails.screenResult.creditScore >= 600) &&
                                        <span className="badge grade-c-plus text-white">C+</span>
                                        }
                                        {(this.state.creditScreeningDetails.screenResult.creditScore < 599 && this.state.creditScreeningDetails.screenResult.creditScore >= 550) &&
                                        <span className="badge grade-c text-white">C</span>
                                        }
                                        {(this.state.creditScreeningDetails.screenResult.creditScore < 549 && this.state.creditScreeningDetails.screenResult.creditScore >= 500) &&
                                        <span className="badge grade-d text-white">D</span>
                                        }
                                        {this.state.creditScreeningDetails.screenResult.creditScore < 499 &&
                                        <span className="badge grade-f text-white">F</span>
                                        }
                                    </React.Fragment>
                                    }
                                    {!this.state.creditScreeningDetails.screenResult.creditScore &&
                                    <React.Fragment>
                                        No Credit Score
                                    </React.Fragment>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Late Rent Potential
                                </td>
                                <td>
                                    <div className="text-nowrap">
                                        {this.state.creditScreeningDetails.screenResult.lateRentPotential === 'High' &&
                                        <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-danger" />
                                        }
                                        {this.state.creditScreeningDetails.screenResult.lateRentPotential === 'Medium' &&
                                        <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-warning" />
                                        }
                                        {this.state.creditScreeningDetails.screenResult.lateRentPotential === 'Low' &&
                                        <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-success" />
                                        }
                                        <span className="ml-1">{this.state.creditScreeningDetails.screenResult.lateRentPotential}</span>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                    }

                    <div className="card-footer">
                        <div className="row">
                            <div className="col">
                                <small className="text-muted">
                                    Credit reports provided by Certn and conducted by Equifax.
                                </small>
                            </div>
                            <div className="col-2 text-right">
                                <img src={certn} alt="Credit checks powered by Certn." className="img-fluid" />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="modal fade" id="screening-basic" tabIndex="-1" role="dialog" aria-labelledby="screening-basic-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="screening-basic-label">
                                    Initiate Background Check
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                </button>
                            </div>

                            <div className="modal-body bg-secondary">
                                <p className="mb-0">
                                    To perform this background check, your payment method on file will be charged at the end of your billing cycle for the amount detailed below.
                                </p>
                            </div>

                            <div className="modal-body modal-body-table">
                                <table className="table mb-0">
                                    <tbody>
                                    <tr>
                                        <td className="">
                                            Certn Background Check <span className="small text-muted">(Qty: 1)</span>
                                        </td>
                                        <td className="text-right">
                                            <FormattedNumber value={this.state.serviceFee.feeAmount} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="">
                                            {this.state.serviceFee.taxJurisdiction1} <span className="small text-muted">({this.state.serviceFee.taxRate1}%)</span>
                                        </td>
                                        <td className="text-right">
                                            <FormattedNumber value={(((this.state.serviceFee.taxRate1 / 100) * this.state.serviceFee.feeAmount).toFixed(2))} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">
                                            Total
                                        </td>
                                        <td className="text-right font-weight-bold">
                                            <FormattedNumber value={(parseFloat(this.state.serviceFee.feeAmount) + parseFloat((((this.state.serviceFee.taxRate1 / 100) * this.state.serviceFee.feeAmount).toFixed(2))))} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="modal-footer bg-secondary rounded-bottom d-block">

                                <div className="row">
                                    <div className="col-4">
                                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                    </div>
                                    <div className="col-8 text-right">

                                        <div className="btn btn-primary btn-lg" onClick={() => this.initScreening('BASIC')}>
                                            Agree & Initiate
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="screening-credit" tabIndex="-1" role="dialog" aria-labelledby="screening-credit-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="screening-credit-label">
                                    Initiate Credit Check
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                </button>
                            </div>

                            <div className="modal-body bg-secondary">
                                <p className="mb-0">
                                    To perform this credit check, your payment method on file will be charged at the end of your billing cycle for the amount detailed below.
                                </p>
                            </div>

                            <div className="modal-body modal-body-table">
                                <table className="table mb-0">
                                    <tbody>
                                    <tr>
                                        <td className="">
                                            Equifax Credit Check <span className="small text-muted">(Qty: 1)</span>
                                        </td>
                                        <td className="text-right">
                                            <FormattedNumber value={this.state.serviceFee.feeAmount} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="">
                                            {this.state.serviceFee.taxJurisdiction1} <span className="small text-muted">({this.state.serviceFee.taxRate1}%)</span>
                                        </td>
                                        <td className="text-right">
                                            <FormattedNumber value={(((this.state.serviceFee.taxRate1 / 100) * this.state.serviceFee.feeAmount).toFixed(2))} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">
                                            Total
                                        </td>
                                        <td className="text-right font-weight-bold">
                                            <FormattedNumber value={(parseFloat(this.state.serviceFee.feeAmount) + parseFloat((((this.state.serviceFee.taxRate1 / 100) * this.state.serviceFee.feeAmount).toFixed(2))))} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    
                                    </tbody>
                                </table>
                            </div>

                            <div className="modal-footer bg-secondary rounded-bottom d-block">

                                <div className="row">
                                    <div className="col-4">
                                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                    </div>
                                    <div className="col-8 text-right">

                                        <div className="btn btn-primary btn-lg" onClick={() => this.initScreening('CREDIT')}>
                                            Agree & Initiate
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

Screenings.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Screenings);