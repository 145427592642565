import React from 'react';
import {injectIntl, intlShape} from 'react-intl';
import Banner from "../common/Banner";
import Propertii from "../common/Propertii";
import axios from "axios";
import * as constants from "../../util/constants";
import FieldText from "../common/FieldText";
import Alert from "../common/Alert";
import ButtonSubmit from "../common/ButtonSubmit";
import Modal from "../common/Modal";
import {Link} from 'react-router-dom';
import $ from "jquery";
import GoogleLogin from "react-google-login";
import google from "../../media/img/logos/google.png";

class Admin extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            password: '',
            confirmPassword: '',
            verificationCode: '',
            validationList: [],
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOAuth = this.handleOAuth.bind(this);
        this.setupPassword = this.setupPassword.bind(this);
    }

    /**
     * On mounting of the component, parse the data from the URL params to provide context to the admin completing their
     * account.
     */
    componentDidMount() {

        this.setState(prevState => ({
            ...prevState,
            firstName: this.props.location.state.firstName,
            lastName: this.props.location.state.lastName,
            verificationCode: this.props.location.state.verificationCode
        }));
    }

    /**
     * Handle submission of the invitation flow. Redirects the user to a completion splash page upon successful
     * completion.
     *
     * @param event - The event container.
     */
    handleSubmit(event) {

        event.preventDefault();

        axios.post(`${constants.REACT_APP_HOST_API_URL}/completeaccount`, {
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            verificationCode: this.state.verificationCode
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);

            this.props.refreshAppState();

            $('#onboarding-complete').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }


    /**
     * Handle admins signing up via OAuth. Signing up via OAuth allows the user to skip local system account setup and
     * proceed directly to their dashboard.
     *
     * @param response - The returned OAuth response.
     */
    handleOAuth(response) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/authorizeoauth?accountType=TYPE_GOOGLE_OAUTH_ACCOUNT&authorizationCode=${encodeURIComponent(response.code)}&requestUri=${encodeURIComponent(window.location.origin)}&userType=TYPE_ADMIN&verificationCode=${encodeURIComponent(this.state.verificationCode)}`).then(response => {

            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);

            this.props.refreshAppState();

            $('#onboarding-complete').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Reveal the generic password fields if the user determines that they want to use a traditional password for their
     * Letus account.
     */
    setupPassword() {

        $('.card-credentials').fadeOut(function() {
            $('.card-password').fadeIn();
            $('.button-submit').fadeIn();
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A public-facing component that handles admins completing their account.
     */
    render() {

        return(
            <div className="content-block">

                <Banner backgroundImg="login" heading="static.onboarding.heading" subheading="static.onboarding.subheading" align="left" values={{firstName: this.state.firstName}} />

                <div className="container">

                    <Alert validationList={this.state.validationList} />

                    <form onSubmit={this.handleSubmit}>

                        <div className="card">
                            <div className="card-header">
                                Authorization
                            </div>
                            <div className="card-body card-credentials text-center">

                                <p className="pt-3 pb-1">Click the button below to enable authorization to your Letus account using your assigned Google account.</p>

                                <div className="mb-4">

                                    <GoogleLogin
                                        clientId={constants.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                                        render={renderProps => (
                                            <div className="btn btn-lg btn-outline-primary btn-google mb-0 d-block d-md-inline" onClick={renderProps.onClick}>
                                                <img src={google} alt="Log In with Google" style={{height: '18px'}} className="m-1" /> Log In with Google
                                            </div>
                                        )}
                                        onSuccess={this.handleOAuth}
                                        onFailure={this.handleOAuth}
                                        responseType="code"
                                    />

                                </div>

                            </div>
                            <div className="card-body card-password hidden">

                                <FieldText required={true} id="password" label="Password" type="password" parent={this} value={this.state['password']} />

                                <FieldText required={true} id="confirmPassword" label="Confirm Password" type="password" parent={this} value={this.state['confirmPassword']} />

                            </div>
                        </div>

                        <div className="row">
                            <div className="col text-right">

                                <div className="row">

                                    <div className="col text-right">

                                        <ButtonSubmit customClasses="button-submit ml-2 hidden" />

                                    </div>
                                </div>

                            </div>
                        </div>

                    </form>

                    <Modal id="onboarding-complete" theme="primary" iconType="far" iconName="thumbs-up" title={`Welcome, ${this.state.firstName}`}
                           body="Your administrator account is now complete. You may continue to your dashboard by clicking the button below.">
                        <Link to="/admin/dashboard" className="btn btn-primary btn-lg" onClick={() => $("#onboarding-complete").modal("hide")}>
                            Continue
                        </Link>
                    </Modal>

                </div>

            </div>
        )
    };
}

Admin.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Admin);