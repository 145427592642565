import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import { Link } from 'react-router-dom';
import Letus from "./common/Propertii";
import Spinner from "./common/Spinner";
import womanChair from "../media/img/backgrounds/woman-chair.jpg";
import coupleKitchen from "../media/img/backgrounds/couple-kitchen.jpg";
import propertyManagement from "../media/img/mockups/property-management.png";
import visa from "../media/img/payments/visa.png";
import mastercard from "../media/img/payments/mastercard.png";
import americanExpress from "../media/img/payments/american-express.png";
import discover from "../media/img/payments/discover.png";
import unionPay from "../media/img/payments/unionpay.png";
import bankAccount from "../media/img/payments/bank-account.png";
import paymentFlow from "../media/img/mockups/payment-flow.png";

class Home extends Letus {

    /**
     * Render the component.
     *
     * @returns {*} - The homepage component.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner}/>

                <div id="signup" className="jumbotron jumbotron-fluid content-header content-header-landing mb-0" style={{background: `linear-gradient(100deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.1) 100%) 0% 0% / cover, url('${coupleKitchen}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container">

                        <div className="row align-items-center">
                            <div className="col-md-6 align-self-center">

                                <h1 className="text-white mb-4 display-4">
                                    Open your door to better renting
                                </h1>
                                <p className="lead text-white mb-4">
                                    Letus gives property managers the tools they need to create a better rental experience.
                                </p>

                                <div className="mb-4">
                                    <Link to="/landlords" className="btn btn-primary btn-lg mb-2 mr-2">
                                        I am a Property Manager
                                    </Link>
                                    <Link to="/tenants" className="btn btn-primary btn-lg mb-2">
                                        I am a Tenant
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>

                    </div>
                </div>

                <div className="container">

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h1 className="mb-4">
                                The simple and secure property management solution
                            </h1>
                            <p className="mb-4 lead">
                                Letus has you covered from the moment a lease application is submitted to the day rent is due, and everything in between.
                            </p>
                            <Link to="/landlords" className="btn btn-outline-primary btn-lg mb-2">
                                Learn More
                            </Link>
                        </div>
                        <div className="col-md-6">
                            <img src={propertyManagement} alt="Letus offers a wide variety of simple and secure property management tools for landlords" className="img-sm-fluid" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="screenings" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h1 className="mb-4">
                                Making rent payments rewarding for tenants
                            </h1>
                            <p className="mb-4 lead">
                                Enjoy the flexibility and convenience of paying your rent online, all while building up your credit and earning points on your rewards credit card.
                            </p>
                            <div className="mb-4">
                                <img src={visa} className="rounded img-fluid bg-light border mr-2" alt="Visa" title="Visa" width="50"/>
                                <img src={mastercard} className="rounded img-fluid bg-light border mr-2" alt="Mastercard" title="Mastercard" width="50"/>
                                <img src={americanExpress} className="rounded img-fluid bg-light border mr-2" alt="American Express" title="American Express" width="50"/>
                                <img src={discover} className="rounded img-fluid bg-light border mr-2" alt="Discover" title="Discover" width="50"/>
                                <img src={unionPay} className="rounded img-fluid bg-light border mr-2" alt="UnionPay" title="UnionPay" width="50"/>
                                <img src={bankAccount} className="rounded img-fluid bg-light border mr-2" alt="Bank Account" title="Bank Account" width="50"/>
                            </div>
                            <Link to="/tenants" className="btn btn-outline-primary btn-lg mb-2">
                                Learn More
                            </Link>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={paymentFlow} alt="Letus offers simple and secure online rent payments for tenants" className="img-sm-fluid float-right" width="130%"/>
                        </div>
                    </div>

                </div>

                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${womanChair}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container text-center">
                        <h1 className="pt-4 mb-4 text-white">
                            A better rental experience begins with Letus
                        </h1>
                        <div className="row justify-content-md-center">
                            <div className="col-md-8 text-md-center">
                                <p className="lead text-white">
                                    Trusted by North America's biggest property managers, now available to you. Start by creating your Letus account today.
                                </p>
                            </div>
                        </div>
                        <div className="">
                            <Link to="/landlords" className="btn btn-primary btn-lg mb-4 mr-2">
                                I am a Property Manager
                            </Link>
                            <Link to="/tenants" className="btn btn-primary btn-lg mb-4">
                                I am a Tenant
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

Home.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Home);
