import React from 'react';
import {FormattedMessage} from "react-intl";
import visa from "../../media/img/payments/visa.png";
import mastercard from "../../media/img/payments/mastercard.png";
import americanExpress from "../../media/img/payments/american-express.png";
import discover from "../../media/img/payments/discover.png";
import unionPay from "../../media/img/payments/unionpay.png";
import CardBrandIcon from "./CardBrandIcon";
import axios from "axios";
import * as constants from "../../util/constants";
import Propertii from "./Propertii";

class FieldCardNumber extends Propertii {

    /**
     * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
     * custom handleChange function appears in the parent class, utilize the default handleChange function below.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Handle changes to the card number field when creating a new credit or debit card. Validate the entered card
     * number to determine the brand of the card. Based on the brand of the card, update the label text for the security
     * code field accordingly.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        event.persist();

        let cardNumber;

        if(event.type === 'change') {
            cardNumber = event.target.value;
        }

        if(event.type === 'paste') {
            cardNumber = event.clipboardData.getData('Text');
        }

        this.props.parent.setState(prevState => ({
            ...prevState,
            creditCardSecurityCodeLabel: securityCodeLabel,
            paymentMethod: {
                ...prevState.paymentMethod,
                cardNumber: cardNumber
            }
        }));

        let cardBrand;
        let cardType;
        let securityCodeLabel = this.getCardSecurityCodeLabel(cardNumber);

        if(cardNumber.length >= 6) {

            axios.get(`${constants.REACT_APP_HOST_API_URL}/binlookup/${cardNumber.substr(0, 6)}`, {
                headers: localStorage.getItem('access_token') ? this.generateRequestHeaders() : {'Content-Type': 'application/json'}
            }).then(response => {

                cardBrand = response.data.systemCardBrand;
                cardType = response.data.cardType;

                if(response.data.systemCardBrand !== 'UNION_PAY') {
                    axios.get(`${constants.REACT_APP_HOST_API_URL}/binlookup/UP${cardNumber.substr(0, 6)}`, {
                        headers: localStorage.getItem('access_token') ? this.generateRequestHeaders() : {'Content-Type': 'application/json'}
                    }).then(response => {
                        this.props.parent.setState(({
                            possibleUnionPayBin: true,
                        }));
                    }).catch(error => {
                        console.error(error);
                    });
                }

                this.props.parent.setState(prevState => ({
                    ...prevState,
                    paymentMethod: {
                        ...prevState.paymentMethod,
                        brand: cardBrand,
                        cardType: cardType
                    },
                    validationList: [],
                }));

            }).catch(error => {

                this.props.parent.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        fields: {
                            cardNumber: 'RM_ERROR_CREDIT_CARD_NOT_SUPPORTED'
                        },
                        alert: {
                            type: 'danger',
                            code: 'RM_ERROR_CREDIT_CARD_NOT_SUPPORTED'
                        },
                    }],
                }));

            });
        }
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic credit/debit card number field component.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if(validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if(key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        return(

                <div className="form-group row">

                    <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                        {this.props.label}
                    </label>

                    <div className={`col-sm-${this.props.fieldColumns}`}>

                        <div className="input-group input-group-md">

                            <input id={this.props.id}
                                   name={this.props.id}
                                   type={this.props.type}
                                   maxLength={this.props.maxLength}
                                   value={this.props.value || ""}
                                   onChange={this.handleChange}
                                   onPaste={this.handlePaste}
                                   onFocus={this.props.handleFocus}
                                   onBlur={this.props.handleBlur}
                                   placeholder={this.props.placeholder}
                                   min={this.props.min}
                                   max={this.props.max}
                                   step={this.props.step}
                                   pattern={this.props.pattern}
                                   disabled={this.props.disabled}
                                   required={this.props.required}
                                   className={`form-control ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`} />

                            <div className="input-group-append">

                                {this.props.brand &&
                                <span className="input-group-text bg-white">
                                <CardBrandIcon paymentMethodType="TYPE_CREDIT_CARD" brand={this.props.brand} width="35" />
                            </span>
                                }

                                {!this.props.brand &&
                                <span className="input-group-text bg-white">
                                <img src={visa} className="rounded border mr-1" width="35" alt="Visa" title="Visa" />
                                <img src={mastercard} className="rounded border mr-1" width="35" alt="Mastercard" title="Mastercard" />
                                <img src={americanExpress} className="rounded border mr-1" width="35" alt="American Express" title="American Express" />
                                <img src={discover} className="rounded border mr-1" width="35" alt="Discover" title="Discover" />
                                <img src={unionPay} className="rounded border" width="35" alt="UnionPay" title="UnionPay" />
                            </span>
                                }

                            </div>

                            {this.props.help &&
                            <small className="form-text text-muted">
                                {this.props.help}
                            </small>
                            }

                            {isInvalid &&
                            <div className="invalid-feedback">
                                <FormattedMessage id={"danger." + errorCode} />
                            </div>
                            }

                        </div>

                    </div>

                </div>

        )
    };
}

FieldCardNumber.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    type: 'text',
    maxLength: '100'
};

export default FieldCardNumber;
