import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {injectIntl, intlShape} from 'react-intl';

class NavItem extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A navigation item.
     */
    render() {

        return(
            <li className={`nav-item ${window.location.href.indexOf(this.props.path) > 0 ? 'active' : ''} ${this.props.upperNav ? 'd-md-none' : ''} ${this.props.customClasses}`}>

                {!this.props.externalPath &&
                <NavLink to={this.props.path} className={`nav-link ${this.props.disabled ? 'disabled' : ''}`} activeClassName={this.props.upperNav ? 'text-primary' : 'text-white'}>

                    {this.props.iconName &&
                    <FontAwesomeIcon icon={[this.props.iconType, this.props.iconName]} className={`fa-fw va-b mr-1 ${this.props.upperNav ? 'mr-md-1' : 'mr-md-0'}`} size={this.props.upperNav ? '1x' : '2x'} />
                    }

                    {this.props.notification &&
                    <div className="notification">
                        <span className="badge badge-danger badge-pill">
                             <FontAwesomeIcon icon={['fas', 'exclamation']} className="va-b" />
                        </span>
                    </div>
                    }

                    <div className={`nav-title ${this.props.upperNav ? 'd-inline' : ''}`}>
                        {this.props.name}
                    </div>

                </NavLink>
                }

                {this.props.externalPath &&
                <a href={this.props.path} target="_blank" rel="noopener noreferrer" className="nav-link">

                    {this.props.iconName &&
                    <FontAwesomeIcon icon={[this.props.iconType, this.props.iconName]} className={`fa-fw va-b mr-1 mr-md-0 ${this.props.upperNav ? 'mr-md-1' : 'mr-md-0'}`} size={this.props.upperNav ? '1x' : '2x'} />
                    }

                    {this.props.notification &&
                    <div className="notification">
                        <span className="badge badge-danger badge-pill">
                             <FontAwesomeIcon icon={['fas', 'exclamation']} className="va-b" />
                        </span>
                    </div>
                    }

                    <div className={`nav-title ${this.props.upperNav ? 'd-inline' : ''}`}>
                        {this.props.name}
                    </div>

                </a>
                }

            </li>
        )
    }
}

NavItem.propTypes = {
    intl: intlShape.isRequired,
};

NavItem.defaultProps = {
    customClasses: '',
    upperNav: false,
    externalPath: false,
    notification: false
};

export default injectIntl(NavItem);