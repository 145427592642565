import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Table from "../../common/Table";
import ButtonBack from "../../common/ButtonBack";
import Propertii from "../../common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import FieldText from "../../common/FieldText";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import Breadcrumb from "../../common/Breadcrumb";
import FieldSelect from "../../common/FieldSelect";
import Spinner from "../../common/Spinner";
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import Moment from "react-moment";
import FieldDate from "../../common/FieldDate";
import Mimic from "../../common/Mimic";

class SystemAccounts extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            systemAccount: {},
            userName: '',

            systemAccountUserNameFilter: '',
            systemAccountUserTypeFilter: '',
            systemAccountTypeFilter: '',
            systemAccountStatusFilter: '',

            systemAccountList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            systemAccountQuery: {
                orderBy: 'ASC',
                orderByFields: ['createDate'],
                conditionList: [],
                joins: {}
            },

            validationList: [],

        };

        this.selectSystemAccount = this.selectSystemAccount.bind(this);
        this.filterSystemAccounts = this.filterSystemAccounts.bind(this);
        this.searchSystemAccounts = this.searchSystemAccounts.bind(this);
        this.saveSystemAccount = this.saveSystemAccount.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
    }

    /**
     * On mounting of the component, fetch a list of all system accounts across the platform.
     */
    componentDidMount() {

        this.searchSystemAccounts(1, 25, this.state.systemAccountQuery);
    }

    /**
     * Handle selecting a system account by bringing up the edit system account modal.
     *
     * @param systemAccountId - The ID of the system account selected.
     */
    selectSystemAccount(systemAccountId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/local_system_account/${systemAccountId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                systemAccount: response.data,
                userName: response.data.userName,
            }));

            $('#system-account').modal('show');

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Save changes to the selected system account.
     *
     * @param event - The event container.
     */
    saveSystemAccount(event) {

        event.preventDefault();

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/${this.state.systemAccount.type.substr(5).toLowerCase()}/${this.state.systemAccount.id}/update`, {
            userName: this.state.systemAccount.userName,
            status: this.state.systemAccount.status,
            passwordExpiryDate: this.state.systemAccount.passwordExpiryDate
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved'
                    }
                }],
            });

            this.searchSystemAccounts(1, 25, this.state.systemAccountQuery);

            $('#system-account').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle submitting the search filter field by adjusting the search query and initiating a new search for system
     * accounts.
     *
     * @param event - The event container.
     */
    filterSystemAccounts(event) {

        if(event != null) {
            event.preventDefault();
        }

        this.setState({
            spinner: true
        });

        let systemAccountQuery = this.state.systemAccountQuery;

        systemAccountQuery.conditionList = [];

        if(this.state.systemAccountUserNameFilter !== '') {
            systemAccountQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'userName',
                    operator: 'EQUALS',
                    fieldValue: this.state.systemAccountUserNameFilter
                },
            );
        }

        if(this.state.systemAccountUserTypeFilter !== '') {
            systemAccountQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'userType',
                    operator: 'EQUALS',
                    fieldValue: this.state.systemAccountUserTypeFilter
                },
            );
        }

        if(this.state.systemAccountTypeFilter !== '') {
            systemAccountQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'type',
                    operator: 'EQUALS',
                    fieldValue: this.state.systemAccountTypeFilter
                },
            );
        }

        if(this.state.systemAccountStatusFilter !== '') {
            systemAccountQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: this.state.systemAccountStatusFilter
                },
            );
        }

        this.setState({
            systemAccountQuery: systemAccountQuery
        });

        this.searchSystemAccounts(1, 25, systemAccountQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter query.
     */
    clearFilters() {

        this.setState({
            systemAccountUserNameFilter: '',
            systemAccountUserTypeFilter: '',
            systemAccountTypeFilter: '',
            systemAccountStatusFilter: '',
        }, () => {

            this.filterSystemAccounts();

        });
    }

    /**
     * Perform a search for system accounts.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchSystemAccounts(page, recordsPerPage, query) {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/local_system_account/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                systemAccountList: response.data,
                systemAccountQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The list of all system accounts across the platform.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="System Accounts" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card">
                        <div className="card-header">
                            System Accounts
                        </div>
                        <div className="card-header gotham border-top py-3 bg-secondary">
                            <form onSubmit={this.filterSystemAccounts} autoComplete="off">
                                <div className="media">
                                    <div className="media-body align-self-center mr-3">
                                        <FieldText id="systemAccountUserNameFilter" label="Search" labelClass="d-none"
                                                   fieldColumns="12" labelColums="0" placeholder="Filter by user name..."
                                                   parent={this} value={this.state.systemAccountUserNameFilter} />
                                    </div>
                                    <div className="media-body align-self-center mr-3">
                                        <FieldSelect id="systemAccountUserTypeFilter" labelColumns="0"
                                                     fieldColumns="12" parent={this}
                                                     value={this.state.systemAccountUserTypeFilter}>
                                            <option value="">All User Types</option>
                                            <option value="TYPE_CUSTOMER">Customer</option>
                                            <option value="TYPE_LANDLORD">Landlord</option>
                                            <option value="TYPE_MANAGER">Manager</option>
                                            <option value="TYPE_ADMIN">Administrator</option>
                                        </FieldSelect>
                                    </div>
                                    <div className="media-body align-self-center mr-3">
                                        <FieldSelect id="systemAccountTypeFilter" labelColumns="0"
                                                     fieldColumns="12" parent={this}
                                                     value={this.state.systemAccountTypeFilter}>
                                            <option value="">All Account Types</option>
                                            <option value="TYPE_LOCAL_SYSTEM_ACCOUNT">Local System Account</option>
                                            <option value="TYPE_GOOGLE_OAUTH_ACCOUNT">Google OAuth Account</option>
                                        </FieldSelect>
                                    </div>
                                    <div className="media-body align-self-center mr-3">
                                        <FieldSelect id="systemAccountStatusFilter" labelColumns="0"
                                                     fieldColumns="12" parent={this}
                                                     value={this.state.systemAccountStatusFilter}>
                                            <option value="">All Statuses</option>
                                            <option value="VERIFIED">Verified</option>
                                            <option value="UNVERIFIED">Unverified</option>
                                            <option value="RESET">Reset</option>
                                            <option value="SUSPENDED">Suspended</option>
                                        </FieldSelect>
                                    </div>
                                    <div className="align-self-center text-right">
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                            </button>
                                            <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <Table columns={{userName: 'User Name', userType: 'User Type', type: 'Account Type', status: 'Status', createDate: 'Create Date'}}
                               columnWidths={['20%', '20%', '20%', '20%', '20%']}
                               headerClass="c-pointer"
                               data={this.state.systemAccountList}
                               query={this.state.systemAccountQuery}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchSystemAccounts}>
                            <tbody>
                            {this.state.systemAccountList.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.selectSystemAccount(data.id)} className="c-pointer">
                                        <td>
                                            <div className="">
                                                {data.userName}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                <FormattedMessage id={"enum.record." + data.userType} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                <FormattedMessage id={"enum.record." + data.type} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.systemAccount.status." + data.status + ".class"})}`} />
                                                <span className="ml-1"><FormattedMessage id={"enum.systemAccount.status." + data.status} /></span>
                                            </div>
                                        </td>
                                        <td>
                                            <Moment format="MMM DD, YYYY hh:mm">
                                                {data.createDate}
                                            </Moment>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="system-account" tabIndex="-1" role="dialog" aria-labelledby="system-account-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.saveSystemAccount}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="admin-label">
                                            {this.state.userName}
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body bg-secondary">
                                        <p className="mb-0">
                                            This user has indicated that they would like to sign up and log in to their Letus account using their Google account credentials.
                                        </p>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldText id="userId" label="User ID" model="systemAccount" disabled={true} parent={this} value={this.state.systemAccount['userId']}/>

                                        <FieldText id="id" label="System Account ID" model="systemAccount" disabled={true} parent={this} value={this.state.systemAccount['id']}/>

                                        <FieldSelect id="status" label="Status" model="systemAccount" disabled={!this.state.systemAccount['verificationDate'] && this.state.systemAccount.type === 'TYPE_LOCAL_SYSTEM_ACCOUNT'} parent={this} value={this.state.systemAccount['status']}>
                                            <option disabled>Select a status...</option>
                                            <option value="VERIFIED">Verified</option>
                                            <option value="UNVERIFIED">Unverified</option>
                                            <option value="RESET">Reset</option>
                                            <option value="SUSPENDED">Suspended</option>
                                        </FieldSelect>

                                        <FieldDate id="verificationDate" label="Verification Date" model="systemAccount" disabled={true} parent={this} value={this.state.systemAccount['verificationDate']}/>

                                        <FieldText id="userName" label="User Name" model="systemAccount" type="email" disabled={this.state.systemAccount.type === 'TYPE_GOOGLE_OAUTH_ACCOUNT'} parent={this} value={this.state.systemAccount['userName']}/>

                                        <FieldDate id="passwordExpiryDate" label="Password Expiry" model="systemAccount" disabled={this.state.systemAccount.type === 'TYPE_GOOGLE_OAUTH_ACCOUNT'} parent={this} value={this.state.systemAccount['passwordExpiryDate']}/>

                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom d-block">

                                        <div className="row">
                                            <div className="col">

                                                <div className="float-left">
                                                    <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#system-account").modal("hide")}>Close</button>
                                                </div>

                                                <div className="float-right">

                                                    {(this.state.systemAccount.userType && this.state.systemAccount.userType !== 'TYPE_ADMIN') &&
                                                    <Mimic userType={this.state.systemAccount.userType.substr(5).toLowerCase()} userId={this.state.systemAccount.userId} parent={this} label="Mimic" displayType="button" />
                                                    }

                                                    <ButtonSave />

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

SystemAccounts.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(SystemAccounts);