import React from 'react';
import {FormattedMessage} from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { injectIntl, intlShape } from 'react-intl';
import axios from "axios/index";
import { Link } from 'react-router-dom';
import * as constants from '../util/constants';
import Alert from "./common/Alert";
import Propertii from "./common/Propertii";
import familyMoving from "../media/img/backgrounds/couple-laptop.jpg";

class ForgotPassword extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            username: '',
            submitted: false,
            verificationUrl: '',
            validationList: [],
        };

        this.renderForm = this.renderForm.bind(this);
        this.renderSuccess = this.renderSuccess.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Handle change events on fields.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state.
     *
     * @param error - The server error response.
     */
    handleValidation(error) {

        let fields = {};

        if(error.response.data.fieldErrors) {
            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );
        }

        // Handle email not found validation errors by pulling the record type
        const recordType = error.response.data.recordType;

        this.setState({
            validationList: [{
                alert: {
                    type: 'danger',
                    message: error.response.data.message,
                    code: error.response.data.errorCode + (recordType ? ('.' + recordType) : '')
                },
                fields
            }],
        });
    }

    /**
     * Handle the forgot password form submission. Upon success, the user is redirected to a confirmation component,
     * letting them know that an email has been sent to the email address provided with a link to reset their password.
     *
     * @param event - The event container.
     */
    handleSubmit(event) {

        event.preventDefault();

        axios.post(`${constants.REACT_APP_HOST_API_URL}/forgotpassword`, {
            username: this.state.username
        }).then(response => {
            this.setState({
                submitted: true,
                verificationUrl: encodeURIComponent(response.data.verificationCode)
            });
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A standard forgot password flow where the user is asked to enter their email to reset their
     * account password. A message will be displayed upon successful submission of their email.
     */
    render() {

        let renderFragment;

        // Render the form or success message fragment based on form submission state
        if(!this.state.submitted) {
            renderFragment = this.renderForm();
        } else {
            renderFragment = this.renderSuccess();
        }

        return(
            <div className="content-block">

                <div className="content-header mb-0">
                    <div className="container">

                        <div className="row no-gutters">
                            <div className="col-md-6 col-12 align-self-center pr-md-5">
                                <div className="jumbotron jumbotron-fluid bg-transparent">

                                    {renderFragment}

                                </div>
                            </div>
                            <div className="col-md-6 d-none d-md-block align-self-center">

                                <div style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${familyMoving}') no-repeat center center scroll`, backgroundSize: "cover", height: "725px", width: "998px"}}>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        );
    }

    /**
     * Render the forgot password email entry form fragment.
     *
     * @returns {*} - A simple form where the user is required to enter the email address associated with their account.
     */
    renderForm() {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <h1 className="display-5 mb-3">
                    <FormattedMessage id="static.forgot.heading" />
                </h1>

                <p className="mb-0">
                    <FormattedMessage id="static.forgot.body" />
                </p>

                <hr className="my-4" />

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.handleSubmit}>

                    <div className="form-group row mb-4">
                        <div className="col-sm-12">
                            <div className="input-group input-group-lg">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={['fas', 'user']} />
                                    </span>
                                </div>
                                <input name="username" type="email" placeholder={formatMessage({ id: "field.email"})} value={this.state['username']} onChange={this.handleChange} className={`form-control form-control-lg mb-0 rounded-right ${this.state.validationList[0] ? (this.state.validationList[0].fields['username'] ? 'is-invalid' : '') : ''}`} />
                                {this.state.validationList[0] &&
                                <div className="invalid-feedback">
                                    <FormattedMessage id={"danger." + this.state.validationList[0].fields['username']} />
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <Link to="/login" className="btn btn-lg btn-outline-primary btn-block">
                                <FormattedMessage id="button.cancel" />
                            </Link>
                        </div>
                        <div className="col-lg-6">
                            <button type="submit" className="btn btn-lg btn-primary btn-block">
                                <FormattedMessage id="button.continue" />
                            </button>
                        </div>
                    </div>

                </form>

            </React.Fragment>
        );
    }

    /**
     * Render the success message fragment.
     *
     * @returns {*} - A message that instructs the user to look for a password reset email in their inbox. A button
     * redirects the user back to the log in component.
     */
    renderSuccess() {

        return(
            <React.Fragment>

                <div className="card card-primary border-primary">
                    <div className="card-body">
                        <h4 className="card-title">
                            <FormattedMessage id="static.forgot.confirm.heading" />
                        </h4>
                        <p className="card-text">
                            <FormattedMessage id="static.forgot.confirm.body" />
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Link to="/login" className="btn btn-lg btn-primary btn-block">
                            <FormattedMessage id="button.ok" />
                        </Link>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

ForgotPassword.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ForgotPassword);