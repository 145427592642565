import React, { Component } from 'react';
import {FormattedMessage} from "react-intl";
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import $ from "jquery";

class FieldPhone extends Component {

    /**
     * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
     * custom handleChange function appears in the parent class, utilize the default handleChange function below.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        if(this.props.parent.handleChange) {
            this.handleChange = this.props.parent.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
    }

    /**
     * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
     * state, as opposed to a standalone element.
     *
     * @param value - The new value of the field.
     */
    handleChange(value) {

        let field;

        if(this.props.model != null) {
            field = this.props.parent.state[this.props.model];
            field[this.props.id] = value;
        }

        if(this.props.model == null) {
            field = value;
        }

        this.props.parent.setState(({
            [this.props.model ? this.props.model : this.props.id]: field,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic phone number field component.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if(validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if(key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        return(
            <React.Fragment>

                <div className={`form-group row ${this.props.containerClass}`}>

                    <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                        {this.props.label} {this.props.optional && <small className="text-muted font-italic">(Optional)</small>}
                    </label>

                    <div className={`col-sm-${this.props.fieldColumns}`}>

                        <PhoneInput
                            placeholder={this.props.placeholder}
                            country="US"
                            value={this.props.value || ""}
                            required={this.props.required}
                            className={`form-control ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`}
                            onChange={this.props.handleChange || this.handleChange} />

                        {this.props.help &&
                        <small className="form-text text-muted">
                            {this.props.help}
                        </small>
                        }

                        {isInvalid &&
                        <div className="invalid-feedback">
                            <FormattedMessage id={"danger." + errorCode}/>
                        </div>
                        }

                    </div>

                </div>

            </React.Fragment>
        )
    };
}

FieldPhone.defaultProps = {
    formGroup: true,
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    type: 'text',
    maxLength: '100'
};

export default FieldPhone;
