import React from 'react';
import {Switch} from 'react-router-dom';
import axios from "axios";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import {injectIntl, intlShape} from "react-intl";
import PrivateRoute from "../../common/PrivateRoute";
import Property from "./Property";
import Propertii from "../../common/Propertii";
import Tenants from "./Tenants";
import Transactions from "./Transactions";
import NavListItem from "../../common/NavListItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalPhrase from "../../common/ModalPhrase";
import Merchants from "./Merchants";
import JumbotronHelp from "../../common/JumbotronHelp";
import Breadcrumb from "../../common/Breadcrumb";

class Navigation extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            property: {},
            validationList: [],
        };

        this.refreshProperty = this.refreshProperty.bind(this);
        this.deleteProperty = this.deleteProperty.bind(this);
    }

    /**
     * Get the property based on the ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${this.props.match.params.propertyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                property: response.data
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Delete the property currently being viewed.
     */
    deleteProperty() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/property/${this.props.match.params.propertyId}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.props.history.push({
                pathname: `/manager/properties`,
                state: {
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'manager.properties.deleted'
                        }
                    }],
                }
            });
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Force a refresh of this component if required by a child component. Because this component displays the
     * property's name or street address in the breadcrumbs, this component should update if a child component makes an
     * update to said values.
     *
     * @param propertyId - The ID of the property to fetch data from.
     */
    refreshProperty(propertyId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${propertyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                property: response.data
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view property component for landlords.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/manager/properties" parentPage="Properties" childPage={`${this.state.property.propertyName ? this.state.property.propertyName : this.state.property.street1}`} />

                    <Alert validationList={this.state.validationList} />

                    <div className="row">
                        <div className="col-md-4">

                            <div className="card">

                                <div className="card-header">
                                    Options
                                </div>

                                <div className="card-body card-body-list">
                                    <div className="list-group">

                                        <NavListItem path={`/manager/properties/${this.props.match.params.propertyId}/edit`} active="edit" size="small" iconName="building" name="Property" />
                                        <NavListItem path={`/manager/properties/${this.props.match.params.propertyId}/tenants`} active="tenants" size="small" iconName="users" name="Tenants" />
                                        <NavListItem path={`/manager/properties/${this.props.match.params.propertyId}/transactions`} active="transactions" size="small" iconName="credit-card" name="Transactions" />
                                        <NavListItem path={`/manager/properties/${this.props.match.params.propertyId}/merchants`} active="merchants" size="small" iconName="university" name="Merchant Accounts" />

                                        <div className="list-group-item list-group-item-action c-pointer" data-toggle="modal" data-target="#delete-property">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="">
                                                    <FontAwesomeIcon icon={['fas', 'trash']} className="fa-fw va-b mr-2" />
                                                    Delete Property
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <JumbotronHelp icon="question-circle"
                                           heading="Need a hand?"
                                           body="Whether you're troubleshooting an issue or learning something new, our Help Center has you covered."
                                           buttonText="Visit the Help Center"
                                           buttonIcon="external-link-square-alt"
                                           buttonUrl="https://help.rentmoola.com/hc/en-us/sections/360006583852-Landlords" />

                            <ModalPhrase id="delete-property" title="Delete Property" iconName="exclamation-triangle" confirmPhrase="DELETE" handleSuccess={() => this.deleteProperty()}>

                                <p className="">Deleting this property will have the following effects:</p>

                                <ul className="small">
                                    <li>Managers currently overseeing this property will no longer have access to the property information</li>
                                    <li>All property leases associated with this property will be deleted</li>
                                    <li>All scheduled one time payments for this property will be deleted</li>
                                    <li>All auto payments being made for this property will be deleted</li>
                                    <li>Historical transactions made to this property will no longer display the property information</li>
                                </ul>

                                <p className="mb-0">To delete this property, enter the phrase 'DELETE' in the field below, then click the Confirm button.</p>

                            </ModalPhrase>

                        </div>
                        <div className="col-md-8">

                            <Switch>
                                <PrivateRoute exact path="/manager/properties/:propertyId/edit" type="TYPE_MANAGER" parent={this} component={Property} refreshProperty={(propertyId) => this.refreshProperty(propertyId)} />
                                <PrivateRoute exact path="/manager/properties/:propertyId/tenants" type="TYPE_MANAGER" parent={this} component={Tenants} />
                                <PrivateRoute exact path="/manager/properties/:propertyId/transactions" type="TYPE_MANAGER" parent={this} component={Transactions} />
                                <PrivateRoute exact path="/manager/properties/:propertyId/merchants" type="TYPE_MANAGER" parent={this} component={Merchants} />
                            </Switch>

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Navigation.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Navigation);