import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormattedMessage, FormattedNumber, injectIntl, intlShape} from "react-intl";
import propertii from "../../media/img/logos/propertii-b.png";
import $ from "jquery";
import {Link} from "react-router-dom";
import Moment from "react-moment";

class ReceiptPaymentTransaction extends Component {

    /**
     * Redirect the admin user to the funds transfer component with a modal pre-filled when the user clicks on the funds
     * transfer ID in the payment receipt.
     *
     * @param fundsTransferId - The ID of the funds transfer record selected.
     */
    viewFundsTransfer(fundsTransferId) {

        $('#receipt-payment-transaction').modal('hide');

        this.props.history.push({
            pathname: '/admin/system/efts',
            state: {
                fundsTransferId: fundsTransferId
            }
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A payment transaction receipt modal.
     */
    render() {

        return(
            <div className="modal fade" id="receipt-payment-transaction" tabIndex="-1" role="dialog" aria-labelledby="receipt-payment-transaction-label" aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered ${(this.props.paymentTransaction.parentId && this.props.parentPaymentTransaction) ? 'modal-xl' : 'modal-md'}`} role="document">
                    <div className="modal-content shadow">

                        <div className="modal-header text-center d-block text-white py-4 bg-dark border-bottom-0">
                            <div className="row">
                                <div className="col order-2">

                                    {this.renderReceiptHeader(this.props.paymentTransaction)}

                                </div>
                                {(this.props.paymentTransaction.parentId && this.props.parentPaymentTransaction) &&
                                <div className="col-6">

                                    {this.renderReceiptHeader(this.props.parentPaymentTransaction)}

                                </div>
                                }
                            </div>
                        </div>

                        {(this.props.paymentTransaction.integration != null && this.props.paymentTransaction.integration.error != null) &&
                        <div className="modal-body bg-danger border-top-0">
                            <p className="mb-0 text-white">
                                <span className="font-weight-bold">The integrated system has returned an error: </span>{this.props.paymentTransaction.integration.error}
                            </p>
                        </div>
                        }

                        {(this.props.displayAdvanced && this.props.paymentTransaction.error) &&
                        <div className="modal-body bg-danger border-top-0">
                            <p className="mb-0 text-white">
                                <span className="font-weight-bold">The payment provider has returned an error message after attempting to process this transaction: </span>{this.props.paymentTransaction.error}
                            </p>
                        </div>
                        }

                        {(!this.props.displayAdvanced && this.props.paymentTransaction.error) &&
                        <div className="modal-body bg-danger border-top-0">
                            <p className="mb-0 text-white">
                                There was an error attempting to process this transaction. For additional information, please contact our support team at help@rentmoola.com.
                            </p>
                        </div>
                        }

                        {(this.props.displayAdvanced && this.props.paymentTransaction.transactionType === 'RETURN') &&
                        <div className="modal-body bg-danger border-top-0">
                            <p className="mb-0 text-white">
                                <span className="font-weight-bold">This transaction was returned for the following reason: </span>{this.props.paymentTransaction.returnReason}
                            </p>
                        </div>
                        }

                        <div className={`row ${this.props.paymentTransaction.parentId ? 'no-gutters' : ''}`}>
                            <div className="col order-1">
                                <div className="modal-body modal-body-table">

                                    {this.renderReceiptBody(this.props.paymentTransaction)}

                                </div>
                            </div>
                            {(this.props.paymentTransaction.parentId && this.props.parentPaymentTransaction) &&
                            <div className="col-6">
                                <div className="modal-body modal-body-table">

                                    {this.renderReceiptBody(this.props.parentPaymentTransaction)}

                                </div>
                            </div>
                            }
                        </div>

                        <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                            <div className="receipt-actions">
                                {this.props.children}
                            </div>

                            <div className="receipt-disclaimer d-none">
                                <p className="my-2">Have any questions? Send us an email at <a href="mailto:help@rentmoola.com">help@rentmoola.com</a>.</p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    };

    /**
     * Render the header portion of the receipt.
     *
     * @param paymentTransaction - The payment transaction being viewed.
     * @returns {*} - The header portion of the receipt.
     */
    renderReceiptHeader(paymentTransaction) {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <div className="receipt-logo d-none">
                    <img src={propertii} alt="Letus" className="img-fluid w-50" />
                </div>
                <div className="receipt-icon">
                    {paymentTransaction.transactionType &&
                    <FontAwesomeIcon icon={["fas", formatMessage({id: "enum.transactionType." + paymentTransaction.transactionType + ".icon"})]} className="fa-fw va-b mb-3" size="4x" />
                    }
                </div>
                <h5 className="modal-title" id={`receipt-payment-transaction-label`}>
                    {paymentTransaction.transactionType &&
                    <div className="">
                        <FormattedMessage id={`enum.transactionType.${paymentTransaction.transactionType}`} /> Receipt
                    </div>
                    }
                </h5>
                <p className="mb-0 small">
                    {paymentTransaction.transactionType &&
                    <span className="">
                        <FormattedMessage id={`enum.transactionType.${paymentTransaction.transactionType}`} /> made on <Moment format="MMM DD, YYYY">{paymentTransaction.createDate}</Moment>
                    </span>
                    }
                </p>

            </React.Fragment>
        );
    }

    /**
     * Render the body portion of the receipt.
     *
     * @param paymentTransaction - The payment transaction being viewed.
     * @returns {*} - The body portion of the receipt.
     */
    renderReceiptBody(paymentTransaction) {

        const {formatMessage} = this.props.intl;

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        return(
            <table className={`table mb-0 ${paymentTransaction.parentId ? 'border-left' : ''}`}>
                <tbody>
                {!paymentTransaction.invoiceId &&
                <React.Fragment>
                    {(paymentTransaction.charges && paymentTransaction.charges.length > 0) &&
                    <React.Fragment>
                        {paymentTransaction.charges.map((data, key) => {
                            return (
                                <tr key={key}>
                                    <td className="">
                                        <div className="">
                                            <FormattedMessage id={data.name ? data.name : data.code} />
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <FormattedNumber value={data.amount} style={`currency`} currency={paymentTransaction.currency}/>
                                    </td>
                                </tr>
                            );
                        })}
                    </React.Fragment>
                    }
                </React.Fragment>
                }
                {paymentTransaction.invoice &&
                <tr>
                    <td className="">
                        <div className="">
                            Invoice {paymentTransaction.invoice.invoiceNumber}
                        </div>
                    </td>
                    <td className="text-right">
                        <FormattedNumber value={paymentTransaction.invoice.grandTotal} style={`currency`} currency={paymentTransaction.invoice.currency}/>
                    </td>
                </tr>
                }
                {((paymentTransaction.transactionType === 'CHARGEBACK' || paymentTransaction.transactionType === 'CHARGEBACK_REVERSAL') && paymentTransaction.amount !== 0) &&
                <tr>
                    <td className="">
                        Charges
                    </td>
                    <td className="text-right text-danger">
                        {(paymentTransaction.amount != null && paymentTransaction.currency) &&
                        <FormattedNumber value={paymentTransaction.amount} style={`currency`} currency={paymentTransaction.currency}/>
                        }
                    </td>
                </tr>
                }
                {(paymentTransaction.transactionType === 'REFUND' && paymentTransaction.amount !== 0) &&
                <tr>
                    <td className="">
                        Refunded Charges
                    </td>
                    <td className="text-right text-danger">
                        {(paymentTransaction.amount != null && paymentTransaction.currency) &&
                        <FormattedNumber value={paymentTransaction.amount} style={`currency`} currency={paymentTransaction.currency}/>
                        }
                    </td>
                </tr>
                }
                {(this.props.displayFeeAmount && paymentTransaction.feeAmount !== 0) &&
                <tr>
                    <td className="">
                        {paymentTransaction.transactionType === 'REFUND' ? 'Refunded Transaction Fee' : 'Transaction Fee'}
                    </td>
                    <td className={`text-right ${paymentTransaction.transactionType === 'REFUND' ? 'text-danger' : ''}`}>
                        {(paymentTransaction.feeAmount != null && paymentTransaction.currency) &&
                        <FormattedNumber value={paymentTransaction.feeAmount} style={`currency`} currency={paymentTransaction.currency}/>
                        }
                        {(this.props.displayAdvanced && paymentTransaction.feeProfile) &&
                            <span tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-html="true" title={paymentTransaction.feeProfile.companyId ? 'Custom Transaction Fee Details' : 'Default Transaction Fee Details'} data-content={
                                `
                                Card Type: <span class=''>${paymentTransaction.feeProfile.cardType ? paymentTransaction.feeProfile.cardType : 'Any'}</span><br />
                                Country: <span class=''>${formatMessage({id: "country." + paymentTransaction.feeProfile.country})}</span><br />
                                Fee Rate: <span class=''>${paymentTransaction.feeProfile.feeType === 'SUBTRACT' ? '-' : ''}${paymentTransaction.feeProfile.rateType === 'FLAT' ? '$' : ''}${(paymentTransaction.feeProfile.feeRate).toFixed(2)}${paymentTransaction.feeProfile.rateType === 'PERCENT' ? '%' : ''}</span><br />
                                International Rate: <span class=''>${paymentTransaction.feeProfile.feeType === 'SUBTRACT' ? '-' : ''}${paymentTransaction.feeProfile.internationalRateType === 'FLAT' ? '$' : ''}${(paymentTransaction.feeProfile.internationalFeeRate).toFixed(2)}${paymentTransaction.feeProfile.internationalRateType === 'PERCENT' ? '%' : ''}</span><br />
                                `
                            }>
                                <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 fa-fw small btn-link c-pointer" />
                            </span>
                        }
                    </td>
                </tr>
                }
                <tr>
                    <td className="font-weight-bold">
                        {paymentTransaction.transactionType === 'REFUND' ? 'Refunded Total' : 'Total'}
                    </td>
                    {this.props.displayFeeAmount &&
                    <td className={`font-weight-bold text-right ${(paymentTransaction.amount + paymentTransaction.feeAmount < 0) ? 'text-danger' : ''}`}>
                        {(paymentTransaction.amount != null && paymentTransaction.feeAmount != null && paymentTransaction.currency) &&
                        <FormattedNumber value={paymentTransaction.amount + paymentTransaction.feeAmount} style={`currency`} currency={paymentTransaction.currency} />
                        }
                    </td>
                    }
                    {!this.props.displayFeeAmount &&
                    <td className={`font-weight-bold text-right ${paymentTransaction.amount < 0 ? 'text-danger' : ''}`}>
                        {(paymentTransaction.amount && paymentTransaction.currency) &&
                        <FormattedNumber value={paymentTransaction.amount} style={`currency`} currency={paymentTransaction.currency} />
                        }
                    </td>
                    }
                </tr>
                {paymentTransaction.reversedAmount > 0 &&
                <tr>
                    <td className="">
                        Reversed Charges
                    </td>
                    <td className="text-right text-danger">
                        <FormattedNumber value={-paymentTransaction.reversedAmount} style={`currency`} currency={paymentTransaction.currency} />
                    </td>
                </tr>
                }
                {(this.props.displayFeeAmount && paymentTransaction.reversedFeeAmount > 0) &&
                <tr>
                    <td className="">
                        Reversed Transaction Fee
                    </td>
                    <td className="text-right text-danger">
                        <FormattedNumber value={-paymentTransaction.reversedFeeAmount} style={`currency`} currency={paymentTransaction.currency} />
                    </td>
                </tr>
                }
                {(paymentTransaction.reversedAmount > 0 || paymentTransaction.reversedFeeAmount > 0) &&
                <tr>
                    <td className="font-weight-bold">
                        Reversed Total
                    </td>
                    {this.props.displayFeeAmount &&
                    <td className="font-weight-bold text-right text-danger">
                        <FormattedNumber value={-paymentTransaction.reversedAmount + -paymentTransaction.reversedFeeAmount} style={`currency`} currency={paymentTransaction.currency} />
                    </td>
                    }
                    {!this.props.displayFeeAmount &&
                    <td className="font-weight-bold text-right text-danger">
                        <FormattedNumber value={-paymentTransaction.reversedAmount} style={`currency`} currency={paymentTransaction.currency} />
                    </td>
                    }
                </tr>
                }
                {(paymentTransaction.joins && paymentTransaction.joins.c) &&
                <tr className="small">
                    <td className="">
                        Paid By
                    </td>
                    <td className="text-right">
                        {this.props.userType &&
                        <Link to={`/${this.props.userType.substring(5).toLowerCase()}/tenants/${paymentTransaction.billingAccountId}/edit`} onClick={() => $('#receipt-payment-transaction').modal('hide')}>
                            {paymentTransaction.joins.c.firstName} {paymentTransaction.joins.c.lastName}
                        </Link>
                        }
                        {!this.props.userType &&
                        <React.Fragment>
                            {paymentTransaction.joins.c.firstName} {paymentTransaction.joins.c.lastName}
                        </React.Fragment>
                        }
                    </td>
                </tr>
                }
                <tr className="small">
                    <td className="">
                        {paymentTransaction.transactionType === 'REFUND' ? 'Refunded To' : 'Paid With'}
                    </td>
                    <td className="text-right">
                        {paymentTransaction.paymentMethodType === 'TYPE_CREDIT_CARD' &&
                        <div className="">
                            {paymentTransaction.cardBrand ? formatMessage({id: "enum.creditCard.brand." + paymentTransaction.cardBrand}) : 'Credit Card'} {paymentTransaction.last4 ? ' ending in ' + paymentTransaction.last4 : ''}
                        </div>
                        }
                        {paymentTransaction.paymentMethodType === 'TYPE_BANK_ACCOUNT' &&
                        <div className="">
                            {'Bank Account'} {paymentTransaction.last4 ? ' ending in ' + paymentTransaction.last4 : ''}
                        </div>
                        }
                        {paymentTransaction.paymentMethodType === 'TYPE_CASH' &&
                        <div className="">
                            {'Cash'}
                        </div>
                        }
                    </td>
                </tr>
                <tr className="small">
                    <td className="">
                        {paymentTransaction.transactionType === 'REFUND' ? 'Refunded On' : 'Paid On'}
                    </td>
                    <td className="text-right">
                        <Moment format="MMM DD, YYYY HH:mm">
                            {paymentTransaction.createDate}
                        </Moment>
                    </td>
                </tr>
                {paymentTransaction.joins && paymentTransaction.joins.pl &&
                <tr className="small">
                    <td className="">
                        Unit
                    </td>
                    <td className="text-right">
                        {paymentTransaction.joins.pl.unit}
                    </td>
                </tr>
                }
                {paymentTransaction.joins && paymentTransaction.joins.p &&
                <tr className="small">
                    <td className="">
                        Property
                    </td>
                    <td className="text-right">
                        {(this.props.userType && this.props.userType !== 'TYPE_ADMIN') &&
                        <Link to={`/${this.props.userType.substring(5).toLowerCase()}/properties/${paymentTransaction.propertyId}/edit`} onClick={() => $('#receipt-payment-transaction').modal('hide')}>
                            {paymentTransaction.joins.p.street1}
                        </Link>
                        }
                        {(this.props.userType && this.props.userType === 'TYPE_ADMIN') &&
                        <React.Fragment>
                            {paymentTransaction.joins.p.street1}
                        </React.Fragment>
                        }
                        {!this.props.userType &&
                        <React.Fragment>
                            {paymentTransaction.joins.p.street1}
                        </React.Fragment>
                        }
                    </td>
                </tr>
                }
                {(this.props.displayAdvanced && paymentTransaction.joins && paymentTransaction.joins.co) &&
                <tr className="small">
                    <td className="">
                        {paymentTransaction.joins.co.landlordId &&
                        <span className="">Landlord</span>
                        }
                        {!paymentTransaction.joins.co.landlordId &&
                        <span className="">Company</span>
                        }
                    </td>
                    <td className="text-right">
                        {this.props.userType &&
                        <React.Fragment>
                            <Link to={`/${this.props.userType.substring(5).toLowerCase()}/${paymentTransaction.joins.co.landlordId ? 'landlords' : 'companies'}/${paymentTransaction.joins.co.landlordId ? paymentTransaction.joins.co.landlordId : paymentTransaction.companyId}/edit`} onClick={() => $('#receipt-payment-transaction').modal('hide')}>
                                {paymentTransaction.joins.co.name}
                            </Link>
                        </React.Fragment>
                        }
                        {!this.props.userType &&
                        <React.Fragment>
                            {paymentTransaction.joins.co.name}
                        </React.Fragment>
                        }
                    </td>
                </tr>
                }
                {(this.props.displayAdvanced && paymentTransaction.currency) &&
                <tr className="small">
                    <td className="">
                        Currency
                    </td>
                    <td className="text-right">
                        {paymentTransaction.currency}
                    </td>
                </tr>
                }
                {(this.props.displayAdvanced && paymentTransaction.source) &&
                <tr className="small">
                    <td className="">
                        Initiated Via
                    </td>
                    <td className="text-right">
                        <FormattedMessage id={`enum.transactionSource.${paymentTransaction.source}`} />
                    </td>
                </tr>
                }
                <tr className="small">
                    <td className="">
                        Transaction Type
                    </td>
                    <td className="text-right">
                        {paymentTransaction.transactionType &&
                        <FormattedMessage id={`enum.transactionType.${paymentTransaction.transactionType}`} />
                        }
                    </td>
                </tr>
                {paymentTransaction.status &&
                <tr className="small">
                    <td className="">
                        Transaction Status
                    </td>
                    <td className="text-right">
                        <div className="text-nowrap">
                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + paymentTransaction.status + ".class"})}`}/>
                            <span className="ml-1"><FormattedMessage id={`enum.transactionStatus.${paymentTransaction.status}`}/></span>
                        </div>
                    </td>
                </tr>
                }
                {paymentTransaction.integration != null &&
                <tr className="small">
                    <td className="">
                        Push Status
                    </td>
                    <td className="text-right">
                        <div className="text-nowrap">
                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.pushStatus." + paymentTransaction.integration.pushStatus + ".class"})}`} />
                            <span className="ml-1"><FormattedMessage id={`enum.pushStatus.${paymentTransaction.integration.pushStatus}`} /></span>
                        </div>
                    </td>
                </tr>
                }
                {(this.props.displayAdvanced && paymentTransaction.disbursementStatus) &&
                <tr className="small">
                    <td className="">
                        Disbursement Status
                    </td>
                    <td className="text-right">
                        <div className="text-nowrap">
                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.disbursementStatus." + paymentTransaction.disbursementStatus + ".class"})}`} />
                            <span className="ml-1"><FormattedMessage id={`enum.disbursementStatus.${paymentTransaction.disbursementStatus}`} /></span>
                        </div>
                    </td>
                </tr>
                }
                {this.props.displayAdvanced &&
                <tr className="small">
                    <td className="">
                        Settlement Date
                    </td>
                    <td className="text-right">
                        {paymentTransaction.settlementDate &&
                        <Moment format="MMM DD, YYYY HH:mm">
                            {paymentTransaction.settlementDate}
                        </Moment>
                        }
                        {!paymentTransaction.settlementDate &&
                        <div className="text-nowrap">
                            <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-warning" />
                            <span className="ml-1">Settlement Pending</span>
                        </div>
                        }
                    </td>
                </tr>
                }
                {(this.props.displayAdvanced && paymentTransaction.paymentProviderId) &&
                <tr className="small">
                    <td className="">
                        Payment Provider
                    </td>
                    <td className="text-right">
                        <FormattedMessage id={"enum.paymentProviderId." + paymentTransaction.paymentProviderId} />
                    </td>
                </tr>
                }
                {paymentTransaction.confirmationId &&
                <tr className="small">
                    <td className="">
                        Confirmation ID
                    </td>
                    <td className="text-right">
                        {paymentTransaction.confirmationId}
                    </td>
                </tr>
                }
                {(this.props.displayAdvanced && paymentTransaction.fundsTransferId) &&
                <tr className="small">
                    <td className="">
                        Funds Transfer ID
                    </td>
                    <td className="text-right">
                        <div className="btn-link c-pointer" onClick={() => this.viewFundsTransfer(paymentTransaction.fundsTransferId)}>{paymentTransaction.fundsTransferId}</div>
                    </td>
                </tr>
                }
                {this.props.displayAdvanced &&
                <tr className="small">
                    <td className="">
                        Internal ID
                    </td>
                    <td className="text-right">
                        {paymentTransaction.id}
                    </td>
                </tr>
                }
                </tbody>
            </table>
        );

    }
}

ReceiptPaymentTransaction.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ReceiptPaymentTransaction);