import React, { Component } from 'react';
import {FormattedMessage} from "react-intl";

class FieldTextarea extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        if(this.props.parent.handleChange) {
            this.handleChange = this.props.parent.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
    }

    /**
     * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
     * state, as opposed to a standalone element.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        let field;

        if(this.props.model != null) {
            field = this.props.parent.state[this.props.model];
            field[event.target.name] = event.target.value;
        }

        if(this.props.model == null) {
            field = event.target.value;
        }

        this.props.parent.setState(({
            [this.props.model ? this.props.model : event.target.name]: field,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic textarea component.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if(validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if(key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        return(
            <div className="form-group row">

                <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                    {this.props.label}
                </label>

                <div className={`col-sm-${this.props.fieldColumns}`}>

                    <textarea
                        id={this.props.id}
                        name={this.props.id}
                        rows={this.props.rows}
                        value={this.props.value || ""}
                        onChange={this.handleChange}
                        maxLength={this.props.maxLength}
                        placeholder={this.props.placeholder}
                        required={this.props.required}
                        autoComplete={this.props.autoComplete}
                        autoCorrect={this.props.autoCorrect}
                        autoCapitalize={this.props.autoCapitalize}
                        spellCheck={this.props.spellCheck}
                        className={`form-control ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`}>

                    </textarea>

                    {this.props.help &&
                    <small className="form-text text-muted">
                        {this.props.help}
                    </small>
                    }

                    {isInvalid &&
                    <div className="invalid-feedback">
                        <FormattedMessage id={"danger." + errorCode} />
                    </div>
                    }

                </div>

            </div>
        )
    };
}

FieldTextarea.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    maxLength: '500',
    rows: '3',
    autoComplete: 'off',
    autoCorrect: 'off',
    autoCapitalize: 'off',
    spellcheck: 'false'
};

export default FieldTextarea;
