import React from 'react';
import Propertii from "../../common/Propertii";
import FieldSelect from "../../common/FieldSelect";
import axios from "axios";
import * as constants from "../../../util/constants";
import Spinner from "../../common/Spinner";
import ButtonBack from "../../common/ButtonBack";
import Breadcrumb from "../../common/Breadcrumb";
import {Bar} from "react-chartjs-2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Revenue extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            selectedYear: '2020',
            filteredYear: '2020',

            saasRevenue: {},
            feeRefunds: {},
            clients: {},
            landlords: {},
            activePayUsers: {},
            feeRevenue: {},
            saasRefunds: {},
            units: {},

            validationList: [],

        };

        this.filterRevenue = this.filterRevenue.bind(this);
    }

    /**
     * On mounting of the component, filter the revenue totals by the default year.
     */
    componentDidMount() {

        this.filterRevenue();
    }

    /**
     * Filter the revenue KPIs by providing a year.
     *
     * @param event - The event container.
     */
    filterRevenue(event) {

        if(event != null) {
            event.preventDefault();
        }

        this.setState(prevState => ({
            spinner: true
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/admin/stats/revenue_kpi/${this.state.selectedYear}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                filteredYear: this.state.selectedYear,
                saasRevenue: response.data.saasRevenue,
                feeRefunds: response.data.feeRefunds,
                clients: response.data.clients,
                landlords: response.data.landlords,
                activePayUsers: response.data.activePayUsers,
                feeRevenue: response.data.feeRevenue,
                saasRefunds: response.data.saasRefunds,
                units: response.data.units
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Download revenue KPI data.
     */
    downloadRevenue() {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/admin/stats/revenue_kpi/${this.state.selectedYear}/download`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': this.props.token
            }
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 10;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The admin processing key performance indicators.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Spinner visible={this.state.spinner} />

                    <Breadcrumb parentPath="/admin/dashboard" parentPage="Dashboard" childPage="Revenue" />

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Revenue
                                </div>
                                <div className="col text-right">
                                    <div data-toggle="modal" data-target="#service-fee" className="btn btn-primary btn-sm" onClick={() => this.downloadRevenue()}>
                                        <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download Report
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-header gotham border-top py-3 bg-secondary">
                            <form onSubmit={this.filterRevenue} autoComplete="off">
                                <div className="media">
                                    <div className="media-body align-self-center mr-3">
                                        <FieldSelect id="selectedYear" labelColumns="0" fieldColumns="12" parent={this} value={this.state.selectedYear}>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                        </FieldSelect>
                                    </div>
                                    <div className="align-self-center text-right">
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card-body border-bottom">

                            <p className="lead mb-0">Gross Revenue</p>
                            <p className="text-muted">Transaction Fee Revenue and SaaS Revenue: {this.state.filteredYear}</p>

                            <div className="chart">
                                <Bar
                                    borderColor={'rgba(0, 0, 0, 1)'}
                                    options={{
                                        layout: {
                                            padding: {
                                                left: 25,
                                                right: 25,
                                                top: 10,
                                                bottom: 25
                                            }
                                        },
                                        legend: {
                                            labels: {
                                                boxWidth: 15,
                                            }
                                        },
                                        tooltips: {
                                            mode: 'x',
                                            titleSpacing: 10,
                                            bodySpacing: 5,
                                            xPadding: 10,
                                            yPadding: 10,
                                        },
                                    }}
                                    data={{
                                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                        datasets: [
                                            {
                                                label: 'Transaction Fee Revenue',
                                                backgroundColor: 'rgba(0, 187, 194, 0.75)',
                                                borderColor: 'rgba(0, 187, 194, 1)',
                                                pointBackgroundColor: 'rgba(0, 187, 194, 1)',
                                                pointBorderColor: 'rgba(0, 187, 194, 1)',
                                                pointBorderWidth: 3,
                                                pointHitRadius: 5,
                                                pointHoverRadius: 5,
                                                borderWidth: 2,
                                                data: [
                                                    this.state.feeRevenue[`${this.state.filteredYear}-01`],
                                                    this.state.feeRevenue[`${this.state.filteredYear}-02`],
                                                    this.state.feeRevenue[`${this.state.filteredYear}-03`],
                                                    this.state.feeRevenue[`${this.state.filteredYear}-04`],
                                                    this.state.feeRevenue[`${this.state.filteredYear}-05`],
                                                    this.state.feeRevenue[`${this.state.filteredYear}-06`],
                                                    this.state.feeRevenue[`${this.state.filteredYear}-07`],
                                                    this.state.feeRevenue[`${this.state.filteredYear}-08`],
                                                    this.state.feeRevenue[`${this.state.filteredYear}-09`],
                                                    this.state.feeRevenue[`${this.state.filteredYear}-10`],
                                                    this.state.feeRevenue[`${this.state.filteredYear}-11`],
                                                    this.state.feeRevenue[`${this.state.filteredYear}-12`],
                                                ]
                                            },
                                            {
                                                label: 'SaaS Revenue',
                                                backgroundColor: 'rgba(194, 149, 0, 0.75)',
                                                borderColor: 'rgba(194, 149, 0, 1)',
                                                pointBackgroundColor: 'rgba(194, 149, 0, 1)',
                                                pointBorderColor: 'rgba(194, 149, 0, 1)',
                                                pointBorderWidth: 3,
                                                pointHitRadius: 5,
                                                pointHoverRadius: 5,
                                                borderWidth: 2,
                                                data: [
                                                    this.state.saasRevenue[`${this.state.filteredYear}-01`],
                                                    this.state.saasRevenue[`${this.state.filteredYear}-02`],
                                                    this.state.saasRevenue[`${this.state.filteredYear}-03`],
                                                    this.state.saasRevenue[`${this.state.filteredYear}-04`],
                                                    this.state.saasRevenue[`${this.state.filteredYear}-05`],
                                                    this.state.saasRevenue[`${this.state.filteredYear}-06`],
                                                    this.state.saasRevenue[`${this.state.filteredYear}-07`],
                                                    this.state.saasRevenue[`${this.state.filteredYear}-08`],
                                                    this.state.saasRevenue[`${this.state.filteredYear}-09`],
                                                    this.state.saasRevenue[`${this.state.filteredYear}-10`],
                                                    this.state.saasRevenue[`${this.state.filteredYear}-11`],
                                                    this.state.saasRevenue[`${this.state.filteredYear}-12`],
                                                ]
                                            },
                                        ]
                                    }}
                                />
                            </div>

                        </div>
                        <div className="card-body border-bottom">

                            <p className="lead mb-0">Gross Revenue Refunds</p>
                            <p className="text-muted">Transaction Fee Refunds and SaaS Refunds: {this.state.filteredYear}</p>

                            <div className="chart">
                                <Bar
                                    borderColor={'rgba(0, 0, 0, 1)'}
                                    options={{
                                        layout: {
                                            padding: {
                                                left: 25,
                                                right: 25,
                                                top: 10,
                                                bottom: 25
                                            }
                                        },
                                        legend: {
                                            labels: {
                                                boxWidth: 15,
                                            }
                                        },
                                        tooltips: {
                                            mode: 'x',
                                            titleSpacing: 10,
                                            bodySpacing: 5,
                                            xPadding: 10,
                                            yPadding: 10,
                                        },
                                    }}
                                    data={{
                                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                        datasets: [
                                            {
                                                label: 'Transaction Fee Refunds',
                                                backgroundColor: 'rgba(0, 187, 194, 0.75)',
                                                borderColor: 'rgba(0, 187, 194, 1)',
                                                pointBackgroundColor: 'rgba(0, 187, 194, 1)',
                                                pointBorderColor: 'rgba(0, 187, 194, 1)',
                                                pointBorderWidth: 3,
                                                pointHitRadius: 5,
                                                pointHoverRadius: 5,
                                                borderWidth: 2,
                                                data: [
                                                    this.state.feeRefunds[`${this.state.filteredYear}-01`],
                                                    this.state.feeRefunds[`${this.state.filteredYear}-02`],
                                                    this.state.feeRefunds[`${this.state.filteredYear}-03`],
                                                    this.state.feeRefunds[`${this.state.filteredYear}-04`],
                                                    this.state.feeRefunds[`${this.state.filteredYear}-05`],
                                                    this.state.feeRefunds[`${this.state.filteredYear}-06`],
                                                    this.state.feeRefunds[`${this.state.filteredYear}-07`],
                                                    this.state.feeRefunds[`${this.state.filteredYear}-08`],
                                                    this.state.feeRefunds[`${this.state.filteredYear}-09`],
                                                    this.state.feeRefunds[`${this.state.filteredYear}-10`],
                                                    this.state.feeRefunds[`${this.state.filteredYear}-11`],
                                                    this.state.feeRefunds[`${this.state.filteredYear}-12`],
                                                ]
                                            },
                                            {
                                                label: 'SaaS Refunds',
                                                backgroundColor: 'rgba(194, 149, 0, 0.75)',
                                                borderColor: 'rgba(194, 149, 0, 1)',
                                                pointBackgroundColor: 'rgba(194, 149, 0, 1)',
                                                pointBorderColor: 'rgba(194, 149, 0, 1)',
                                                pointBorderWidth: 3,
                                                pointHitRadius: 5,
                                                pointHoverRadius: 5,
                                                borderWidth: 2,
                                                data: [
                                                    this.state.saasRefunds[`${this.state.filteredYear}-01`],
                                                    this.state.saasRefunds[`${this.state.filteredYear}-02`],
                                                    this.state.saasRefunds[`${this.state.filteredYear}-03`],
                                                    this.state.saasRefunds[`${this.state.filteredYear}-04`],
                                                    this.state.saasRefunds[`${this.state.filteredYear}-05`],
                                                    this.state.saasRefunds[`${this.state.filteredYear}-06`],
                                                    this.state.saasRefunds[`${this.state.filteredYear}-07`],
                                                    this.state.saasRefunds[`${this.state.filteredYear}-08`],
                                                    this.state.saasRefunds[`${this.state.filteredYear}-09`],
                                                    this.state.saasRefunds[`${this.state.filteredYear}-10`],
                                                    this.state.saasRefunds[`${this.state.filteredYear}-11`],
                                                    this.state.saasRefunds[`${this.state.filteredYear}-12`],
                                                ]
                                            },
                                        ]
                                    }}
                                />
                            </div>

                        </div>
                        <div className="card-body border-bottom">

                            <p className="lead mb-0">Conversion Rate</p>
                            <p className="text-muted">Active Payment Users and Units: {this.state.filteredYear}</p>

                            <div className="chart">
                                <Bar
                                    borderColor={'rgba(0, 0, 0, 1)'}
                                    options={{
                                        layout: {
                                            padding: {
                                                left: 25,
                                                right: 25,
                                                top: 10,
                                                bottom: 25
                                            }
                                        },
                                        legend: {
                                            labels: {
                                                boxWidth: 15,
                                            }
                                        },
                                        tooltips: {
                                            mode: 'x',
                                            titleSpacing: 10,
                                            bodySpacing: 5,
                                            xPadding: 10,
                                            yPadding: 10,
                                        },
                                    }}
                                    data={{
                                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                        datasets: [
                                            {
                                                label: 'Active Payment Users',
                                                backgroundColor: 'rgba(0, 187, 194, 0.75)',
                                                borderColor: 'rgba(0, 187, 194, 1)',
                                                pointBackgroundColor: 'rgba(0, 187, 194, 1)',
                                                pointBorderColor: 'rgba(0, 187, 194, 1)',
                                                pointBorderWidth: 3,
                                                pointHitRadius: 5,
                                                pointHoverRadius: 5,
                                                borderWidth: 2,
                                                data: [
                                                    this.state.activePayUsers[`${this.state.filteredYear}-01`],
                                                    this.state.activePayUsers[`${this.state.filteredYear}-02`],
                                                    this.state.activePayUsers[`${this.state.filteredYear}-03`],
                                                    this.state.activePayUsers[`${this.state.filteredYear}-04`],
                                                    this.state.activePayUsers[`${this.state.filteredYear}-05`],
                                                    this.state.activePayUsers[`${this.state.filteredYear}-06`],
                                                    this.state.activePayUsers[`${this.state.filteredYear}-07`],
                                                    this.state.activePayUsers[`${this.state.filteredYear}-08`],
                                                    this.state.activePayUsers[`${this.state.filteredYear}-09`],
                                                    this.state.activePayUsers[`${this.state.filteredYear}-10`],
                                                    this.state.activePayUsers[`${this.state.filteredYear}-11`],
                                                    this.state.activePayUsers[`${this.state.filteredYear}-12`],
                                                ]
                                            },
                                            {
                                                label: 'Units',
                                                backgroundColor: 'rgba(194, 149, 0, 0.75)',
                                                borderColor: 'rgba(194, 149, 0, 1)',
                                                pointBackgroundColor: 'rgba(194, 149, 0, 1)',
                                                pointBorderColor: 'rgba(194, 149, 0, 1)',
                                                pointBorderWidth: 3,
                                                pointHitRadius: 5,
                                                pointHoverRadius: 5,
                                                borderWidth: 2,
                                                data: [
                                                    this.state.units[`${this.state.filteredYear}-01`],
                                                    this.state.units[`${this.state.filteredYear}-02`],
                                                    this.state.units[`${this.state.filteredYear}-03`],
                                                    this.state.units[`${this.state.filteredYear}-04`],
                                                    this.state.units[`${this.state.filteredYear}-05`],
                                                    this.state.units[`${this.state.filteredYear}-06`],
                                                    this.state.units[`${this.state.filteredYear}-07`],
                                                    this.state.units[`${this.state.filteredYear}-08`],
                                                    this.state.units[`${this.state.filteredYear}-09`],
                                                    this.state.units[`${this.state.filteredYear}-10`],
                                                    this.state.units[`${this.state.filteredYear}-11`],
                                                    this.state.units[`${this.state.filteredYear}-12`],
                                                ]
                                            },
                                        ]
                                    }}
                                />
                            </div>

                        </div>
                        <div className="card-body">

                            <p className="lead mb-0">Total Clients</p>
                            <p className="text-muted">Parent Companies and Landlord Users: {this.state.filteredYear}</p>

                            <div className="chart">
                                <Bar
                                    borderColor={'rgba(0, 0, 0, 1)'}
                                    options={{
                                        layout: {
                                            padding: {
                                                left: 25,
                                                right: 25,
                                                top: 10,
                                                bottom: 25
                                            }
                                        },
                                        legend: {
                                            labels: {
                                                boxWidth: 15,
                                            }
                                        },
                                        tooltips: {
                                            mode: 'x',
                                            titleSpacing: 10,
                                            bodySpacing: 5,
                                            xPadding: 10,
                                            yPadding: 10,
                                        },
                                    }}
                                    data={{
                                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                        datasets: [
                                            {
                                                label: 'Parent Companies',
                                                backgroundColor: 'rgba(0, 187, 194, 0.75)',
                                                borderColor: 'rgba(0, 187, 194, 1)',
                                                pointBackgroundColor: 'rgba(0, 187, 194, 1)',
                                                pointBorderColor: 'rgba(0, 187, 194, 1)',
                                                pointBorderWidth: 3,
                                                pointHitRadius: 5,
                                                pointHoverRadius: 5,
                                                borderWidth: 2,
                                                data: [
                                                    this.state.clients[`${this.state.filteredYear}-01`],
                                                    this.state.clients[`${this.state.filteredYear}-02`],
                                                    this.state.clients[`${this.state.filteredYear}-03`],
                                                    this.state.clients[`${this.state.filteredYear}-04`],
                                                    this.state.clients[`${this.state.filteredYear}-05`],
                                                    this.state.clients[`${this.state.filteredYear}-06`],
                                                    this.state.clients[`${this.state.filteredYear}-07`],
                                                    this.state.clients[`${this.state.filteredYear}-08`],
                                                    this.state.clients[`${this.state.filteredYear}-09`],
                                                    this.state.clients[`${this.state.filteredYear}-10`],
                                                    this.state.clients[`${this.state.filteredYear}-11`],
                                                    this.state.clients[`${this.state.filteredYear}-12`],
                                                ]
                                            },
                                            {
                                                label: 'Landlord Users',
                                                backgroundColor: 'rgba(194, 149, 0, 0.75)',
                                                borderColor: 'rgba(194, 149, 0, 1)',
                                                pointBackgroundColor: 'rgba(194, 149, 0, 1)',
                                                pointBorderColor: 'rgba(194, 149, 0, 1)',
                                                pointBorderWidth: 3,
                                                pointHitRadius: 5,
                                                pointHoverRadius: 5,
                                                borderWidth: 2,
                                                data: [
                                                    this.state.landlords[`${this.state.filteredYear}-01`],
                                                    this.state.landlords[`${this.state.filteredYear}-02`],
                                                    this.state.landlords[`${this.state.filteredYear}-03`],
                                                    this.state.landlords[`${this.state.filteredYear}-04`],
                                                    this.state.landlords[`${this.state.filteredYear}-05`],
                                                    this.state.landlords[`${this.state.filteredYear}-06`],
                                                    this.state.landlords[`${this.state.filteredYear}-07`],
                                                    this.state.landlords[`${this.state.filteredYear}-08`],
                                                    this.state.landlords[`${this.state.filteredYear}-09`],
                                                    this.state.landlords[`${this.state.filteredYear}-10`],
                                                    this.state.landlords[`${this.state.filteredYear}-11`],
                                                    this.state.landlords[`${this.state.filteredYear}-12`],
                                                ]
                                            },
                                        ]
                                    }}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/dashboard" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default Revenue;