import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from '../media/img/error/unauthorized.jpg';
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import Propertii from "./common/Propertii";

class Unauthorized extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - Error 401: Unauthorized.
     */
    render() {

        return(
            <div className="content-block">

                <div className="content-header mb-0">
                    <div className="container">

                        <div className="row no-gutters">
                            <div className="col-md-6 col-12 align-self-center pr-md-5">

                                <div className="jumbotron jumbotron-fluid bg-transparent">

                                    <h1 className="display-1">
                                        <strong>
                                            <FormattedMessage id="static.error.unauthorized.code" />
                                        </strong>
                                    </h1>

                                    <p className="lead pt-2">
                                        <FormattedMessage id="static.error.unauthorized.heading" />
                                    </p>

                                    <p className="pb-2">
                                        <FormattedHTMLMessage id="static.error.unauthorized.body" />
                                    </p>

                                    <div className="">
                                        <Link to="/login" className="btn btn-primary mb-1">
                                            <FormattedMessage id="static.error.button.login" />
                                        </Link>
                                        <a href="https://help.rentmoola.com/" target="_blank" rel="noopener noreferrer" className="btn btn-primary mb-1 ml-1">
                                            <FormattedMessage id="static.error.button.help" />
                                        </a>
                                        <Link to="/contact" className="btn btn-primary mb-1 ml-1">
                                            <FormattedMessage id="static.error.button.contact" />
                                        </Link>
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6 d-none d-md-block align-self-center">

                                <div style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${backgroundImage}') no-repeat center center scroll`, backgroundSize: "auto", height: "650px", width: "998px"}}>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>


            </div>
        )
    };
}

export default Unauthorized;