import React from 'react';
import Propertii from "../../common/Propertii";
import PropertyList from "../../common/Properties";

class Properties extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The manager property list component.
     */
    render() {

        return(
            <div className="">

                <PropertyList userType={this.props.userSession.sessionRole.type} hideRegion={true} companyId={this.props.match.params.companyId} merchantAccounts={true}
                              history={this.props.history} createMessage="admin.companies.properties.created" />

            </div>
        )
    };
}

export default Properties;