import React from 'react';
import {Switch} from 'react-router-dom';
import Alert from "../../common/Alert";
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import PrivateRoute from "../../common/PrivateRoute";
import Propertii from "../../common/Propertii";
import Application from "./Application";
import NavListItem from "../../common/NavListItem";
import axios from "axios";
import * as constants from "../../../util/constants";
import Screening from "./Screenings";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "../../common/Modal";
import $ from "jquery";
import FieldText from "../../common/FieldText";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldCharges from "../../common/FieldCharges";
import Spinner from "../../common/Spinner";
import JumbotronHelp from "../../common/JumbotronHelp";
import Breadcrumb from "../../common/Breadcrumb";
import FieldSelect from "../../common/FieldSelect";
import FieldDate from "../../common/FieldDate";

class Navigation extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            company: {},
            parentCompany: {},
            leaseApplication: {},

            startDate: null,
            monthlyPaymentDueDay: '',
            requireAutoPay: false,
            charges: [{}],
            customChargeCodes: [],

            integrationId: '',

            integrationSearch: {
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                tenantId: '',
                propertyId: ''
            },

            integrationCustomerList: [],

            validationList: [],

        };

        this.declineLeaseApplication = this.declineLeaseApplication.bind(this);
        this.approveLeaseApplication = this.approveLeaseApplication.bind(this);

        this.initIntegrationCustomers = this.initIntegrationCustomers.bind(this);
        this.searchIntegrationCustomers = this.searchIntegrationCustomers.bind(this);
        this.selectIntegrationCustomer = this.selectIntegrationCustomer.bind(this);

        this.addCharge = this.addCharge.bind(this);
        this.removeCharge = this.removeCharge.bind(this);
        this.importCharges = this.importCharges.bind(this);

        this.handleChangeAccountNumber = this.handleChangeAccountNumber.bind(this);
    }

    /**
     * Fetch the application information as well as the property and company/parent company related to the lease
     * application on mounting of the component.
     */
    componentDidMount() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.match.params.leaseApplicationId
                }
            ],
            joins: {
                p: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'p',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode', 'propertyIdentifier', 'companyId']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let leaseApplication = response.data.records[0];

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${response.data.records[0].joins.p.companyId}/fallbackfields`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    leaseApplication: leaseApplication,
                    integrationId: response.data.integrationId,
                    customChargeCodes: response.data.chargeCodes,
                }));

            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Decline a pending lease application. Sends an email to the applicant letting them know their application has been
     * declined.
     */
    declineLeaseApplication() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let leaseApplication = this.state.leaseApplication;

        leaseApplication.status = 'DECLINED';

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, leaseApplication, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            $('#decline-lease-application').modal("hide");

            this.props.history.push({
                pathname: `/manager/applications`,
                state: {
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'common.applications.declined'
                        },
                        values: {
                            firstName: this.state.leaseApplication.firstName,
                            lastName: this.state.leaseApplication.lastName
                        }
                    }],
                }
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Approve a pending lease application. Creates a pending invitation record and sends the new tenant an invitation
     * email allowing them to fully create a tenant profile.
     *
     * @param event - The event container.
     */
    approveLeaseApplication(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let approveLeaseApplication = {};

        if(this.state.leaseApplication.accountNumber) {
            approveLeaseApplication.accountNumber = this.state.leaseApplication.accountNumber;
        }

        approveLeaseApplication.startDate = this.state.startDate;
        approveLeaseApplication.monthlyPaymentDueDay = this.state.monthlyPaymentDueDay;
        approveLeaseApplication.recurringCharges = this.state.charges;
        approveLeaseApplication.requireAutoPay = this.state.requireAutoPay;

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/${this.state.leaseApplication.id}/approve`, approveLeaseApplication, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            $('#approve-lease-application').modal("hide");

            this.props.history.push({
                pathname: `/manager/applications`,
                state: {
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'common.applications.approved'
                        },
                        values: {
                            firstName: this.state.leaseApplication.firstName,
                            lastName: this.state.leaseApplication.lastName
                        }
                    }],
                }
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize the search integration customers flow by taking the property identifier value from the selected
     * property and pre-filling the property ID search field.
     */
    initIntegrationCustomers() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${this.state.leaseApplication.propertyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                integrationCustomerList: [],
                integrationSearch: {
                    ...prevState.integrationSearch,
                    propertyId: response.data.propertyIdentifier,
                    email: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    tenantId: '',
                },
            }));

            $('#approve-lease-application').modal('hide');
            $('#search-tenant').modal('show');

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Perform a search for customers in the integrated system for selection.
     */
    searchIntegrationCustomers(event) {

        event.preventDefault();

        this.setState({
            spinner: true,
            integrationCustomerList: [],
            validationList: [{
                fields: {},
                alert: {
                    type: '',
                    code: ''
                }
            }]
        });

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/tenant/search`, {
            email: this.state.integrationSearch.email,
            firstName: this.state.integrationSearch.firstName,
            lastName: this.state.integrationSearch.lastName,
            phone: this.state.integrationSearch.phone,
            propertyId: this.state.integrationSearch.propertyId,
            tenantId: this.state.integrationSearch.tenantId,
            integrationId: this.state.integrationId
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    integrationCustomerList: response.data
                }));
            }

            if(response.data.length === 0) {
                this.setState({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'common.applications.integration.null'
                        }
                    }]
                });
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Select a customer from the integrated system. Using the data from the integrated system, the fields used to
     * invite or create a tenant will be pre-filled with property lease and customer information.
     *
     * @param data - The customer that has been selected from the integrated system.
     */
    selectIntegrationCustomer(data) {

        this.setState(prevState => ({
            ...prevState,
            leaseApplication: {
                ...prevState.leaseApplication,
                accountNumber: data.externalId,
            }
        }));

        $('#search-tenant').modal('hide');
        $('#approve-lease-application').modal('show');
    }

    /**
     * Add a new blank charge to the list of charges.
     */
    addCharge() {

        this.setState(prevState => ({
            ...prevState,
            charges: [...prevState.charges, {
                amount: '',
                code: '',
                name: ''
            }],
        }));
    }

    /**
     * Remove a specific charge from the list of charges.
     *
     * @param index - The array index of the charge to remove.
     */
    removeCharge(index) {

        let charges = this.state.charges;

        charges.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            charges: charges,
        }));
    }

    /**
     * Import charges and amounts from the integrated system.
     */
    importCharges() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/tenant/recurringcharges`, {
            propertyId: this.state.integrationSearch.propertyId,
            tenantId: this.state.leaseApplication.accountNumber,
            integrationId: this.state.integrationId
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    customChargeCodes: [...prevState.customChargeCodes, ...response.data],
                    charges: response.data,
                }));
            }

            if(response.data.length === 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'common.applications.charges.null'
                        }
                    }]
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the account number field.
     *
     * @param event - The event container.
     */
    handleChangeAccountNumber(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            leaseApplication: {
                ...prevState.leaseApplication,
                accountNumber: event.target.value
            }
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view property component for landlords.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/manager/applications" parentPage="Applications" childPage={`${this.state.leaseApplication.firstName} ${this.state.leaseApplication.lastName}`} />

                    <div className="row">
                        <div className="col-md-4">

                            <div className="card">

                                <div className="card-header">
                                    Options
                                </div>

                                <div className="card-body card-body-list">
                                    <div className="list-group">

                                        <NavListItem path={`/manager/applications/${this.props.match.params.leaseApplicationId}/view`} active="view" size="small" iconName="address-card" name="Application" />

                                        {!this.state.leaseApplication.existingTenant &&
                                        <NavListItem path={`/manager/applications/${this.props.match.params.leaseApplicationId}/screening`} active="screening" size="small" iconName="search" name="Screening"/>
                                        }

                                        {this.state.leaseApplication.status === 'PENDING' &&
                                        <div className="list-group-item list-group-item-action c-pointer" data-toggle="modal" data-target="#approve-lease-application">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="">
                                                    <FontAwesomeIcon icon={['fas', 'user-check']} className="fa-fw va-b mr-2" />
                                                    Approve Application
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        {this.state.leaseApplication.status === 'PENDING' &&
                                        <div className="list-group-item list-group-item-action c-pointer" data-toggle="modal" data-target="#decline-lease-application">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="">
                                                    <FontAwesomeIcon icon={['fas', 'user-times']} className="fa-fw va-b mr-2" />
                                                    Decline Application
                                                </div>
                                            </div>
                                        </div>
                                        }

                                    </div>
                                </div>

                            </div>

                            <JumbotronHelp icon="question-circle"
                                           heading="Need a hand?"
                                           body="Whether you're troubleshooting an issue or learning something new, our Help Center has you covered."
                                           buttonText="Visit the Help Center"
                                           buttonIcon="external-link-square-alt"
                                           buttonUrl="https://help.rentmoola.com/hc/en-us/sections/360006583852-Landlords" />

                            <Modal id="decline-lease-application" theme="primary" iconType="fas" iconName="question-circle" title="Decline Application" body="Are you sure you want to decline this lease application? The applicant will receive a notification letting them know their application has been declined.">
                                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => {$("#lease-application").modal("show")}}>
                                    <FormattedMessage id="button.back" />
                                </button>
                                <button className="btn btn-primary btn-lg" data-dismiss="modal" onClick={() => this.declineLeaseApplication(this.state.leaseApplication)}>
                                    Decline Application
                                </button>
                            </Modal>

                            <div className="modal fade" id="approve-lease-application" tabIndex="-1" role="dialog" aria-labelledby="approve-lease-application-label" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                                    <div className="modal-content shadow">
                                        <form onSubmit={this.approveLeaseApplication} autoComplete="off">
                                            <div className="modal-header bg-dark text-white">
                                                <h5 className="modal-title" id="approve-lease-application-label">
                                                    Approve Application
                                                </h5>
                                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                                </button>
                                            </div>

                                            <div className="modal-body bg-secondary">
                                                <p className="mb-0">
                                                    Before approving this applicant, you may provide the following lease information prior to sending them an invitation to create their account.
                                                </p>
                                            </div>

                                            <React.Fragment>

                                                <div className="modal-body">

                                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label col-form-label-sm" htmlFor="accountNumber">
                                                            Account ID
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <div className="input-group input-group-sm">

                                                                <input id="accountNumber" name="accountNumber" value={this.state.leaseApplication['accountNumber'] || ""} className="form-control mb-0" onChange={this.handleChangeAccountNumber} />

                                                                {this.state.integrationId &&
                                                                <div className="input-group-append">
                                                                    <div className="btn btn-secondary" onClick={() => this.initIntegrationCustomers()}>
                                                                        Search...
                                                                    </div>
                                                                </div>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <FieldDate id="startDate" label="Start Date" parent={this} value={this.state['startDate'] || null} />

                                                    <FieldSelect id="monthlyPaymentDueDay" label="Payment Due" parent={this} value={this.state['monthlyPaymentDueDay']}>
                                                        <option value={0}>No monthly payment due date</option>
                                                        <option value={1}>1st day of every month</option>
                                                        <option value={2}>2nd day of every month</option>
                                                        <option value={3}>3rd day of every month</option>
                                                        <option value={4}>4th day of every month</option>
                                                        <option value={5}>5th day of every month</option>
                                                        <option value={6}>6th day of every month</option>
                                                        <option value={7}>7th day of every month</option>
                                                        <option value={8}>8th day of every month</option>
                                                        <option value={9}>9th day of every month</option>
                                                        <option value={10}>10th day of every month</option>
                                                        <option value={11}>11th day of every month</option>
                                                        <option value={12}>12th day of every month</option>
                                                        <option value={13}>13th day of every month</option>
                                                        <option value={14}>14th day of every month</option>
                                                        <option value={15}>15th day of every month</option>
                                                        <option value={16}>16th day of every month</option>
                                                        <option value={17}>17th day of every month</option>
                                                        <option value={18}>18th day of every month</option>
                                                        <option value={19}>19th day of every month</option>
                                                        <option value={20}>20th day of every month</option>
                                                        <option value={21}>21st day of every month</option>
                                                        <option value={22}>22nd day of every month</option>
                                                        <option value={23}>23rd day of every month</option>
                                                        <option value={24}>24th day of every month</option>
                                                        <option value={25}>25th day of every month</option>
                                                        <option value={26}>26th day of every month</option>
                                                        <option value={27}>27th day of every month</option>
                                                        <option value={28}>28th day of every month</option>
                                                        <option value={29}>29th day of every month</option>
                                                        <option value={30}>30th day of every month</option>
                                                        <option value={31}>31st day of every month</option>
                                                    </FieldSelect>

                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label col-form-label-sm">
                                                            Charges
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <FieldCharges customChargeCodes={this.state.customChargeCodes.length > 0 ? this.state.customChargeCodes : null} charges={this.state.charges} addCharge={this.addCharge} removeCharge={this.removeCharge} importCharges={(this.state.integrationId && this.state.leaseApplication.accountNumber) ? this.importCharges : null} size="sm" parent={this} />
                                                        </div>
                                                    </div>

                                                    <FieldCheckbox id="requireAutoPay" label="Auto Pay" fieldLabel="Require this tenant to set up automatic monthly payments" parent={this} value={this.state['requireAutoPay']} />

                                                </div>

                                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                                    <div className="row">
                                                        <div className="col-4">
                                                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $('#lease-application').modal('show')}>Back</button>
                                                        </div>
                                                        <div className="col-8 text-right">
                                                            <button type="submit" className="btn btn-primary btn-lg ml-2">Approve Application</button>
                                                        </div>
                                                    </div>

                                                </div>

                                            </React.Fragment>

                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="search-tenant" tabIndex="-1" role="dialog" aria-labelledby="search-tenant-label" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                                    <div className="modal-content shadow">
                                        <form onSubmit={this.searchIntegrationCustomers}>

                                            <div className="modal-header bg-dark text-white">
                                                <h5 className="modal-title" id="search-tenant-label">
                                                    Search Tenants
                                                </h5>
                                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                                </button>
                                            </div>

                                            <div className="modal-body bg-secondary">
                                                <p className="mb-0">
                                                    Select the tenant from your integrated system using the available search fields below.
                                                </p>
                                            </div>

                                            <div className="modal-body">

                                                <Alert validationList={this.state.validationList} validationType="danger"/>

                                                <FieldText id="tenantId" label="Account ID" model="integrationSearch" parent={this} value={this.state.integrationSearch['tenantId']} />

                                                <div className="divider">
                                                    <span className="small text-muted font-italic text-uppercase">or</span>
                                                </div>

                                                <FieldText id="propertyId" label="Property ID" model="integrationSearch" parent={this} disabled={true} value={this.state.integrationSearch['propertyId']} />

                                                <FieldText id="firstName" label="First Name" model="integrationSearch" parent={this} value={this.state.integrationSearch['firstName']} />

                                                <FieldText id="lastName" label="Last Name" model="integrationSearch" parent={this} value={this.state.integrationSearch['lastName']} />

                                                <FieldText id="email" label="Email" type="email" model="integrationSearch" parent={this} value={this.state.integrationSearch['email']} />

                                                <FieldText id="phone" label="Phone" type="tel" model="integrationSearch" parent={this} value={this.state.integrationSearch['phone']} />

                                            </div>

                                            {this.state.integrationCustomerList.length > 0 &&
                                            <div className="modal-body modal-body-table overflow-auto border-top" style={{maxHeight: '400px'}}>
                                                <div className="card-body card-body-table">
                                                    <table className="table table-bordered table-hover border">
                                                        <thead>
                                                        <tr>
                                                            <th width="25%">First Name</th>
                                                            <th width="25%">Last Name</th>
                                                            <th width="25%">Unit</th>
                                                            <th width="25%">Account ID</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.integrationCustomerList.map((data, key) => {
                                                            return (
                                                                <tr key={key} onClick={() => this.selectIntegrationCustomer(data)} className="c-pointer">
                                                                    <td className="">
                                                                        {data.firstName}
                                                                    </td>
                                                                    <td className="">
                                                                        {data.lastName}
                                                                    </td>
                                                                    <td className="">
                                                                        {data.unit}
                                                                    </td>
                                                                    <td className="">
                                                                        {data.externalId}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            }

                                            <div className="modal-footer bg-secondary rounded-bottom d-block">

                                                <div className="row">
                                                    <div className="col-6 text-left">
                                                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $('#approve-lease-application').modal('show')}>Back</button>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <button type="submit" className="btn btn-primary btn-lg">Search</button>
                                                    </div>
                                                </div>

                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-8">

                            <Switch>
                                <PrivateRoute exact path="/manager/applications/:leaseApplicationId/view" type="TYPE_MANAGER" parent={this} component={Application} leaseApplication={this.state.leaseApplication} />
                                <PrivateRoute exact path="/manager/applications/:leaseApplicationId/screening" type="TYPE_MANAGER" parent={this} component={Screening} leaseApplication={this.state.leaseApplication} />
                            </Switch>

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Navigation.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Navigation);