import React from 'react';
import axios from "axios";
import * as constants from "../../util/constants";
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";

class Mimic extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.mimicUser = this.mimicUser.bind(this);

        this.searchBroadcastMessages = this.searchBroadcastMessages.bind(this);
    }

    /**
     * Mimic a user by overwriting the current session role with their session. Based on the session role's user type,
     * redirect to the appropriate page upon successfully mimicking the user.
     */
    mimicUser() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.props.userType}/${this.props.userId}/mimic`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);

            // Store the property lease ID in the localStorage for post-mimic redirect purposes
            localStorage.setItem('mimic_tenant_property_lease_id', this.props.propertyLeaseId);

            // Fetch broadcast messages
            this.searchBroadcastMessages();

            // Close any relevant modals
            $('#system-account').modal('hide');

            // Refresh the app state
            this.props.parent.props.refreshAppState();

            // Decipher the token
            const access_token = localStorage.getItem('access_token');
            const base64Url = access_token.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            const userSession = JSON.parse(window.atob(base64));
            const sessionRole = JSON.parse(userSession.sessionRole);

            this.props.parent.props.history.push(this.handleLoginRedirect(sessionRole));

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Fetch available broadcast messages for the user being logged in and store the data in the local storage.
     */
    searchBroadcastMessages() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/broadcast_message/active`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            // Clear any existing broadcast messages
            localStorage.removeItem('broadcast_messages');

            // Store the broadcast messages in the localStorage
            localStorage.setItem('broadcast_messages', JSON.stringify(response.data));

            // Refresh the app state
            this.props.parent.props.refreshAppState();

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A simple button that initiates the action of mimicking a user.
     */
    render() {

        return (
            <React.Fragment>

                {this.props.displayType === 'list' &&
                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.mimicUser()}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                            <FontAwesomeIcon icon={['fas', 'street-view']} className="fa-fw va-b mr-2" />
                            {this.props.label}
                        </div>
                    </div>
                </div>
                }

                {this.props.displayType === 'button' &&
                <div className="btn btn-primary btn-lg" onClick={() => this.mimicUser()}>
                    {this.props.label}
                </div>
                }

            </React.Fragment>
        )
    };
}

Mimic.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Mimic);