import React from 'react';
import Propertii from "../../common/Propertii";
import {injectIntl, intlShape} from "react-intl";
import TransactionList from "../../common/Transactions";

class Transactions extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {

        return(
            <React.Fragment>

                <TransactionList propertyId={this.props.match.params.propertyId}
                                 userType="TYPE_LANDLORD" />

            </React.Fragment>
        )
    };
}

Transactions.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Transactions);