import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import Propertii from "../../common/Propertii";
import FieldText from "../../common/FieldText";
import FieldAddress from "../../common/FieldAddress";

class Property extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            property: {},
            validationList: []
        };

        this.saveProperty = this.saveProperty.bind(this);
    }

    /**
     * Fetch a new instance of a property object on the mounting of the component.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${this.props.match.params.propertyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                property: response.data
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle the submission of the form.
     *
     * @param event - The event container.
     */
    saveProperty(event) {

        event.preventDefault();

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, this.state.property, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved'
                    }
                }],
            });

            this.props.refreshProperty(this.props.match.params.propertyId);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.saveProperty}>

                    <div className="card">
                        <div className="card-header">
                            Property
                        </div>
                        <div className="card-body">

                            <FieldText id="propertyName" label="Property Name" model="property" parent={this} value={this.state.property['propertyName']}  />

                            <FieldAddress model="property" parent={this} value={this.state.property} />

                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonSave />

                        </div>
                    </div>

                </form>

            </React.Fragment>
        )
    };
}

export default Property;