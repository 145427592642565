import React from 'react';
import Alert from "../../common/Alert";
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import axios from "axios";
import * as constants from "../../../util/constants";
import FieldSelect from "../../common/FieldSelect";
import FieldText from "../../common/FieldText";
import ButtonSave from "../../common/ButtonSave";
import FieldTimezone from "../../common/FieldTimezone";
import Spinner from "../../common/Spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FieldCheckbox from "../../common/FieldCheckbox";
import Modal from "../../common/Modal";

class Integrations extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            company: {},
            settings: {},
            customSettings: [{}],
            validationList: [],
        };

        this.initIntegration = this.initIntegration.bind(this);
        this.saveIntegration = this.saveIntegration.bind(this);
        this.testIntegration = this.testIntegration.bind(this);
        this.deleteIntegration = this.deleteIntegration.bind(this);

        this.addCustomIntegrationSetting = this.addCustomIntegrationSetting.bind(this);
        this.removeCustomIntegrationSetting = this.removeCustomIntegrationSetting.bind(this);
        this.handleChangeCustomIntegrationSettings = this.handleChangeCustomIntegrationSettings.bind(this);
    }

    /**
     * On mounting of the component, fetch the company and it's corresponding integration information if available for
     * displaying. If a custom integration is fetched, assign the settings of the integration to a local state value
     * for editing.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.match.params.companyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                company: response.data
            }));

            if(response.data.integrationId) {
                axios.get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/${response.data.integrationId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    let customSettings = [{}];

                    if(response.data.integrationType === 'CUSTOM') {
                        Object.entries(response.data.settings).forEach(([customSettingKey, customSettingValue]) => {
                            customSettings.push({
                                customSettingKey: customSettingKey,
                                customSettingValue: customSettingValue
                            });
                        });
                    }

                    this.setState(prevState => ({
                        ...prevState,
                        integration: response.data,
                        settings: response.data.settings,
                        customSettings: customSettings
                    }));

                }).catch(error => {
                    console.error(error);
                });
            }

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Initialize a new instance of an integration object.
     */
    initIntegration() {

        axios.get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                integration: response.data,
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Create or save an existing integration object for the company being viewed.
     */
    saveIntegration(event) {

        event.preventDefault();

        let integration = this.state.integration;

        integration.settings = this.state.settings;
        integration.active = true;
        integration.companyId = this.state.company.id;
        integration.companyName = this.state.company.name;

        if(integration.integrationType === 'CUSTOM') {
            this.state.customSettings.forEach((data, key) => {
                integration.settings[data.customSettingKey] = data.customSettingValue;
            });
        }

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        if(!this.state.company.integrationId) {

            axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/create`, integration, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    integration: response.data
                }));

                axios.patch(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.company.id}/update`, {
                    integrationId: response.data.id
                }, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        spinner: false,
                        company: response.data,
                        validationList: [{
                            fields: {},
                            alert: {
                                type: 'primary',
                                code: 'admin.companies.integrations.created'
                            }
                        }],
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });

            }).catch(error => {
                this.handleValidation(error);
            });

        }

        if(this.state.company.integrationId) {

            axios.put(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/update`, integration, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved'
                        }
                    }],
                }));
            }).catch(error => {
                this.handleValidation(error);
            });

        }

        window.scrollTo(0, 0);
    }

    /**
     * Test an integration to ensure fields are validated.
     */
    testIntegration() {

        let integration = this.state.integration;

        integration.settings = this.state.settings;
        integration.active = true;
        integration.companyId = this.state.company.id;
        integration.companyName = this.state.company.name;

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/test`, integration, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.integrations.tested'
                    }
                }],
            });
        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Set the integration ID value of the company to null. This does not delete the integration itself.
     */
    deleteIntegration() {

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.company.id}/update`, {
            integrationId: null
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                company: response.data,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.integrations.deleted'
                    }
                }],
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Add a new blank charge to the list of charges.
     */
    addCustomIntegrationSetting() {

        this.setState(prevState => ({
            ...prevState,
            customSettings: [...prevState.customSettings, {
                customSettingKey: '',
                customSettingValue: ''
            }]
        }));
    }

    /**
     * Remove a specific setting from the list of custom settings.
     *
     * @param index - The array index of the custom setting to remove.
     */
    removeCustomIntegrationSetting(index) {

        let customSettings = this.state.customSettings;

        customSettings.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            customSettings: customSettings
        }));
    }

    /**
     * Handle changes to the selected custom integration setting. Handles both the setting key and value fields for each
     * setting.
     *
     * @param event - The event container.
     */
    handleChangeCustomIntegrationSettings(event) {

        let customSettings = this.state.customSettings;

        let chargeIdSplit = (event.target.name).split("-");
        let chargeIdIndex = chargeIdSplit[chargeIdSplit.length - 1];

        // Handle key changes
        if(event.target.name.split('-')[2] === 'key') {
            customSettings[chargeIdIndex] = {
                customSettingKey: event.target.value,
                customSettingValue: customSettings[event.target.name.substr(-1)].customSettingValue
            };
        }

        // Handle value changes
        if(event.target.name.split('-')[2] === 'value') {
            customSettings[chargeIdIndex] = {
                customSettingKey: customSettings[event.target.name.substr(-1)].customSettingKey,
                customSettingValue: event.target.value
            };
        }

        this.setState(prevState => ({
            ...prevState,
            customSettings: customSettings
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The company integrations component.
     */
    render() {

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.saveIntegration}>

                    <div className="card">

                        <div className="card-header">
                            Integrations
                        </div>

                        {this.state.integration == null &&
                        <div className="card-body text-center">

                            <p className="pt-3 pb-1">This company does not currently have an integration set up.</p>

                            <div className="btn btn-primary btn-lg mb-4" onClick={() => this.initIntegration()}>
                                Add an Integration
                            </div>

                        </div>
                        }

                        {this.state.integration != null &&
                        <div className="card-body">

                            <FieldSelect id="integrationType" label="Integration Type" model="integration" disabled={this.state.integration.createDate} parent={this} value={this.state.integration['integrationType']}>
                                <option value="">Select an integration type...</option>
                                <option value="MRI">MRI</option>
                                <option value="YARDI">Yardi</option>
                                <option value="RESMAN">ResMan</option>
                                <option value="CUSTOM">Custom</option>
                            </FieldSelect>

                            <FieldTimezone id="timeZone" label="Timezone" model="integration" parent={this} value={this.state.integration['timeZone']} />

                            <FieldCheckbox id="integratedBalance" label="Integrated Balance" fieldLabel="Utilize the balance due from the integrated system for payments" help="If checked, Letus will NOT automatically post monthly charges. Instead, a balance must be imported manually from the integrated system every month." model="integration" parent={this} value={this.state.integration['integratedBalance']} />

                            {this.state.integration['integrationType'] === 'MRI' &&
                            <div className="">

                                <FieldText id="ClientId" label="Client ID" model="settings" parent={this} value={this.state.settings['ClientId']} />

                                <FieldText id="Database" label="Database" model="settings" parent={this} value={this.state.settings['Database']} />

                                <FieldText id="ApiUrl" label="API URL" model="settings" parent={this} value={this.state.settings['ApiUrl']} />

                                <FieldText id="UserName" label="Username" model="settings" parent={this} value={this.state.settings['UserName']} />

                                <FieldText id="Password" label="Password" model="settings" parent={this} value={this.state.settings['Password']} />

                            </div>
                            }

                            {this.state.integration['integrationType'] === 'YARDI' &&
                            <div className="">

                                <FieldText id="ApiUrl" label="API URL" model="settings" parent={this} value={this.state.settings['ApiUrl']} />

                                <FieldText id="Database" label="Database" model="settings" parent={this} value={this.state.settings['Database']} />

                                <FieldText id="Username" label="Username" model="settings" parent={this} value={this.state.settings['Username']} />

                                <FieldText id="Password" label="Password" model="settings" parent={this} value={this.state.settings['Password']} />

                                <FieldText id="ServerName" label="Server Name" model="settings" parent={this} value={this.state.settings['ServerName']} />

                                <FieldText id="Interface" label="Interface" model="settings" parent={this} value={this.state.settings['Interface']} />

                                <FieldText id="Platform" label="Platform" model="settings" parent={this} value={this.state.settings['Platform']} />

                            </div>
                            }

                            {this.state.integration['integrationType'] === 'RESMAN' &&
                            <div className="">

                                <FieldText id="AccountId" label="Account ID" model="settings" parent={this} value={this.state.settings['AccountId']} />

                            </div>
                            }

                            {this.state.integration['integrationType'] === 'CUSTOM' &&
                            <div className="">

                                <FieldText id="pluginClass" label="Plugin Class" model="integration" parent={this} value={this.state.integration['pluginClass']} />

                                <div className="form-group row mb-2">

                                    <label className="col-sm-3 col-form-label col-form-label-sm" htmlFor="customSettings">
                                        Settings
                                    </label>

                                    <div className="col-sm-9">

                                        {this.state.customSettings.map((data, key) => {
                                            return (
                                                <div className="input-group input-group-sm mb-3">

                                                    <input id={`custom-setting-key-${key}`} name={`custom-setting-key-${key}`} value={data.customSettingKey} placeholder="Name" className="form-control" onChange={this.handleChangeCustomIntegrationSettings} />

                                                    <input id={`custom-setting-value-${key}`} name={`custom-setting-value-${key}`} value={data.customSettingValue} placeholder="Value" className="form-control" onChange={this.handleChangeCustomIntegrationSettings} />

                                                    <div className="input-group-append">
                                                        <button className="btn btn-outline-primary" type="button" id="button-addon2" disabled={key === 0} title="You must provide at least one charge" onClick={() => this.removeCustomIntegrationSetting(key)}>
                                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw"/>
                                                        </button>
                                                    </div>

                                                </div>
                                            )
                                        })}

                                        <div className="btn btn-outline-primary btn-sm" onClick={() => this.addCustomIntegrationSetting()}>
                                            <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw"/> Add another setting
                                        </div>

                                    </div>

                                </div>

                            </div>
                            }

                        </div>
                        }

                    </div>

                    {this.state.integration != null &&
                    <div className="row">
                        <div className="col text-right">

                            {this.state.company.integrationId &&
                            <div className="btn btn-outline-primary btn-lg ml-2" data-toggle="modal" data-target="#delete-integration">
                                Delete
                            </div>
                            }

                            <div className="btn btn-primary btn-lg ml-2" onClick={() => this.testIntegration()}>
                                Test
                            </div>

                            <ButtonSave />

                        </div>
                    </div>
                    }

                </form>

                <Modal id="delete-integration" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Integration"
                       body="By deleting this integration, the integration details will be retained in the backend, but the the company will no longer be associated with the integration details.">
                    <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal">
                        <FormattedMessage id="button.close" />
                    </button>
                    <button onClick={() => {this.deleteIntegration()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                        Delete Integration
                    </button>
                </Modal>

            </React.Fragment>
        )
    };
}

Integrations.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Integrations);