import React from 'react';
import axios from "axios";
import * as constants from "../../util/constants";
import Table from "../common/Table";
import Propertii from "../common/Propertii";
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Alert from "../common/Alert";
import FieldText from "../common/FieldText";
import Spinner from "./Spinner";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import $ from "jquery";
import FieldSelect from "./FieldSelect";
import Moment from "react-moment";

class LeaseApplications extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        let leaseApplicationConditionList = [];

        if(this.props.propertyId) {
            leaseApplicationConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'propertyId',
                    fieldValue: this.props.propertyId
                }
            );
        }

        leaseApplicationConditionList.push(
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                operator: 'EQUALS',
                fieldName: 'status',
                fieldValue: 'PENDING'
            }
        );

        this.state = {

            company: {},
            leaseApplication: {},

            leaseApplicationSearchFilter: '',
            leaseApplicationExistingTenantFilter: '',
            leaseApplicationStatusFilter: 'PENDING',

            leaseApplicationLinkCopied: false,
            leaseApplicationEmail: {},
            leaseApplicationPageList: [{}],

            leaseApplicationEmailFields: {
                firstName: '',
                lastName: '',
                email: '',
                companyId: this.props.companyId,
                pageName: '',
            },

            leaseApplicationList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            leaseApplicationQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: leaseApplicationConditionList,
                joins: {
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'propertyId',
                        alias: 'p',
                        returnFields: ['propertyName', "street1"]
                    },
                },
            },

            validationList: [],

        };

        this.searchLeaseApplications = this.searchLeaseApplications.bind(this);
        this.filterLeaseApplications = this.filterLeaseApplications.bind(this);

        this.clearFilters = this.clearFilters.bind(this);

        this.selectLeaseApplication = this.selectLeaseApplication.bind(this);

        this.initLeaseApplicationEmail = this.initLeaseApplicationEmail.bind(this);
        this.sendLeaseApplicationEmail = this.sendLeaseApplicationEmail.bind(this);
    }

    /**
     * Retrieve list of lease applications on mounting of the component, as well as the company at hand.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.companyId}`,  {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                company: response.data,
            }));

        }).catch(error => {
            console.error(error);
        });

        this.searchLeaseApplications(1, 25, this.state.leaseApplicationQuery);
    }

    /**
     * Update the data table of lease applications.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchLeaseApplications(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                leaseApplicationList: {
                    ...response.data,
                    emptyUnfilteredList: this.state.leaseApplicationSearchFilter === '' && response.data.records.length === 0
                },
                leaseApplicationQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Select an existing lease application. Redirects the user to the application view component.
     *
     * @param leaseApplicationId - The ID of the lease application.
     */
    selectLeaseApplication(leaseApplicationId) {

        this.props.history.push(`/${this.props.userType.substring(5).toLowerCase()}/applications/${leaseApplicationId}/view`);
    }

    /**
     * Initialize the lease application email invitation flow. Creates a new instance of an email object and performs a
     * search for all lease application pages related to the company ID provided in the props, which is used as a merge
     * field in the email that will be sent out.
     */
    initLeaseApplicationEmail() {

        let leaseApplicationPageQuery = {
            orderBy: 'DESC',
            orderByFields: ['pageName'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'companyId',
                    fieldValue: this.props.companyId
                }
            ],
        };

        axios.get(`${constants.REACT_APP_HOST_API_URL}/email/new`,  {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let email = response.data;

            axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application_page/search`, {
                orderBy: leaseApplicationPageQuery.orderBy,
                orderByFields: leaseApplicationPageQuery.orderByFields,
                conditionList: leaseApplicationPageQuery.conditionList
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    leaseApplicationEmail: email,
                    leaseApplicationPageList: response.data.records
                }));

            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });

        $('#new-applicant').modal('hide');
    }

    /**
     * Send an email to a specific email address with a link to the company's lease application submission form.
     *
     * @param event - The event container.
     */
    sendLeaseApplicationEmail(event) {

        event.preventDefault();

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/invite`, {
            firstName: this.state.leaseApplicationEmailFields.firstName,
            lastName: this.state.leaseApplicationEmailFields.lastName,
            email: this.state.leaseApplicationEmailFields.email,
            applicationPath: this.state.leaseApplicationEmailFields.pageName ? `apply/${this.state.leaseApplicationEmailFields.pageName}` : `apply?c=${this.props.companyId}&fn=${this.state.leaseApplicationEmailFields.firstName}&ln=${this.state.leaseApplicationEmailFields.lastName}&e=${encodeURIComponent(this.state.leaseApplicationEmailFields.email)}`,
            companyName: this.state.company.name
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.applications.sent'
                    },
                    values: {
                        email: this.state.leaseApplicationEmailFields.email,
                    }
                }],
            });

            $('#send-application').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle submitting the lease application search filter field by adjusting the search query and initiating a new
     * search.
     *
     * @param event - The event container.
     */
    filterLeaseApplications(event) {

        if(event != null) {
            event.preventDefault();
        }

        this.setState({
            spinner: true
        });

        let leaseApplicationQuery = this.state.leaseApplicationQuery;

        leaseApplicationQuery.conditionList = [];

        if(this.state.leaseApplicationSearchFilter === '') {

            if(this.props.propertyId) {
                leaseApplicationQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'propertyId',
                        fieldValue: this.props.propertyId
                    }
                );
            }

        }

        if(this.state.leaseApplicationSearchFilter !== '') {
            leaseApplicationQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.state.leaseApplicationSearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'firstName,lastName,email,unit,p.street1',
                    operator: 'MATCH',
                    fieldValue: this.state.leaseApplicationSearchFilter
                }
            );
        }

        if(this.state.leaseApplicationExistingTenantFilter !== '') {
            leaseApplicationQuery.conditionList.push(
                {
                    type: 'BOOLEAN',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'existingTenant',
                    fieldValue: this.state.leaseApplicationExistingTenantFilter === 'true'
                }
            );
        }

        leaseApplicationQuery.conditionList.push(
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                operator: 'EQUALS',
                fieldName: 'status',
                fieldValue: this.state.leaseApplicationStatusFilter
            }
        );

        this.setState({
            leaseApplicationQuery: leaseApplicationQuery,
        });

        this.searchLeaseApplications(1, 25, leaseApplicationQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter query.
     */
    clearFilters() {

        this.setState({
            leaseApplicationSearchFilter: '',
            leaseApplicationExistingTenantFilter: '',
            leaseApplicationStatusFilter: 'PENDING',
        }, () => {

            this.filterLeaseApplications();

        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {

        const {formatMessage} = this.props.intl;

        let leaseApplicationColumnLabels = {createDate: 'Date', firstName: 'Applicant', unit: 'Unit', 'p.street1': 'Property', existingTenant: 'Type', status: 'Status'};
        let leaseApplicationColumnWidths = ['16%', '16%', '16%', '16%', '16%', '16%'];

        if(this.props.context === 'property') {
            leaseApplicationColumnLabels = {createDate: 'Date', firstName: 'Applicant', unit: 'Unit', existingTenant: 'Type', status: 'Status'};
            leaseApplicationColumnWidths = ['20%', '20%', '20%', '20%', '20%'];
        }

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} validationType="primary" />

                {this.props.history.location.state &&
                <Alert validationList={this.props.history.location.state.validationList} validationType="primary" />
                }

                <div className="card">

                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Applications
                            </div>
                            <div className="col text-right">

                                {this.props.newApplicant &&
                                <React.Fragment>

                                    {!this.props.newApplicantDisabled &&
                                    <div data-toggle="modal" data-target="#new-applicant" className="btn btn-primary btn-sm">
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> New Applicant
                                    </div>
                                    }

                                    {this.props.newApplicantDisabled &&
                                    <div className="btn btn-primary btn-sm ml-2 disabled" data-toggle={this.props.newApplicantTooltip ? 'tooltip' : ''} data-placement="top" title={this.props.newApplicantTooltip}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> New Applicant
                                    </div>
                                    }

                                </React.Fragment>
                                }

                            </div>
                        </div>
                    </div>

                    <div className="card-header gotham border-top py-3 bg-secondary">
                        <form onSubmit={this.filterLeaseApplications}>
                            <div className="media">
                                <div className="media-body align-self-center mr-3">
                                    <FieldText id="leaseApplicationSearchFilter" label="Search" labelClass="d-none"
                                               fieldColumns="12"
                                               labelColums="0" placeholder="Filter by name, unit, or property..." parent={this}
                                               value={this.state.leaseApplicationSearchFilter}/>
                                </div>
                                <div className="media-body align-self-center mr-3">
                                    <FieldSelect id="leaseApplicationExistingTenantFilter" labelColumns="0"
                                                 fieldColumns="12" parent={this}
                                                 value={this.state.leaseApplicationExistingTenantFilter}>
                                        <option value="">All Application Types</option>
                                        <option value={false}>New Applicants</option>
                                        <option value={true}>Existing Tenants</option>
                                    </FieldSelect>
                                </div>
                                <div className="media-body align-self-center mr-3">
                                    <FieldSelect id="leaseApplicationStatusFilter" labelColumns="0"
                                                 fieldColumns="12" parent={this}
                                                 value={this.state.leaseApplicationStatusFilter}>
                                        <option value="PENDING">Pending Applications</option>
                                        <option value="APPROVED">Approved Applications</option>
                                        <option value="DECLINED">Declined Applications</option>
                                        <option value="CANCELLED">Cancelled Applications</option>
                                    </FieldSelect>
                                </div>
                                <div className="align-self-center text-right">
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                            <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                        </button>
                                        <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                            <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    {this.state.leaseApplicationList.emptyUnfilteredList &&
                    <div className="card-body bg-secondary py-5 border-top">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="text-center text-muted">
                                    <div className="fa-stack fa-2x mb-2">
                                        <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                        <FontAwesomeIcon icon={['far', 'address-card']} className="fa-stack-1x fa-inverse" />
                                    </div>
                                </div>
                                <div className="text-center text-muted">
                                    <small>
                                        Lease applications for prospective tenants will appear here.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {!this.state.leaseApplicationList.emptyUnfilteredList &&
                    <Table columns={leaseApplicationColumnLabels}
                           columnWidths={leaseApplicationColumnWidths}
                           headerClass="c-pointer"
                           data={this.state.leaseApplicationList}
                           query={this.state.leaseApplicationQuery}
                           sortEnabled={true}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           parent={this}
                           updateFunction={this.searchLeaseApplications}>
                        <tbody>
                        {this.state.leaseApplicationList.records.map((data, key) => {
                            return (
                                <tr key={key} onClick={() => this.selectLeaseApplication(data.id)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            <Moment format="MMM DD, YYYY">
                                                {data.createDate}
                                            </Moment>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.firstName} {data.lastName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.unit}
                                        </div>
                                    </td>
                                    {this.props.context !== 'property' &&
                                    <td>
                                        {data.joins && data.joins.p &&
                                        <div className="">
                                            {data.joins.p.street1}
                                        </div>
                                        }
                                    </td>
                                    }
                                    <td>
                                        <div className="">
                                            {data.existingTenant &&
                                            <React.Fragment>
                                                Existing Tenant <FontAwesomeIcon icon={['fas', 'question-circle']} className="fa-fw" data-toggle="tooltip" data-placement="top" title="This applicant has indicated that they already have a lease with one of your properties, and would like to create a Letus account" />
                                            </React.Fragment>
                                            }
                                            {!data.existingTenant &&
                                            <React.Fragment>
                                                New Applicant
                                            </React.Fragment>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-nowrap">
                                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.leaseApplication.status." + data.status + ".class"})}`}/>
                                            <span className="ml-1"><FormattedMessage id={"enum.leaseApplication.status." + data.status}/></span>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    }

                </div>

                <div className="modal fade" id="new-applicant" tabIndex="-1" role="dialog" aria-labelledby="new-applicant-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="new-applicant-label">
                                    New Applicant
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="row justify-content-center">

                                    <div className="col-md-6 text-center mb-4 mb-sm-0">
                                        <div className="px-3">
                                            <h5 className="display-5 mb-4">
                                                Share a Link
                                            </h5>
                                            <FontAwesomeIcon icon={['fal', 'link']} className="fa-fw mb-4 text-primary" size="3x" />
                                            <p className="mb-4">
                                                Copy the link below to your website or external rental listing service to accept applications.
                                            </p>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" value={`${constants.REACT_APP_HOST_ROOT_URL}/apply?c=${this.props.companyId}`} aria-label="Application URL" aria-describedby="button-addon2" />
                                                <div className="input-group-append">
                                                    <CopyToClipboard text={`${constants.REACT_APP_HOST_ROOT_URL}/apply?c=${this.props.companyId}`} onCopy={() => this.setState({leaseApplicationLinkCopied: true})}>
                                                        <button className="btn btn-primary" type="button" data-toggle="tooltip" data-placement="top" title="Copy to clipboard">
                                                            <FontAwesomeIcon icon={['far', this.state.leaseApplicationLinkCopied ? 'check' : 'copy']} className="fa-fw" />
                                                        </button>
                                                    </CopyToClipboard>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-6 text-center mb-4 mb-sm-0 border-left">
                                        <div className="px-3">
                                            <h5 className="display-5 mb-4">
                                                Send an Email
                                            </h5>
                                            <FontAwesomeIcon icon={['fal', 'envelope-open-text']} className="fa-fw mb-4 text-primary" size="3x" />
                                            <p className="mb-4">
                                                Click the button below to send a personalized invitation to fill out an application.
                                            </p>
                                            <div data-toggle="modal" data-target="#send-application" className="btn btn-primary btn-md" onClick={() => this.initLeaseApplicationEmail()}>
                                                Send an Email
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="modal fade" id="send-application" tabIndex="-1" role="dialog" aria-labelledby="send-application-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.sendLeaseApplicationEmail}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="send-application-label">
                                        Send an Email
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Enter the details of the individual you would like to send a personalized application link to.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="firstName" label="First Name" model="leaseApplicationEmailFields" required={true} parent={this} value={this.state.leaseApplicationEmailFields['firstName']} />

                                    <FieldText id="lastName" label="Last Name" model="leaseApplicationEmailFields" required={true} parent={this} value={this.state.leaseApplicationEmailFields['lastName']} />

                                    <FieldText id="email" type="email" label="Email" model="leaseApplicationEmailFields" required={true} parent={this} value={this.state.leaseApplicationEmailFields['email']} />

                                    {this.state.leaseApplicationPageList.length > 0 &&
                                    <FieldSelect id="pageName" label="Portal" help="Use this field to determine what page the recipient will be directed to from your email." model="leaseApplicationEmailFields" parent={this} value={this.state.leaseApplicationEmailFields['pageName']}>
                                        <option value="">Default lease application portal</option>
                                        {this.state.leaseApplicationPageList &&
                                        <React.Fragment>
                                            {this.state.leaseApplicationPageList.map((data, key) => {
                                                return (
                                                    <option key={key} value={data.pageName}>{constants.REACT_APP_HOST_ROOT_URL}/apply/{data.pageName}</option>
                                                );
                                            })}
                                        </React.Fragment>
                                        }
                                    </FieldSelect>
                                    }

                                </div>

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-6 text-left">
                                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                        </div>
                                        <div className="col-6 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">Send Email</button>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>

                    </div>
                </div>

            </React.Fragment>
        )
    };
}

LeaseApplications.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(LeaseApplications);