import React from 'react';
import {FormattedMessage, FormattedNumber, injectIntl, intlShape} from 'react-intl';
import Propertii from "../../common/Propertii";
import axios from "axios";
import * as constants from "../../../util/constants";
import $ from "jquery";
import FieldText from "../../common/FieldText";
import Alert from "../../common/Alert";
import Spinner from "../../common/Spinner";
import FieldAddress from "../../common/FieldAddress";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Banner from "../../common/Banner";
import FieldCompanyType from "../../common/FieldCompanyType";
import FieldCheckbox from "../../common/FieldCheckbox";
import CardBrandIcon from "../../common/CardBrandIcon";
import creditCard from "../../../media/img/payments/credit-card.png";
import FieldCardNumber from "../../common/FieldCardNumber";
import FieldSelect from "../../common/FieldSelect";
import {Link} from "react-router-dom";
import ModalUnionPay from "../../common/ModalUnionPay";
import CardPreview from "../../common/CardPreview";
import TermsContents from "../../common/Terms";
import FieldPhone from "../../common/FieldPhone";
import FieldBirthDate from "../../common/FieldBirthDate";
import Modal from "../../common/Modal";

class Signup extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            landlord: {},
            company: {},
            billingAddress: {},
            paymentMethod: {},
            paymentTransaction: {},

            address: {},
            addressList: [],

            showAddressForm: false,
            editAddress: false,

            landlordType: '',

            paymentMethodList: [],

            showCreditCardForm: false,
            showBillingAddressForm: false,
            populateBillingAddressForm: false,
            creditCardSecurityCodeLabel: 'CVV',

            baseSignupFee: 0,

            termsCompleted: false,
            landlordCompleted: false,
            addressesCompleted: false,
            companyCompleted: false,
            paymentMethodCompleted: false,

            validationList: [],

        };

        this.saveTerms = this.saveTerms.bind(this);
        this.saveLandlord = this.saveLandlord.bind(this);
        this.saveAddresses = this.saveAddresses.bind(this);
        this.saveCompany = this.saveCompany.bind(this);
        this.savePaymentMethod = this.savePaymentMethod.bind(this);

        this.editTerms = this.editTerms.bind(this);
        this.editLandlord = this.editLandlord.bind(this);
        this.editAddresses = this.editAddresses.bind(this);
        this.editCompany = this.editCompany.bind(this);
        this.editPaymentMethod = this.editPaymentMethod.bind(this);

        this.searchPaymentMethods = this.searchPaymentMethods.bind(this);
        this.initPaymentMethod = this.initPaymentMethod.bind(this);
        this.initBillingAddress = this.initBillingAddress.bind(this);

        this.getSignupFee = this.getSignupFee.bind(this);
        this.paySignupFee = this.paySignupFee.bind(this);

        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
        this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
        this.handleChangePopulateBillingAddress = this.handleChangePopulateBillingAddress.bind(this);
        this.handleChangeLandlordType = this.handleChangeLandlordType.bind(this);
    }

    /**
     * On mounting of the component, fetch the landlord data based on the log in session, as well as new instances of
     * address objects for the current and previous addresses, in addition to a new instance of a company object. If the
     * status of the application is 'APPLIED', pre-fill the application data and jump straight to the payment method
     * portion of the landlord application flow.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                landlord: response.data
            }));

            if(response.data.applicationStatus === 'APPLIED') {

                let addressList = [];

                addressList.push(JSON.parse(response.data.customFields.address));

                if(response.data.customFields.prevAddresses) {
                    JSON.parse(response.data.customFields.prevAddresses).forEach((address, key) => {
                        addressList.push(address);
                    });
                }

                this.setState(prevState => ({
                    ...prevState,
                    addressList: addressList,
                    termsCompleted: true,
                    landlordCompleted: true,
                    addressesCompleted: true,
                    companyCompleted: true,
                    paymentMethodCompleted: false,
                    validationList: [],
                }));

                this.searchPaymentMethods();
            }

        }).catch(error => {
            this.handleValidation(error);
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                company: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });

        this.getSignupFee('');
    }

    /**
     * Save the terms of use portion of the landlord application flow.
     *
     * @param event - The event container.
     */
    saveTerms(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            termsCompleted: true,
            landlordCompleted: false,
            addressesCompleted: false,
            companyCompleted: false,
            paymentMethodCompleted: false,
            validationList: [],
        }));

        this.searchPaymentMethods();
    }

    /**
     * Save the landlord details portion of the landlord application flow.
     *
     * @param event - The event container.
     */
    saveLandlord(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            landlordCompleted: true,
            addressesCompleted: false,
            companyCompleted: false,
            paymentMethodCompleted: false,
            validationList: [],
        }));
    }

    /**
     * Save the address history portion of the landlord application flow. If less than 3 years of address(es) have been
     * provided, throw an error to the user prompting them to enter more address(es).
     *
     * @param event - The event container.
     */
    saveAddresses(event) {

        event.preventDefault();

        let addressList = this.state.addressList;

        if(this.state.showAddressForm) {

            if(this.state.editAddress) {
                addressList.forEach((address, key) => {
                    if(address.id === this.state.address.id) {
                        address = this.state.address;
                    }
                });
            }

            if(!this.state.editAddress) {
                addressList.push(this.state.address);
            }

            this.setState(prevState => ({
                ...prevState,
                showAddressForm: false,
                editAddress: false,
                addressList: addressList,
                validationList: [],
            }));
        }

        else {

            let monthsAtAddressTotal = 0;

            addressList.forEach((address, key) => {
                monthsAtAddressTotal = monthsAtAddressTotal + parseInt(address.monthsAtAddress);
            });

            if(monthsAtAddressTotal < 36) {

                this.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            code: 'landlord.signup.addresses.months'
                        }
                    }]
                }));

                window.scrollTo(0, 0);
            }

            if(monthsAtAddressTotal >= 36) {
                this.setState(prevState => ({
                    ...prevState,
                    addressesCompleted: true,
                    companyCompleted: false,
                    paymentMethodCompleted: false,
                    validationList: [],
                }));
            }
        }
    }

    /**
     * Save the company details portion of the landlord application flow. If successful, the application status of the
     * landlord will become 'APPLIED'.
     *
     * @param event - The event container.
     */
    saveCompany(event) {

        event.preventDefault();

        let application = {
            landlord: this.state.landlord,
            address: this.state.addressList[0]
        };

        let previousAddresses = [];

        this.state.addressList.forEach((address, key) => {
            if(address.addressType === 'PREVIOUS') {
                previousAddresses.push(address);
            }
        });

        if(previousAddresses.length > 0) {
            application.prevAddresses = previousAddresses;
        }

        if(this.state.landlordType === 'BUSINESS') {
            application.company = this.state.company;
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/landlord/apply`, application, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.props.refreshAppState();

            this.setState(prevState => ({
                ...prevState,
                landlord: response.data,
                companyCompleted: true,
                paymentMethodCompleted: false,
                validationList: [],
            }));

        }).catch(error => {

            this.handleValidation(error);

            if(error.response.data.errorCode === 'RM_ERROR_UNDER_AGE') {
                this.setState(prevState => ({
                    ...prevState,
                    termsCompleted: true,
                    landlordCompleted: false,
                    addressesCompleted: false,
                    companyCompleted: false,
                    paymentMethodCompleted: false,
                }));
            }

            window.scrollTo(0, 0);

        });
    }

    /**
     * Save the payment method portion of the landlord application flow.
     *
     * @param event - The event container.
     */
    savePaymentMethod(event) {

        if(event != null) {
            event.preventDefault();
        }

        if(this.state.possibleUnionPayBin) {

            $('#confirm-unionpay').modal('show');

            return null;
        }

        // Handle no selection
        if(Object.keys(this.state.paymentMethod).length === 0) {

            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: 'common.payment.method.null'
                    }
                }]
            });

            window.scrollTo(0, 0);

            return null;
        }

        let paymentMethod = this.state.paymentMethod;
        let billingAddress = this.state.billingAddress;

        // Handle 'securityCode' to 'cvv' conversions for credit cards
        paymentMethod.cvv = this.state.paymentMethod.securityCode;

        // Handle existing payment method selection
        if(!this.state.showCreditCardForm) {

            this.setState(prevState => ({
                ...prevState,
                paymentMethodCompleted: true,
                showCreditCardForm: false,
                showBillingAddressForm: false,
                validationList: []
            }));
        }

        // Create a new payment method if the new payment method form is shown
        if(this.state.showCreditCardForm) {

            this.setState(prevState => ({
                ...prevState,
                spinner: true
            }));

            // Delete the existing payment method, if there is one
            if(this.state.paymentMethodList[0]) {

                axios.delete(`${constants.REACT_APP_HOST_API_URL}/${this.state.paymentMethodList[0].type.substring(5).toLowerCase()}/${this.state.paymentMethodList[0].id}/delete`, {
                    headers: this.generateRequestHeaders()
                }).catch(error => {

                    this.handleValidation(error);

                    window.scrollTo(0, 0);

                });
            }

            axios.post(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/setuppayment`, {
                billingAddress: billingAddress,
                creditCard: paymentMethod,
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    paymentMethod: response.data,
                    paymentMethodCompleted: true,
                    showCreditCardForm: false,
                    showBillingAddressForm: false,
                    populateBillingAddressForm: false,
                    spinner: false,
                    validationList: [],
                }));

                this.searchPaymentMethods();

            }).catch(error => {

                this.handleValidation(error);

                window.scrollTo(0, 0);

            });
        }
    }

    /**
     * Edit the terms of use portion of the landlord application flow.
     */
    editTerms() {

        this.setState(prevState => ({
            ...prevState,
            termsCompleted: false,
            landlordCompleted: false,
            currentAddressCompleted: false,
            previousAddressCompleted: false,
            companyCompleted: false,
            paymentMethodCompleted: false,
        }));
    }

    /**
     * Edit the landlord details portion of the landlord application flow.
     */
    editLandlord() {

        this.setState(prevState => ({
            ...prevState,
            landlordCompleted: false,
            currentAddressCompleted: false,
            previousAddressCompleted: false,
            companyCompleted: false,
            paymentMethodCompleted: false,
        }));
    }

    /**
     * Edit the address history portion of the landlord application flow.
     */
    editAddresses() {

        this.setState(prevState => ({
            ...prevState,
            addressesCompleted: false,
            companyCompleted: false,
            paymentMethodCompleted: false,
        }));
    }

    /**
     * Edit the company details portion of the landlord application flow.
     */
    editCompany() {

        this.setState(prevState => ({
            ...prevState,
            companyCompleted: false,
            paymentMethodCompleted: false,
        }));
    }

    /**
     * Edit the payment method portion of the landlord application flow.
     */
    editPaymentMethod() {

        this.setState(prevState => ({
            ...prevState,
            paymentMethodCompleted: false,
        }));
    }

    /**
     * Initialize a new instance of an address object.
     */
    initAddress() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                showAddressForm: true,
                editAddress: false,
                address: {
                    ...response.data,
                    addressType: prevState.addressList.length === 0 ? 'RESIDENTIAL' : 'PREVIOUS'
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Edit an existing address from the list of addresses.
     *
     * @param address - The address to edit.
     */
    editAddress(address) {

        this.setState(prevState => ({
            ...prevState,
            showAddressForm: true,
            editAddress: true,
            address: address
        }));
    }

    /**
     * Delete an existing address from the list of addresses.
     *
     * @param address - The address object to delete.
     */
    deleteAddress(address) {

        let addressList = this.state.addressList;

        addressList.forEach((data, key) => {
            if(data.id === address.id) {
                addressList.splice(key, 1);
            }
        });

        this.setState(prevState => ({
            ...prevState,
            showAddressForm: false,
            editAddress: false,
            address: {},
            addressList: addressList,
        }));
    }

    /**
     * Initialize a new instance of a payment method. Upon submission, perform a search for billing addresses available
     * to the user to populate the billing address field.
     *
     * @param paymentType - The type of the payment method (i.e. credit_card, bank_account, etc.)
     */
    initPaymentMethod(paymentType) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${paymentType}/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethod: {
                    ...response.data,
                    userId: this.props.userSession.sessionRole.id,
                    userType: this.props.userSession.sessionRole.type
                },
                showCreditCardForm: paymentType === 'credit_card',
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Search for all available payment methods assigned to the user and add them to the list of payment methods for the
     * user to select from. Erase the list of payment methods prior to the search to ensure no duplicates are added.
     */
    searchPaymentMethods() {

        this.setState(prevState => ({
            ...prevState,
            paymentMethodList: [],
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/paymentmethods`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethodList: response.data
            }));

            if(response.data.length === 0) {
                this.initBillingAddress();
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of a billing address, assigning it to the payment method at hand and revealing the
     * billing address fields.
     */
    initBillingAddress() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                showBillingAddressForm: true,
                billingAddress: {
                    ...response.data,
                    addressType: 'BILLING',
                    userType: this.props.userSession.sessionRole.type,
                    userId: this.props.userSession.sessionRole.id
                },
                paymentMethod: {
                    ...prevState.paymentMethod,
                    billingAddressId: response.data.id
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Confirm and submit the landlord account setup fee payment.
     */
    paySignupFee() {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/paysignupfee`, {
            creditCardId: this.state.paymentMethod.id,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            localStorage.setItem('access_token', response.data.token);

            this.setState(prevState => ({
                ...prevState,
                paymentTransaction: response.data.payment,
                spinner: false
            }));

            $('#signup-complete').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Get the landlord sign up fee amount.
     */
    getSignupFee() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/signupfee`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                baseSignupFee: response.data
            }));

        }).catch(error => {

            this.handleValidation(error);

            window.scrollTo(0, 0);

        });
    }

    /**
     * Handle changes to the landlord type field.
     *
     * @param event - The event container.
     */
    handleChangeLandlordType(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            landlordType: event.target.value,
        }));
    }

    /**
     * Handle changes to the selected payment method. Parses the value of the selected payment method as JSON. If the
     * user happens to select an existing payment method while creating a new payment method, hide the new payment
     * method creation and billing address creation forms accordingly.
     *
     * @param event - The event container.
     */
    handleChangePaymentMethod(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: JSON.parse(event.target.value),
        }));

        // If selecting an existing method while currently entering a new method, close all new method forms
        if(this.state.showCreditCardForm) {
            this.setState(prevState => ({
                ...prevState,
                showCreditCardForm: false,
                showBillingAddressForm: false
            }));
        }
    }

    /**
     * Handle changes to the billing address field. If the 'Add new billing address...' option is selected, reveal the
     * billing address creation form.
     *
     * @param event - The event container.
     */
    handleChangeBillingAddress(event) {

        if(event.target.value === 'NEW') {
            this.initBillingAddress();
        }
    }

    /**
     * Handle changes to the populate billing address checkbox, allowing the user to populate all the billing address
     * information automatically using the residential address they provided in the previous step of the landlord
     * sign up flow.
     *
     * @param event - The event container.
     */
    handleChangePopulateBillingAddress(event) {

        event.persist();

        let residentialAddress;
        let billingAddress;

        // Populate the billing address with the information in the landlord's residential address
        if(event.target.checked) {

            residentialAddress = JSON.parse(this.state.landlord.customFields['address']);
            billingAddress = this.state.billingAddress;

            billingAddress.city = residentialAddress.city;
            billingAddress.country = residentialAddress.country;
            billingAddress.postalCode = residentialAddress.postalCode;
            billingAddress.province = residentialAddress.province;
            billingAddress.street1 = residentialAddress.street1;
            billingAddress.street2 = residentialAddress.street2;
            billingAddress.suite = residentialAddress.suite;
        }

        // Clear the billing address fields if the checkbox is unchecked
        if(!event.target.checked) {

            billingAddress = this.state.billingAddress;

            billingAddress.city = '';
            billingAddress.country = '';
            billingAddress.postalCode = '';
            billingAddress.province = '';
            billingAddress.street1 = '';
            billingAddress.street2 = '';
            billingAddress.suite = '';
        }

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.checked,
            billingAddress: billingAddress
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The lease application component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <Banner backgroundImg="boston" heading="landlord.application.heading" subheading="landlord.application.subheading" align="left" values={{firstName: this.props.userSession.sessionRole.firstName}} />

                <div className="container">

                    <div className="row">
                        <div className="col-md-8">

                            <Alert validationList={this.state.validationList} />

                            <div className="card">

                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-auto mr-auto">
                                            Terms of Use
                                        </div>
                                        <div className="col-auto align-items-center">
                                            <small className="text-muted">
                                                Step 1 of 5
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">
                                        Please review and accept the Letus Terms of Use.
                                    </p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveTerms}>

                                        {this.state.termsCompleted &&
                                        <div className="">

                                            <div className="list-group">
                                                <div className={`list-group-item list-group-item-action c-pointer ${this.state.landlord.applicationStatus === 'APPLIED' ? 'disabled' : ''}`} onClick={() => this.editTerms()}>
                                                    <div className="">
                                                        Letus Terms of Use
                                                    </div>
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        I have read and agree to the Letus Terms of Use
                                                    </small>
                                                </div>
                                            </div>

                                            {this.state.landlord.applicationStatus !== 'APPLIED' &&
                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editTerms()}>
                                                Edit
                                            </div>
                                            }

                                        </div>
                                        }

                                        {!this.state.termsCompleted &&
                                        <React.Fragment>

                                            <div className="card-body border mb-3 bg-secondary rounded small" style={{height: "250px", overflowY: "scroll"}}>

                                                <TermsContents />

                                            </div>

                                            <FieldCheckbox id="acknowledgedTerms" fieldLabel="I have read and agree to the Letus Terms of Use" fieldColumns="12" parent={this} model="landlord" value={this.state.landlord['acknowledgedTerms']} />

                                            {this.state.landlord.acknowledgedTerms &&
                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>
                                            }

                                            {!this.state.landlord.acknowledgedTerms &&
                                            <div className="btn btn-primary btn-md btn-block mt-3 disabled" data-toggle="tooltip" data-placement="top" title="In order to continue with your account setup, you must indicate that you have read and agree to the Letus terms of use above.">
                                                Save & Continue
                                            </div>
                                            }

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>
                            </div>

                            {this.state.termsCompleted &&
                            <div className="card">

                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-auto mr-auto">
                                            Landlord
                                        </div>
                                        <div className="col-auto align-items-center">
                                            <small className="text-muted">
                                                Step 2 of 5
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">
                                        Who is the landlord setting up this account?
                                    </p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveLandlord} autoComplete="off">

                                        {this.state.landlordCompleted &&
                                        <div className="">

                                            <div className="list-group">
                                                <div className={`list-group-item list-group-item-action c-pointer ${this.state.landlord.applicationStatus === 'APPLIED' ? 'disabled' : ''}`} onClick={() => this.editLandlord()}>
                                                    <div className="">
                                                        {this.state.landlord.firstName} {this.state.landlord.lastName}
                                                    </div>
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        {this.state.landlord.email}
                                                    </small>
                                                </div>
                                            </div>

                                            {this.state.landlord.applicationStatus !== 'APPLIED' &&
                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editLandlord()}>
                                                Edit
                                            </div>
                                            }

                                        </div>
                                        }

                                        {!this.state.landlordCompleted &&
                                        <React.Fragment>

                                            <FieldText id="firstName" label="First Name" model="landlord" parent={this} required={true} value={this.state.landlord['firstName']}/>

                                            <FieldText id="lastName" label="Last Name" model="landlord" parent={this} required={true} value={this.state.landlord['lastName']}/>

                                            <FieldText id="email" label="Email" model="landlord" type="email" disabled={true} required={true} parent={this} value={this.state.landlord['email']}/>

                                            <FieldPhone id="phone" label="Phone" required={true} model="landlord" parent={this} value={this.state.landlord['phone']} />

                                            <FieldBirthDate id="birthDate" label="Date of Birth" model="landlord" parent={this} required={true} value={this.state.landlord['birthDate']} />

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>

                            </div>
                            }

                            {this.state.landlordCompleted &&
                            <div className="card">

                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-auto mr-auto">
                                            Addresses
                                        </div>
                                        <div className="col-auto align-items-center">
                                            <small className="text-muted">
                                                Step 3 of 5
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body bg-secondary">

                                    <p className="mb-0">
                                        What addresses have you resided at in the last 3 years?
                                    </p>

                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveAddresses}>

                                        {!this.state.addressesCompleted &&
                                        <React.Fragment>
                                            {this.state.addressList.map((address, key) => {
                                                return(
                                                    <div key={key} className="list-group mb-2">
                                                        <div className={`list-group-item ${(this.state.editAddress && address.id === this.state.address.id) ? '' : 'list-group-item-action c-pointer'}`} onClick={() => (this.state.editAddress && address.id === this.state.address.id) ? null : this.editAddress(address)}>

                                                            <div className="row align-items-center">
                                                                <div className="col-10">
                                                                    <div className="">
                                                                        {address.suite ? address.suite + ' - ' : ''}{address.street1 + (address.street2 ? ', ' + address.street2 : '') + ', ' + address.city + ', ' + (address.country === 'CA' ? formatMessage({id: "province." + address.province}) : formatMessage({id: "state." + address.province})) + ', ' + formatMessage({id: "country." + address.country}) + ' ' + address.postalCode}
                                                                    </div>
                                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                                        {address.addressType === 'RESIDENTIAL' ? `Current address for ${address.monthsAtAddress} month(s)` : `Previous address for ${address.monthsAtAddress} month(s)`}
                                                                    </small>
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <FontAwesomeIcon icon={['far', 'pencil']} className="fa-fw" size="1x" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {(this.state.editAddress && address.id === this.state.address.id) &&
                                                            <React.Fragment>

                                                                <div className="mt-3">
                                                                    <FieldAddress model="address" parent={this} value={this.state.address} suite={true} monthsAtAddress={true} required={true} />
                                                                </div>

                                                                <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.deleteAddress(address)}>
                                                                    Delete Address
                                                                </div>

                                                                <button type="submit" className="btn btn-primary btn-md btn-block">
                                                                    Save Address
                                                                </button>

                                                            </React.Fragment>
                                                            }

                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </React.Fragment>
                                        }

                                        {(!this.state.addressesCompleted && !this.state.showAddressForm) &&
                                        <div className="list-group mb-2">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.initAddress()}>
                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        Add New Address
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="float-right">
                                                            <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        {(!this.state.addressesCompleted && this.state.showAddressForm && !this.state.editAddress) &&
                                        <div className="list-group mb-2">
                                            <div className="list-group-item pb-3">

                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        Add New Address
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="float-right">
                                                            <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-3">
                                                    <FieldAddress model="address" parent={this} value={this.state.address} suite={true} monthsAtAddress={true} required={true} />
                                                </div>

                                                <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                    Save Address
                                                </button>

                                            </div>
                                        </div>
                                        }

                                        {this.state.addressesCompleted &&
                                        <div className="">

                                            <div className="list-group">
                                                <div className={`list-group-item list-group-item-action c-pointer ${this.state.landlord.applicationStatus === 'APPLIED' ? 'disabled' : ''}`} onClick={() => this.editAddresses()}>
                                                    <div className="">
                                                        {this.state.addressList[0].suite ? this.state.addressList[0].suite + ' - ' : ''}{this.state.addressList[0].street1 + (this.state.addressList[0].street2 ? ', ' + this.state.addressList[0].street2 : '') + ', ' + this.state.addressList[0].city + ', ' + (this.state.addressList[0].country === 'CA' ? formatMessage({id: "province." + this.state.addressList[0].province}) : formatMessage({id: "state." + this.state.addressList[0].province})) + ', ' + formatMessage({id: "country." + this.state.addressList[0].country}) + ' ' + this.state.addressList[0].postalCode}
                                                    </div>
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        {this.state.addressList.length === 1 &&
                                                        <React.Fragment>
                                                            Current residential address
                                                        </React.Fragment>
                                                        }
                                                        {this.state.addressList.length > 1 &&
                                                        <React.Fragment>
                                                            {this.state.addressList.length === 2 ? '1 other address' : this.state.addressList.length - 1 + ' other addresses'}
                                                        </React.Fragment>
                                                        }
                                                    </small>
                                                </div>
                                            </div>

                                            {this.state.landlord.applicationStatus !== 'APPLIED' &&
                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editAddresses()}>
                                                Edit
                                            </div>
                                            }

                                        </div>
                                        }

                                        {(!this.state.addressesCompleted && !this.state.showAddressForm) &&
                                        <React.Fragment>

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>
                            </div>
                            }

                            {this.state.addressesCompleted &&
                            <div className="card">

                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-auto mr-auto">
                                            Company Details
                                        </div>
                                        <div className="col-auto align-items-center">
                                            <small className="text-muted">
                                                Step 4 of 5
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">
                                        Do you operate your rental units as a registered business?
                                    </p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveCompany}>

                                        {this.state.companyCompleted &&
                                        <div className="">

                                            {this.state.landlord.applicationStatus !== 'APPLIED' &&
                                            <div className="list-group">
                                                <div className={`list-group-item list-group-item-action c-pointer ${this.state.landlord.applicationStatus === 'APPLIED' ? 'disabled' : ''}`} onClick={() => this.editCompany()}>
                                                    {this.state.landlordType === 'PRIVATE' &&
                                                    <div className="">
                                                        No, I operate my rental units privately
                                                    </div>
                                                    }
                                                    {this.state.landlordType === 'BUSINESS' &&
                                                    <div className="">
                                                        Yes, I operate my rental units as a registered business
                                                    </div>
                                                    }
                                                    {this.state.landlordType === 'PRIVATE' &&
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        No company information provided
                                                    </small>
                                                    }
                                                    {this.state.landlordType === 'BUSINESS' &&
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        {this.state.company.name}
                                                    </small>
                                                    }
                                                </div>
                                            </div>
                                            }

                                            {this.state.landlord.applicationStatus === 'APPLIED' &&
                                            <div className="list-group">
                                                <div className="list-group-item list-group-item-action c-pointer disabled">
                                                    {!this.state.landlord.customFields.company &&
                                                    <div className="">
                                                        No, I operate my rental units privately
                                                    </div>
                                                    }
                                                    {this.state.landlord.customFields.company &&
                                                    <div className="">
                                                        Yes, I operate my rental units as a registered business
                                                    </div>
                                                    }
                                                    {!this.state.landlord.customFields.company &&
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        No company information provided
                                                    </small>
                                                    }
                                                    {this.state.landlord.customFields.company &&
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        Company details submitted
                                                    </small>
                                                    }
                                                </div>
                                            </div>
                                            }

                                            {this.state.landlord.applicationStatus !== 'APPLIED' &&
                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editCompany()}>
                                                Edit
                                            </div>
                                            }

                                        </div>
                                        }

                                        {!this.state.companyCompleted &&
                                        <React.Fragment>

                                            <div className="list-group mb-2">
                                                <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                    <input type="radio" name="landlordType" value="PRIVATE" id="landlordTypePrivate" defaultChecked={this.state.landlordType === 'PRIVATE' || false} onChange={this.handleChangeLandlordType} className="custom-control-input" />
                                                    <label className="custom-control-label pl-3 c-pointer" htmlFor="landlordTypePrivate">

                                                        <div className="">
                                                            Private
                                                        </div>

                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            I operate my rental units privately
                                                        </small>

                                                    </label>
                                                </div>
                                            </div>

                                            <div className="list-group mb-2">
                                                <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                    <input type="radio" name="landlordType" value="BUSINESS" id="landlordTypeBusiness" defaultChecked={this.state.landlordType === 'BUSINESS' || false} onChange={this.handleChangeLandlordType} className="custom-control-input" />
                                                    <label className="custom-control-label pl-3 c-pointer" htmlFor="landlordTypeBusiness">

                                                        <div className="">
                                                            Business
                                                        </div>

                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            I operate my rental units as a registered business
                                                        </small>

                                                        {this.state.landlordType === 'BUSINESS' &&
                                                        <div className="mt-3">

                                                            <FieldText id="name" label="Company Name" model="company" parent={this} value={this.state.company['name']} />

                                                            <FieldText id="legalName" label="Legal Name" model="company" parent={this} value={this.state.company['legalName']} />

                                                            <FieldCompanyType id="companyType" label="Company Type" model="company" parent={this} value={this.state.company['companyType']} />

                                                            <FieldText id="phone" label="Business Phone" model="company" type="tel" parent={this} value={this.state.company['phone']} />

                                                            <FieldText id="taxNumber" label="Tax ID" model="company" type="tel" parent={this} value={this.state.company['taxNumber']} />

                                                            <FieldBirthDate id="inceptionDate" label="Inception Date" model="company" parent={this} required={true} value={this.state.company['inceptionDate']} />

                                                            <FieldAddress model="company" parent={this} value={this.state.company} />

                                                        </div>
                                                        }

                                                    </label>
                                                </div>
                                            </div>

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>

                            </div>
                            }

                            {(this.state.companyCompleted && this.state.landlord.applicationStatus === 'APPLIED') &&
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-auto mr-auto">
                                            Payment Method
                                        </div>
                                        <div className="col-auto align-items-center">
                                            <small className="text-muted">
                                                Step 5 of 5
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">

                                    {this.state.paymentMethodCompleted &&
                                    <div className="list-group">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editPaymentMethod()}>
                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    <div className="">
                                                        {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                                        <React.Fragment>
                                                            <FormattedMessage id={"enum.creditCard.brand." + this.state.paymentMethod.brand}/>
                                                        </React.Fragment>
                                                        }
                                                    </div>
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                                        <React.Fragment>
                                                            Card number ending in {this.state.paymentMethod.last4}
                                                        </React.Fragment>
                                                        }
                                                    </small>
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right mr-2">
                                                        <CardBrandIcon paymentMethodType={this.state.paymentMethod.type} brand={this.state.paymentMethod.brand} customClasses="w-75"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {!this.state.paymentMethodCompleted &&
                                    <React.Fragment>

                                        <p className="">How would you like to pay?</p>

                                        <form onSubmit={this.savePaymentMethod}>

                                            {this.state.paymentMethodList.map((data, key) => {
                                                return (
                                                    <div key={key} className="list-group mb-2">
                                                        <div className="custom-control custom-radio list-group-item list-group-item-action">
                                                            <input type="radio" name="paymentMethod" value={JSON.stringify(data)} id={key} checked={this.state.paymentMethod.id === data.id || false} onChange={this.handleChangePaymentMethod} className="custom-control-input"/>
                                                            <label className="custom-control-label pl-3" htmlFor={key}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-8">
                                                                        <div className="">
                                                                            {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                            <React.Fragment>
                                                                                Bank Account
                                                                            </React.Fragment>
                                                                            }
                                                                            {data.type === 'TYPE_CREDIT_CARD' &&
                                                                            <React.Fragment>
                                                                                <FormattedMessage id={"enum.creditCard.brand." + data.brand}/>
                                                                            </React.Fragment>
                                                                            }
                                                                        </div>
                                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                                            {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                            <React.Fragment>
                                                                                Account number ending in {data.last4}
                                                                            </React.Fragment>
                                                                            }
                                                                            {data.type === 'TYPE_CREDIT_CARD' &&
                                                                            <React.Fragment>
                                                                                Card number ending in {data.last4}
                                                                            </React.Fragment>
                                                                            }
                                                                        </small>
                                                                    </div>
                                                                    <div className="col text-right">
                                                                        <div className="float-right mr-2">
                                                                            <CardBrandIcon paymentMethodType={data.type} brand={data.brand} customClasses="w-75"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                            {!this.state.showCreditCardForm &&
                                            <div className="list-group mb-2">
                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.initPaymentMethod('credit_card')}>
                                                    <div className="row align-items-center">
                                                        <div className="col-8">
                                                            {this.state.paymentMethodList.length === 0 ? 'Add New Credit or Debit Card' : 'New Credit or Debit Card'}
                                                        </div>
                                                        <div className="col text-right">
                                                            <div className="float-right">
                                                                <img src={creditCard} className="rounded border w-75" alt="Add New Credit or Debit Card" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }

                                            {this.state.showCreditCardForm &&
                                            <div className="list-group mb-2">
                                                <div className="list-group-item pb-3">

                                                    <div className="row align-items-center mb-3">
                                                        <div className="col-8">
                                                            {this.state.paymentMethodList.length === 0 ? 'Add New Credit or Debit Card' : 'New Credit or Debit Card'}
                                                        </div>
                                                        <div className="col text-right">
                                                            <div className="float-right">
                                                                <img src={creditCard} className="rounded border w-75" alt="Add New Credit or Debit Card" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <CardPreview paymentMethod={this.state.paymentMethod} cardPreviewFlipped={this.state.cardPreviewFlipped} activePaymentMethodField={this.state.activePaymentMethodField} />

                                                    <FieldCardNumber id="cardNumber" label="Card Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod.cardNumber} brand={this.state.paymentMethod.brand} handleFocus={() => this.handleFocusPaymentMethodField('cardNumber')} handleBlur={() => this.handleBlurPaymentMethodField('cardNumber')} />

                                                    <FieldText id="nameOnCard" label="Card Holder Name" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['nameOnCard']} handleFocus={() => this.handleFocusPaymentMethodField('nameOnCard')} handleBlur={() => this.handleBlurPaymentMethodField('nameOnCard')} />

                                                    <div className="row mb-3">

                                                        <div className="col">
                                                            <FieldSelect id="expiryMonth" label="Month" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['expiryMonth']} handleFocus={() => this.handleFocusPaymentMethodField('expiryMonth')} handleBlur={() => this.handleBlurPaymentMethodField('expiryMonth')}>
                                                                <option value="">-</option>
                                                                <option value="1">01 - Jan</option>
                                                                <option value="2">02 - Feb</option>
                                                                <option value="3">03 - Mar</option>
                                                                <option value="4">04 - Apr</option>
                                                                <option value="5">05 - May</option>
                                                                <option value="6">06 - Jun</option>
                                                                <option value="7">07 - Jul</option>
                                                                <option value="8">08 - Aug</option>
                                                                <option value="9">09 - Sep</option>
                                                                <option value="10">10 - Oct</option>
                                                                <option value="11">11 - Nov</option>
                                                                <option value="12">12 - Dec</option>
                                                            </FieldSelect>
                                                        </div>

                                                        <div className="col">
                                                            <FieldSelect id="expiryYear" label="Year" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['expiryYear']} handleFocus={() => this.handleFocusPaymentMethodField('expiryYear')} handleBlur={() => this.handleBlurPaymentMethodField('expiryYear')}>
                                                                <option value="">-</option>
                                                                <option value="2020">2020</option>
                                                                <option value="2021">2021</option>
                                                                <option value="2022">2022</option>
                                                                <option value="2023">2023</option>
                                                                <option value="2024">2024</option>
                                                                <option value="2025">2025</option>
                                                                <option value="2026">2026</option>
                                                                <option value="2027">2027</option>
                                                                <option value="2028">2028</option>
                                                                <option value="2029">2029</option>
                                                                <option value="2030">2030</option>
                                                                <option value="2031">2031</option>
                                                                <option value="2032">2032</option>
                                                                <option value="2033">2033</option>
                                                            </FieldSelect>
                                                        </div>

                                                        <div className="col">
                                                            <FieldText id="securityCode" label={this.state.creditCardSecurityCodeLabel} required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod.securityCode} handleFocus={() => this.handleFocusPaymentMethodField('securityCode')} handleBlur={() => this.handleBlurPaymentMethodField('securityCode')} />
                                                        </div>

                                                    </div>

                                                    {!this.state.showBillingAddressForm &&
                                                    <FieldSelect id="billingAddressId" label="Billing Address" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['billingAddressId']} handleChange={this.handleChangeBillingAddress}>

                                                        <option value="">Select from your billing addresses...</option>
                                                        <option value="NEW">Add new billing address...</option>

                                                    </FieldSelect>
                                                    }

                                                    {this.state.showBillingAddressForm &&
                                                    <div className="mb-2">

                                                        <div className="mt-4 mb-3">
                                                            <FieldCheckbox id="populateBillingAddressForm" fieldLabel="Billing address same as residential" fieldClass="form-control-sm" fieldColumns="12" labelClass="col-form-label-sm px-2" parent={this} value={this.state.populateBillingAddressForm} handleChange={this.handleChangePopulateBillingAddress}/>
                                                        </div>

                                                        <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} parent={this} value={this.state.billingAddress}/>

                                                    </div>
                                                    }

                                                </div>
                                            </div>
                                            }

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>

                                        </form>

                                    </React.Fragment>
                                    }

                                </div>
                            </div>
                            }

                        </div>
                        <div className="col-md-4">
                            <div className="sticky-top">

                                <div className="card">
                                    <div className="card-header">
                                        Payment Summary
                                    </div>
                                    <div className="card-body card-body-list">
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <div className="float-left">
                                                    Landlord Signup Fee
                                                </div>
                                                <div className="float-right">
                                                    <FormattedNumber value={this.state.baseSignupFee} style={`currency`} currency="USD" />
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="float-left font-weight-bold">
                                                    Subtotal
                                                </div>
                                                <div className="float-right font-weight-bold">
                                                    <FormattedNumber value={this.state.baseSignupFee} style={`currency`} currency="USD" />
                                                </div>
                                            </li>
                                            <li className="list-group-item font-weight-bold">
                                                <div className="float-left">
                                                    Total
                                                </div>
                                                <div className="float-right">
                                                    <FormattedNumber value={this.state.baseSignupFee} style={`currency`} currency="USD" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-footer">
                                        <p className="small text-muted mb-0">
                                            Once you complete your landlord account setup, you will be charged the account setup fees listed above.
                                        </p>
                                    </div>
                                </div>

                                {this.state.paymentMethodCompleted &&
                                <div className="btn btn-primary btn-lg btn-block py-3" onClick={() => this.paySignupFee()}>
                                    <FontAwesomeIcon icon={['far', 'lock']} className="fa-fw" /> Make Payment
                                </div>
                                }

                            </div>

                        </div>
                    </div>

                    <ModalUnionPay parent={this} />

                    <Modal id="signup-complete" theme="primary" iconType="far" iconName="thumbs-up" title="You're good to go!"
                           body="Congratulations! Your landlord account sign up fee has been successfully processed and you may now continue to your account by clicking the button below.">
                        <Link to="/landlord/transactions" className="btn btn-primary btn-lg" onClick={() => {$('#signup-complete').modal('hide'); localStorage.setItem('status', 'PAID'); this.props.refreshAppState();}}>
                            Continue to my Account
                        </Link>
                    </Modal>

                </div>

            </div>
        )
    };
}

Signup.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Signup);