import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import NavListItem from "../../common/NavListItem";
import Propertii from "../../common/Propertii";
import Breadcrumb from "../../common/Breadcrumb";

class Account extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The customer account navigation component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Reports" />

                    <div className="card">
                        <div className="card-header">
                            Options
                        </div>
                        <div className="card-body card-body-list">
                            <div className="list-group">

                                <NavListItem path="/manager/reports/transactions" iconType="far" iconName="credit-card" name="Transactions" size="large" description="View a detailed history of completed transactions by your tenants." />
                                <NavListItem path="/manager/reports/efts" iconType="far" iconName="piggy-bank" name="EFTs" size="large" description="View sent EFT batches and their individual transactions."/>
                                <NavListItem path="/manager/reports/tenants" iconType="far" iconName="users" name="Tenants" size="large" description="View tenants and their assigned property information." />

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Account.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Account);