import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import TransactionList from "../../common/Transactions";

class Transactions extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            landlord: null,
            validationList: [],
        };
    }

    /**
     * On mounting of the component, fetch the landlord data based on the landlord ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                landlord: response.data,
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The landlord transactions component.
     */
    render() {

        return(
            <React.Fragment>

                {this.state.landlord &&
                <TransactionList companyId={this.state.landlord.companyId}
                                 displayFeeAmount={true}
                                 displayAdvanced={true}
                                 userType="TYPE_ADMIN" />
                }

            </React.Fragment>
        )
    };
}

Transactions.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Transactions);