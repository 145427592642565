import React from 'react';
import axios from "axios";
import * as constants from "../../util/constants";
import Table from "../common/Table";
import {FormattedMessage} from "react-intl";
import Propertii from "../common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import FieldSelect from "../common/FieldSelect";
import Alert from "../common/Alert";
import FieldFile from "../common/FieldFile";
import FieldDate from "../common/FieldDate";
import Spinner from "../common/Spinner";
import FieldTextarea from "../common/FieldTextarea";
import ButtonSave from "./ButtonSave";
import Modal from "./Modal";
import Moment from "react-moment";

class ImportList extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            import: {},
            triggeredBy: {},

            importFileKey: '',

            startDate: null,
            endDate: null,
            importType: null,

            importList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            importQuery: {
                orderBy: 'DESC',
                orderByFields: ['startDate'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.companyId
                    }
                ]
            },

            validationList: [],

        };

        this.searchImports = this.searchImports.bind(this);
        this.filterImports = this.filterImports.bind(this);

        this.viewImport = this.viewImport.bind(this);
        this.initImport = this.initImport.bind(this);
        this.saveImport = this.saveImport.bind(this);
        this.refreshImport = this.refreshImport.bind(this);

        this.downloadImportTemplate = this.downloadImportTemplate.bind(this);
        this.downloadImportFailures = this.downloadImportFailures.bind(this);
        this.downloadImportFile = this.downloadImportFile.bind(this);
    }

    /**
     * Load the list of imports on mounting of the component.
     */
    componentDidMount() {

        this.searchImports(1, 25, this.state.importQuery);
    }

    /**
     * Handle selecting an import record by bringing up the modal with import details shown.
     *
     * @param importId - The ID of the import record selected.
     */
    viewImport(importId) {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/${importId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                import: {
                    ...response.data,
                    file: ''
                }
            }));

            axios.get(`${constants.REACT_APP_HOST_API_URL}/${response.data.triggeredByType.substring(5).toLowerCase()}/${response.data.triggeredById}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                $('#import').modal('show');

                $('#import').on('shown.bs.modal', () => {
                    this.setState(prevState => ({
                        ...prevState,
                        spinner: false,
                        triggeredBy: response.data
                    }));
                });

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Refresh an import job record by re-fetching the record.
     *
     * @param importId - The ID of the import job record to refresh.
     */
    refreshImport(importId) {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/${importId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                import: response.data
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of an import object when the user clicks the appropriate button.
     */
    initImport() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/new`).then(response => {
            this.setState(prevState => ({
                ...prevState,
                import: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle the submission of the import. Upon successful submission of the form, refresh the table and hide the
     * modal.
     *
     * @param event - The event container.
     */
    saveImport(event) {

        if(event != null) {
            event.preventDefault();
        }

        this.setState({
            spinner: true
        });

        if(this.props.importModel === 'FILE_IMPORT') {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/file_import/${this.props.companyId}/${this.state.import.importType}/process`, this.state.import.file, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    spinner: false,
                    importFileKey: Date.now(),
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: this.props.createMessage
                        }
                    }],
                });

                this.searchImports(this.state.importList.page, this.state.importList.recordsPerPage, this.state.importQuery);

                $('#import-file').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        if(this.props.importModel === 'INTEGRATED_CHARGES_IMPORT') {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/integrated_charges_import/${this.props.companyId}/process`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    spinner: false,
                    importFileKey: Date.now(),
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: this.props.createMessage
                        }
                    }],
                });

                this.searchImports(this.state.importList.page, this.state.importList.recordsPerPage, this.state.importQuery);

                $('#import-integrated-charges').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Handle submitting the search filter fields by adjusting the import search query and initiating a new search.
     *
     * @param event - The event container.
     */
    filterImports(event) {

        if (event != null) {
            event.preventDefault();
        }

        let importQuery = {
            orderBy: 'DESC',
            orderByFields: ['startDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            ],
        };

        if(this.state.startDate !== null) {
            importQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'startDate',
                    operator: 'GREATER_THAN_OR_EQUALS',
                    fieldValue: this.state.startDate
                },
            );
        }

        if(this.state.endDate !== null) {
            importQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'endDate',
                    operator: 'LESS_THAN_OR_EQUALS',
                    fieldValue: this.state.endDate
                },
            );
        }

        if(this.state.importType !== null && this.state.importType !== '') {
            importQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'importType',
                    operator: 'EQUALS',
                    fieldValue: this.state.importType
                },
            );
        }

        this.setState({
            importQuery: importQuery
        });

        this.searchImports(1, 25, importQuery);
    }

    /**
     * Download an import file template.
     *
     * @param importType - The import type to download the template file of.
     */
    downloadImportTemplate(importType) {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/${importType}/template`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': this.props.token
            }
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 10;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Download the failed records file associated with an import job.
     *
     * @param importId - The import job ID to download the failed records file of.
     */
    downloadImportFailures(importId) {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/${importId}/failedrecords`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': this.props.token
            }
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 10;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Download the original import file associated with an import job.
     *
     * @param importId - The import job ID to download the original import file of.
     */
    downloadImportFile(importId) {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/${importId}/file`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': this.props.token
            }
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 10;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Search for all import jobs.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchImports(page, recordsPerPage, query) {

        this.setState({
            spinner: true
        });

        if(this.props.importModel === 'INTEGRATED_CHARGES_IMPORT') {
            query.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'type',
                    operator: 'EQUALS',
                    fieldValue: 'TYPE_INTEGRATED_CHARGES_IMPORT'
                },
            );
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                import: {},
                importList: response.data,
                importQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The import interface.
     */
    render() {

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Imports
                            </div>
                            <div className="col text-right">

                                {this.props.importModel === 'FILE_IMPORT' &&
                                <div className="btn-group" role="group">
                                    <button id="btnGroupDrop1" type="button" className="btn btn-primary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download Template
                                    </button>
                                    <div className="dropdown-menu gotham" aria-labelledby="btnGroupDrop1">

                                        {this.props.companyId === 'RENTMOOLA' &&
                                        <React.Fragment>
                                            <div className="dropdown-item c-pointer" onClick={() => this.downloadImportTemplate('BANK_INFO')}>Bank Info</div>
                                            <div className="dropdown-item c-pointer" onClick={() => this.downloadImportTemplate('BIN')}>BIN Mapping</div>
                                        </React.Fragment>
                                        }

                                        {this.props.companyId !== 'RENTMOOLA' &&
                                        <React.Fragment>
                                            <div className="dropdown-item c-pointer" onClick={() => this.downloadImportTemplate('TENANT')}>Tenant</div>
                                            <div className="dropdown-item c-pointer" onClick={() => this.downloadImportTemplate('PROPERTY')}>Property</div>
                                            <div className="dropdown-item c-pointer" onClick={() => this.downloadImportTemplate('OPEN_CHARGES')}>Open Charges</div>
                                        </React.Fragment>
                                        }

                                    </div>
                                </div>
                                }

                                {this.props.importModel === 'FILE_IMPORT' &&
                                <div data-toggle="modal" data-target="#import-file" className="btn btn-primary btn-sm ml-2" onClick={() => this.initImport()}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> New Import
                                </div>
                                }

                                {this.props.importModel === 'INTEGRATED_CHARGES_IMPORT' &&
                                <div data-toggle="modal" data-target="#import-integrated-charges" className="btn btn-primary btn-sm ml-2" onClick={() => this.initImport()}>
                                    <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Bulk Import Charges
                                </div>
                                }

                            </div>
                        </div>
                    </div>
                    {this.props.searchFilters &&
                    <div className="card-header gotham border-top py-3 bg-secondary">
                        <form onSubmit={this.filterImports} autoComplete="off">
                            <div className="media">
                                <div className="media-body align-self-center mr-3">
                                    <FieldDate id="startDate" placeholder="Start Date" labelColumns="0"
                                               fieldColumns="12" parent={this} value={this.state.startDate}
                                               selectsStart={true} startDate={this.state.startDate}
                                               endDate={this.state.endDate}/>
                                </div>
                                <div className="media-body align-self-center mr-3">
                                    <FieldDate id="endDate" placeholder="End Date" labelColumns="0" fieldColumns="12"
                                               parent={this} value={this.state.endDate} selectsEnd={this}
                                               startDate={this.state.startDate} endDate={this.state.endDate}
                                               minDate={this.state.startDate}/>
                                </div>
                                <div className="media-body align-self-center mr-3">
                                    <FieldSelect id="importType" placeholder="Import Type" labelColumns="0"
                                                 fieldColumns="12" parent={this} value={this.state.importType}>

                                        <option value="">All Import Types</option>

                                        {this.props.companyId === 'RENTMOOLA' &&
                                        <React.Fragment>
                                            <option value="BANK_INFO">Bank Info</option>
                                            <option value="BIN">BIN Mapping</option>
                                        </React.Fragment>
                                        }

                                        {this.props.companyId !== 'RENTMOOLA' &&
                                        <React.Fragment>
                                            <option value="TENANT">Tenant</option>
                                            <option value="PROPERTY">Property</option>
                                            <option value="OPEN_CHARGES">Open Charges</option>
                                            <option value="INTEGRATED_CHARGES">Integrated Charges</option>
                                        </React.Fragment>
                                        }

                                    </FieldSelect>
                                </div>
                                <div className="align-self-center text-right">
                                    <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                        <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    }
                    <Table columns={{type: 'Type', startDate: 'Start Date', endDate: 'End Date', totalRecords: 'Records', processedRecords: 'Processed', ignoredRecords: 'Ignored', failedRecords: 'Failed'}}
                           columnWidths={['14%', '14%', '14%', '14%', '14%', '14%', '14%']}
                           headerClass="c-pointer"
                           data={this.state.importList}
                           query={this.state.importQuery}
                           sortEnabled={true}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.searchImports}>
                        <tbody>
                        {this.state.importList.records.map((data, key) => {
                            return(
                                <tr key={key} onClick={() => this.viewImport(data.id)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            <FormattedMessage id={"enum.imports.importType." + data.importType} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.startDate &&
                                            <Moment format="MMM DD, YYYY HH:mm">
                                                {data.startDate}
                                            </Moment>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.endDate &&
                                            <Moment format="MMM DD, YYYY HH:mm">
                                                {data.endDate}
                                            </Moment>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.totalRecords}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-success" /> {data.processedRecords}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-warning" /> {data.ignoredRecords}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-danger" /> {data.failedRecords}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="import" tabIndex="-1" role="dialog" aria-labelledby="import-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveImport}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="import-label">
                                        <FormattedMessage id={"enum.imports.importType." + this.state.import.importType}/> Import
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="progress">
                                        <div className={`progress-bar bg-danger ${this.state.import.endDate ? '' : 'progress-bar-striped progress-bar-animated'}`} role="progressbar" style={{width: ((this.state.import.failedRecords / this.state.import.totalRecords) * 100) + '%', ariaValuenow: (this.state.import.failedRecords % this.state.import.totalRecords), ariaValuemin: 0, ariaValuemax: 100}}>{((this.state.import.failedRecords / this.state.import.totalRecords) * 100).toFixed(2)}% FAILED</div>
                                        <div className={`progress-bar bg-warning ${this.state.import.endDate ? '' : 'progress-bar-striped progress-bar-animated'}`} role="progressbar" style={{width: ((this.state.import.ignoredRecords / this.state.import.totalRecords) * 100) + '%', ariaValuenow: (this.state.import.ignoredRecords % this.state.import.totalRecords), ariaValuemin: 0, ariaValuemax: 100}}>{((this.state.import.ignoredRecords / this.state.import.totalRecords) * 100).toFixed(2)}% IGNORED</div>
                                        <div className={`progress-bar bg-success ${this.state.import.endDate ? '' : 'progress-bar-striped progress-bar-animated'}`} role="progressbar" style={{width: ((this.state.import.processedRecords / this.state.import.totalRecords) * 100) + '%', ariaValuenow: (this.state.import.processedRecords % this.state.import.totalRecords), ariaValuemin: 0, ariaValuemax: 100}}>{((this.state.import.processedRecords / this.state.import.totalRecords) * 100).toFixed(2)}% PROCESSED</div>
                                    </div>
                                </div>
                                <div className="modal-body modal-body-table">
                                    <table className="table mb-0 border-bottom">
                                        <tbody>
                                        <tr className="small">
                                            <td className="">
                                                Import Type
                                            </td>
                                            <td className="text-right">
                                                <FormattedMessage id={"enum.imports.importType." + this.state.import.importType}/>
                                            </td>
                                        </tr>
                                        {this.state.import.importFile &&
                                        <tr className="small">
                                            <td className="">
                                                Import File
                                            </td>
                                            <td className="text-right">
                                                {this.state.import.importFile.replace(/^.*[\\/]/, '')}
                                            </td>
                                        </tr>
                                        }
                                        <tr className="small">
                                            <td className="">
                                                Triggered By
                                            </td>
                                            <td className="text-right">
                                                {this.state.triggeredBy.type === 'TYPE_API_ACCOUNT' ? this.state.triggeredBy.apiUser : this.state.triggeredBy.firstName + ' ' + this.state.triggeredBy.lastName}
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Start Date
                                            </td>
                                            <td className="text-right">
                                                <Moment format="MMM DD, YYYY HH:mm">
                                                    {this.state.import.startDate}
                                                </Moment>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                End Date
                                            </td>
                                            <td className="text-right">
                                                {this.state.import.endDate &&
                                                <Moment format="MMM DD, YYYY HH:mm">
                                                    {this.state.import.endDate}
                                                </Moment>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Total Records
                                            </td>
                                            <td className="text-right">
                                                {this.state.import.totalRecords}
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Processed Records
                                            </td>
                                            <td className="text-right">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-success"/> {this.state.import.processedRecords} ({(this.state.import.processedRecords / this.state.import.totalRecords * 100).toFixed(2)}%)
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Ignored Records
                                            </td>
                                            <td className="text-right">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-warning"/> {this.state.import.ignoredRecords} ({(this.state.import.ignoredRecords / this.state.import.totalRecords * 100).toFixed(2)}%)
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Failed Records
                                            </td>
                                            <td className="text-right">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-danger"/> {this.state.import.failedRecords} ({(this.state.import.failedRecords / this.state.import.totalRecords * 100).toFixed(2)}%)
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-body">

                                    <FieldTextarea id="log" label="Log" labelClass="d-none" fieldColumns="12" labelColums="0" rows="10" model="import" parent={this} value={this.state.import['log']}/>

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#import").modal("hide")}>Close</button>
                                        </div>
                                        <div className="col-8 text-right">

                                            {((this.state.import.endDate && this.props.importModel === 'FILE_IMPORT') || (this.state.import.startDate && !this.state.import.endDate)) &&
                                            <div className="dropdown">

                                                <div className="btn btn-primary btn-lg dropdown-toggle" role="button"
                                                     id="payment-transaction-actions" data-toggle="dropdown"
                                                     aria-haspopup="true" aria-expanded="false">
                                                    Actions
                                                </div>

                                                <div className="dropdown-menu"
                                                     aria-labelledby="payment-transaction-actions">

                                                    {(this.state.import.endDate && this.props.importModel === 'FILE_IMPORT') &&
                                                    <React.Fragment>

                                                        {this.state.import.failedRecords > 0 &&
                                                        <div className="dropdown-item c-pointer"
                                                             onClick={() => this.downloadImportFailures(this.state.import.id)}>
                                                            <FontAwesomeIcon icon={['fas', 'arrow-to-bottom']}
                                                                             className="fa-fw"/> Download Failures
                                                        </div>
                                                        }

                                                        <div className="dropdown-item c-pointer"
                                                             onClick={() => this.downloadImportFile(this.state.import.id)}>
                                                            <FontAwesomeIcon icon={['fas', 'file-download']}
                                                                             className="fa-fw"/> Download Original
                                                            Import
                                                        </div>

                                                    </React.Fragment>
                                                    }

                                                    {(this.state.import.startDate && !this.state.import.endDate) &&
                                                    <React.Fragment>

                                                        <div className="dropdown-item c-pointer"
                                                             onClick={() => this.refreshImport(this.state.import.id)}>
                                                            <FontAwesomeIcon icon={['fas', 'sync-alt']}
                                                                             className="fa-fw"/> Refresh
                                                        </div>

                                                    </React.Fragment>
                                                    }

                                                </div>

                                            </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="import-file" tabIndex="-1" role="dialog" aria-labelledby="import-file-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveImport}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="import-file-label">
                                        New Import
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                    <FieldSelect id="importType" label="Type" model="import" labelClass="col-form-label-md" fieldClass="form-control-md" parent={this} value={this.state.import['importType']}>

                                        <option value="" disabled>Select an import type...</option>

                                        {this.props.companyId === 'RENTMOOLA' &&
                                        <React.Fragment>
                                            <option value="BANK_INFO">Bank Info</option>
                                            <option value="BIN">BIN Mapping</option>
                                        </React.Fragment>
                                        }

                                        {this.props.companyId !== 'RENTMOOLA' &&
                                        <React.Fragment>
                                            <option value="TENANT">Tenant</option>
                                            <option value="PROPERTY">Property</option>
                                            <option value="OPEN_CHARGES">Open Charges</option>
                                        </React.Fragment>
                                        }

                                    </FieldSelect>

                                    <FieldFile key={this.state.importFileKey} id="file" label="File" help="Accepted file types: .csv, .txt." model="import" parent={this} value={this.state.import['file']}/>

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#import").modal("hide")}>Close</button>
                                        </div>
                                        <div className="col-8 text-right">
                                            <ButtonSave />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <Modal id="import-integrated-charges" theme="primary" iconType="fas" iconName="question-circle" title="Bulk Import Charges"
                       body="Are you sure you would like to import all tenant charges from your integrated system? If a tenant has existing charges associated with their Letus account, they may be overwritten as a result.">
                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                        <FormattedMessage id="button.close" />
                    </button>
                    <button className="btn btn-primary btn-lg" data-dismiss="modal" onClick={() => this.saveImport()}>
                        Import Charges
                    </button>
                </Modal>

            </React.Fragment>
        )
    };
}

export default ImportList;