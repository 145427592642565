import React from 'react';
import {Switch} from 'react-router-dom';
import axios from "axios";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import PrivateRoute from "../../common/PrivateRoute";
import Property from "./Property";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Tenants from "./Tenants";
import Transactions from "./Transactions";
import NavListItem from "../../common/NavListItem";
import JumbotronHelp from "../../common/JumbotronHelp";
import Breadcrumb from "../../common/Breadcrumb";

class Navigation extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            property: {},
            validationList: [],
        };

        this.refreshProperty = this.refreshProperty.bind(this);
        this.deleteProperty = this.deleteProperty.bind(this);
    }

    /**
     * Get the property based on the ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${this.props.match.params.propertyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                property: response.data
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Delete the property currently being viewed.
     */
    deleteProperty() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/property/${this.props.match.params.propertyId}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.props.history.push({
                pathname: `/landlord/properties`,
                state: {
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'landlord.properties.deleted'
                        }
                    }],
                }
            });
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Force a refresh of this component if required by a child component. Because this component displays the
     * property's name or street address in the breadcrumbs, this component should update if a child component makes an
     * update to said values.
     *
     * @param propertyId - The ID of the property to fetch data from.
     */
    refreshProperty(propertyId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${propertyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                property: response.data
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view property component for landlords.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/landlord/properties" parentPage="Properties" childPage={this.state.property.propertyName ? this.state.property.propertyName : this.state.property.street1} />

                    <Alert validationList={this.state.validationList} />

                    <div className="row">
                        <div className="col-md-4">

                            <div className="card">
                                <div className="card-header">
                                    Options
                                </div>
                                <div className="card-body card-body-list">
                                    <div className="list-group">

                                        <NavListItem path={`/landlord/properties/${this.props.match.params.propertyId}/edit`} active="edit" size="small" iconName="building" name="Property" />
                                        <NavListItem path={`/landlord/properties/${this.props.match.params.propertyId}/tenants`} active="tenants" size="small" iconName="users" name="Tenants" />
                                        <NavListItem path={`/landlord/properties/${this.props.match.params.propertyId}/transactions`} active="transactions" size="small" iconName="credit-card" name="Transactions" />

                                    </div>
                                </div>
                            </div>

                            <JumbotronHelp icon="question-circle"
                                           heading="Need a hand?"
                                           body="Whether you're troubleshooting an issue or learning something new, our Help Center has you covered."
                                           buttonText="Visit the Help Center"
                                           buttonIcon="external-link-square-alt"
                                           buttonUrl="https://help.rentmoola.com/hc/en-us/sections/360006583852-Landlords" />

                            <Modal id="deleteProperty" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Property"
                                   body="If you delete this property, you will no longer be able to view any previous transactions made by tenants for this property.">
                                <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal">
                                    <FormattedMessage id="button.close" />
                                </button>
                                <button onClick={() => {this.deleteProperty()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                                    Delete Property
                                </button>
                            </Modal>

                        </div>
                        <div className="col-md-8">

                            <Switch>
                                <PrivateRoute exact path="/landlord/properties/:propertyId/edit" type="TYPE_LANDLORD" parent={this} component={Property} refreshProperty={(propertyId) => this.refreshProperty(propertyId)} />
                                <PrivateRoute exact path="/landlord/properties/:propertyId/tenants" type="TYPE_LANDLORD" parent={this} component={Tenants} />
                                <PrivateRoute exact path="/landlord/properties/:propertyId/transactions" type="TYPE_LANDLORD" parent={this} component={Transactions} />
                            </Switch>

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Navigation.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Navigation);