import React from 'react';
import Propertii from "../../common/Propertii";
import ChangePassword from "../../common/Password";
import Breadcrumb from "../../common/Breadcrumb";

class Password extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The change password interface for managers.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/manager/settings" parentPage="Settings" childPage="Change Password" />

                    <ChangePassword backRedirect="/manager/settings" userId={this.props.userSession.sessionRole.id} saveMessage="manager.settings.password.saved" />

                </div>
            </div>
        )
    };
}

export default Password;