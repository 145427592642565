import React from 'react';
import Propertii from "../../common/Propertii";
import ChargesList from "../../common/OpenCharges";

class OpenCharges extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - An interface that allows the landlord to add, edit, and delete a tenant's recurring and issued
     * (open) charges.
     */
    render() {

        return(
            <React.Fragment>

                <ChargesList propertyLeaseId={this.props.match.params.propertyLeaseId} />

            </React.Fragment>
        )
    };
}

export default OpenCharges;