import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Table from "../../common/Table";
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import FieldText from "../../common/FieldText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Breadcrumb from "../../common/Breadcrumb";

class Landlords extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            landlordSearchFilter: '',

            landlordList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            landlordQuery: {
                orderBy: 'ASC',
                orderByFields: ['firstName'],
                conditionList: []
            },

            validationList: [],

        };

        this.searchLandlords = this.searchLandlords.bind(this);
        this.filterLandlords = this.filterLandlords.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.selectLandlord = this.selectLandlord.bind(this);
    }

    /**
     * Fetch a list of all landlords in the system on the mounting of the component.
     */
    componentDidMount() {

        this.searchLandlords(1, 25, this.state.landlordQuery);
    }

    /**
     * Handle clicking a landlord by redirecting the user to the view landlord component.
     *
     * @param landlordId - The ID of the landlord selected for editing.
     */
    selectLandlord(landlordId) {

        this.props.history.push('/admin/landlords/' + landlordId + '/edit');
    }

    /**
     * Handle submitting the search filter field by adjusting the parent company search query and initiating a new
     * search.
     *
     * @param event - The event container.
     */
    filterLandlords(event) {

        if (event != null) {
            event.preventDefault();
        }

        let landlordQuery = {
            orderBy: 'ASC',
            orderByFields: ['firstName'],
            conditionList: []
        };

        if (this.state.landlordSearchFilter !== '') {

            landlordQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.state.landlordSearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'firstName,lastName,email,phone',
                    operator: 'MATCH',
                    fieldValue: this.state.landlordSearchFilter
                }
            );
        }

        this.setState({
            landlordQuery: landlordQuery
        });

        this.searchLandlords(1, 25, landlordQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter query.
     */
    clearFilters() {

        this.setState({
            landlordSearchFilter: '',
        }, () => {

            this.filterLandlords();

        });
    }

    /**
     * Update the data table of landlords.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchLandlords(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/landlord/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                landlordList: response.data,
                landlordQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The admin landlord list component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return (
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Landlords" />

                    <Alert validationList={this.state.validationList}/>

                    <div className="card">
                        <div className="card-header">
                            Landlords
                        </div>
                        <div className="card-header gotham border-top py-3 bg-secondary">
                            <form onSubmit={this.filterLandlords}>
                                <div className="media">
                                    <div className="media-body align-self-center mr-3">
                                        <FieldText id="landlordSearchFilter" label="Search" labelClass="d-none" fieldColumns="12"
                                                   labelColums="0" placeholder="Filter by first name, last name, email, or phone..."
                                                   parent={this} value={this.state.landlordSearchFilter} />
                                    </div>
                                    <div className="align-self-center text-right">
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                            </button>
                                            <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <Table columns={{firstName: 'First Name', lastName: 'Last Name', email: 'Email', phone: 'Phone', applicationStatus: 'Status'}}
                               columnWidths={['20%', '20%', '20%', '20%', '20%']}
                               headerClass="c-pointer"
                               data={this.state.landlordList}
                               query={this.state.landlordQuery}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchLandlords}>
                            <tbody>
                            {this.state.landlordList.records.map((data, key) => {
                                return (
                                    <tr key={key} onClick={() => this.selectLandlord(data.id)} className="c-pointer">
                                        <td>
                                            <div className="">
                                                {data.firstName}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.lastName}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.email}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.phone}
                                            </div>
                                        </td>
                                        <td>
                                            {data.applicationStatus &&
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.landlord.applicationStatus." + data.applicationStatus + ".class"})}`}/>
                                                <span className="ml-1"><FormattedMessage id={"enum.landlord.applicationStatus." + data.applicationStatus}/></span>
                                            </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                </div>

            </div>
        )
    };
}

Landlords.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Landlords);
