import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormattedHTMLMessage} from "react-intl";

class Alert extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            showMessage: false
        };
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic alert bar that appears above a form in response to the submission of the form.
     */
    render() {


        return(
            <React.Fragment>

                {this.props.validationList && this.props.validationList.length > 0 &&
                <React.Fragment>

                    {!this.props.validationType &&
                    <div data-alert="alert" className={`alert alert-${this.props.validationList[0].alert.type} alert-dismissible fade show ${this.props.customClasses ? this.props.customClasses : ''}`}>
                        {this.props.validationList.map((validation, key) => {
                            return (
                                <div className={`${this.props.validationList.length > 1 ? 'mb-1' : 'mb-0'}`} key={key}>

                                    {validation.alert &&
                                    <React.Fragment>

                                        {validation.alert.type === 'primary' &&
                                        <FontAwesomeIcon icon={['fas', 'check-circle']} className="va-b mr-1" />
                                        }

                                        {validation.alert.type === 'danger' &&
                                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} onClick={() => this.setState({showMessage: !this.state.showMessage})} className="va-b mr-1 c-pointer" />
                                        }

                                        <FormattedHTMLMessage id={validation.alert.type + "." + validation.alert.code} values={validation.values} />

                                        {(this.state.showMessage && validation.alert.message) &&
                                        <div className="mt-1">
                                            <small>{validation.alert.message}</small>
                                        </div>
                                        }

                                    </React.Fragment>
                                    }

                                </div>
                            )
                        })}
                    </div>
                    }

                    {(this.props.validationType === 'primary' && this.props.validationList[0].alert.type === 'primary') &&
                    <div data-alert="alert" className={`alert alert-primary alert-dismissible fade show ${this.props.customClasses ? this.props.customClasses : ''}`}>
                        {this.props.validationList.map((validation, key) => {
                            return (
                                <div className={`${this.props.validationList.length > 1 ? 'mb-1' : 'mb-0'}`} key={key}>

                                    {validation.alert &&
                                    <React.Fragment>

                                        <FontAwesomeIcon icon={['fas', 'check-circle']} className="va-b mr-1" />

                                        <FormattedHTMLMessage id={validation.alert.type + "." + validation.alert.code} values={validation.values} />

                                    </React.Fragment>
                                    }

                                </div>
                            )
                        })}
                    </div>
                    }

                    {(this.props.validationType === 'danger' && this.props.validationList[0].alert.type === 'danger') &&
                    <div data-alert="alert" className={`alert alert-danger alert-dismissible fade show ${this.props.customClasses ? this.props.customClasses : ''}`}>
                        {this.props.validationList.map((validation, key) => {
                            return (
                                <div className={`${this.props.validationList.length > 1 ? 'mb-1' : 'mb-0'}`} key={key}>

                                    {validation.alert &&
                                    <React.Fragment>

                                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} onClick={() => this.setState({showMessage: !this.state.showMessage})} className="va-b mr-1 c-pointer" />

                                        <FormattedHTMLMessage id={validation.alert.type + "." + validation.alert.code} values={validation.values} />

                                        {(this.state.showMessage && validation.alert.message) &&
                                        <div className="mt-1">
                                            <small>{validation.alert.message}</small>
                                        </div>
                                        }

                                    </React.Fragment>
                                    }

                                </div>
                            )
                        })}
                    </div>
                    }

                </React.Fragment>
                }

            </React.Fragment>
        )
    };
}

export default Alert;