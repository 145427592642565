import React, { Component } from 'react';
import {FormattedMessage} from "react-intl";
import BootstrapSwitchButton from "bootstrap-switch-button-react/lib/bootstrap-switch-button-react";

class FieldSwitch extends Component {

    /**
     * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
     * custom handleChange function appears in the parent class, utilize the default handleChange function below.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        if(this.props.parent.handleChange) {
            this.handleChange = this.props.parent.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
    }

    /**
     * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
     * state, as opposed to a standalone element.
     *
     * @param checked - True or false is the switch is on or off..
     */
    handleChange(checked) {

        let field;

        if(this.props.model != null) {
            field = this.props.parent.state[this.props.model];
            field[this.props.id] = checked;
        }

        if(this.props.model == null) {
            field = checked;
        }

        this.props.parent.setState(({
            [this.props.model ? this.props.model : this.props.id]: field,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic checkbox component, but with an on/off switch style.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if(validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if(key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        return(
            <div className="form-group row">

                <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                    {this.props.label}
                </label>

                <div className={`col-sm-${this.props.fieldColumns} align-self-center`}>

                    {this.props.value != null &&
                    <BootstrapSwitchButton
                        checked={this.props.value}
                        onlabel="Enabled"
                        onstyle="primary"
                        offlabel="Disabled"
                        offstyle="secondary"
                        width={125}
                        size="md"
                        onChange={this.props.handleChange || this.handleChange}
                    />
                    }

                    {this.props.help &&
                    <small className="form-text text-muted">
                        {this.props.help}
                    </small>
                    }

                    {isInvalid &&
                    <div className="invalid-feedback">
                        <FormattedMessage id={"danger." + errorCode} />
                    </div>
                    }

                </div>

            </div>
        )
    };
}

FieldSwitch.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
};

export default FieldSwitch;
