import React from 'react';
import Propertii from "../../common/Propertii";
import ManagerList from "../../common/Managers";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";

class Managers extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The manager managers component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Managers" />

                    <Alert validationList={this.state.validationList}/>

                    <ManagerList userType={this.props.userSession.sessionRole.type}
                                 location={this.props.location}
                                 editModal={false}
                                 editable={true}
                                 history={this.props.history} />

                </div>

            </div>
        )
    };
}

export default Managers;