import React from 'react';
import Propertii from "../../common/Propertii";
import ImportList from "../../common/ImportList";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";

class Imports extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The import interface.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/manager/settings" parentPage="Settings" childPage="Imports" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <ImportList token={this.props.token} companyId={this.props.userSession.sessionRole.companyId} searchFilters={false} importModel="INTEGRATED_CHARGES_IMPORT" createMessage="manager.settings.imports.created" />

                </div>

            </div>
        )
    };
}

export default Imports;