import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import CustomFieldList from "../../common/CustomFieldList";
import Alert from "../../common/Alert";

class CustomFields extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The company custom fields management component.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} validationType="primary" />

                <CustomFieldList intl={this.props.intl} token={this.props.token} parent={this} recordType="COMPANY" recordId={this.props.match.params.companyId}
                                 createMessage="admin.companies.fields.created" deleteMessage="admin.companies.fields.deleted" />

            </React.Fragment>
        )
    };
}

CustomFields.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(CustomFields);