import React, { Component } from 'react';
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";

class FieldCheckbox extends Component {

    /**
     * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
     * custom handleChange function appears in the parent class, utilize the default handleChange function below.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        if(this.props.parent.handleChange) {
            this.handleChange = this.props.parent.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
    }

    /**
     * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
     * state, as opposed to a standalone element.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        let field;

        if(this.props.model != null) {
            field = this.props.parent.state[this.props.model];
            field[event.target.name] = event.target.checked;
        }

        if(this.props.model == null) {
            field = event.target.checked;
        }

        this.props.parent.setState(({
            [this.props.model ? this.props.model : event.target.name]: field,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic checkbox component.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if(validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if(key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        return(
            <div className="form-group row">

                {this.props.label &&
                <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                    {this.props.label}
                </label>
                }

                <div className={`col-sm-${this.props.fieldColumns} align-self-center`}>

                    <div className="custom-control custom-checkbox">

                        <input
                            id={this.props.id}
                            name={this.props.id}
                            type="checkbox"
                            value={this.props.value}
                            checked={this.props.value || false}
                            onChange={this.props.handleChange || this.handleChange}
                            disabled={this.props.disabled}
                            className={`custom-control-input ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`}
                        />

                        <label className={`custom-control-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                            <FormattedHTMLMessage id={this.props.fieldLabel} />
                        </label>

                    </div>

                    {this.props.help &&
                    <small className="form-text text-muted">
                        {this.props.help}
                    </small>
                    }

                    {isInvalid &&
                    <div className="invalid-feedback">
                        <FormattedMessage id={"danger." + errorCode} />
                    </div>
                    }

                </div>

            </div>
        )
    };
}

FieldCheckbox.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
};

export default FieldCheckbox;