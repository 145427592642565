import React from 'react';
import Propertii from "../../common/Propertii";
import TenantList from "../../common/Tenants";
import Breadcrumb from "../../common/Breadcrumb";

class Tenants extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The manager tenants component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Tenants" />

                    <TenantList userType={this.props.userSession.sessionRole.type}
                                userId={this.props.userSession.sessionRole.id}
                                history={this.props.history}
                                inviteTenant={true}
                                createTenant={true}
                                parent={this} />

                </div>

            </div>
        )
    };
}

export default Tenants;