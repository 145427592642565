import React, { Component } from 'react';
import FieldSelect from "../common/FieldSelect";
import states from "../../util/states";
import {FormattedMessage} from "react-intl";

class FieldState extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A select field component, specialized for US states.
     */
    render() {

        return(
            <FieldSelect id={this.props.id} label={this.props.label} labelClass={this.props.labelClass} fieldClass={this.props.fieldClass}
                         fieldColumns={this.props.fieldColumns} labelColumns={this.props.labelColumns} handleChange={this.props.handleChange}
                         disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value}
                         required={this.props.required}>

                <option value="">Select a state...</option>

                <React.Fragment>
                    {Object.keys(states).map(key => {
                        return (
                            <FormattedMessage id={"state." + key}>
                                {(state) => <option key={key} value={key}>{state}</option>}
                            </FormattedMessage>
                        )
                    })}
                </React.Fragment>
            </FieldSelect>
        )
    };
}

FieldState.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    disabled: false
};

export default FieldState;
