import Propertii from "../../common/Propertii";
import React from "react";
import {injectIntl} from "react-intl";
import EftReport from "../../common/ReportEfts";
import ButtonBack from "../../common/ButtonBack";
import Breadcrumb from "../../common/Breadcrumb";

class Efts extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The company EFT report component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/manager/reports" parentPage="Reports" childPage="EFTs" />

                    <EftReport companyId={this.props.userSession.sessionRole.companyId} />

                    <div className="row">
                        <div className="col text-right">
                            <ButtonBack path="/manager/reports" />
                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default injectIntl(Efts);