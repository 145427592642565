import React from 'react';
import axios from "axios/index";
import * as constants from '../../util/constants';
import Propertii from "../common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Alert from "../common/Alert";
import {injectIntl, intlShape} from "react-intl";
import Spinner from "../common/Spinner";
import visa from "../../media/img/payments/visa.png";
import mastercard from "../../media/img/payments/mastercard.png";
import americanExpress from "../../media/img/payments/american-express.png";
import discover from "../../media/img/payments/discover.png";
import unionPay from "../../media/img/payments/unionpay.png";
import bankAccount from "../../media/img/payments/bank-account.png";
import {Link} from "react-router-dom";
import FieldText from "../common/FieldText";
import modernTower from "../../media/img/backgrounds/modern-building.jpg";

class Landing extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            hostedPaymentPage: {},
            customFields: {},
            firstName: '',
            lastName: '',
            email: '',
            confirmEmail: '',
            validationList: [],
        };

        this.initHostedPayment = this.initHostedPayment.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
    }

    /**
     * On mounting of the component, fetch the hosted payment page associated with the page name provided in the URL.
     * The values in the hosted payment page record will be used to customize the user interface accordingly based on
     * the company's needs.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/hosted_payment_page/name/${this.props.match.params.pageName}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                hostedPaymentPage: response.data,
                customFields: response.data.customFields,
            }));

        }).catch(error => {
            this.props.history.push("");
        });
    }

    /**
     * Start the hosted payment flow by redirecting the user to the payment page.
     *
     * @param event - The event container.
     */
    initHostedPayment(event) {

        event.preventDefault();

        if(this.state.email !== this.state.confirmEmail) {
            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: 'hosted.email.mismatch'
                    }
                }]
            }));
        }

        else {
            this.props.history.push({
                pathname: `/pay/${this.props.match.params.pageName}/payment`,
                state: {
                    hostedPaymentPage: this.state.hostedPaymentPage,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email
                }
            });
        }
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state.
     *
     * @param error - The server error response.
     */
    handleValidation(error) {

        let fields = {};

        if(error.response.data.fieldErrors) {
            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );
        }

        this.setState({
            validationList: [{
                alert: {
                    type: 'danger',
                    message: error.response.data.message,
                    code: error.response.data.errorCode
                },
                fields
            }],
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A payment page customized with company-specific values which allows users to make a payment
     * directly into a specific merchant account.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner}/>

                <div id="signup" className="jumbotron jumbotron-fluid content-header content-header-landing mb-0" style={{background: `linear-gradient(100deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${this.state.customFields.background ? this.state.customFields.background : modernTower}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container">

                        <div className="row align-items-center">
                            <div className="col-md-6">

                                <h1 className="text-white mb-4">
                                    {this.state.customFields.heroHeading ? this.state.customFields.heroHeading : '{HERO_HEADING}'}
                                </h1>
                                <ul className="fa-ul benefit-list text-white lead mb-5">
                                    <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" /> {this.state.customFields.heroPoint1 ? this.state.customFields.heroPoint1 : '{HERO_POINT_1}'}</li>
                                    <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" /> {this.state.customFields.heroPoint2 ? this.state.customFields.heroPoint2 : '{HERO_POINT_2}'}</li>
                                    <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" /> {this.state.customFields.heroPoint3 ? this.state.customFields.heroPoint3 : '{HERO_POINT_3}'}</li>
                                    <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" /> {this.state.customFields.heroPoint4 ? this.state.customFields.heroPoint4 : '{HERO_POINT_4}'}</li>
                                </ul>
                                <div className="mb-5">
                                    <img src={visa} className="rounded img-fluid bg-light mr-2" alt="Visa" title="Visa" width="50"/>
                                    <img src={mastercard} className="rounded img-fluid bg-light mr-2" alt="Mastercard" title="Mastercard" width="50"/>
                                    <img src={americanExpress} className="rounded img-fluid bg-light mr-2" alt="American Express" title="American Express" width="50"/>
                                    <img src={discover} className="rounded img-fluid bg-light mr-2" alt="Discover" title="Discover" width="50"/>
                                    <img src={unionPay} className="rounded img-fluid bg-light mr-2" alt="UnionPay" title="UnionPay" width="50"/>
                                    <img src={bankAccount} className="rounded img-fluid bg-light mr-2" alt="Bank Account" title="Bank Account" width="50"/>
                                </div>

                            </div>
                            <div className="col-md-6">

                                <div className="jumbotron jumbotron-fluid rounded shadow mx-md-5 px-4 py-5">
                                    <div className="container">

                                        <form onSubmit={this.initHostedPayment}>

                                            <h3 className="text-center">
                                                <img src={this.state.customFields.logo} alt={this.state.customFields.companyName} className="img-fluid" />
                                            </h3>

                                            <p className="text-center small mb-4 font-italic">
                                                {this.state.customFields.formSubheading ? this.state.customFields.formSubheading : '{FORM_SUBHEADING}'}
                                            </p>

                                            <hr className="my-4"/>

                                            <Alert validationList={this.state.validationList} />

                                            <div className="row">
                                                <div className="col-6">

                                                    <FieldText id="firstName" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0" fieldColumns="12" labelColums="0" placeholder="First Name" parent={this} value={this.state['firstName']} maxLength="100" />

                                                </div>
                                                <div className="col-6">

                                                    <FieldText id="lastName" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0" fieldColumns="12" labelColums="0" placeholder="Last Name" parent={this} value={this.state['lastName']} maxLength="100" />

                                                </div>
                                            </div>

                                            <FieldText id="email" type="email" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0" fieldColumns="12" labelColums="0" placeholder="Email" parent={this} value={this.state['email']} maxLength="100" />

                                            <FieldText id="confirmEmail" type="email" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0" fieldColumns="12" labelColums="0" placeholder="Confirm Email" parent={this} value={this.state['confirmEmail']} maxLength="100" />

                                            <div className="row">
                                                <div className="col text-right">
                                                    <button type="submit" className="btn btn-lg btn-primary btn-block mb-0">
                                                        Continue
                                                    </button>
                                                </div>
                                            </div>

                                        </form>

                                    </div>

                                </div>

                                <div className="row justify-content-sm-center">
                                    <div className="col-md-10 text-center">
                                        <p className="text-white text-center small">
                                            Already have an account? <Link to="/login">Log in now</Link>.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

Landing.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Landing);
