import React from 'react';
import Propertii from "../../common/Propertii";
import OpenChargesList from "../../common/OpenCharges";

class OpenCharges extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - An interface that allows the admin to add, edit, and delete a tenant's open charges.
     */
    render() {

        return(
            <React.Fragment>

                <OpenChargesList propertyLeaseId={this.props.match.params.propertyLeaseId} issueChargesButton={true} />

            </React.Fragment>
        )
    };
}

export default OpenCharges;