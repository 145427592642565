import React from 'react';
import {FormattedMessage, FormattedNumber, injectIntl, intlShape} from "react-intl";
import Propertii from "../common/Propertii";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import axios from "axios";
import * as constants from "../../util/constants";
import FieldSelect from "../common/FieldSelect";
import FieldText from "../common/FieldText";
import bankAccount from "../../media/img/payments/bank-account.png";
import creditCard from "../../media/img/payments/credit-card.png";
import FieldAddress from "../common/FieldAddress";
import Alert from "../common/Alert";
import 'moment-timezone';
import ReceiptPaymentTransaction from "../common/ReceiptPaymentTransaction";
import FieldCountry from "../common/FieldCountry";
import FeeProfiles from "../common/FeeProfiles";
import Spinner from "../common/Spinner";
import FieldCharges from "../common/FieldCharges";
import FieldCardNumber from "../common/FieldCardNumber";
import CardBrandIcon from "../common/CardBrandIcon";
import ModalUnionPay from "../common/ModalUnionPay";
import CardPreview from "../common/CardPreview";

class Payment extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            firstName: '',
            lastName: '',
            email: '',
            confirmEmail: '',

            company: {},
            charges: [{}],
            paymentMethod: {},
            billingAddress: {},

            creditCardSecurityCodeLabel: 'CVV',
            bankAccountInstitutionNumber: '',
            bankAccountTransitNumber: '',

            customerCompleted: false,
            chargesCompleted: false,
            paymentMethodCompleted: false,

            showCreditCardForm: false,
            showBankAccountForm: false,
            showBillingAddressForm: false,
            populateBillingAddressForm: false,

            amount: 0,
            feeAmount: 0,

            feeProfileList: [{}],
            feeProfileCountry: '',

            paymentTransaction: {},

            acceptedPaymentTypes: [],
            customChargeCodes: [],

            validationList: [],

        };

        this.saveCustomer = this.saveCustomer.bind(this);
        this.saveCharges = this.saveCharges.bind(this);
        this.savePaymentMethod = this.savePaymentMethod.bind(this);

        this.editCustomer = this.editCustomer.bind(this);
        this.editCharges = this.editCharges.bind(this);
        this.editPaymentMethod = this.editPaymentMethod.bind(this);

        this.addCharge = this.addCharge.bind(this);
        this.removeCharge = this.removeCharge.bind(this);

        this.createPaymentMethod = this.createPaymentMethod.bind(this);

        this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);

        this.calculateFees = this.calculateFees.bind(this);
        this.getFeeProfiles = this.getFeeProfiles.bind(this);

        this.submitPayment = this.submitPayment.bind(this);
    }

    /**
     * On mounting of the component, fetch the hosted payment page and relevant company in order to load custom charges.
     */
    componentDidMount() {

        if(this.props.location && this.props.location.state && this.props.location.state.hostedPaymentPage) {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.location.state.hostedPaymentPage.companyId}/peek`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                let acceptedPaymentTypes = [];

                if(this.props.location.state.hostedPaymentPage.creditMerchantAccountList) {
                    Object.entries(this.props.location.state.hostedPaymentPage.creditMerchantAccountList).forEach(([creditMerchantAccountBrand, creditMerchantAccountId]) => {
                        acceptedPaymentTypes.push(creditMerchantAccountBrand);
                    });
                }

                if(this.props.location.state.hostedPaymentPage.bankMerchantAccountId) {
                    acceptedPaymentTypes.push("TYPE_BANK_ACCOUNT");
                }

                this.setState(prevState => ({
                    ...prevState,
                    company: response.data,
                    customChargeCodes: response.data.chargeCodes,
                    acceptedPaymentTypes: acceptedPaymentTypes,
                    hostedPaymentPage: this.props.location.state.hostedPaymentPage,
                    firstName: this.props.location.state.firstName,
                    lastName: this.props.location.state.lastName,
                    email: this.props.location.state.email,
                }));

            }).catch(error => {
                console.error(error);
                this.props.history.push("");
            });
        }
    }

    /**
     * Save the customer portion of the hosted payment page flow.
     *
     * @param event - The event container.
     */
    saveCustomer(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            customerCompleted: true,
            validationList: [],
        }));
    }

    /**
     * Save the charges portion of the hosted payment page flow. Calculates the total of all charges, and also checks to
     * see if at least one charge has been fully provided. Splices out any incomplete charges.
     *
     * @param event - The event container.
     */
    saveCharges(event) {

        event.preventDefault();

        let charges = this.state.charges;
        let amount = 0;

        // Calculate the total of all charges
        charges.forEach((charge, index) => {

            amount += parseFloat(charge.amount);

            // Splice out incomplete charges
            if(charges.length > 1) {
                if(!charge.amount || !charge.code) {
                    charges.splice(index, 1);
                }
            }
        });

        // Determine if at least one charge has been fully provided
        if(!charges[0].amount || !charges[0].code) {

            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: 'common.payment.charges.null'
                    }
                }]
            });

            window.scrollTo(0, 0);

        } else {

            this.setState(prevState => ({
                ...prevState,
                chargesCompleted: true,
                charges: charges,
                amount: amount,
                validationList: []
            }));

        }
    }

    /**
     * Save the selected payment method. Handle basic validation such as no payment method selected, selecting an
     * existing payment method, or creating a new payment method. After saving, calculate the transaction fees based on
     * the selected charges and payment method information.
     *
     * @param event - The event container.
     */
    savePaymentMethod(event) {

        if(event != null) {
            event.preventDefault();
        }

        if(this.state.possibleUnionPayBin) {

            $('#confirm-unionpay').modal('show');

            return null;
        }

        // Handle no selection
        if(Object.keys(this.state.paymentMethod).length === 0) {

            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: 'common.payment.method.null'
                    }
                }]
            });

            window.scrollTo(0, 0);

            return null;
        }

        let paymentMethod = this.state.paymentMethod;

        // Handle 'securityCode' to 'cvv' conversions for credit cards
        if(paymentMethod.type === 'TYPE_CREDIT_CARD') {
            paymentMethod.cvv = this.state.paymentMethod.securityCode;
        }

        // Handle institution and transit number conversions to routing number for Canadian banks
        if(paymentMethod.type === 'TYPE_BANK_ACCOUNT' && paymentMethod.country === 'CA') {
            paymentMethod.routingNumber = this.state.bankAccountInstitutionNumber + this.state.bankAccountTransitNumber;
        }

        if(this.state.showCreditCardForm || this.state.showBankAccountForm) {

            this.setState(prevState => ({
                ...prevState,
                paymentMethodCompleted: true,
                validationList: []
            }));

            this.calculateFees();

        }
    }

    /**
     * Edit the customer portion of the hosted payment page flow.
     */
    editCustomer() {

        this.setState(prevState => ({
            ...prevState,
            customerCompleted: false,
            chargesCompleted: false,
            paymentMethodCompleted: false,
        }));
    }

    /**
     * Edit the charges portion of the hosted payment page flow.
     */
    editCharges() {

        this.setState(prevState => ({
            ...prevState,
            chargesCompleted: false,
            paymentMethodCompleted: false,
        }));
    }

    /**
     * Edit the payment method portion of the hosted payment page flow.
     */
    editPaymentMethod() {

        this.setState(prevState => ({
            ...prevState,
            paymentMethodCompleted: false,
        }));
    }

    /**
     * Add a new blank charge to the list of charges.
     */
    addCharge() {

        this.setState(prevState => ({
            ...prevState,
            charges: [...prevState.charges, {
                amount: '',
                code: '',
                name: ''
            }],
        }));
    }

    /**
     * Remove a specific charge from the list of charges.
     *
     * @param index - The array index of the charge to remove.
     */
    removeCharge(index) {

        let charges = this.state.charges;

        charges.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            charges: charges,
        }));
    }

    /**
     * Initialize a new instance of a payment method.
     *
     * @param paymentType - The type of the payment method (i.e. credit_card, bank_account, etc.)
     */
    createPaymentMethod(paymentType) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${paymentType}/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethod: response.data,
                showCreditCardForm: paymentType === 'credit_card',
                showBankAccountForm: paymentType === 'bank_account'
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the billing address field. If the 'Add new billing address...' option is selected, reveal the
     * billing address creation form.
     *
     * @param event - The event container.
     */
    handleChangeBillingAddress(event) {

        if(event.target.value !== 'NEW') {
            this.setState(({
                paymentMethod: {
                    ...this.state.paymentMethod,
                    billingAddressId: event.target.value
                },
            }));
        } else {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    showBillingAddressForm: true,
                    billingAddress: {
                        ...response.data,
                        addressType: 'BILLING',
                    },
                    paymentMethod: {
                        ...prevState.paymentMethod,
                        billingAddressId: response.data.id
                    },
                }));
            }).catch(error => {
                this.handleValidation(error);
            });
        }
    }

    /**
     * Handle changes to the selected payment method. Parses the value of the selected payment method as JSON. If the
     * user happens to select an existing payment method while creating a new payment method, hide the new payment
     * method creation and billing address creation forms accordingly.
     *
     * @param event - The event container.
     */
    handleChangePaymentMethod(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: JSON.parse(event.target.value),
        }));

        // If selecting an existing method while currently entering a new method, close all new method forms
        if(this.state.showCreditCardForm || this.state.showBankAccountForm) {
            this.setState(prevState => ({
                ...prevState,
                showCreditCardForm: false,
                showBankAccountForm: false,
                showBillingAddressForm: false
            }));
        }
    }

    /**
     * Calculate the transaction fees that will be charged to the user prior to making the payment. Transaction fees can
     * only be calculated after the billing account, charge total, and payment method has been provided.
     */
    calculateFees() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/calculatefee`, {
            amount: this.state.amount,
            companyId: this.state.company.id,
            paymentMethod: this.state.paymentMethod,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                feeAmount: response.data
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Fetch a list of all fee profiles related to the company at hand.
     */
    getFeeProfiles() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/fee_profile?companyId=${this.state.company.id}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                feeProfileList: response.data,
                feeProfileCountry: this.state.company.country
            }));

            $("#fees").modal("show");

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Confirm and submit the payment.
     */
    submitPayment() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/hostedpayment`, {
            billingAddress: this.state.billingAddress,
            charges: this.state.charges,
            email: this.state.email,
            firstName: this.state.firstName,
            hostedPaymentPageId: this.state.hostedPaymentPage.id,
            lastName: this.state.lastName,
            paymentMethod: this.state.paymentMethod,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentTransaction: response.data,
                spinner: false
            }));

            $('#receipt-payment-transaction').modal('show');

        }).catch(error => {

            this.handleValidation(error);

            window.scrollTo(0, 0);

        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The generic make a payment component.
     */
    render() {

        $(function() {
            $('[data-toggle="popover"]').popover();
            $('[data-toggle="tooltip"]').tooltip();
        });

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                {this.props.location.state.hostedPaymentPage &&
                <div className="jumbotron jumbotron-fluid mb-0"
                     style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${this.props.location.state.hostedPaymentPage.customFields.background ? this.props.location.state.hostedPaymentPage.customFields.background : 'https://dheiziex291vk.cloudfront.net/static/backgrounds/college.jpg'}') no-repeat center center scroll`}}>
                    <div className="container text-md-left">
                        <h1 className="display-5 pt-3 text-white">
                            <FormattedMessage id="hosted.payment.heading" values={{firstName: this.props.location.state.firstName}}/>
                        </h1>
                        <p className="lead text-white">
                            <FormattedMessage id='hosted.payment.subheading' values={{companyName: this.state.company.name}}/>
                        </p>
                    </div>
                </div>
                }

                <div className="container">

                    <div className="row">
                        <div className="col-md-8">

                            <Alert validationList={this.state.validationList} />

                            <div className="card">

                                <div className="card-header">
                                    Personal
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">
                                        Who is making this payment?
                                    </p>
                                </div>

                                <div className="card-body">

                                    {this.state.customerCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editCustomer()}>
                                                <div className="">
                                                    {this.state.firstName} {this.state.lastName}
                                                </div>
                                                <small className="mb-0 ml-md-0 small text-muted">
                                                    {this.state.email}
                                                </small>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editCustomer()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {!this.state.customerCompleted &&
                                    <form onSubmit={this.saveCustomer} autoComplete="off">

                                        <FieldText id="firstName" label="First Name" parent={this} required={true} value={this.state['firstName']} />

                                        <FieldText id="lastName" label="Last Name" parent={this} required={true} value={this.state['lastName']} />

                                        <FieldText id="email" label="Email" type="email" parent={this} required={true} value={this.state['email']} />

                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </form>
                                    }

                                </div>

                            </div>

                            {this.state.customerCompleted &&
                            <div className="card">

                                <div className="card-header">
                                    Charges
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">
                                        How much would you like to pay?
                                    </p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveCharges}>

                                        {this.state.chargesCompleted &&
                                        <div className="">

                                            <div className="list-group">
                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editCharges()}>
                                                    <table className="table table-borderless mb-0 table-sm">

                                                        <tbody>
                                                        {this.state.charges.map((data, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>
                                                                        <FormattedMessage id={"charge." + data.code} defaultMessage={data.name} />
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {data.amount &&
                                                                        <FormattedNumber value={data.amount} style={`currency`} currency="USD"/>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>

                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editCharges()}>
                                                Edit
                                            </div>

                                        </div>
                                        }

                                        {!this.state.chargesCompleted &&
                                        <React.Fragment>

                                            <FieldCharges customChargeCodes={this.state.customChargeCodes.length > 0 ? this.state.customChargeCodes : null} charges={this.state.charges} addCharge={this.addCharge} removeCharge={this.removeCharge} parent={this} />

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>
                            </div>
                            }

                            {this.state.chargesCompleted &&
                            <div className="card">

                                <div className="card-header">
                                    Payment Method
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">
                                        How would you like to pay?
                                    </p>
                                </div>

                                <div className="card-body">

                                    {this.state.paymentMethodCompleted &&
                                    <div className="">
                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editPaymentMethod()}>
                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        <div className="">
                                                            {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                                            <React.Fragment>
                                                                Bank Account
                                                            </React.Fragment>
                                                            }
                                                            {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                                            <React.Fragment>
                                                                <FormattedMessage id={"enum.creditCard.brand." + this.state.paymentMethod.brand} />
                                                            </React.Fragment>
                                                            }
                                                        </div>
                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                                            <React.Fragment>
                                                                Account number ending in {this.state.paymentMethod.bankAccountNumber.slice(-4)}
                                                            </React.Fragment>
                                                            }
                                                            {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                                            <React.Fragment>
                                                                Card number ending in {this.state.paymentMethod.cardNumber.slice(-4)}
                                                            </React.Fragment>
                                                            }
                                                        </small>
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="float-right mr-2">
                                                            <CardBrandIcon paymentMethodType={this.state.paymentMethod.type} brand={this.state.paymentMethod.brand} customClasses="w-75" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editPaymentMethod()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {!this.state.paymentMethodCompleted &&
                                    <React.Fragment>

                                        <form onSubmit={this.savePaymentMethod}>

                                            {!this.state.showCreditCardForm &&
                                            <div className="list-group mb-2">
                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.createPaymentMethod('credit_card')}>
                                                    <div className="row align-items-center">
                                                        <div className="col-8">
                                                            Add New Credit or Debit Card
                                                        </div>
                                                        <div className="col text-right">
                                                            <div className="float-right">
                                                                <img src={creditCard} className="rounded border w-75" alt="Add New Credit or Debit Card"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }

                                            {this.state.showCreditCardForm &&
                                            <div className="list-group mb-2">
                                                <div className="list-group-item pb-3">

                                                    <div className="row align-items-center mb-3">
                                                        <div className="col-8">
                                                            Add New Credit or Debit Card
                                                        </div>
                                                        <div className="col text-right">
                                                            <div className="float-right">
                                                                <img src={creditCard} className="rounded border w-75" alt="Add New Credit or Debit Card" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <CardPreview paymentMethod={this.state.paymentMethod} cardPreviewFlipped={this.state.cardPreviewFlipped} activePaymentMethodField={this.state.activePaymentMethodField} />

                                                    <FieldCardNumber id="cardNumber" label="Card Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod.cardNumber} brand={this.state.paymentMethod.brand} handleFocus={() => this.handleFocusPaymentMethodField('cardNumber')} handleBlur={() => this.handleBlurPaymentMethodField('cardNumber')} />

                                                    <FieldText id="nameOnCard" label="Card Holder Name" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['nameOnCard']} handleFocus={() => this.handleFocusPaymentMethodField('nameOnCard')} handleBlur={() => this.handleBlurPaymentMethodField('nameOnCard')} />

                                                    <div className="row mb-3">

                                                        <div className="col">
                                                            <FieldSelect id="expiryMonth" label="Month" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['expiryMonth']} handleFocus={() => this.handleFocusPaymentMethodField('expiryMonth')} handleBlur={() => this.handleBlurPaymentMethodField('expiryMonth')}>
                                                                <option value="">-</option>
                                                                <option value="1">01 - Jan</option>
                                                                <option value="2">02 - Feb</option>
                                                                <option value="3">03 - Mar</option>
                                                                <option value="4">04 - Apr</option>
                                                                <option value="5">05 - May</option>
                                                                <option value="6">06 - Jun</option>
                                                                <option value="7">07 - Jul</option>
                                                                <option value="8">08 - Aug</option>
                                                                <option value="9">09 - Sep</option>
                                                                <option value="10">10 - Oct</option>
                                                                <option value="11">11 - Nov</option>
                                                                <option value="12">12 - Dec</option>
                                                            </FieldSelect>
                                                        </div>

                                                        <div className="col">
                                                            <FieldSelect id="expiryYear" label="Year" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['expiryYear']} handleFocus={() => this.handleFocusPaymentMethodField('expiryYear')} handleBlur={() => this.handleBlurPaymentMethodField('expiryYear')}>
                                                                <option value="">-</option>
                                                                <option value="2020">2020</option>
                                                                <option value="2021">2021</option>
                                                                <option value="2022">2022</option>
                                                                <option value="2023">2023</option>
                                                                <option value="2024">2024</option>
                                                                <option value="2025">2025</option>
                                                                <option value="2026">2026</option>
                                                                <option value="2027">2027</option>
                                                                <option value="2028">2028</option>
                                                                <option value="2029">2029</option>
                                                                <option value="2030">2030</option>
                                                                <option value="2031">2031</option>
                                                                <option value="2032">2032</option>
                                                                <option value="2033">2033</option>
                                                            </FieldSelect>
                                                        </div>

                                                        <div className="col">
                                                            <FieldText id="securityCode" label={this.state.creditCardSecurityCodeLabel} model="paymentMethod" required={true} fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod.securityCode} handleFocus={() => this.handleFocusPaymentMethodField('securityCode')} handleBlur={() => this.handleBlurPaymentMethodField('securityCode')} />
                                                        </div>

                                                    </div>

                                                    {!this.state.showBillingAddressForm &&
                                                    <FieldSelect id="billingAddressId" label="Billing Address" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['billingAddressId']} handleChange={this.handleChangeBillingAddress}>

                                                        <option value="">Select from your billing addresses...</option>
                                                        <option value="NEW">Add new billing address...</option>

                                                    </FieldSelect>
                                                    }

                                                    {this.state.showBillingAddressForm &&
                                                    <div className="mb-2">

                                                        <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} parent={this} value={this.state.billingAddress} />

                                                    </div>
                                                    }

                                                </div>
                                            </div>
                                            }

                                            {!this.state.showBankAccountForm &&
                                            <div className="list-group mb-2">
                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.createPaymentMethod('bank_account')}>
                                                    <div className="row align-items-center">
                                                        <div className="col-8">
                                                            Add New Bank Account
                                                        </div>
                                                        <div className="col text-right">
                                                            <div className="float-right">
                                                                <img src={bankAccount} className="rounded border w-75" alt="Add New Bank Account"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }

                                            {this.state.showBankAccountForm &&
                                            <div className="list-group mb-2">
                                                <div className="list-group-item pb-3">

                                                    <div className="row align-items-center mb-3">
                                                        <div className="col-8">
                                                            Add New Bank Account
                                                        </div>
                                                        <div className="col text-right">
                                                            <div className="float-right">
                                                                <img src={bankAccount} className="rounded border w-75" alt="Add New Bank Account" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {this.state.paymentMethod['country'] &&
                                                    <React.Fragment>

                                                        {this.state.paymentMethod['country'] === 'CA' &&
                                                        <React.Fragment>

                                                            <FieldText id="bankAccountInstitutionNumber"
                                                                       label="Institution Number"
                                                                       help={
                                                                           <div data-toggle="popover"
                                                                                data-trigger="focus"
                                                                                tabIndex="0"
                                                                                data-html="true"
                                                                                title="Institution Number"
                                                                                className="btn-link d-inline c-pointer"
                                                                                data-content="The institution number is the 3-digit number that identifies your financial institution. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/CAD/en/institution-number.png' class='img-fluid mt-2' />">
                                                                               What is this?
                                                                           </div>
                                                                       }
                                                                       labelColumns="12"
                                                                       fieldColumns="12"
                                                                       fieldClass="form-control-md mb-0"
                                                                       type="tel"
                                                                       parent={this}
                                                                       required={true}
                                                                       value={this.state['bankAccountInstitutionNumber']}
                                                            />

                                                            <FieldText id="bankAccountTransitNumber"
                                                                       label="Transit Number"
                                                                       labelColumns="12"
                                                                       fieldColumns="12"
                                                                       fieldClass="form-control-md mb-0"
                                                                       type="tel" help={
                                                                <div data-toggle="popover"
                                                                     data-trigger="focus"
                                                                     tabIndex="0"
                                                                     data-html="true"
                                                                     title="Transit Number"
                                                                     className="btn-link d-inline c-pointer"
                                                                     data-content="The transit number is the 5-digit number that identifies your specific bank branch. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/CAD/en/transit-number.png' class='img-fluid mt-2' />">
                                                                    What is this?
                                                                </div>
                                                            }
                                                                       parent={this}
                                                                       required={true}
                                                                       value={this.state['bankAccountTransitNumber']}
                                                            />

                                                        </React.Fragment>
                                                        }

                                                        {this.state.paymentMethod['country'] === 'US' &&
                                                        <React.Fragment>

                                                            <FieldText id="routingNumber"
                                                                       label="Routing Number"
                                                                       labelColumns="12"
                                                                       fieldColumns="12"
                                                                       fieldClass="form-control-md mb-0"
                                                                       type="tel" help={
                                                                <div data-toggle="popover"
                                                                     data-trigger="focus"
                                                                     tabIndex="0"
                                                                     data-html="true"
                                                                     title="Routing Number"
                                                                     className="btn-link d-inline c-pointer"
                                                                     data-content="The routing number is a 9-digit or 8-digit code that is used to identify where your bank account was opened. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/USD/en/routing-number.png' class='img-fluid mt-2' />">
                                                                    What is this?
                                                                </div>
                                                            }
                                                                       parent={this}
                                                                       required={true}
                                                                       value={this.state.paymentMethod['routingNumber']}
                                                            />

                                                        </React.Fragment>
                                                        }

                                                        <FieldText id="bankAccountNumber"
                                                                   label="Account Number"
                                                                   model="paymentMethod"
                                                                   labelColumns="12"
                                                                   fieldColumns="12"
                                                                   fieldClass="form-control-md mb-0"
                                                                   type="tel"
                                                                   help={
                                                                       <div data-toggle="popover"
                                                                            data-trigger="focus"
                                                                            tabIndex="0"
                                                                            data-html="true"
                                                                            title="Account Number"
                                                                            className="btn-link d-inline c-pointer"
                                                                            data-content="The bank account number is the 7 to 12-digit number that is specific to your personal account. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/CAD/en/account-number.png' class='img-fluid mt-2' />">
                                                                           What is this?
                                                                       </div>
                                                                   }
                                                                   parent={this}
                                                                   required={true}
                                                                   value={this.state.paymentMethod['bankAccountNumber']}
                                                        />

                                                        <FieldText id="accountHolder" label="Account Holder" required={true} model="paymentMethod" labelColumns="12" fieldColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['accountHolder']} />

                                                        <FieldCountry id="country" label="Bank Country" required={true} model="paymentMethod" labelColumns="12" fieldColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['country']} />

                                                        {!this.state.showBillingAddressForm &&
                                                        <FieldSelect id="billingAddressId" label="Billing Address" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['billingAddressId']} handleChange={this.handleChangeBillingAddress}>

                                                            <option value="">Select from your billing addresses...</option>
                                                            <option value="NEW">Add new billing address...</option>

                                                        </FieldSelect>
                                                        }

                                                        {this.state.showBillingAddressForm &&
                                                        <div className="mb-2">

                                                            <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} parent={this} value={this.state.billingAddress} />

                                                        </div>
                                                        }

                                                    </React.Fragment>
                                                    }

                                                </div>
                                            </div>
                                            }

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>

                                        </form>

                                    </React.Fragment>
                                    }

                                </div>

                                <div className="card-footer">
                                    <p className="small text-muted mb-0">
                                        Transactions made through Letus are subject to transaction fees, dependant upon your chosen method of payment. To view a list of our transaction fees, <span className="btn-link c-pointer" data-toggle="modal" data-target="#fees" onClick={() => this.getFeeProfiles()}>click here</span>.
                                    </p>
                                </div>

                            </div>
                            }

                        </div>
                        <div className="col-md-4">
                            <div className="sticky-top">

                                <div className="card">
                                    <div className="card-header">
                                        Payment Summary
                                    </div>

                                    {!this.state.chargesCompleted &&
                                    <div className="card-body">
                                        <div className="row justify-content-center">
                                            <div className="col-10">
                                                <div className="text-center text-secondary">
                                                    <FontAwesomeIcon icon={['far', 'receipt']} className="fa-fw mt-3 mb-4" size="5x" />
                                                </div>
                                                <div className="text-center text-muted">
                                                    <small>
                                                        Once you add your charges, your payment summary will appear here.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    <div className="card-body card-body-list">

                                        {this.state.chargesCompleted &&
                                        <ul className="list-group">
                                            {this.state.charges.map((data, key) => {
                                                return (
                                                    <li key={key} className="list-group-item">
                                                        <div className="float-left">
                                                            <FormattedMessage id={`charge.${data.code}`} defaultMessage={data.name} />
                                                        </div>
                                                        <div className="float-right">
                                                            <FormattedNumber value={data.amount} style={`currency`} currency={`USD`} />
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                            <li className="list-group-item">
                                                <div className="float-left font-weight-bold">
                                                    Subtotal
                                                </div>
                                                <div className="float-right font-weight-bold">
                                                    <FormattedNumber value={this.state.amount} style={`currency`} currency={`USD`} />
                                                </div>
                                            </li>
                                        </ul>
                                        }

                                        {(this.state.customerCompleted && this.state.chargesCompleted && this.state.paymentMethodCompleted) &&
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <div className="float-left">
                                                    Service Fee
                                                </div>
                                                <div className="float-right">
                                                    <FormattedNumber value={this.state.feeAmount} style={`currency`} currency={`USD`} />
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="float-left font-weight-bold">
                                                    Total
                                                </div>
                                                <div className="float-right font-weight-bold">
                                                    <FormattedNumber value={this.state.amount + this.state.feeAmount} style={`currency`} currency={`USD`} />
                                                </div>
                                            </li>
                                        </ul>
                                        }

                                    </div>

                                    {(this.state.customerCompleted && this.state.chargesCompleted && this.state.paymentMethodCompleted) &&
                                    <div className="card-footer text-center">
                                        <small className="">
                                            <div className="btn-link c-pointer" data-toggle="modal" data-target="#fees" onClick={() => this.getFeeProfiles()}>
                                                How are transaction fees calculated?
                                            </div>
                                        </small>
                                    </div>
                                    }

                                </div>

                                {(this.state.customerCompleted && this.state.chargesCompleted && this.state.paymentMethodCompleted) &&
                                <div className="btn btn-primary btn-lg btn-block py-3 mb-4" onClick={() => this.submitPayment()}>

                                    <div className="">
                                        <FontAwesomeIcon icon={['far', 'lock']} className="fa-fw" /> Confirm Payment
                                    </div>

                                </div>
                                }

                            </div>
                        </div>
                    </div>

                    <FeeProfiles feeProfileList={this.state.feeProfileList} feeProfileCountry={this.state.feeProfileCountry} />

                    <ModalUnionPay parent={this} />

                    <ReceiptPaymentTransaction paymentTransaction={this.state.paymentTransaction} displayFeeAmount={true}>

                        {this.props.location && this.props.location.state && this.props.location.state.hostedPaymentPage &&
                        <div className="float-left">
                            <Link to={`/pay/${this.props.location.state.hostedPaymentPage.pageName}`} className={`btn btn-outline-primary btn-lg`} onClick={() => $('#receipt-payment-transaction').modal('hide')}>
                                <FormattedMessage id="button.close"/>
                            </Link>
                        </div>
                        }

                        <div className="float-right">
                            <div className="btn btn-primary btn-lg" onClick={() => window.print()}>
                                <FontAwesomeIcon icon={['far', 'print']} className="fa-fw va-b" /> Print
                            </div>
                        </div>

                    </ReceiptPaymentTransaction>

                </div>

            </div>
        )
    };
}

Payment.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Payment);