import React from 'react';
import {FormattedMessage} from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { injectIntl, intlShape } from 'react-intl';
import axios from "axios/index";
import { Link } from 'react-router-dom';
import * as constants from '../util/constants';
import Alert from "./common/Alert";
import Propertii from "./common/Propertii";
import GoogleLogin from "react-google-login";
import $ from "jquery";
import google from '../media/img/logos/google.png';
import familyMoving from "../media/img/backgrounds/couple-laptop.jpg";
import Modal from "./common/Modal";

class Login extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            type: '',
            username: '',
            password: '',
            validationList: [],
        };

        this.submitLogin = this.submitLogin.bind(this);
        this.submitOAuth = this.submitOAuth.bind(this);
        this.resetPassword = this.resetPassword.bind(this);

        this.searchBroadcastMessages = this.searchBroadcastMessages.bind(this);

        this.handleValidation = this.handleValidation.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Handle change events on fields.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state.
     *
     * @param error - The server error response.
     */
    handleValidation(error) {

        let fields = {};

        if(error.response.data.fieldErrors) {
            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );
        }

        // Handle account status validation errors
        const accountStatus = error.response.data.accountStatus;

        // Display login-specific errors if the error code returned is the generic system account status error
        if(error.response.data.errorCode === 'RM_ERROR_SYSTEM_ACCOUNT_STATUS') {
            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        message: error.response.data.message,
                        code: 'login.' + error.response.data.errorCode + (accountStatus ? ('.' + accountStatus) : '')
                    },
                    fields
                }],
            });
            return;
        }

        if(error.response.data.errorCode === 'RM_ERROR_PASSWORD_EXPIRED') {

            $("#password-expired").modal('show');

            return;

        }

        this.setState({
            validationList: [{
                alert: {
                    type: 'danger',
                    message: error.response.data.message,
                    code: error.response.data.errorCode
                },
                fields
            }],
        });
    }

    /**
     * Handle logging in via OAuth. Parse the session owner from the returned access token and redirect the user to
     * their appropriate dashboard.
     *
     * @param response - The returned OAuth response.
     */
    submitOAuth(response) {
        console.log(response);
        axios.get(`${constants.REACT_APP_HOST_API_URL}/authorizeoauth?accountType=TYPE_GOOGLE_OAUTH_ACCOUNT&authorizationCode=${encodeURIComponent(response.code)}&requestUri=${encodeURIComponent(window.location.origin)}`).then(response => {

            // Store token data in local storage
            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);

            // Refresh the app state
            this.props.refreshAppState();

            let token = localStorage.getItem('access_token');
            let sessionRole;

            // Decipher the token
            if(token !== null) {

                let base64Url = token.split('.')[1];
                let base64 = base64Url.replace('-', '+').replace('_', '/');
                let userSession = JSON.parse(window.atob(base64));

                sessionRole = JSON.parse(userSession.sessionRole);
            }

            this.props.history.push(this.handleLoginRedirect(sessionRole));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle login form submission. If successful, store token information into the localStorage and update the entire
     * App by calling refreshAppState(). Afterward, redirect the user to their appropriate dashboard, depending on the
     * user's type and user's screening status, if applicable.
     *
     * @param event - The event container.
     */
    submitLogin(event) {

        event.preventDefault();

        axios.get(`${constants.REACT_APP_HOST_API_URL}/authorize`, {
            auth: {
                username: this.state.username,
                password: this.state.password
            }
        }).then(response => {

            // Store token data in local storage
            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);

            // Fetch broadcast messages
            this.searchBroadcastMessages();

            // Refresh the app state
            this.props.refreshAppState();

            // Decipher the token
            let access_token = localStorage.getItem('access_token');
            let base64Url = access_token.split('.')[1];
            let base64 = base64Url.replace('-', '+').replace('_', '/');
            let userSession = JSON.parse(window.atob(base64));
            let sessionRole = JSON.parse(userSession.sessionRole);

            this.props.history.push(this.handleLoginRedirect(sessionRole));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Reset the password for a system account.
     *
     * @param email - The email of the system account to reset the password for.
     */
    resetPassword(email) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/forgotpassword`, {
            username: this.state.username
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'login.reset'
                    }
                }],
            });

        }).catch(error => {
            this.handleValidation(error);
        });

    }

    /**
     * Fetch available broadcast messages for the user being logged in and store the data in the local storage.
     */
    searchBroadcastMessages() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/broadcast_message/active`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            localStorage.setItem('broadcast_messages', JSON.stringify(response.data));

            this.props.refreshAppState();

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A standard login form where the user is asked to enter their email and password.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <div className="content-block">

                <div className="content-header mb-0">
                    <div className="container">

                        <div className="row no-gutters">
                            <div className="col-md-6 col-12 align-self-center pr-md-5">

                                <div className="jumbotron jumbotron-fluid bg-transparent">

                                    <h1 className="display-5">
                                        <FormattedMessage id="static.login.heading" />
                                    </h1>

                                    <hr className="my-4" />

                                    <Alert validationList={this.state.validationList} />

                                    <form onSubmit={this.submitLogin}>

                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="input-group input-group-lg">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <FontAwesomeIcon icon={['fas', 'user']} />
                                                        </span>
                                                    </div>
                                                    <input name="username" type="email" placeholder={formatMessage({ id: "field.email"})} autoFocus={true} value={this.state['username']} onChange={this.handleChange} className={`form-control form-control-lg mb-0 rounded-right ${this.state.validationList[0] ? (this.state.validationList[0].fields['username'] ? 'is-invalid' : '') : ''}`} />
                                                    {this.state.validationList[0] &&
                                                    <div className="invalid-feedback">
                                                        <FormattedMessage id={"danger." + this.state.validationList[0].fields['username']} />
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="input-group input-group-lg">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <FontAwesomeIcon icon={['fas', 'key']} />
                                                        </span>
                                                    </div>
                                                    <input name="password" type="password" placeholder={formatMessage({ id: "field.password"})} value={this.state['password']} onChange={this.handleChange} className={`form-control form-control-lg mb-0 rounded-right ${this.state.validationList[0] ? (this.state.validationList[0].fields['password'] ? 'is-invalid' : '') : ''}`} />
                                                    {this.state.validationList[0] &&
                                                    <div className="invalid-feedback">
                                                        <FormattedMessage id={"danger." + this.state.validationList[0].fields['password']} />
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col text-left">
                                                <div className="small mb-3">
                                                    <Link to="/signup">Don't have an account?</Link>
                                                </div>
                                            </div>
                                            <div className="col text-right">
                                                <div className="small mb-3">
                                                    <Link to="/forgot-password">Forgot your password?</Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col text-right">
                                                <button type="submit" className="btn btn-lg btn-primary btn-block mb-0">
                                                    <FormattedMessage id="button.login" />
                                                </button>
                                            </div>
                                        </div>

                                        <div className="divider">
                                            <span className="small text-muted font-italic text-uppercase">or</span>
                                        </div>

                                        <div className="row">
                                            <div className="col text-center">
                                                <GoogleLogin
                                                    clientId={constants.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                                                    render={renderProps => (
                                                        <div className="btn btn-md btn-block btn-outline-primary btn-google" onClick={renderProps.onClick}>
                                                            <img src={google} alt="Log In with Google" style={{height: '15px'}} className="m-1" /> Log In with Google
                                                        </div>
                                                    )}
                                                    onSuccess={this.submitOAuth}
                                                    onFailure={this.submitOAuth}
                                                    responseType="code"
                                                />
                                            </div>
                                        </div>

                                        <Modal id="password-expired" theme="primary" iconType="fas" iconName="key" title="Password Expired" body="Your Letus account password has expired. Click the button below to receive an email with a link to reset your password.">
                                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                                                <FormattedMessage id="button.close" />
                                            </button>
                                            <button onClick={() => {this.resetPassword()}} className="btn btn-primary btn-lg" data-dismiss="modal">
                                                Reset My Password
                                            </button>
                                        </Modal>

                                    </form>

                                </div>

                            </div>
                            <div className="col-md-6 d-none d-md-block align-self-center">

                                <div style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${familyMoving}') no-repeat center center scroll`, backgroundSize: "cover", height: "725px", width: "998px"}}>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

Login.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Login);
