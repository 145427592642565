import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import Propertii from "../../common/Propertii";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldSelect from "../../common/FieldSelect";

class Merchants extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            property: {},

            customMerchantAccounts: false,
            creditMerchantAccountList: {},

            validationList: [],

        };

        this.saveProperty = this.saveProperty.bind(this);
        this.handleChangeCreditMerchantAccount = this.handleChangeCreditMerchantAccount.bind(this);
    }

    /**
     * Fetch a new instance of a property object on the mounting of the component.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${this.props.match.params.propertyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let companyId = response.data.companyId;
            let customMerchantAccounts = true;

            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA_ELECTRON');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MASTERCARD');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MAESTRO');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'AMERICAN_EXPRESS');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DISCOVER');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DINERS_CLUB');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'JCB');
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'UNION_PAY');
            this.getMerchantAccounts(companyId,'TYPE_BANK_ACCOUNT');
            this.getMerchantAccounts(companyId,'TYPE_CASH');
            this.getMerchantAccounts(companyId,null);

            if(response.data.bankMerchantAccountId === null
                && response.data.padMerchantAccountId === null
                && response.data.cashMerchantAccountId === null
                && Object.keys(response.data.creditMerchantAccountList).length === 0) {
                customMerchantAccounts = false;
            }

            this.setState(prevState => ({
                ...prevState,
                property: response.data,
                creditMerchantAccountList: response.data.creditMerchantAccountList,
                customMerchantAccounts: customMerchantAccounts
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle the submission of the form.
     *
     * @param event - The event container.
     */
    saveProperty(event) {

        event.preventDefault();

        let property = this.state.property;

        property.creditMerchantAccountList = this.state.creditMerchantAccountList;

        Object.entries(property.creditMerchantAccountList).forEach(([key, value]) => {
            if(value === '' || !this.state.customMerchantAccounts) {
                delete property.creditMerchantAccountList[key];
            }
        });

        if(!this.state.customMerchantAccounts) {
            property.bankMerchantAccountId = null;
            property.cashMerchantAccountId = null;
            property.padMerchantAccountId = null;
        }

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, property, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved'
                    }
                }],
            });
        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle changes to the default credit/debit merchant account field. By selecting a merchant account from this
     * field, the same merchant account will be applies to all credit/debit card brands available.
     *
     * @param event - The event container.
     */
    handleChangeCreditMerchantAccount(event) {

        event.preventDefault();

        let creditMerchantAccountList = this.state.creditMerchantAccountList;
        let creditMerchantAccountId = event.target.value;

        creditMerchantAccountList['VISA'] = creditMerchantAccountId;
        creditMerchantAccountList['VISA_ELECTRON'] = creditMerchantAccountId;
        creditMerchantAccountList['MASTERCARD'] = creditMerchantAccountId;
        creditMerchantAccountList['MAESTRO'] = creditMerchantAccountId;
        creditMerchantAccountList['AMERICAN_EXPRESS'] = creditMerchantAccountId;
        creditMerchantAccountList['DISCOVER'] = creditMerchantAccountId;
        creditMerchantAccountList['DINERS_CLUB'] = creditMerchantAccountId;
        creditMerchantAccountList['JCB'] = creditMerchantAccountId;

        this.setState(prevState => ({
            ...prevState,
            creditMerchantAccountList: creditMerchantAccountList,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The edit property address component for managers.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.saveProperty}>

                    <div className="card card-primary border-primary">
                        <div className="card-body">
                            <p className="card-text">
                                Merchant accounts determine where funds should be sent to when your tenants make payments. By default, all funds are sent to your company's default merchant accounts. This can be overridden by assigning custom merchant accounts specifically to this property using the form below.
                            </p>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            Merchant Accounts
                        </div>
                        <div className="card-body">

                            <FieldCheckbox id="customMerchantAccounts" label="Merchant Accounts" fieldLabel="This property utilizes custom merchant accounts" parent={this} value={this.state.customMerchantAccounts} />

                            {this.state.customMerchantAccounts &&
                            <div className="">

                                {this.state.property.creditMerchantAccountList &&
                                <React.Fragment>

                                    <FieldSelect id="VISA" label="Credit/Debit Card" model="creditMerchantAccountList" parent={this} help="Applies to Visa, Visa Electron, Mastercard, Maestro, American Express, Discover, Diners Club, and JCB cards." value={this.state.creditMerchantAccountList['VISA']} handleChange={this.handleChangeCreditMerchantAccount}>
                                        {this.state.visaMerchantAccounts &&
                                        <React.Fragment>
                                            {this.state.visaMerchantAccounts.map((data, key) => {
                                                if(data.id === data.joins.c.creditMerchantAccountList['VISA']) {
                                                    return (<option key={key} value="">[DEFAULT] Bank account ending in *****{data.last4}</option>);
                                                } else {
                                                    return (<option key={key} value={data.id}>Bank account ending in *****{data.last4}</option>);
                                                }
                                            })}
                                            <option value="">-</option>
                                        </React.Fragment>
                                        }
                                    </FieldSelect>

                                    <FieldSelect id="UNION_PAY" label="UnionPay" model="creditMerchantAccountList" parent={this} value={this.state.creditMerchantAccountList['UNION_PAY']}>
                                        {this.state.unionPayMerchantAccounts &&
                                        <React.Fragment>
                                            {this.state.unionPayMerchantAccounts.map((data, key) => {
                                                if(data.id === data.joins.c.creditMerchantAccountList['UNION_PAY']) {
                                                    return (<option key={key} value="">[DEFAULT] Bank account ending in *****{data.last4}</option>);
                                                } else {
                                                    return (<option key={key} value={data.id}>Bank account ending in *****{data.last4}</option>);
                                                }
                                            })}
                                            <option value="">-</option>
                                        </React.Fragment>
                                        }
                                    </FieldSelect>

                                </React.Fragment>
                                }

                                <FieldSelect id="bankMerchantAccountId" label="Bank Account" model="property" parent={this} value={this.state.property['bankMerchantAccountId']}>
                                    {this.state.bankMerchantAccounts &&
                                    <React.Fragment>
                                        {this.state.bankMerchantAccounts.map((data, key) => {
                                            if(data.id === data.joins.c.bankMerchantAccountId) {
                                                return (<option key={key} value="">[DEFAULT] Bank account ending in *****{data.last4}</option>);
                                            } else {
                                                return (<option key={key} value={data.id}>Bank account ending in *****{data.last4}</option>);
                                            }
                                        })}
                                        <option value="">-</option>
                                    </React.Fragment>
                                    }
                                </FieldSelect>

                                <FieldSelect id="cashMerchantAccountId" label="Cash" model="property" parent={this} value={this.state.property['cashMerchantAccountId']}>
                                    {this.state.cashMerchantAccounts &&
                                    <React.Fragment>
                                        {this.state.cashMerchantAccounts.map((data, key) => {
                                            if(data.id === data.joins.c.cashMerchantAccountId) {
                                                return (<option key={key} value="">[DEFAULT] Bank account ending in *****{data.last4}</option>);
                                            } else {
                                                return (<option key={key} value={data.id}>Bank account ending in *****{data.last4}</option>);
                                            }
                                        })}
                                        <option value="">-</option>
                                    </React.Fragment>
                                    }
                                </FieldSelect>

                                <FieldSelect id="padMerchantAccountId" label="Pre-Authorized Debit" model="property" parent={this} value={this.state.property['padMerchantAccountId']}>
                                    {this.state.padMerchantAccounts &&
                                    <React.Fragment>
                                        {this.state.padMerchantAccounts.map((data, key) => {
                                            if(data.id === data.joins.c.padMerchantAccountId) {
                                                return (<option key={key} value="">[DEFAULT] Bank account ending in *****{data.last4}</option>);
                                            } else {
                                                return (<option key={key} value={data.id}>Bank account ending in *****{data.last4}</option>);
                                            }
                                        })}
                                        <option value="">-</option>
                                    </React.Fragment>
                                    }
                                </FieldSelect>

                            </div>
                            }

                        </div>
                    </div>


                    <div className="row">
                        <div className="col text-right">

                            <ButtonSave />

                        </div>
                    </div>

                </form>

            </React.Fragment>
        )
    };
}

export default Merchants;