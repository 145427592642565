import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import EventList from "../../common/EventList";
import axios from "axios";
import * as constants from "../../../util/constants";

class Events extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            propertyLease: {},
            customer: {},
            validationList: [],
        };
    }

    /**
     * Retrieve the property lease and customer based on the property lease ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property_lease/${this.props.match.params.propertyLeaseId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let propertyLease = response.data;

            axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${response.data.userId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    propertyLease: propertyLease,
                    customer: response.data
                }));

            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The customer event list component.
     */
    render() {

        return(
            <React.Fragment>

                {this.state.customer.id &&
                <EventList userId={this.state.customer.id} />
                }

            </React.Fragment>
        )
    };
}

Events.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Events);