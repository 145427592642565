import React, { Component } from 'react';

class ButtonSave extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A generic submit button component.
     */
    render() {

        return(
            <React.Fragment>

                <button type="submit" className={`btn btn-primary btn-lg ${this.props.customClasses ? this.props.customClasses : 'ml-2'}`}>
                    Save
                </button>

            </React.Fragment>
        )
    };
}

export default ButtonSave;