import React, { Component } from 'react';
import {Link} from "react-router-dom";

class ButtonBack extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A generic back button component.
     */
    render() {

        return(
            <React.Fragment>

                <Link to={this.props.path} className="btn btn-outline-primary btn-lg">
                    Back
                </Link>

            </React.Fragment>
        )
    };
}

export default ButtonBack;