import React from 'react';
import Propertii from "../../common/Propertii";
import EditSystemAccount from "../../common/SystemAccount";

class SystemAccount extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The edit manager system account component for admins.
     */
    render() {

        return(
            <React.Fragment>

                <EditSystemAccount userId={this.props.match.params.managerId} />

            </React.Fragment>
        )
    };
}

export default SystemAccount;