import React from 'react';
import qs from 'qs';
import axios from "axios/index";
import { Link } from 'react-router-dom';
import * as constants from '../util/constants';
import {FormattedMessage} from "react-intl";
import Propertii from "./common/Propertii";
import Alert from "./common/Alert";
import familyMoving from "../media/img/backgrounds/couple-laptop.jpg";
import Spinner from "./common/Spinner";

class Verify extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            verificationErrorCode: '',
            validationList: [],
        };

        this.resendConfirmation = this.resendConfirmation.bind(this);
    }

    /**
     * Parse the URL for the verification code query parameter. If no verification code is provided in the URL, redirect
     * the user to the login component. Otherwise, immediately call the verification endpoint. If no query parameters
     * were provided, simply display the message indicating that the user must confirm their email address.
     */
    componentDidMount() {

        let urlParams;

        if(this.props.location.search) {

            urlParams = qs.parse(this.props.location.search.slice(1));

            if(Object.keys(urlParams).length !== 0) {
                axios.post(`${constants.REACT_APP_HOST_API_URL}/verify`, {
                    verificationCode: urlParams.verificationCode
                }).then(response => {
                    this.setState({
                        verificationErrorCode: 'SUCCESS',
                    });
                }).catch(error => {
                    if(error.response.data) {
                        this.setState(prevState => ({
                            ...prevState,
                            verificationErrorCode: error.response.data.errorCode
                        }));
                    }
                });
            } else {
                this.props.history.push('/login');
            }

        }
    }

    /**
     * Resend the confirmation email in the event that the user's verification code has expired.
     */
    resendConfirmation() {

        const urlParams = qs.parse(this.props.location.search.slice(1));

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/email/${urlParams.emailId}/resend`, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            this.setState({
                spinner: false,
                verificationErrorCode: 'RESEND',
            });
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A message telling the user that their email verification was successful and that they may now
     * log in.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="content-header mb-0">
                    <div className="container">

                        <div className="row no-gutters">
                            <div className="col-md-6 col-12 align-self-center pr-md-5">
                                <div className="jumbotron jumbotron-fluid bg-transparent my-3">

                                    {this.state.verificationErrorCode === 'RM_ERROR_INVALID_VERIFICATION_CODE' &&
                                    <div className="card card-danger border-danger">
                                        <div className="card-body">
                                            <h4 className="card-title">
                                                <FormattedMessage id="static.verify.invalid.heading" />
                                            </h4>
                                            <p className="card-text">
                                                <FormattedMessage id="static.verify.invalid.body" />
                                            </p>
                                        </div>
                                    </div>
                                    }

                                    {this.state.verificationErrorCode === 'RM_ERROR_VERIFICATION_CODE_EXPIRED' &&
                                    <div className="">

                                        <Alert validationList={this.state.validationList} />

                                        <div className="card card-danger border-danger">
                                            <div className="card-body">
                                                <h4 className="card-title">
                                                    <FormattedMessage id="static.verify.expired.heading" />
                                                </h4>
                                                <p className="card-text">
                                                    <FormattedMessage id="static.verify.expired.body" />
                                                </p>
                                            </div>
                                        </div>

                                        <div className="btn btn-primary btn-lg btn-block mb-0" onClick={() => this.resendConfirmation()}>
                                            Resend Confirmation Email
                                        </div>

                                    </div>
                                    }

                                    {this.state.verificationErrorCode === 'SUCCESS' &&
                                    <div className="">
                                        <div className="card card-primary border-primary">
                                            <div className="card-body">
                                                <h4 className="card-title">
                                                    <FormattedMessage id="static.verify.verified.heading" />
                                                </h4>
                                                <p className="card-text">
                                                    <FormattedMessage id="static.verify.verified.body" />
                                                </p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <Link to="/login" className="btn btn-lg btn-primary btn-block">
                                                    <FormattedMessage id="button.login" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {this.state.verificationErrorCode === 'RESEND' &&
                                    <div className="">
                                        <div className="card card-primary border-primary">
                                            <div className="card-body">
                                                <h4 className="card-title">
                                                    We've sent a new confirmation email
                                                </h4>
                                                <p className="card-text">
                                                    A new confirmation email has been sent to your email address. Follow the instructions in the email to verify your account.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <Link to="/" className="btn btn-lg btn-primary btn-block">
                                                    OK
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {this.state.verificationErrorCode === '' &&
                                    <div className="">
                                        <div className="card card-primary border-primary">
                                            <div className="card-body">
                                                <h4 className="card-title">
                                                    Confirm your email address
                                                </h4>
                                                <p className="card-text">
                                                    We've sent an email to {this.props.location.state ? this.props.location.state.email : 'your inbox'} to confirm it's you. Please follow the instructions within the email to continue with your account setup.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <Link to="/" className="btn btn-lg btn-primary btn-block">
                                                    OK
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                </div>
                            </div>
                            <div className="col-md-6 d-none d-md-block align-self-center">

                                <div style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${familyMoving}') no-repeat center center scroll`, backgroundSize: "cover", height: "675px", width: "998px"}}>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        );
    }

}

export default Verify;
