import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import PaymentFlow from "../../common/Payment";
import axios from "axios";
import * as constants from "../../../util/constants";
import Breadcrumb from "../../common/Breadcrumb";

class Payment extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            propertyLease: null,
            validationList: [],
        };
    }

    /**
     * Get the property lease based on the property lease ID provided in the route on mounting of the component.
     */
    componentDidMount() {

        let billingAccountQuery = {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.match.params.propertyLeaseId
                }
            ],
            joins: {
                p: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'p',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode', 'propertyIdentifier']
                },
                u: {
                    targetRecordType: 'TYPE_CUSTOMER',
                    joinField: 'userId',
                    alias: 'u',
                    returnFields: ['firstName', 'lastName', 'phone', 'email']
                },
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                    returnFields: ['name', 'chargeCodes', 'integrationId', 'parentId']
                },
                pc: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'c.parentId',
                    alias: 'pc',
                    returnFields: ['name', 'chargeCodes', 'integrationId']
                }
            }
        };

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search?recordsPerPage=1&page=1`, {
            orderBy: billingAccountQuery.orderBy,
            orderByFields: billingAccountQuery.orderByFields,
            conditionList: billingAccountQuery.conditionList,
            joins: billingAccountQuery.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                propertyLease: response.data.records[0]
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The generic make a payment component when a manager views a tenant.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    {this.state.propertyLease &&
                    <Breadcrumb parentPath="/manager/tenants" parentPage="Tenants" childPath={`/manager/tenants/${this.state.propertyLease.id}/edit`} childPage={`${this.state.propertyLease.joins.u.firstName} ${this.state.propertyLease.joins.u.lastName}`} subChildPage="Make a Payment" />
                    }

                    {!this.state.propertyLease &&
                    <Breadcrumb parentPath="/manager/tenants" parentPage="Tenants" childPage="Make a Payment" />
                    }

                    {this.state.propertyLease !== null &&
                    <PaymentFlow parent={this}
                                 userId={this.props.userSession.sessionRole.id}
                                 userType={this.props.userSession.sessionRole.type}
                                 billingAccount={this.state.propertyLease}
                                 redirectPath={`/manager/tenants/${this.props.match.params.propertyLeaseId}/transactions`}/>
                    }

                </div>

            </div>
        )
    };
}

Payment.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Payment);