import React, { Component } from 'react';
import FieldText from "../common/FieldText";
import FieldCountry from "../common/FieldCountry";
import FieldProvince from "../common/FieldProvince";
import FieldState from "../common/FieldState";
import FieldSelect from "./FieldSelect";

class FieldAddress extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A series of address-related fields. The state/province and ZIP/postal code fields' labels display
     * appropriately based on the country selected.
     */
    render() {

        let postalCodeLabel = "ZIP/Postal Code";

        if(this.props.parent.state[this.props.model].country === 'CA') {
            postalCodeLabel = "Postal Code";
        }

        if(this.props.parent.state[this.props.model].country === 'US') {
            postalCodeLabel = "ZIP Code";
        }

        return(
            <React.Fragment>

                {this.props.addressType === true &&
                <FieldSelect id="addressType" label="Address Type" model={this.props.model} labelColumns={this.props.labelColumns}
                             fieldColumns={this.props.fieldColumns} parent={this.props.parent} value={this.props.value['addressType']}
                             disabled={this.props.disabled}>
                    <option value="" disabled>Select an address type...</option>
                    <option value="RESIDENTIAL">Residential</option>
                    <option value="BILLING">Billing</option>
                    <option value="PREVIOUS">Previous</option>
                </FieldSelect>
                }

                {this.props.suite === true &&
                <FieldText id="suite" label="Suite" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                           labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} optional={true}
                           disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['suite']}/>
                }

                <FieldText id="street1" label="Street 1" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                           labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                           disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['street1']} />

                <FieldText id="street2" label="Street 2" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                           labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} optional={true}
                           disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['street2']} />

                <FieldText id="city" label="City" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                           labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                           disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['city']} />

                <FieldCountry id="country" label="Country" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                              labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                              disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['country']} />

                {this.props.parent.state[this.props.model].country === 'CA' &&
                <FieldProvince id="province" label="Province" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                               labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                               disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['province']} />
                }

                {this.props.parent.state[this.props.model].country === 'US' &&
                <FieldState id="province" label="State" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                            labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                            disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['province']}/>
                }

                <FieldText id="postalCode" label={postalCodeLabel} labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                           labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                           disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['postalCode']} />

                {this.props.monthsAtAddress &&
                <FieldText id="monthsAtAddress" label="Months at Address" labelColumns={this.props.labelColumns} type="number" maxLength="3"
                           fieldColumns={this.props.fieldColumns} labelClass={this.props.labelClass} fieldClass={this.props.fieldClass}
                           required={this.props.required} disabled={this.props.disabled} model={this.props.model} parent={this.props.parent}
                           value={this.props.value['monthsAtAddress']}/>
                }

            </React.Fragment>
        )
    };
}

FieldAddress.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    disabled: false,
    required: false
};

export default FieldAddress;
