import React from 'react';
import NavListItem from "../../common/NavListItem";
import Propertii from "../../common/Propertii";
import Breadcrumb from "../../common/Breadcrumb";

class Account extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The admin account navigation component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Account" />

                    <div className="card">
                        <div className="card-header">
                            Options
                        </div>
                        <div className="card-body card-body-list">
                            <div className="list-group">

                                <NavListItem path="/admin/account/profile" iconType="far" iconName="pencil-alt" name="Edit Profile" size="large" description="Edit your basic contact details." />

                                {this.props.userSession.accountType === 'TYPE_LOCAL_SYSTEM_ACCOUNT' &&
                                <NavListItem path="/admin/account/password" iconType="far" iconName="lock-alt" name="Change Password" size="large" description="Change your account password."/>
                                }

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default Account;