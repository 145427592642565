import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Modal extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A generic modal component.
     */
    render() {

        return(
            <React.Fragment>

                <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby={`${this.props.id}-label`} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content shadow">

                            <div className={`modal-header text-center d-block text-white py-4 bg-dark`}>
                                <div className="">
                                    <FontAwesomeIcon icon={[this.props.iconType, this.props.iconName]} className="fa-fw va-b mb-3" size="4x" />
                                </div>
                                <h5 className="modal-title" id={`${this.props.id}-label`}>
                                    {this.props.title}
                                </h5>
                            </div>

                            {this.props.body &&
                            <div className="modal-body text-center">
                                <p className="mb-0 py-2">
                                    {this.props.body}
                                </p>
                            </div>
                            }

                            <div className="modal-footer d-block text-center bg-secondary rounded-bottom d-block">

                                {this.props.children}

                            </div>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

export default Modal;