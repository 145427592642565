import React from 'react';
import {FormattedMessage, FormattedNumber, injectIntl, intlShape} from 'react-intl';
import Banner from "../common/Banner";
import Propertii from "../common/Propertii";
import axios from "axios";
import * as constants from "../../util/constants";
import FieldCheckbox from "../common/FieldCheckbox";
import $ from "jquery";
import FieldText from "../common/FieldText";
import Alert from "../common/Alert";
import GoogleLogin from "react-google-login";
import {Link} from "react-router-dom";
import Modal from "../common/Modal";
import Spinner from "../common/Spinner";
import paymentTypes from "../../util/paymentTypes";
import bankAccount from "../../media/img/payments/bank-account.png";
import creditCard from "../../media/img/payments/credit-card.png";
import FieldSelect from "../common/FieldSelect";
import FieldAddress from "../common/FieldAddress";
import FieldCountry from "../common/FieldCountry";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FieldPassword from "../common/FieldPassword";
import FieldCardNumber from "../common/FieldCardNumber";
import CardBrandIcon from "../common/CardBrandIcon";
import TermsContents from "../common/Terms";
import ModalUnionPay from "../common/ModalUnionPay";
import CardPreview from "../common/CardPreview";
import FieldPhone from "../common/FieldPhone";
import FieldBirthDate from "../common/FieldBirthDate";
import Moment from "react-moment";
import FieldDate from "../common/FieldDate";
import FeeProfiles from "../common/FeeProfiles";

class Customer extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            invitation: {},
            property: {},
            paymentMethod: {},
            billingAddress: null,

            paymentSchedule: '',
            recurringStartDate: '',

            paymentMethodList: [],
            billingAddressList: [],

            creditCardSecurityCodeLabel: 'CVV',
            bankAccountInstitutionNumber: '',
            bankAccountTransitNumber: '',

            authorizationType: '',
            password: '',
            confirmPassword: '',

            acceptedPaymentTypes: [],

            termsAcknowledgementCompleted: false,
            customerCompleted: false,
            paymentScheduleCompleted: false,
            paymentMethodCompleted: false,
            accountCredentialsCompleted: false,

            showCreditCardForm: false,
            showBankAccountForm: false,
            showBillingAddressForm: false,
            populateBillingAddressForm: false,

            feeProfileList: [{}],
            feeProfileCountry: '',

            verificationCode: '',
            accessToken: '',

            validationList: [],

        };

        this.saveTermsAcknowledgement = this.saveTermsAcknowledgement.bind(this);
        this.saveCustomer = this.saveCustomer.bind(this);
        this.savePaymentSchedule = this.savePaymentSchedule.bind(this);
        this.savePaymentMethod = this.savePaymentMethod.bind(this);
        this.saveAccountCredentials = this.saveAccountCredentials.bind(this);

        this.editTermsAcknowledgement = this.editTermsAcknowledgement.bind(this);
        this.editCustomer = this.editCustomer.bind(this);
        this.editPaymentSchedule = this.editPaymentSchedule.bind(this);
        this.editPaymentMethod = this.editPaymentMethod.bind(this);
        this.editAccountCredentials = this.editAccountCredentials.bind(this);

        this.searchPaymentMethods = this.searchPaymentMethods.bind(this);
        this.createPaymentMethod = this.createPaymentMethod.bind(this);
        this.initBillingAddress = this.initBillingAddress.bind(this);
        this.searchBillingAddresses = this.searchBillingAddresses.bind(this);
        this.getFeeProfiles = this.getFeeProfiles.bind(this);

        this.handleChangePaymentSchedule = this.handleChangePaymentSchedule.bind(this);
        this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
        this.handleChangePopulateBillingAddress = this.handleChangePopulateBillingAddress.bind(this);
        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
        this.handleChangeAuthorizationType = this.handleChangeAuthorizationType.bind(this);

        this.acceptInvitation = this.acceptInvitation.bind(this);
    }

    /**
     * On mounting of the component, fetch the invitation data based on the ID of the invitation provided in the route.
     * If no valid invitation ID is provided, redirect the user to the homepage. Also fetches the property data using
     * the property ID provided in the invitation.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/invitation/${this.props.match.params.invitationId}/peek`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                invitation: response.data,
                invitationStep: "TERMS",
                verificationCode: this.props.location.state.verificationCode,
                accessToken: this.props.location.state.accessToken,
            }));

            axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${response.data.propertyId}/peek`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                let acceptedPaymentTypes;
                let property;

                acceptedPaymentTypes = response.data.acceptedPaymentTypes;
                property = response.data;

                axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${response.data.companyId}/peek`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    response.data.acceptedPaymentTypes.forEach((data, index) => {
                        if(!acceptedPaymentTypes.includes(data)) {
                            acceptedPaymentTypes.push(data);
                        }
                    });

                    this.setState(prevState => ({
                        ...prevState,
                        property: property,
                        acceptedPaymentTypes: acceptedPaymentTypes,
                    }));

                }).catch(error => {
                    this.handleValidation(error);
                });

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.props.history.push('/');
        });
    }

    /**
     * Save the terms acknowledgement portion of the invitation acceptance flow.
     *
     * @param event - The event container.
     */
    saveTermsAcknowledgement(event) {

        event.preventDefault();

        // Fetch the phone number and birth date from the lease application, if one exists
        if(this.state.invitation.applicationId) {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/lease_application/${this.state.invitation.applicationId}/peek`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    invitation: {
                        ...prevState.invitation,
                        phone: response.data.phone,
                        birthDate: response.data.birthDate
                    },
                }));

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        this.setState(prevState => ({
            ...prevState,
            termsAcknowledgementCompleted: true,
            customerCompleted: false,
            paymentScheduleCompleted: false,
            paymentMethodCompleted: false,
            accountCredentialsCompleted: !!this.state.invitation.customerId,
            validationList: [],
        }));
    }

    /**
     * Save the customer portion of the invitation acceptance flow.
     *
     * @param event - The event container.
     */
    saveCustomer(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            customerCompleted: true,
            paymentScheduleCompleted: false,
            paymentMethodCompleted: false,
            accountCredentialsCompleted: !!this.state.invitation.customerId,
            validationList: [],
        }));
    }

    /**
     * Save the payment schedule portion of the invitation acceptance flow.
     *
     * @param event - The event container.
     */
    savePaymentSchedule(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            paymentScheduleCompleted: true,
            paymentMethodCompleted: false,
            accountCredentialsCompleted: !!this.state.invitation.customerId,
            validationList: [],
        }));

        this.searchPaymentMethods();
    }

    /**
     * Save the selected payment method. Handle basic validation such as no payment method selected, selecting an
     * existing payment method, or creating a new payment method. After saving, calculate the transaction fees based on
     * the selected charges and payment method information.
     *
     * @param event - The event container.
     */
    savePaymentMethod(event) {

        if(event != null) {
            event.preventDefault();
        }

        if(this.state.possibleUnionPayBin) {

            $('#confirm-unionpay').modal('show');

            return null;
        }

        // Handle no selection
        if(Object.keys(this.state.paymentMethod).length === 1) {

            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: 'common.payment.method.null'
                    }
                }]
            });

            window.scrollTo(0, 0);

            return null;
        }

        let paymentMethod = this.state.paymentMethod;
        let billingAddress = this.state.billingAddress;
        let saveListData = [];

        // Handle existing payment method selection
        if(!this.state.showCreditCardForm && !this.state.showBankAccountForm) {

            this.setState(prevState => ({
                ...prevState,
                paymentMethodCompleted: true,
                accountCredentialsCompleted: !!this.state.invitation.customerId,
                showCreditCardForm: false,
                showBankAccountForm: false,
                showBillingAddressForm: false,
                validationList: []
            }));
        }

        // Add the billingAddress state to the save list queue if the new billing address form is shown
        if(this.state.showBillingAddressForm) {
            saveListData.push(billingAddress);
        }

        // Add the paymentMethod state to the save list queue if the new payment method form is shown
        if(this.state.showCreditCardForm || this.state.showBankAccountForm) {
            saveListData.push(paymentMethod);
        }

        // Handle 'securityCode' to 'cvv' conversions for credit cards
        if(paymentMethod.type === 'TYPE_CREDIT_CARD') {
            paymentMethod.cvv = this.state.paymentMethod.securityCode;
        }

        // Handle institution and transit number conversions to routing number for Canadian banks
        if(paymentMethod.type === 'TYPE_BANK_ACCOUNT' && paymentMethod.country === 'CA') {
            paymentMethod.routingNumber = this.state.bankAccountInstitutionNumber + this.state.bankAccountTransitNumber;
        }

        // Create a new payment method if the new payment method form is shown
        if(this.state.showCreditCardForm || this.state.showBankAccountForm) {

            this.setState(prevState => ({
                ...prevState,
                spinner: true
            }));

            if(this.props.userSession) {
                axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, saveListData, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    this.setState(prevState => ({
                        ...prevState,
                        paymentMethod: response.data[this.state.showBillingAddressForm ? 1 : 0],
                        paymentMethodCompleted: true,
                        accountCredentialsCompleted: !!this.state.invitation.customerId,
                        showCreditCardForm: false,
                        showBankAccountForm: false,
                        showBillingAddressForm: false,
                        spinner: false,
                        validationList: [],
                    }));

                    this.searchBillingAddresses();
                    this.searchPaymentMethods();

                }).catch(error => {

                    this.handleValidation(error);

                    window.scrollTo(0, 0);

                });
            }

            if(!this.props.userSession) {
                this.setState(prevState => ({
                    ...prevState,
                    paymentMethodCompleted: true,
                    spinner: false,
                    validationList: [],
                }));
            }
        }
    }

    /**
     * Save the account credentials portion of the invitation acceptance flow.
     *
     * @param event - The event container.
     */
    saveAccountCredentials(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            accountCredentialsCompleted: true,
            validationList: [],
        }));
    }

    /**
     * Edit the terms acknowledgement & agreement. Updates the state to reflect the terms acknowledgement portion of the
     * flow.
     */
    editTermsAcknowledgement() {

        this.setState(prevState => ({
            ...prevState,
            termsAcknowledgementCompleted: false,
            customerCompleted: false,
            paymentMethodCompleted: false,
            accountCredentialsCompleted: false
        }));
    }

    /**
     * Edit the customer details. Updates the state to reflect the customer portion of the flow.
     */
    editCustomer() {

        this.setState(prevState => ({
            ...prevState,
            customerCompleted: false,
            paymentScheduleCompleted: false,
            paymentMethodCompleted: false,
            accountCredentialsCompleted: false
        }));
    }

    /**
     * Edit the payment schedule. Updates the state to reflect the payment schedule portion of the flow.
     */
    editPaymentSchedule() {

        this.setState(prevState => ({
            ...prevState,
            paymentScheduleCompleted: false,
            paymentMethodCompleted: false,
            accountCredentialsCompleted: false
        }));
    }

    /**
     * Edit the selected payment method. Updates the state to reflect the payment method portion of the flow.
     */
    editPaymentMethod() {

        this.setState(prevState => ({
            ...prevState,
            paymentMethodCompleted: false,
            accountCredentialsCompleted: false
        }));
    }

    /**
     * Edit the preferred account log in method and credentials. Updates the state to reflect the account credentials
     * portion of the flow.
     */
    editAccountCredentials() {

        this.setState(prevState => ({
            ...prevState,
            accountCredentialsCompleted: false
        }));
    }

    /**
     * Search for all available payment methods assigned to the user and add them to the list of payment methods for the
     * user to select from. Erase the list of payment methods prior to the search to ensure no duplicates are added.
     */
    searchPaymentMethods() {

        this.setState(prevState => ({
            ...prevState,
            paymentMethodList: [],
        }));

        if(this.state.accessToken) {
            Object.keys(paymentTypes).forEach(key => {
                axios.post(`${constants.REACT_APP_HOST_API_URL}/${paymentTypes[key]}/search`, {
                    orderBy: 'ASC',
                    orderByFields: ['id'],
                    conditionList: [{
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'userId',
                        operator: 'EQUALS',
                        fieldValue: this.state.invitation.customerId
                    }]
                }, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    response.data.records.forEach((data, key) => {
                        this.setState(prevState => ({
                            ...prevState,
                            paymentMethodList: [...prevState.paymentMethodList, data],
                        }));
                    });

                }).catch(error => {
                    this.handleValidation(error);
                });
            });
        }

        if(!this.state.accessToken) {
            this.initBillingAddress();
        }
    }

    /**
     * Initialize a new instance of a payment method. Upon submission, perform a search for billing addresses available
     * to the user to populate the billing address field.
     *
     * @param paymentType - The type of the payment method (i.e. credit_card, bank_account, etc.)
     */
    createPaymentMethod(paymentType) {

        if(this.state.accessToken) {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/${paymentType}/new`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    paymentMethod: {
                        ...response.data,
                        userId: this.props.userId,
                        userType: this.props.userType
                    },
                    showCreditCardForm: paymentType === 'credit_card',
                    showBankAccountForm: paymentType === 'bank_account'
                }));

                this.searchBillingAddresses();

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        if(!this.state.accessToken) {
            this.setState(prevState => ({
                ...prevState,
                paymentMethod: {
                    ...prevState.paymentMethod,
                    type: 'TYPE_' + paymentType.toUpperCase()
                },
                showCreditCardForm: paymentType === 'credit_card',
                showBankAccountForm: paymentType === 'bank_account'
            }));
        }
    }

    /**
     * Initialize a new instance of a billing address, assigning it to the payment method at hand and revealing the
     * billing address fields.
     */
    initBillingAddress() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                showBillingAddressForm: true,
                billingAddress: {
                    ...response.data,
                    addressType: 'BILLING',
                    userType: this.props.userType,
                    userId: this.props.userId
                },
                paymentMethod: {
                    ...prevState.paymentMethod,
                    billingAddressId: response.data.id
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Search for all addresses of type 'BILLING' associated with the user (as long as there is an active user session).
     */
    searchBillingAddresses() {

        if(this.state.accessToken) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/address/search`, {
                orderBy: 'ASC',
                orderByFields: ['id'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'userId',
                        operator: 'EQUALS',
                        fieldValue: this.props.userId
                    }
                ]
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    billingAddressList: response.data.records,
                }));

                if(response.data.records.length === 0) {
                    this.initBillingAddress();
                }

            }).catch(error => {
                console.error(error);
            });
        }
    }

    /**
     * Fetch a list of all fee profiles related to the company at hand.
     */
    getFeeProfiles() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.property.companyId}/peek`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let company = response.data;

            axios.get(`${constants.REACT_APP_HOST_API_URL}/fee_profile?companyId=${this.state.property.companyId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    feeProfileList: response.data,
                    feeProfileCountry: company.country
                }));

                $("#fees").modal("show");

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the selected payment schedule.
     *
     * @param event - The event container.
     */
    handleChangePaymentSchedule(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    }

    /**
     * Handle changes to the billing address field. If the 'Add new billing address...' option is selected, reveal the
     * billing address creation form.
     *
     * @param event - The event container.
     */
    handleChangeBillingAddress(event) {

        if(event.target.value !== 'NEW') {

            this.setState(({
                paymentMethod: {
                    ...this.state.paymentMethod,
                    billingAddressId: event.target.value
                },
            }));

        } else {

            this.initBillingAddress();

        }
    }

    /**
     * Handle changes to the populate billing address checkbox, allowing the user to populate all the billing address
     * information automatically using the address of the property they are being invited to.
     *
     * @param event - The event container.
     */
    handleChangePopulateBillingAddress(event) {

        event.persist();

        let residentialAddress;
        let billingAddress;

        // Populate the billing address with the information in the customer's new property
        if(event.target.checked) {

            residentialAddress = this.state.property;
            billingAddress = this.state.billingAddress;

            billingAddress.city = residentialAddress.city;
            billingAddress.country = residentialAddress.country;
            billingAddress.postalCode = residentialAddress.postalCode;
            billingAddress.province = residentialAddress.province;
            billingAddress.street1 = residentialAddress.street1;
            billingAddress.street2 = residentialAddress.street2;
            billingAddress.suite = this.state.invitation.unit;
        }

        // Clear the billing address fields if the checkbox is unchecked
        if(!event.target.checked) {

            billingAddress = this.state.billingAddress;

            billingAddress.city = '';
            billingAddress.country = '';
            billingAddress.postalCode = '';
            billingAddress.province = '';
            billingAddress.street1 = '';
            billingAddress.street2 = '';
            billingAddress.suite = '';
        }

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.checked,
            billingAddress: billingAddress
        }));
    }

    /**
     * Handle changes to the selected payment method. Parses the value of the selected payment method as JSON. If the
     * user happens to select an existing payment method while creating a new payment method, hide the new payment
     * method creation and billing address creation forms accordingly.
     *
     * @param event - The event container.
     */
    handleChangePaymentMethod(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: JSON.parse(event.target.value),
        }));

        // If selecting an existing method while currently entering a new method, close all new method forms
        if(this.state.showCreditCardForm || this.state.showBankAccountForm) {
            this.setState(prevState => ({
                ...prevState,
                showCreditCardForm: false,
                showBankAccountForm: false,
                showBillingAddressForm: false
            }));
        }
    }

    /**
     * Handle changes to the selected method of authorization/logging in.
     *
     * @param event - The event container.
     */
    handleChangeAuthorizationType(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    }

    /**
     * Accept the invitation. Validate the payment method and billing address accordingly. Based on the authorization
     * type, call the 'accept' or 'acceptoauth' endpoints. After successfully accepting the invitation, authorize the
     * customer and redirect them to their payments dashboard.
     *
     * @param oAuthResponse
     */
    acceptInvitation(oAuthResponse) {

        let invitationAcceptEndpoint = 'accept';

        let requestData = {
            verificationCode: this.state.verificationCode
        };

        requestData.paymentMethod = this.state.paymentMethod;
        requestData.recurringStartDate = this.state.recurringStartDate;

        // Only supply a billing address if creating a new payment method
        if(!this.state.paymentMethod.createDate) {
            requestData.billingAddress = this.state.billingAddress;
        }

        // If the account is being set up with a password, include applicable fields
        if(this.state.authorizationType === 'PASSWORD') {
            requestData.phone = this.state.invitation.phone;
            requestData.birthDate = this.state.invitation.birthDate;
            requestData.password = this.state.password;
            requestData.confirmPassword = this.state.confirmPassword;
            invitationAcceptEndpoint = 'accept';
        }

        // If the account is being set up with Google OAuth, include the required OAuth information
        if(this.state.authorizationType === 'OAUTH') {
            requestData.oauthAccountType = 'TYPE_GOOGLE_OAUTH_ACCOUNT';
            requestData.authorizationCode = oAuthResponse.code;
            requestData.requestUri = window.location.origin;
            invitationAcceptEndpoint = 'acceptoauth';
        }

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/invitation/${this.props.match.params.invitationId}/${invitationAcceptEndpoint}`, requestData, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false
            }));

            // If no log in session is found, and a token was returned, log the user in automatically
            if(!this.state.accessToken && response.data.token_type) {

                localStorage.setItem('token_type', response.data.token_type);
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('expires_in', response.data.expires_in);

                this.props.refreshAppState();

                $("#invitation-complete").modal("show");

            }

            // If no log in session if found, and no token was returned, prompt the user to log in
            if(!this.state.accessToken && !response.data.token_type) {

                $("#invitation-login").modal("show");

            }

            // If a log in session is active, prompt the user to continue to their account
            if(this.state.accessToken) {

                $("#invitation-complete").modal("show");

            }

        }).catch(error => {

            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - A public-facing component that handles customers accepting invitations.
     */
    render() {

        const {formatMessage} = this.props.intl;

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        const ordinal = require('ordinal');

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <Banner backgroundImg="login" heading="static.invitation.heading" subheading="static.invitation.subheading.new" align="left" values={{firstName: this.state.invitation.firstName}} />

                <div className="container">

                    <div className="row">
                        <div className="col-md-8">

                            <Alert validationList={this.state.validationList} />

                            <div className="card">

                                <div className="card-header">
                                    Terms of Use
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">First, please review and accept the Letus Terms of Use.</p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveTermsAcknowledgement}>

                                        {this.state.termsAcknowledgementCompleted &&
                                        <div className="">

                                            <div className="list-group">
                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editTermsAcknowledgement()}>
                                                    <div className="">
                                                        Letus Terms of Use
                                                    </div>
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        I have read and agree to the Letus Terms of Use
                                                    </small>
                                                </div>
                                            </div>

                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editTermsAcknowledgement()}>
                                                Edit
                                            </div>

                                        </div>
                                        }

                                        {!this.state.termsAcknowledgementCompleted &&
                                        <React.Fragment>

                                            <div className="card-body border mb-3 bg-secondary rounded small" style={{height: "250px", overflowY: "scroll"}}>

                                                <TermsContents />

                                            </div>

                                            <FieldCheckbox id="termsAcknowledged" fieldLabel="I have read and agree to the Letus Terms of Use" fieldColumns="12" parent={this} model="invitation" value={this.state.invitation['termsAcknowledged']} />

                                            {this.state.invitation.termsAcknowledged &&
                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>
                                            }

                                            {!this.state.invitation.termsAcknowledged &&
                                            <div className="btn btn-primary btn-md btn-block mt-3 disabled" data-toggle="tooltip" data-placement="top" title="In order to continue with your account setup, you must indicate that you have read and agree to the Letus terms of use above.">
                                                Save & Continue
                                            </div>
                                            }

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>
                            </div>

                            {this.state.termsAcknowledgementCompleted &&
                            <div className="card">

                                <div className="card-header">
                                    Tenant
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">Who is accepting this invitation?</p>
                                </div>

                                <div className="card-body">

                                    {this.state.customerCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editCustomer()}>
                                                <div className="">
                                                    {this.state.invitation.firstName} {this.state.invitation.lastName}
                                                </div>
                                                <small className="mb-0 ml-md-0 small text-muted">
                                                    {this.state.invitation.email}
                                                </small>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editCustomer()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {!this.state.customerCompleted &&
                                    <form onSubmit={this.saveCustomer} autoComplete="off">

                                        <FieldText id="firstName" label="First Name" model="invitation" parent={this} required={true} disabled={true} value={this.state.invitation['firstName']} />

                                        <FieldText id="lastName" label="Last Name" model="invitation" parent={this} required={true} disabled={true} value={this.state.invitation['lastName']} />

                                        <FieldText id="email" label="Email" model="invitation" type="email" parent={this} disabled={true} required={true} value={this.state.invitation['email']} />

                                        {!this.state.invitation.customerId &&
                                        <FieldPhone id="phone" label="Phone" model="invitation" parent={this} value={this.state.invitation['phone']}/>
                                        }

                                        {!this.state.invitation.customerId &&
                                        <FieldBirthDate id="birthDate" label="Date of Birth" model="invitation" parent={this} disabled={this.state.invitation.applicationId || this.state.invitation.customerId} required={true} value={this.state.invitation['birthDate']}/>
                                        }

                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </form>
                                    }

                                </div>

                            </div>
                            }

                            {this.state.customerCompleted &&
                            <div className="card">

                                <div className="card-header">
                                    Payment Schedule
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">How would you like to make your payments?</p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.savePaymentSchedule} autoComplete="off">

                                        {this.state.paymentScheduleCompleted &&
                                        <div className="">

                                            <div className="list-group">
                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editPaymentSchedule()}>

                                                    {this.state.paymentSchedule === 'TRANSACTION' &&
                                                    <React.Fragment>
                                                        <div className="">
                                                            One-Time Payments
                                                        </div>
                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            Manually log in and make one-time payments every month
                                                        </small>
                                                    </React.Fragment>
                                                    }

                                                    {this.state.paymentSchedule === 'RECURRING' &&
                                                    <React.Fragment>
                                                        <div className="">
                                                            Auto Payments
                                                        </div>
                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            Schedule my payments every month starting on <Moment format="MMMM DD, YYYY" tz="UTC">{this.state.recurringStartDate}</Moment>
                                                        </small>
                                                    </React.Fragment>
                                                    }

                                                </div>
                                            </div>

                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editPaymentSchedule()}>
                                                Edit
                                            </div>

                                        </div>
                                        }

                                        {!this.state.paymentScheduleCompleted &&
                                        <React.Fragment>

                                            <div className="list-group mb-2" data-toggle={this.state.invitation.requireAutoPay ? 'tooltip' : ''} data-placement="top" title={this.state.invitation.requireAutoPay ? 'Your landlord or property manager has requested that you schedule automatic monthly payments for this lease' : ''}>
                                                <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                    <input type="radio" name="paymentSchedule" value="TRANSACTION" id="dateTransaction" disabled={this.state.invitation.requireAutoPay} defaultChecked={this.state.paymentSchedule === 'TRANSACTION' || false} onChange={this.handleChangePaymentSchedule} className="custom-control-input" />
                                                    <label className="custom-control-label pl-3 c-pointer" htmlFor="dateTransaction">
                                                        <div className="">
                                                            One-Time Payments
                                                        </div>
                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            Manually log in and make one-time payments every month
                                                        </small>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="list-group mb-2">
                                                <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                    <input type="radio" name="paymentSchedule" value="RECURRING" id="dateRecurring" defaultChecked={this.state.paymentSchedule === 'RECURRING' || false} onChange={this.handleChangePaymentSchedule} className="custom-control-input"/>
                                                    <label className="custom-control-label pl-3 c-pointer" htmlFor="dateRecurring">

                                                        <div className="">
                                                            Auto Payments
                                                        </div>
                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            Automatically pay for your charges every month
                                                        </small>

                                                        {this.state.paymentSchedule === 'RECURRING' &&
                                                        <div className="mt-2">
                                                            <FieldDate id="recurringStartDate" filterDateType={this.state.invitation.monthlyPaymentDueDay > 0 ? 'MONTHLY_PAYMENT_DUE_DAY' : ''} monthlyPaymentDueDay={this.state.invitation.monthlyPaymentDueDay} label="Monthly Payment Start Date" labelColumns="12" fieldColumns="12" fieldClass="form-control-md mb-0" minDate={this.addDays(new Date(), 1)} help="You will be charged every month on your selected date. Please ensure you have sufficient funds in your account on the payment date so your payment can successfully process." parent={this} value={this.state['recurringStartDate']}>
                                                                {this.state.invitation.monthlyPaymentDueDay > 0 &&
                                                                <div className="text-center font-weight-bold py-2">
                                                                    Payment is due by<br />the {ordinal(this.state.invitation.monthlyPaymentDueDay)} of each month
                                                                </div>
                                                                }
                                                            </FieldDate>
                                                        </div>
                                                        }

                                                    </label>
                                                </div>
                                            </div>

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>
                            </div>
                            }

                            {this.state.paymentScheduleCompleted &&
                            <div className="card">

                                <div className="card-header">
                                    Payment Method
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">What payment method would you like to use?</p>
                                </div>

                                <div className="card-body">

                                    {this.state.paymentMethodCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editPaymentMethod()}>
                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        <div className="">
                                                            {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                                            <React.Fragment>
                                                                Bank Account
                                                            </React.Fragment>
                                                            }
                                                            {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                                            <React.Fragment>
                                                                Credit Card
                                                            </React.Fragment>
                                                            }
                                                        </div>
                                                        {this.state.paymentMethod.last4 &&
                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                                            <React.Fragment>
                                                                Account number ending in {this.state.paymentMethod.last4}
                                                            </React.Fragment>
                                                            }
                                                            {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                                            <React.Fragment>
                                                                Card number ending in {this.state.paymentMethod.last4}
                                                            </React.Fragment>
                                                            }
                                                        </small>
                                                        }
                                                        {!this.state.paymentMethod.last4 &&
                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                                            <React.Fragment>
                                                                Account number ending in {this.state.paymentMethod.bankAccountNumber ? this.state.paymentMethod.bankAccountNumber.slice(-4) : ''}
                                                            </React.Fragment>
                                                            }
                                                            {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                                            <React.Fragment>
                                                                Card number ending in {this.state.paymentMethod.cardNumber ? this.state.paymentMethod.cardNumber.slice(-4) : ''}
                                                            </React.Fragment>
                                                            }
                                                        </small>
                                                        }
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="float-right mr-2">
                                                            <CardBrandIcon paymentMethodType={this.state.paymentMethod.type} brand={this.state.paymentMethod.brand} customClasses="w-75" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editPaymentMethod()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {!this.state.paymentMethodCompleted &&
                                    <React.Fragment>

                                        <form onSubmit={this.savePaymentMethod}>

                                            {this.state.paymentMethodList.map((data, key) => {

                                                if(data.type !== 'TYPE_CASH') {
                                                    return (
                                                        <div key={key} className="list-group mb-2">
                                                            <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                                <input type="radio" name="paymentMethod" value={JSON.stringify(data)} id={key} checked={this.state.paymentMethod.id === data.id || false} onChange={this.handleChangePaymentMethod} className="custom-control-input"/>
                                                                <label className="custom-control-label pl-3 c-pointer" htmlFor={key}>
                                                                    <div className="row align-items-center">
                                                                        <div className="col-8">
                                                                            <div className="">
                                                                                {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                                <React.Fragment>
                                                                                    Bank Account
                                                                                </React.Fragment>
                                                                                }
                                                                                {data.type === 'TYPE_CREDIT_CARD' &&
                                                                                <React.Fragment>
                                                                                    <FormattedMessage id={"enum.creditCard.brand." + data.brand}/>
                                                                                </React.Fragment>
                                                                                }
                                                                            </div>
                                                                            <small className="mb-0 ml-md-0 small text-muted">
                                                                                {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                                <React.Fragment>
                                                                                    Account number ending in {data.last4}
                                                                                </React.Fragment>
                                                                                }
                                                                                {data.type === 'TYPE_CREDIT_CARD' &&
                                                                                <React.Fragment>
                                                                                    Card number ending in {data.last4}
                                                                                </React.Fragment>
                                                                                }
                                                                            </small>
                                                                        </div>
                                                                        <div className="col text-right">
                                                                            <div className="float-right mr-2">
                                                                                <CardBrandIcon paymentMethodType={data.type} brand={data.brand} customClasses="w-75"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    );
                                                }

                                                return null;

                                            })}

                                            {!this.state.showCreditCardForm &&
                                            <div className="list-group mb-2">
                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.createPaymentMethod('credit_card')}>
                                                    <div className="row align-items-center">
                                                        <div className="col-8">
                                                            Add New Credit or Debit Card
                                                        </div>
                                                        <div className="col text-right">
                                                            <div className="float-right">
                                                                <img src={creditCard} className="rounded border w-75" alt="Add New Credit or Debit Card"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }

                                            {this.state.showCreditCardForm &&
                                            <div className="list-group mb-2">
                                                <div className="list-group-item pb-3">

                                                    <div className="row align-items-center mb-3">
                                                        <div className="col-8">
                                                            Add New Credit or Debit Card
                                                        </div>
                                                        <div className="col text-right">
                                                            <div className="float-right">
                                                                <img src={creditCard} className="rounded border w-75" alt="Add New Credit or Debit Card" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <CardPreview paymentMethod={this.state.paymentMethod} cardPreviewFlipped={this.state.cardPreviewFlipped} activePaymentMethodField={this.state.activePaymentMethodField} />

                                                    <FieldCardNumber id="cardNumber" label="Card Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod.cardNumber} brand={this.state.paymentMethod.brand} handleFocus={() => this.handleFocusPaymentMethodField('cardNumber')} handleBlur={() => this.handleBlurPaymentMethodField('cardNumber')} />

                                                    <FieldText id="nameOnCard" label="Card Holder Name" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['nameOnCard']} handleFocus={() => this.handleFocusPaymentMethodField('nameOnCard')} handleBlur={() => this.handleBlurPaymentMethodField('nameOnCard')} />

                                                    <div className="row mb-3">

                                                        <div className="col">
                                                            <FieldSelect id="expiryMonth" label="Month" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['expiryMonth']} handleFocus={() => this.handleFocusPaymentMethodField('expiryMonth')} handleBlur={() => this.handleBlurPaymentMethodField('expiryMonth')}>
                                                                <option value="">-</option>
                                                                <option value="1">01 - Jan</option>
                                                                <option value="2">02 - Feb</option>
                                                                <option value="3">03 - Mar</option>
                                                                <option value="4">04 - Apr</option>
                                                                <option value="5">05 - May</option>
                                                                <option value="6">06 - Jun</option>
                                                                <option value="7">07 - Jul</option>
                                                                <option value="8">08 - Aug</option>
                                                                <option value="9">09 - Sep</option>
                                                                <option value="10">10 - Oct</option>
                                                                <option value="11">11 - Nov</option>
                                                                <option value="12">12 - Dec</option>
                                                            </FieldSelect>
                                                        </div>

                                                        <div className="col">
                                                            <FieldSelect id="expiryYear" label="Year" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['expiryYear']} handleFocus={() => this.handleFocusPaymentMethodField('expiryYear')} handleBlur={() => this.handleBlurPaymentMethodField('expiryYear')}>
                                                                <option value="">-</option>
                                                                <option value="2020">2020</option>
                                                                <option value="2021">2021</option>
                                                                <option value="2022">2022</option>
                                                                <option value="2023">2023</option>
                                                                <option value="2024">2024</option>
                                                                <option value="2025">2025</option>
                                                                <option value="2026">2026</option>
                                                                <option value="2027">2027</option>
                                                                <option value="2028">2028</option>
                                                                <option value="2029">2029</option>
                                                                <option value="2030">2030</option>
                                                                <option value="2031">2031</option>
                                                                <option value="2032">2032</option>
                                                                <option value="2033">2033</option>
                                                            </FieldSelect>
                                                        </div>

                                                        <div className="col">
                                                            <FieldText id="securityCode" label={this.state.creditCardSecurityCodeLabel} required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod.securityCode} handleFocus={() => this.handleFocusPaymentMethodField('securityCode')} handleBlur={() => this.handleBlurPaymentMethodField('securityCode')} />
                                                        </div>

                                                    </div>

                                                    {!this.state.showBillingAddressForm &&
                                                    <FieldSelect id="billingAddressId" required={true} label="Billing Address" model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['billingAddressId']} handleChange={this.handleChangeBillingAddress}>

                                                        <option value="">Select from your billing addresses...</option>

                                                        {this.state.billingAddressList.map((data, key) => {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.suite ? data.suite + ' - ' : ''}{data.street1}{data.street2 ? ', ' + data.street2 : ''}, {data.city}, {data.country === 'CA' ? formatMessage({id: "province." + data.province}) : formatMessage({id: "state." + data.province})}, {formatMessage({id: "country." + data.country})} {data.postalCode}
                                                                </option>
                                                            );
                                                        })}

                                                        <option value="NEW">Add new billing address...</option>

                                                    </FieldSelect>
                                                    }

                                                    {this.state.showBillingAddressForm &&
                                                    <div className="mb-2">

                                                        <div className="mt-4 mb-3">
                                                            <FieldCheckbox id="populateBillingAddressForm" fieldLabel="Billing address same as property lease" fieldClass="form-control-sm" fieldColumns="12" labelClass="col-form-label-sm px-2" parent={this} value={this.state.populateBillingAddressForm} handleChange={this.handleChangePopulateBillingAddress} />
                                                        </div>

                                                        <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} parent={this} value={this.state.billingAddress} />

                                                    </div>
                                                    }

                                                </div>
                                            </div>
                                            }

                                            {!this.state.showBankAccountForm &&
                                            <div className="list-group mb-2">
                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.createPaymentMethod('bank_account')}>
                                                    <div className="row align-items-center">
                                                        <div className="col-8">
                                                            Add New Bank Account
                                                        </div>
                                                        <div className="col text-right">
                                                            <div className="float-right">
                                                                <img src={bankAccount} className="rounded border w-75" alt="Add New Bank Account"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }

                                            {this.state.showBankAccountForm &&
                                            <div className="list-group mb-2">
                                                <div className="list-group-item pb-3">

                                                    <div className="row align-items-center mb-3">
                                                        <div className="col-8">
                                                            Add New Bank Account
                                                        </div>
                                                        <div className="col text-right">
                                                            <div className="float-right">
                                                                <img src={bankAccount} className="rounded border w-75" alt="Add New Bank Account" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <FieldCountry id="country" label="Bank Country" required={true} model="paymentMethod" labelColumns="12" fieldColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['country']} />

                                                    {this.state.paymentMethod['country'] &&
                                                    <React.Fragment>

                                                        {this.state.paymentMethod['country'] === 'CA' &&
                                                        <React.Fragment>

                                                            <FieldText id="bankAccountInstitutionNumber"
                                                                       label="Institution Number"
                                                                       help={
                                                                           <div data-toggle="popover"
                                                                                data-trigger="focus"
                                                                                tabIndex="0"
                                                                                data-html="true"
                                                                                title="Institution Number"
                                                                                className="btn-link d-inline c-pointer"
                                                                                data-content="The institution number is the 3-digit number that identifies your financial institution. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/CAD/en/institution-number.png' class='img-fluid mt-2' />">
                                                                               What is this?
                                                                           </div>
                                                                       }
                                                                       labelColumns="12"
                                                                       fieldColumns="12"
                                                                       fieldClass="form-control-md mb-0"
                                                                       type="tel"
                                                                       parent={this}
                                                                       required={true}
                                                                       value={this.state['bankAccountInstitutionNumber']}
                                                            />

                                                            <FieldText id="bankAccountTransitNumber"
                                                                       label="Transit Number"
                                                                       labelColumns="12"
                                                                       fieldColumns="12"
                                                                       fieldClass="form-control-md mb-0"
                                                                       type="tel" help={
                                                                <div data-toggle="popover"
                                                                     data-trigger="focus"
                                                                     tabIndex="0"
                                                                     data-html="true"
                                                                     title="Transit Number"
                                                                     className="btn-link d-inline c-pointer"
                                                                     data-content="The transit number is the 5-digit number that identifies your specific bank branch. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/CAD/en/transit-number.png' class='img-fluid mt-2' />">
                                                                    What is this?
                                                                </div>
                                                            }
                                                                       parent={this}
                                                                       required={true}
                                                                       value={this.state['bankAccountTransitNumber']}
                                                            />

                                                        </React.Fragment>
                                                        }

                                                        {this.state.paymentMethod['country'] === 'US' &&
                                                        <React.Fragment>

                                                            <FieldText id="routingNumber"
                                                                       label="Routing Number"
                                                                       labelColumns="12"
                                                                       fieldColumns="12"
                                                                       fieldClass="form-control-md mb-0"
                                                                       type="tel" help={
                                                                <div data-toggle="popover"
                                                                     data-trigger="focus"
                                                                     tabIndex="0"
                                                                     data-html="true"
                                                                     title="Routing Number"
                                                                     className="btn-link d-inline c-pointer"
                                                                     data-content="The routing number is a 9-digit or 8-digit code that is used to identify where your bank account was opened. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/USD/en/routing-number.png' class='img-fluid mt-2' />">
                                                                    What is this?
                                                                </div>
                                                            }
                                                                       parent={this}
                                                                       required={true}
                                                                       value={this.state.paymentMethod['routingNumber']}
                                                            />

                                                        </React.Fragment>
                                                        }

                                                        <FieldText id="bankAccountNumber"
                                                                   label="Account Number"
                                                                   model="paymentMethod"
                                                                   labelColumns="12"
                                                                   fieldColumns="12"
                                                                   fieldClass="form-control-md mb-0"
                                                                   type="tel"
                                                                   help={
                                                                       <div data-toggle="popover"
                                                                            data-trigger="focus"
                                                                            tabIndex="0"
                                                                            data-html="true"
                                                                            title="Account Number"
                                                                            className="btn-link d-inline c-pointer"
                                                                            data-content="The bank account number is the 7 to 12-digit number that is specific to your personal account. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/CAD/en/account-number.png' class='img-fluid mt-2' />">
                                                                           What is this?
                                                                       </div>
                                                                   }
                                                                   parent={this}
                                                                   required={true}
                                                                   value={this.state.paymentMethod['bankAccountNumber']}
                                                        />

                                                        <FieldText id="accountHolder" label="Account Holder" required={true} model="paymentMethod" labelColumns="12" fieldColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['accountHolder']} />

                                                        {!this.state.showBillingAddressForm &&
                                                        <FieldSelect id="billingAddressId" required={true} label="Billing Address" model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['billingAddressId']} handleChange={this.handleChangeBillingAddress}>

                                                            <option value="">Select from your billing addresses...</option>

                                                            {this.state.billingAddressList.map((data, key) => {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.suite ? data.suite + ' - ' : ''}{data.street1}{data.street2 ? ', ' + data.street2 : ''}, {data.city}, {data.country === 'CA' ? formatMessage({id: "province." + data.province}) : formatMessage({id: "state." + data.province})}, {formatMessage({id: "country." + data.country})} {data.postalCode}
                                                                    </option>
                                                                );
                                                            })}

                                                            <option value="NEW">Add new billing address...</option>

                                                        </FieldSelect>
                                                        }

                                                        {this.state.showBillingAddressForm &&
                                                        <div className="mb-2">

                                                            <div className="mt-4 mb-3">
                                                                <FieldCheckbox id="populateBillingAddressForm" fieldLabel="Billing address same as property lease" fieldClass="form-control-sm" fieldColumns="12" labelClass="col-form-label-sm px-2" parent={this} value={this.state.populateBillingAddressForm} handleChange={this.handleChangePopulateBillingAddress} />
                                                            </div>

                                                            <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} parent={this} value={this.state.billingAddress} />

                                                        </div>
                                                        }

                                                    </React.Fragment>
                                                    }

                                                </div>
                                            </div>
                                            }

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>

                                        </form>

                                    </React.Fragment>
                                    }

                                </div>

                                <div className="card-footer">
                                    <p className="small text-muted mb-0">
                                        Transactions made through Letus are subject to transaction fees, dependant upon your chosen method of payment. To view a list of our transaction fees, <span className="btn-link c-pointer" data-toggle="modal" data-target="#fees" onClick={() => this.getFeeProfiles()}>click here</span>.
                                    </p>
                                </div>

                            </div>
                            }

                            {(this.state.paymentMethodCompleted && !this.state.invitation.customerId) &&
                            <div className="card">

                                <div className="card-header">
                                    Your Account
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">How would you like to log in to your Letus account?</p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveAccountCredentials}>

                                        {this.state.accountCredentialsCompleted &&
                                            <div className="">

                                                <div className="list-group">
                                                    <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editAccountCredentials()}>
                                                        {this.state.authorizationType === 'PASSWORD' &&
                                                        <React.Fragment>
                                                            <div className="">
                                                                Password
                                                            </div>
                                                            <small className="mb-0 ml-md-0 small text-muted">
                                                                Use my own secure Letus account password
                                                            </small>
                                                        </React.Fragment>
                                                        }
                                                        {this.state.authorizationType === 'OAUTH' &&
                                                        <React.Fragment>
                                                            <div className="">
                                                                Google
                                                            </div>
                                                            <small className="mb-0 ml-md-0 small text-muted">
                                                                Log in to my Letus account instantly using my Google account
                                                            </small>
                                                        </React.Fragment>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editAccountCredentials()}>
                                                    Edit
                                                </div>

                                            </div>
                                        }

                                        {!this.state.accountCredentialsCompleted &&
                                        <React.Fragment>

                                            <div className="list-group mb-2">
                                                <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                    <input type="radio" name="authorizationType" value="PASSWORD" id="authorizationPassword" defaultChecked={this.state.authorizationType === 'PASSWORD' || false} onChange={this.handleChangeAuthorizationType} className="custom-control-input" />
                                                    <label className="custom-control-label pl-3 c-pointer" htmlFor="authorizationPassword">

                                                        <div className="">
                                                            Password
                                                        </div>

                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            Set up my own secure Letus account password
                                                        </small>

                                                        {this.state.authorizationType === 'PASSWORD' &&
                                                        <div className="mt-3">

                                                            <FieldPassword id="password" label="Password" required={true} labelColumns="0" labelClass="d-none mb-0" placeholder="Password" fieldColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state['password']} dataHtml={true} />

                                                            <FieldText id="confirmPassword" label="Confirm Password" required={true} type="password" labelColumns="0" labelClass="d-none mb-0" placeholder="Confirm Password" fieldColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state['confirmPassword']} />

                                                        </div>
                                                        }

                                                    </label>
                                                </div>
                                            </div>

                                            <div className="list-group mb-2">
                                                <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                    <input type="radio" name="authorizationType" value="OAUTH" id="authorizationOAuth" defaultChecked={this.state.authorizationType === 'OAUTH' || false} onChange={this.handleChangeAuthorizationType} className="custom-control-input" />
                                                    <label className="custom-control-label pl-3 c-pointer" htmlFor="authorizationOAuth">

                                                        <div className="">
                                                            Google
                                                        </div>

                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            Log in to your Letus account instantly by linking your Google account
                                                        </small>

                                                    </label>
                                                </div>
                                            </div>

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>
                            </div>
                            }

                        </div>
                        <div className="col-md-4">
                            <div className="sticky-top">

                                <div className="card">

                                    <div className="card-header">
                                        Invitation Summary
                                    </div>

                                    <div className="card-body card-body-table">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>
                                                    Monthly Charges
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>
                                    </div>

                                    <div className="card-body card-body-list">
                                        {this.state.invitation.charges &&
                                        <ul className="list-group">

                                            {this.state.invitation.charges.map((data, key) => {
                                                return (
                                                    <li key={key} className="list-group-item">
                                                        <div className="float-left small">
                                                            <FormattedMessage id={`charge.${data.code}`} defaultMessage={data.name ? data.name : data.code} />
                                                        </div>
                                                        <div className="float-right small">
                                                            <FormattedNumber value={data.amount} style={`currency`} currency={`USD`} />
                                                        </div>
                                                    </li>
                                                );
                                            })}

                                            {this.state.invitation.monthlyPaymentDueDay !== 0 &&
                                            <li className="list-group-item">
                                                <div className="float-left small">
                                                    Payment Due
                                                </div>
                                                <div className="float-right small">
                                                    Monthly on the {ordinal(this.state.invitation.monthlyPaymentDueDay)}
                                                </div>
                                            </li>
                                            }

                                            <li className="list-group-item">
                                                <div className="float-left small">
                                                    Start Date
                                                </div>
                                                <div className="float-right small">
                                                    <Moment format="MMM DD, YYYY" tz="UTC">
                                                        {this.state.invitation.startDate}
                                                    </Moment>
                                                </div>
                                            </li>

                                        </ul>
                                        }
                                    </div>

                                    <div className="card-body card-body-table">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>
                                                    Property
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>
                                    </div>

                                    <div className="card-body card-body-list">
                                        <ul className="list-group">

                                            <li className="list-group-item">
                                                <div className="float-left small">
                                                    Unit
                                                </div>
                                                <div className="float-right small">
                                                    {this.state.invitation.unit}
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="float-left small">
                                                    Street
                                                </div>
                                                <div className="float-right small">
                                                    {this.state.property.street1}
                                                </div>
                                            </li>

                                            {this.state.property.street2 &&
                                            <li className="list-group-item">
                                                <div className="float-left small">
                                                    Street 2
                                                </div>
                                                <div className="float-right small">
                                                    {this.state.property.street2}
                                                </div>
                                            </li>
                                            }

                                            {this.state.property.city &&
                                            <li className="list-group-item">
                                                <div className="float-left small">
                                                    City
                                                </div>
                                                <div className="float-right small">
                                                    {this.state.property.city}
                                                </div>
                                            </li>
                                            }

                                            {(this.state.property.country && this.state.property.country === 'CA') &&
                                            <li className="list-group-item">
                                                <div className="float-left small">
                                                    Province
                                                </div>
                                                <div className="float-right small">
                                                    {formatMessage({id: "province." + this.state.property.province})}
                                                </div>
                                            </li>
                                            }

                                            {(this.state.property.country && this.state.property.country === 'US') &&
                                            <li className="list-group-item">
                                                <div className="float-left small">
                                                    State
                                                </div>
                                                <div className="float-right small">
                                                    {formatMessage({id: "state." + this.state.property.province})}
                                                </div>
                                            </li>
                                            }

                                            {this.state.property.country &&
                                            <li className="list-group-item">
                                                <div className="float-left small">
                                                    Country
                                                </div>
                                                <div className="float-right small">
                                                    {formatMessage({id: "country." + this.state.property.country})}
                                                </div>
                                            </li>
                                            }

                                            <li className="list-group-item">
                                                <div className="float-left small">
                                                    ZIP/Postal Code
                                                </div>
                                                <div className="float-right small">
                                                    {this.state.property.postalCode}
                                                </div>
                                            </li>

                                        </ul>
                                    </div>

                                    <div className="card-footer">
                                        <p className="small text-muted mb-0">
                                            If the above information is inaccurate, contact your property manager directly.
                                        </p>
                                    </div>

                                </div>

                                {(this.state.termsAcknowledgementCompleted && this.state.paymentMethodCompleted && this.state.accountCredentialsCompleted) &&
                                <React.Fragment>

                                    {(this.state.authorizationType === 'PASSWORD' || this.state.invitation.customerId) &&
                                    <div className="btn btn-primary btn-lg btn-block py-3 mb-4" onClick={() => this.acceptInvitation()}>
                                        <FontAwesomeIcon icon={['far', 'check-circle']} className="fa-fw" /> Accept Invitation
                                    </div>
                                    }

                                    {this.state.authorizationType === 'OAUTH' &&
                                    <div className="">
                                        <GoogleLogin
                                            clientId={constants.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                                            render={renderProps => (
                                                <div className="btn btn-primary btn-lg btn-block py-3 mb-4" onClick={renderProps.onClick}>
                                                    <FontAwesomeIcon icon={['far', 'check-circle']} className="fa-fw" /> Accept Invitation
                                                </div>
                                            )}
                                            onSuccess={this.acceptInvitation}
                                            onFailure={this.acceptInvitation}
                                            responseType="code"
                                        />
                                    </div>
                                    }

                                </React.Fragment>
                                }

                            </div>

                        </div>
                    </div>

                </div>

                <FeeProfiles feeProfileList={this.state.feeProfileList} feeProfileCountry={this.state.feeProfileCountry} />

                <ModalUnionPay parent={this} />

                <Modal id="invitation-complete" theme="primary" iconType="far" iconName="thumbs-up" title={`Welcome to Letus, ${this.state.invitation.firstName}!`}
                       body="Your Letus account is now complete. Say goodbye to rent checks for good! Click the button below to continue to your Letus account.">
                    <Link to="/customer/payments" className="btn btn-primary btn-lg" onClick={() => $("#invitation-complete").modal("hide")}>
                        Continue
                    </Link>
                </Modal>

                <Modal id="invitation-login" theme="primary" iconType="far" iconName="thumbs-up" title={`Welcome to Letus, ${this.state.invitation.firstName}!`}
                       body="Your Letus account is now complete. Say goodbye to rent checks for good! Click the button below to log in and be taken to your Letus account.">
                    <Link to="/login" className="btn btn-primary btn-lg" onClick={() => $("#invitation-login").modal("hide")}>
                        Log In
                    </Link>
                </Modal>

            </div>
        )
    };
}

Customer.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Customer);