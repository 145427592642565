import React from 'react';
import {injectIntl, intlShape} from 'react-intl';
import Letus from "./common/Propertii";
import familyMoving from "../media/img/backgrounds/couple-moving.jpg";
import rentmoolaLogo from "../media/img/logos/rentmoola-w.png";

class Privacy extends Letus {

    /**
     * Render the component.
     *
     * @returns {*} - A public-facing static component that displays the privacy policy page.
     */
    render() {

        return(
            <div className="content-block">

                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${familyMoving}') no-repeat center center scroll`}}>
                    <div className="container text-md-center">
                        <h1 className="display-5 pt-3 text-white">
                            Landlord Pricing
                        </h1>
                        <p className="lead text-white">
                            What does it cost to use Letus?
                        </p>
                    </div>
                </div>

                <div className="container">

                    <div className="card-group my-5">
                        <div className="card">
                            <div className="card-header text-center">
                                <h5 className="card-title mb-0">
                                    <img src={rentmoolaLogo} alt="Letus" height="50" className="m-2" />
                                </h5>
                            </div>
                            <div className="card-body text-center bg-secondary">
                                <p className="display-3 mb-0">$0.50</p>
                                <p className="font-weight-bold small mb-0">starting at per unit, per month</p>
                                <p className="small mb-0">* not including other fees</p>
                            </div>
                            <div className="card-body card-body-list text-center">
                                <ul className="list-group">
                                    <li className="list-group-item">
                                        1 landlord user account
                                    </li>
                                    <li className="list-group-item">
                                        Online lease applications
                                    </li>
                                    <li className="list-group-item">
                                        Tenant background & credit checks <div className="small text-muted">$10 per background check, $15 per credit check</div>
                                    </li>
                                    <li className="list-group-item">
                                        Online rent payments for tenants <div className="small text-muted">free bank account payments; transaction fee for credit & debit cards</div>
                                    </li>
                                    <li className="list-group-item">
                                        Automatic monthly payments
                                    </li>
                                    <li className="list-group-item">
                                        Property management platform
                                    </li>
                                    <li className="list-group-item">
                                        Online customer support
                                    </li>
                                </ul>
                            </div>
                            <div className="card-footer text-center border-top">
                                <small className="text-muted">
                                    <a href="#signup" className="btn btn-primary btn-lg my-3">
                                        Create an Account
                                    </a>
                                </small>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header text-center">
                                <h5 className="card-title mb-0">
                                    <img src={rentmoolaLogo} alt="Letus" height="50" className="m-2" />
                                </h5>
                            </div>
                            <div className="card-body bg-secondary text-center">
                                <p className="lead mb-0 pt-2">Additional features included:</p>
                            </div>
                            <div className="card-body card-body-list text-center">
                                <ul className="list-group">
                                    <li className="list-group-item">
                                        Custom pricing packages
                                    </li>
                                    <li className="list-group-item">
                                        Multiple property manager user accounts
                                    </li>
                                    <li className="list-group-item">
                                        Multiple bank accounts
                                    </li>
                                    <li className="list-group-item">
                                        Accounting software integrations
                                    </li>
                                    <li className="list-group-item">
                                        Reporting tools
                                    </li>
                                    <li className="list-group-item">
                                        Pre-authorized debit (PAD) payments
                                    </li>
                                    <li className="list-group-item">
                                        Dedicated account manager
                                    </li>
                                    <li className="list-group-item">
                                        Co-branded digital marketing packages <div className="small text-muted">including a custom co-branded landing page</div>
                                    </li>
                                    <li className="list-group-item">
                                        Online and phone customer support
                                    </li>
                                </ul>
                            </div>
                            <div className="card-footer text-center border-top">
                        <a href="mailto:yourlandlord@yoursite.com?subject=I am interested in paying my rent with Propertii&body=Propertii gives property managers the tools they need to create a better rental experience. Check it out at https://let.us!" className="btn btn-lg btn-primary mt-3 mb-4 px-4 text-white">
                            Invite my Landlord
                        </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

Privacy.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Privacy);