import React from 'react';
import Propertii from "../common/Propertii";
import {Link} from "react-router-dom";
import familyMoving from "../../media/img/backgrounds/couple-laptop.jpg";

class Submitted extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            leaseApplication: {},
            leaseApplicationPage: {},
            company: {},
            validationList: [],
        };
    }

    /**
     * On mounting of the component, fetch the lease application, lease application page, and company data passed in
     * through the submission of the lease application form.
     */
    componentDidMount() {

        if(this.props.location && this.props.location.state) {
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: this.props.location.state.leaseApplication,
                leaseApplicationPage: this.props.location.state.leaseApplicationPage,
                company: this.props.location.state.company
            }));
        }
    }

    /**
     * Render the component.
     *
     * @returns {*} - A splash screen informing the user that their application has been submitted and it is now under
     * review by the landlord or property manager.
     */
    render() {

        return(
            <div className="content-block">

                <div className="content-header mb-0">
                    <div className="container">

                        <div className="row no-gutters">
                            <div className="col-md-6 col-12 align-self-center pr-md-5">
                                <div className="jumbotron jumbotron-fluid bg-transparent mb-0">

                                    <h1 className="display-5 mb-2">
                                        Thanks, {this.state.leaseApplication.firstName}!
                                    </h1>

                                    <h3 className="mb-4">
                                        Your application is under review
                                    </h3>

                                    <div className="">
                                        <p className="mb-4">
                                            Your lease application has been submitted to {this.state.company.name}. Upon successful review, you will receive an email with instructions for creating your Letus account.
                                        </p>
                                    </div>

                                    {this.state.leaseApplicationPage &&
                                    <Link to={`/apply/${this.state.leaseApplicationPage.pageName}`} className="btn btn-lg btn-primary btn-block">
                                        OK
                                    </Link>
                                    }

                                    {!this.state.leaseApplicationPage &&
                                    <Link to="/" className="btn btn-lg btn-primary btn-block">
                                        OK
                                    </Link>
                                    }

                                </div>
                            </div>
                            <div className="col-md-6 d-none d-md-block align-self-center">

                                <div style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${(this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields) ? this.state.leaseApplicationPage.customFields.background : familyMoving}') no-repeat center center scroll`, backgroundSize: "cover", height: "675px", width: "998px"}}>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        );
    }

}

export default Submitted;
