import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import TenantList from "../../common/Tenants";

class Tenants extends Propertii {

    /**
     * Fetch the company based on the company ID provided in the route on mounting of the component.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.match.params.companyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                company: response.data
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view tenants list for companies.
     */
    render() {

        return(
            <React.Fragment>

                {this.state.company &&
                <TenantList userType={this.props.userSession.sessionRole.type}
                            userId={this.props.userSession.sessionRole.id}
                            companyId={this.state.company.id}
                            inviteTenant={false}
                            createTenant={false}
                            parent={this}
                            history={this.props.history} />
                }

            </React.Fragment>
        )
    };
}

Tenants.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Tenants);