import React, { Component } from 'react';
import {Link} from "react-router-dom";

class Terms extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - The Letus terms of use contents.
     */
    render() {

        return(
            <React.Fragment>

                <p>Your use of the Services offered by PaySlate Inc. (referenced here as "<span className="font-weight-bold">Letus</span>", "<span className="font-weight-bold">us</span>", "<span className="font-weight-bold">we</span>" or "<span className="font-weight-bold">our</span>") is governed by this Agreement between you and Letus. PLEASE READ THIS AGREEMENT CAREFULLY. IT IS A LEGAL AND ENFORCEABLE CONTRACT BETWEEN YOU AND LETUS. IN ORDER TO USE THE SERVICES, YOU MUST READ, AGREE WITH AND ACCEPT ALL OF THE TERMS AND CONDITIONS CONTAINED IN THIS AGREEMENT. IN ADDITION, YOU MUST READ, AGREE WITH AND ACCEPT OUR PRIVACY POLICY, AVAILABLE AT: <Link to="/privacy">https://let.us/privacy</Link>.</p>
                <p>BY USING THE SERVICES, OR BY CLICKING THE "I ACCEPT" BUTTON, YOU: (1) AGREE TO BE BOUND BY THIS AGREEMENT, INCLUDING ALL AMENDMENTS MADE FROM TIME TO TIME; (2) REPRESENT AND WARRANT THAT, IF YOU ARE AN INDIVIDUAL, YOU ARE 18 YEARS OLD OR OLDER OR, IF YOU ARE AN ENTITY, THAT YOU ARE A CORPORATION, PARTNERSHIP, OR OTHER LEGAL ENTITY DULY FORMED (AND INCORPORATED, IF APPLICABLE) IN GOOD STANDING; AND (3) REPRESENT AND WARRANT THAT YOU HAVE THE POWER AND AUTHORITY TO ENTER INTO THIS AGREEMENT, INCLUDING LEGALLY ON ACTING BEHALF OF AN INDIVIDUAL, AND PERFORM ACTS AS REQUIRED BY YOU UNDER THIS AGREEMENT, INCLUDING ACTING ON BEHALF OF YOUR TENANT IN THE EVENT YOU ARE A PROPERTY MANAGER OR LANDLORD. IF YOU ARE A THIRD PARTY ACCESSING AN ACCOUNT ON BEHALF OF A USER (E.G., AS AN ADMINISTRATOR, CONSULTANT, ANALYST, AGENT, PARENT, CHILD, GUARDIAN, RELATIVE, ETC.), YOU AGREE THAT THESE TERMS APPLY, MUTATIS MUTANDIS, TO YOUR ACTIVITIES ON BEHALF OF SUCH USER.</p>
                <p>IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT, DO NOT CLICK THE "I AGREE" BUTTON OR OTHERWISE INDICATE REFUSAL AND DO NOT USE THE SERVICES.</p>

                <h4>1. Scope</h4>

                <h5>1.1 Definitions</h5>

                <p>When used in this Agreement, each of the following words and phrases shall have the meanings set out below:</p>
                <p>(a) "<span className="font-weight-bold">Account Profile</span>" means a user's account created for unique identification;</p>
                <p>(b) "<span className="font-weight-bold">Agreement</span>" means these Terms of Use, including related schedules, policies and other documents that appear under "Legal" on the Website, as may be amended from time to time;</p>
                <p>(c) "<span className="font-weight-bold">Business Days</span>" means any day, other than a Saturday or Sunday, and Statutory Holiday, on which the chartered banks are open for business;</p>
                <p>(d) "<span className="font-weight-bold">Cash Payment</span>" refers to the use of the Services to make an in-person payment via cash where available at participating payment locations;</p>
                <p>(e) "<span className="font-weight-bold">Credit Card Payment</span>" refers to the use of the Services to make a payment via credit card by Users);</p>
                <p>(f) "<span className="font-weight-bold">Debit Card Payment</span>" refers to the use of the Services to make a payment via Visa debit card or MasterCard debit card by Users;</p>
                <p>(g) "<span className="font-weight-bold">Bank Account Payment</span>" and <span className="font-weight-bold">Pre-Authorized Debit</span>" refers to the use of the Services to make a payment via checking/chequing bank account by Users;</p>
                <p>(h) "<span className="font-weight-bold">Force Majeure</span>" means any act, occurrence, condition or event beyond the control of a party that materially affects the performance of that party's obligations under this Agreement that could not reasonably have been foreseen or provided against, including such events as fires, telecommunications or utility or power failures, governmental or quasi-governmental acts, equipment failures, labour strife, riots, war, non-performance of our vendors or suppliers, acts of God, but not including general economic conditions;</p>
                <p>(i) "<span className="font-weight-bold">Indemnified Parties</span>" means Letus and its subsidiaries, parents, affiliates, officers, directors, agents, employees, consultants, customers, suppliers, third parties and other representatives, taken collectively;</p>
                <p>(j) "<span className="font-weight-bold">Landlord</span>" means the person or entity, including but not limited to property management companies, with whom you have a lease agreement under which you are using the Services to facilitate Payment;</p>
                <p>(k) "<span className="font-weight-bold">Merchant Account</span>" means a merchant account established at the Underwriting Bank into which one or more Payments may be made;</p>
                <p>(l) "<span className="font-weight-bold">Monthly Subscription Fee</span>" means a monthly charge for a Landlord to use and license of our Services;</p>
                <p>(m) "<span className="font-weight-bold">Payment</span>" means a Credit Card Payment, Debit Card Payment, Bank Account Payment, Pre-Authorized Debit, or a Cash Payment as the case may be;</p>
                <p>(n) "<span className="font-weight-bold">Payment Amount</span>" refers to the aggregate dollar value of a Payment exclusive of the Transaction Fee;</p>
                <p>(o) "<span className="font-weight-bold">Payment Processor</span>" means Letus's third party payment processor;</p>
                <p>(p) "<span className="font-weight-bold">Recurring Payment</span>" refers to an automatic recurring payment processed monthly on the date that you or your Landlord selected;</p>
                <p>(q) "<span className="font-weight-bold">Transaction Fee</span>" means the fee charged by us to you when you use the Services including but not limited to a Credit Card Payment, Debit Card Payment, Bank Account Payment, Pre-Authorized Debit, or a Cash Payment is accepted by Letus and/or monthly subscription fee;</p>
                <p>(r) "<span className="font-weight-bold">Services</span>" means the internet Payment services offered through the Website and third parties;</p>
                <p>(s) "<span className="font-weight-bold">Tenant</span>" means a person who occupies real property owned by another based upon an agreement between the person and the landlord for rental payments;</p>
                <p>(t) "<span className="font-weight-bold">Underwriting Bank</span>" means Letus's third party underwriting bank;</p>
                <p>(u) "<span className="font-weight-bold">User</span>" means anyone who uses the Services; and</p>
                <p>(v) "<span className="font-weight-bold">Website</span>" means the website located at <a href="https://let.us" target="_blank" rel="noopener noreferrer">https://let.us</a>, <a href="https://let.us" target="_blank" rel="noopener noreferrer">https://let.us</a> and all related webpages.</p>

                <h4>2. Eligibility and your Account</h4>

                <h5>2.1 Letus Service</h5>

                <p>Subject to the terms of this Agreement and when offered by us, you may use our Services to make or receive Payments through the Website.</p>

                <h5>2.2 Your Account</h5>

                <p>When you enroll to use our Services, you must create an account and provide an Account Profile. You agree to keep the information in your profile current. If we become aware of any changes to your personal information, we may update your profile. You may opt out of communications, delete your account, and remove payment method details but your payment history and any information linked to those transactions will remain in our system and any information as required or permitted by law.</p>

                <h5>2.3 Eligibility</h5>

                <p>To be eligible to use our Services, you must be either (1) an individual at least 18 years old who is able to form legally binding contracts or legally act on behalf of an individual under applicable law with a mailing address in the United States or Canada; or (2) a corporation, partnership, or other legal entity duly formed (and incorporated, if applicable), in good standing, and have the power and authority to enter into this agreement and perform the acts as required by you or your agent under this Agreement. You may use the Services on your own behalf or as the authorized representative or agent of a corporation registered or individual to conduct business in the same country in which you reside. No machines, scripts or automated services may be used to access or use the Services.</p>

                <h5>2.4 Authentication</h5>

                <p>You hereby authorize us, directly or through third parties, to make any inquiries we consider necessary to validate your identity, banking information and business entity information (if applicable). This may include asking you for further information, personal information, requiring you to take steps to confirm ownership of your email address, bank account, or financial instruments, ordering a credit report and verifying your information against third party databases or through other sources. You represent and warrant that you have the power and authority to provide this information to us.</p>

                <h4>3. Payments</h4>

                <h5>3.1 Payment Processing</h5>

                <p>When you make or receive a Payment using our Services, our Payment Processors relay the payment information and transaction details and responses between you, your credit card issuing bank (or one or more credit card agencies), the Underwriting Bank and any other third parties required to process the transaction. The transfer of the Payment amount is effected between your credit card issuing bank or your bank and the Underwriting Bank (and not Letus). You accept and agree that Letus does not at any time receive, collect or hold any Payment amounts.</p>

                <h5>3.2 Transaction Fees</h5>

                <p>Immediately after you make a Payment using the Service, Letus will charge you a non-refundable Transaction Fee. This Transaction Fee is payable in addition to (and in advance of) the Payment Amount. Transaction Fees are never deducted from or adjusted against Payment amounts. Transaction Fees are paid into a separate Merchant Account established by Letus and are subject to change at any time. This includes Monthly Subscription fees payable by the Landlord where applicable.</p>
                <p>Transaction Fees associated with making a rent payment or other payment can be found here: <a href="https://help.rentmoola.com/hc/en-us/articles/115000150023-Service-Fees" target="_blank" rel="noopener noreferrer">https://help.rentmoola.com/hc/en-us/articles/115000150023-Service-Fees</a></p>

                <h5>3.3 Other Fees</h5>

                <p>Fees for Landlords including Property Managers, include a monthly price per unit, a one-time setup fee, merchant bank account fees, user fees, integration fees, Chargeback Support Fees, merchant inactivity fees and may change from time to time.</p>

                <h5>3.4 Taxes</h5>

                <p>It is your responsibility to determine what, if any, taxes apply to the Payments you make, and it is your responsibility to collect, report and remit the correct tax to the appropriate tax authority. Letus is not responsible for determining whether taxes apply to your Payment transaction, or for collecting, reporting or remitting any taxes arising from any Payment transaction.</p>

                <h5>3.5 Credit Card, Debit Card, Bank Account, Pre-Authorized Debit or Cash Delays and Non-Completion</h5>

                <p>Since Credit Card Payment, Debit Card Payment, Bank Account Payment, Pre-Authorized Debit, or Cash Payment processing times depend on administrative factors and institutional backlogs beyond our control, Credit Card Payments, Debit Card Payments, Bank Account Payments, Pre-Authorized Debit or a Cash Payments may take up to five Business Days (and in rare cases, even longer) to complete. Furthermore, completion of a Credit Card Payment, Debit Card Payment, Bank Account Payments, Pre-Authorized Debit or a Cash Payment is contingent on both the authorization of the Credit Card Payment, Debit Card Payment, Bank Account Payment, Pre-Authorized Debit or a Cash Payment by the applicable credit card issuing bank and acceptance of the Credit Card Payment or Debit Card Payment by the Underwriting Bank. In the event that a Credit Card Payment, Debit Card Payment, Bank Account Payment, Pre-Authorized Debit, or a Cash Payment is unable to be completed, we will attempt to notify you at least once through our Website or using the contact information in your Account Profile. In the case of such delay or non-completion, your liability to the intended recipient of the Credit Card Payment, Debit Card Payment, Bank Account Payment, Pre-Authorized Debit or a Cash Payment may be subject to penalties, late fees and interest charges assessed by such recipient or by your Landlord, all of which remain your responsibility (and not Letus's). If a Credit Card Payment, Debit Card Payment, Bank Account Payment, Pre-Authorized Debit, or a Cash Payment is not completed, you are not entitled to a return of any Transaction Fees charged by us, irrespective of the reason for non-completion.</p>

                <h5>3.6 No Reversals, No Refunds, Chargebacks</h5>

                <p>If your Payment is completed, you accept and agree that no refunds, cancellations, or chargebacks are permitted through the Website. If a Payment is refunded, cancelled, or charged back by your credit card issuing bank, bank, financial institution, or another person, you are not entitled to the return of any associated Transaction Fees, irrespective of the reason for such refund, cancellation, or charge back. You accept and agree not to request a refund, cancellation, or chargeback of Service Fees from your credit card issuing bank, bank, financial institution, or any other person. Your landlord may authorize a refund for a Payment by submitting a written request to us. The refund is subject to the funds being made available by your landlord and the amount will only be refunded to the original payment method used for the payment. There are no refunds whatsoever twelve (12) months after the original payment transaction date. We reserve the right to recoup any costs associated with processing a refund, cancellation, or chargeback from you. All chargebacks are subject to an additional non-refundable Chargeback Support Fee of $25.00 charged by us to the Landlord.</p>
                <p>A chargeback is typically caused when a User or cardholder disputes a charge that appears on their bill. A chargeback may result in the reversal of a transaction, with the amount charged back to you. You can be assessed chargebacks for disputes, unauthorized or improperly authorized transactions, transactions that do not comply with the payment network rules or terms of this agreement, are allegedly unlawful or suspicious, or any reversals by the payment network, our processor or acquiring bank, or the cardholder’s card issuing financial institution.</p>
                <p>When a chargeback is issued, the Landlord is immediately liable for the full amount of the chargeback plus any associated fees, Chargeback Support Fees, fines, expenses or penalties (including those assessed by the payment network or our payment processors).The Landlord  agrees that we may recover these amounts by means of EFT/ACH debit of your merchant account, bank account or setting off any amounts owed to you by us. If we are unable to recover funds related to a chargeback for which the Landlord is  liable, you will pay us the full amount of the chargeback immediately upon demand.</p>
                <p>The Landlord or Letus may elect to contest chargebacks assessed to your account(s). We will provide you with assistance including notification to help contest your chargebacks. We do not assume any responsibility for our role or assistance in contesting chargebacks. You agree to provide us with the necessary information, within two (2) business days and at your expense, to investigate or help resolve any chargeback. You also grant us permission to share records or other information required with the user or cardholder, the cardholder’s financial institution, and your financial institution to help resolve any disputes. You acknowledge that your failure to provide us with complete and accurate information in a timely manner may result in an irreversible chargeback being assessed. If the cardholder’s issuing bank of the payment network does not resolve a dispute in your favour, we may recover the chargeback amount and any associated fees, including the Chargeback Support Fee, from you as described in this Agreement and all applicable agreements.</p>
                <p>At any point if it is determined that you are incurring excessive chargebacks, such may result in additional fees, penalties, or fines. Excessive chargebacks may also result in additional controls and restrictions to your use of the Service, including without limitation, delays in your payments, possible suspension or termination of your account(s) and the Service.</p>

                <h5>3.7 Pre-Authorized Debits and Recurring Payments</h5>

                <p>You hereby authorize us and our respective vendors and agents to initiate debit and credit entries to any bank account you or your Landlord authorize us to debit funds from, or any other account maintained by you, all in accordance with this Agreement. YOU HEREBY WAIVE THE RIGHT TO RECEIVE ADVANCE NOTICE FROM US AND OUR VENDORS AND AGENTS OF ANY AND ALL SUCH DEBITS. This authorization will remain in effect after termination of this Agreement and until all of your obligations to us have been paid in full. If you change your bank account, this authorization will apply to the new account and you shall provide us or your Landlord, in writing, such information regarding the new account as we deem necessary. Such new account shall thereafter be and become the new bank account for the purpose of this Agreement. It may take us up to ten (10) business days after receipt of a written notice from you to reflect in our system any change to your bank account. If you change your bank account, you agree that you are responsible for all costs incurred by us in connection with your decision to change the account. You may revoke any pre-authorization upon thirty (30) days' prior written notice to us. You will receive an email notification seven days before your scheduled recurring payment is processed. It is your responsibility to disable your pre-authorized debit or recurring payment a minimum of 2 full business days before the scheduled recurring payment date. If you are set up with pre-authorized recurring credit card payments and we cannot complete the transaction with your primary credit card account on file, we will attempt to complete the transaction with the secondary credit card account on file.</p>

                <h5>3.8 Payment Limits</h5>

                <p>We may, at our sole discretion, impose limits on the Payment Amount you can send or receive through our Services.</p>

                <h5>3.9 Insufficient Funds or Credit</h5>

                <p>NOTWITHSTANDING ANY OTHER PROVISION, LETUS IS NOT RESPONSIBLE FOR THE PAYMENT OF RENT OR FEES TO YOUR LANDLORD OR OTHER PAYEES: YOU ARE SOLELY RESPONSIBLE. ANY FAILURE IN RELATION TO MAKING A PAYMENT TO YOUR LANDLORD OR PAYEES IS YOUR SOLE RESPONSIBILITY AND LETUS IS NOT LIABLE, IN ANY WAY, FOR YOUR FAILURE OR INABILITY TO MAKE PAYMENTS TO YOUR LANDLORD OR PAYEES. THIS INCLUDES, BUT IS NOT LIMITED TO, ANY SITUATION WHERE YOU HAVE INSUFFICIENT FUNDS OR CREDIT AVAILABLE TO MAKE A PAYMENT TO YOUR LANDLORD OR PAYEES AS A RESULT OF LETUS CHARGING ANY APPLICABLE TRANSACTION FEE FOR YOUR USE OF THE SERVICES.</p>

                <h5>3.10 Declined Transaction Fees</h5>

                <p>If any Service Fee or Monthly Subscription Fee is declined for a Payment you have made, your account will be considered in arrears. You must pay any outstanding Transaction Fees or Monthly Subscription Fees to put your account back into good standing. No additional Transaction Fees are applicable when paying the Service Fee through your account. Failure to do so may result in your account being suspended and/or a third party collections agency contacting you.</p>

                <h5>3.11 Default and Fraudulent Transactions</h5>

                <p>If any single transaction is investigated and is determined to be fraudulent or suspicious, in Letus's sole discretion, Letus may terminate or limit the relevant User's account. Such default, fraudulent or suspicious transactions may be reported to legal authorities. We reserve the right to not authorize or settle any transaction you submit which we believe is in violation of this Agreement and all applicable agreements. We are not responsible for fraudulent transactions that are authorized by the payment network or bank.</p>

                <h5>3.12 Payments to Partners</h5>

                <p>You may only make Payments to building owners, landlords, property managers and entities who are authorized partners of Letus and have entered into the Terms of Use and its services providers (as applicable).</p>

                <h4>4. Your Conduct</h4>

                <h5>4.1 Conduct</h5>

                <p>In connection with your use of our Website and our Services, you agree to:</p>
                <p>(a) use the Services exclusively for the remittance of rental, condominium, utility or other property management fees, and not for the remittance of any other types of payments unless approved by us;</p>
                <p>(b) comply in all respects with this Agreement and any other agreements that you have entered into with us;</p>
                <p>(c) comply with all applicable Federal, State/Provincial and Local laws and regulations;</p>
                <p>(d) not register multiple Letus accounts without our prior written consent;</p>
                <p>(e) not infringe our or any third party's copyright, patent, trade-mark, trade secret or other intellectual property rights, or rights of publicity or privacy;</p>
                <p>(f) provide, if requested, confirmation of your identity or any other information you have provided to us, and to cooperate with any investigations of the same by us or our third party delegates;</p>
                <p>(g) not make Payments using the Services to provide yourself or any other person with a credit card cash advance;</p>
                <p>(h) respond in a reasonable and timely fashion to our communications;</p>
                <p>(i) not introduce or facilitate the use or spread of any viruses, trojan horses, worms or other computer programming routines that may damage, detrimentally interfere with, surreptitiously intercept, or expropriate any system, data, or information;</p>
                <p>(j) not use any robot, spider, other automatic device, or manual process to monitor or copy our Website without our prior written consent;</p>
                <p>(k) not use any device, software, or routine to bypass our robot exclusion headers or to interfere, or attempt to interfere with our Website or our Services; and</p>
                <p>(l) not take any action that may cause us to lose any of our services from our internet service providers, our Payment Processor or other suppliers.</p>

                <h5>4.2 Suspected Fraud</h5>

                <p>IF LETUS HAS REASON TO BELIEVE, IN ITS SOLE DISCRETION, THAT YOU OR OTHERS MAY ENGAGE OR MAY HAVE ENGAGED IN ANY CRIMINAL OR PROHIBITED OR CIVIL VIOLATION OF APPLICABLE LAWS, LETUS MAY CEASE TO PROVIDE YOU THE SERVICES, AND WILL CONTACT THE APPROPRIATE ADMINISTRATIVE AND LAW ENFORCEMENT AUTHORITIES. YOU AGREE TO COOPERATE FULLY WITH ANY SUCH INVESTIGATIONS.</p>

                <h4>5. Intellectual Property</h4>

                <h5>5.1 Limited Licence</h5>

                <p>You grant to us a limited, non-exclusive and revocable licence to access and use all information and data that you provide in connection with your use of our Services. You agree not to use our Services for co-branding, framing, linking or reselling any portion of our Services.</p>

                <h5>5.2 Intellectual Property</h5>

                <p>"Letus", "Letus.com", "let.us" all related logos, marks, symbols, and words on our Website (including the Services) are either trademarks or registered trademarks that belong to us or to our licensors. In addition, all content and materials on our Website, Website page headers, custom graphics, button icons, and scripts are our copyrighted materials, service marks, trademarks, or trade dress that belong to us or our licensors. You may not copy, imitate, or use any of the foregoing without our prior written consent.</p>

                <h4>6. Security and Electronic Communication</h4>

                <h5>6.1 Unsecure Electronic Channels</h5>

                <p>You accept full responsibility for all risks associated with electronic communications between us, including, without limitation, the risk that such communications may be delivered to the wrong person, intercepted, lost, or altered by a third party.</p>

                <h5>6.2 Access to Account</h5>

                <p>You are responsible for ensuring that the Services, your Merchant Account, your Letus account, or your Letus account will only be used by you or someone legally authorized to act on your behalf. You agree that any electronic communications made by you or purporting to have been made on your behalf will be binding on you.</p>

                <h5>6.3 Notice of Unauthorized Use</h5>

                <p>You agree to notify us immediately if you know or reasonably ought to know that an unauthorized person is accessing or may access our Services (through your Letus account or your Letus account, or otherwise) or your Merchant Account or if you become aware of any actual or threatened use of our Services in a manner contrary to this Agreement.</p>

                <h5>6.4 Web Links</h5>

                <p>There may be links on our Website to websites not owned or operated by us. We make no representations about, do not endorse, and are not responsible for the content, products, services, privacy policies, or practices relating to such other websites. We are not responsible for any losses realized by you or claims made against you relating to your use of such other websites.</p>

                <h5>6.5 Evidence</h5>

                <p>Electronic records and other information maintained by us regarding any electronic communications with you will be admissible in any legal, administrative or other proceedings as conclusive evidence of the contents of those communications in the same manner as an original paper document, and you waive any right to object to the introduction of any such record or other information into evidence on that basis.</p>

                <h5>6.6 Help Center</h5>

                <p>Standard message rates and data charges from your carrier apply when sending and/or receiving Letus text messages or calling the Letus Help Center. Please check with your carrier if you have questions about your plan and costs.</p>

                <h4>7. Privacy</h4>

                <h5>7.1 Privacy</h5>

                <p>By using the Website and our Services, you agree to the collection and use of your personal information, and the sharing of such information with our Indemnified Parties and your landlord, property manager, or other related party (as the case may be), in accordance with our Privacy Policy, available at <Link to="/privacy">https://let.us/privacy</Link>.</p>

                <h5>7.2 Password Security and Keeping Your Email and Address Current</h5>

                <p>You are responsible for maintaining adequate security and control of any and all IDs, passwords, personal identification numbers (PINs), or any other codes that you use to access the Services. You are also responsible for keeping your email address, street address, and other personal information, up to date in your Account Profile.</p>

                <h5>7.3 Calls to You; Mobile Telephone Numbers</h5>

                <p>By providing us with a telephone number (including a mobile telephone number), you consent to receiving auto-dialed and pre-recorded message calls from us at that number. If we determine that a telephone number you have provided to us is a mobile telephone number, we may categorize it as such in our systems and in your Account Profile, and you consent to receive text messages from us about your use of the Services at that number. Your call may be recorded for training and quality purposes.</p>

                <h4>8. Indemnification; Limitation of Liability; Warranties</h4>

                <h5>8.1 Indemnification and Release</h5>

                <p>You agree to indemnify and hold harmless each of the Indemnified Parties from any claim or demand made by such Indemnified Parties or any third parties (including reimbursement of reasonable legal costs incurred) due to or arising out of your use of our Services or our Website, your breach of this Agreement or your violation of any law or right of any third party.</p>

                <h5>8.2 Limitations of Liability</h5>

                <p>IN NO EVENT SHALL ANY INDEMNIFIED PARTY BE LIABLE FOR LOST PROFITS, BUSINESS, REVENUE, OR CUSTOMERS OR ANY SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, DISCLOSURE OF INFORMATION OR LOSS OF PRIVACY), HOWEVER ARISING OUT OF OR IN CONNECTION WITH OUR WEBSITE, OUR SERVICES OR THIS AGREEMENT INCLUDING NEGLIGENCE EVEN IF LETUS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ANYTHING TO THE CONTRARY NOTWITHSTANDING, THE CUMULATIVE LIABILITY OF ALL INDEMNIFIED PARTIES TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED IN TOTAL TO THE LESSER OF (A) THE AMOUNT OF TRANSACTION FEES YOU PAID TO LETUS IN THE SIX MONTHS PRIOR TO THE EVENT GIVING RISE TO AN ALLEGED LIABILITY AND (B) FIFTY DOLLARS, AS THE SOLE AND EXCLUSIVE REMEDY.</p>

                <h5>8.3 No Warranty</h5>

                <p>OUR SERVICES ARE PROVIDED AS IS AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED OR STATUTORY, OF ANY KIND. WE SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. WE DO NOT GUARANTEE CONTINUOUS, UNINTERRUPTED, ERROR-FREE, OR SECURE ACCESS TO ANY PART OF OUR SERVICES.</p>

                <h5>8.4 No Jury or Class Action</h5>

                <p>YOU HEREBY WAIVE ANY RIGHT TO A TRIAL BY JURY AND AGREE THAT ANY AND ALL DISPUTES, CLAIMS, ACTIONS AND CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THE TERMS OF USE AND ALL ISSUES AND QUESTIONS CONCERNING THE VALIDITY, INTERPRETATION, AND ENFORCEABILITY OF THIS TERMS OF USE AND OTHER DOCUMENTS SHALL BE RESOLVED INDIVIDUALLY, WITHOUT RESORT TO ANY FORM OF CLASS ACTION.</p>

                <h4>9. Termination</h4>

                <h5>9.1 Termination by Letus</h5>

                <p>We may, in our sole discretion and without penalty or liability whatsoever, terminate this Agreement or your access to our Services for any reason and at any time, upon notice to you.</p>
                <p>For Property Managers and Landlords, This Agreement shall be for an initial term of twelve (12) months commencing on the Effective Date (the "Initial Term") and shall automatically renew at the end of each successive term for a further twelve (12) months unless terminated in accordance with the terms and provisions of this Agreement.</p>

                <h5>9.2 Post-Termination Transition</h5>

                <p>All monetary and non-monetary obligations of the parties owing or to be performed or discharged prior to the date of termination or expiration shall survive termination until such obligations have been performed or discharged.</p>

                <h4>10. Miscellaneous</h4>

                <h5>10.1 Currency</h5>

                <p>Unless otherwise specified, all dollar amounts referred to in this Agreement are in lawful money of Canada and the United States.</p>

                <h5>10.2 Relationship</h5>

                <p>Letus is an independent contractor. Nothing contained in this Agreement will create a partnership, joint venture, principal-and-agent relationship, or any similar relationship between us.</p>

                <h5>10.3 Third-Party Providers</h5>

                <p>We may use one or more third-party service providers in the course of providing our Services. You agree that information about you and your Account Profile, Payments may be shared with such service providers for the purposes of performing this Agreement in accordance with our Privacy Policy.</p>

                <h5>10.4 Features and Functionality</h5>

                <p>You understand that the Services may change over time as we refine and add more features or content. We reserve the right to update, modify, or discontinue the features, functionality, content or other aspects of the Service, including Letus, any and all MoolaPerks, contests and promotions at any time, with or without notice, in our sole discretion. Any new features that augment or enhance the current Services, including the release of new tools and resources, shall be subject to this Agreement. We do not guarantee the availability of the Services and/or any of the MoolaPerks or other benefits contained therein, and they are all subject to change at any time without notice.</p>

                <h5>10.5 Entire Agreement</h5>

                <p>This Agreement constitutes the entire agreement between us relating to the subject matter of this Agreement; it supersedes and replaces any previous or contemporaneous agreements and discussions between us.</p>

                <h5>10.6 Representation and Warranty</h5>

                <p>You represent and warrant that your execution of, and performance under, this Agreement (a) in no way breaches, contravenes, violates, or in any manner conflicts with any of your other legal obligations, <span className="font-weight-bold">[including, without limitation, your corporate charter or similar document, if applicable,]</span> or any agreement between you and any third party; and (b) has been duly authorized by all necessary actions and does not require any consent or other action by or in respect of any third party.</p>

                <h5>10.7 Notices</h5>

                <p>In order to be effective, notice pursuant to this Agreement must be given as follows:</p>
                <p>(a) notice to you may be given by electronic mail to the email address stated in your Account Profile, or by posting such notice on our Website, whether addressed specifically to you or more generally to users of our Website or our Services; and</p>
                <p>(b) except as otherwise stated in this Agreement, notice to us must be sent by postal mail, with an electronic mail copy to help@let.us, to:</p>

                <p>
                    PaySlate Inc.<br />
                    710 - 1030 West Georgia Street,<br />
                    Vancouver BC V6E 2Y3<br />
                    and such notice is effective on the fourth Business Day following the day on which it is postmarked.
                </p>

                <h5>10.8 Severability</h5>

                <p>The invalidity or unenforceability of any particular provision of this Agreement will not affect or limit the validity or enforceability of the remaining provisions.</p>

                <h5>10.9 Governing Law</h5>

                <p>This Agreement is governed by, and is to be interpreted, construed and enforced in accordance with, the laws of the State of Nevada and the laws of the United States of America applicable in Nevada, excluding any rule or principle of conflicts of law that may provide otherwise. This Agreement is governed by, and is to be interpreted, construed and enforced in accordance with, the laws of the Province of British Columbia and the laws of Canada applicable in British Columbia, excluding any rule or principle conflicts of law that may provide otherwise. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.</p>

                <h5>10.10 Jurisdiction</h5>

                <p>The parties irrevocably submit to and accept generally and unconditionally the exclusive jurisdiction of the courts and appellate courts of the State of Nevada or the Province of British Columbia with respect to any legal action or proceeding which may be brought at any time relating in any way to this Agreement except for applications for injunctive relief or specific performance by Letus. Each of the parties irrevocably waives any objection it may now or in the future have to the venue of any such action or proceeding, and any claim it may now or in the future have that any such action or proceeding has been brought in an inconvenient forum. Jurisdiction and venue under this Agreement shall lie in the State of Nevada or the Province of British Columbia.</p>

                <h5>10.11 Binding Effect; Assignment</h5>

                <p>This Agreement ensures to the benefit of and binds the parties' respective heirs, executors, administrators and other legal representatives, successors, and permitted assigns. You may not assign this Agreement without our prior written consent. We may assign this Agreement to a third party upon notice to you.</p>

                <h5>10.12 Equivalency</h5>

                <p>This electronic Agreement and any other materials incorporated herein will be:</p>
                <p>(a) deemed for all purposes to be a "writing" or "in writing", and to comply with all statutory, contractual, and other legal requirements for a writing;</p>
                <p>(b) legally enforceable as a signed writing as against the parties subject to the electronic documents; and</p>
                <p>(c) deemed an "original" when printed from electronic records established and maintained in the ordinary course of business.</p>
                <p>Electronic documents introduced as evidence in any judicial, arbitration, mediation or proceeding will, if established and maintained in the ordinary course of business, be admissible to the same extent as business records in written form that are similarly established and maintained.</p>

                <h5>10.13 Amendments</h5>

                <p>We may amend this Agreement from time to time at our discretion. You accept responsibility for reviewing all communication about this Agreement on our Website and acknowledge you will be deemed to have agreed to the terms of amendments communicated on our Website if you continue to use our Services.</p>

                <h5>10.14 Professional Advice</h5>

                <p>Any information provided to you by us is merely guidance and you agree that it does not constitute professional advice. You should consult with professional accounting, tax, legal, and other advisors before deciding to use our Services.</p>

                <h5>10.15 Waivers</h5>

                <p>A waiver shall not be effective unless it is in writing and signed by the party against whom the waiver is being enforced.</p>

                <h5>10.16 Survival</h5>

                <p>The provisions of Sections 1, 3, 4, 5, 7, 8 and 10.16 of this Agreement shall survive termination and expiration.</p>

                <h5>10.17 Force Majeure</h5>

                <p>We are not responsible for damages caused by delay or the failure to perform any of our obligations under this Agreement when the delay or failure is the result of Force Majeure.</p>

                <h5>10.18 Language</h5>

                <p>The parties confirm that this Agreement, as well as any other documents relating to this Agreement, have been and shall be prepared in the English language only. Les parties reconnaissent avoir convenue que la présente convention ainsi que tous documents, avis et procédures judiciaires qui pourront être exécutés, donnés ou intentées à la suite des présentes ou ayant un rapport, direct ou indirect, avec la présente convention soient rédigée en anglais. Las partes confirman que este Acuerdo, así como cualesquiera otros documentos relacionados con este Acuerdo, han sido y serán preparados en el idioma inglés solamente.</p>

            </React.Fragment>
        )
    };
}

export default Terms;