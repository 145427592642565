import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Table from "../../common/Table";
import {FormattedMessage} from "react-intl";
import Propertii from "../../common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import ButtonSave from "../../common/ButtonSave";
import Modal from "../../common/Modal";
import Alert from "../../common/Alert";
import FieldText from "../../common/FieldText";
import FieldFile from "../../common/FieldFile";
import FieldSelect from "../../common/FieldSelect";
import Moment from "react-moment";

class LeaseApplicationPages extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            leaseApplicationPage: {},
            customFields: {},
            document: {
                file: '',
            },
            documentList: [],
            leaseApplicationPageList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },
            leaseApplicationPageQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.match.params.companyId
                    }
                ]
            },
            validationList: [],
        };

        this.searchLeaseApplicationPages = this.searchLeaseApplicationPages.bind(this);
        this.editLeaseApplicationPage = this.editLeaseApplicationPage.bind(this);
        this.initLeaseApplicationPage = this.initLeaseApplicationPage.bind(this);
        this.saveLeaseApplicationPage = this.saveLeaseApplicationPage.bind(this);
        this.deleteLeaseApplicationPage = this.deleteLeaseApplicationPage.bind(this);

        this.searchDocuments = this.searchDocuments.bind(this);
        this.uploadDocument = this.uploadDocument.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.selectDocument = this.selectDocument.bind(this);
    }

    /**
     * Load the list of lease application pages on mounting of the component.
     */
    componentDidMount() {

        this.searchLeaseApplicationPages(1, 25, this.state.leaseApplicationPageQuery);
    }

    /**
     * Handle selecting a lease application page record by bringing up the modal with lease application page values
     * pre-filled.
     *
     * @param leaseApplicationPage - The ID of the lease application page selected.
     */
    editLeaseApplicationPage(leaseApplicationPage) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/lease_application_page/${leaseApplicationPage}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                leaseApplicationPage: response.data,
                customFields: response.data.customFields
            }));

            this.searchDocuments();

            $('#lease-application-page').modal('show');

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Initialize a new instance of a lease application page object when the user clicks the appropriate button.
     */
    initLeaseApplicationPage() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/lease_application_page/new`).then(response => {
            this.setState(prevState => ({
                ...prevState,
                leaseApplicationPage: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Delete the selected lease application page.
     */
    deleteLeaseApplicationPage() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/lease_application_page/${this.state.leaseApplicationPage.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.leasing.deleted'
                    }
                }],
            });

            this.searchLeaseApplicationPages(this.state.leaseApplicationPageList.page, this.state.leaseApplicationPageList.recordsPerPage, this.state.leaseApplicationPageQuery);

            $('#lease-application-page').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle the submission of the form. Use the create date field of the lease application page in the state to
     * determine if the user is editing or creating a new lease application page. Upon successful submission of the
     * form, refresh the table and hide the modal.
     *
     * @param event - The event container.
     */
    saveLeaseApplicationPage(event) {

        event.preventDefault();

        let leaseApplicationPage = this.state.leaseApplicationPage;

        leaseApplicationPage.companyId = this.props.match.params.companyId;
        leaseApplicationPage.customFields = this.state.customFields;

        if(this.state.leaseApplicationPage.createDate == null) {

            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, leaseApplicationPage, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.companies.leasing.created'
                        }
                    }],
                });

                this.searchLeaseApplicationPages(this.state.leaseApplicationPageList.page, this.state.leaseApplicationPageList.recordsPerPage, this.state.leaseApplicationPageQuery);

                $('#lease-application-page').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });

        } else {

            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, leaseApplicationPage, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved'
                        }
                    }],
                });

                this.searchLeaseApplicationPages(this.state.leaseApplicationPageList.page, this.state.leaseApplicationPageList.recordsPerPage, this.state.leaseApplicationPageQuery);

                $('#lease-application-page').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Upload a document to the list of documents for a specific lease application page.
     *
     * @param event - The event container.
     */
    uploadDocument(event) {

        event.preventDefault();

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application_page/${this.state.leaseApplicationPage.id}/upload`, this.state.document.file, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.searchDocuments();

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Select a document and bring up the delete document confirmation modal.
     *
     * @param document - The selected document.
     */
    selectDocument(document) {

        this.setState(prevState => ({
            ...prevState,
            document: document
        }));

        $('#lease-application-page-documents').modal('hide');
        $('#delete-document').modal('show');
    }

    /**
     * Delete the selected document.
     */
    deleteDocument() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/document/${this.state.document.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.searchDocuments();

            $('#lease-application-page-documents').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Update the data table of lease application pages.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchLeaseApplicationPages(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application_page/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                leaseApplicationPageList: response.data,
                leaseApplicationPageQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Perform a search for all available documents related to the lease application page for custom fields that require
     * an image URL or document URL.
     */
    searchDocuments() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/document/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'recordId',
                    operator: 'EQUALS',
                    fieldValue: this.state.leaseApplicationPage.id
                }
            ]
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                documentList: response.data.records,
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The lease application page management component.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} validationType="primary" />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Lease Application Pages
                            </div>
                            <div className="col text-right">
                                <div data-toggle="modal" data-target="#lease-application-page" className="btn btn-primary btn-sm" onClick={() => this.initLeaseApplicationPage()}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Lease Application Page
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table columns={{pageName: 'Page Name',pageUrl:'Page URL'}}
                           columnWidths={['40%','60%']}
                           headerClass="c-pointer"
                           data={this.state.leaseApplicationPageList}
                           query={this.state.leaseApplicationPageQuery}
                           sortEnabled={false}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.searchLeaseApplicationPages}>
                        <tbody>
                        {this.state.leaseApplicationPageList.records.map((data, key) => {
                            return(
                                <tr >
                                    <td className="c-pointer"  key={key} onClick={() => this.editLeaseApplicationPage(data.id)}>
                                        <div className="">
                                            {data.pageName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            <a target='_blank' href={'/apply/' + data.pageName}>{window.location.host}/apply/{data.pageName}</a>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="lease-application-page" tabIndex="-1" role="dialog" aria-labelledby="lease-application-page-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveLeaseApplicationPage}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="lease-application-page-label">
                                        {this.state.leaseApplicationPage['createDate'] == null &&
                                        <span className="">Create Lease Application Page</span>
                                        }
                                        {this.state.leaseApplicationPage['createDate'] != null &&
                                        <span className="">Edit Lease Application Page</span>
                                        }
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="pageName" label="Page Name" model="leaseApplicationPage" parent={this} value={this.state.leaseApplicationPage['pageName']} />

                                    <FieldText id="companyName" label="Company Name" model="customFields" parent={this} value={this.state.customFields['companyName']} />

                                    <FieldText id="heroHeading" label="Heading" model="customFields" parent={this} value={this.state.customFields['heroHeading']} />

                                    <FieldText id="heroPoint1" label="Point 1" model="customFields" parent={this} value={this.state.customFields['heroPoint1']} />

                                    <FieldText id="heroPoint2" label="Point 2" model="customFields" parent={this} value={this.state.customFields['heroPoint2']} />

                                    <FieldText id="heroPoint3" label="Point 3" model="customFields" parent={this} value={this.state.customFields['heroPoint3']} />

                                    <FieldText id="heroPoint4" label="Point 4" model="customFields" parent={this} value={this.state.customFields['heroPoint4']} />

                                    <FieldText id="formSubheading" label="Form Subheading" model="customFields" parent={this} value={this.state.customFields['formSubheading']} />

                                    <FieldSelect id="logo" label="Logo" model="customFields" parent={this} value={this.state.customFields['logo']}>
                                        <option value="">Select a file...</option>
                                        {this.state.documentList &&
                                        <React.Fragment>
                                            {this.state.documentList.map((document, key) => {
                                                return (
                                                    <option key={key} value={document.url}>{document.name}</option>
                                                );
                                            })}
                                        </React.Fragment>
                                        }
                                    </FieldSelect>

                                    <FieldSelect id="background" label="Background" model="customFields" parent={this} value={this.state.customFields['background']}>
                                        <option value="">Select a file...</option>
                                        {this.state.documentList &&
                                        <React.Fragment>
                                            {this.state.documentList.map((document, key) => {
                                                return (
                                                    <option key={key} value={document.url}>{document.name}</option>
                                                );
                                            })}
                                        </React.Fragment>
                                        }
                                    </FieldSelect>

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#lease-application-page").modal("hide")}>Close</button>
                                        </div>
                                        <div className="col-8 text-right">

                                            {this.state.leaseApplicationPage['createDate'] != null &&
                                            <div className="btn btn-lg btn-primary ml-2" onClick={() => {$("#lease-application-page").modal("hide"); $("#delete-lease-application-page").modal("show");}}>
                                                Delete
                                            </div>
                                            }

                                            {this.state.leaseApplicationPage['createDate'] != null &&
                                            <div className="btn btn-lg btn-primary ml-2" onClick={() => {$("#lease-application-page").modal("hide");$("#lease-application-page-documents").modal("show");}}>
                                                Upload Files...
                                            </div>
                                            }

                                            <ButtonSave />

                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="lease-application-page-documents" tabIndex="-1" role="dialog" aria-labelledby="lease-application-page-documents-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.uploadDocument}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="lease-application-page-documents-label">
                                        Upload Files
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Files uploaded below will be made available when editing image-related fields for this lease application page.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    {this.state.documentList.map((document, key) => {
                                        return(
                                            <div key={key} className="list-group mb-2">
                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.selectDocument(document)}>

                                                    <div className="row align-items-center">
                                                        <div className="col-2 text-center">
                                                            <img src={document.url} alt={document.name} className="img-fluid" />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="">
                                                                {document.name}
                                                            </div>
                                                            <small className="mb-0 ml-md-0 small text-muted">
                                                                Uploaded on <Moment format="MMM DD, YYYY HH:mm">{document.createDate}</Moment>
                                                            </small>
                                                        </div>
                                                        <div className="col-2 text-right">
                                                            <div className="float-right">
                                                                <FontAwesomeIcon icon={['far', 'trash-alt']} className="fa-fw" size="1x" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        );
                                    })}

                                    <FieldFile id="file" label="File" model="document" labelClass="d-none mb-0" fieldClass="form-control-lg mb-0" fieldColumns="12" labelColums="0" parent={this} value={this.state.document['file']} />

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#lease-application-page-documents").modal("hide"); $("#lease-application-page").modal("show");}}>Back</button>
                                        </div>
                                        <div className="col-8 text-right">

                                            <button type="submit" className="btn btn-primary btn-lg ml-2">
                                                Upload File
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

                <Modal id="delete-lease-application-page" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Lease Application Page"
                       body="Are you sure you would like to delete this lease application page?">
                    <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => {$("#lease-application-page").modal("show")}}>
                        <FormattedMessage id="button.close" />
                    </button>
                    <button onClick={() => {this.deleteLeaseApplicationPage()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                        Delete Lease Application Page
                    </button>
                </Modal>

                <Modal id="delete-document" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete File"
                       body={`Are you sure you would like to delete ${this.state.document.name}? Lease application page elements currently utilizing this file may appear broken as a result, and will require manual review.`}>
                    <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => {$("#lease-application-page-documents").modal("show")}}>
                        <FormattedMessage id="button.close" />
                    </button>
                    <button onClick={() => {this.deleteDocument()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                        Delete File
                    </button>
                </Modal>

            </React.Fragment>
        )
    };
}

export default LeaseApplicationPages;