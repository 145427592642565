import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Table from "../../common/Table";
import {FormattedMessage, injectIntl} from "react-intl";
import ButtonBack from "../../common/ButtonBack";
import Propertii from "../../common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import ButtonSave from "../../common/ButtonSave";
import FieldText from "../../common/FieldText";
import Modal from "../../common/Modal";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import Spinner from "../../common/Spinner";

class Roles extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            role: {},

            writePermissions: [],
            recordTypeList: [],

            roleList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            roleQuery: {
                orderBy: 'ASC',
                orderByFields: ['createDate'],
                conditionList: []
            },

            validationList: [],

        };

        this.searchRoles = this.searchRoles.bind(this);
        this.viewRole = this.viewRole.bind(this);
        this.initRole = this.initRole.bind(this);
        this.saveRole = this.saveRole.bind(this);
        this.deleteRole = this.deleteRole.bind(this);

        this.addWritePermission = this.addWritePermission.bind(this);
        this.removeWritePermission = this.removeWritePermission.bind(this);

        this.handleChangeWritePermissionRecordType = this.handleChangeWritePermissionRecordType.bind(this);
        this.handleChangeWritePermissionAccess = this.handleChangeWritePermissionAccess.bind(this);
    }

    /**
     * Load the list of roles on mounting of the component.
     */
    componentDidMount() {

        this.searchRoles(1, 25, this.state.roleQuery);

        axios.get(`${constants.REACT_APP_HOST_API_URL}/recordtypes`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                recordTypeList: response.data
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle selecting a role by bringing up the modal with role values pre-filled.
     *
     * @param roleId - The ID of the role selected.
     */
    viewRole(roleId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/role/${roleId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let writePermissions = [];

            Object.keys(response.data.writePermissions).forEach((key, index) => {
               writePermissions.push({
                   recordType: key,
                   access: response.data.writePermissions[key]
               });
            });

            this.setState(prevState => ({
                ...prevState,
                role: response.data,
                writePermissions: writePermissions
            }));

            $('#role').modal('show');

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Initialize a new instance of a role object when the user initializes a new role.
     */
    initRole() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/role/new`).then(response => {

            let writePermissions = [{
                recordType: '',
                access: false
            }];

            this.setState(prevState => ({
                ...prevState,
                role: response.data,
                writePermissions: writePermissions
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Delete the selected role.
     */
    deleteRole() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/role/${this.state.role.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.system.roles.deleted'
                    }
                }],
            });

            this.searchRoles(this.state.roleList.page, this.state.roleList.recordsPerPage, this.state.roleQuery);

            $('#role').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle the submission of the form. Use the create date field of the role in the state to determine if the user is
     * editing or creating a new role. Upon successful submission of the form, refresh the table and hide the modal.
     *
     * @param event - The event container.
     */
    saveRole(event) {

        event.preventDefault();

        let role = this.state.role;
        let writePermissions = {};

        this.state.writePermissions.forEach((writePermission, key) => {
            writePermissions[writePermission.recordType] = writePermission.access;
        });

        role.writePermissions = writePermissions;

        if(this.state.role.createDate == null) {

            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, role, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.system.roles.created'
                        }
                    }],
                });

                this.searchRoles(this.state.roleList.page, this.state.roleList.recordsPerPage, this.state.roleQuery);

                $('#role').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        if(this.state.role.createDate != null) {

            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, role, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved'
                        }
                    }],
                });

                this.searchRoles(this.state.roleList.page, this.state.roleList.recordsPerPage, this.state.roleQuery);

                $('#role').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });

        }

        window.scrollTo(0, 0);
    }

    /**
     * Update the data table of roles.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchRoles(page, recordsPerPage, query) {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/role/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                roleList: response.data,
                roleQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Add a write permission to the list of write permissions from the selected role.
     */
    addWritePermission() {

        let writePermissions = this.state.writePermissions;

        writePermissions.push({
            recordType: '',
            access: false,
        });

        this.setState(prevState => ({
            ...prevState,
           writePermissions: writePermissions
        }));
    }

    /**
     * Remove a write permission from the list of write permissions from the selected role.
     *
     * @param index - The index of the write permission to remove from the list of write permissions.
     */
    removeWritePermission(index) {

        let writePermissions = this.state.writePermissions;

        writePermissions.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            writePermissions: writePermissions
        }));
    }

    /**
     * Handle changes to a write permission record type.
     *
     * @param event - The event container.
     */
    handleChangeWritePermissionRecordType(event) {

        event.persist();

        let writePermissions = this.state.writePermissions;

        writePermissions[(event.target.id).substring(22)] = {
            recordType: event.target.value,
            access: writePermissions[(event.target.id).substring(22)].access,
        };

        this.setState(prevState => ({
            ...prevState,
            writePermissions: writePermissions
        }));
    }

    /**
     * Handle changes to a write permission's access flag.
     *
     * @param event - The event container.
     */
    handleChangeWritePermissionAccess(event) {

        let writePermissions = this.state.writePermissions;

        writePermissions[(event.target.id).substring(24)] = {
            recordType: writePermissions[(event.target.id).substring(24)].recordType,
            access: event.target.checked,
        };

        this.setState(prevState => ({
            ...prevState,
            writePermissions: writePermissions
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The roles component.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="Roles" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Rules
                                </div>
                                <div className="col text-right">
                                    <div data-toggle="modal" data-target="#role" className="btn btn-primary btn-sm" onClick={() => this.initRole()}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Role
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table columns={{name: 'Role', writePermissions: 'Write Permissions'}}
                               columnWidths={['25%', '75%']}
                               data={this.state.roleList}
                               query={this.state.roleQuery}
                               sortEnabled={false}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchRoles}>
                            <tbody>
                            {this.state.roleList.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.viewRole(data.id)} className="c-pointer">
                                        <td>
                                            <div className="">
                                                {data.name}
                                            </div>
                                        </td>
                                        <td>
                                            {data.writePermissions &&
                                            <div className="">
                                                {Object.keys(data.writePermissions).map((key) => {
                                                    return (
                                                        <span className={`badge ${data.writePermissions[key] === true ? 'badge-success' : 'badge-danger'} text-uppercase mr-1`} key={key}>
                                                            {key !== '*' &&
                                                            <React.Fragment>
                                                                <FontAwesomeIcon icon={['fas', data.writePermissions[key] === true ? 'check' : 'times']} className="" /> <FormattedMessage id={"enum.record." + key} />
                                                            </React.Fragment>
                                                            }
                                                            {key === '*' &&
                                                            <React.Fragment>
                                                                <FontAwesomeIcon icon={['fas', data.writePermissions[key] === true ? 'check' : 'times']} className="" /> All Record Types
                                                            </React.Fragment> }
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="role" tabIndex="-1" role="dialog" aria-labelledby="role-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.saveRole}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="role-label">
                                            {this.state.role['createDate'] == null &&
                                            <span className="">Create Role</span>
                                            }
                                            {this.state.role['createDate'] != null &&
                                            <span className="">Edit Role</span>
                                            }
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldText id="name" label="Name" labelClass="col-form-label-md" fieldClass="form-control-md" model="role" parent={this} required={true} value={this.state.role['name']} />

                                        {this.state.role.writePermissions &&
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label col-form-label-sm">
                                                Permissions
                                            </label>
                                            <div className="col-sm-9">

                                                {this.state.writePermissions.map((writePermission, key) => {
                                                    return (
                                                        <div key={key} className="form-group row mb-2">
                                                            <div className="col-sm-12">
                                                                <div className={`input-group input-group-md`}>

                                                                    <div className="input-group-prepend">
                                                                        <div className="input-group-text">
                                                                            <div className="custom-control custom-checkbox ml-2">

                                                                                <input
                                                                                    id={`write-permission-access-${key}`}
                                                                                    name={`write-permission-access-${key}`}
                                                                                    type="checkbox"
                                                                                    value={writePermission.access}
                                                                                    checked={writePermission.access || false}
                                                                                    onChange={this.handleChangeWritePermissionAccess}
                                                                                    className="custom-control-input" />

                                                                                <label className={`custom-control-label`} htmlFor={`write-permission-access-${key}`} />

                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <select id={`write-permission-type-${key}`}
                                                                            name={`write-permission-type-${key}`}
                                                                            value={writePermission.recordType}
                                                                            className={`form-control mb-0`}
                                                                            onChange={this.handleChangeWritePermissionRecordType}>

                                                                        <option value="">Select a record type...</option>

                                                                        <option value="*">All Record Types</option>

                                                                        {Object.entries(this.state.recordTypeList).map(([index, recordType]) => {
                                                                            return (
                                                                                <FormattedMessage id={"enum.record." + recordType} key={index}>
                                                                                    {(recordTypeValue) => <option key={index} value={recordType}>{recordTypeValue}</option>}
                                                                                </FormattedMessage>
                                                                            )
                                                                        })}

                                                                    </select>

                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-outline-primary" type="button" id="button-addon2" disabled={key === 0} onClick={() => this.removeWritePermission(key)}>
                                                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw"/>
                                                                        </button>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                                <div className="btn btn-outline-primary btn-sm" onClick={() => this.addWritePermission()}>
                                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw"/> Add permission
                                                </div>

                                            </div>
                                        </div>
                                        }

                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom d-block">
                                        <div className="row">
                                            <div className="col-4">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#role").modal("hide")}>Close</button>
                                            </div>
                                            <div className="col-8 text-right">

                                                {this.state.role['createDate'] != null &&
                                                <div className="btn btn-lg btn-primary ml-2" onClick={() => {$("#role").modal("hide"); $("#delete-role").modal("show");}}>
                                                    Delete
                                                </div>
                                                }

                                                <ButtonSave />

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <Modal id="delete-role" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Role" body="Are you sure you would like to delete this role? Users currently utilizing this role will no longer have any write permissions as this role will no longer exist.">
                        <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => {$("#role").modal("show")}}>
                            <FormattedMessage id="button.back" />
                        </button>
                        <button onClick={() => {this.deleteRole()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                            Delete Role
                        </button>
                    </Modal>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default injectIntl(Roles);