import Propertii from "./Propertii";
import axios from "axios";
import * as constants from "../../util/constants";
import React from "react";
import Table from "./Table";
import {injectIntl} from "react-intl";
import $ from "jquery";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FieldDate from "./FieldDate";
import moment from "moment";
import FieldSelect from "./FieldSelect";
import Spinner from "./Spinner";
import ButtonClose from "./ButtonClose";
import Moment from "react-moment";
import FieldProperty from "./FieldProperty";

class ReportTransactions extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            transaction: {},

            transactionList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            startDate: moment().subtract(30, 'days').format('YYYY-MM-DD[T]hh:mm:ssZZ'),
            endDate: null,

            parameters: {
                settledOnly: false,
                propertyId: null
            },

            propertyList: [],
            selectedProperty: null,

            validationList: [],

        };

        this.searchTransactions = this.searchTransactions.bind(this);
        this.filterTransactions = this.filterTransactions.bind(this);
        this.downloadTransactions = this.downloadTransactions.bind(this);
        this.viewTransaction = this.viewTransaction.bind(this);
        this.searchProperties = this.searchProperties.bind(this);
    }

    /**
     * Fetch the list of related properties and transactions upon mounting of the component.
     */
    componentDidMount() {

        let filter = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters
        };

        this.searchProperties();
        this.searchTransactions(1, 25, filter);
    }

    /**
     * View the detailed information of a transaction by initializing the transaction view modal.
     *
     * @param transaction - The transaction model to view.
     */
    viewTransaction(transaction) {

        this.setState(prevState => ({
            ...prevState,
            transaction: transaction
        }));

        $('#transaction').modal('show');
    }

    /**
     * Update the data table of transactions.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param filter - The filters to apply to the search.
     */
    searchTransactions(page, recordsPerPage, filter) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_transactions?recordsPerPage=${recordsPerPage}&page=${page}`, filter, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                transactionList: response.data,
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Apply the available search filters on the transaction list and perform a new search.
     *
     * @param event - The event container.
     */
    filterTransactions(event) {

        if(event != null) {
            event.preventDefault();
        }

        let filter = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters
        };

        if(this.state.fromDate != null) {
            filter.startDate = this.state.startDate;
        }

        if(this.state.endDate != null) {
            filter.endDate = this.state.endDate;
        }

        if(this.state.parameters.settledOnly != null) {
            filter.parameters.settledOnly = this.state.parameters.settledOnly === "true";
        }

        if(this.state.parameters.propertyId != null) {
            filter.parameters.propertyId = this.state.parameters.propertyId;
        }

        this.searchTransactions(1, 25, filter);
    }

    /**
     * Search for a list of all available properties for filtering.
     */
    searchProperties() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'c.parentId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            ],
            joins: {
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                },
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                propertyList: response.data.records
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Download the transaction report in CSV format. The downloaded file is based on the filters set by the user.
     *
     * @param event - The event container.
     */
    downloadTransactions(event) {

        event.preventDefault();

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_transactions/download`, {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters,
        }, {
            responseType: 'arraybuffer',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The completed transactions report component.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Transactions
                            </div>
                            <div className="col text-right">
                                <div className="btn btn-primary btn-sm" onClick={(event) => this.downloadTransactions(event)}>
                                    <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download Report
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-header gotham border-top py-3 bg-secondary">
                        <form onSubmit={this.filterTransactions} autoComplete="off">
                            <div className="media">
                                <div className="media-body align-self-center">
                                    <FieldDate id="startDate" placeholder="Start Date" labelColumns="0"
                                               fieldColumns="12" parent={this} value={this.state.startDate}
                                               selectsStart={true} startDate={this.state.startDate}
                                               endDate={this.state.endDate}/>
                                </div>
                                <div className="align-self-center mx-3">
                                    <p className="mb-0 text-dark">
                                        to
                                    </p>
                                </div>
                                <div className="media-body align-self-center mr-3">
                                    <FieldDate id="endDate" placeholder="End Date" labelColumns="0"
                                               fieldColumns="12" parent={this} value={this.state.endDate}
                                               selectsEnd={this} startDate={this.state.startDate}
                                               endDate={this.state.endDate} minDate={this.state.startDate}/>
                                </div>
                                <div className="media-body align-self-center mr-3">
                                    <FieldProperty id="propertyId" label="Property" model="parameters" fieldColumns="12"
                                                   labelClass="d-none" labelColumns="0"
                                                   value={this.state.selectedProperty} parent={this}
                                                   options={this.state.propertyList} allPropertiesOption={true} />
                                </div>
                                <div className="media-body align-self-center mr-3">
                                    <FieldSelect id="settledOnly" labelColumns="0"
                                                 fieldColumns="12" parent={this} model="parameters"
                                                 value={this.state.parameters.settledOnly}>
                                        <option value={false}>All Transactions</option>
                                        <option value={true}>Settled Transactions</option>
                                    </FieldSelect>
                                </div>
                                <div className="align-self-center text-right">
                                    <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                        <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Table tableClass="table-bordered table-hover table-responsive"
                           columns={{
                               propertyName: 'Property Name',
                               unit: 'Unit',
                               customerName: 'Name',
                               phone: 'Phone',
                               email: 'Email',
                               paymentMethod: 'Payment Method',
                               source: 'Source',
                               confirmationId: 'Confirmation ID',
                               transactionDate: 'Transaction Date',
                               type: 'Type',
                               amount: 'Amount',
                               charges: 'Charges',
                               returnReason: 'Return Reason',
                               eftConfId: 'EFT Reference ID',
                               eftDate: 'EFT Date',
                               eftAmount: 'EFT Amount',
                               accountNumber: 'Account ID',
                               propertyIdentifier: 'Property ID',
                               note: 'Note'
                           }}
                           minColumnWidths={[
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '300px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '300px',
                               '200px',
                               '200px',
                               '300px',
                               '300px',
                               '200px'
                           ]}
                           headerClass=""
                           data={this.state.transactionList}
                           filters={{
                               startDate: this.state.startDate,
                               endDate: this.state.endDate,
                               parameters: this.state.parameters
                           }}
                           sortEnabled={false}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.searchTransactions}>
                        <tbody>
                        {this.state.transactionList.records.map((data, key) => {
                            return(
                                <tr key={key} onClick={() => this.viewTransaction(data)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            {data.propertyName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.unit}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.customerName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.phone}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.email}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.paymentMethod}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.source}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.confirmationId}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.transactionDate &&
                                            <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                {data.transactionDate}
                                            </Moment>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.type}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.amount}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.charges}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.returnReason}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.eftConfId}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.eftDate &&
                                            <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                {data.eftDate}
                                            </Moment>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.eftAmount}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.accountNumber}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.propertyIdentifier}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.note}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="transaction" tabIndex="-1" role="dialog" aria-labelledby="transaction-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title">
                                    <div className="">
                                        Completed Transaction
                                    </div>
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                </button>
                            </div>
                            <div className="modal-body modal-body-table">
                                <table className="table mb-0">
                                    <tbody>
                                    <tr className="small">
                                        <td className="">
                                            Property Name
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.propertyName}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Unit
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.unit}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Name
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.customerName}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Phone
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.phone}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Email
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.email}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Payment Method
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.paymentMethod}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Source
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.source}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Confirmation ID
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.confirmationId}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Transaction Date
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.transactionDate &&
                                                <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                    {this.state.transaction.transactionDate}
                                                </Moment>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Type
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.type}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Amount
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.amount}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Charges
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.charges}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Return Reason
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.returnReason}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            EFT Reference ID
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.eftConfId}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            EFT Date
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.eftDate &&
                                                <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                    {this.state.transaction.eftDate}
                                                </Moment>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            EFT Amount
                                        </td>
                                        <td className="text-right">
                                            {this.state.transaction.eftAmount}
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Account ID
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.accountNumber}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Property ID
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.propertyIdentifier}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Note
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.transaction.note}
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer d-block text-center bg-secondary rounded-bottom">
                                <ButtonClose />
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default injectIntl(ReportTransactions);