import React from 'react';
import axios from "axios";
import * as constants from "../../../util/constants";
import Table from "../../common/Table";
import Moment from "react-moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ButtonBack from "../../common/ButtonBack";
import Propertii from "../../common/Propertii";
import $ from "jquery";
import FieldText from "../../common/FieldText";
import ButtonSave from "../../common/ButtonSave";
import Modal from "../../common/Modal";
import {FormattedMessage} from "react-intl";
import FieldDate from "../../common/FieldDate";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";

class ApiAccounts extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            apiAccount: {},
            apiUser: '',
            apiAccounts: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },
            query: {
                orderBy: 'ASC',
                orderByFields: ['apiUser']
            },
            validationList: [],
        };

        this.searchApiAccounts = this.searchApiAccounts.bind(this);
        this.viewApiAccount = this.viewApiAccount.bind(this);
        this.deleteApiAccount = this.deleteApiAccount.bind(this);
        this.initApiAccount = this.initApiAccount.bind(this);
        this.saveApiAccount = this.saveApiAccount.bind(this);
    }

    /**
     * Initialize the API accounts list by performing a search for all API accounts.
     */
    componentDidMount() {

        this.searchApiAccounts(1, 25, this.state.query);
    }

    /**
     * Handle selecting an API account by bringing up the API account modal.
     *
     * @param apiAccountId - The ID of the API account selected.
     */
    viewApiAccount(apiAccountId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/api_account/${apiAccountId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                apiAccount: response.data,
                apiUser: response.data.apiUser
            }));

            $('#api-account').modal('show');

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Initialize a new instance of an API account object.
     */
    initApiAccount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/api_account/new`).then(response => {
            this.setState(prevState => ({
                ...prevState,
                apiAccount: response.data,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Create or save an existing API account on submission of the API account form.
     *
     * @param event - The event container.
     */
    saveApiAccount(event) {

        event.preventDefault();

        if(this.state.apiAccount.createDate == null) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, this.state.apiAccount, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.system.accounts.created'
                        }
                    }],
                });

                this.searchApiAccounts(this.state.apiAccounts.page, this.state.apiAccounts.recordsPerPage, this.state.query);

                $('#api-account').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, this.state.apiAccount, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved'
                        }
                    }],
                });

                this.searchApiAccounts(this.state.apiAccounts.page, this.state.apiAccounts.recordsPerPage, this.state.query);

                $('#api-account').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Delete an existing API account.
     */
    deleteApiAccount() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/api_account/${this.state.apiAccount.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.system.accounts.deleted'
                    }
                }],
            });

            this.searchApiAccounts(this.state.apiAccounts.page, this.state.apiAccounts.recordsPerPage, this.state.query);

            $('#api-account').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Perform a search for API accounts.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchApiAccounts(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/api_account/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                apiAccounts: response.data,
                query: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The sortable API accounts list.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="API Accounts" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    API Accounts
                                </div>
                                <div className="col text-right">
                                    <div data-toggle="modal" data-target="#api-account" className="btn btn-primary btn-sm" onClick={() => this.initApiAccount()}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create API Account
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table columns={{apiUser: 'API User', apiKey: 'API Key', createDate: 'Create Date', expiryDate: 'Expiry Date'}}
                               columnWidths={['25%', '25%', '25%', '25%']}
                               headerClass="c-pointer"
                               data={this.state.apiAccounts}
                               query={this.state.query}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchApiAccounts}>
                            <tbody>
                            {this.state.apiAccounts.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.viewApiAccount(data.id)} className="c-pointer">
                                        <td>{data.apiUser}</td>
                                        <td>{data.apiKey}</td>
                                        <td>
                                            {data.createDate &&
                                            <Moment format="MMM DD, YYYY">
                                                {data.createDate}
                                            </Moment>
                                            }
                                            {!data.createDate &&
                                            <span className="text-muted">
                                                N/A
                                            </span>
                                            }
                                        </td>
                                        <td>
                                            {data.expiryDate &&
                                            <Moment format="MMMM DD, YYYY" tz="UTC">
                                                {data.expiryDate}
                                            </Moment>
                                            }
                                            {!data.expiryDate &&
                                            <span className="text-secondary">
                                                <FontAwesomeIcon icon={['fas', 'minus']} className="fa-fw va-b mr-1" />
                                            </span>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="api-account" tabIndex="-1" role="dialog" aria-labelledby="api-account-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.saveApiAccount}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="api-account-label">
                                            {this.state.apiAccount['createDate'] == null &&
                                            <span className="">
                                                Create API Account
                                            </span>
                                            }
                                            {this.state.apiAccount['createDate'] != null &&
                                            <span className="">
                                                {this.state.apiUser}
                                            </span>
                                            }
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldText id="apiUser" label="API User" model="apiAccount" parent={this} value={this.state.apiAccount['apiUser']} />

                                        <FieldText id="apiKey" label="API Key" model="apiAccount" parent={this} value={this.state.apiAccount['apiKey']} />

                                        <FieldDate id="expiryDate" label="Expiry Date" model="apiAccount" parent={this} value={this.state.apiAccount['expiryDate'] || null} />

                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom d-block">

                                        <div className="row">
                                            <div className="col">

                                                <div className="float-left">

                                                    <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#api-account").modal("hide")}>Close</button>

                                                </div>
                                                <div className="float-right">

                                                    {this.state.apiAccount['createDate'] != null &&
                                                    <div data-toggle="modal" data-target="#delete-api-account" className="btn btn-lg btn-primary ml-2" onClick={() => $("#api-account").modal("hide")}>
                                                        Delete
                                                    </div>
                                                    }

                                                    <ButtonSave />

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <Modal id="delete-api-account" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete API Account"
                           body="Are you sure you want to delete this API Account? This change is not reversible.">
                        <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => $("#api-account").modal("show")}>
                            <FormattedMessage id="button.back" />
                        </button>
                        <button onClick={() => this.deleteApiAccount()} className="btn btn-danger btn-lg" data-dismiss="modal">
                            Delete API Account
                        </button>
                    </Modal>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default ApiAccounts;