import React, { Component } from 'react';
import {FormattedMessage} from "react-intl";
import $ from "jquery";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as constants from "../../util/constants";

class FieldText extends Component {

    /**
     * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
     * custom handleChange function appears in the parent class, utilize the default handleChange function below.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        if(this.props.parent.handleChange) {
            this.handleChange = this.props.parent.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
    }

    /**
     * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
     * state, as opposed to a standalone element.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        let field;

        if(this.props.model != null) {
            field = this.props.parent.state[this.props.model];
            field[event.target.name] = event.target.value.replace(constants.SPECIAL_CHARACTER_REGEX, "");
        }

        if(this.props.model == null) {
            field = event.target.value.replace(constants.SPECIAL_CHARACTER_REGEX, "");
        }

        this.props.parent.setState(({
            [this.props.model ? this.props.model : event.target.name]: field,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic text field component.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if(validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if(key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        return(
            <React.Fragment>

                <div className={`form-group row ${this.props.containerClass}`}>

                    <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                        {this.props.label} {this.props.optional && <small className="text-muted font-italic">(Optional)</small>}
                    </label>

                    <div className={`col-sm-${this.props.fieldColumns}`}>

                        <div className={(this.props.prependIconName && this.props.prependIconType) ? 'input-group' : ''}>

                            {(this.props.prependIconName && this.props.prependIconType) &&
                            <div className={`input-group-prepend ${this.props.prependClass}`}>
                                <span className={`input-group-text ${this.props.prependTextClass}`}>
                                     <FontAwesomeIcon icon={[this.props.prependIconType, this.props.prependIconName]} className="fa-fw small" />
                                </span>
                            </div>
                            }

                            <input
                                id={this.props.id}
                                name={this.props.id}
                                type={this.props.type}
                                maxLength={this.props.maxLength}
                                value={this.props.value || ""}
                                onChange={this.props.handleChange || this.handleChange}
                                onFocus={this.props.handleFocus}
                                onBlur={this.props.handleBlur}
                                placeholder={this.props.placeholder}
                                min={this.props.min}
                                max={this.props.max}
                                step={this.props.step}
                                pattern={this.props.pattern}
                                disabled={this.props.disabled}
                                required={this.props.required}
                                autoFocus={this.props.autoFocus}
                                className={`form-control ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`}
                                data-container={this.props.dataContainer}
                                data-trigger={this.props.dataTrigger}
                                data-toggle={this.props.dataToggle}
                                data-placement={this.props.dataPlacement}
                                data-content={this.props.dataContent}
                                data-html={this.props.dataHtml}
                            />

                            {isInvalid &&
                            <div className="invalid-feedback">
                                <FormattedMessage id={"danger." + errorCode}/>
                            </div>
                            }

                        </div>

                        {this.props.help &&
                        <small className="form-text text-muted">
                            {this.props.help}
                        </small>
                        }

                    </div>

                </div>

            </React.Fragment>
        )
    };
}

FieldText.defaultProps = {
    formGroup: true,
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    type: 'text',
    maxLength: '100'
};

export default FieldText;
