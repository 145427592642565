import React, { Component } from 'react';
import Cookies from "universal-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class JumbotronHelp extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            hideHelp: false,
            validationList: [],
        };

        this.hideHelp = this.hideHelp.bind(this);
    }

    /**
     * On mounting of the component, determine if the component should be visible to the user or not by accessing the
     * user's cookies.
     */
    componentDidMount() {

        const cookies = new Cookies();

        this.setState(prevState => ({
            ...prevState,
            hideHelp: !!cookies.get('hideHelp')
        }));
    }

    /**
     * Hide the help jumbotron for one week. Sets a special additional state in order to display a confirmation message
     * to the user letting them know that the message has been hidden.
     */
    hideHelp() {

        const cookies = new Cookies();

        cookies.set('hideHelp', true, { maxAge: 604800 });

        this.setState(prevState => ({
            ...prevState,
            hideHelp: cookies.get('hideHelp'),
            hideHelpTriggered: true
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A customizable help component that provides an external link, most commonly to the Help Center.
     */
    render() {

        return(
            <React.Fragment>

                {!this.state.hideHelp &&
                <div className="jumbotron border py-5">
                    <div className="container">

                        <p className="">
                            <FontAwesomeIcon icon={['fal', this.props.icon]} className={`text-primary`} size="3x"/>
                        </p>

                        <p className="h4">
                            {this.props.heading}
                        </p>

                        <p className="">
                            {this.props.body}
                        </p>

                        <a className="btn btn-primary btn-md mb-3 text-white" href={this.props.buttonUrl}>
                            {this.props.buttonText} <FontAwesomeIcon icon={['far', this.props.buttonIcon]} className="fa-fw"/>
                        </a>

                        <p className="small btn-link c-pointer mb-0" onClick={() => this.hideHelp()}>
                            No thanks, hide this for now.
                        </p>

                    </div>
                </div>
                }

                {this.state.hideHelpTriggered &&
                <div className="jumbotron border py-5">
                    <div className="container">
                        <p className="text-muted mb-0">
                            Got it! We'll keep this message hidden for a few days.
                        </p>
                    </div>
                </div>
                }

            </React.Fragment>
        )
    };
}

export default JumbotronHelp;