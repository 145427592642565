import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import DocumentList from "../../common/DocumentList";
import Alert from "../../common/Alert";

class Documents extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - A document management interface for a property lease under a manager.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} validationType="primary" />

                <DocumentList token={this.props.token} parent={this} recordType="PROPERTY_LEASE" recordId={this.props.match.params.propertyLeaseId} />

            </React.Fragment>
        )
    };
}

Documents.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Documents);