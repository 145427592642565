import React from 'react';
import Propertii from "../../common/Propertii";
import PropertyList from "../../common/Properties";
import Breadcrumb from "../../common/Breadcrumb";

class Properties extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The manager property list component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Properties" />

                    <PropertyList userType={this.props.userSession.sessionRole.type}
                                  companyId={this.props.userSession.sessionRole.companyId}
                                  merchantAccounts={true}
                                  history={this.props.history}
                                  createMessage="manager.properties.created" />

                </div>

            </div>
        )
    };
}

export default Properties;