import React from 'react';
import * as constants from "../../util/constants";
import {FormattedMessage, injectIntl, intlShape} from "react-intl";
import Propertii from "../common/Propertii";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import FieldText from "../common/FieldText";
import ButtonSave from "./ButtonSave";
import axios from "axios";
import Modal from "./Modal";
import Alert from "./Alert";

class CustomFieldList extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            customFields: {},
            customFieldKey: '',
            customFieldValue: '',
            editing: false,
            validationList: [],
        };

        this.initCustomField = this.initCustomField.bind(this);
        this.editCustomField = this.editCustomField.bind(this);
        this.saveCustomField = this.saveCustomField.bind(this);
        this.deleteCustomField = this.deleteCustomField.bind(this);
    }

    /**
     * Get the company based on the ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.props.recordType}/${this.props.recordId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                customFields: response.data.customFields
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Initialize a custom field when the user clicks the 'create custom field' button.
     */
    initCustomField() {

        this.setState(prevState => ({
            ...prevState,
            customFieldKey: '',
            customFieldValue: '',
            editing: false
        }));
    }

    /**
     * Edit an existing custom field key/value pair.
     *
     * @param customFieldKey - The key of the custom field.
     */
    editCustomField(customFieldKey) {

        let customFieldValue = this.state.customFields[customFieldKey];

        this.setState(prevState => ({
            ...prevState,
            customFieldKey: customFieldKey,
            customFieldValue: customFieldValue,
            editing: true
        }));

        $("#custom-field").modal("show");
    }

    /**
     * Save and submit a custom field key/value pair.
     *
     * @param event - The event container.
     */
    saveCustomField(event) {

        event.preventDefault();

        let customFields = this.state.customFields;

        customFields[this.state.customFieldKey] = this.state.customFieldValue;

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/${this.props.recordType}/${this.props.recordId}/update`, {
            customFields: customFields
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                customFields: response.data.customFields,
            }));

            this.props.parent.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: this.state.editing ? 'saved' : this.props.createMessage
                    }
                }],
            });

            $("#custom-field").modal("hide");

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.match.params.companyId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    company: response.data,
                    customFields: response.data.customFields,
                }));
            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Delete a custom field key/value pair.
     */
    deleteCustomField() {

        let customFields = this.state.customFields;

        delete customFields[this.state.customFieldKey];

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/${this.props.recordType}/${this.props.recordId}/update`, {
            customFields: customFields
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                customFields: response.data.customFields,
            }));

            this.props.parent.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: this.props.deleteMessage
                    }
                }],
            });

            $("#custom-field").modal("hide");

            axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.props.recordType}/${this.props.recordId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    company: response.data,
                    customFields: response.data.customFields
                }));
            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The generic custom fields management component.
     */
    render() {

        const prettyJson = require('prettyjson');

        return(
            <React.Fragment>

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Custom Fields
                            </div>
                            <div className="col text-right">
                                <div data-toggle="modal" data-target="#custom-field" className="btn btn-primary btn-sm" onClick={() => this.initCustomField()}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Custom Field
                                </div>
                            </div>
                        </div>
                    </div>
                    {Object.entries(this.state.customFields).length === 0 &&
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="text-center text-secondary">
                                    <FontAwesomeIcon icon={['fas', 'ghost']} className="fa-fw mb-4" size="5x"/>
                                </div>
                                <div className="text-center text-muted">
                                    <small>
                                        This record does not currently have any custom fields.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {Object.entries(this.state.customFields).length > 0 &&
                    <div className="card-body card-body-table">
                        <table className="table table-hover table-bordered">
                            <thead>
                            <tr>
                                <th width="25%">Key</th>
                                <th width="75%">Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.keys(this.state.customFields).map((key) => {
                                return(
                                    <tr key={key} onClick={() => this.editCustomField(key)} className="c-pointer">
                                        <td>
                                            {key}
                                        </td>
                                        <td>
                                            <pre className="mb-0">
                                                <code>
                                                    {prettyJson.render(this.state.customFields[key])}
                                                </code>
                                            </pre>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    }
                </div>

                <div className="modal fade" id="custom-field" tabIndex="-1" role="dialog" aria-labelledby="custom-field-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveCustomField}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="custom-field-label">
                                        {!this.state.editing &&
                                        <span className="">Create Custom Field</span>
                                        }
                                        {this.state.editing &&
                                        <span className="">Edit Custom Field</span>
                                        }
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="customFieldKey" label="Key" disabled={this.state.editing} parent={this} value={this.state.customFieldKey} />

                                    <FieldText id="customFieldValue" label="Value" parent={this} value={this.state.customFieldValue} />

                                </div>
                                <div className="modal-footer bg-secondary d-block rounded-bottom">

                                    <div className="row">
                                        <div className="col">

                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#custom-field").modal("hide")}>Close</button>
                                            </div>

                                            <div className="float-right">

                                                {this.state.editing &&
                                                <div className="btn btn-lg btn-primary ml-2" onClick={() => {$("#custom-field").modal("hide"); $("#delete-custom-field").modal('show');}}>
                                                    Delete
                                                </div>
                                                }

                                                <ButtonSave />
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <Modal id="delete-custom-field" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Custom Field"
                       body="Are you sure you want to delete this custom field?">
                    <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => {$("#delete-custom-field").modal("hide"); $("#custom-field").modal('show');}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button onClick={() => {this.deleteCustomField()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                        Delete Custom Field
                    </button>
                </Modal>

            </React.Fragment>
        )
    };
}

CustomFieldList.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(CustomFieldList);