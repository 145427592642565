import Propertii from "./Propertii";
import axios from "axios";
import * as constants from "../../util/constants";
import React from "react";
import Table from "./Table";
import {FormattedNumber, injectIntl} from "react-intl";
import $ from "jquery";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FieldDate from "./FieldDate";
import moment from "moment";
import FieldSelect from "./FieldSelect";
import Spinner from "./Spinner";
import ButtonClose from "./ButtonClose";
import Moment from "react-moment";

class ReportEfts extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            eft: {},

            eftList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            eftTransactionList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            eftTransactionQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [],
                joins: {
                    pl: {
                        targetRecordType: 'TYPE_PROPERTY_LEASE',
                        joinField: 'billingAccountId',
                        alias: 'pl',
                        returnFields: ['accountNumber']
                    },
                    c: {
                        targetRecordType: 'TYPE_CUSTOMER',
                        joinField: 'userId',
                        alias: 'c',
                        returnFields: ['firstName', 'lastName']
                    }
                }
            },

            startDate: moment().subtract(30, 'days').format('YYYY-MM-DD[T]hh:mm:ssZZ'),
            endDate: null,

            parameters: {
                last4: null
            },

            bankAccountList: [],

            validationList: [],

        };

        this.searchEfts = this.searchEfts.bind(this);
        this.filterEfts = this.filterEfts.bind(this);
        this.searchEftTransactions = this.searchEftTransactions.bind(this);
        this.downloadEfts = this.downloadEfts.bind(this);
        this.viewEft = this.viewEft.bind(this);
        this.searchMerchantAccounts = this.searchMerchantAccounts.bind(this);
    }

    /**
     * Fetch the list of related properties and transactions upon mounting of the component.
     */
    componentDidMount() {

        let filter = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters
        };

        this.searchMerchantAccounts();
        this.searchEfts(1, 25, filter);
    }

    /**
     * View the detailed information of an EFT by initializing the EFT view modal.
     *
     * @param eft - The EFT model to view.
     */
    viewEft(eft) {

        let eftTransactionQuery = this.state.eftTransactionQuery;

        eftTransactionQuery.conditionList = [
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'fundsTransferId',
                operator: 'EQUALS',
                fieldValue: eft.id
            }
        ];

        this.setState(prevState => ({
            ...prevState,
            eft: eft
        }));

        this.searchEftTransactions(1, 25, eftTransactionQuery);

        $('#eft').modal('show');
    }

    /**
     * Update the data table of EFTs.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param filter - The filters to apply to the search.
     */
    searchEfts(page, recordsPerPage, filter) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_eft?recordsPerPage=${recordsPerPage}&page=${page}`, filter, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                eftList: response.data,
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Apply the available search filters on the EFT list and perform a new search.
     *
     * @param event - The event container.
     */
    filterEfts(event) {

        if(event != null) {
            event.preventDefault();
        }

        let filter = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters
        };

        if(this.state.fromDate != null) {
            filter.startDate = this.state.startDate;
        }

        if(this.state.endDate != null) {
            filter.endDate = this.state.endDate;
        }

        if(this.state.parameters.last4 != null) {
            filter.parameters.last4 = this.state.parameters.last4;
        }

        this.searchEfts(1, 25, filter);
    }

    /**
     * Search for a list of all relevant merchant accounts for populating the bank account dropdown filter.
     */
    searchMerchantAccounts() {

        let bankAccountList = [];

        axios.post(`${constants.REACT_APP_HOST_API_URL}/merchant_account/search`, {
            orderBy: 'ASC',
            orderByFields: ['last4'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'c.parentId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            ],
            joins: {
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                },
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            response.data.records.forEach((data, key) => {
                bankAccountList.push(data.last4);
            });

            bankAccountList = [...new Set(bankAccountList)];

            this.setState(prevState => ({
                ...prevState,
                bankAccountList: bankAccountList
            }));

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Search for a list of all payment transactions related to the EFT currently being viewed by the user.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchEftTransactions(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                eftTransactionList: response.data,
                eftTransactionQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Download the EFT report in CSV format. The downloaded file is based on the filters set by the user.
     *
     * @param event - The event container.
     */
    downloadEfts(event) {

        event.preventDefault();

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_eft/download`, {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters,
        }, {
            responseType: 'arraybuffer',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The EFT report component.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                EFTs
                            </div>
                            <div className="col text-right">
                                <div className="btn btn-primary btn-sm" onClick={(event) => this.downloadEfts(event)}>
                                    <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download Report
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-header gotham border-top py-3 bg-secondary">
                        <form onSubmit={this.filterEfts} autoComplete="off">
                            <div className="media">
                                <div className="media-body align-self-center">
                                    <FieldDate id="startDate" placeholder="Start Date" labelColumns="0"
                                               fieldColumns="12" parent={this} value={this.state.startDate}
                                               selectsStart={true} startDate={this.state.startDate}
                                               endDate={this.state.endDate}/>
                                </div>
                                <div className="align-self-center mx-3">
                                    <p className="mb-0 text-dark">
                                        to
                                    </p>
                                </div>
                                <div className="media-body align-self-center mr-3">
                                    <FieldDate id="endDate" placeholder="End Date" labelColumns="0"
                                               fieldColumns="12" parent={this} value={this.state.endDate}
                                               selectsEnd={this} startDate={this.state.startDate}
                                               endDate={this.state.endDate} minDate={this.state.startDate}/>
                                </div>
                                <div className="media-body text-center align-self-center mr-3">
                                    <FieldSelect id="last4" fieldColumns="12" labelColumns="0" parent={this}
                                                 value={this.state.parameters.last4} model="parameters">
                                        <option value="">All Bank Accounts</option>
                                        {this.state.bankAccountList &&
                                        <React.Fragment>
                                            {this.state.bankAccountList.map((data, key) => {
                                                return (
                                                    <option key={key} value={data}>
                                                        *****{data}
                                                    </option>
                                                );
                                            })}
                                        </React.Fragment>
                                        }
                                    </FieldSelect>
                                </div>
                                <div className="align-self-center text-right">
                                    <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                        <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Table tableClass="table-bordered table-hover table-responsive"
                           columns={{
                               eftDate: 'EFT Date',
                               amount: 'Amount',
                               last4: 'Bank Account',
                               confirmationId: 'Confirmation ID',
                           }}
                           minColumnWidths={[
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                           ]}
                           headerClass=""
                           data={this.state.eftList}
                           filters={{
                               startDate: this.state.startDate,
                               endDate: this.state.endDate,
                               parameters: this.state.parameters
                           }}
                           sortEnabled={false}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.searchEfts}>
                        <tbody>
                        {this.state.eftList.records.map((data, key) => {
                            return(
                                <tr key={key} onClick={() => this.viewEft(data)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            {data.eftDate &&
                                            <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                {data.eftDate}
                                            </Moment>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.amount}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            ****{data.last4}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.confirmationId}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="eft" tabIndex="-1" role="dialog" aria-labelledby="eft-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">
                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title">
                                    <div className="">
                                        EFT
                                    </div>
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                </button>
                            </div>
                            <div className="modal-body modal-body-table">
                                <table className="table mb-0 border-bottom">
                                    <tbody>
                                    <tr className="small">
                                        <td className="">
                                            EFT Date
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.eft.eftDate &&
                                                <Moment format="YYYY-MM-SS HH:mm (UTC)" tz="UTC">
                                                    {this.state.eft.eftDate}
                                                </Moment>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Amount
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.eft.amount}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Bank Account
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                *****{this.state.eft.last4}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Confirmation ID
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.eft.confirmationId}
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-body modal-body-table">
                                <Table tableClass="table-bordered table-responsive"
                                       columns={{createDate:'Transaction Date', customerName: 'Name', accountNumber: 'Account ID', confirmationId: 'Confirmation ID', transactionType: 'Transaction Type', amount: 'Transaction Amount'}}
                                       minColumnWidths={['200px', '200px', '200px', '300px', '200px', '200px']}
                                       data={this.state.eftTransactionList}
                                       query={this.state.eftTransactionQuery}
                                       headerClass=""
                                       sortEnabled={false}
                                       recordsEnabled={true}
                                       paginationEnabled={true}
                                       updateFunction={this.searchEftTransactions}>
                                    <tbody>
                                    {this.state.eftTransactionList.records.map((data, key) => {
                                        return(
                                            <tr key={key}>
                                                <td>
                                                    <div className="">
                                                        <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                            {data.createDate}
                                                        </Moment>
                                                    </div>
                                                </td>
                                                <td>
                                                    {data.joins && data.joins.c &&
                                                    <div className="">
                                                        {data.joins.c.firstName} {data.joins.c.lastName}
                                                    </div>
                                                    }
                                                </td>
                                                <td>
                                                    {data.joins && data.joins.pl &&
                                                    <div className="">
                                                        {data.joins.pl.accountNumber}
                                                    </div>
                                                    }
                                                </td>
                                                <td>
                                                    <div className="">
                                                        {data.confirmationId}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        {data.transactionType}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <FormattedNumber value={data.amount} style={`currency`} currency="USD"/>
                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>

                            </div>
                            <div className="modal-footer d-block text-center bg-secondary rounded-bottom">
                                <ButtonClose />
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default injectIntl(ReportEfts);
