import React from 'react';
import {injectIntl, intlShape} from "react-intl";
import Propertii from "../../common/Propertii";
import ChargeCodeList from "../../common/ChargeCodes";
import ButtonBack from "../../common/ButtonBack";
import Breadcrumb from "../../common/Breadcrumb";

class ChargeCodes extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The company charge codes management component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/manager/settings" parentPage="Settings" childPage="Payment Methods" />

                    <div className="card card-primary border-primary">
                        <div className="card-body">
                            <p className="card-text">
                                By default, Letus offers a list of generic charge types. You may customize this list of charges by replacing them with your own custom charges below.
                            </p>
                        </div>
                    </div>

                    <ChargeCodeList companyId={this.props.userSession.sessionRole.companyId} createMessage="manager.settings.charges.created" deleteMessage="manager.settings.charges.deleted" duplicateMessage="manager.settings.charges.duplicate" />

                    <div className="row">
                        <div className="col text-right">
                            <ButtonBack path="/manager/settings" />
                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

ChargeCodes.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ChargeCodes);